import React, { SyntheticEvent, useMemo } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { Collapsed } from '@components/Collapsed';
import { RadioGroupControl } from '@components/RadioGroup';
import { DropdownListsContext } from '@components/DynamicForm';
import { useLoanProgramList } from '@pages/personalLoans/hooks/useLoanProgramList';
import { QUOTE_ACCORDION_VALUES, QUOTE_TYPE } from '@typings/common';
import { FormExitConfirmationPrompt } from '@components/FormExitConfirmationPrompt/FormExitConfirmationPrompt.component';
import { QuoteFields } from '@pages/scenarioBuilder/pages/quoteForm/components/QuoteFields/QuoteFields.component';
import { FieldProp } from '@components/DynamicForm/contexts/FieldsProps.context';
import { QUOTE_TYPE_RADIO_GROUP_NAME } from '../../QuoteForm.types';
import { QUOTE_DETAILS_TYPE_OPTIONS } from '../../QuoteForm.constants';

interface QuoteDetailsProps {
  quoteType: QUOTE_TYPE;
  calculationErrors: string[];
  accordionOnChange: (
    panel: QUOTE_ACCORDION_VALUES
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
  accordionValue: QUOTE_ACCORDION_VALUES | null;
  isCalculated: boolean;
  isSaved: boolean;
  isCalculating?: boolean;
  disabledByCheck?: boolean;
  milestoneFieldConfig?: Record<string, FieldProp[]>;
  isDirty?: boolean;
}

export const QuoteDetails = ({
  quoteType,
  isCalculated,
  isSaved,
  isCalculating,
  calculationErrors,
  accordionValue,
  accordionOnChange,
  disabledByCheck,
  milestoneFieldConfig,
  isDirty = false,
}: QuoteDetailsProps) => {
  const { programs, products } = useLoanProgramList();

  const values = useMemo(() => {
    return {
      limitedLoanProgram: programs,
      loanProgram: products,
    };
  }, [programs, products]);

  return (
    <Paper elevation={2}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} data-cy="build-my-quote-header">
          <Typography variant="h2">Build My Quote</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p" paragraph>
            Create a loan quote by providing the information below to determine
            pricing and viability.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Collapsed
            label="Details"
            expanded={accordionValue === QUOTE_ACCORDION_VALUES.DETAILS}
            onChange={accordionOnChange(QUOTE_ACCORDION_VALUES.DETAILS)}
            data-cy="quote-details-accordion"
          >
            <Grid container spacing={2.5}>
              {!isSaved ? (
                <Grid item xs={12}>
                  <RadioGroupControl
                    name={QUOTE_TYPE_RADIO_GROUP_NAME.QUOTE_TYPE}
                    options={QUOTE_DETAILS_TYPE_OPTIONS}
                    row
                    major
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <DropdownListsContext.Provider value={values}>
                  <QuoteFields
                    isDirty={isDirty}
                    isCalculating={isCalculating}
                    isCalculated={isCalculated || isSaved}
                    quoteType={quoteType}
                    errors={calculationErrors}
                    disabledByCheck={disabledByCheck}
                    milestoneFieldConfig={milestoneFieldConfig}
                  />
                </DropdownListsContext.Provider>
              </Grid>
              <FormExitConfirmationPrompt
                when={!isCalculating && isSaved && isDirty}
              />
            </Grid>
          </Collapsed>
        </Grid>
      </Grid>
    </Paper>
  );
};
