import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useLazyGetApiV1LoansByLoanIdDocumentTasksQuery } from '@api/output/api';
import { useTaskGenerate } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTaskGenerate';

export const useFinalDocumentTasks = () => {
  const { loanId = '' } = useParams();
  const { isSuccess, isLoading } = useTaskGenerate();

  const [loadTasks, { isFetching: isTaskLoading }] =
    useLazyGetApiV1LoansByLoanIdDocumentTasksQuery();

  useEffect(() => {
    if (isSuccess) {
      loadTasks({ loanId });
    }
  }, [isSuccess]);

  return useMemo(() => {
    return {
      isLoading: isLoading || isTaskLoading,
    };
  }, [isLoading, isTaskLoading]);
};
