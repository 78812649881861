import React from 'react';
import { Paper } from '@mui/material';
import { TableColumnType } from 'antd';
import { DataTable } from '@lola/ui-react-components';
import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';
import styles from './quotesTable.module.scss';

export interface QuotesTableProps {
  columns: TableColumnType<LolaBffApiContractsModelsLoanLoanView>[];
  data?: LolaBffApiContractsModelsLoanLoanView[];
  onLoanSelect?: (row: any[]) => void;
}

export const QuotesTable = ({ columns, data }: QuotesTableProps) => {
  return (
    <Paper elevation={0} classes={{ root: styles.paper }}>
      <DataTable
        rowKey="id"
        scroll={{
          x: 1300,
          y: 600,
        }}
        columns={columns}
        dataSource={data}
        mode="wide"
      />
    </Paper>
  );
};
