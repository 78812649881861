import React from 'react';
import { Grid, LinearProgress } from '@mui/material';
import { useTaskGenerate } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTaskGenerate';
import { TASK_SECTIONS } from '@typings/common';
import { UploadDocuments } from './components';

export interface UploadDocumentsPageProps {
  title?: string;
  description?: string;
  section: TASK_SECTIONS;
}

export const UploadDocumentsPage = ({
  title,
  description,
  section,
}: UploadDocumentsPageProps) => {
  const { isLoading } = useTaskGenerate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isLoading && <LinearProgress />}
        {!isLoading && (
          <UploadDocuments
            section={section}
            title={title}
            description={description}
          />
        )}
      </Grid>
    </Grid>
  );
};
