import React from 'react';
import { FlashlightIcon } from '@lola/ui-react-components';

export enum MODE_SWITCHER_VALUES {
  ALL = 'All',
  KEY_CLOSING_TASK = 'KCT',
}

export const MODE_SWITCHER_OPTIONS = [
  {
    label: 'All',
    value: MODE_SWITCHER_VALUES.ALL,
  },
  {
    label: 'KCT',
    value: MODE_SWITCHER_VALUES.KEY_CLOSING_TASK,
    icon: <FlashlightIcon />,
  },
];
