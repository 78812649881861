import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import {
  LolaBffApiContractsModelsUser,
  usePostApiV1TeamsMutation,
} from '@api/output/api';
import { SetModalContent } from '@typings/common';
import { usePostUpdate } from '@hooks/usePostUpdate';
import { SelectDestinationTeam } from '../SelectDestinationTeam/SelectDestinationTeam.component';
import {
  MODAL_STATE,
  ModalContentType,
} from '../../../../TeamMembers.constants';
import styles from './assignToTeam.module.scss';

interface AssignToTeamProps {
  setModalContent: SetModalContent<ModalContentType>;
  context: LolaBffApiContractsModelsUser;
}

export const AssignToTeam = ({
  setModalContent,
  context,
}: AssignToTeamProps) => {
  const methods = useForm({
    defaultValues: {
      destinationTeamId: '',
    },
  });

  const handleClose = useCallback(
    () => setModalContent({ state: MODAL_STATE.CLOSE }),
    []
  );

  const [add, { isUpdating }] = usePostUpdate(
    usePostApiV1TeamsMutation,
    handleClose
  );

  const { fullName, userId } = context;

  const onFormSubmit = useMemo(() => {
    return methods.handleSubmit((values) => {
      const { destinationTeamId } = values;
      add({
        lolaBffApiContractsModelsRequestsManageTeamRequest: {
          userId,
          sourceTeamId: null,
          destinationTeamId: +destinationTeamId,
          previousUserId: null,
          action: 'Add',
        },
      });
    });
  }, [methods.handleSubmit]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit}>
        <SelectDestinationTeam
          label={`Select team to which you want to assign ${fullName}`}
          variant="filled"
          showHelperText
        />
        <footer className={styles.footer}>
          <Button
            variant="text"
            className={styles.cancel}
            onClick={() => setModalContent({ state: MODAL_STATE.CLOSE })}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!methods.formState.isDirty || isUpdating}
          >
            Confirm
          </Button>
        </footer>
      </form>
    </FormProvider>
  );
};
