import React, { useState, useMemo, useCallback } from 'react';
import { NavigationConfig } from '@typings/common';

export interface BeforeStepActionComponentProps {
  state: boolean;
  goToStep: () => void;
}

export interface StepChangeInterceptorConfig {
  beforeGoToNextStepComponent?: ({
    state,
    goToStep,
  }: BeforeStepActionComponentProps) => JSX.Element | null;
  beforeGoToPreviousStepComponent?: ({
    state,
    goToStep,
  }: BeforeStepActionComponentProps) => JSX.Element | null;
  beforeGoToNextStepCallback?: (goToNextStep: () => void) => Promise<void>;
  beforeGoToPreviousStepCallback?: (goToNextStep: () => void) => Promise<void>;
}

export type StepsChangeInterceptorConfig = Record<
  string,
  StepChangeInterceptorConfig
>;

export interface UseStepChangeInterceptorProps {
  config?: StepsChangeInterceptorConfig;
  currentStep?: NavigationConfig;
  goToNextStep?: () => void;
  goToPreviousStep?: () => void;
}

export const useStepChangeInterceptor = ({
  config,
  currentStep,
  goToNextStep,
  goToPreviousStep,
}: UseStepChangeInterceptorProps) => {
  const [
    beforeGoToNextStepComponentState,
    setBeforeGoToNextStepComponentState,
  ] = useState(false);
  const [
    beforeGoToPreviousStepComponentState,
    setBeforeGoToPreviousStepComponentState,
  ] = useState(false);
  const stepConfig = config?.[currentStep?.value ?? ''];

  const stepChangeInterceptorRenderer = useMemo(
    () => (
      <>
        {stepConfig?.beforeGoToNextStepComponent && (
          <stepConfig.beforeGoToNextStepComponent
            state={beforeGoToNextStepComponentState}
            goToStep={() => {
              goToNextStep?.();
              setBeforeGoToNextStepComponentState(false);
            }}
          />
        )}
        {stepConfig?.beforeGoToPreviousStepComponent && (
          <stepConfig.beforeGoToPreviousStepComponent
            state={beforeGoToPreviousStepComponentState}
            goToStep={() => {
              goToPreviousStep?.();
              setBeforeGoToPreviousStepComponentState(false);
            }}
          />
        )}
      </>
    ),
    [
      beforeGoToNextStepComponentState,
      beforeGoToPreviousStepComponentState,
      goToNextStep,
      goToPreviousStep,
      stepConfig,
    ]
  );

  const goToNextStepHandler = useCallback(async () => {
    if (stepConfig?.beforeGoToNextStepCallback && goToNextStep) {
      await stepConfig.beforeGoToNextStepCallback(goToNextStep);
    }
    if (stepConfig?.beforeGoToNextStepComponent) {
      setBeforeGoToNextStepComponentState(true);
    } else {
      goToNextStep?.();
    }
  }, [stepConfig, setBeforeGoToNextStepComponentState, goToNextStep]);

  const goToPreviousStepHandler = useCallback(async () => {
    if (stepConfig?.beforeGoToPreviousStepCallback && goToNextStep) {
      await stepConfig.beforeGoToPreviousStepCallback(goToNextStep);
    }
    if (stepConfig?.beforeGoToPreviousStepComponent) {
      setBeforeGoToPreviousStepComponentState(true);
    } else {
      goToPreviousStep?.();
    }
  }, [stepConfig, setBeforeGoToPreviousStepComponentState, goToPreviousStep]);

  return {
    goToNextStepHandler: goToNextStep && goToNextStepHandler,
    goToPreviousStepHandler: goToPreviousStep && goToPreviousStepHandler,
    stepChangeInterceptorRenderer,
  };
};
