import React from 'react';
// MUI
import { Chip } from '@mui/material';
// types
import {
  ApproveMyLoanStatus,
  ActiveLoanStatus,
} from '@pages/personalLoans/types/LoanTypes.type';
// constants
import { STATUS_LIST, COLOR_LIST } from './LoanStatusChip.constants';
// styles
import styles from './LoanStatusChip.module.scss';

export const LoanStatusChip = ({
  status,
}: {
  status: ApproveMyLoanStatus | ActiveLoanStatus;
}): JSX.Element => {
  // *nota: This component in the next sprint will get the Label and color from back
  const { label = status, color = COLOR_LIST.primary } =
    STATUS_LIST[status] ?? {};

  return (
    <span className={styles.container}>
      <Chip
        size="small"
        label={label}
        color={color}
        className={styles.chipModifier}
        disabled={status === ActiveLoanStatus.Archived}
      />
    </span>
  );
};
