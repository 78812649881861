import { Step } from '@components/verticalStepDetail/VerticalStepDetail.component';
import { LolaBffApiContractsModelsLoanPartyInfo } from '@api/output/api';

export enum CREDIT_SCORE_RESULT {
  POOR = 'poor',
  FAIR = 'fair',
  GOOD = 'good',
  EXCELLENT = 'excellent',
}

export enum CREDIT_SCORE_TASK_STATUS {
  AUTHORIZATION = 'credit_report.authorization',
  REISSUE_GENERATION = 'reissue_credit_report.generation',
  PAYMENT = 'credit_report.payment',
  GENERATION = 'credit_report.generation',
  RESULT = 'credit_report.result',
}

export interface CreditScoreItem {
  id: string;
  name: string;
  description?: string;
  score: number;
  hasGeneratedDocument: boolean;
  steps: Step[];
}

export interface CreditScoreIndividual
  extends LolaBffApiContractsModelsLoanPartyInfo {
  description: string;
}
