import React from 'react';
import { Typography } from '@mui/material';
import { formatAmountToCurrency } from '@utils/currency.utils';
import { Scenario, ScenarioProps } from './ScenarioRentalCalculation.types';

export const createAmountComponent =
  (key: keyof Omit<Scenario, 'preferred' | 'included'>) =>
  ({ scenario }: ScenarioProps) =>
    (
      <Typography>
        ${formatAmountToCurrency(scenario[key] as string | number)}
      </Typography>
    );

export const createCashToComponent =
  (key: keyof Omit<Scenario, 'preferred' | 'included'>) =>
  ({ scenario }: ScenarioProps) => {
    const amount = scenario[key] as string | number;
    const formattedAmount = amount < 0 ? +amount * -1 : amount;

    return <Typography>${formatAmountToCurrency(formattedAmount)}</Typography>;
  };
