import React, { useState } from 'react';
import { ClickAwayListener, MenuItem } from '@mui/material';
import { HandleSortChange, SorterOption } from '@lola/ui-react-components';
import { Menu } from '@components/Menu';
import { ControlPanelLabel } from '../index';

interface DataSorterProps {
  label: string;
  options: SorterOption[];
  handleSortChange: HandleSortChange;
  currentSorting?: string;
}

export const DataSorter = ({
  label,
  options,
  currentSorting = '',
  handleSortChange,
}: DataSorterProps) => {
  const [isPanelVisible, setIsPanelVisible] = useState(false);

  const openPanel = () => setIsPanelVisible(true);

  const handleClickAway = () => setIsPanelVisible(false);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Menu
          trigger={
            <ControlPanelLabel
              label={label}
              isActive={isPanelVisible}
              onClick={openPanel}
              labelPostfix={currentSorting}
            />
          }
        >
          {options.map((option) => (
            <MenuItem
              key={option.title}
              onClick={() => handleSortChange(option)}
            >
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </ClickAwayListener>
  );
};
