import React, { ChangeEvent, useCallback } from 'react';
import { IconButton, TextField, Typography, Grid } from '@mui/material';
import MaskedInput from 'react-text-mask';
import classnames from 'classnames';
import { AddIcon, MinusIcon } from '@lola/ui-react-components';
import styles from './numberStepInput.module.scss';
import { ButtonAction } from './NumberStepInput.types';

const SEPARATORS = ['.', ','];
const POSITION_MODIFIER = 1;

const convertToNumber = (value: string) => {
  return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
};

const convertToMap = (value: string) =>
  value.split('').map((char) => {
    if (/\d/.test(char)) return /\d/;
    if (SEPARATORS.includes(char)) return char;
    return '';
  });

interface NumberStepInputProps {
  fractionLength?: number;
  maskSymbol: string;
  maximum: number;
  minimum: number;
  value: number;
  onChange: (value: number) => void;
  name?: string;
  step?: number;
  disabled?: boolean;
  defaultValue?: string | number;
  helperText?: string;
  label?: string;
  white?: boolean;
}

export const NumberStepInput = ({
  value,
  minimum,
  maximum,
  maskSymbol,
  step = 1,
  fractionLength = 1,
  name,
  onChange,
  disabled,
  defaultValue,
  helperText,
  label,
  white,
}: NumberStepInputProps) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const numValue = convertToNumber(e.target.value);
      onChange(numValue);
    },
    [onChange]
  );

  const isButtonDisabled = useCallback(
    (action: ButtonAction): boolean => {
      if (disabled) return true;

      const effectiveStep = step ?? 1;

      switch (action) {
        case ButtonAction.Decrease:
          return value - effectiveStep < minimum;
        case ButtonAction.Increase:
          return value + effectiveStep > maximum;
        default:
          throw new Error(`Unhandled button action: ${action}`);
      }
    },
    [value, step, minimum, maximum, disabled]
  );

  const createMask = useCallback(
    (value: string) => {
      const normalizedValue = value.replace(/[^0-9|.,]/g, '');
      const separatorPosition = normalizedValue
        .split('')
        .findIndex((char) => SEPARATORS.includes(char));

      const newValue = normalizedValue.slice(
        0,
        separatorPosition < 0
          ? normalizedValue.length
          : separatorPosition + fractionLength + POSITION_MODIFIER
      );
      const number = convertToNumber(newValue);

      if (number > maximum) {
        const maximumMask = convertToMap(`${maximum}`);
        return new RegExp(`${maximum}`).test(newValue)
          ? [...maximumMask, maskSymbol]
          : [...maximumMask.slice(0, -1), maskSymbol];
      }

      const mask = convertToMap(newValue);
      return [...mask, maskSymbol];
    },
    [fractionLength, maximum]
  );

  const handleDecrease = useCallback(() => {
    const number = value - step;
    const result = number <= minimum ? minimum : number;
    onChange(result);
  }, [onChange, value, minimum, step]);

  const handleIncrease = useCallback(() => {
    const number = value + step;
    const result = number >= maximum ? maximum : number;
    onChange(result);
  }, [onChange, value, maximum, step]);

  return (
    <Grid container spacing={1} className={styles.inputStepWrapper}>
      {label && (
        <Grid item xs={12}>
          <Typography variant="p" className={styles.label}>
            {label}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <div
          className={classnames(styles.inputStep, {
            [styles.white]: white,
          })}
        >
          <IconButton
            disableRipple={true}
            onClick={handleDecrease}
            disabled={isButtonDisabled(ButtonAction.Decrease)}
          >
            <MinusIcon />
          </IconButton>
          <MaskedInput
            defaultValue={defaultValue}
            mask={createMask}
            guide={true}
            value={value}
            onChange={handleChange}
            name={name}
            disabled={disabled}
            render={(ref, props) => <TextField inputRef={ref} {...props} />}
          />
          <IconButton
            disableRipple={true}
            onClick={handleIncrease}
            disabled={isButtonDisabled(ButtonAction.Increase)}
          >
            <AddIcon />
          </IconButton>
        </div>
      </Grid>

      {helperText && (
        <Grid item xs={12}>
          <Typography variant="pSmall" className={styles.helperText}>
            {helperText}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
