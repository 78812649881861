import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  downloadFile,
  getFilenameFromContentDisposition,
} from '@utils/file.utils';
import { selectAccessToken } from '@store/selectors/auth.selectors';
import { useOpenToast } from '@hooks/useOpenToast';
import { ApiError, convertCatchError } from '@utils/errors.utils';
import { DEFAULT_API_ERROR_TITLE } from '@constants/text.constants';

export const useFileBlobDownloader = () => {
  const token = useSelector(selectAccessToken);
  const [isLoading, setIsLoading] = useState(false);
  const openToast = useOpenToast();

  const downloadBlobFile = useCallback(
    async (url: string) => {
      try {
        if (!url) return;

        setIsLoading(true);
        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition
          ? getFilenameFromContentDisposition(contentDisposition)
          : 'Downloaded file';

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        setIsLoading(false);
        downloadFile(filename, blobUrl);
        URL.revokeObjectURL(blobUrl);
      } catch (err) {
        setIsLoading(false);
        const apiError = err as ApiError;
        const description = convertCatchError(apiError);
        openToast({
          isError: true,
          title: DEFAULT_API_ERROR_TITLE,
          description,
        });
      }
    },
    [token, openToast]
  );

  return {
    downloadBlobFile,
    isLoading,
  };
};
