import { AlertProps } from '@mui/material';
import { UploaderDrawerConfig } from '@components/UploadDrawer/uploaderDrawer.types';
import { DEFAULT_UPLOADER_DRAWER_CONFIG } from '@components/UploadDrawer/uploaderDrawer.constants';
import {
  DEFAULT_UPLOADER_CONFIG,
  UPLOADER_STATE,
} from '@components/Uploader/Uploader.constants';
import { UPLOAD_STATUS } from '@constants/uploader.constants';
import { UploadNotificationConfig } from '@components/Uploader/Uploader.types';

export const PORTFOLIO_EXCEL_UPLOADER_ALLOWED_EXTENSIONS = ['xlsx'];

export const PORTFOLIO_EXCEL_UPLOADER_CONFIG = {
  ...DEFAULT_UPLOADER_CONFIG,
  description: 'Accepted file types: XLSX',
  submitButtonText: 'Submit to review',
  textConfig: {
    ...DEFAULT_UPLOADER_CONFIG.textConfig,
    [UPLOADER_STATE.INIT]: 'Drop your documents here or',
    [UPLOADER_STATE.SUCCESS]: 'Documents were successfully uploaded',
  },
  rules: {
    ...DEFAULT_UPLOADER_CONFIG.rules,
    allowedExtensions: {
      rule: PORTFOLIO_EXCEL_UPLOADER_ALLOWED_EXTENSIONS,
      message: 'Wrong file extension',
    },
    maxFiles: 1,
  },
};

const DESCRIPTION =
  'It takes up to 48h to review your file. Once it is reviewed, you can proceed with loan completion.';

export const PORTFOLIO_EXCEL_UPLOADER_DRAWER_CONFIG: UploaderDrawerConfig = {
  ...DEFAULT_UPLOADER_DRAWER_CONFIG,
  title: 'Portfolio property',
  description: DESCRIPTION,
};

export const UPLOAD_NOTIFICATION_SEVERITY: Record<
  UPLOAD_STATUS,
  AlertProps['severity']
> = {
  [UPLOAD_STATUS.NOT_UPLOADED]: undefined,
  [UPLOAD_STATUS.UPLOADED]: undefined,
  [UPLOAD_STATUS.IN_REVIEW]: 'warning',
  [UPLOAD_STATUS.REOPENED]: 'warning',
  [UPLOAD_STATUS.APPROVED]: 'success',
  [UPLOAD_STATUS.DENIED]: 'error',
};

export const UPLOAD_NOTIFICATION_TITLE: Record<UPLOAD_STATUS, string> = {
  [UPLOAD_STATUS.NOT_UPLOADED]: '',
  [UPLOAD_STATUS.UPLOADED]: '',
  [UPLOAD_STATUS.IN_REVIEW]: 'Your documents will be reviewed manually',
  [UPLOAD_STATUS.REOPENED]: 'Your documents will be reviewed manually',
  [UPLOAD_STATUS.APPROVED]:
    'Documents are approved. You can proceed with a loan',
  [UPLOAD_STATUS.DENIED]: 'Document is not approved',
};

export const UPLOAD_NOTIFICATION_TEXT: Record<UPLOAD_STATUS, string> = {
  [UPLOAD_STATUS.NOT_UPLOADED]: '',
  [UPLOAD_STATUS.UPLOADED]: '',
  [UPLOAD_STATUS.IN_REVIEW]: DESCRIPTION,
  [UPLOAD_STATUS.REOPENED]: DESCRIPTION,
  [UPLOAD_STATUS.APPROVED]: '',
  [UPLOAD_STATUS.DENIED]:
    'Please update information in a file and re-upload it.',
};

export const UPLOAD_NOTIFICATION_CONFIG: UploadNotificationConfig = {
  severity: UPLOAD_NOTIFICATION_SEVERITY,
  title: UPLOAD_NOTIFICATION_TITLE,
  text: UPLOAD_NOTIFICATION_TEXT,
};
