import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  ChangeEvent,
} from 'react';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import Select, {
  components,
  GroupBase,
  MultiValue,
  OptionProps,
  MenuListProps,
  MenuProps,
  ControlProps,
} from 'react-select';
import {
  TextField,
  MenuItem,
  Checkbox,
  InputAdornment,
  IconButton,
  Button,
  FormHelperText,
  FormControl,
} from '@mui/material';
import classnames from 'classnames';
import {
  ChevronDownIcon,
  SearchIcon,
  Label,
  Input,
} from '@lola/ui-react-components';
import { Option } from '@typings/common';
import styles from './multiSelectSearch.module.scss';

const CustomOption = ({
  selectProps,
  ...props
}: OptionProps<Option, true, GroupBase<Option>>) => {
  const { data, isSelected, isFocused, innerRef, innerProps } = props;
  const { lastSelectedOption } = selectProps;
  const isLastSelected =
    lastSelectedOption && lastSelectedOption.value === data.value;

  return (
    <MenuItem
      component="div"
      selected={isFocused}
      ref={innerRef as React.ForwardedRef<HTMLDivElement>}
      onClick={innerProps.onClick}
      className={classnames(styles.option, {
        [styles.lastSelected]: isLastSelected,
      })}
      role="option"
    >
      <Checkbox checked={isSelected} color="primary" />
      {data.label}
    </MenuItem>
  );
};

const CustomMenuList = ({
  children,
  ...props
}: MenuListProps<Option, true, GroupBase<Option>>) => {
  const {
    selectProps: { onInputChange },
    setValue,
  } = props;
  const [searchValue, setSearchValue] = useState('');

  const handleClearClick = () => {
    setValue([], 'deselect-option');
    setSearchValue('');
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    onInputChange(e.target.value, {
      prevInputValue: '',
      action: 'input-change',
    });
  };

  return (
    <>
      <div className={styles.searchWrapper}>
        <TextField
          name="search"
          variant="standard"
          placeholder="Search"
          size="small"
          fullWidth
          onChange={handleSearchChange}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
          }}
          className={styles.search}
          value={searchValue}
          InputProps={{
            startAdornment: (
              <IconButton className={styles.searchIcon}>
                <SearchIcon />
              </IconButton>
            ),
            inputProps: {
              className: styles.searchInput,
            },
          }}
        />
      </div>
      <components.MenuList {...props} className={styles.optionsList}>
        <MenuItem value="" disabled style={{ display: 'none' }}>
          Select options
        </MenuItem>
        {children}
      </components.MenuList>
      <div className={styles.clearBtnWrapper}>
        <Button variant="outlined" size="small" onClick={handleClearClick}>
          Clear
        </Button>
      </div>
    </>
  );
};

const CustomMenu = ({ children, ...props }: MenuProps<Option>) => {
  return (
    <components.Menu {...props} className={styles.menuBlock}>
      {children}
    </components.Menu>
  );
};

const CustomControl = ({
  selectProps,
  getValue,
  menuIsOpen,
}: ControlProps<Option>) => {
  const {
    placeholder,
    helperText,
    label,
    error,
    required,
    isDisabled,
    optionalHelperText,
    setMenuOpen,
  } = selectProps;
  const selectedLength = (getValue() as Option[])?.length;
  const inputText =
    selectedLength === 0 ? placeholder : `${selectedLength} selected`;

  const handleControlClick = () => {
    setMenuOpen(!menuIsOpen);
  };

  const handleControlKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (isDisabled) return;

      if (
        (e.code === 'ArrowUp' ||
          e.code === 'ArrowDown' ||
          e.code === 'Enter') &&
        !menuIsOpen
      ) {
        setMenuOpen(true);
      }

      if (e.code === 'Escape') {
        setMenuOpen(false);
      }
    },
    [isDisabled, setMenuOpen, menuIsOpen]
  );

  return (
    <FormControl fullWidth={true} disabled={isDisabled}>
      <Label
        text={label ?? ''}
        error={error}
        required={required}
        optionalHelperText={optionalHelperText}
        className={styles.label}
        disabled={isDisabled}
      />
      <Input
        className={styles.controlInput}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={handleControlClick}
              className={classnames(styles.controlAdornment, {
                [styles.opened]: menuIsOpen,
              })}
            >
              <ChevronDownIcon className={styles.icon} />
            </InputAdornment>
          ),
        }}
        fullWidth
        value={inputText}
        onClick={handleControlClick}
        onKeyDown={handleControlKeyDown}
        contentEditable={false}
        disabled={isDisabled}
        error={error}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

const mapValuesToItems = (
  items: Option[],
  selectedItems: (string | number)[]
) => {
  const selectedItemSet = new Set(selectedItems);
  return items.filter((item) => selectedItemSet.has(item.value));
};

export interface MultiSelectSearchProps {
  options: Option[];
  selectedItems: string[];
  onChange: (newValue: (string | number)[]) => void;
  placeholder?: string;
  helperText?: string;
  optionalHelperText?: string;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  label?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const MultiSelectSearch = ({
  options: baseOptions,
  selectedItems,
  onChange,
  helperText,
  optionalHelperText,
  disabled,
  required,
  placeholder,
  label,
  error,
  onBlur,
}: MultiSelectSearchProps) => {
  const [tempSelectedOptions, setTempSelectedOptions] = useState<Option[]>([]);
  const initialOptions = useRef(baseOptions);
  const [options, setOptions] = useState(initialOptions.current);
  const [menuOpen, setMenuOpen] = useState(false);
  const [lastSelectedOption, setLastSelectedOption] = useState<Option | null>(
    null
  );
  const selectWrapperRef = useRef<HTMLDivElement | null>(null);

  const highlightSelectedOptions = useCallback(
    (newOptions: MultiValue<Option>) => {
      const newOptionsArray = [...newOptions] as Option[];
      const newOptionsSorted = newOptionsArray.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      const stateOptions =
        initialOptions.current?.filter((option) => {
          return !newOptionsSorted.find((newOpt) => {
            return newOpt === option;
          });
        }) ?? [];
      const updatedOptions = newOptionsSorted?.concat(stateOptions);

      setLastSelectedOption(
        newOptionsSorted[newOptionsSorted.length - 1] || null
      );
      setOptions(updatedOptions);
    },
    [initialOptions, setOptions]
  );

  const changeHandler = useCallback(
    (newOptions: MultiValue<Option>) => {
      setTempSelectedOptions(newOptions as Option[]);
    },
    [setTempSelectedOptions]
  );

  const onMenuCloseHandler = useCallback(() => {
    const tempSelectedValues = tempSelectedOptions.map(
      (option) => option.value
    );

    if (!isEqual(sortBy(selectedItems), sortBy(tempSelectedValues))) {
      onChange(tempSelectedValues);
    }
  }, [tempSelectedOptions, selectedItems, onChange]);

  useEffect(() => {
    const selectedOptions = mapValuesToItems(options, selectedItems);
    highlightSelectedOptions(selectedOptions);
    setTempSelectedOptions(selectedOptions);
  }, [selectedItems]);

  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (
        selectWrapperRef.current &&
        !selectWrapperRef.current.contains(e.target as Node)
      ) {
        setMenuOpen(false);
        onMenuCloseHandler();
      }
    };
    document.addEventListener('mousedown', clickOutside);
    return () => document.removeEventListener('mousedown', clickOutside);
  }, [onMenuCloseHandler, setMenuOpen]);

  return (
    <div ref={selectWrapperRef}>
      <Select
        components={{
          Option: CustomOption,
          MenuList: CustomMenuList,
          Menu: CustomMenu,
          Control: CustomControl,
        }}
        menuPlacement="auto"
        hideSelectedOptions={false}
        isClearable
        isMulti
        menuIsOpen={menuOpen}
        noOptionsMessage={() => 'No items found'}
        onChange={changeHandler}
        options={options}
        placeholder={placeholder}
        value={tempSelectedOptions}
        isDisabled={disabled}
        helperText={helperText}
        required={required}
        error={error}
        label={label}
        optionalHelperText={optionalHelperText}
        lastSelectedOption={lastSelectedOption}
        setMenuOpen={setMenuOpen}
        onBlur={onBlur}
      />
    </div>
  );
};
