import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';
import { ROLES } from '@typings/common';

export enum TAB_VALUE {
  PROCESSORS = 'processors',
  BROKERS = 'brokers',
}

interface TabConfig {
  value: TAB_VALUE;
  label: string;
}

export const DEFAULT_TABS: TabConfig[] = [
  {
    value: TAB_VALUE.PROCESSORS,
    label: 'Processors',
  },
  {
    value: TAB_VALUE.BROKERS,
    label: 'Loan officers',
  },
];

const BROKER_TABS: TabConfig[] = [
  {
    value: TAB_VALUE.PROCESSORS,
    label: 'Processors',
  },
];

export const TABS: Partial<Record<ROLES, TabConfig[]>> = {
  [ROLES.BROKER]: BROKER_TABS,
};

export interface DrawerState {
  isOpen: boolean;
  context?: LolaBffApiContractsModelsLoanLoanView;
}

export const MANAGE_ACCESS_FORM_NAME = 'manageAccess';
export const MANAGE_ACCESS_FORM = {
  PROCESSORS: `${MANAGE_ACCESS_FORM_NAME}.processors`,
  ACTION: 'action',
  NEW_PROCESSOR_ID: 'newProcessorId',
  NEW_BROKER_ID: `${MANAGE_ACCESS_FORM_NAME}.broker.newBrokerId`,
  CURRENT_BROKER_ID: `${MANAGE_ACCESS_FORM_NAME}.broker.currentBrokerId`,
  NEW_ASSIGNED_PROCESSOR_ID: `${MANAGE_ACCESS_FORM_NAME}.newAssignedProcessorId`,
  LOAN_ID: `${MANAGE_ACCESS_FORM_NAME}.loanId`,
};
