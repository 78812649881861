import React from 'react';
import { Button, Grid } from '@mui/material';
import { Modal } from '@components/Modal';
import styles from './fileViewer.module.scss';

interface FileViewerProps {
  title: string;
  open: boolean;
  handleClose?: () => void;
  fileUrl?: string;
}

export const FileViewer = ({
  title,
  open,
  handleClose,
  fileUrl,
}: FileViewerProps) => {
  return (
    <Modal title={title} isOpen={open} onClose={handleClose} width={1000}>
      <Grid container spacing={6}>
        <Grid item xs={12} className={styles.iframeWrapper}>
          <iframe src={fileUrl} className={styles.iframe} />
        </Grid>
        <Grid item xs={12} classes={{ root: styles.footer }}>
          <Button
            variant="contained"
            onClick={handleClose}
            classes={{ root: styles.button }}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
