import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const yupNativeSchema = yup
  .object({
    username: yup
      .string()
      .email('Incorrect username')
      .required('Username is required'),
    password: yup.string().required('Password is required'),
  })
  .required();

export const loginFormSchema = yupResolver(yupNativeSchema);
