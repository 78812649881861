import React from 'react';
import classNames from 'classnames';
import { Divider, Typography, List, Tooltip, IconButton } from '@mui/material';
import { InformationIcon } from '@lola/ui-react-components';
import {
  ProgressStep,
  ProgressStatusHeaderIcon,
  MemoizedProgressIndicator,
} from './components';
import { ProgressStepperItem, ProgressIndicatorType } from './types';
import styles from './progressStepper.module.scss';

export interface ProgressStepperProps {
  items: ProgressStepperItem[];
  headerTitle: string;
  activeItem?: string;
  locked?: boolean;
  headerIcon?: React.ReactNode;
  progressIndicatorType?: ProgressIndicatorType;
  startFolded?: boolean;
  notification?: React.ReactNode;
  tooltip?: string;
  iconPosition?: 'end' | 'afterTitle';
}

export const ProgressStepper = ({
  items,
  headerTitle,
  activeItem,
  locked,
  headerIcon,
  progressIndicatorType,
  startFolded,
  notification,
  tooltip,
  iconPosition = 'end',
}: ProgressStepperProps) => {
  const titleWrapperClasses = classNames(styles.headerTitleWrapper, {
    [styles.iconAfterText]: iconPosition === 'afterTitle',
    [styles.iconAtEnd]: iconPosition === 'end',
  });

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.locked]: locked,
      })}
    >
      <header className={styles.header}>
        <div className={titleWrapperClasses}>
          <Typography
            variant="h4"
            className={classNames(styles.headerTitle, {
              [styles.locked]: locked,
            })}
          >
            {headerTitle}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip}>
              <IconButton className={styles.tooltipWrapper}>
                <InformationIcon className={styles.infoIcon} />
              </IconButton>
            </Tooltip>
          )}
          {progressIndicatorType && (
            <ProgressStatusHeaderIcon
              headerIcon={headerIcon}
              locked={locked}
              items={items}
            />
          )}
        </div>
        {progressIndicatorType && (
          <div className={styles.progressStatus}>
            <MemoizedProgressIndicator
              items={items}
              progressIndicatorType={progressIndicatorType}
              locked={locked}
            />
          </div>
        )}
      </header>
      <Divider className={styles.divider} />
      {notification}
      <div className={styles.steps}>
        <List component="nav">
          {items.map((item) => (
            <ProgressStep
              key={item.id}
              activeItem={activeItem}
              indent={4}
              bold
              startFolded={startFolded}
              {...item}
            />
          ))}
        </List>
      </div>
    </div>
  );
};
