import { useMemo } from 'react';
import {
  LolaBffApiContractsModelsUserRole,
  useGetApiV1UsersQuery,
} from '@api/output/api';

interface UseLoadUsers {
  roles?: string[];
  skipFetch?: boolean;
}

export const useLoadUsers = ({
  roles = [],
  skipFetch = false,
}: UseLoadUsers) => {
  const { data, isLoading, isError } = useGetApiV1UsersQuery(
    {},
    {
      skip: skipFetch,
      refetchOnMountOrArgChange: true,
    }
  );

  const users = useMemo(
    () =>
      roles?.length
        ? data?.filter(({ role }) =>
            roles.includes(role as LolaBffApiContractsModelsUserRole)
          )
        : data,
    [data, roles]
  );

  return {
    users,
    isLoading,
    isError,
  };
};
