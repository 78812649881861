import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { LinearProgress } from '@mui/material';
import { FormConfigContext } from '@components/DynamicForm/contexts/FormConfig.context';
import { useOnChange } from '@pages/personalLoans/pages/buildMyLoan/hooks/useOnChange';
import { useLoanDetails } from '@pages/personalLoans/pages/buildMyLoan/hooks/useLoanDetails';
import { BuildMyLoanContainer } from '@pages/personalLoans/pages/buildMyLoan/components/BuildMyLoanContainer/BuildMyLoanContainer.component';
import { useOnRemoveParty } from '@pages/personalLoans/pages/buildMyLoan/hooks/useOnRemoveParty';
import { useOnAddParty } from '@pages/personalLoans/pages/buildMyLoan/hooks/useOnAddParty';
import { LoanDisableContext } from './BuildMyLoan.context';
import { useFormDirtyState } from './hooks/useFormDirtyState';
import { BuildMyLoanFormData } from './buildMyLoan.type';
import { isLoanDisabled } from './utils/loan.utils';

export const BuildMyLoan = () => {
  const [isInitial, setIsInitial] = useState(true);
  const { data, isLoading } = useLoanDetails();

  const methods = useForm<BuildMyLoanFormData>({
    values: data,
    mode: 'onBlur',
  });

  const {
    formState: { isDirty },
    trigger,
    reset,
    clearErrors,
  } = methods;

  const { hasFormModified, clearErrorState } = useFormDirtyState({
    data,
    isDirty,
    reset,
    clearErrors,
  });

  const changeHandler = useOnChange(methods);
  const removePartyHandler = useOnRemoveParty();
  const addPartyHandler = useOnAddParty();

  const milestone = data.loan.dealMetadata?.milestone;
  const loanDisabledState = isLoanDisabled(milestone);

  const context = useMemo(
    () => ({
      disabled: loanDisabledState,
      onBlur: changeHandler,
      onRemove: removePartyHandler,
      onAdd: addPartyHandler,
      clearErrorState,
    }),
    [changeHandler, removePartyHandler, milestone]
  );

  const loanDisableContextValue = useMemo(
    () => ({
      disabled: loanDisabledState,
    }),
    [milestone]
  );

  useEffect(() => {
    if (isInitial && !isLoading) {
      setIsInitial(false);
    }
  }, [isLoading]);

  if (isInitial && isLoading) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  return (
    <FormProvider {...methods}>
      <FormConfigContext.Provider value={context}>
        <LoanDisableContext.Provider value={loanDisableContextValue}>
          <BuildMyLoanContainer
            triggerValidation={trigger}
            isDirty={hasFormModified}
          />
        </LoanDisableContext.Provider>
      </FormConfigContext.Provider>
    </FormProvider>
  );
};
