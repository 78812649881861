import React, { useCallback, useContext } from 'react';
import { CheckboxControl } from '@lola/ui-react-components';
import { DynamicCheckboxProps } from '@form-configs/types';
import { useEventHandlerWrapper } from '@components/DynamicForm/hooks/useEventHandlerWrapper';
import { CommonEvent } from '@typings/common';
import { getCheckboxValue } from '@utils/form/checkbox.utils';
import { useFieldSpecificProps } from '@components/DynamicForm/hooks/useFieldSpecificProps';
import { FormConfigContext } from '../../contexts/FormConfig.context';

export const DynamicCheckbox = ({
  name,
  label,
  description,
  useYesNo,
  defaultValue,
}: DynamicCheckboxProps) => {
  const { disabled, onBlur } = useContext(FormConfigContext);
  const fieldSpecificProps = useFieldSpecificProps(name);

  const checkboxOnBlur = useCallback(
    (name: string, value: string | boolean) => {
      const storeValue = getCheckboxValue(useYesNo, !!value);
      onBlur?.(name, storeValue);
    },
    [useYesNo, onBlur]
  );

  const createChangeWrapper = useEventHandlerWrapper(
    name,
    onBlur && checkboxOnBlur,
    'checked'
  );

  const onChangeWrapper = useCallback(
    (e: CommonEvent) => {
      createChangeWrapper()(e);
    },
    [useYesNo, createChangeWrapper]
  );

  return (
    <CheckboxControl
      name={name.toString()}
      label={label}
      description={description}
      disabled={disabled}
      onChangeWrapper={onChangeWrapper}
      useYesNo={useYesNo}
      defaultValue={defaultValue}
      {...fieldSpecificProps}
    />
  );
};
