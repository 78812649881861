import React from 'react';
import { Paper } from '@mui/material';
import { TableColumnType } from 'antd';
import { DataTable } from '@lola/ui-react-components';
import {
  GetApiV1UsersApiResponse,
  LolaBffApiContractsModelsUser,
} from '@api/output/api';
import styles from './teamMembersList.module.scss';

export interface TeamMembersListProps {
  columns: TableColumnType<LolaBffApiContractsModelsUser>[];
  data?: GetApiV1UsersApiResponse;
}

export const TeamMembersList = ({ columns, data }: TeamMembersListProps) => {
  return (
    <Paper elevation={0} classes={{ root: styles.paper }}>
      <DataTable
        rowSelection={{
          type: 'checkbox',
        }}
        rowKey="email"
        scroll={{
          x: data?.length ? 1200 : undefined,
          y: 600,
        }}
        columns={columns}
        dataSource={data}
      />
    </Paper>
  );
};
