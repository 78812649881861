import { FileItem } from '@components/Uploader/Uploader.types';

const DEFAULT_FILENAME = 'Document';

export const getIndexedFilename = (filename: string, names: Set<string>) => {
  const parts = filename.split('.');
  const ext = parts.pop();
  const realName = parts.join('.');

  let result = filename;
  let modifier = 0;
  while (names.has(result)) {
    modifier++;
    result = `${realName}_${modifier}.${ext}`;
  }

  return result;
};

export const getFilesToUpload = (
  newFiles: FileItem[] = [],
  uploadedFiles: FileItem[] = []
) => {
  const uploadedNames = uploadedFiles.map(
    ({ fileName }) => fileName ?? DEFAULT_FILENAME
  );
  const uploadedNamesSet = new Set(uploadedNames);

  return newFiles.map((file) => {
    const fileName = getIndexedFilename(
      file.fileName ?? DEFAULT_FILENAME,
      uploadedNamesSet
    );

    let newFile = file.file;
    if (file.file) {
      newFile = new File([file.file], fileName, {
        type: file.file.type,
      });
    }

    return {
      ...file,
      fileName,
      file: newFile,
    };
  });
};
