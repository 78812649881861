import * as yup from 'yup';
import { THIRD_PARTY_BROKER_FIELDS } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';

export const createThirdPartyBrokerSchema = (prefix = '') => {
  return yup
    .object({
      [`${prefix}${THIRD_PARTY_BROKER_FIELDS.FIRST_NAME}`]: yup
        .string()
        .required(),
      [`${prefix}${THIRD_PARTY_BROKER_FIELDS.LAST_NAME}`]: yup
        .string()
        .required(),
      [`${prefix}${THIRD_PARTY_BROKER_FIELDS.NMLS}`]: yup.string().required(),
    })
    .required();
};
