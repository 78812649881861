import React, { useCallback } from 'react';
import { IconButton, MenuItem, Stack } from '@mui/material';
import {
  MoreVerticalIcon,
  ListIcon,
  DeleteIcon,
  CheckIcon,
  CloseIcon,
} from '@lola/ui-react-components';
import { Menu } from '@components/Menu';
import { LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse } from '@api/output/api';
import styles from './actionsMenu.module.scss';

type QuoteActionHandler = (
  quote: LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse
) => void;

export interface ActionsMenuProps {
  onOpenModal: QuoteActionHandler;
  quote: LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse;
  onCopyQuote: QuoteActionHandler;
  onStageQuote: QuoteActionHandler;
}

export const ActionsMenu = ({
  onOpenModal,
  quote,
  onCopyQuote,
  onStageQuote,
}: ActionsMenuProps) => {
  const openModalHandler = useCallback(() => {
    onOpenModal(quote);
  }, [quote]);

  const copyQuoteHandler = useCallback(() => {
    onCopyQuote(quote);
  }, [quote]);

  const stageQuoteHandler = useCallback(() => {
    onStageQuote(quote);
  }, [quote]);

  const { isQuoteStaged, isAttachedToLoan } = quote;
  const canBeStaged = !isAttachedToLoan && !isQuoteStaged;
  const canBeUnstaged = !isAttachedToLoan && isQuoteStaged;

  return (
    <Stack direction="row" justifyContent="center">
      <Menu
        trigger={
          <IconButton>
            <MoreVerticalIcon />
          </IconButton>
        }
      >
        <div className={styles.menu}>
          <MenuItem onClick={copyQuoteHandler}>
            <ListIcon /> Copy quote
          </MenuItem>
          {canBeStaged && (
            <MenuItem onClick={stageQuoteHandler}>
              <CheckIcon /> Stage Quote
            </MenuItem>
          )}
          {canBeUnstaged && (
            <MenuItem onClick={stageQuoteHandler}>
              <CloseIcon /> Unstage Quote
            </MenuItem>
          )}
          <MenuItem
            onClick={openModalHandler}
            disabled={quote.isSubmittedInLoan}
          >
            <DeleteIcon /> Delete quote
          </MenuItem>
        </div>
      </Menu>
    </Stack>
  );
};
