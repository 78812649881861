import React from 'react';
import { Collapsed } from '@components/Collapsed';
import { LolaBffApiContractsModelsLoanTaskInfo } from '@api/output/api';
import { UploadAppraisal } from '../UploadAppraisal/UploadAppraisal.component';

export interface AppraisalItemProps {
  label: string;
  tasks: LolaBffApiContractsModelsLoanTaskInfo[];
  uploadDrawerHandler: (
    task?: LolaBffApiContractsModelsLoanTaskInfo
  ) => () => void;
  disabled?: boolean;
  isSingle?: boolean;
}

export const AppraisalItem = ({
  label,
  tasks,
  uploadDrawerHandler,
  disabled,
  isSingle = false,
}: AppraisalItemProps) => {
  return isSingle ? (
    <UploadAppraisal uploadDrawerHandler={uploadDrawerHandler} tasks={tasks} />
  ) : (
    <Collapsed disabled={disabled} label={label} defaultExpanded>
      <UploadAppraisal
        uploadDrawerHandler={uploadDrawerHandler}
        tasks={tasks}
      />
    </Collapsed>
  );
};
