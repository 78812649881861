import React from 'react';

export const archiveReasons = [
  {
    value: 'Competitive offer/pricing',
    label: 'Competitive offer/pricing',
  },
  {
    value: 'Borrower Nonresponsive',
    label: 'Borrower Nonresponsive',
  },
  {
    value: 'Credit does not meet minimum requirement',
    label: 'Credit does not meet minimum requirement',
  },
  {
    value: 'Borrower Tier ineligible for deal type',
    label: 'Borrower Tier ineligible for deal type',
  },
  {
    value: 'Denied by Underwriting',
    label: 'Denied by Underwriting',
  },
  {
    value: 'Submitted by Mistake',
    label: 'Submitted by Mistake',
  },
  {
    value: 'Quote Misalignment',
    label: 'Quote Misalignment',
  },
];
