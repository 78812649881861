import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useParties } from '@pages/personalLoans/pages/buildMyLoan/hooks/useParties';
import { useEntityTasksBySection } from '@pages/personalLoans/pages/buildMyLoan/hooks/useEntityTasksBySection';
import { convertAddressToTitle } from '@utils/address.utils';
import {
  LolaBffApiContractsModelsLoanLoanProperty,
  LolaBffApiContractsModelsLoanTaskInfo,
} from '@api/output/api';
import { LoanPartyInfo, TASK_SECTIONS } from '@typings/common';

type WatchReturn = [LolaBffApiContractsModelsLoanLoanProperty[], LoanPartyInfo];

interface DocumentSection {
  id: string;
  title: string;
  entityId?: string | null;
  tasks?: LolaBffApiContractsModelsLoanTaskInfo[] | null;
}

export const useUploadDocuments = (
  section = TASK_SECTIONS.UPLOAD_DOCUMENTS
) => {
  const { loanId = '' } = useParams();
  const [tasks] = useEntityTasksBySection(section);
  const [properties, entity]: WatchReturn = useWatch({
    name: ['properties', 'party.entity'],
  });
  const { parties } = useParties();

  const partiesDocuments: DocumentSection[] | null = useMemo(() => {
    return parties.map((party) => ({
      id: `party-${party.id}`,
      entityId: party.id,
      title: `${party.relationRole} (${party.fullName})`,
      tasks: party.id ? tasks[party.id] : null,
    }));
  }, [parties, tasks]);

  const entityDocuments: DocumentSection | null = useMemo(() => {
    if (!entity) {
      return null;
    }

    return {
      id: `entity-${entity.id}`,
      entityId: entity.id,
      title: `Entity (${entity.fullName})`,
      tasks: entity.id ? tasks[entity.id] : null,
    };
  }, [entity, tasks]);

  const loanDocuments: DocumentSection | null = useMemo(() => {
    return {
      id: `loan-${loanId}`,
      entityId: loanId,
      title: 'Loan documents',
      tasks: loanId ? tasks[loanId] : null,
    };
  }, [loanId, tasks]);

  const propertiesDocuments: DocumentSection[] | null = useMemo(() => {
    return properties?.map((property, index) => ({
      id: `property-${property.id}`,
      entityId: property.id,
      title: `Property #${index + 1}. ${convertAddressToTitle(
        property.address
      )}`,
      tasks: property.id ? tasks[property.id] : null,
    }));
  }, [properties, tasks]);

  return useMemo(() => {
    let result: DocumentSection[] = [];

    if (entityDocuments) {
      result.push(entityDocuments);
    }

    if (partiesDocuments) {
      result = result.concat(partiesDocuments);
    }

    if (loanDocuments) {
      result.push(loanDocuments);
    }

    if (propertiesDocuments) {
      result = result.concat(propertiesDocuments);
    }

    return result;
  }, [partiesDocuments, propertiesDocuments, entityDocuments, loanDocuments]);
};
