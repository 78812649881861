import React from 'react';
import { Divider } from '@mui/material';

interface CalculationBlockDividerProps {
  my?: number;
}

export const CalculationBlockDivider = ({
  my = 0,
}: CalculationBlockDividerProps) => {
  return (
    <Divider
      sx={{
        my,
        width: '100%',
      }}
    />
  );
};
