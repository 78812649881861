import { matchPath, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { NavigationConfig } from '@typings/common';

const ROUTES_WITHOUT_TABS = ['/profile'];

export const useActiveRoute = (
  currentRoutes: NavigationConfig[]
): [string, number] => {
  const { pathname = '/' } = useLocation();

  return useMemo(() => {
    let matched = '/';
    let index = -1;

    if (ROUTES_WITHOUT_TABS.includes(pathname)) {
      return ['', index];
    }

    for (let i = 0; i < currentRoutes.length; i++) {
      const { value } = currentRoutes[i];
      const isMatched = matchPath(
        {
          path: value,
          end: false,
        },
        pathname
      );

      if (isMatched && value.length > matched.length) {
        matched = value;
        index = i;
      }
    }

    return [matched, index];
  }, [pathname, currentRoutes]);
};
