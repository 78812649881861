import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { LinearProgress } from '@mui/material';
import { LabeledCardCollapsed } from '@components/LabeledCardCollapsed/LabeledCardCollapsed.component';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { convertToOptions } from '@utils/options.utils';
import { useLoadMembers } from '@pages/teamManagement/hooks/useLoadMembers';
import { ROLES } from '@typings/common';
import { MANAGE_ACCESS_FORM } from '../../ManageAccessDrawer.constants';

const ALLOWED_ROLES = [
  ROLES.KEY_BROKER_MANAGER,
  ROLES.BROKER_MANAGER,
  ROLES.BROKER,
];

interface BrokersProps {
  currentBrokerId: string;
  name: string;
  email: string;
}

export const Brokers = ({ currentBrokerId, name, email }: BrokersProps) => {
  const { setValue } = useFormContext();
  const { members, isLoading } = useLoadMembers();
  useEffect(() => {
    setValue(MANAGE_ACCESS_FORM.CURRENT_BROKER_ID, currentBrokerId);
  }, []);

  const options = useMemo(() => {
    return members
      ? convertToOptions({
          input: members.filter(
            ({ role, status }) =>
              ALLOWED_ROLES.includes(role as ROLES) && status === 'Active'
          ),
          idKey: 'userId',
          valueKeys: ['fullName', 'email'],
        })
      : [];
  }, [members]);

  if (isLoading) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  return name ? (
    <LabeledCardCollapsed label={name} description={email}>
      <SelectControl
        name={MANAGE_ACCESS_FORM.NEW_BROKER_ID}
        label="Select a loan officer whom you would like to assign this loan to"
        options={options}
        placeholder="Choose member"
      />
    </LabeledCardCollapsed>
  ) : null;
};
