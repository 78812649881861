import React, { Children, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
type TabPanelProps = {
  active: boolean;
  children: ReactNode;
};

export const TabPanel = ({ active, children }: TabPanelProps) => {
  const id = uuidv4();

  return (
    <div
      role="tabpanel"
      aria-hidden={!active}
      id={id}
      aria-labelledby={`${id}-label`}
      hidden={!active}
    >
      {Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement, { active })
      )}
    </div>
  );
};
