import React, { PropsWithChildren } from 'react';
import { IconButton } from '@mui/material';
import { QuestionIcon } from '@lola/ui-react-components';
import { Menu } from '@components/Menu';
import styles from './actionTooltip.module.scss';

export const ActionTooltip = ({ children }: PropsWithChildren) => {
  return (
    <Menu
      trigger={
        <IconButton className={styles.question}>
          <QuestionIcon />
        </IconButton>
      }
    >
      {children}
    </Menu>
  );
};
