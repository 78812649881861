import { useMemo } from 'react';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import {
  LolaBffApiContractsModelsLoanDocumentTask,
  LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto,
} from '@api/output/api';
import { useCreditTasks } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/hooks/useCreditTasks';
import { getIndividualConfig } from '../../pages/creditReport/components/CreditScore/utils/creditScore.utils';
import { useStepStatus } from '../useStepStatus';
import { usePartiesIds } from '../usePartiesIds';
import { useTaskPolling } from '../useTaskPolling';
import {
  COMPLETION_STATUS,
  CREDIT_REPORT_TASK,
  DEFAULT_POLLING_INTERVAL,
  NORMAL_POLLING_INTERVAL,
} from './useBuildMyLoan.constants';
import { isAllRequiredStepsDone } from './useBuildMyLoan.utils';

const CREDIT_REPORT: ProgressStepperItem = {
  id: '/personal-loans/:id/build-my-loan/credit-report',
  to: 'credit-report',
  title: 'Credit report (optional)',
};

const isAllPartiesHasCreditScoreResult = (
  ids: string[],
  taskNames: string[],
  lolaTasks?: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto[],
  reissueTasks?: LolaBffApiContractsModelsLoanDocumentTask[],
  reportTasks?: LolaBffApiContractsModelsLoanDocumentTask[]
) => {
  const idsSet = new Set(ids);

  lolaTasks?.forEach((lolaTask) => {
    const { task } = getIndividualConfig(
      reissueTasks,
      reportTasks,
      lolaTask.entity2Id ?? ''
    );

    const documents = task?.task?.documents;

    if (
      !!documents?.length ||
      (idsSet.has(lolaTask.entity2Id ?? '') &&
        taskNames?.includes(lolaTask.taskType ?? '') &&
        lolaTask.completionStatus === COMPLETION_STATUS)
    ) {
      idsSet.delete(lolaTask.entity2Id ?? '');
    }
  });

  return idsSet.size === 0;
};

export const useStepCreditReport = (
  requiredSteps: ProgressStepItemStatus,
  isDynamic = false
) => {
  const ids = usePartiesIds();
  const [reissueTasks, reportTasks] = useCreditTasks();
  const lolaTasks = useTaskPolling({
    shouldRefetchInit: isAllRequiredStepsDone([requiredSteps]) && isDynamic,
    config: [
      {
        taskNames: [CREDIT_REPORT_TASK.PAYMENT],
        interval: DEFAULT_POLLING_INTERVAL,
      },
      {
        taskNames: [CREDIT_REPORT_TASK.RESULT],
        interval: NORMAL_POLLING_INTERVAL,
      },
    ],
    partyIds: ids,
  });

  const isRequiredTasksDone = useMemo(
    () =>
      isAllPartiesHasCreditScoreResult(
        ids,
        [CREDIT_REPORT_TASK.RESULT],
        lolaTasks,
        reissueTasks,
        reportTasks
      ),
    [ids, lolaTasks, reissueTasks, reportTasks]
  );

  const status = useStepStatus({
    requiredStepsStatus: [requiredSteps],

    /* TODO: Remove after Reissue fixes, mark as completed by default*/
    isCompleted: true,
  });

  return useMemo(
    () => ({
      ...CREDIT_REPORT,
      status,
    }),
    [status]
  );
};
