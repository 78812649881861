import React from 'react';
import classnames from 'classnames';
import { Typography } from '@mui/material';
import { UploaderConfig } from '../../Uploader.types';
import { UPLOADER_STATE } from '../../Uploader.constants';

import styles from './uploaderHeader.module.scss';

interface UploaderHeaderProps {
  state: UPLOADER_STATE;
  iconConfig?: UploaderConfig['iconConfig'];
  textConfig?: UploaderConfig['textConfig'];
}

export const UploaderHeader = ({
  state,
  iconConfig,
  textConfig,
}: UploaderHeaderProps) => {
  const iconClasses = classnames({
    [styles.icon]: true,
    [styles.disabled]: state === UPLOADER_STATE.LOADING,
    [styles.error]: state === UPLOADER_STATE.ERROR,
  });

  const textClasses = classnames({
    [styles.text]: true,
    [styles.error]: state === UPLOADER_STATE.ERROR,
  });

  return (
    <>
      {iconConfig && (
        <div className={iconClasses}>
          {iconConfig[state] || iconConfig[UPLOADER_STATE.INIT]}
        </div>
      )}
      {textConfig && (
        <Typography className={textClasses}>
          {textConfig[state] || textConfig[UPLOADER_STATE.INIT]}
        </Typography>
      )}
    </>
  );
};
