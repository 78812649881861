import React, { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import styles from './formFooter.module.scss';

export const FormFooter = ({ children }: PropsWithChildren) => {
  return (
    <Stack
      component="footer"
      direction="row"
      justifyContent="flex-end"
      spacing={3}
      className={styles.footer}
    >
      {children}
    </Stack>
  );
};
