import { Option } from '@typings/common';
import { LolaBffApiContractsModelsTeam } from '@api/output/api';

export const convertTeamsResponseToOptions = (
  response: LolaBffApiContractsModelsTeam[] = []
): Option[] => {
  return response.map(({ id }) => ({
    value: id || -1,
    label: `Team ${id}`,
  }));
};
