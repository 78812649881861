import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTableDataSearchAndFilter } from '@lola/ui-react-components';
import {
  LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse,
  useLazyGetApiV1PricingEngineQuotesQuery,
} from '@api/output/api';
import { QUOTE_TYPE } from '@typings/common';
import { QUOTE_PRODUCT_KEY } from '@pages/personalLoans/pages/buildMyLoan/pages/product/productPage.constants';
import { useCreatedByFilter } from '@hooks/useCreatedByFilter';
import { useRole } from '@hooks/useRole';

export const useProgramQuotes = (quoteType: QUOTE_TYPE) => {
  const role = useRole();
  const [getQuotes, { data, isFetching, isError }] =
    useLazyGetApiV1PricingEngineQuotesQuery();
  const [currentLoanProgram = ''] = useWatch({
    name: [QUOTE_PRODUCT_KEY],
  });

  const {
    filteredData,
    handleSearchChange,
    handleFilterChange,
    appliedFilters,
    filters,
  } =
    useTableDataSearchAndFilter<LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse>(
      data ?? [],
      {},
      'name',
      ''
    );
  const { selectedQuotesCreators, setSelectedQuotesCreators, updatedFilters } =
    useCreatedByFilter(role, filters);

  useEffect(() => {
    getQuotes({
      portalUserIds: selectedQuotesCreators,
      includeSubmitted: false,
    });
  }, [getQuotes, selectedQuotesCreators]);

  const quotes = useMemo(() => {
    if (!filteredData) {
      return [];
    }

    return filteredData.filter((item) => {
      if (quoteType === QUOTE_TYPE.RENTAL) {
        return item.quoteType === 'Rental';
      } else {
        return (
          item.quoteType === 'STF' && item.productType === currentLoanProgram
        );
      }
    }, []);
  }, [filteredData, quoteType, currentLoanProgram]);

  const loadQuotes = useCallback(() => {
    getQuotes({
      portalUserIds: selectedQuotesCreators,
      includeSubmitted: false,
    });
  }, [getQuotes, selectedQuotesCreators]);

  return {
    loadQuotes,
    quotes,
    filters: updatedFilters,
    filteredData,
    handleSearchChange,
    handleFilterChange,
    appliedFilters,
    isFetching,
    isError,
    setSelectedQuotesCreators,
  };
};
