import React from 'react';
//MUI
import { Chip, Grid } from '@mui/material';
//Libraries
import classnames from 'classnames';
//Components
import { ModeSwitcher } from '@components/ModeSwitcher';
//Constants
import {
  MODE_SWITCHER_OPTIONS,
  MODE_SWITCHER_VALUES,
} from '../../ManageConditions.constants';
//Styles
import styles from './filterButtons.module.scss';

export interface FilterButtonsProps {
  buttons: string[];
  activeButton?: string | null;
  onClick: (arg: string) => void;
  mode: string | number;
  setMode: (v: string | number | MODE_SWITCHER_VALUES) => void;
}

export const FilterButtons = ({
  buttons,
  activeButton,
  onClick,
  mode,
  setMode,
}: FilterButtonsProps) => {
  return (
    <Grid className={styles.filterButtons}>
      <Grid>
        {buttons &&
          buttons.length > 0 &&
          buttons.map((info) => {
            return (
              <Chip
                data-testid="filterButton"
                key={`filterButton-${info}`}
                label={info}
                size="small"
                color="info"
                variant="outlined"
                onClick={() => onClick(info)}
                className={classnames(styles.filterButtonsChip, {
                  [styles.chipActive]: activeButton === info,
                })}
              />
            );
          })}
      </Grid>
      <ModeSwitcher
        value={mode}
        onSelect={(value) => setMode(value as MODE_SWITCHER_VALUES)}
        options={MODE_SWITCHER_OPTIONS}
      />
    </Grid>
  );
};
