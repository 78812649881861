import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { CardSection } from '@lola/ui-react-components';
import { Collapsed } from '@components/Collapsed';
import { useUploadActiveTask } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUploadActiveTask';
import { useEntityTasksBySection } from '@pages/personalLoans/pages/buildMyLoan/hooks/useEntityTasksBySection';
import { BuildMyLoanUploaderDrawer } from '@pages/personalLoans/pages/buildMyLoan/components/BuildMyLoanUploaderDrawer/BuildMyLoanUploaderDrawer.component';
import { UploadDocumentsList } from '@pages/personalLoans/pages/buildMyLoan/components/UploadDocumentsList/UploadDocumentsList.component';
import { TASK_SECTIONS } from '@typings/common';
import {
  CONSTRUCTION_BUDGET_DEFAULT_DRAWER_CONFIG,
  CONSTRUCTION_BUDGET_DEFAULT_UPLOADER_CONFIG,
} from './constructionBudgetDocuments.constants';
import styles from './constructionBudgetDocuments.module.scss';

export const ConstructionBudgetDocuments = () => {
  const [, { raw }] = useEntityTasksBySection(
    TASK_SECTIONS.CONSTRUCTION_BUDGET
  );

  const {
    activeTask,
    uploadDrawerState,
    uploaderCloseHandler,
    uploadDrawerHandler,
    templates,
    templatesAreBeingFetched,
  } = useUploadActiveTask(TASK_SECTIONS.UPLOAD_DOCUMENTS);

  const drawerConfig = useMemo(() => {
    const taskName = activeTask?.taskName ?? '';

    return {
      ...CONSTRUCTION_BUDGET_DEFAULT_DRAWER_CONFIG,
      title: taskName,
    };
  }, [activeTask]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardSection
          title="Construction budget"
          description="You need to attach a list of documents for your construction project. Once we receive them, you can start the inspection process. An inspection needs to assess the feasibility of your plan by our experts."
        >
          <Collapsed label="Documents upload" defaultExpanded>
            <div className={styles.documentsListWrapper}>
              <UploadDocumentsList
                tasks={raw}
                uploadDrawerHandler={uploadDrawerHandler}
              />
            </div>
          </Collapsed>
        </CardSection>
      </Grid>
      <BuildMyLoanUploaderDrawer
        isOpen={uploadDrawerState}
        task={activeTask}
        onClose={uploaderCloseHandler}
        uploaderConfig={CONSTRUCTION_BUDGET_DEFAULT_UPLOADER_CONFIG}
        drawerConfig={drawerConfig}
        templates={templates}
        templatesAreBeingFetched={templatesAreBeingFetched}
      />
    </Grid>
  );
};
