import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, LinearProgress } from '@mui/material';
import { TextFieldProps } from '@form-configs/types';
import { Modal } from '@components/Modal';
import { useOpenState } from '@hooks/useOpenState';
import { usePartyApi } from '@pages/personalLoans/pages/buildMyLoan/pages/applicationAndEntityInformation/components/PartForm/components/SSNField/hooks/usePartyApi';
import { FormConfigContext } from '@components/DynamicForm/contexts/FormConfig.context';

import { useStatusToastsHandlers } from '@hooks/useStatusToastsHandlers';
import { AnyError } from '@utils/errors.utils';
import { getRoleByKey } from '@pages/personalLoans/pages/buildMyLoan/pages/applicationAndEntityInformation/hooks/usePartyAttach';
import { useUpdateBuildMyLoanCache } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateBuildMyLoanCache';
import { LoanPartyInfo, ROLE_TYPE } from '@typings/common';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { useLoanDetails } from '@pages/personalLoans/pages/buildMyLoan';
import { PARTY_SSN_NAME } from '../../partyForm.constants';
import { SSNControl, SSNRef } from './components/SSNControl.component';
import { MODAL_MODE, ModalContent } from './components/ModalContent.component';
import { ENTITY_TYPE, MASK_MAP } from './SSNField.constants';
import styles from './SSNField.module.scss';

export interface SSNFieldProps extends TextFieldProps {
  mode: ENTITY_TYPE;
}

export const SSNField = ({ label, name, mode }: SSNFieldProps) => {
  const mask = MASK_MAP[mode];
  const ssnRef = useRef<SSNRef>(null);
  const { onBlur } = useContext(FormConfigContext);
  const { disabled } = useContext(LoanDisableContext);
  const { errorHandler } = useStatusToastsHandlers();

  const partyKey = useMemo(() => {
    return name.replace(`.${PARTY_SSN_NAME}`, '');
  }, [name]);

  const {
    updatePartyPropertyCache,
    updatePartyCacheById,
    updatePartyCacheByIndex,
  } = useUpdateBuildMyLoanCache();
  const { refetchParties, arePartiesFetching } = useLoanDetails();

  const { watch, setValue } = useFormContext();
  const [possibleSSN, setPossibleSSN] = useState('');

  const [ssn, currentId, currentIndex] = watch([
    name,
    `${partyKey}.id`,
    `${partyKey}.index`,
  ]);
  const [isOpened, open, close] = useOpenState();
  const {
    check,
    limitedUser,
    getParty,
    attachParty,
    createParty,
    isSaving,
    isLoading,
  } = usePartyApi(possibleSSN, mode);

  const onSSNChange = useCallback(
    async (value: string) => {
      try {
        const existedUser = await check(value);
        const isUserTheSame = currentId === existedUser?.id;

        if (isUserTheSame && existedUser && currentId) {
          return;
        }

        if (currentId && !existedUser) {
          setValue(name, value);
          onBlur?.(name, value);
          return;
        }

        setPossibleSSN(value);
        open();
      } catch (e) {
        errorHandler(e as AnyError);
      }
    },
    [mask, name, currentId]
  );

  const cancelHandler = useCallback(() => {
    updatePartyPropertyCache(currentId, name, ssn);
    ssnRef.current?.reset();
    close();
  }, [ssn, close, name, currentId]);

  const onModalClose = useCallback(() => {
    cancelHandler();
    close();
  }, [close, cancelHandler]);

  const handleUpdateParty = useCallback(
    async (role: ROLE_TYPE, newParty: LoanPartyInfo | null | undefined) => {
      if (newParty) {
        if (currentId) {
          updatePartyCacheById(newParty, role, currentId);
        } else if (currentIndex) {
          updatePartyCacheByIndex(newParty, role, currentIndex);
        } else {
          await refetchParties();
        }
      }
    },
    [currentId, currentIndex]
  );

  const selectHandler = useCallback(async () => {
    try {
      const role = getRoleByKey(name);
      let newParty;

      if (limitedUser) {
        // If the current SSN already exists and the user agrees to use it.
        await attachParty(limitedUser?.id, currentId, role);
        newParty = await getParty();
      } else {
        // If the current SSN does not exist and the user agrees to create a new party.
        newParty = await createParty(name.includes('borrower'));
      }
      await handleUpdateParty(role, newParty);
      setValue(name, newParty?.[PARTY_SSN_NAME] ?? '');
    } catch (err) {
      console.error(err);
      errorHandler(
        limitedUser
          ? 'Unable to add party to the deal.'
          : 'Unable to create a new party.'
      );
      close();
    }
    close();
  }, [
    close,
    limitedUser,
    currentId,
    createParty,
    attachParty,
    getParty,
    name,
    handleUpdateParty,
  ]);

  return (
    <>
      <div className={styles.inputWrapper}>
        <SSNControl
          ref={ssnRef}
          name={name}
          label={label}
          mask={mask}
          disabled={isLoading || disabled}
          onSSNChange={onSSNChange}
        />
        {(isLoading || arePartiesFetching) && <LinearProgress />}
      </div>

      <Modal
        isLoading={isSaving || isLoading}
        title={limitedUser ? 'This client exists' : 'New client'}
        isOpen={isOpened}
        onClose={onModalClose}
      >
        <ModalContent
          ssn={possibleSSN}
          existedUserName={limitedUser?.displayName ?? ''}
          entityType={mode}
          mode={limitedUser ? MODAL_MODE.REPLACE : MODAL_MODE.CREATE}
        />
        <footer>
          <Button
            variant="outlined"
            onClick={cancelHandler}
            disabled={isSaving || isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={selectHandler}
            disabled={isSaving || isLoading}
          >
            OK
          </Button>
        </footer>
      </Modal>
    </>
  );
};
