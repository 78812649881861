import { useParams } from 'react-router-dom';
import { useGetApiV1LolaTaskStatusQuery } from '@api/output/api';

export const useLolaTasks = (skip?: boolean) => {
  const { loanId = '' } = useParams();
  const {
    data: tasks = [],
    refetch: refetchTasks,
    isFetching: areTasksFetching,
  } = useGetApiV1LolaTaskStatusQuery(
    {
      entity1Type: 'loan',
      entity1Id: loanId,
    },
    {
      skip,
    }
  );

  return {
    tasks,
    refetchTasks,
    areTasksFetching,
  };
};
