import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  CONFIGURED_TASKS,
  LoanPartyInfo,
  TASK_SECTIONS,
} from '@typings/common';
import { UploadFileItem } from '@components/Uploader/hooks/useFilesUploader';
import { getTaskInfoByEntityId } from '@pages/personalLoans/pages/buildMyLoan/utils/tasks.utils';
import {
  uploadIconButtonRendererUploader,
  uploadButtonRendererUploader,
  detailsIconButtonRendererUploader,
  detailsButtonRendererUploader,
} from '@components/FileUploader';
import { useTasksByName } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTasksByName';
import {
  useLazyGetApiV1LoansByLoanIdDocumentTasksQuery,
  useLazyGetApiV1LolaTaskStatusQuery,
  usePostApiV1LoansByLoanIdUploadBorrowerAuthorizationMutation,
} from '@api/output/api';
import { CustomFileUploader } from '@pages/personalLoans/pages/buildMyLoan/components/CustomFileUploader/CustomFileUploader.component';
import { ApiError, convertCatchError } from '@utils/errors.utils';
import { useOpenToast } from '@hooks/useOpenToast';
import { DEFAULT_API_ERROR_TITLE } from '@constants/text.constants';
import styles from './authorizationUpload.module.scss';
import {
  AUTHORIZATION_DRAWER_CONFIG,
  AUTHORIZATION_UPLOADER_CONFIG,
} from './AuthorizationUpload.constants';
export interface AuthorizationUploadProps {
  parties: LoanPartyInfo[];
  onUpload?: (file: UploadFileItem, party: LoanPartyInfo) => Promise<unknown>;
}

export const AuthorizationUpload = ({ parties }: AuthorizationUploadProps) => {
  const { loanId = '' } = useParams();
  const openToast = useOpenToast();
  const [reloadTasks] = useLazyGetApiV1LoansByLoanIdDocumentTasksQuery();
  const [uploadAuthorization] =
    usePostApiV1LoansByLoanIdUploadBorrowerAuthorizationMutation();
  const [getLolaTasks] = useLazyGetApiV1LolaTaskStatusQuery();

  const tasks = useTasksByName(
    TASK_SECTIONS.BORROWER_UPLOAD_DOCUMENTS,
    CONFIGURED_TASKS.AUTHORIZATION
  );

  const uploadOperationAsyncHandler = useCallback(
    (partyId?: string | null) => {
      return async (file: UploadFileItem) => {
        try {
          if (!partyId) return;

          await uploadAuthorization({
            loanId,
            partyId,
            body: file.body as unknown as { file: Blob },
          }).unwrap();

          await getLolaTasks({
            entity1Type: 'loan',
            entity1Id: loanId,
          }).unwrap();
        } catch (err) {
          const description = convertCatchError(err as ApiError);
          openToast({
            isError: true,
            title: DEFAULT_API_ERROR_TITLE,
            description,
          });
        }
      };
    },
    [getLolaTasks, uploadAuthorization, loanId]
  );

  const completeOperationAsyncHandler = useCallback(async () => {
    try {
      await reloadTasks({ loanId });
    } catch (err) {
      const description = convertCatchError(err as ApiError);
      openToast({
        isError: true,
        title: DEFAULT_API_ERROR_TITLE,
        description,
      });
    }
  }, [reloadTasks]);

  const onDeleteHandler = useCallback(async () => {
    try {
      await reloadTasks({ loanId });
    } catch (err) {
      const description = convertCatchError(err as ApiError);
      openToast({
        isError: true,
        title: DEFAULT_API_ERROR_TITLE,
        description,
      });
    }
  }, [reloadTasks]);

  return (
    <div className={styles.documentsListWrapper}>
      {parties.map((party) => (
        <div className={styles.fileUploader} key={party.id}>
          <CustomFileUploader
            title={`Authorization for ${party.firstName} ${party.lastName}`}
            placeholder="PDF less than 20mb"
            task={getTaskInfoByEntityId(tasks, party?.id)}
            uploaderConfig={AUTHORIZATION_UPLOADER_CONFIG}
            drawerConfig={AUTHORIZATION_DRAWER_CONFIG}
            buttonRendererIfFileExists={
              parties.length > 1
                ? detailsIconButtonRendererUploader
                : detailsButtonRendererUploader
            }
            buttonRendererIfFileDoesntExist={
              parties.length > 1
                ? uploadIconButtonRendererUploader
                : uploadButtonRendererUploader
            }
            uploadOperationAsync={uploadOperationAsyncHandler(party?.id)}
            completeOperationAsync={completeOperationAsyncHandler}
            onDelete={onDeleteHandler}
          />
        </div>
      ))}
    </div>
  );
};
