import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import {
  usePostApiV1TeamsMutation,
  LolaBffApiContractsModelsUser,
  LolaBffApiContractsModelsRequestsTeamAction,
} from '@api/output/api';
import { usePostUpdate } from '@hooks/usePostUpdate';
import { SetModalContent } from '@typings/common';
import {
  MODAL_STATE,
  ModalContentType,
} from '../../../../TeamMembers.constants';
import { SelectManager } from '../../../../../../components/SelectManager/SelectManager.component';
import { FormFooter } from '../FormFooter/FormFooter.component';
import { FormWithTeamSelection } from './components/FormWithTeamSelection/FormWithTeamSelection.component';

interface RemoveFromTeamProps {
  setModalContent: SetModalContent<ModalContentType>;
  context: LolaBffApiContractsModelsUser;
}

export const RemoveFromTeam = ({
  setModalContent,
  context,
}: RemoveFromTeamProps) => {
  const methods = useForm({
    defaultValues: {
      managerUserId: '',
      removeFromTeamId: '',
    },
  });

  const { managesTeams, fullName } = context;
  const hasManyTeams = managesTeams && managesTeams.length > 1;

  const managerLabel = useMemo(() => {
    if (hasManyTeams) {
      return `Select manager who will lead the team instead of ${
        fullName ?? ''
      }`;
    }

    return `Select manager who will lead Team ${
      managesTeams?.[0] ?? ''
    } instead of ${fullName ?? ''}`;
  }, [hasManyTeams, managesTeams, fullName]);

  const handleClose = useCallback(
    () => setModalContent({ state: MODAL_STATE.CLOSE }),
    []
  );

  const [remove, { isUpdating }] = usePostUpdate(
    usePostApiV1TeamsMutation,
    handleClose
  );

  const onFormSubmit = useMemo(() => {
    return methods.handleSubmit((values) => {
      const { managerUserId, removeFromTeamId } = values;
      remove({
        lolaBffApiContractsModelsRequestsManageTeamRequest: {
          sourceTeamId: hasManyTeams
            ? +removeFromTeamId
            : +(managesTeams?.[0] as string),
          userId: managerUserId,
          destinationTeamId: null,
          previousUserId: null,
          action: 'Remove' as LolaBffApiContractsModelsRequestsTeamAction,
        },
      });
    });
  }, [methods.handleSubmit]);

  const {
    formState: { isDirty },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit}>
        {hasManyTeams ? (
          <FormWithTeamSelection
            fullName={context.fullName ?? ''}
            teams={managesTeams}
          />
        ) : (
          <SelectManager label={managerLabel} selectVariant="filled" />
        )}

        <FormFooter>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!isDirty || isUpdating}
          >
            Confirm
          </Button>
        </FormFooter>
      </form>
    </FormProvider>
  );
};
