import { useCallback, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  usePostApiV1PaymentsStripeCreatePaymentLinkMutation,
  usePostApiV1PaymentsStripeSendPaymentLinksMutation,
} from '@api/output/api';
import { LoanPartyInfo } from '@typings/common';

export const usePayment = (
  parties: LoanPartyInfo[],
  productCode: string,
  refetchStatuses: () => void
) => {
  const { loanId = '' } = useParams();
  const [getPaymentLink, { isLoading: isGetPaymentLinkLoading }] =
    usePostApiV1PaymentsStripeCreatePaymentLinkMutation();
  const [sendPaymentLinksAction, { isLoading: isSendPaymentLinksLoading }] =
    usePostApiV1PaymentsStripeSendPaymentLinksMutation();
  const [errorPartyIds, setErrorPartyIds] = useState<string[]>([]);

  const allPartyIds = useMemo(() => {
    return parties.map((party) => party.id).filter(Boolean) as string[];
  }, [parties]);

  const payByLink = useCallback(async () => {
    try {
      const link = await getPaymentLink({
        lolaBffApiContractsModelsPaymentCreatePaymentLinkRequest: {
          dealId: loanId,
          productCode: productCode,
          partyIds: allPartyIds,
        },
      }).unwrap();

      if (typeof link === 'string') {
        window.open(link, '_blank');
      }
    } catch (e) {
      setErrorPartyIds(allPartyIds);
    }
  }, [parties, productCode, allPartyIds]);

  const sendPaymentLinks = useCallback(async () => {
    try {
      await sendPaymentLinksAction({
        lolaBffApiContractsModelsPaymentCreatePaymentLinkRequest: {
          dealId: loanId,
          productCode: productCode,
          partyIds: allPartyIds,
        },
      })
        .unwrap()
        .then(() => {
          refetchStatuses();
        });
    } catch (e) {
      setErrorPartyIds(allPartyIds);
    }
  }, [parties, productCode, allPartyIds]);

  return {
    payByLink,
    sendPaymentLinks,
    errorPartyIds,
    isLoading: isGetPaymentLinkLoading || isSendPaymentLinksLoading,
  };
};
