import React from 'react';
import { Paper, Grid, Typography } from '@mui/material';
import { DynamicFields } from '@components/DynamicForm';
import { useContactForm } from '@pages/personalLoans/pages/buildMyLoan/pages/application/hooks/useContactForm';
import { LOAN_PROGRAM } from '@typings/common';
import { APPLICATION_TYPE } from '../../Application.constants';
import styles from './ContactInfo.module.scss';

interface ContactInfoProps {
  applicationType: APPLICATION_TYPE;
  loanProgram: LOAN_PROGRAM;
  disabled?: boolean;
}

export const ContactInfo = ({
  applicationType,
  loanProgram,
  disabled,
}: ContactInfoProps) => {
  const fields = useContactForm(applicationType, loanProgram);

  return (
    <Paper elevation={2}>
      <DynamicFields fields={fields} disabled={disabled} />
      {applicationType === APPLICATION_TYPE.PORTFOLIO && (
        <Grid item xs={12} className={styles.tip}>
          <Typography variant="pDescription">
            Please provide your sales representative with all payoff contact
            information
          </Typography>
        </Grid>
      )}
    </Paper>
  );
};
