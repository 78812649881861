import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import get from 'lodash/get';
import set from 'lodash/set';
import {
  api,
  usePutApiV1PricingEngineQuoteByQuoteIdChangeStatusMutation,
} from '@api/output/api';
import { QUOTE_TYPE } from '@typings/common';

export const useStageQuote = (quoteId?: string, quoteType?: QUOTE_TYPE) => {
  const [stageQuote] =
    usePutApiV1PricingEngineQuoteByQuoteIdChangeStatusMutation();

  const dispatch = useDispatch();

  return useCallback(async () => {
    if (!quoteId) {
      return;
    }
    await stageQuote({
      quoteId: quoteId,
    });

    const getPath =
      quoteType === QUOTE_TYPE.SHORT_TERM
        ? 'getApiV1PricingEngineQuoteByQuoteIdStf'
        : 'getApiV1PricingEngineQuoteByQuoteIdRental';

    dispatch(
      api.util.updateQueryData(getPath, { quoteId }, (draft) => {
        const value = get(draft, 'quoteInfo.isQuoteStaged');
        set(draft, 'quoteInfo.isQuoteStaged', !value);
      }) as unknown as AnyAction
    );
  }, [quoteId, quoteType]);
};
