import { UploaderConfig } from '@components/Uploader/Uploader.types';
import { DEFAULT_UPLOADER_CONFIG } from '@components/Uploader/Uploader.constants';
import { DEFAULT_UPLOADER_DRAWER_CONFIG } from '@components/UploadDrawer/uploaderDrawer.constants';
import { UploaderDrawerConfig } from '@components/UploadDrawer/uploaderDrawer.types';

export const AUTHORIZATION_UPLOADER_CONFIG: UploaderConfig = {
  ...DEFAULT_UPLOADER_CONFIG,
};

export const AUTHORIZATION_DRAWER_CONFIG: UploaderDrawerConfig = {
  ...DEFAULT_UPLOADER_DRAWER_CONFIG,
  title: 'Authorization documents',
  description: 'Optional description of the document',
};
