import React, { useEffect } from 'react';
//MUI
import { Stack, Grid, Paper, Skeleton } from '@mui/material';
//Libraries
import classnames from 'classnames';
//Router
import { useParams } from 'react-router-dom';
//Components
import { ProgressStepper } from '@components/ProgressStepper/ProgressStepper.component';
//API
import {
  LolaBffApiContractsModelsLoanResponseLoanControlPanelResponse,
  useGetApiV1LoansControlPanelQuery,
} from '@api/output/api';
//Types
import {
  ProgressIndicatorType,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';

import { BuildMyLoanColumn } from './components/BuildMyLoanColumn/BuildMyLoanColumn.component';

// styles
import styles from './controlPanel.module.scss';

export const ControlPanel = () => {
  // Router
  const { loanId = '' } = useParams();

  // API
  const { isLoading, data, refetch } = useGetApiV1LoansControlPanelQuery({
    loanId,
  });

  useEffect(() => {
    if (!loanId) return;
    refetch();
  }, []);

  if (!data || isLoading) {
    return (
      <section className={styles.controlPanel}>
        <Stack
          direction={{ xs: 'row' }}
          sx={{ alignItems: 'flex-start' }}
          spacing={{ xs: 0 }}
          className={styles.controlPanelStack}
        >
          <Skeleton
            variant="rectangular"
            className={styles.controlPanelStackItem}
          />
          <Skeleton
            variant="rectangular"
            className={styles.controlPanelStackItem}
          />
          <Skeleton
            variant="rectangular"
            className={styles.controlPanelStackItem}
          />
        </Stack>
      </section>
    );
  }

  return (
    <section className={styles.controlPanel}>
      <Stack
        direction={{ xs: 'row' }}
        sx={{ alignItems: 'flex-start' }}
        spacing={{ xs: 0 }}
        className={styles.controlPanelStack}
      >
        <Paper
          elevation={0}
          data-testid="control-panel-column"
          className={classnames(
            styles.stepperWidget,
            styles.controlPanelStackItem
          )}
          variant="outlined"
        >
          <BuildMyLoanColumn />
        </Paper>

        {data?.map(
          (
            column: LolaBffApiContractsModelsLoanResponseLoanControlPanelResponse
          ) => {
            return (
              <Grid
                className={styles.controlPanelStackItem}
                key={column.stepName}
                data-testid="control-panel-column"
              >
                <Paper
                  elevation={0}
                  className={classnames({
                    [styles.stepperWidget]: true,
                    [styles.locked]: column.isDisabled,
                  })}
                  variant="outlined"
                >
                  <ProgressStepper
                    progressIndicatorType={ProgressIndicatorType.LINEAR}
                    items={column.steps as ProgressStepperItem[]}
                    headerTitle={column.stepName ?? ''}
                    startFolded={true}
                    locked={column.isDisabled}
                    iconPosition="afterTitle"
                    tooltip={
                      column.stepName === 'Approve my loan'
                        ? 'This section includes loans that have been submitted and are under review or have conditions to manage.'
                        : 'This section includes loans that have been approved but are not yet closed.'
                    }
                  />
                </Paper>
              </Grid>
            );
          }
        )}
      </Stack>
    </section>
  );
};
