import React from 'react';
//MUI
import { Grid } from '@mui/material';
//Types
import { LolaBffApiContractsModelsLoanLoanModel } from '@api/output/api';
//Components
import { DetailsList, MilestoneList } from './components';
import { DetailsListArchive } from './components/DetailsListArchive/DetailsListArchive.component';
import { MilestoneDatesList } from './components/MilestoneDatesList/MilestoneDatesList.component';

export interface DetailsPopulatedProps {
  loanDetails: LolaBffApiContractsModelsLoanLoanModel;
}

export const DetailsPopulated = ({ loanDetails }: DetailsPopulatedProps) => {
  return (
    <Grid container spacing={4}>
      {!loanDetails.canBeArchived ? (
        <>
          <Grid item xs={6}>
            <DetailsListArchive data={loanDetails} listTitle="Loan details" />
          </Grid>
          <Grid item xs={6}>
            <MilestoneDatesList data={loanDetails} listTitle="Key Milestones" />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6}>
            <DetailsList data={loanDetails} listTitle="Loan details" />
          </Grid>
          <Grid item xs={6}>
            <MilestoneList data={loanDetails} listTitle="Key Milestones" />
          </Grid>
        </>
      )}
    </Grid>
  );
};
