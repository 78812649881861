import React from 'react';
import { Typography } from '@mui/material';
import { LolaBffApiContractsModelsLicensesLicense } from '@api/output/api';

export const stateRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsLicensesLicense
) => {
  const { stateCode } = row;

  return <Typography variant="pBold">{stateCode}</Typography>;
};
