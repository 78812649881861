import { useEffect, useState } from 'react';
import { ProgressStepperItem, ProgressStepItemStatus } from '../types';

interface UseProgressStepperResults {
  totalSteps: number;
  completedSteps: number;
}

const countSteps = (
  items: ProgressStepperItem[],
  completedSteps = 0
): UseProgressStepperResults => {
  let totalSteps = 0;

  for (const item of items) {
    if (item.items) {
      const childCount = countSteps(item.items, completedSteps);
      totalSteps += childCount.totalSteps;
      completedSteps = childCount.completedSteps;
    } else {
      totalSteps++;

      if (item.status === ProgressStepItemStatus.DONE) {
        completedSteps++;
      }
    }
  }

  return {
    totalSteps,
    completedSteps,
  };
};

export const useProgressStepperStats = (
  items: ProgressStepperItem[]
): UseProgressStepperResults => {
  const [stepperStatus, setStepperStatus] = useState<UseProgressStepperResults>(
    {
      totalSteps: 0,
      completedSteps: 0,
    }
  );

  useEffect(() => {
    const { totalSteps, completedSteps } = countSteps(items);
    setStepperStatus({
      totalSteps,
      completedSteps,
    });
  }, [items]);

  return stepperStatus;
};
