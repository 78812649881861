import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Grid, Typography } from '@mui/material';
import { SelectRequiredUser } from '@containers/SelectRequiredUser/SelectRequiredUser.component';
import { ROLES, SetModalContent } from '@typings/common';
import { ModalFooter } from '@components/Menu';
import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';
import {
  ASSIGN_PROCESSOR_BULK_FORM,
  ASSIGN_PROCESSOR_BULK_FORM_NAME,
  MODAL_STATE,
  ModalContentType,
} from '../../../../Loans.constants';

import styles from './assignProcessorBulk.module.scss';

interface AssignProcessorBulkProps {
  setModalContent: SetModalContent<ModalContentType>;
}

export const AssignProcessorBulk = ({
  setModalContent,
}: AssignProcessorBulkProps) => {
  const { formState, resetField, getValues } = useFormContext();

  const selectedLoans: LolaBffApiContractsModelsLoanLoanView[] =
    getValues('selectedLoans') ?? [];

  const handleCancel = useCallback(() => {
    resetField(ASSIGN_PROCESSOR_BULK_FORM_NAME);
    setModalContent({ state: MODAL_STATE.CLOSE });
  }, [resetField, setModalContent]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>{`You selected ${selectedLoans.length} loans`}</Typography>
        <div className={styles.scrollableList}>
          {selectedLoans.map(({ id, addressLine }) => (
            <Typography key={id}>{addressLine}</Typography>
          ))}
        </div>
      </Grid>
      <Grid item xs={12}>
        <SelectRequiredUser
          name={ASSIGN_PROCESSOR_BULK_FORM.PROCESSOR_ID}
          roles={[ROLES.PROCESSOR]}
          variant="filled"
          label="Select a processor whom you would like to assign selected loans to"
          placeholder="Choose option"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          If you want to assign a different processor to each loan, please do
          this for each loan separately.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ModalFooter>
          <Button variant="text" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!formState.dirtyFields[ASSIGN_PROCESSOR_BULK_FORM_NAME]}
            onClick={() =>
              setModalContent({ state: MODAL_STATE.ASSIGN_PROCESSOR_CONFIRM })
            }
          >
            Confirm
          </Button>
        </ModalFooter>
      </Grid>
    </Grid>
  );
};
