import get from 'lodash/get';
import { LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest } from '@api/output/api';
import { formatCurrency } from '@utils/currency.utils';
import { convertToFixedNumber } from '@utils/numbers.utils';
import { FormData } from '@form-configs/types';
import { assertValue } from '@utils/assert.utils';
import { Quote } from '@typings/common';

export interface SectionConfig {
  title: string;
  items: ItemConfig[];
  description?: string;
}

export interface ItemConfig {
  key: string;
  title?: string;
  value?: string;
  format?: Format;
  titleRenderer?: (details: FormData) => string;
  valueRenderer?: (details: FormData) => string;
  itemRenderer?: (details: FormData, disabled?: boolean) => JSX.Element;
  customRenderer?: () => JSX.Element;
  decorations?: 'bold' | 'boldGray' | null;
}

interface CalculatedItem {
  title: string;
  value: string;
  key: string;
  decorations?: ItemConfig['decorations'];
  customRenderer?: () => JSX.Element;
}

export enum Format {
  Percent = 'percent',
  Currency = 'currency',
  AbsoluteCurrency = 'absoluteCurrency',
}

export interface CalculatedSection {
  title: string;
  description?: string;
  items: CalculatedItem[];
}

export type SectionsConfig = SectionConfig[];

export const getApiValue = (item: ItemConfig, details: Quote) => {
  let apiValue = item.value ? get(details, item.value) : undefined;

  if (assertValue(apiValue) && item.format === Format.Percent) {
    apiValue = `${convertToFixedNumber(apiValue)}%`;
  }

  if (assertValue(apiValue) && item.format === Format.Currency) {
    const value = convertToFixedNumber(apiValue);
    apiValue = formatCurrency(value);
  }

  if (assertValue(apiValue) && item.format === Format.AbsoluteCurrency) {
    const value = convertToFixedNumber(apiValue);
    const absValue = Math.abs(value);
    apiValue = formatCurrency(absValue);
  }

  return apiValue;
};

export const convertQuoteObjectToCalculationBlock = (
  details: FormData,
  sectionsConfig: SectionsConfig,
  disabled?: boolean
): CalculatedSection[] => {
  return sectionsConfig.map((section) => {
    return {
      ...section,
      items: section.items.map((item) => {
        const title = item.titleRenderer
          ? item.titleRenderer(details)
          : item.title;
        const value = item.valueRenderer
          ? item.valueRenderer(details)
          : getApiValue(item, details);

        const customRenderer = item.itemRenderer
          ? item.itemRenderer.bind(null, details, disabled)
          : undefined;

        return {
          title: title ?? '',
          value: value ?? '',
          customRenderer,
          key: item.key,
          decorations: item.decorations,
        };
      }),
    };
  });
};

export const getLoanPurposeRentalDefaultValues = (
  values?: LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest
): {
  conditions: {
    loanPurpose: {
      rpolt6: boolean;
      rpogt6: boolean;
      purchase: boolean;
    };
  };
} => {
  return {
    conditions: {
      loanPurpose: {
        rpolt6:
          Number(values?.appraisedVal1) > 0 || Number(values?.totalCosts1) > 0,
        rpogt6: Number(values?.appraisedVal3) > 0,
        purchase: Number(values?.purchasePrice) > 0,
      },
    },
  };
};
