import { useMemo } from 'react';
import { Option } from '@typings/common';

export const useAvailableOptions = (
  options: Option[],
  selectedOptions: Option['value'][],
  mode: 'include' | 'exclude' = 'exclude'
) => {
  return useMemo(
    () =>
      options.filter(({ value }) =>
        mode === 'exclude'
          ? !selectedOptions.includes(value)
          : selectedOptions.includes(value)
      ),
    [options, selectedOptions]
  );
};
