import React from 'react';
import { Alert, AlertColor, AlertTitle } from '@mui/material';
import {
  AlertIcon,
  StatusDoneIcon,
  StatusAlertIcon,
} from '@lola/ui-react-components';
import style from './messageArea.module.scss';

// types
export enum StatusState {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

type SetIcon =
  | StatusState.Success
  | StatusState.Info
  | StatusState.Error
  | StatusState.Warning;

export interface MessageAreaProps {
  messageStatus: AlertColor;
  title?: string;
  message?: string;
}

const getIcon = (type: SetIcon): JSX.Element => {
  const iconList = {
    [StatusState.Success]: <StatusDoneIcon />,
    [StatusState.Info]: <AlertIcon />,
    [StatusState.Error]: <StatusAlertIcon />,
    [StatusState.Warning]: <AlertIcon />,
  };

  return iconList[type] ?? iconList.warning;
};

export const MessageArea = ({
  messageStatus = 'success',
  title,
  message,
}: MessageAreaProps) => {
  const statusMessage =
    messageStatus?.charAt(0).toUpperCase() + messageStatus?.slice(1);
  const alertSeverity: AlertColor = messageStatus ?? 'success';

  return (
    <section className={style.alert}>
      <Alert
        severity={alertSeverity}
        icon={getIcon(StatusState[statusMessage as keyof typeof StatusState])}
      >
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </section>
  );
};
