import { createStatusRenderer } from '@utils/renderers/statusRenderer/createStatusRenderer';
import { STATUS_CONFIG } from '@config/status';
import { LICENSE_STATUS_TYPE } from '@typings/common';
import { expirationDateRenderer, stateRenderer } from './renderers';

const LICENSE_STATUS_CONFIG = {
  [LICENSE_STATUS_TYPE.EXPIRED]: STATUS_CONFIG.ERROR_FILLED,
  [LICENSE_STATUS_TYPE.ACTIVE]: STATUS_CONFIG.SUCCESS_FILLED,
  [LICENSE_STATUS_TYPE.EXPIRED_IN_30_DAYS]: STATUS_CONFIG.WARNING_FILLED,
};

export const COLUMNS = [
  {
    key: 'stateCode',
    dataIndex: 'stateCode',
    title: 'State',
    width: '5%',
    render: stateRenderer,
  },
  {
    key: 'licenseType',
    dataIndex: 'licenseType',
    title: 'License type',
    width: '5%',
  },
  {
    key: 'licenseNumber',
    dataIndex: 'licenseNumber',
    title: 'Number',
    width: '7%',
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: 'Status',
    width: '5%',
    render: createStatusRenderer<LICENSE_STATUS_TYPE>(LICENSE_STATUS_CONFIG),
  },
  {
    key: 'expirationDate',
    dataIndex: 'expirationDate',
    title: 'Expiration date',
    width: '5%',
    render: expirationDateRenderer,
  },
  {
    key: 'createdBy',
    dataIndex: 'createdBy',
    title: 'Created by',
    width: '10%',
  },
];
