import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import { IconButton, Tooltip } from '@mui/material';
import classnames from 'classnames';
import styles from './labelWithAction.module.scss';

export interface LabelWithActionProps extends PropsWithChildren {
  icon?: ReactNode;
  tooltip?: string;
  onClick?: () => void;
}

export const LabelWithAction = ({
  icon,
  onClick,
  children,
  tooltip,
}: LabelWithActionProps) => {
  const Wrapper = useMemo(() => {
    if (tooltip) {
      return ({ children }: { children: ReactElement }) => (
        <Tooltip title={tooltip}>{children}</Tooltip>
      );
    }

    return React.Fragment;
  }, [tooltip]);

  return (
    <span className={styles.removeLabel}>
      {children}
      {icon && (
        <Wrapper>
          <IconButton
            onClick={onClick}
            className={classnames({
              [styles.tooltipOnly]: !onClick && !!tooltip,
            })}
          >
            {icon}
          </IconButton>
        </Wrapper>
      )}
    </span>
  );
};
