import { ChipProps } from '@mui/material';
import { QUOTE_TYPE, QUOTE_TYPE_LABELS } from '@typings/common';
import { FieldProp } from '@components/DynamicForm/contexts/FieldsProps.context';
import { getFieldPropConfig } from '@utils/form/form.utils';
import { STATUS_CONFIG } from '@config/status';
import {
  SUMMARY_ITEM_KEYS,
  TitleConfig,
  StageModalConfig,
} from './QuoteForm.types';

export const LOAN_PRODUCT_KEY = 'loanSummary.loanProduct';

export const QUOTE_DETAILS_TYPE_OPTIONS = [
  {
    label: QUOTE_TYPE_LABELS[QUOTE_TYPE.SHORT_TERM],
    value: QUOTE_TYPE.SHORT_TERM,
  },
  {
    label: QUOTE_TYPE_LABELS[QUOTE_TYPE.RENTAL],
    value: QUOTE_TYPE.RENTAL,
  },
];

export const QUOTE_NAME = {
  [QUOTE_TYPE.SHORT_TERM]: 'Short Term Finance',
  [QUOTE_TYPE.RENTAL]: 'Rental',
};

export const SUMMARY_ITEMS = Object.values(SUMMARY_ITEM_KEYS);

export const SUMMARY_ITEM_TITLES: Record<SUMMARY_ITEM_KEYS, TitleConfig> = {
  [SUMMARY_ITEM_KEYS.CALCULATION_ERROR]: {
    singular: 'Calculation error',
    plural: 'Calculation errors',
  },
  [SUMMARY_ITEM_KEYS.APPLIED_OVERRIDE]: {
    singular: 'Calculation message',
    plural: 'Calculation messages',
  },
  [SUMMARY_ITEM_KEYS.VALIDATION_WARNING]: {
    singular: 'Validation warning',
    plural: 'Validation warnings',
  },
  [SUMMARY_ITEM_KEYS.APPLIED_RATE_LLPAS]: {
    singular: "Applied rate LLPA's",
    plural: "Applied rate LLPA's",
  },
  [SUMMARY_ITEM_KEYS.FEE_LLPAS]: {
    singular: "Fee LLPA's",
    plural: "Fee LLPA's",
    withoutCount: true,
  },
  [SUMMARY_ITEM_KEYS.APPLIED_RATE_LLPAS_FACTORED]: {
    singular: "Applied rate LLPA's",
    plural: "Applied rate LLPA's",
  },
  [SUMMARY_ITEM_KEYS.FEE_LLPAS_FACTORED]: {
    singular: "Fee LLPA's",
    plural: "Fee LLPA's",
    withoutCount: true,
  },
};

export enum ELIGIBILITY {
  ELIGIBLE = 'Eligible',
}

export const QUOTE_PATH_MAP: Record<string, QUOTE_TYPE> = {
  stf: QUOTE_TYPE.SHORT_TERM,
  rental: QUOTE_TYPE.RENTAL,
};

const LOCKED_PROP = [
  {
    propName: 'locked',
    value: true,
  },
];

const APPROVE_MY_LOAN_STF_LOCKED_FIELDS = [
  'quoteName',
  'borrowerInfo.primaryGuarantor',
  'borrowerInfo.secondaryGuarantor',
  'borrowerInfo.borrowingEntity',
  'borrowerInfo.qualifyingFICO',
  'borrowerInfo.combinedLiquidity',
  'borrowerInfo.usCitizen',
  'borrowerInfo.borrowerTier',
  'dealTerms.targetCloseDate',
  'dealTerms.timeCloseToPayoff',
  'dealTerms.lesserOfLotOrPurchase',
  'dealTerms.rehabConBudget',
  'dealTerms.afterCompletedVal',
  'dealTerms.existingDebtStatus',
  'dealTerms.originalPurchaseDate',
  'dealTerms.payoff',
  'dealTerms.numOfProperties',
  'loanSummary.loanProduct',
  'loanSummary.loanPurpose',
  'loanSummary.propertyAddress',
  'loanSummary.city',
  'loanSummary.state',
  'loanSummary.zip',
  'loanSummary.propertyType',
  'loanTerm.loanTerm',
];

const APPROVE_MY_LOAN_RENTAL_LOCKED_FIELDS = [
  'quoteName',
  'appraisedVal1',
  'creditScore',
  'annualTaxes',
  'propertiesNY',
  'state',
  'propertyAddress',
  'city',
  'zip',
  'annualInsurance',
  'refiL1cFixNFlip',
  'numOfProperties',
  'annualFloodInsurance',
  'borrowingEntity',
  'mortgagePayoffs',
  'appraisedVal3',
  'totalCosts1',
  'monthlyRent',
  'purchasePrice',
  'sreoPropertyNumber',
  'borrowerNetWorth',
  'annualHOA',
  'base.condo',
  'base.propertyVal',
  'base.fixToRent',
  'buyUpDownPercentage',
  'usCitizen',
  'seasonalRental',
  'conditions.loanPurpose.rpolt6',
  'conditions.loanPurpose.rpogt6',
  'conditions.loanPurpose.purchase',
];

export const APPROVE_MY_LOAN_LOCKED_FIELDS: Record<
  QUOTE_TYPE,
  Record<string, FieldProp[]>
> = {
  [QUOTE_TYPE.SHORT_TERM]: getFieldPropConfig(
    APPROVE_MY_LOAN_STF_LOCKED_FIELDS,
    LOCKED_PROP
  ),
  [QUOTE_TYPE.RENTAL]: getFieldPropConfig(
    APPROVE_MY_LOAN_RENTAL_LOCKED_FIELDS,
    LOCKED_PROP
  ),
};

export enum SUMMARY_QUOTE_STATUS {
  DRAFT = 'Draft Quote',
  STAGED = 'Staged Quote',
}

export const SUMMARY_QUOTE_STATUS_CONFIG: Record<
  SUMMARY_QUOTE_STATUS,
  ChipProps
> = {
  [SUMMARY_QUOTE_STATUS.DRAFT]: STATUS_CONFIG.INFO,
  [SUMMARY_QUOTE_STATUS.STAGED]: STATUS_CONFIG.INFO_FILLED,
};

export enum STAGE_MODAL_TYPE {
  INIT = 'init',
  STAGE = 'done',
  CLOSE = '',
  UNSTAGE = 'unstage',
}

export const STAGE_MODAL_TITLE: StageModalConfig<string> = {
  [STAGE_MODAL_TYPE.INIT]: 'Stage quote',
  [STAGE_MODAL_TYPE.STAGE]: 'Quote was staged',
  [STAGE_MODAL_TYPE.UNSTAGE]: 'Unstage quote',
};

export const STAGE_MODAL_CONTENT: StageModalConfig<string> = {
  [STAGE_MODAL_TYPE.INIT]:
    'Please confirm you want to stage this quote by clicking confirm below. All quotes must be staged prior to Starting a Loan, however, you should only click confirm if you believe this quote has a high likelihood of becoming a loan.',
  [STAGE_MODAL_TYPE.STAGE]:
    'We successfully staged this Quote. You may now Review the Quote or Start a loan with it.',
  [STAGE_MODAL_TYPE.UNSTAGE]: 'Are you sure you want to Unstage this Quote?',
};

export const STAGE_MODAL_SUBMIT: StageModalConfig<string> = {
  [STAGE_MODAL_TYPE.INIT]: 'Confirm',
  [STAGE_MODAL_TYPE.UNSTAGE]: 'Confirm',
  [STAGE_MODAL_TYPE.STAGE]: 'Start a loan',
};

export const STAGE_MODAL_CANCEL: StageModalConfig<string> = {
  [STAGE_MODAL_TYPE.STAGE]: 'Review the Quote',
};
