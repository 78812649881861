import { PARTY_TYPE } from '@typings/common';
import { ExtendedLoanPartyInfo } from './buildMyLoan.type';

export enum PAYMENT_STATUS_TYPE {
  NOT_PAID = 'Not paid',
  PAID = 'Paid',
  LINK_SENT = 'Waiting for payment',
  DENIED = 'Rejected',
  ERROR = 'Error',
}

export const THIRD_PARTY_BROKER_FIELDS = {
  FIRST_NAME: 'lola.thirdPartyBrokerFirstName',
  LAST_NAME: 'lola.thirdPartyBrokerLastName',
  NMLS: 'lola.thirdPartyBrokerNmlsId',
  EXPIRATION: 'lola.thirdPartyBrokerLicenseExpirationDate',
};

export const PROGRAM_PATH = 'aggregations.loanProgramType.thirdParty';
export const PROGRAM_KEY = `loan.${PROGRAM_PATH}`;

export const QUOTE_ID_AGGREGATION_PATH =
  'quote.aggregations.quoteId.thirdParty';
export const SAVED_QUOTE_ID_TO_LOAN = `loan.${QUOTE_ID_AGGREGATION_PATH}`;

export const REVIEW_AND_SUBMIT_CONSENT = 'reviewAndSubmitConsent';

export const DEFAULT_GUARANTOR_VALUE: ExtendedLoanPartyInfo = {
  id: undefined,
  firstName: '',
  lastName: '',
  middleName: '',
  fullName: '',
  displayName: '',
  phoneNumber: '',
  email: '',
  contactPointEmailValue: '',
  contactPointTelephoneValue: '',
  address: {
    addressLineText: '',
    stateName: '',
    stateCode: '',
    postalCode: '',
    addressUnitIdentifier: '',
    cityName: '',
    countyName: '',
    propertyBlockIdentifier: '',
    propertyLotIdentifier: '',
    propertyIdentifier: '',
    legalDescription: '',
    fullAddressName: '',
    propertySubdivisionIdentifier: '',
  },
  streetAddress: '',
  cityName: '',
  stateName: '',
  stateCode: '',
  zip: '',
  taxpayerIdentifierValue: '',
  taxpayerIdentifierValueLocal: '',
  birthDate: '',
  citizenshipCountry: '',
  businessRegistrationStates: [''],
  realEstateOperationType: [''],
  type: PARTY_TYPE.INDIVIDUAL,
  entityType: '',
  relationRoleType: [''],
  partyRelationRoles: [''],
  ownershipPercentage: '',
  liquidAssetTotalAmount: '',
  transUnionScore: '',
  experianScore: '',
  creditReportDate: '',
  equifaxScore: '',
  lastLoanDate: '',
  amountOfLoans: '',
  createdAt: '',
  modifiedAt: '',
  crmId: '',
  businessIncorporationStateName: '',
  businessIncorporationStateCode: '',
  fixAndFlipBridgePlusTier: '',
  newConstructionEligibilityIndicator: '',
  remainingLineOfCreditAmount: '',
  notes: '',
  partyMilestone: '',
  totalOutstandingLongTermLoanCount: '',
  totalOutstandingLongTermPrincipalAmount: '',
  estimatedCreditScore: '',
  convictionIndicator: '',
  outstandingLawsuitsIndicator: '',
  outstandingJudgmentsIndicator: '',
  bankruptcyIndicator: '',
  foreclosureIndicator: '',
  mortgageDelinquentIndicator: '',
  lola: null,
};
