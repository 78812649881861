import { ROLES } from '@typings/common';

export enum ACTIONS {
  NO_TEAM = 'noteam',
  CREATE_NEW = 'createnew',
  SELECT_EXISTING = 'selectexisting',
}

export const ActionsMap = {
  [ACTIONS.NO_TEAM]: {
    value: ACTIONS.NO_TEAM,
    name: 'Leave without a team',
  },
  [ACTIONS.SELECT_EXISTING]: {
    value: ACTIONS.SELECT_EXISTING,
    name: 'Select from the list',
  },
  [ACTIONS.CREATE_NEW]: {
    value: ACTIONS.CREATE_NEW,
    name: 'Create new',
  },
};

const getActionsForRole = (role: ROLES) => {
  switch (role) {
    case ROLES.KEY_BROKER_MANAGER:
    case ROLES.BROKER_MANAGER:
      return [ActionsMap[ACTIONS.NO_TEAM], ActionsMap[ACTIONS.CREATE_NEW]];
    case ROLES.BROKER:
      return [
        ActionsMap[ACTIONS.NO_TEAM],
        ActionsMap[ACTIONS.CREATE_NEW],
        ActionsMap[ACTIONS.SELECT_EXISTING],
      ];
    case ROLES.PROCESSOR:
    case ROLES.NOT_SET:
    default:
      return [];
  }
};

const createActionRoleMap = () => ({
  [ROLES.KEY_BROKER_MANAGER]: getActionsForRole(ROLES.KEY_BROKER_MANAGER),
  [ROLES.BROKER_MANAGER]: getActionsForRole(ROLES.BROKER_MANAGER),
  [ROLES.BROKER]: getActionsForRole(ROLES.BROKER),
  [ROLES.PROCESSOR]: getActionsForRole(ROLES.PROCESSOR),
  [ROLES.NOT_SET]: getActionsForRole(ROLES.NOT_SET),
});

export const ActionRoleMap = createActionRoleMap();
