import { Option } from '@typings/common';
import { createFilledArray } from '@utils/array.utils';

export enum LICENSE_TYPE {
  NMLS = 'NMLS',
  OTHER = 'Other',
}

export const LICENSE_OPTIONS: Option[] = [
  {
    label: 'NMLS',
    value: LICENSE_TYPE.NMLS,
  },
  {
    label: 'Other',
    value: LICENSE_TYPE.OTHER,
  },
];

const LICENSE_NUMBER_LENGTH = 15;

export const LICENSE_NUMBER_MASK = createFilledArray(
  LICENSE_NUMBER_LENGTH,
  /[^\W_]/
);

export const LICENSING_STATES = [
  'AZ',
  'CA',
  'ID',
  'MN',
  'NV',
  'NY',
  'OR',
  'UT',
];

export const CHECKBOX_CONFIRMATION_NAME = 'isInformationCorrect';
export const LICENSE_TYPE_NAME = 'licenseType';
