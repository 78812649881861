import React, { useMemo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, LinearProgress } from '@mui/material';
import {
  CheckboxControl,
  InputControl,
  DatePickerControl,
} from '@lola/ui-react-components';
import { generateMinDateValidator } from '@components/DynamicForm/utils/validators.utils';
import { ContentSectionFooter } from '@components/ContentSection';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { options } from '@form-configs/output';
import { useAvailableOptions } from '@hooks/useAvailableOptions';
import {
  LolaBffApiContractsModelsLicensesLicense,
  usePostApiV1LicensesMutation,
  usePutApiV1LicensesByLicenseIdMutation,
} from '@api/output/api';
import { ApiError } from '@utils/errors.utils';
import { useOpenToast } from '@hooks/useOpenToast';
import { LICENSING_DRAWER_TYPE } from '@pages/profile/pages/licensingInfo/LicensingInfo.costants';
import { FormData } from '@form-configs/types';
import { Option } from '@typings/common';
import {
  CHECKBOX_CONFIRMATION_NAME,
  LICENSE_NUMBER_MASK,
  LICENSE_OPTIONS,
  LICENSE_TYPE_NAME,
  LICENSING_STATES,
} from './LicenseUpdateDrawer.constants';
import styles from './licenseUpdateDrawer.module.scss';

interface LicenseUpdateDrawerProps {
  selectedStates: string[];
  drawerType: LICENSING_DRAWER_TYPE;
  currentLicense: LolaBffApiContractsModelsLicensesLicense | null;
  closeDrawer: () => void;
  getLicenses: () => void;
}

export const LicenseUpdateDrawer = ({
  selectedStates,
  closeDrawer,
  drawerType,
  getLicenses,
  currentLicense,
}: LicenseUpdateDrawerProps) => {
  const openToast = useOpenToast(3000);
  const [addLicense, { isLoading: isLoadingAddLicense }] =
    usePostApiV1LicensesMutation();
  const [updateLicense, { isLoading: isLoadingUpdateLicense }] =
    usePutApiV1LicensesByLicenseIdMutation();
  const methods = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      [CHECKBOX_CONFIRMATION_NAME]: false,
      [LICENSE_TYPE_NAME]: [],
    },
  });
  const confirmationCheckboxValue = methods.watch(CHECKBOX_CONFIRMATION_NAME);

  useEffect(() => {
    if (drawerType === LICENSING_DRAWER_TYPE.UPDATE && currentLicense) {
      methods.reset({
        ...currentLicense,
        [CHECKBOX_CONFIRMATION_NAME]: true,
      });
    }
  }, [methods, currentLicense, drawerType]);

  const minDate = useMemo(() => new Date(), []);

  const { minDate: validateMinDate } = useMemo(
    () => generateMinDateValidator([minDate]),
    [minDate]
  );

  const states = useAvailableOptions(
    options.states,
    LICENSING_STATES,
    'include'
  );
  const statesWithoutSelected = useAvailableOptions(states, selectedStates);

  const filteredStates = useMemo(
    () =>
      drawerType === LICENSING_DRAWER_TYPE.UPDATE
        ? ([
            ...statesWithoutSelected,
            states.find(
              ({ value }) =>
                currentLicense && value === currentLicense.stateCode
            ),
          ] as Option[])
        : statesWithoutSelected,
    [drawerType, states, statesWithoutSelected, currentLicense]
  );

  const submitHandler = useMemo(() => {
    return methods.handleSubmit(
      async ({ stateCode, licenseType, licenseNumber, expirationDate }) => {
        try {
          const commonValues = {
            stateCode,
            licenseType,
            licenseNumber,
            expirationDate,
          };

          if (drawerType === LICENSING_DRAWER_TYPE.UPDATE) {
            if (!currentLicense?.licenseId) return;

            await updateLicense({
              licenseId: currentLicense.licenseId,
              lolaBffApiContractsModelsLicensesUpdateLicenseRequest: {
                ...commonValues,
              },
            }).unwrap();
            openToast({
              title: 'Success',
              description: 'License has been updated',
            });
          } else if (drawerType === LICENSING_DRAWER_TYPE.ADD) {
            await addLicense({
              lolaBffApiContractsModelsLicensesCreateLicenseRequest:
                commonValues,
            }).unwrap();
            openToast({
              title: 'Success',
              description: 'License has been added',
            });
          }

          getLicenses();
          closeDrawer();
        } catch (err) {
          openToast({
            isError: true,
            title: (err as ApiError)?.data?.title ?? '',
          });
        }
      }
    );
  }, [
    methods.handleSubmit,
    getLicenses,
    closeDrawer,
    updateLicense,
    addLicense,
    drawerType,
  ]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={submitHandler} noValidate className={styles.form}>
        <section className={styles.section}>
          <LabeledCard>
            <Grid container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={6}>
                <SelectControl
                  name="stateCode"
                  label="Select state"
                  options={filteredStates}
                  required="State is required"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectControl
                  name={LICENSE_TYPE_NAME}
                  label="License type"
                  options={LICENSE_OPTIONS}
                  required="License type is required"
                />
              </Grid>
              <Grid item xs={6}>
                <InputControl
                  type="text"
                  name="licenseNumber"
                  label="License number"
                  mask={LICENSE_NUMBER_MASK}
                  required="License number is required"
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerControl
                  label="Expiration date"
                  name="expirationDate"
                  minDate={minDate}
                  required="Expiration date is required"
                  rules={{
                    validate: {
                      minDate: validateMinDate,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckboxControl
                  name={CHECKBOX_CONFIRMATION_NAME}
                  label="I attest that this information is correct"
                />
              </Grid>
            </Grid>
          </LabeledCard>
        </section>
        {(isLoadingAddLicense || isLoadingUpdateLicense) && <LinearProgress />}
        <ContentSectionFooter
          actions={{
            cancel: {
              onClick: closeDrawer,
            },
            submit: {
              label: 'Save',
              disabled:
                !methods.formState.isDirty || !confirmationCheckboxValue,
            },
          }}
        />
      </form>
    </FormProvider>
  );
};
