import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export const useDefaultRouteNavigate = (
  baseRoute: string,
  defaultRoute: string
) => {
  const { pathname = '' } = useLocation();
  return pathname === baseRoute ? <Navigate to={defaultRoute} replace /> : null;
};
