import React, { useMemo } from 'react';
import { IconButton } from '@mui/material';
import { ListIcon, UploadIcon } from '@lola/ui-react-components';

interface UploaderIconButtonComponentProps {
  isUploaded: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const UploaderIconButton = ({
  isUploaded,
  onClick,
  disabled = false,
}: UploaderIconButtonComponentProps) => {
  const icon = useMemo(() => {
    return isUploaded ? <ListIcon /> : <UploadIcon />;
  }, [isUploaded]);

  return (
    <IconButton onClick={onClick} disabled={disabled}>
      {icon}
    </IconButton>
  );
};
