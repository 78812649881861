import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { NavLink, useParams } from 'react-router-dom';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';

import styles from './attestation.module.scss';

export interface AttestationProps {
  title: string;
  description: string;
}

export const Attestation = ({ title, description }: AttestationProps) => {
  const { control } = useFormContext();
  const actualDate: string = new Date().toLocaleDateString('en-US');
  const { loanId = '' } = useParams();
  return (
    <LabeledCard label={title} className={styles.attestationContainer}>
      <Typography variant="p">{description}</Typography>
      <Grid className={styles.attestationFieldsContainer}>
        <Grid item>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field }) => {
              return (
                <TextField
                  variant="standard"
                  label="First name"
                  placeholder="Type here"
                  {...field}
                />
              );
            }}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item>
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field }) => {
              return (
                <TextField
                  variant="standard"
                  label="Last name"
                  placeholder="Type here"
                  {...field}
                />
              );
            }}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item>
          <Controller
            name="termsDate"
            control={control}
            defaultValue={actualDate}
            render={({ field }) => {
              return (
                <TextField
                  variant="standard"
                  label="Date"
                  placeholder={actualDate}
                  InputProps={{
                    readOnly: true,
                  }}
                  {...field}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <div className={styles.attestationCheck}>
        <Controller
          name="iConsent"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label={
                <Typography variant="p">
                  {'I consent to the terms and conditions outlined '}
                  <Link
                    component={NavLink}
                    to={
                      '/personal-loans/' +
                      loanId +
                      '/approve-my-loan/final-pricing-and-terms-sheet'
                    }
                    underline="always"
                    target="_blank"
                    variant="pBold"
                  >
                    here
                  </Link>
                </Typography>
              }
            />
          )}
          rules={{ required: true }}
        />
      </div>
    </LabeledCard>
  );
};
