import React from 'react';
import { IconButton, MenuItem } from '@mui/material';
import {
  MoreVerticalIcon,
  EditIcon,
  DeleteIcon,
} from '@lola/ui-react-components';
import { Menu } from '@components/Menu/Menu.component';
import styles from './licensingActions.module.scss';

interface LicensingActionsProps {
  openModal: () => void;
  openDrawer: () => void;
}

export const LicensingActions = ({
  openModal,
  openDrawer,
}: LicensingActionsProps) => {
  return (
    <Menu
      trigger={
        <IconButton>
          <MoreVerticalIcon />
        </IconButton>
      }
    >
      <MenuItem onClick={openDrawer}>
        <EditIcon /> Update license
      </MenuItem>
      <MenuItem onClick={openModal} classes={{ root: styles.delete }}>
        <DeleteIcon /> Delete license
      </MenuItem>
    </Menu>
  );
};
