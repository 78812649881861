import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { useOpenToast } from '@hooks/useOpenToast';
import { useQuotesCalculateApi } from '@hooks/useQuotesApi/useQuotesCalculateApi';
import { Quote, QUOTE_TYPE } from '@typings/common';
import { useQuotesUpdateApi } from '@hooks/useQuotesApi/useQuotesUpdateApi';
import { FormData } from '@form-configs/types';
import { getQuoteSummary } from '@hooks/useQuotesApi/utils/getQuoteSummary.utils';
import { SUMMARY_ITEM_KEYS } from '@pages/scenarioBuilder';
import { ApiError, convertCatchError } from '@utils/errors.utils';
import {
  api,
  GetApiV1PricingEngineQuoteByQuoteIdStfApiResponse,
} from '@api/output/api';
import { LeverageCalculationsDrawer } from '@components/QuoteCalculation/components/LeverageCalculations/components/LeverageCalculationsDrawer/LeverageCalculationsDrawer.component';
import { DEFAULT_API_ERROR_TITLE } from '@constants/text.constants';

export interface LeverageCalculationsProps {
  details?: Quote;
  closeModal: () => void;
}

export const LeverageCalculationControl = ({
  details,
  closeModal,
}: LeverageCalculationsProps) => {
  const dispatch = useDispatch();
  const openToast = useOpenToast();
  const [leverageDetails, setLeverageDetails] = useState<Quote | undefined>(
    details
  );
  const [calculationErrors, setCalculationErrors] = useState<string[]>([]);
  const [isCalculated, setIsCalculated] = useState(false);
  const [calculate, { isLoading: isCalculating }] = useQuotesCalculateApi(
    QUOTE_TYPE.SHORT_TERM
  );
  const [update, { isLoading: isSaving }] = useQuotesUpdateApi(
    QUOTE_TYPE.SHORT_TERM
  );

  const methods = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    setLeverageDetails(details);
    reset(details);
  }, [details, reset, setLeverageDetails]);

  const calculateHandler = useMemo(() => {
    return handleSubmit(async (values) => {
      try {
        const result = await calculate(values.in);
        if (result) {
          const summaryData = getQuoteSummary(
            result as Quote,
            QUOTE_TYPE.SHORT_TERM
          );
          const errors =
            summaryData?.[SUMMARY_ITEM_KEYS.CALCULATION_ERROR]?.items;
          if (errors) {
            setCalculationErrors(errors);
          } else {
            const newData = {
              ...values,
              out: result.out,
            };
            openToast({
              title: 'Quote calculated',
              description: 'The quote has been successfully calculated.',
            });
            setCalculationErrors([]);
            setIsCalculated(true);
            reset(newData);
            setLeverageDetails(newData);
          }
        }
      } catch (err) {
        const description = convertCatchError(err as ApiError);
        openToast({
          isError: true,
          title: DEFAULT_API_ERROR_TITLE,
          description,
        });
      }
    });
  }, [handleSubmit, reset, calculate, setCalculationErrors, setIsCalculated]);

  const updateHandler = useMemo(() => {
    return handleSubmit(async (values) => {
      try {
        const result = await update(values.quoteInfo.id, values.in);
        if (result) {
          const newData = {
            ...values,
            in: result.in,
            out: result.out,
          };
          openToast({
            title: 'Leverage calculations have been successfully updated',
            description:
              'New values for Leverage Calculation section have been successfully updated',
          });
          setIsCalculated(false);
          dispatch(
            api.util.updateQueryData(
              'getApiV1PricingEngineQuoteByQuoteIdStf',
              {
                quoteId: details?.quoteInfo?.id
                  ? details?.quoteInfo?.id.toString()
                  : '',
              },
              (draft: GetApiV1PricingEngineQuoteByQuoteIdStfApiResponse) => {
                Object.assign(draft, newData);
              }
            ) as unknown as AnyAction
          );
        }
      } catch (err) {
        const description = convertCatchError(err as ApiError);
        openToast({
          isError: true,
          title: DEFAULT_API_ERROR_TITLE,
          description,
        });
      }
    });
  }, [reset, handleSubmit, update]);

  return (
    <FormProvider {...methods}>
      <LeverageCalculationsDrawer
        open={true}
        onClose={closeModal}
        quoteDetails={details}
        leverageDetails={leverageDetails}
        calculateHandler={calculateHandler}
        isCalculating={isCalculating}
        isSaving={isSaving}
        isDirty={isDirty}
        isCalculated={isCalculated}
        calculationErrors={calculationErrors}
        updateHandler={updateHandler}
      />
    </FormProvider>
  );
};
