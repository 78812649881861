import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { LinearProgress } from '@mui/material';

import './pdfViewer.utils';

import styles from './documentPDFViewer.module.scss';

export interface DocumentPDFViewerProps {
  url: string;
}

export const DocumentPDFViewer = ({ url }: DocumentPDFViewerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [pages, setPages] = useState<undefined[]>([]);

  const onSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setPages(Array.from({ length: numPages }));
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);

  return (
    <>
      <div className={styles.helperPage} ref={ref}></div>
      <Document
        file={url}
        onLoadSuccess={onSuccess}
        loading={<LinearProgress data-cy="linear-progress" />}
      >
        {pages.map((_, index) => (
          <Page
            width={width}
            className={styles.page}
            key={`${url}-${index}`}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
          />
        ))}
      </Document>
    </>
  );
};
