import React from 'react';
//MUI
import { Typography, Stack } from '@mui/material';
//types
import { LolaBffApiContractsModelsLoanInactiveLoan } from '@api/output/api';
//styles
import styles from './loanDataRenderer.module.scss';

export const loanDataRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsLoanInactiveLoan
) => {
  const { name, borrower, productType, loanIdentifier } = row;

  return (
    <Stack direction={{ xs: 'column' }} spacing={{ xs: 1.5 }}>
      <div className={styles.loanInfo}>
        <Typography paragraph={true} variant="pBold" mb={0.5}>
          {name}
        </Typography>
        <Typography paragraph={true} variant="pSmall" mb={0.5}>
          {borrower ? borrower?.firstName + ' ' + borrower.lastName : ''}
          {' • '}
          {productType} # {loanIdentifier}
        </Typography>
      </div>
    </Stack>
  );
};
