import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import { TOOLTIPS } from '../../ScenarioRentalCalculation.constants';
import { ActionTooltip } from '../ActionTooltip/ActionTooltip.component';

import styles from './rowHead.module.scss';

interface RowHeadProps {
  title: string;
  dataIndex: string;
  className: string;
  titleComponent?: JSX.Element;
}

export const RowHead = ({
  title,
  titleComponent,
  dataIndex,
  className,
}: RowHeadProps) => {
  return (
    <div key={title} className={className}>
      {titleComponent}
      {!titleComponent && <Typography>{title}</Typography>}
      {TOOLTIPS[dataIndex] && (
        <>
          {' '}
          <ActionTooltip>
            <MenuItem className={styles.menuItem}>
              <Typography>{TOOLTIPS[dataIndex]}</Typography>
            </MenuItem>
          </ActionTooltip>
        </>
      )}
    </div>
  );
};
