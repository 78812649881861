export interface ErrorPoolItem {
  stringKey: string;
  fieldName: string;
  message: string;
}

export enum RE_ISSUE_MESSAGE {
  TRIMERGE = 'tri-merge',
  FIELDS = 'fields',
  DEFAULT = 'default',
}
