import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export type DynamicBaseQueryHandler = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
>;

export class DynamicBaseQuery {
  handler: DynamicBaseQueryHandler = fetchBaseQuery({ baseUrl: '/' });

  setHandler(handler: DynamicBaseQueryHandler) {
    this.handler = handler;
  }

  getHandler(): DynamicBaseQueryHandler {
    return async (...args) => {
      return this.handler(...args);
    };
  }
}
