import { CustomColors } from '../types';

export const THEME_ID = 'provider-theme-variables';

export const getCSSSection = (variables: CustomColors) => {
  let result = `body {\n`;
  result += Object.entries(variables)
    .map(([name, value]) => `--${name}: ${value};`)
    .join('\n');
  result += '\n}';
  return result;
};

export const updateCSSVariables = (variables: CustomColors) => {
  let styleElement = document.getElementById(THEME_ID);
  if (!styleElement) {
    styleElement = document.createElement('style');
    styleElement.id = THEME_ID;
    document.head.appendChild(styleElement);
  }

  styleElement.innerHTML = getCSSSection(variables);
};
