import React, { useContext, useMemo } from 'react';
import { ChipProps, Grid, Typography } from '@mui/material';
import { LolaBffApiContractsModelsLoanTaskInfo } from '@api/output/api';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { UploaderIconButton } from '@components/UploaderIconButton/UploaderIconButton.component';
import { UploaderStatusChip } from '@components/UploaderStatusChip/UploaderStatusChip.component';
import styles from './uploadDocumentsItem.module.scss';

export interface ConstructionBudgetDocumentComponentProps {
  task: LolaBffApiContractsModelsLoanTaskInfo;
  placeholder: string;
  uploadDrawerHandler: (
    task: LolaBffApiContractsModelsLoanTaskInfo
  ) => () => void;
}

export const CHIP_CONFIG: Record<string, ChipProps> = {
  SUCCESS: {
    label: 'Uploaded',
    color: 'success',
    variant: 'filled',
  },
  EMPTY: {
    label: 'Not uploaded',
    color: 'info',
    variant: 'outlined',
  },
};

export const UploadDocumentsItem = ({
  task,
  uploadDrawerHandler,
}: ConstructionBudgetDocumentComponentProps) => {
  const { disabled } = useContext(LoanDisableContext);
  const isUploaded = useMemo(() => {
    return !!task?.documents?.[0];
  }, [task]);

  return (
    <div className={styles.fileUploader}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={7}>
          <Typography variant="pBold">{task.taskName}</Typography>
          <br />
          <Typography variant="pDescription">PDF less than 20mb</Typography>
        </Grid>
        <Grid item xs={10} sm={4}>
          <UploaderStatusChip isUploaded={isUploaded} />
        </Grid>
        <Grid item xs={2} sm={1}>
          <UploaderIconButton
            isUploaded={isUploaded}
            onClick={uploadDrawerHandler(task)}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </div>
  );
};
