import { useMemo } from 'react';
import {
  useGetApiV1UsersMeQuery,
  useGetApiV1UsersQuery,
} from '@api/output/api';
import { Option, ROLES } from '@typings/common';
import { convertToOptions } from '@utils/options.utils';

export const useManagersList = () => {
  const { data: currentUser } = useGetApiV1UsersMeQuery();

  const { data } = useGetApiV1UsersQuery(
    {
      role: ROLES.BROKER_MANAGER,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const managers = useMemo(
    () =>
      convertToOptions({
        input: data ?? [],
        idKey: 'userId',
        valueKeys: ['fullName', 'email'],
      }),
    [data]
  );

  const currentUserOption: Option[] = useMemo(() => {
    if (!currentUser || currentUser.role !== ROLES.KEY_BROKER_MANAGER) {
      return [];
    }

    return [
      {
        label: `${currentUser.fullName} (${currentUser.email})`,
        value: currentUser.userId ?? '',
      },
    ];
  }, [currentUser]);

  return useMemo(() => {
    return currentUserOption.concat(managers);
  }, [currentUserOption, managers]);
};
