import React from 'react';
import { Grid } from '@mui/material';
import { useInspectionProgress } from '@pages/personalLoans/pages/buildMyLoan/pages/constructionBudget/hooks/useInspectionProgress';
import { useHasConstructionBudgetInspection } from '@pages/personalLoans/pages/buildMyLoan/hooks/constructionBudget/useHasInspection';
import {
  ConstructionBudgetDocuments,
  ConstructionInspection,
  ConstructionInspectionStatus,
} from './components';

export const ConstructionBudgetPage = () => {
  const hasInspection = useHasConstructionBudgetInspection();
  const { inspectionProgress, inspectionStatus } = useInspectionProgress();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ConstructionBudgetDocuments />
      </Grid>
      {hasInspection && (
        <>
          <Grid item xs={12}>
            <ConstructionInspection />
          </Grid>
          <Grid item xs={12}>
            <ConstructionInspectionStatus
              progress={inspectionProgress}
              status={inspectionStatus}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
