import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { FileAddIcon } from '@lola/ui-react-components';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { Option } from '@typings/common';
import { useAvailableProcessors } from '../../hooks';
import { MANAGE_ACCESS_FORM } from '../../../../ManageAccessDrawer.constants';

interface AssignFormProps {
  processors: Option[];
}

export const AssignForm = ({ processors }: AssignFormProps) => {
  const [isFormOpened, setIsFormOpened] = useState(false);
  const [newProcessorId = '', selectedProcessors = []] = useWatch({
    name: [
      MANAGE_ACCESS_FORM.NEW_ASSIGNED_PROCESSOR_ID,
      MANAGE_ACCESS_FORM.PROCESSORS,
    ],
  });

  const options = useAvailableProcessors({
    processors,
    selectedProcessors,
  });

  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          startIcon={<FileAddIcon />}
          onClick={() => setIsFormOpened(true)}
          disabled={!!newProcessorId}
        >
          Assign a new processor
        </Button>
      </Grid>
      {isFormOpened && (
        <Grid item xs={12}>
          <LabeledCard label="Assign new processor">
            <SelectControl
              name={MANAGE_ACCESS_FORM.NEW_ASSIGNED_PROCESSOR_ID}
              options={options}
              label="Select a processor whom you would like to assign this loan to"
            />
          </LabeledCard>
        </Grid>
      )}
    </>
  );
};
