import React from 'react';
import classnames from 'classnames';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  Typography,
} from '@mui/material';
import { Label } from '@lola/ui-react-components';
import { Option } from '@typings/common';

import styles from './radioGroup.module.scss';

export interface RadioGroupProps extends MuiRadioGroupProps {
  label?: string;
  options: Option[];
  ariaLabel?: string;
  value?: string;
  major?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  isLabelTruncated?: boolean;
}

export const RadioGroup = React.forwardRef<unknown, RadioGroupProps>(
  (
    {
      disabled = false,
      name,
      label,
      ariaLabel,
      options,
      value,
      major,
      className,
      required,
      error,
      isLabelTruncated,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl fullWidth>
        <Label
          text={label}
          required={required}
          isTruncated={isLabelTruncated}
          className={styles.label}
          disabled={disabled}
        />
        <MuiRadioGroup
          className={classnames(className, {
            [styles.majorGroup]: major,
            [styles.error]: error,
          })}
          aria-label={ariaLabel}
          value={value}
          name={name}
          ref={ref}
          {...props}
        >
          {options.map((option) => {
            const { value = '', label, description } = option;
            return (
              <div key={value}>
                <FormControlLabel
                  value={value}
                  label={label}
                  control={<Radio />}
                  disabled={disabled || option.disabled}
                  data-cy={`${value}-radioButton`.toLowerCase()}
                />
                {description && (
                  <Typography variant="pDescription" paragraph>
                    {description}
                  </Typography>
                )}
              </div>
            );
          })}
        </MuiRadioGroup>
      </FormControl>
    );
  }
);
