import React, { ForwardedRef, forwardRef } from 'react';
import { IconButton, Typography } from '@mui/material';
import { AlertIcon, CloseIcon, DoneFillIcon } from '@lola/ui-react-components';
import {
  ToastItem as StoreToastItem,
  ToastType,
} from '@store/slices/toast.slice';
import styles from './toastItem.module.scss';

export interface ToastItemProps extends StoreToastItem {
  onClose: (id: string) => void;
}

export const ToastItem = forwardRef(
  (
    { id, title, description, type, onClose }: ToastItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className={`${styles.card} ${styles[type]}`} ref={ref}>
        <div className={styles.status}>
          {type === ToastType.Error ? <AlertIcon /> : <DoneFillIcon />}
        </div>
        <div className={styles.text}>
          <Typography variant="pBold">{title}</Typography>
          <Typography variant="p" className={styles.description}>
            {description}
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton onClick={() => onClose(id)} title="Close">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    );
  }
);
