import { useEffect, useState } from 'react';
import { UPLOADER_STATE } from '../Uploader.constants';

const MULTIPLIER = 1.47;
const PROGRESS_STEP_INCREASE = 50;
const PROGRESS_STOP_LEVEL = 90;

export const useProgressValue = (state: UPLOADER_STATE) => {
  const [progress, setProgress] = useState(0);
  let divider = (Math.random() + 1) * MULTIPLIER;

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;
    if (state === UPLOADER_STATE.LOADING) {
      timerId = setInterval(() => {
        divider *= MULTIPLIER;
        setProgress((prevValue) => {
          if (prevValue > PROGRESS_STOP_LEVEL) {
            clearInterval(timerId);
          }
          return prevValue + PROGRESS_STEP_INCREASE / divider;
        });
      }, 500);
    }

    if (state === UPLOADER_STATE.SUCCESS) {
      setProgress(0);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [state]);

  return progress;
};
