import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import flow from 'lodash/flow';
import { TableColumnType } from 'antd';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  useTableDataSearchAndFilter,
  createDateSorter,
  createDefaultSorter,
  dateRenderer,
} from '@lola/ui-react-components';
import { DataControlPanel } from '@components/DataTableControlPanel/DataTableControlPanel.component';
import { NoContent } from '@components/NoContent';
import {
  LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse,
  useDeleteApiV1PricingEngineQuoteByQuoteIdMutation,
  usePostApiV1PricingEngineQuoteByQuoteIdCopyQuoteMutation,
  useLazyGetApiV1PricingEngineQuotesQuery,
  usePutApiV1PricingEngineQuoteByQuoteIdChangeStatusMutation,
} from '@api/output/api';
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import { useOpenToast } from '@hooks/useOpenToast';
import { CommonError } from '@typings/common';
import { useOpenState } from '@hooks/useOpenState';
import { Modal } from '@components/Modal';
import { useRole } from '@hooks/useRole';
import { useCreatedByFilter } from '@hooks/useCreatedByFilter';
import { adjustCreatedByColumnVisibilityByRole } from '@utils/quotesList.utils';
import {
  quoteStatusSorter,
  updateActionsColumnRenderer,
} from '@pages/scenarioBuilder/pages/quotes/components/QuotesList/QuotesList.utils';
import { QuotesTable } from './components';
import {
  actionsRenderer,
  quoteNameRenderer,
  createQuoteStatusRenderer,
} from './renderers/tableCell';
import styles from './quotesList.module.scss';

export type OpenModalHandler = (
  quote: LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse
) => void;

const columns: TableColumnType<LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse>[] =
  [
    {
      dataIndex: 'name',
      key: 'name',
      sorter: createDefaultSorter('name'),
      render: quoteNameRenderer,
      title: 'Quote name',
      width: '30%',
    },
    {
      dataIndex: 'isAttachedToLoan',
      key: 'isAttachedToLoan',
      sorter: quoteStatusSorter('isAttachedToLoan'),
      render: createQuoteStatusRenderer(),
      title: 'Quote status',
      width: '20%',
    },
    {
      dataIndex: 'customerProductType',
      key: 'customerProductType',
      sorter: createDefaultSorter('customerProductType'),
      title: 'Product',
      width: '20%',
    },
    {
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: dateRenderer,
      sorter: createDateSorter('createdOn'),
      title: 'Created on',
      width: '20%',
    },
    {
      dataIndex: 'modifiedOn',
      key: 'modifiedOn',
      render: dateRenderer,
      sorter: createDateSorter('modifiedOn'),
      title: 'Last updated',
      width: '20%',
    },
    {
      key: 'createdBy',
      dataIndex: 'portalUser',
      title: 'Created by',
      render: ({ fullName }) => fullName,
      sorter: createDefaultSorter('portalUser.fullName'),
      width: '15%',
    },
    {
      key: 'actions',
      title: 'Actions',
      align: 'center',
      width: '10%',
    },
  ];

export const QuotesList = () => {
  const role = useRole();
  const [isModalOpen, openModal, closeModal] = useOpenState();
  const [currentQuote, setCurrentQuote] =
    useState<LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse | null>(
      null
    );
  const [getQuotes, { data, isFetching, isError }] =
    useLazyGetApiV1PricingEngineQuotesQuery();
  const [copyQuote, { isLoading: copyingInProgress }] =
    usePostApiV1PricingEngineQuoteByQuoteIdCopyQuoteMutation();
  const [stageQuote, { isLoading: quoteStaging }] =
    usePutApiV1PricingEngineQuoteByQuoteIdChangeStatusMutation();

  const [deleteQuote, { isLoading: deletionInProcess }] =
    useDeleteApiV1PricingEngineQuoteByQuoteIdMutation();
  const openToast = useOpenToast();
  const {
    filteredData,
    handleSearchChange,
    handleFilterChange,
    handleResetSearchChange,
    appliedFilters,
    filters,
  } =
    useTableDataSearchAndFilter<LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse>(
      data ?? [],
      {
        customerProductType: {
          values: [],
          title: 'Product',
        },
      },
      'name',
      ''
    );
  const { selectedQuotesCreators, updatedFilters } = useCreatedByFilter(
    role,
    filters
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    getQuotes({
      portalUserIds: selectedQuotesCreators,
      includeSubmitted: true,
    });
  }, [getQuotes, selectedQuotesCreators]);

  useEffect(() => {
    if (data) {
      isFirstRender.current = false;
    }
  }, [data]);

  const openModalHandler: OpenModalHandler = useCallback(
    (
      quote: LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse
    ) => {
      if (quote) {
        openModal();
        setCurrentQuote(quote);
      }
    },
    [openModal, setCurrentQuote]
  );

  const navigate = useNavigate();
  const copyQuoteHandler = useCallback(
    async (
      quote: LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse
    ) => {
      const { id, quoteType } = quote;
      const type = quoteType === 'STF' ? 'stf' : 'rental';
      try {
        const newQuoteId = await copyQuote({ quoteId: id ?? '' }).unwrap();
        getQuotes({
          portalUserIds: selectedQuotesCreators,
          includeSubmitted: true,
        });
        navigate(`/scenario-builder/quote/${type}/${newQuoteId}`);
      } catch (e) {
        openToast({
          isError: true,
          title: 'Error occurred',
          description: 'Copy has not been copied',
        });
      }
    },
    [navigate, selectedQuotesCreators]
  );

  const stageQuoteHandler = useCallback(
    async (
      quote: LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse
    ) => {
      try {
        await stageQuote({
          quoteId: quote.id ?? '',
        }).unwrap();
        await getQuotes({
          portalUserIds: selectedQuotesCreators,
          includeSubmitted: true,
        });
      } catch (e) {
        openToast({
          isError: true,
          title: 'Error occurred',
          description: 'Something went wrong, please try again later.',
        });
      }
    },
    [selectedQuotesCreators]
  );

  const deleteQuoteHandler = useCallback(() => {
    if (!currentQuote?.id) return;

    return deleteQuote({ quoteId: currentQuote.id })
      .unwrap()
      .then(() => {
        openToast({
          title: 'Quote has been deleted',
        });
        getQuotes({
          portalUserIds: selectedQuotesCreators,
          includeSubmitted: true,
        });
        handleResetSearchChange();
        closeModal();
      })
      .catch((err: CommonError) => {
        openToast({
          isError: true,
          title: 'Error occurred',
          description: err.message,
        });
      });
  }, [deleteQuote, openToast, currentQuote, selectedQuotesCreators, getQuotes]);

  const updatedColumns = useMemo(() => {
    const transformations = flow(
      adjustCreatedByColumnVisibilityByRole(role),
      updateActionsColumnRenderer(
        openModalHandler,
        copyQuoteHandler,
        stageQuoteHandler
      )
    );

    return transformations(columns);
  }, [columns, role, actionsRenderer, openModalHandler, stageQuoteHandler]);

  if (isFetching) {
    return <LinearProgress variant="indeterminate" value={30} />;
  }

  if (isError) {
    return <ErrorNoticeFetchRequest />;
  }

  if (data && data?.length === 0 && isFirstRender.current) {
    return (
      <div className={styles.noContentWrapper}>
        <NoContent title="No data to show here yet" />
      </div>
    );
  }

  if (!data) return null;

  return (
    <>
      <DataControlPanel
        appliedFilters={appliedFilters}
        filters={updatedFilters}
        handleFilterChange={handleFilterChange}
        handleSearchChange={handleSearchChange}
        className={styles.quotesListFilter}
        title="Filters"
      />
      <Grid container spacing={2} className={styles.quotesListContainer}>
        <Grid item xs={12}>
          {copyingInProgress && <LinearProgress />}
          <QuotesTable columns={updatedColumns} data={filteredData} />
        </Grid>
      </Grid>
      <Modal
        isOpen={isModalOpen}
        isLoading={deletionInProcess}
        title="Delete quote"
        successButtonText="Confirm"
        onClose={closeModal}
        onSuccess={deleteQuoteHandler}
        hasCancel
      >
        <Typography>
          Are you sure you want to delete the quote &ldquo;
          {currentQuote?.name ?? ''}
          &rdquo;?
        </Typography>
      </Modal>
    </>
  );
};
