import { useMemo } from 'react';
import { useTasksConfiguration } from '@pages/personalLoans/hooks/useTasksConfiguration';
import { CONFIGURED_TASKS } from '@typings/common';

export const useTaskConfigurationIdByTaskKey = (taskKey: CONFIGURED_TASKS) => {
  const { data: configuration } = useTasksConfiguration();

  return useMemo(() => {
    if (!configuration) {
      return;
    }

    return configuration?.taskMapping?.[taskKey]?.taskConfigurationId;
  }, [configuration]);
};
