import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
  Link,
  Typography,
} from '@mui/material';
import { LogOutIcon } from '@lola/ui-react-components';
import { UserProfileBall } from '@components/UserProfileBall/UserProfileBall.component';
import { Menu } from '@components/Menu';
import {
  useGetApiV1UsersMeQuery,
  useLazyGetApiV1AuthLogoutQuery,
} from '@api/output/api';
import { Modal } from '@components/Modal';
import styles from './userProfileLine.module.scss';

export const UserProfileLine = () => {
  const { data } = useGetApiV1UsersMeQuery();
  const [logout] = useLazyGetApiV1AuthLogoutQuery();
  const [isModalOpened, setModalOpened] = useState(false);

  const hash = useMemo(() => {
    return Date.now();
  }, []);

  if (!data) {
    return null;
  }

  const { firstName = '', lastName = '', email = '', picture } = data;

  return (
    <>
      <Menu
        trigger={
          <IconButton title="Profile">
            <UserProfileBall
              firstName={firstName}
              lastName={lastName || email}
              picture={picture}
              hash={hash}
            />
          </IconButton>
        }
      >
        <MenuItem>
          <Link
            component={NavLink}
            to="/profile/personal-info"
            className={styles.wrapper}
          >
            <UserProfileBall
              firstName={firstName}
              lastName={lastName || email}
              picture={picture}
              hash={hash}
            />
            <div className={styles.content}>
              <Typography variant="pBold">
                {firstName} {lastName}
              </Typography>
              <Typography variant="pSmall" className={styles.email}>
                {email}
              </Typography>
            </div>
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setModalOpened(true)}>
          <LogOutIcon />
          Log out
        </MenuItem>
      </Menu>
      <Modal
        title="Log out"
        isOpen={isModalOpened}
        onClose={() => setModalOpened(false)}
      >
        <div className={styles.content}>
          <Typography variant="p">
            Are you sure you want to log out from your account?
          </Typography>
          <div className={styles.actions}>
            <Button variant="outlined" onClick={() => setModalOpened(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => logout()}>
              Log out
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
