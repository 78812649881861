import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Menu as MuiMenu } from '@mui/material';
import { DEFAULT_POSITION_PROPS } from './Menu.constants';
import { PositionProps } from './Menu.types';

export interface MenuProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onClose?: () => void;
  trigger?: ReactNode;
  position?: PositionProps;
  disabled?: boolean;
}

export const Menu = ({
  children,
  trigger,
  onClose,
  open: controlledOpen,
  onOpenChange,
  position = DEFAULT_POSITION_PROPS,
  disabled,
}: PropsWithChildren<MenuProps>) => {
  const anchorEl = useRef(null);
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
  const open = controlledOpen !== undefined ? controlledOpen : uncontrolledOpen;

  const closeMenu = useCallback(() => {
    onClose?.();
    if (controlledOpen === undefined) {
      setUncontrolledOpen(false);
    } else {
      onOpenChange?.(false);
    }
  }, [onClose, controlledOpen, setUncontrolledOpen, onOpenChange]);

  const toggleMenu = useCallback(() => {
    if (disabled) return;
    if (controlledOpen === undefined) {
      setUncontrolledOpen(!uncontrolledOpen);
    } else {
      onOpenChange?.(!controlledOpen);
    }
  }, [
    controlledOpen,
    uncontrolledOpen,
    setUncontrolledOpen,
    onOpenChange,
    disabled,
  ]);

  return (
    <>
      <div ref={anchorEl} onClick={toggleMenu}>
        {trigger}
      </div>
      {anchorEl.current && (
        <MuiMenu
          open={open}
          anchorEl={anchorEl.current}
          onClick={closeMenu}
          {...position}
        >
          {children}
        </MuiMenu>
      )}
    </>
  );
};
