import { useMemo } from 'react';
import { useBuildMyLoanSteps } from '@pages/personalLoans/pages/buildMyLoan';
import { ProgressStepperItem } from '@components/ProgressStepper/types';

export const updateSteps = (steps: ProgressStepperItem[]) => {
  return steps.map((step) => {
    const newStep = { ...step };
    const isBuildPage = newStep.to?.includes('build-my-loan/');
    if (newStep.to !== undefined && !isBuildPage) {
      newStep.to = `build-my-loan/${step.to}`;
    }
    if (newStep.items) {
      newStep.items = updateSteps(newStep.items);
    }

    return newStep;
  });
};

export const useBuildMyLoansControlPanelSteps = () => {
  const steps = useBuildMyLoanSteps(false);
  return useMemo(() => updateSteps(steps), [steps]);
};
