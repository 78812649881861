import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '@store/types';

const selectSelf = (state: AppState) => state.auth;

export const isPossibleAuthenticated = createSelector(
  selectSelf,
  (state) => !!state.accessToken
);

export const selectAccessToken = createSelector(
  selectSelf,
  (state) => state.accessToken
);

export const selectUserName = createSelector(
  selectSelf,
  (state) => state.username
);
