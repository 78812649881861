import {
  UseFormResetField,
  UseFormSetValue,
  FieldValues,
} from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import { FieldConfig } from '@form-configs/types';
import { getConditionResult } from '@components/DynamicForm/utils/condition.utils';
import { NewClearResult } from '@components/DynamicForm/components/DynamicBlock/DynamicBlock.component';
import { FieldProp } from '@components/DynamicForm/contexts/FieldsProps.context';
import { FieldPropsDynamic } from '@components/DynamicForm/DynamicForm.typings';

export interface FlatConfigItem {
  id: string;
  name: string;
  defaultValue?: string;
}

export const getFlatConfigNames = (fields: FieldConfig[]) => {
  let result: FlatConfigItem[] = [];

  fields.forEach((field) => {
    if (field.props?.name) {
      result.push({
        id: field.id,
        name: field.props.name,
        defaultValue: field.props.defaultValue,
      });
    }

    if (field.items) {
      result = result.concat(getFlatConfigNames(field.items));
    }
  });

  return result;
};

export const getOverlapItems = (
  configA: FlatConfigItem[],
  configB: FlatConfigItem[]
): FlatConfigItem[][] => {
  return configA.reduce(
    (config: FlatConfigItem[][], currentItem) => {
      const itemInB = configB.find(({ name }) => currentItem.name === name);
      if (itemInB) {
        config[0].push(itemInB);
      } else {
        config[1].push(currentItem);
      }

      return config;
    },
    [[], []]
  );
};

export const resetFields = (
  resetFn: UseFormResetField<FieldValues>,
  fields: FieldConfig[] = []
) => {
  fields.forEach((field) => {
    const { items } = field;

    if (!items) {
      resetFn(field?.props?.name);
    }

    resetFields(resetFn, items);
  });
};

export interface ResetWithOverlapDataConfig {
  fields: FieldConfig[];
  fieldsToReset: FieldConfig[];
  fieldsToRender: FieldConfig[];
}

export interface ResetWithOverlapConfig extends ResetWithOverlapDataConfig {
  updateFn: UseFormSetValue<FieldValues>;
  resetFn: UseFormResetField<FieldValues>;
}

export const resetWithOverlap = ({
  updateFn,
  resetFn,
  fields,
  fieldsToReset,
  fieldsToRender,
}: ResetWithOverlapConfig) => {
  const currentConfig = getFlatConfigNames(fields);
  const resetConfig = getFlatConfigNames(fieldsToReset);
  const addConfig = getFlatConfigNames(fieldsToRender);
  const [itemsToUpdate, itemsToReset] = getOverlapItems(resetConfig, addConfig);

  itemsToUpdate.forEach(({ id, name, defaultValue }) => {
    const isChanged = !currentConfig.find((field) => field.id === id);
    if (isChanged) {
      updateFn(name, defaultValue);
    }
  });

  itemsToReset.forEach(({ name }) => {
    resetFn(name);
  });
};

export const getCurrentFields = (
  fields: FieldConfig[],
  items: FieldConfig[],
  values: FieldValues
) => {
  const { newItems, clearItems } = items.reduce(
    (result: NewClearResult, field) => {
      if (field.props?.condition) {
        const isReadyToDisplay = getConditionResult(
          values,
          field.props?.condition
        );
        const key = isReadyToDisplay ? 'newItems' : 'clearItems';
        result[key].push(field);
      } else {
        result.newItems.push(field);
      }
      return result;
    },
    {
      newItems: [],
      clearItems: [],
    }
  );

  const isChanged = !isEqual(fields, newItems);

  return {
    isChanged,
    newItems,
    clearItems,
  };
};

export const convertConfigToFieldProps = (config: FieldProp[]) => {
  const fieldPropsDynamic: FieldPropsDynamic = {};

  config.forEach(({ propName, value }) => {
    fieldPropsDynamic[propName] = value;
  });

  return fieldPropsDynamic;
};
