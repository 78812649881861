import React, { useCallback, useState } from 'react';
import { Button, Typography } from '@mui/material';
import {
  ExceptionRequestIcon,
  RequestAChangeIcon,
  Drawer,
} from '@lola/ui-react-components';
import { ContentSection, ContentSectionItem } from '@components/ContentSection';
import { ExceptionRequest } from '@components/ExceptionRequest/ExceptionRequest.component';
import { DRAWER_STATE, DRAWER_TITLE } from './QuickActionsWidget.constants';
import { ChangeRequest } from './components/ChangeRequest/ChangeRequest.component';
import styles from './quickActionsWidget.module.scss';

export const QuickActionsWidget = () => {
  const [drawerState, setDrawerState] = useState<DRAWER_STATE>(
    DRAWER_STATE.CLOSE
  );

  const handleClose = useCallback(() => setDrawerState(DRAWER_STATE.CLOSE), []);

  return (
    <>
      <ContentSection title="QUICK ACTIONS">
        <ContentSectionItem>
          <Button
            size="large"
            variant="text"
            onClick={() => setDrawerState(DRAWER_STATE.CHANGE)}
            className={styles.line}
          >
            <ExceptionRequestIcon />
            <div>
              <Typography variant="h4">Request a change</Typography>
              <Typography variant="p">
                Request a change to loan that is locked
              </Typography>
            </div>
          </Button>
        </ContentSectionItem>
        <ContentSectionItem>
          <Button
            size="large"
            variant="text"
            onClick={() => setDrawerState(DRAWER_STATE.EXCEPTION)}
            className={styles.line}
          >
            <RequestAChangeIcon />
            <div>
              <Typography variant="h4">Launch exception request</Typography>
              <Typography variant="p">An exception request</Typography>
            </div>
          </Button>
        </ContentSectionItem>
      </ContentSection>
      <Drawer
        isOpen={drawerState !== DRAWER_STATE.CLOSE}
        title={DRAWER_TITLE[drawerState]}
        onClose={handleClose}
      >
        {drawerState === DRAWER_STATE.EXCEPTION && (
          <ExceptionRequest handledClose={handleClose} isLoansList />
        )}
        {drawerState === DRAWER_STATE.CHANGE && (
          <ChangeRequest handleClose={handleClose} />
        )}
      </Drawer>
    </>
  );
};
