import { useMemo } from 'react';
import { RouteObject } from 'react-router';
import { useRole } from '@hooks/useRole';
import { routerConfig, RouterWithRoles } from '@router/router.constants';
import { ROLES } from '@typings/common';

export const convertRolesRouterToRegular = (
  routes: RouterWithRoles[],
  userRole?: ROLES
): RouteObject[] => {
  return routes.reduce((result: RouteObject[], { roles, ...route }) => {
    const isUserHavePermissions = roles
      ? roles.some((role) => userRole === role)
      : true;

    if (isUserHavePermissions) {
      const newRoute: RouteObject = { ...route };
      if (route.children) {
        newRoute.children = convertRolesRouterToRegular(
          route.children,
          userRole
        );
      }
      result.push(newRoute);
    }

    return result;
  }, []);
};

export const useRolesRoutes = () => {
  const role = useRole(true);

  return useMemo(
    () => convertRolesRouterToRegular(routerConfig, role),
    [role, routerConfig]
  );
};
