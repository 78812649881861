import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { usePostApiV1LoansByLoanIdGenerateTasksMutation } from '@api/output/api';

export const useTaskGenerate = () => {
  const { loanId = '' } = useParams();
  const [
    generate,
    {
      isSuccess,
      isLoading: isGenerating,
      isUninitialized: isNotGenerateTriggerred,
    },
  ] = usePostApiV1LoansByLoanIdGenerateTasksMutation();

  useEffect(() => {
    generate({ loanId });
  }, [loanId, generate]);

  return useMemo(() => {
    return {
      isSuccess,
      isLoading: isGenerating || isNotGenerateTriggerred,
    };
  }, [isSuccess, isGenerating, isNotGenerateTriggerred]);
};
