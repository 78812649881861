export enum FILE_TYPE {
  DOC = 'doc',
  PDF = 'pdf',
  XLS = 'xls',
  VIDEO = 'video',
  NOT_SET = 'notSet',
}

const EXTENSIONS_CONFIGS = [
  {
    fileType: FILE_TYPE.DOC,
    extensions: ['doc', 'docx', 'rtf', 'txt'],
  },
  {
    fileType: FILE_TYPE.XLS,
    extensions: ['xls', 'xlsx', 'csv'],
  },
  {
    fileType: FILE_TYPE.VIDEO,
    extensions: ['mp4', 'mov', 'avi'],
  },
  {
    fileType: FILE_TYPE.PDF,
    extensions: ['pdf'],
  },
];

const SIZES = ['gb', 'mb', 'kb', 'bytes'];
const BYTES_IN_KB = 1024;

export const getFilenameFromContentDisposition = (
  contentDisposition: string
) => {
  if (!contentDisposition) {
    return 'download';
  }

  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i;
  const matches = filenameRegex.exec(contentDisposition);
  const filename = matches?.[1]?.replace(/['"]/g, '');

  return filename ? decodeURIComponent(filename) : 'download';
};

export const getFileTypeByExtension = (
  extension?: string | null
): FILE_TYPE => {
  if (!extension) {
    return FILE_TYPE.NOT_SET;
  }

  const rawExtension = extension.replace('.', '');

  const fileTypeConfig = EXTENSIONS_CONFIGS.find(({ extensions }) =>
    extensions.includes(rawExtension)
  );

  return fileTypeConfig ? fileTypeConfig.fileType : FILE_TYPE.NOT_SET;
};

export const getFileSizeByBytes = (filesize?: string | number | null) => {
  const sizes = [...SIZES];

  let currentSize = sizes.pop();
  let result = filesize ? +filesize : 0;

  while (result >= BYTES_IN_KB && sizes.length > 0) {
    result = result / BYTES_IN_KB;
    currentSize = sizes.pop();
  }

  const fixedValue = Math.round(result * 10) / 10;
  return `${fixedValue}${currentSize}`;
};

export const downloadFile = (fileName: string, url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const checkFileExtension = (
  filename: string | null | undefined,
  extensions: string[]
): boolean => {
  if (!filename) return false;

  const fileExtension = filename.split('.').pop()?.toLowerCase();

  return extensions
    .map((ext) => ext.toLowerCase())
    .includes(fileExtension ?? '');
};
