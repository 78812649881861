import React from 'react';
import classnames from 'classnames';
import { Button } from '@mui/material';
import { FooterAction, FooterActionConfig } from './ContentSectionFooter.types';
import { DEFAULT_ACTION_PROPS } from './ContentSectionFooter.constants';
import styles from './contentSectionFooter.module.scss';

interface ContentSectionFooterProps {
  actions: Partial<Record<FooterAction, FooterActionConfig>>;
  className?: string;
}

export const ContentSectionFooter = ({
  actions,
  className,
}: ContentSectionFooterProps) => {
  const classes = classnames({
    [styles.footer]: true,
    [className as string]: !!className,
  });
  return (
    <footer className={classes}>
      {Object.entries(actions).map(([action, config]) => {
        const defaultProps = DEFAULT_ACTION_PROPS[action as FooterAction];
        const { label, ...restConfig } = config;
        const correctLabel = label ?? defaultProps.label ?? action;
        return (
          <Button key={correctLabel} {...defaultProps} {...restConfig}>
            {correctLabel}
          </Button>
        );
      })}
    </footer>
  );
};
