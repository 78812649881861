import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  PostApiV1LoansByLoanIdCreateGuarantorApiArg,
  useLazyGetApiV1BorrowersByPartyIdQuery,
  useLazyGetApiV1BorrowersSearchQuery,
  usePostApiV1LoansByLoanIdCreateGuarantorMutation,
} from '@api/output/api';
import { PARTY_SSN_NAME } from '@pages/personalLoans/pages/buildMyLoan/pages/applicationAndEntityInformation/components/PartForm/partyForm.constants';
import { usePartyAttach } from '@pages/personalLoans/pages/buildMyLoan/pages/applicationAndEntityInformation/hooks/usePartyAttach';
import { PARTY_TYPE } from '@typings/common';

export const usePartyApi = (ssn: string, mode: string) => {
  const { loanId = '' } = useParams();

  const [checkExisting, { data: limitedUsers, isFetching: isBorrowerLoading }] =
    useLazyGetApiV1BorrowersSearchQuery();

  const [loadParty, { isFetching: isPartyLoading }] =
    useLazyGetApiV1BorrowersByPartyIdQuery();

  const [createAndAttach, { isLoading: isCreatingOrAttaching }] =
    usePostApiV1LoansByLoanIdCreateGuarantorMutation();

  const { attachParty, isSaving: isAttaching } = usePartyAttach();

  const limitedUser = useMemo(() => {
    return limitedUsers?.[0];
  }, [limitedUsers]);

  const check = useCallback(
    async (ssn: string) => {
      const users = await checkExisting({
        type: mode,
        query: ssn,
      }).unwrap();

      return users[0];
    },
    [checkExisting, mode]
  );

  const getParty = useCallback(async () => {
    if (!limitedUser?.id) {
      return;
    }

    return loadParty({ partyId: limitedUser?.id }).unwrap();
  }, [limitedUser, loadParty]);

  const createParty = useCallback(
    async (isPrimary?: boolean) => {
      const payload: PostApiV1LoansByLoanIdCreateGuarantorApiArg = {
        loanId: loanId,
        body: {
          TaxpayerIdentifierValue: ssn,
          PartyType:
            mode === 'entity' ? PARTY_TYPE.ENTITY : PARTY_TYPE.INDIVIDUAL,
        },
        isPrimaryGuarantor: false,
      };

      if (isPrimary) {
        payload.isPrimaryGuarantor = true;
      }

      const { guarantorPartyId } = await createAndAttach(payload).unwrap();

      return {
        [PARTY_SSN_NAME]: ssn,
        id: guarantorPartyId,
      };
    },
    [ssn, attachParty, mode]
  );

  return {
    check,
    getParty,
    limitedUser,
    attachParty,
    createParty,
    isSaving: isCreatingOrAttaching || isAttaching,
    isLoading: isBorrowerLoading || isPartyLoading,
  };
};
