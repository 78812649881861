import { LolaBffApiContractsModelsLoanLoanMilestone } from '@api/output/api';

export const checkApproveMyLoanStep = (
  milestone: LolaBffApiContractsModelsLoanLoanMilestone
) => {
  const APPROVE_MY_LOAN_MILESTONES: LolaBffApiContractsModelsLoanLoanMilestone[] =
    [
      'New Deal',
      'Initial Deal Review',
      'Processing',
      'Suspended', // Check with BE after 14881 is it still in approve my loan step
      'Final Deal Review',
      'Final Deal Review Approved',
      'Final Quote Issued',
      'Final Quote Accepted',
      'Final Closing Updates Requested',
    ];

  return APPROVE_MY_LOAN_MILESTONES.includes(milestone);
};
