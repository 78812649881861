import React, { ChangeEvent, useCallback, useContext } from 'react';
import { RadioGroupProps } from '@form-configs/types';
import { RadioGroupControl } from '@components/RadioGroup';
import { FormConfigContext } from '@components/DynamicForm/contexts/FormConfig.context';
import styles from './dynamicRadioGroup.module.scss';

export type ChangeRadioGroupHandler = (
  event: ChangeEvent<HTMLInputElement>,
  value: string
) => void;

export const DynamicRadioGroup = ({
  options = [],
  label,
  name,
  required,
  isLabelTruncated,
}: RadioGroupProps) => {
  const { disabled, onBlur } = useContext(FormConfigContext);

  const createChangeWrapper = useCallback(
    (handler?: ChangeRadioGroupHandler) =>
      (event: ChangeEvent<HTMLInputElement>, value: string) => {
        handler?.(event, value);
        onBlur?.(name, value);
      },
    []
  );

  return (
    <RadioGroupControl
      disabled={disabled}
      name={name}
      options={options}
      label={label}
      row
      className={styles.radioWrapper}
      onBlurCreator={createChangeWrapper}
      required={required}
      isLabelTruncated={isLabelTruncated}
    />
  );
};
