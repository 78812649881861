import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useTasksByName } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTasksByName';
import { CONFIGURED_TASKS, TASK_SECTIONS } from '@typings/common';

export const useBorrowerDocumentsExist = (entityId?: string | null) => {
  const tasks = useTasksByName(
    TASK_SECTIONS.BORROWER_UPLOAD_DOCUMENTS,
    CONFIGURED_TASKS.SPONSOR_APPLICATION
  );

  return useMemo(() => {
    const entityTask = tasks?.find((task) => task.entityId === entityId);
    const firstDocument = get(entityTask, 'task.documents[0]');
    return !isEmpty(firstDocument);
  }, [entityId, tasks]);
};
