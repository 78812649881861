import React from 'react';
import classNames from 'classnames';
import { VERTICAL_STATUS_STATE } from '@components/verticalStepDetail/VerticalStepDetail.component';
import { STATUS_ICON } from './StatusIcon.constants';

import styles from './statusIcon.module.scss';

interface StatusIconProps {
  status: VERTICAL_STATUS_STATE;
  className?: string;
}

export const StatusIcon = ({ status, className }: StatusIconProps) => {
  const iconClasses = classNames({
    [styles.icon]: true,
    [styles.error]: status === VERTICAL_STATUS_STATE.ERROR,
    [className as string]: !!className,
  });

  return <div className={iconClasses}>{STATUS_ICON[status]}</div>;
};
