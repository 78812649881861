import React, { useContext } from 'react';
import { useTheme } from '@mui/material';
import { ListContext } from '@components/DynamicForm';
import { CustomComponentProps } from '@components/DynamicForm/contexts/Components.context';
import { NewExistingSelector } from '@pages/personalLoans/pages/buildMyLoan/components/NewExistingSelector/NewExistingSelector.component';
import { PartyForm } from '../../components/PartForm/PartyForm.component';

export const SelectGuarantor = ({ items }: CustomComponentProps) => {
  const index = useContext(ListContext);
  const { config } = useTheme();
  const key = `party.guarantors[${index}]`;

  return (
    <NewExistingSelector
      entityType="guarantor"
      entityKey={key}
      newComponent={<PartyForm fields={items} entityKey={key} />}
      label={`Has this client worked with ${config.companyLongName} before?`}
    />
  );
};
