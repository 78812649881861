import { createSelector } from '@reduxjs/toolkit';
import { api } from '@api/output/api';
import { RootState } from '../store';

const getActionsList = (state: RootState) => {
  const { data: actions } =
    api.endpoints.getApiV1DashboardActionsForToday.select()(state);
  return actions;
};

export const getActionForTodayById = createSelector(
  [getActionsList, (_, id) => id],
  (data, id) => data?.find((item) => item.displayedTaskId === id)
);
