import React from 'react';
import { Grid, Paper } from '@mui/material';
import { CardSection } from '@lola/ui-react-components';
import { Collapsed } from '@components/Collapsed';
import { getTaskInfoByEntityId } from '@pages/personalLoans/pages/buildMyLoan/utils/tasks.utils';
import { CREDIT_REPORT_ACTION } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/hooks/usePaymentParties';
import { useCreditTasks } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/hooks/useCreditTasks';
import { PAYMENT_STATUS_TYPE } from '../../../../buildMyLoan.constants';
import { usePartyTaskStatuses } from '../../../../hooks/usePartyTaskStatuses';
import { useParties } from '../../../../hooks/useParties';
import { CreditScoreCollectionSelector } from './components';

export interface CreditScoreCollectionOptionsProps {
  onChange: (type: CREDIT_REPORT_ACTION, party: string) => void;
  setCreditReportAccordionExpanded: (state: boolean) => void;
  setCurrentlyExpandedCreditScoreDetailsId: (state: string | null) => void;
}

export const CreditScoreCollectionOptions = ({
  onChange,
  setCreditReportAccordionExpanded,
  setCurrentlyExpandedCreditScoreDetailsId,
}: CreditScoreCollectionOptionsProps) => {
  const [reissueTasks, reportTasks] = useCreditTasks();
  const { parties } = useParties();
  const {
    partyStatuses: [borrower, ...guarantors],
  } = usePartyTaskStatuses({
    parties,
    taskType: 'credit_report.payment',
    defaultStatus: PAYMENT_STATUS_TYPE.NOT_PAID,
  });

  return (
    <>
      <Grid item xs={12}>
        <CardSection
          title="Credit report"
          description="We need to pull credit on all loan participants. We will ask them for authorization to agree to work with their credit data"
        >
          <Collapsed
            label={`Lead Borrower (${borrower?.firstName} ${borrower?.lastName})`}
            defaultExpanded
          >
            <CreditScoreCollectionSelector
              setCreditReportAccordionExpanded={
                setCreditReportAccordionExpanded
              }
              setCurrentlyExpandedCreditScoreDetailsId={
                setCurrentlyExpandedCreditScoreDetailsId
              }
              task={getTaskInfoByEntityId(reportTasks, borrower?.id)}
              taskReissue={getTaskInfoByEntityId(reissueTasks, borrower?.id)}
              party={borrower}
              onChange={onChange}
              status={borrower?.status}
            />
          </Collapsed>
        </CardSection>
      </Grid>
      {guarantors?.map((guarantor, index) => (
        <Grid item xs={12} key={guarantor?.id}>
          <Paper>
            <Collapsed
              label={`Guarantor #${index + 1} (${guarantor?.firstName} ${
                guarantor?.lastName
              })`}
              defaultExpanded
            >
              <CreditScoreCollectionSelector
                setCreditReportAccordionExpanded={
                  setCreditReportAccordionExpanded
                }
                setCurrentlyExpandedCreditScoreDetailsId={
                  setCurrentlyExpandedCreditScoreDetailsId
                }
                task={getTaskInfoByEntityId(reportTasks, guarantor?.id)}
                taskReissue={getTaskInfoByEntityId(reissueTasks, guarantor?.id)}
                onChange={onChange}
                party={guarantor}
                status={guarantor?.status}
              />
            </Collapsed>
          </Paper>
        </Grid>
      ))}
    </>
  );
};
