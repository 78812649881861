import React from 'react';
import { Title, SubTitle, StyledNavLink } from './loanTitleRenderer.styles';

export interface LoanTitleRendererRow {
  loanId?: string | null;
  id?: string | null;
  addressLine?: string | null;
  borrowerName?: string | null;
  loanType?: string | null;
  loanNumber?: string | null;
}

export const loanTitleRenderer = (_: unknown, row: LoanTitleRendererRow) => {
  const url = `/personal-loans/${row.loanId ?? row.id}`;

  return (
    <StyledNavLink to={url}>
      <Title variant="pBold" paragraph>
        {row.addressLine}
      </Title>
      <SubTitle variant="pSmall" paragraph>
        {row.borrowerName && <span>{row.borrowerName}</span>}
        {row.loanType && <span>{row.loanType}</span>}
        {row.loanNumber && <span>#{row.loanNumber}</span>}
      </SubTitle>
    </StyledNavLink>
  );
};
