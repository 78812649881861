import { Typography } from '@mui/material';
import React from 'react';
import { ENTITY_TYPE } from '@pages/personalLoans/pages/buildMyLoan/pages/applicationAndEntityInformation/components/PartForm/components/SSNField/SSNField.constants';

export interface ReplaceModalContentProps {
  ssn?: string;
  existedUserName?: string | null;
  entityType: ENTITY_TYPE;
}

export const PREFIX_MAP = {
  [ENTITY_TYPE.INDIVIDUAL]: 'The user with SSN ',
  [ENTITY_TYPE.ENTITY]: 'The entity with EIN ',
};

export const POSTFIX_MAP = {
  [ENTITY_TYPE.INDIVIDUAL]: 'user ',
  [ENTITY_TYPE.ENTITY]: 'entity ',
};

export const ReplaceModalContent = ({
  ssn,
  existedUserName,
  entityType,
}: ReplaceModalContentProps) => {
  return (
    <Typography variant="p">
      {PREFIX_MAP[entityType]}
      <strong>{ssn}</strong> already exists <strong>({existedUserName})</strong>
      . Would you like to select this {POSTFIX_MAP[entityType]}
      for current loan?
    </Typography>
  );
};
