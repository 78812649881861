import React, { useCallback, useEffect } from 'react';
// redux
import { useSelector } from 'react-redux';
// libraries
import dayjs from 'dayjs';
// MUI
import { Button, Link, LinearProgress, Grid } from '@mui/material';
// router
import { NavLink } from 'react-router-dom';
// redux selectors
import { AddIcon, InboxIcon, EyeOnIcon } from '@lola/ui-react-components';
import { selectUserName } from '@store/selectors/auth.selectors';
// API
import {
  useGetApiV1LoansActiveLoansQuery,
  LolaBffApiContractsModelsLoanActiveLoan,
  LolaBffApiContractsModelsLoanLoanStatus,
} from '@api/output/api';
// icons
import { NoContent } from '@components/NoContent';
// utils
import { normalizeText } from '@utils/normalizeText.utils';
// types
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import { getLoanIdentifier } from '@pages/myLoans/components/myLoansActiveLoans/MyLoansActiveLoans.utils';
import { sortBy } from '../../types/index';
// local components
import { CardActiveLoan, SectionActiveLoan } from './components/index';
// mocks
import MyLoansActiveLoansText from './_mock_/myLoansActiveLoansText.json';
// style
import styles from './MyLoansActiveLoans.module.scss';

interface MyLoansActiveLoansProps {
  searchTerm?: string;
  isSortBy?: sortBy;
  statusFilter?: string[];
  setStatusFilters: (
    arg: (LolaBffApiContractsModelsLoanLoanStatus | undefined)[]
  ) => void;
}

export const MyLoansActiveLoans = ({
  searchTerm,
  isSortBy,
  statusFilter,
  setStatusFilters,
}: MyLoansActiveLoansProps): JSX.Element => {
  // redux
  const userName = useSelector(selectUserName);

  // data
  const { data, isError, isLoading } = useGetApiV1LoansActiveLoansQuery(
    {
      userName: userName ?? '',
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const approveMyLoanData = data?.approveMyLoan;
  const buildMyLoanData = data?.buildMyLoan;
  const closeMyLoanData = data?.closeMyLoan;

  const isEmpty =
    approveMyLoanData?.length === 0 &&
    buildMyLoanData?.length === 0 &&
    closeMyLoanData?.length === 0;

  const { noData } = MyLoansActiveLoansText;
  const { title: titleNoData, text, button } = noData;

  useEffect(() => {
    if (!isEmpty) {
      const statusFilters = [
        ...(approveMyLoanData ?? []),
        ...(buildMyLoanData ?? []),
        ...(closeMyLoanData ?? []),
      ].map(({ status }) => status);
      setStatusFilters(statusFilters);
    }
  }, [isEmpty, approveMyLoanData, buildMyLoanData, closeMyLoanData]);

  // render
  const filteredLoans = useCallback(
    (loans: LolaBffApiContractsModelsLoanActiveLoan[]) => {
      let resultLoans = loans;

      if (searchTerm) {
        resultLoans = resultLoans?.filter((loan) => {
          const normalSearch = normalizeText(searchTerm);
          const identifier = getLoanIdentifier(loan);
          const normalLoanName = normalizeText(identifier);

          return normalLoanName.includes(normalSearch);
        });
      }

      if (statusFilter?.length !== 0) {
        resultLoans = resultLoans?.filter((loan) =>
          statusFilter?.includes(loan?.status as string)
        );
      }

      return resultLoans.slice().sort((a, b) => {
        const dateA = dayjs(a.createdAt).format('YYYYMMDD');
        const dateB = dayjs(b.createdAt).format('YYYYMMDD');
        const isOldToNew = isSortBy !== sortBy.newToOld;

        if (isOldToNew) return dateA < dateB ? 1 : -1;
        return dateA > dateB ? 1 : -1;
      });
    },
    [data, searchTerm, statusFilter, isSortBy]
  );

  if (isLoading) {
    return (
      <>
        <LinearProgress />
        <section className={styles.container}>
          <Grid container spacing={4}>
            {Array.from(Array(3).keys()).map((e) => (
              <Grid item xs={12} sm={4} className={styles.loadContent} key={e}>
                <CardActiveLoan isLoading={true} />
              </Grid>
            ))}
          </Grid>
        </section>
      </>
    );
  }

  if (isError) {
    return <ErrorNoticeFetchRequest />;
  }

  if (isEmpty) {
    return (
      <section className={styles.noContent}>
        <NoContent icon={<InboxIcon />} title={titleNoData} text={text}>
          <Link component={NavLink} to="/new-loan" underline="none">
            <Button variant="outlined" startIcon={<AddIcon />}>
              {button}
            </Button>
          </Link>
        </NoContent>
      </section>
    );
  }

  const renderList = (
    data: LolaBffApiContractsModelsLoanActiveLoan[],
    title: string
  ): JSX.Element => (
    <Grid item xs={12} sm={4} className={styles.loadContent} key={title}>
      <CardActiveLoan totalLoans={data?.length ?? 0} title={title}>
        {data.length === 0 && (
          <NoContent icon={<EyeOnIcon />} title={`No loans in ${title}`} />
        )}

        {filteredLoans(data).map((internalLoan) => (
          <Link
            component={NavLink}
            to={internalLoan?.id?.toString() ?? ''}
            underline="none"
            key={internalLoan.id}
          >
            <SectionActiveLoan {...internalLoan} />
          </Link>
        ))}
      </CardActiveLoan>
    </Grid>
  );

  return (
    <section className={styles.container}>
      <Grid container spacing={4}>
        {buildMyLoanData &&
          renderList(buildMyLoanData, 'Step 1: Build my loan')}

        {approveMyLoanData &&
          renderList(approveMyLoanData, 'step 2: Approve my loan')}

        {closeMyLoanData &&
          renderList(closeMyLoanData, 'step 3: Close my loan')}
      </Grid>
    </section>
  );
};
