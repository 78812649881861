import React from 'react';
//icons
import { UnarchiveIcon } from '@lola/ui-react-components';
//types
import { LolaBffApiContractsModelsLoanInactiveLoan } from '@api/output/api';

export const loanActionRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsLoanInactiveLoan
) => {
  const { archivedLoan } = row;

  if (!archivedLoan) {
    return null;
  }

  return <UnarchiveIcon fontSize={24} />;
};
