import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldConfig } from '@form-configs/types';
import { DynamicFields } from '@components/DynamicForm';
import styles from './PartyForm.module.scss';
import { SSNField } from './components/SSNField/SSNField.component';
import { PARTY_SSN_NAME } from './partyForm.constants';

export interface PartyFormProps {
  entityKey?: string;
  fields?: FieldConfig[];
}

export const PartyForm = ({ fields, entityKey }: PartyFormProps) => {
  const { watch } = useFormContext();
  const ssn = watch(`${entityKey}.${PARTY_SSN_NAME}`);
  if (!fields) {
    return null;
  }

  return (
    <div className={styles.partyFormWrapper}>
      <DynamicFields
        fields={fields}
        components={{
          ssn: SSNField,
        }}
        disabled={!ssn}
      />
    </div>
  );
};
