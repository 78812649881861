import React, { useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, Grid, Skeleton, Typography } from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { CardSection } from '@lola/ui-react-components';
import { Attestation } from '@pages/personalLoans/pages/approveMyLoan/pages/approveAndGetClearToClose/components/Attestation/Attestation.component';
import { Modal } from '@components/Modal';
import { LoanFooterActions } from '@pages/personalLoans/components';
import {
  SEND_QUOTE_TASKS,
  SendQuote,
} from '@pages/personalLoans/components/SendQuote';
import {
  PostApiV1ApprovemyloanGetMyClearToCloseApiArg,
  useGetApiV1BorrowersGetByLoanidQuery,
  usePostApiV1ApprovemyloanGetMyClearToCloseMutation,
} from '@api/output/api';
import { VALIDATION_DEFAULT_MESSAGES } from '@components/DynamicForm/utils/validation.types';
import { useQuoteIdAndTypeFromApi } from '@pages/personalLoans/hooks/useQuoteIdAndTypeFromApi';
import styles from './ApproveAndGetClearToClose.module.scss';

export enum BORROWER_STATUS_TYPE {
  LEAD_BORROWER = 'Lead borrower',
  PRIMARY_GUARANTOR = 'Primary guarantor',
}

export enum AUTHORIZATION_STATUS_TYPE {
  NOT_SEND = 'Not sent',
  SEND = 'Sent',
  UPLOAD = 'Upload',
  SIGNED = 'Signed',
}

export interface BorrowerInfoInterface {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: BORROWER_STATUS_TYPE;
  itemActionStatus: AUTHORIZATION_STATUS_TYPE;
}

export type BorrowerDataItem<T> = {
  id?: string;
  firstName?: string;
  lastName?: string;
  status?: string;
  email?: string;
  fundedTotalAmount?: number;
  itemActionStatus?: T;
};

export const ApproveAndGetClearToClosePage = () => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { loanId = '' } = useParams();
  const handleRedirectModal = () => {
    navigate(`/personal-loans/${loanId}/close-my-loan/scheduling`);
  };
  const {
    formState: { isValid },
    getValues,
  } = useFormContext();
  const { data = [], refetch } = useGetApiV1BorrowersGetByLoanidQuery({
    loanId,
  });
  const [BeginFinalDealAccepted, { isLoading }] =
    usePostApiV1ApprovemyloanGetMyClearToCloseMutation();
  const listData = data as BorrowerDataItem<AUTHORIZATION_STATUS_TYPE>[];
  const isDatePicked: boolean =
    getValues(['termsDate'])[0] &&
    (getValues(['termsDate'])[0] as unknown as string) !==
      VALIDATION_DEFAULT_MESSAGES.DATE;
  const handleSubmit = () => {
    const submitData: [string, string, string, boolean] = getValues([
      'firstName',
      'lastName',
      'termsDate',
      'iConsent',
    ]);
    const submitDataTyped: PostApiV1ApprovemyloanGetMyClearToCloseApiArg = {
      lolaBffApiContractsModelsApproveMyLoanRequestAttestationRequest: {
        dealId: loanId,
        firstName: submitData[0],
        lastName: submitData[1],
        date: submitData[2],
        consent: submitData[3],
      },
    };
    BeginFinalDealAccepted(submitDataTyped).then(() => {
      setIsOpenModal(true);
    });
  };

  const parties = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      contactPointEmailValue: item.email,
    }));
  }, [data]);

  const { quoteId, quoteType } = useQuoteIdAndTypeFromApi();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardSection title="Approval" description="">
          <Grid
            container
            direction="column"
            className={styles.cardSectionContainer}
          >
            <Attestation
              title={'Approve'}
              description={
                'I hereby confirm that I have carefully reviewed and fully understand the exact terms displayed, and I am aware that by checking this box, I am giving my consent and agreement to close this loan under such terms'
              }
            />
            <Divider />
            <SendQuote
              quoteId={quoteId}
              quoteType={quoteType}
              taskName={SEND_QUOTE_TASKS.FINAL}
              parties={parties}
            />
          </Grid>
          <LoanFooterActions>
            {isLoading ? (
              <Skeleton variant="rounded" width={210} height={48} />
            ) : (
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={
                  !(
                    isValid &&
                    isDatePicked &&
                    listData[0]?.itemActionStatus === 'Signed'
                  )
                }
              >
                Get My Clear to Close
              </Button>
            )}
          </LoanFooterActions>
        </CardSection>
      </Grid>
      <Modal
        isOpen={isOpenModal}
        onClose={handleRedirectModal}
        title="You moved to close my loan"
      >
        <Typography variant="p">
          Congratulations! Your loan is moving to the closing process.
        </Typography>

        <footer>
          <Button variant="contained" onClick={handleRedirectModal}>
            Go to Close My Loan
          </Button>
        </footer>
      </Modal>
    </Grid>
  );
};
