import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FileUploader, FileUploaderProps } from './FileUploader.component';

export interface FileUploaderControlProps extends FileUploaderProps {
  name: string;
}

export const FileUploaderControl = ({
  name,
  ...fileUploaderProps
}: FileUploaderControlProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = [], ref, ...props } }) => (
        <FileUploader uploadedFiles={value} {...fileUploaderProps} {...props} />
      )}
    />
  );
};
