export interface FeesTypeOption {
  label: string;
  valueType?: FEE_VALUE_TYPE;
  value: string;
}

export enum FEE_VALUE_TYPE {
  PERCENT = 'percent',
}

export const feesTypeOptionsForRental: FeesTypeOption[] = [
  {
    label: 'Appraisal Fee',
    value: 'lenderFinance.lenderFinanceAppraisalFee',
  },
  {
    label: 'Processing Fee',
    value: 'lenderFinance.processingFee',
  },
  {
    label: 'Document fee',
    value: 'lenderFinance.documentFee',
  },
  {
    label: 'Application Fee',
    value: 'lenderFinance.applicationFee',
  },
];

export const feesTypeOptionsForShortTerm: FeesTypeOption[] = [
  {
    label: 'Appraisal Fee',
    value: 'lenderFinance.lenderFinanceAppraisalFee',
  },
  {
    label: 'Processing Fee',
    value: 'lenderFinance.processingFee',
  },
  {
    label: 'Document fee',
    value: 'lenderFinance.documentFee',
  },
  {
    label: 'Application Fee',
    value: 'lenderFinance.applicationFee',
  },
];
