import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useGetApiV1LoansByLoanIdDocumentTasksQuery } from '@api/output/api';

export const useUploadComplete = () => {
  const { loanId = '' } = useParams();
  const { refetch } = useGetApiV1LoansByLoanIdDocumentTasksQuery({
    loanId,
  });
  return useCallback(async () => {
    await refetch().unwrap();
  }, [refetch]);
};
