import React from 'react';
import classnames from 'classnames';
import { StagesProgress } from '@components/StagesProgress';
import { STEPS, STEP_VALUES } from './StageStep.constants';
import { StageContainer, StageName } from './StageStep.styles';

interface StageStepProps {
  rowStep: STEP_VALUES;
  textPosition?: 'top' | 'right';
}

export const StageStep = ({
  rowStep,
  textPosition = 'top',
}: StageStepProps) => {
  const step = STEPS[rowStep] || STEPS[STEP_VALUES.DEFAULT];

  const stageClasses = classnames({
    textTop: textPosition === 'top',
    textRight: textPosition === 'right',
  });

  return (
    <StageContainer className={stageClasses}>
      <StageName variant="p" title={step.title}>
        {step.title}
      </StageName>
      <StagesProgress
        sectionsCount={3}
        completedCount={step.stepNumber}
        className={'stageBar'}
      />
    </StageContainer>
  );
};
