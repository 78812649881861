import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { IconButton, Tooltip, Typography, Stack, Chip } from '@mui/material';
import { InformationIcon } from '@lola/ui-react-components';

import styles from './widgetContent.module.scss';

interface WidgetContentProps {
  title: string;
  titleClass?: string;
  tooltip?: string;
  chipPrefix?: string;
  chipPostfix?: string;
}

export const WidgetContent = ({
  title,
  tooltip,
  chipPrefix,
  chipPostfix,
  titleClass,
  children,
}: PropsWithChildren<WidgetContentProps>) => {
  const titleClasses = classnames({
    [styles.title]: true,
    [titleClass as string]: !!titleClass,
  });

  return (
    <Stack spacing={2}>
      <header className={styles.header}>
        <Typography variant="h4" className={titleClasses}>
          {title}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip}>
            <IconButton>
              <InformationIcon className={styles.infoIcon} />
            </IconButton>
          </Tooltip>
        )}
        {(chipPrefix || chipPostfix) && (
          <Chip
            className={styles.chip}
            color="default"
            size="small"
            variant="filled"
            label={
              <>
                <b>{chipPrefix}</b> {chipPostfix}
              </>
            }
          />
        )}
      </header>
      {children}
    </Stack>
  );
};
