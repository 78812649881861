import React from 'react';
import { StatusChip } from '@components/StatusChip/StatusChip.component';
import { STATUS_CONFIG } from '@config/status';

const CONFIG = {
  Uploaded: STATUS_CONFIG.SUCCESS_FILLED,
  'Not uploaded': STATUS_CONFIG.INFO,
};

interface UploaderStatusChipProps {
  isUploaded: boolean;
}

export const UploaderStatusChip = ({ isUploaded }: UploaderStatusChipProps) => {
  const status = isUploaded ? 'Uploaded' : 'Not uploaded';
  return <StatusChip status={status} config={CONFIG} />;
};
