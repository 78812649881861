import React from 'react';
import { StageStep, STEP_VALUES } from '@components/StageStep';
import { LOAN_STATUS, StatusConfig } from '@typings/common';
import { StatusChip } from '@components/StatusChip/StatusChip.component';
import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';
import { QuickLoanInfo, QuickLoanDates } from '../../components';

import styles from './loanInfoRenderer.module.scss';

export const createLoanInfoRenderer =
  (config: StatusConfig<LOAN_STATUS>) =>
  (_: unknown, row: LolaBffApiContractsModelsLoanLoanView) => {
    const {
      addressLine,
      borrowerName,
      loanType,
      loanNumber,
      status,
      step,
      createdAt,
      lastUpdateAt,
    } = row;
    return (
      <>
        <StatusChip status={status} config={config} />
        <QuickLoanInfo
          name={borrowerName}
          addressLine={addressLine}
          loanProgramType={loanType}
          loanNumber={loanNumber}
        />
        <div className={styles.step}>
          <StageStep rowStep={step as STEP_VALUES} textPosition="right" />
        </div>
        <QuickLoanDates createdAt={createdAt} modifiedAt={lastUpdateAt} />
      </>
    );
  };
