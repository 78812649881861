import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Typography,
} from '@mui/material';
import omit from 'lodash/omit';

import { ControlType } from '@form-configs/types';
import styles from './collapsed.module.scss';

export interface CollapsedProps extends AccordionProps {
  /* Title of the accordion, node element*/
  label: React.ReactNode;
  icon?: React.ReactNode;
  /* The position of expand/collapse icon: move it to the left */
  isIconLeftSide?: boolean;
  description?: string;
  typographyVariant?: 'h2' | 'h3';
  controlType?: ControlType;
}

export const Collapsed = ({
  label,
  icon,
  description,
  children,
  isIconLeftSide = false,
  typographyVariant = 'h3',
  ...props
}: CollapsedProps) => {
  const collapsedProps = omit(props, 'controlType');

  return (
    <Accordion {...collapsedProps}>
      <AccordionSummary
        sx={{
          flexDirection: isIconLeftSide ? 'row-reverse' : 'row',
        }}
        aria-controls={`${label}-content`}
        className={styles.accordionSummary}
      >
        <div className={styles.info}>
          {icon}
          <Typography variant={typographyVariant}>
            {label}
            {description && <Typography paragraph>{description}</Typography>}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
