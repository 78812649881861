import { UploaderConfig } from '@components/Uploader/Uploader.types';
import { DEFAULT_UPLOADER_CONFIG } from '@components/Uploader/Uploader.constants';
import { DEFAULT_UPLOADER_DRAWER_CONFIG } from '@components/UploadDrawer/uploaderDrawer.constants';
import { UploaderDrawerConfig } from '@components/UploadDrawer/uploaderDrawer.types';

export const CONSTRUCTION_BUDGET_DEFAULT_UPLOADER_CONFIG: UploaderConfig = {
  ...DEFAULT_UPLOADER_CONFIG,
  description: 'Accepted file types: PDF, JPEG, JPG, Excel, less than 20mb',
  rules: {
    ...DEFAULT_UPLOADER_CONFIG.rules,
    allowedExtensions: {
      rule: ['docx', 'pdf', 'jpeg', 'jpg', 'xls', 'xlsx'],
      message: 'Wrong file extension',
    },
    maxFiles: 1,
  },
};

export const CONSTRUCTION_BUDGET_DEFAULT_DRAWER_CONFIG: UploaderDrawerConfig = {
  ...DEFAULT_UPLOADER_DRAWER_CONFIG,
  title: 'Construction Budget documents',
  description: 'Optional description of the document',
};
