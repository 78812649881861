import React from 'react';
import { SetModalContent } from '@typings/common';
import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';
import { MODAL_STATE, ModalContentType } from '../../Loans.constants';
import {
  ArchiveLoan,
  AssignProcessorBulk,
  AssignProcessorConfirm,
} from './components';

interface ModalContentProps {
  content: {
    state: MODAL_STATE;
    context?: LolaBffApiContractsModelsLoanLoanView;
  };
  setModalContent: SetModalContent<ModalContentType>;
}

export const ModalContent = ({
  content,
  setModalContent,
}: ModalContentProps) => {
  switch (content.state) {
    case MODAL_STATE.ARCHIVE_LOAN: {
      return <ArchiveLoan />;
    }
    case MODAL_STATE.ASSIGN_PROCESSOR_BULK: {
      return <AssignProcessorBulk setModalContent={setModalContent} />;
    }

    case MODAL_STATE.ASSIGN_PROCESSOR_CONFIRM: {
      return <AssignProcessorConfirm setModalContent={setModalContent} />;
    }

    default:
      return null;
  }
};
