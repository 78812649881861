interface StepConfig {
  title: string;
  stepNumber: number;
}

export enum STEP_VALUES {
  DEFAULT = 'default',
  BUILD_MY_LOAN = 'BuildMyLoan',
  APPROVE_MY_LOAN = 'ApproveMyLoan',
  CLOSE_MY_LOAN = 'CloseMyLoan',
  ARCHIVED = 'Archived',
  CLOSED = 'Closed',
  SUSPENDED = 'Suspended',
  NA = 'NA',
}

export const STEPS: Record<STEP_VALUES, StepConfig> = {
  [STEP_VALUES.DEFAULT]: {
    title: 'New loan',
    stepNumber: 0,
  },
  [STEP_VALUES.BUILD_MY_LOAN]: {
    title: 'Step 1: Build my loan',
    stepNumber: 1,
  },
  [STEP_VALUES.APPROVE_MY_LOAN]: {
    title: 'Step 2: Approve my loan',
    stepNumber: 2,
  },
  [STEP_VALUES.CLOSE_MY_LOAN]: {
    title: 'Step 3: Close my loan',
    stepNumber: 3,
  },
  [STEP_VALUES.CLOSED]: {
    title: 'Closed',
    stepNumber: 3,
  },
  [STEP_VALUES.SUSPENDED]: {
    title: 'Suspended',
    stepNumber: 0,
  },
  [STEP_VALUES.ARCHIVED]: {
    title: 'Archived',
    stepNumber: 0,
  },
  [STEP_VALUES.NA]: {
    title: 'N/A',
    stepNumber: 3,
  },
};
