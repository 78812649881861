import React from 'react';
// MUI
import { Typography } from '@mui/material';
// classNames
import classnames from 'classnames';
// API
import { NotificationIcon } from '@lola/ui-react-components';
import { LolaBffApiContractsModelsLoanActiveLoan } from '@api/output/api';
// icons
// components
import { LoanStatusChip } from '@pages/personalLoans/components';
// types
import {
  ApproveMyLoanStatus,
  ActiveLoanStatus,
} from '@pages/personalLoans/types/LoanTypes.type';
// style
import styles from './SectionActiveLoan.module.scss';

export const SectionActiveLoan = ({
  name,
  borrower,
  createdAt,
  lastUpdateAt,
  notifications = 0,
  status,
  productType,
  loanIdentifier,
}: LolaBffApiContractsModelsLoanActiveLoan): JSX.Element => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <LoanStatusChip
          status={status as ApproveMyLoanStatus | ActiveLoanStatus}
        />
        <Typography
          variant="pDescription"
          className={classnames({
            [styles.bell]: true,
            [styles.bellActive]: notifications > 0,
          })}
        >
          <NotificationIcon />
          {notifications}
        </Typography>
      </header>
      <div>
        {name && <Typography variant="h3">{name}</Typography>}
        <Typography variant="pDescription">
          {borrower ? borrower?.firstName + ' ' + borrower.lastName : ''}
          {' • '}
          {productType} # {loanIdentifier}
        </Typography>
      </div>
      <footer>
        <Typography variant="pDescription">
          Created: <strong>{createdAt}</strong>
        </Typography>
        <Typography variant="pDescription">
          Last update: <strong>{lastUpdateAt}</strong>
        </Typography>
      </footer>
    </div>
  );
};
