import React, { useMemo } from 'react';
import {
  Alert,
  AlertTitle,
  Grid,
  Button,
  Paper,
  LinearProgress,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { InputControl } from '@lola/ui-react-components';
import { Collapsed } from '@components/Collapsed';
import { FormData } from '@form-configs/types';
import { usePostApiV1AuthChangePasswordMutation } from '@api/output/api';
import { useOpenToast } from '@hooks/useOpenToast';
import {
  CHANGE_PASSWORD_FORM_TOAST_CONFIG,
  CHANGE_PASSWORD_RESOLVER,
} from '@pages/profile/pages/personalInfo/components/ChangePassword/ChangePassword.constants';
import { FIELDS } from './ChangePassword.constants';

export const ChangePassword = () => {
  const [changePassword, { isLoading }] =
    usePostApiV1AuthChangePasswordMutation();

  const methods = useForm<FormData>({
    mode: 'onBlur',
    resolver: CHANGE_PASSWORD_RESOLVER,
  });

  const openToast = useOpenToast();

  const {
    formState: { isDirty },
  } = methods;

  const submitHandler = useMemo(() => {
    return methods.handleSubmit(({ oldPassword, newPassword }) => {
      changePassword({
        lolaBffApiContractsModelsAuthAuthChangePasswordRequest: {
          oldPassword,
          newPassword,
        },
      })
        .unwrap()
        .then(() => {
          openToast(CHANGE_PASSWORD_FORM_TOAST_CONFIG.SUCCESS);
        })
        .catch((error) => {
          openToast({
            isError: true,
            title: 'Error',
            description: error.data.detail,
          });
        });
    });
  }, [methods]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={submitHandler}>
        <Collapsed label="Change password">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="warning">
                <AlertTitle>Password requirements:</AlertTitle>
                - Minimum of 8 characters <br />
                - At least one letter <br />
                - At least one number <br />
                - At least one special character <br />
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Paper variant="filled">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputControl
                      name={FIELDS.OLD_PASSWORD}
                      label="Current password"
                      type="password"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputControl
                      name={FIELDS.NEW_PASSWORD}
                      label="New password"
                      type="password"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputControl
                      name={FIELDS.CONFIRM_PASSWORD}
                      label="Confirm new password"
                      type="password"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!isDirty || isLoading}
                    >
                      Change password
                    </Button>
                  </Grid>
                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Collapsed>
      </form>
    </FormProvider>
  );
};
