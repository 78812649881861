import React, { useEffect, useState } from 'react';
// MUI
import {
  Grid,
  Paper,
  Skeleton,
  Drawer,
  Button,
  Typography,
  ListItemButton,
  IconButton,
  Collapse,
  Alert,
  AlertTitle,
} from '@mui/material';
// router
import { useParams } from 'react-router-dom';
//Icon
import {
  FlashlightRoundIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  InformationIcon,
} from '@lola/ui-react-components';
// API
import { ActionBlock, CardSection } from '@lola/ui-react-components';
import {
  useGetApiV1ApprovemyloanGetShowConditionsQuery,
  usePostApiV1ApprovemyloanBeginFinalUnderwritingMutation,
  useGetApiV1ApprovemyloanGetFinalUnderwritingQuery,
} from '@api/output/api';
// components
import { Step } from '@components/verticalStepDetail/VerticalStepDetail.component';
import {
  LoanStatusSection,
  STATUS_LIST,
  CompletedTasks,
} from '@pages/personalLoans/components';
import { Collapsed } from '@components/Collapsed';
import { Modal } from '@components/Modal';
// Local components
import { ListOfActions } from '../../components/ListOfActions/ListOfActions.component';
import { MessageArea } from '../reviewStatus/components/MessageArea.component';
import {
  ConditionsDisplay,
  FilterButtons,
  ManageConditionPanel,
  ModalFinalUnderwriting,
} from './components';
// hooks
import {
  useFilterConditions,
  useFilteredButtons,
  useManageCondition,
} from './hooks/';
// types
import { PanelDataType } from './components/ManageConditionPanel/types/ManageConditionUploadPanel.type';
// styles
import styles from './ManageConditions.module.scss';

export const ManageConditions = () => {
  // router
  const { loanId = '' } = useParams();

  // API
  const { isLoading, data, refetch } =
    useGetApiV1ApprovemyloanGetShowConditionsQuery({
      loanId,
    });
  const [
    handledStartFinalUnderwriting,
    { isLoading: isLoadingFinal, isError: isErrorFinal },
  ] = usePostApiV1ApprovemyloanBeginFinalUnderwritingMutation();
  const { isLoading: isLoadApprove, data: dataApprove } =
    useGetApiV1ApprovemyloanGetFinalUnderwritingQuery({
      loanId,
    });
  const disabledButton = !data?.enableSubmitFinalUnderwriting;
  const fetchConditions = data?.showConditions ?? [];
  const loanName = `${data?.loanName} ${data?.loanType} #${data?.loanIdentifier}`;

  // hooks
  const {
    activeButton,
    mode,
    drawerState,
    startFinalUnderwriting,
    statusSectionOpen,
    handledActiveButton,
    handledMode,
    openPanel,
    openModal,
    closePanel,
    closeModal,
    handledFinalUnderwriting,
    handledExceptionRequest,
    handleStatusSectionOpen,
  } = useManageCondition({ refetch: data && refetch });
  const filteredCondition = useFilterConditions(
    fetchConditions,
    activeButton ?? null,
    mode
  );
  const filterButtons = useFilteredButtons(fetchConditions ?? []);

  const modifyFilter = (button: string) => {
    if (activeButton !== button) {
      handledActiveButton(button);
    } else if (button !== 'All') {
      handledActiveButton('All');
    }
  };

  // data
  const [isReFetchNeeded, setIsReFetchNeeded] = useState<boolean>(false);
  const textStartFinalUnderwritingButton = 'Start final underwriting';

  // actions States
  const handledInitFinal = () => {
    handledStartFinalUnderwriting({ dealId: loanId })
      .then(() => refetch())
      .finally(() => handledFinalUnderwriting(false));
  };

  useEffect(() => {
    if (isReFetchNeeded) {
      refetch();
      setIsReFetchNeeded(false);
    }
  }, [isReFetchNeeded]);

  if (!data || isLoading) {
    return (
      <Paper
        data-testid="skeleton"
        elevation={2}
        className={styles.manageCondition}
      >
        <Skeleton variant="text" className={styles.skeletonTitle} />
        <Skeleton className={styles.skeletonBody} variant="text" />
        <Skeleton variant="rounded" className={styles.skeletonFooter} />
      </Paper>
    );
  }

  if (fetchConditions?.length <= 0) {
    return (
      <Grid container spacing={2} className={styles.manageCondition}>
        <Grid item xs={12}>
          <CardSection
            title="Manage conditions"
            description="This page displays all the documents that you need to upload or review in order to comply with the conditions that Lima sets for you. You need to fulfill all the conditions in order to continue the process."
          >
            <MessageArea
              messageStatus="success"
              title="Loan has been approved without conditions"
              message="Congratulations! Your loan has been approved with no conditions.You have no conditions to solve. Please proceed to the next step"
            />
          </CardSection>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={2} className={styles.manageCondition}>
        <Grid item xs={12}>
          <CardSection
            title="Manage conditions"
            description="This page displays all the documents that you need to upload or review in order to comply with the conditions that Lima sets for you. You need to fulfill all the conditions in order to continue the process."
          >
            {!disabledButton && (
              <ActionBlock
                title="You can start final underwriting"
                description="You have completed all tasks. No you can start a final underwriting. It can take up to 48h for L1C to review your tasks"
                icon={<InformationIcon />}
              >
                <Button
                  variant="contained"
                  className={styles.finalUnderwritingButton}
                  disabled={disabledButton}
                  onClick={() => handledFinalUnderwriting(true)}
                >
                  {textStartFinalUnderwritingButton}
                </Button>
              </ActionBlock>
            )}

            <ActionBlock
              title="Priority task"
              description="Priority tasks take longer to be reviewed by the underwriting department. The review will begin as soon as you upload them to help you close the loan faster. You still need to complete all tasks to close a loan."
              titleIcon={<FlashlightRoundIcon />}
            />
            <FilterButtons
              buttons={filterButtons}
              activeButton={activeButton}
              onClick={modifyFilter}
              mode={mode}
              setMode={handledMode}
            />
            {filteredCondition?.length > 0 && (
              <>
                <ConditionsDisplay
                  conditions={filteredCondition}
                  onClick={(item: PanelDataType) =>
                    openPanel(item.conditionId?.toString())
                  }
                  selectedItem={drawerState?.conditionId || undefined}
                />

                {isErrorFinal && (
                  <Alert severity="error" className={styles.alert}>
                    <AlertTitle>
                      Something went wrong during Starting final underwriting.
                    </AlertTitle>
                    Please check the loan details and try again.
                  </Alert>
                )}
                <Button
                  variant="contained"
                  className={styles.finalUnderwritingButton}
                  disabled={disabledButton}
                  onClick={() => handledFinalUnderwriting(true)}
                >
                  {textStartFinalUnderwritingButton}
                </Button>
              </>
            )}
          </CardSection>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2}>
            <Collapsed
              label="Tasks being completed by The REI Lender"
              defaultExpanded
            >
              <ListOfActions />
            </Collapsed>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} className={styles.collapseSection}>
            <ListItemButton
              onClick={handleStatusSectionOpen}
              className={styles.collapseHeader}
            >
              <Typography variant="h3">Final underwriting</Typography>
              <IconButton>
                {statusSectionOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
              </IconButton>
            </ListItemButton>
            <Collapse
              in={statusSectionOpen}
              timeout="auto"
              unmountOnExit
              className={styles.collapseContainer}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="p">
                    This page will show you the progress and the results of
                    Final underwriting review.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Paper elevation={2} variant="filled">
                    {isLoadApprove ? (
                      <Skeleton />
                    ) : (
                      <LoanStatusSection
                        completedTasks={
                          dataApprove?.completedTasks as CompletedTasks
                        }
                        loanStatus={dataApprove?.loanStatus as STATUS_LIST}
                        loanDescription={dataApprove?.loanDescription}
                        progress={dataApprove?.progress as Step[]}
                      />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Collapse>
          </Paper>
        </Grid>
      </Grid>

      <Drawer
        anchor={'right'}
        open={drawerState.open}
        onClose={() => closePanel()}
      >
        {drawerState?.conditionId && (
          <ManageConditionPanel
            title="Manage conditions"
            conditionId={drawerState?.conditionId}
            isExceptionRequest={drawerState.isExceptionRequest}
            handledExceptionRequest={handledExceptionRequest}
            onClose={closePanel}
            openModal={openModal}
            loanName={loanName}
            loanIdentifier={data?.loanIdentifier}
            setIsReFetchNeeded={setIsReFetchNeeded}
          />
        )}
      </Drawer>

      <Modal isOpen={drawerState.isOpenModal} onClose={closeModal} title="Note">
        <Typography variant="p">
          All conditions you have completed will not be submitted for review
          until you click the Submit button. This button is disabled until you
          complete all conditions. You can leave this page - your progress will
          be saved
        </Typography>

        <footer className={styles.exceptionModal}>
          <Button variant="contained" onClick={closeModal}>
            Ok
          </Button>
        </footer>
      </Modal>

      <ModalFinalUnderwriting
        startFinalUnderwriting={startFinalUnderwriting}
        closeModal={() => handledFinalUnderwriting(false)}
        handledInitFinal={handledInitFinal}
        isLoadingFinal={isLoadingFinal}
      />
    </>
  );
};
