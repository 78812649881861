import * as yup from 'yup';

export const COMPLETION_STATUS = 'Success';
export const FAIL_STATUS = 'Fail';
export const IN_PROGRESS_STATUS = 'InProgress';

export const DEFAULT_POLLING_INTERVAL = 6 * 1000;
export const NORMAL_POLLING_INTERVAL = 30 * 1000;
export const RARE_POLLING_INTERVAL = 2 * 60 * 1000;

export const OWNERSHIP_ROW_SCHEMA = yup.object({
  partyRelationRoles: yup.array().required(),
  ownershipPercentage: yup.number().required(),
  liquidAssetTotalAmount: yup.string().required(),
});

export enum CREDIT_REPORT_TASK {
  AUTHORIZATION = `credit_report.authorization`,
  PAYMENT = `credit_report.payment`,
  RESULT = 'credit_report.result',
}

export enum CONSTRUCTION_BUDGET_TASK {
  PAYMENT = 'construction_inspection.payment',
  INSPECTION = 'construction_inspection.inspection',
}
