import { useCallback } from 'react';
import { useOpenToast } from '@hooks/useOpenToast';
import { AnyError, convertCatchError } from '@utils/errors.utils';

export const useStatusToastsHandlers = () => {
  const openToast = useOpenToast();

  const successHandler = useCallback((title?: string) => {
    openToast({
      title: title ?? 'Value is updated.',
      description: 'The new value is stored.',
    });
  }, []);

  const errorHandler = useCallback((err: AnyError, title?: string) => {
    const description = convertCatchError(err);
    openToast({
      isError: true,
      title: title ?? 'Value is not updated.',
      description,
    });
  }, []);

  return {
    successHandler,
    errorHandler,
  };
};
