import React from 'react';
//MUI
import { Button, Divider, Grid, Typography } from '@mui/material';
//Icons
import { ArrowBackIcon, ArrowLeftIcon } from '@lola/ui-react-components';
//Components
import { ProgressStatusIcon } from '@components/ProgressStepper/components';
//Types
import { NavigationConfig } from '@typings/common';
import { ProgressStepItemStatus } from '@components/ProgressStepper/types';
//Constants
import { PercentCompletion } from '@pages/personalLoans/components/LoanFooter/components/PercentCompletion/PercentCompletion.component';
import { PORTAL_ACTIONS_ID } from './LoanFooter.constants';
//Styles
import styles from './loanFooter.module.scss';

export interface LoanFooterProps {
  currentStep?: NavigationConfig;
  goToPreviousStep?: () => void;
  goToNextStep?: () => void;
}

export const LoanFooter = ({
  currentStep,
  goToPreviousStep,
  goToNextStep,
}: LoanFooterProps) => {
  return (
    <footer className={styles.footer}>
      <Grid className={styles.navigation}>
        {currentStep && (
          <ProgressStatusIcon
            status={currentStep?.metadata?.status as ProgressStepItemStatus}
          />
        )}
        <Typography variant="pBold" className={styles.navTitle}>
          {currentStep?.label}
        </Typography>
        <Typography variant="pDescription" className={styles.navDescription}>
          <PercentCompletion
            percentage={currentStep?.metadata?.percentComplete as number}
            status={currentStep?.metadata?.status as ProgressStepItemStatus}
          />
        </Typography>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid className={styles.buttons}>
        <div>
          <Button
            variant="outlined"
            size="large"
            className={styles.navButton}
            disabled={!goToPreviousStep}
            onClick={goToPreviousStep}
          >
            <ArrowBackIcon />
          </Button>
          {!(currentStep?.metadata?.hasButton && !goToNextStep) && (
            <Button
              variant="outlined"
              size="large"
              disabled={!goToNextStep}
              onClick={goToNextStep}
              className={styles.navButton}
            >
              <ArrowLeftIcon />
            </Button>
          )}
        </div>
        {/* Used by LoanFooterAction to control different actions */}
        <div id={PORTAL_ACTIONS_ID}></div>
      </Grid>
    </footer>
  );
};
