import React, { ComponentType, useCallback, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { ArchiveIcon, FileAddIcon } from '@lola/ui-react-components';
import { Portal } from '@components/Portal';
import { Modal } from '@components/Modal';
import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';
import { ManageAccessDrawer } from '@containers/ManageAccessDrawer/ManageAccessDrawer.component';
import { DrawerState } from '@containers/ManageAccessDrawer/ManageAccessDrawer.constants';
import { ModalContent } from '../ModalContent/ModalContent.component';
import {
  MODAL_STATE,
  MODAL_TITLES,
  ModalContentType,
} from '../../Loans.constants';
import { PORTAL_TEAM_MANAGEMENT_FOOTER_ID } from '../../../../TeamManagement.constants';

import styles from './withTableActions.module.scss';

export const withTableActions = <P,>(
  WrappedComponent: ComponentType<P>
): ComponentType<P> => {
  return (props: P) => {
    const methods = useForm();

    const [modalContent, setModalContentState] = useState<ModalContentType>({
      state: MODAL_STATE.CLOSE,
    });

    const handleModalClose = useCallback(() => {
      setModalContentState({ state: MODAL_STATE.CLOSE });
    }, []);

    const openAssignProcessorBulkModal = useCallback(
      () => setModalContentState({ state: MODAL_STATE.ASSIGN_PROCESSOR_BULK }),
      []
    );

    const [drawerState, setDrawerState] = useState<DrawerState>({
      isOpen: false,
    });
    const handleDrawerClose = useCallback(() => {
      setDrawerState({ isOpen: false });
    }, []);

    const onLoanSelect = useCallback(
      (selectedLoans: LolaBffApiContractsModelsLoanLoanView[]) =>
        methods.setValue('selectedLoans', [...selectedLoans]),
      []
    );

    const selectedLoans = methods.watch('selectedLoans') ?? [];

    const selectedLoansIdSet = useMemo(() => {
      return new Set(
        selectedLoans
          .map((obj: LolaBffApiContractsModelsLoanLoanView) => obj.id)
          .filter((id: null | string) => id !== null)
      );
    }, [selectedLoans]);

    return (
      <>
        <WrappedComponent
          {...props}
          setModalContent={setModalContentState}
          setDrawerState={setDrawerState}
          selectedLoansIdSet={selectedLoansIdSet}
          onLoanSelect={onLoanSelect}
        />
        <FormProvider {...methods}>
          <Modal
            isOpen={modalContent.state !== MODAL_STATE.CLOSE}
            title={MODAL_TITLES[modalContent.state]}
            onClose={handleModalClose}
          >
            <ModalContent
              content={modalContent}
              setModalContent={setModalContentState}
            />
          </Modal>
          <ManageAccessDrawer
            drawerState={drawerState}
            onClose={handleDrawerClose}
          />
        </FormProvider>
        <Portal id={PORTAL_TEAM_MANAGEMENT_FOOTER_ID}>
          {selectedLoansIdSet.size >= 2 && (
            <div className={styles.footer}>
              <Button
                variant="outlined"
                startIcon={<ArchiveIcon />}
                onClick={() => alert('archive')}
              >
                Archive loan
              </Button>
              <Button
                variant="outlined"
                startIcon={<FileAddIcon />}
                onClick={openAssignProcessorBulkModal}
              >
                Assign new processor
              </Button>
            </div>
          )}
        </Portal>
      </>
    );
  };
};
