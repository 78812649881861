import { SectionsConfig } from '@utils/quotes.utils';
import { RENTAL_PARTNER_ECONOMICS_CALCULATION_CONFIGS } from '@constants/rentalPECalculatuionsConfig.constants';

export enum SCENARIO_ELIGIBILITY {
  ELIGIBLE = 'Eligible',
  NOT_ELIGIBLE = 'Not eligible',
}

export const TOOLTIPS: Record<string, string> = {
  preferred: 'Tooltip for preferred',
  included: 'Tooltip for included',
};

export const RENTAL_CALCULATION_SECTIONS_CONFIGS: SectionsConfig = [
  {
    ...RENTAL_PARTNER_ECONOMICS_CALCULATION_CONFIGS,
  },
];
