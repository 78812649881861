import React from 'react';
import { Grid } from '@mui/material';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';

interface ChangeRequestItemProps {
  label: string;
  elements: JSX.Element[];
}

export const ChangeRequestItem = ({
  label,
  elements,
}: ChangeRequestItemProps) => {
  return (
    <Grid item xs={12}>
      <LabeledCard label={label}>
        <Grid container spacing={2}>
          {elements.map((element) => (
            <Grid item xs={12} key={element.key}>
              {element}
            </Grid>
          ))}
        </Grid>
      </LabeledCard>
    </Grid>
  );
};
