import React, { useMemo } from 'react';
import { LinearProgress } from '@mui/material';
import { RadioGroupControl } from '@components/RadioGroup/RadioGroupControl.component';
import { useGetApiV1SettingsProgramsAndProductsQuery } from '@api/output/api';
import { convertToOptions } from '@utils/options.utils';
import { FIELDS } from '../../CreateLoan.constants';

const DESCRIPTION_MAP = {
  FixNFlip: 'Loans for buying distressed properties, rehabbing, and selling.',
  BridgePlus:
    'Interim financing for properties where minimal or no rehab is planned.',
  'New Construction':
    'New Construction loans for shovel-ready lots or teardown projects.',
  Rental30: 'Refinance or purchase Loans for single properties or portfolios.',
  'Rental30 Premier':
    'Refinance or purchase Loans for single properties or portfolios.',
};

export interface ProductPickerProps {
  disabled?: boolean;
}

export const ProductPicker = ({ disabled }: ProductPickerProps) => {
  const { data, isFetching } = useGetApiV1SettingsProgramsAndProductsQuery();

  const modifiedOptions = useMemo(() => {
    if (!data?.programs) return [];

    return data.programs;
  }, [data]);

  const options = useMemo(
    () =>
      convertToOptions({
        input: modifiedOptions,
        idKey: 'id',
        valueKeys: ['name'],
        descriptionMap: DESCRIPTION_MAP,
      }),
    [modifiedOptions]
  );

  if (isFetching) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  if (!data) {
    return null;
  }

  return (
    <RadioGroupControl
      disabled={disabled}
      name={FIELDS.LOAN_TYPE}
      ariaLabel="Choose a loan type"
      options={options}
    />
  );
};
