import { useCallback, useMemo } from 'react';
import { QUOTE_TYPE } from '@typings/common';
import {
  LolaBffApiContractsModelsPricingEngineResponseCalculateAndUpdateRentalQuoteResponse,
  LolaBffApiContractsModelsPricingEngineResponseCalculateAndUpdateShortTermFinanceQuoteResponse,
  usePutApiV1PricingEngineQuoteByQuoteIdRentalMutation,
  usePutApiV1PricingEngineQuoteByQuoteIdStfMutation,
} from '@api/output/api';
import { replaceFeesWithObject } from '@hooks/useQuotesApi/utils/replaceFeesWithObject.utils';
import { CalculatePayloadValuesWithFees } from '@hooks/useQuotesApi/utils/feeUtils.types';

export type UpdateHandler = (
  id: string,
  values: CalculatePayloadValuesWithFees
) => Promise<
  | LolaBffApiContractsModelsPricingEngineResponseCalculateAndUpdateRentalQuoteResponse
  | LolaBffApiContractsModelsPricingEngineResponseCalculateAndUpdateShortTermFinanceQuoteResponse
>;

export type UseUpdateApiReturnValue = [
  UpdateHandler,
  (
    | ReturnType<typeof usePutApiV1PricingEngineQuoteByQuoteIdRentalMutation>[1]
    | ReturnType<typeof usePutApiV1PricingEngineQuoteByQuoteIdStfMutation>[1]
  )
];

export const useQuotesUpdateApi = (
  quoteType: QUOTE_TYPE
): UseUpdateApiReturnValue => {
  const [updateRental, updateRentalResult] =
    usePutApiV1PricingEngineQuoteByQuoteIdRentalMutation();
  const [updateSTF, updateSTFResult] =
    usePutApiV1PricingEngineQuoteByQuoteIdStfMutation();

  const result = useMemo(
    () =>
      quoteType === QUOTE_TYPE.RENTAL ? updateRentalResult : updateSTFResult,
    [quoteType, updateRentalResult, updateSTFResult]
  );

  const update = useCallback(
    async (quoteId: string, values: CalculatePayloadValuesWithFees) => {
      if (!quoteId) {
        return Promise.reject('QuoteID is required');
      }

      const transformedValues = replaceFeesWithObject(values);

      if (quoteType === QUOTE_TYPE.SHORT_TERM) {
        return updateSTF({
          quoteId,
          lolaBffApiContractsModelsPricingEngineRequestUpdateShortTermFinanceQuoteRequest:
            transformedValues,
        }).unwrap();
      } else {
        return updateRental({
          quoteId,
          lolaBffApiContractsModelsPricingEngineRequestUpdateRentalQuoteRequest:
            transformedValues,
        }).unwrap();
      }
    },
    [quoteType, updateSTF, updateRental]
  );

  return useMemo(() => [update, result], [update, result]);
};
