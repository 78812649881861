import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { CalendarIcon, YearsSelector } from '@lola/ui-react-components';
import { Menu } from '@components/Menu';
import { ChipNavigation } from '@components/ChipNavigation/ChipNavigation.component';
import { getLast7yearsObject } from '@utils/date.utils';
import { CustomRangeSelector } from '@pages/dashboard/components/CustomRangeSelector/CustomRangeSelector.component';
import { PERIOD_OPTIONS, PeriodConfig } from './periodSelector.constants';
import styles from './periodSelector.module.scss';

const MIN_ALLOWED_YEAR = 2022;

export interface PeriodSelectorProps {
  period: PeriodConfig;
  onPeriodChange: (config: PeriodConfig) => void;
}

export const PeriodSelector = ({
  period,
  onPeriodChange,
}: PeriodSelectorProps) => {
  const [tab, setTab] = useState<string>(period.value);
  const [menuState, setMenuState] = useState<boolean>(false);

  const years = useMemo(() => {
    const LAST_YEARS_ALLOWED = 7;
    const currentYear = +dayjs().format('YYYY');
    return new Array(LAST_YEARS_ALLOWED)
      .fill(null)
      .map((_, index) => currentYear - index);
  }, []);

  const onSelect = useCallback((value: string | number) => {
    setTab(value.toString());
  }, []);

  const onYearSelect = useCallback(
    (year: number) => {
      const date = dayjs().set('year', year);
      onPeriodChange({
        value: 'year',
        start: date.startOf('year').format('YYYY-MM-DD'),
        end: date.endOf('year').format('YYYY-MM-DD'),
      });
      setMenuState(false);
    },
    [onPeriodChange]
  );

  useEffect(() => {
    if (tab === 'all') {
      onPeriodChange({
        value: 'all',
        ...getLast7yearsObject(),
      });
    }
  }, [tab]);

  const label = useMemo(() => {
    if (period.value === 'all') {
      return 'All time';
    }

    if (period.value === 'year') {
      return dayjs(period.start).format('YYYY');
    }

    if (period.value === 'custom') {
      return 'Custom';
    }
  }, [period]);

  return (
    <Menu
      onClose={() => setTab(period.value)}
      open={menuState}
      onOpenChange={setMenuState}
      trigger={
        <Button variant="outlined" startIcon={<CalendarIcon />} size="large">
          Stats period: {label}
        </Button>
      }
    >
      <div onClick={(e) => e.stopPropagation()} className={styles.wrapper}>
        <ChipNavigation
          options={PERIOD_OPTIONS}
          value={tab}
          onSelect={onSelect}
        />
        {tab === 'all' && (
          <Typography>
            We only track <b>the last 7 years</b> of activity. Start date is{' '}
            <b>{dayjs(period.start).format('DD MMM YYYY')}</b>
          </Typography>
        )}
        {tab === 'year' && (
          <YearsSelector
            years={years}
            onYearSelect={onYearSelect}
            minAllowedYear={MIN_ALLOWED_YEAR}
          />
        )}
        {tab === 'custom' && (
          <CustomRangeSelector
            period={period}
            minAllowedYear={MIN_ALLOWED_YEAR}
            onPeriodChange={(config) => {
              onPeriodChange(config);
              setMenuState(false);
            }}
          />
        )}
      </div>
    </Menu>
  );
};
