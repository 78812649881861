import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import { LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse } from '@api/output/api';

export const quoteNameRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse
) => {
  const path = `quote/${row.quoteType?.toLowerCase()}/${row.id}`;
  return (
    <Link component={NavLink} to={path} underline="none">
      <Typography variant="pBold">{row.name}</Typography>
    </Link>
  );
};
