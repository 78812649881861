import { useCallback } from 'react';
import { useLazyGetApiV1DocumentsDownloadUrlQuery } from '@api/output/api';
import { global } from '@config/global';

export const useDownloadUrl = (preview?: boolean) => {
  const [getDocumentUrl] = useLazyGetApiV1DocumentsDownloadUrlQuery();

  return useCallback(
    async (downloadId?: string | null) => {
      if (!downloadId) {
        return;
      }

      try {
        const downloadToken = await getDocumentUrl(
          { downloadId },
          false
        ).unwrap();
        const token = downloadToken as unknown as string;
        const encodedToken = encodeURIComponent(token);
        return `${
          global.bffApiUrl
        }api/v1/documents/download?downloadToken=${encodedToken}${
          preview ? '&preview=true' : ''
        }`;
      } catch (e) {
        console.warn('Unable to download file');
      }
    },
    [getDocumentUrl]
  );
};
