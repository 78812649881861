import { useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import {
  usePostApiV1PricingEngineQuoteByQuoteIdRentalPdfSendMutation,
  usePostApiV1PricingEngineQuoteByQuoteIdStfPdfSendMutation,
} from '@api/output/api';
import { QUOTE_TYPE } from '@typings/common';
import { useOpenToast } from '@hooks/useOpenToast';
import { useLolaTasks } from '@hooks/useLolaTasks';

export interface UseSendQuoteProps {
  quoteId?: string;
  quoteType?: string;
  isFinal?: boolean;
}

export const useSendQuote = ({
  quoteId,
  quoteType,
  isFinal,
}: UseSendQuoteProps) => {
  const { loanId = '' } = useParams();

  const [sendStf, stfResult] =
    usePostApiV1PricingEngineQuoteByQuoteIdStfPdfSendMutation();

  const [sendRental, rentalResult] =
    usePostApiV1PricingEngineQuoteByQuoteIdRentalPdfSendMutation();

  const openToast = useOpenToast(5000);

  const result = useMemo(() => {
    return quoteType === QUOTE_TYPE.RENTAL ? rentalResult : stfResult;
  }, [quoteType, stfResult, rentalResult]);

  const send = useMemo(() => {
    return quoteType === QUOTE_TYPE.RENTAL ? sendRental : sendStf;
  }, [quoteType]);

  const { refetchTasks: refetchStatuses } = useLolaTasks();

  const sendQuote = useCallback(async () => {
    try {
      await send({
        quoteId: quoteId as string,
        isFinal,
        lolaBffApiContractsModelsPricingEngineRequestSendQuoteRequest: {
          loanId,
        },
      }).unwrap();
      refetchStatuses();

      openToast({
        title: 'Email has been sent',
        description: 'Email with PDF has been sent.',
      });
    } catch (e) {
      openToast({
        title: 'Something went wrong',
        description: 'Please try again later',
        isError: true,
      });
    }
  }, [loanId, quoteId, send, isFinal]);

  return useMemo(
    () => ({
      sendQuote,
      isLoading: result.isLoading,
      disabled: result.isLoading || !quoteId,
    }),
    [result, quoteId]
  );
};
