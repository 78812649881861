import { useContext } from 'react';
import { FieldsPropsContext } from '@components/DynamicForm/contexts/FieldsProps.context';
import { convertConfigToFieldProps } from '@components/DynamicForm/utils';
import { FieldPropsDynamic } from '@components/DynamicForm/DynamicForm.typings';

export const useFieldSpecificProps = (fieldName: string): FieldPropsDynamic => {
  const fieldsProps = useContext(FieldsPropsContext);
  if (fieldsProps[fieldName]) {
    return convertConfigToFieldProps(fieldsProps[fieldName]);
  }

  return {};
};
