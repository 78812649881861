import React from 'react';
import { Typography } from '@mui/material';
import { LoanPartyInfo } from '@typings/common';
import styles from './userInfoRenderer.module.scss';

export const userInfoRenderer = (_: unknown, row: LoanPartyInfo) => {
  const { relationRole, firstName, lastName } = row;

  return (
    <div className={styles.userInfo}>
      <Typography paragraph={true} variant="pBold" mb={0}>
        {relationRole}
      </Typography>
      <div>
        {firstName && (
          <Typography
            className={styles.email}
            paragraph={true}
            variant="pSmall"
            component="span"
          >
            {firstName}
          </Typography>
        )}
        {lastName && (
          <Typography
            className={styles.email}
            paragraph={true}
            variant="pSmall"
            component="span"
          >
            &nbsp;{lastName}
          </Typography>
        )}
      </div>
    </div>
  );
};
