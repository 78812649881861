import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Grid, Paper } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CardSection } from '@lola/ui-react-components';
import { RadioGroup } from '@components/RadioGroup';
import { DynamicFields } from '@components/DynamicForm';
import { AvailableForms, forms } from '@form-configs/output';
import { LOAN_PROGRAM_KEY } from '@config/forms';
import { isMutationLoading } from '@store/selectors/api.selectors';
import { FormConfigContext } from '@components/DynamicForm/contexts/FormConfig.context';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { useIsPdfUploadAllowed } from '@pages/personalLoans/pages/buildMyLoan/pages/application/hooks/useIsPdfUploadAllowed';
import { useTasksByName } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTasksByName';
import { CONFIGURED_TASKS, TASK_SECTIONS } from '@typings/common';
import {
  ContactInfo,
  PortfolioExcelUpload,
  PortfolioPdfUpload,
  PropertyDetails,
} from './components';
import {
  APPLICATION_TYPE,
  APPLICATION_TYPE_OPTIONS,
  FORM_NAME_MAP,
  RADIO_GROUP_NAME,
} from './Application.constants';
import { Questionnaire } from './components/Questionnaire/Questionnaire.component';
import { usePropertiesNumber } from './hooks/usePropertiesNumber';

export const ApplicationPage = () => {
  const propertiesNumber = usePropertiesNumber();
  const { disabled } = useContext(LoanDisableContext);
  const [applicationType, setApplicationType] = useState(
    '' as APPLICATION_TYPE
  );
  const [disableRadio, setDisableRadio] = useState(false);
  const { watch } = useFormContext();
  const [loanProgram, properties] = watch([LOAN_PROGRAM_KEY, 'properties']);
  const portfolioTasks = useTasksByName(
    TASK_SECTIONS.PORTFOLIO,
    CONFIGURED_TASKS.PORTFOLIO
  );
  const portfolioTask = portfolioTasks?.[0]?.task;
  const isPdfUploadAllowed = useIsPdfUploadAllowed(portfolioTask, properties);

  const fields = useMemo(() => {
    return (
      forms[FORM_NAME_MAP[applicationType]?.[loanProgram] as AvailableForms] ??
      []
    );
  }, [applicationType, loanProgram]);

  const questionnaire = useCallback(
    () => (
      <Questionnaire
        applicationType={applicationType}
        loanProgram={loanProgram}
      />
    ),
    [applicationType, loanProgram]
  );

  useEffect(() => {
    if (!propertiesNumber) {
      setDisableRadio(false);
      return;
    }

    setDisableRadio(true);

    setApplicationType(
      propertiesNumber === 1
        ? APPLICATION_TYPE.SINGLE
        : APPLICATION_TYPE.PORTFOLIO
    );
  }, [propertiesNumber]);

  const typeChangeHandler = useCallback((_: unknown, value: string) => {
    setApplicationType(value as APPLICATION_TYPE);
  }, []);

  const isCreating = useSelector(
    isMutationLoading('postApiV1LoansByLoanIdAddProperties')
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardSection
          title="Application"
          description="Whether you work with one property or multiple properties (Portfolio),
            you can either fill out the application manually or download it as an excel file by downloading
            and filling out a sample of this application in advance."
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <RadioGroup
                name={RADIO_GROUP_NAME.APPLICATION_TYPE}
                label="Is this application for a single property or a portfolio of properties?"
                options={APPLICATION_TYPE_OPTIONS}
                value={applicationType}
                onChange={typeChangeHandler}
                row
                major
                disabled={disabled || disableRadio}
              />
            </Grid>
            {applicationType && (
              <Grid item xs={12}>
                {applicationType === APPLICATION_TYPE.SINGLE && (
                  <PropertyDetails
                    form={
                      <DynamicFields
                        fields={fields}
                        disabled={isCreating}
                        components={{
                          questionnaire,
                        }}
                      />
                    }
                  />
                )}
                {applicationType === APPLICATION_TYPE.PORTFOLIO && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <PortfolioExcelUpload task={portfolioTask} />
                    </Grid>
                    <Grid item xs={12}>
                      <PortfolioPdfUpload
                        task={portfolioTask}
                        disabled={!isPdfUploadAllowed}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </CardSection>
      </Grid>
      {applicationType === APPLICATION_TYPE.PORTFOLIO && (
        <Grid item xs={12}>
          <Paper elevation={2}>
            <DynamicFields
              fields={fields}
              components={{
                questionnaire,
              }}
              disabled={!isPdfUploadAllowed}
            />
          </Paper>
        </Grid>
      )}
      {applicationType && (
        <Grid item xs={12}>
          <ContactInfo
            applicationType={applicationType}
            loanProgram={loanProgram}
            disabled={
              !isPdfUploadAllowed &&
              applicationType === APPLICATION_TYPE.PORTFOLIO
            }
          />
        </Grid>
      )}
    </Grid>
  );
};
