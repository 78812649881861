export const PREFIX = 'feature_';

export enum FEATURES {
  EXPERIMENTAL = 'EXPERIMENTAL',
}

export const AVAILABLE_FEATURES: Record<FEATURES, string> = {
  EXPERIMENTAL: 'All experimental features',
};

export const convertNameToKey = (name: string) =>
  name.toLowerCase().replace(/\s/g, '_');

export const getFeatures = () => {
  return Object.values(AVAILABLE_FEATURES).map((value) => {
    const name = convertNameToKey(value);
    return {
      name,
      label: value,
      value: isFeatureEnabled(name),
    };
  });
};

export const isFeatureEnabled = (feature: string) =>
  !!window.localStorage.getItem(`${PREFIX}${feature}`);

export const toggleFeatureInStorage = (feature: string) => {
  const current = isFeatureEnabled(feature);
  if (current) {
    window.localStorage.removeItem(`${PREFIX}${feature}`);
  } else {
    window.localStorage.setItem(`${PREFIX}${feature}`, 'true');
  }
};

export const resetFeatures = () => {
  Object.values(AVAILABLE_FEATURES).forEach((value) => {
    const name = convertNameToKey(value);
    window.localStorage.removeItem(`${PREFIX}${name}`);
  });
};
