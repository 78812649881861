import React from 'react';
import { ListItemButton, Typography, Grid } from '@mui/material';
// components
import { FlashlightRoundIcon } from '@lola/ui-react-components';
import { LoanStatusChip } from '@pages/personalLoans/components';
//Icon
// types
import { LolaBffApiContractsModelsApproveMyLoanResponseCondition } from '@api/output/api';
import { ApproveMyLoanStatus } from '@pages/personalLoans/types/LoanTypes.type';
import { SetIcon } from '../../types/Condition.type';
import { PanelDataType } from '../ManageConditionPanel/types/ManageConditionUploadPanel.type';
// local Components
import { NotificationIcon } from '../index';
// styles
import styles from './conditionItem.module.scss';

export interface ConditonItemProps {
  condition: LolaBffApiContractsModelsApproveMyLoanResponseCondition;
  selected: boolean;
  onClick: (arg: PanelDataType) => void;
}

export const ConditionItem = ({
  condition,
  selected,
  onClick,
}: ConditonItemProps) => {
  return (
    <div className={styles.conditionItemContent}>
      <ListItemButton
        selected={selected}
        onClick={() => onClick(condition as PanelDataType)}
        className={selected ? styles.conditionItemActive : styles.conditionItem}
      >
        <Grid className={styles.conditionInfo}>
          <Typography variant="pBold">{condition.name}</Typography>
          <Typography variant="pCaption">
            {condition.technicalRequirements}
          </Typography>
        </Grid>
        {condition.isKeyClosingTask ? (
          <FlashlightRoundIcon className={styles.keyClosingTaskIcon} />
        ) : (
          <div className={styles.keyClosingTaskIcon}></div> //filler
        )}
        <Grid className={styles.conditionStatus}>
          <LoanStatusChip status={condition.status as ApproveMyLoanStatus} />
        </Grid>
        <Grid className={styles.conditionNotificationsGrid}>
          {condition.notifications &&
            condition.notifications.length > 0 &&
            condition.notifications.map((icon) => {
              return (
                <NotificationIcon
                  icon={icon as SetIcon}
                  key={condition.name + '-' + icon}
                />
              );
            })}
        </Grid>
      </ListItemButton>
    </div>
  );
};
