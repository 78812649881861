import React, { ReactNode } from 'react';
// MUI
import { Paper, Typography, Skeleton } from '@mui/material';
// styles
import styles from './CardActiveLoan.module.scss';

interface CardActiveLoanProps {
  isLoading?: boolean;
  totalLoans?: number;
  title?: string;
  children?: ReactNode;
}

export const CardActiveLoan = ({
  isLoading = false,
  title,
  totalLoans = 0,
  children,
}: CardActiveLoanProps): JSX.Element => {
  if (isLoading) {
    return (
      <Paper elevation={0} className={styles.container}>
        <header>
          <Skeleton role="skeleton" variant="text" width={160} />
          <Skeleton variant="text" width={80} />
        </header>
        <div>
          {Array.from(Array(3).keys()).map((e) => (
            <Skeleton
              key={e}
              variant="rounded"
              height={80}
              className={styles.skeleton}
            />
          ))}
        </div>
      </Paper>
    );
  }

  return (
    <Paper elevation={0} className={styles.container}>
      <header>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="pDescription">{totalLoans} loans</Typography>
      </header>
      <div className={styles.activeLoans}>{children}</div>
    </Paper>
  );
};
