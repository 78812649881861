import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { FileItem } from '@components/Uploader/Uploader.types';
import { UPLOADER_STATE } from '@components/Uploader/Uploader.constants';
import { UPLOAD_STATUS } from '@constants/uploader.constants';

export type UploadFileItem = FileItem & { body?: FormData };

const verifyFile = (file: FileItem) => !file.metadata?.error;

export interface UseUploaderWithSubmitProps {
  uploadOperationAsync: (file: UploadFileItem) => Promise<unknown>;
  deleteOperationAsync?: (file: FileItem) => Promise<unknown>;
  completeOperationAsync?: () => Promise<void>;
  uploadedFiles?: FileItem[];
  onClose?: () => void;
  status?: UPLOAD_STATUS;
}

export const useFilesUploaderWithSubmit = ({
  onClose,
  uploadedFiles,
  uploadOperationAsync,
  deleteOperationAsync,
  completeOperationAsync,
  status,
}: UseUploaderWithSubmitProps) => {
  const [files, setFiles] = useState<FileItem[]>([]);
  const [uploaderState, setUploaderSate] = useState(UPLOADER_STATE.INIT);

  useLayoutEffect(() => {
    if (uploadedFiles?.length) {
      setFiles(uploadedFiles);
      setUploaderSate(UPLOADER_STATE.SUCCESS);
      return;
    }
    setFiles([]);
    setUploaderSate(UPLOADER_STATE.INIT);
  }, [uploadedFiles, setFiles, setUploaderSate]);

  const verifiedFiles = useMemo(
    () => files?.filter((file) => !file.metadata?.error) || [],
    [files]
  );

  const uploadedFilesChanges = useMemo(() => {
    return (
      uploadedFiles?.length !== files.length ||
      !isEqual(
        uploadedFiles?.map((item) => item.fileName),
        files?.map((item) => item.fileName)
      )
    );
  }, [uploadedFiles, files]);

  const submitDisabled = useMemo(() => {
    const areFilesAdded = !verifiedFiles.length && files.length > 0;
    return (
      areFilesAdded ||
      !uploadedFilesChanges ||
      uploaderState === UPLOADER_STATE.LOADING
    );
  }, [uploaderState, verifiedFiles, files, uploadedFilesChanges]);

  const closeHandler = useCallback(() => {
    if (uploaderState === UPLOADER_STATE.LOADING) return;
    setUploaderSate(UPLOADER_STATE.INIT);
    if (onClose) onClose();
  }, [setFiles, setUploaderSate, onClose, uploaderState]);

  const uploadHandler = useCallback(
    async (files: FileItem[]) => {
      setFiles(files);
    },
    [setFiles]
  );

  const submitHandler = useCallback(async () => {
    const verifiedFiles = files?.filter((file) => verifyFile(file)) || [];

    if (!verifiedFiles.length) return;

    const filesToUpload = uploadedFiles?.length
      ? verifiedFiles.filter(({ fileName }) =>
          uploadedFiles?.find((file) => file.fileName !== fileName)
        )
      : verifiedFiles;
    setUploaderSate(UPLOADER_STATE.LOADING);

    try {
      await Promise.all(
        filesToUpload.map((file) => {
          const body = new FormData();
          body.append('file', file.file ?? '');

          return uploadOperationAsync({
            ...file,
            body,
          });
        })
      );

      if (completeOperationAsync) {
        await completeOperationAsync();
      }
      setUploaderSate(UPLOADER_STATE.SUCCESS);
    } catch (err) {
      setUploaderSate(UPLOADER_STATE.ERROR);
      setFiles((prevFiles) => {
        const filesToExclude = files.map(({ fileName }) => fileName);
        return prevFiles.filter(
          ({ fileName }) => !filesToExclude.includes(fileName)
        );
      });
    }
  }, [setUploaderSate, verifiedFiles, uploadedFiles]);

  const deleteHandler = useCallback(
    async (file: FileItem) => {
      const prevState = uploaderState;
      const prevFiles = files;

      if (!verifyFile(file)) {
        setFiles(
          prevFiles.filter(({ fileName }) => fileName !== file.fileName)
        );
        return;
      }

      setUploaderSate(UPLOADER_STATE.LOADING);

      try {
        if (deleteOperationAsync) {
          await deleteOperationAsync(file);
        }
        if (completeOperationAsync) {
          await completeOperationAsync();
        }

        setFiles(
          prevFiles.filter(({ fileName }) => fileName !== file.fileName)
        );
        setUploaderSate(prevFiles.length > 1 ? prevState : UPLOADER_STATE.INIT);
      } catch {
        setUploaderSate(UPLOADER_STATE.ERROR);
      }
    },
    [
      uploaderState,
      files,
      setUploaderSate,
      deleteOperationAsync,
      completeOperationAsync,
    ]
  );
  return {
    status,
    files,
    uploaderState,
    uploadHandler,
    closeHandler,
    submitHandler,
    submitDisabled,
    deleteHandler,
  };
};
