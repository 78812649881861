import { FieldProp } from '@components/DynamicForm/contexts/FieldsProps.context';

export const getFieldPropConfig = (fields: string[], props: FieldProp[]) =>
  fields.reduce<Record<string, FieldProp[]>>(
    (config, field) => ({
      ...config,
      [field]: props,
    }),
    {}
  );

export const convertArraysToObject = (fields: string[], values: string[]) => {
  return fields.reduce<Record<string, string>>((result, field, index) => {
    result[field] = values[index];
    return result;
  }, {});
};
