import React, { useCallback } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { Portal } from '@components/Portal';
import {
  LolaBffApiContractsModelsUser,
  useDeleteApiV1UsersMePhotoMutation,
  useGetApiV1UsersMeQuery,
  usePostApiV1UsersMePhotoMutation,
  usePutApiV1UsersMutation,
} from '@api/output/api';
import { useRoleName } from '@hooks/useRoleName';
import { useOpenToast } from '@hooks/useOpenToast';
import { DynamicItems } from '@components/DynamicForm/components/DynamicItems/DynamicItems.component';
import { PROFILE_PERSONAL_INFO_FOOTER_ID } from '@pages/profile/Profile.constants';
import {
  PageFooter,
  PhotoDeleteHandler,
  PhotoSelectHandler,
  UserCredentials,
  UserPhoto,
} from '@pages/profile/components';
import { prepareForm } from '../../../../utils/profile.utils';
import {
  INFO_FORM_CONFIG,
  INFO_FORM_RESOLVER,
  INFO_FORM_TOAST_CONFIG,
} from './InfoForm.constants';
import styles from './InfoForm.module.scss';

export const InfoForm = () => {
  const { data, isLoading, refetch } = useGetApiV1UsersMeQuery();
  const [save, { isLoading: isSaving }] = usePutApiV1UsersMutation();
  const [uploadPhoto] = usePostApiV1UsersMePhotoMutation();
  const [deletePhoto] = useDeleteApiV1UsersMePhotoMutation();

  const { firstName, lastName, role, createdAt, picture } = data ?? {};
  const roleName = useRoleName(role);

  const methods = useForm({
    defaultValues: prepareForm(data),
    mode: 'onBlur',
    resolver: INFO_FORM_RESOLVER,
  });

  const {
    formState: { isDirty },
  } = methods;

  const openToast = useOpenToast();

  const submitHandler = useCallback(
    methods.handleSubmit(
      (values) => {
        save({ lolaBffApiContractsModelsUsersUpdateAuth0UserRequest: values })
          .unwrap()
          .then((data: LolaBffApiContractsModelsUser | undefined) => {
            openToast(INFO_FORM_TOAST_CONFIG.SUCCESS);
            methods.reset(prepareForm(data));
            refetch();
          })
          .catch(() => openToast(INFO_FORM_TOAST_CONFIG.ERROR));
      },
      (err) => console.log(err)
    ),
    []
  );

  const uploadPhotoHandler: PhotoSelectHandler = useCallback((body) => {
    uploadPhoto({ body })
      .unwrap()
      .then(() => {
        openToast(INFO_FORM_TOAST_CONFIG.SUCCESS);
        refetch();
      })
      .catch(() => openToast(INFO_FORM_TOAST_CONFIG.ERROR));
  }, []);

  const deletePhotoHandler: PhotoDeleteHandler = useCallback(() => {
    deletePhoto()
      .unwrap()
      .then(() => {
        openToast(INFO_FORM_TOAST_CONFIG.SUCCESS);
        refetch();
      })
      .catch(() => openToast(INFO_FORM_TOAST_CONFIG.ERROR));
  }, []);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2">Personal information</Typography>
            </Grid>
            <Grid item xs={12} container columnSpacing={3}>
              <Grid item>
                <UserPhoto
                  firstName={firstName}
                  lastName={lastName}
                  picture={picture}
                  onPhotoSelect={uploadPhotoHandler}
                  onPhotoDelete={deletePhotoHandler}
                />
              </Grid>
              <Grid item xs container rowSpacing={1}>
                <UserCredentials
                  firstName={firstName}
                  lastName={lastName}
                  role={roleName}
                  date={createdAt}
                />
              </Grid>
            </Grid>
            <div className={styles.form}>
              <DynamicItems fields={INFO_FORM_CONFIG} />
            </div>
          </Grid>
        </form>
      </FormProvider>
      <Portal id={PROFILE_PERSONAL_INFO_FOOTER_ID}>
        <PageFooter
          loading={isSaving}
          isDirty={isDirty}
          onSubmit={submitHandler}
        />
      </Portal>
    </>
  );
};
