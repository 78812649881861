import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api, LolaBffApiContractsModelsUser } from '@api/output/api';

export interface AuthState {
  expiresAt?: number;
  accessToken?: string;
  refreshToken?: string;
  username?: string;
}

const accessKey = 'accessToken';
const usernameKey = 'username';
const refreshKey = 'refreshToken';
const expiresKey = 'expiresAt';

const accessToken = window.localStorage.getItem(accessKey) ?? '';
const refreshToken = window.localStorage.getItem(refreshKey) ?? '';
const expiresAtString = window.localStorage.getItem(expiresKey) ?? '';
const username = window.localStorage.getItem(usernameKey) ?? '';
const expiresAt = expiresAtString ? parseInt(expiresAtString) : 0;

const initialState: AuthState = {
  accessToken,
  refreshToken,
  expiresAt,
  username,
};

export interface SetTokensPayload {
  accessToken?: string | null;
  refreshToken?: string | null;
  expiresIn?: number | null;
}

export const rawClearTokens = (state: AuthState) => {
  window.localStorage.removeItem(accessKey);
  window.localStorage.removeItem(refreshKey);
  state.expiresAt = undefined;
  state.accessToken = undefined;
  state.refreshToken = undefined;
  state.username = undefined;
};

const rawSetTokens = (
  state: AuthState,
  action: PayloadAction<SetTokensPayload>
) => {
  const expiresIn = action.payload.expiresIn || 0;
  const expireDate = Date.now() + expiresIn * 1000;

  window.localStorage.setItem(accessKey, action.payload.accessToken || '');
  window.localStorage.setItem(refreshKey, action.payload.refreshToken || '');
  window.localStorage.setItem(expiresKey, expireDate.toString());

  Object.assign(state, {
    accessToken: action.payload.accessToken,
    refreshToken: action.payload.refreshToken,
    expiresAt: expireDate,
  });
};

export const rawSetUsername = (
  state: AuthState,
  action: PayloadAction<LolaBffApiContractsModelsUser>
) => {
  window.localStorage.setItem(usernameKey, action.payload.email || '');
  state.username = action.payload.email || undefined;
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    clearTokens: rawClearTokens,
    setTokens: rawSetTokens,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.postApiV1AuthToken.matchPending,
      rawClearTokens
    );
    builder.addMatcher(
      api.endpoints.postApiV1AuthToken.matchFulfilled,
      rawSetTokens
    );
    builder.addMatcher(
      api.endpoints.getApiV1UsersMe.matchFulfilled,
      rawSetUsername
    );
    builder.addMatcher(
      api.endpoints.getApiV1AuthLogout.matchFulfilled,
      rawClearTokens
    );
    builder.addMatcher(
      api.endpoints.getApiV1AuthLogout.matchRejected,
      rawClearTokens
    );
  },
});

export const {
  reducer,
  actions: { clearTokens, setTokens },
} = authSlice;
