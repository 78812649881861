import { useCallback } from 'react';
import { set } from 'react-hook-form';
import { usePutApiV1LoansByLoanIdMutation } from '@api/output/api';
import { CustomValueType } from '@typings/common';

export const useUpdateLoan = () => {
  const [updateLoan] = usePutApiV1LoansByLoanIdMutation();

  return useCallback(
    async (loanId: string, payload: Record<string, CustomValueType>) => {
      const body = Object.entries(payload).reduce((acc, [path, value]) => {
        if (path.includes('aggregations')) {
          const paths = path.split('.');
          const mode = paths.pop();
          const parentPath = paths.join('.');
          const focusPath = `${parentPath}.focused`;
          const overridePath = `${parentPath}.override`;
          set(acc, focusPath, mode);
          set(acc, overridePath, '');
        }

        set(acc, path, value);
        return acc;
      }, {});

      return updateLoan({
        loanId,
        lolaBffApiContractsModelsLoanRequestUpdateLoanModel: {
          ...body,
        },
      }).unwrap();
    },
    []
  );
};
