import React, { useCallback } from 'react';
// MUI
import {
  Grid,
  Paper,
  Typography,
  Alert,
  AlertTitle,
  Button,
  Skeleton,
  Link,
} from '@mui/material';
// router
import { NavLink, useParams } from 'react-router-dom';
// API
import { DownloadIcon, StatusInProgressIcon } from '@lola/ui-react-components';
import { useGetApiV1ClosemyloanFundingInformationQuery } from '@api/output/api';
// Icons
// global components
import { NoContent } from '@components/NoContent';
//Download Utils
import { downloadFile } from '@utils/file.utils';
// components
import { LoanFooterActions } from '@pages/personalLoans/components';
import { CloseMyLoanContainer } from '../../components/index';
//Hooks
import { useDownloadUrl } from '../../../../hooks/useDownloadUrl';
// styles
import styles from './Funding.module.scss';
// text
import FundingText from './__text__/FundingText.json';

const { title, noDataTitle, noDataText, description } = FundingText;

export const FundingPage = () => {
  // router
  const { loanId = '' } = useParams();
  //Hooks
  const getDocumentUrl = useDownloadUrl();

  // api
  const { data, isLoading } = useGetApiV1ClosemyloanFundingInformationQuery({
    dealId: loanId,
  });

  const downloadHandler = useCallback(
    async (downloadId: string, fileName: string) => {
      const url = await getDocumentUrl(downloadId);
      if (url) {
        downloadFile(fileName, url);
      }
    },
    []
  );
  // render
  const setDescription = (text: string | undefined | null) => {
    if (!text) return;

    const firstText = text?.split('.')[0];
    const secondText = text?.split('.')[1];

    return (
      <>
        {firstText && <Typography variant="p">{firstText}.</Typography>}
        <br />
        {secondText && <Typography variant="p">{secondText}.</Typography>}
      </>
    );
  };

  if (isLoading) {
    return (
      <CloseMyLoanContainer className={styles.container}>
        <Grid item xs={12}>
          <Skeleton variant="text" width={200} role="skeleton" />
          <Skeleton variant="text" />
          <Skeleton
            variant="rounded"
            height={100}
            className={styles.skeleton}
          />
          <Skeleton
            variant="rounded"
            height={100}
            className={styles.skeleton}
          />
          <Skeleton
            variant="rounded"
            width={200}
            height={35}
            className={styles.skeleton}
          />
        </Grid>
      </CloseMyLoanContainer>
    );
  }

  return (
    <CloseMyLoanContainer className={styles.container}>
      <Grid item xs={12}>
        <Typography variant="h2">{title}</Typography>
        {setDescription(data?.description ?? description)}
      </Grid>
      <Grid item xs={12}>
        {data?.alert && (
          <Alert severity={data?.alert?.messageStatus}>
            <AlertTitle>{data?.alert?.title}</AlertTitle>

            <section className={styles.alertError}>
              {data?.alert?.message}
            </section>
          </Alert>
        )}

        {!data?.alert && (
          <Paper elevation={2}>
            <NoContent
              title={noDataTitle}
              text={noDataText}
              icon={<StatusInProgressIcon />}
              variant="grey"
            />
          </Paper>
        )}
      </Grid>
      {data?.buttonDownload?.titleButton &&
        data?.buttonDownload?.fileDownloadId && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              className={styles.downLoad}
              onClick={() =>
                downloadHandler(
                  data?.buttonDownload?.fileDownloadId ?? '',
                  data?.buttonDownload?.titleButton ?? 'Closing statement'
                )
              }
            >
              <DownloadIcon />
              {data?.buttonDownload?.titleButton}
            </Button>
          </Grid>
        )}
      <LoanFooterActions>
        <Link component={NavLink} to="/" underline="none">
          <Button
            size="large"
            variant="outlined"
            disabled={data?.alert?.messageStatus !== 'success'}
          >
            Go to dashboard
          </Button>
        </Link>
      </LoanFooterActions>
    </CloseMyLoanContainer>
  );
};
