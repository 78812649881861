import { useFormContext } from 'react-hook-form';
import { useCallback, useContext } from 'react';
import { FormConfigContext } from '@components/DynamicForm/contexts/FormConfig.context';

export const useDynamicListControls = (name: string) => {
  const { watch, setValue } = useFormContext();
  const { onRemove, onAdd } = useContext(FormConfigContext);
  const arr = watch(name) || [];

  const addItem = useCallback(() => {
    onAdd?.(name, arr.length);
    setValue(name, arr.concat({}));
  }, [arr, onAdd]);

  const removeItem = useCallback(
    (indexToRemove: number) => {
      onRemove?.(name, arr[indexToRemove].id, indexToRemove);
      const result = arr.filter(
        (_: unknown, index: number) => index !== indexToRemove
      );
      setValue(name, result, { shouldDirty: true });
    },
    [arr]
  );

  return {
    addItem,
    removeItem,
  };
};
