import React, { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  FileUploader,
  FileUploaderButtonProps,
} from '@components/FileUploader';
import { UploadDrawer } from '@components/UploadDrawer/UploadDrawer.component';
import {
  FileItem,
  UploaderConfig,
  UploadNotificationConfig,
} from '@components/Uploader/Uploader.types';
import { useOpenState } from '@hooks/useOpenState';
import { useFileUploadHandler } from '@components/Uploader/hooks/useFileUploadHandler';
import {
  Notification,
  NotificationProps,
} from '@components/Notification/Notification.component';
import { UPLOAD_STATUS, UPLOAD_TYPE } from '@constants/uploader.constants';
import {
  LolaBffApiContractsModelsLoanTaskInfo,
  useDeleteApiV1DocumentsDeleteMutation,
} from '@api/output/api';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { UploaderDrawerConfig } from '@components/UploadDrawer/uploaderDrawer.types';
import { useTemplates } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTemplatesByTaskId';
import { UploadFileItem } from '@components/Uploader/hooks/useFilesUploader';

export type CustomFileUploaderProps = {
  task: LolaBffApiContractsModelsLoanTaskInfo | undefined;
  uploadedFiles?: FileItem[];
  notification?: NotificationProps;
  completeOperationAsync?: () => Promise<void>;
  uploadOperationAsync: (file: UploadFileItem) => Promise<unknown>;
  onDelete?: () => Promise<void>;
  status?: UPLOAD_STATUS;
  disabled?: boolean;
  label?: string;
  title?: string;
  placeholder?: string;
  description?: string;
  buttonRendererIfFileExists?: (
    props: FileUploaderButtonProps
  ) => React.ReactNode;
  buttonRendererIfFileDoesntExist?: (
    props: FileUploaderButtonProps
  ) => React.ReactNode;
  uploaderConfig?: UploaderConfig;
  drawerConfig?: UploaderDrawerConfig;
  notificationConfig?: UploadNotificationConfig;
  type?: UPLOAD_TYPE;
  submitOperation?: () => void;
  allowedExtensions?: string[];
};

export const CustomFileUploader = ({
  task,
  drawerConfig,
  uploaderConfig,
  allowedExtensions,
  type,
  title,
  label,
  description,
  placeholder,
  notification,
  onDelete,
  completeOperationAsync,
  uploadOperationAsync,
  status: statusProp,
  buttonRendererIfFileExists,
  buttonRendererIfFileDoesntExist,
  disabled: propDisabled,
  uploadedFiles: uploadedFilesProp,
}: CustomFileUploaderProps) => {
  const { loanId = '' } = useParams();
  const { disabled: contextDisabled } = useContext(LoanDisableContext);
  const isDisabled = propDisabled ?? contextDisabled;
  const [deleteDocument] = useDeleteApiV1DocumentsDeleteMutation();
  const [isDrawerOpen, setDrawerOpen, setDrawerClose] = useOpenState(false);
  const { templates, templatesAreBeingFetched } = useTemplates(
    task?.taskConfigurationId,
    allowedExtensions
  );
  const taskId = task?.taskId;
  const comments = task?.comments ?? '';

  const uploadedFiles = useMemo(
    () => uploadedFilesProp ?? task?.documents ?? [],
    [task, uploadedFilesProp]
  );

  const status = useMemo(
    () =>
      statusProp ??
      (uploadedFiles && uploadedFiles.length > 0
        ? UPLOAD_STATUS.UPLOADED
        : UPLOAD_STATUS.NOT_UPLOADED),
    [statusProp, uploadedFiles]
  );

  const deleteOperationAsync = useCallback(
    async ({ downloadId }: FileItem) => {
      try {
        if (taskId && downloadId) {
          await deleteDocument({
            taskId,
            downloadId,
          });

          if (onDelete) {
            await onDelete();
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [loanId, taskId, onDelete]
  );

  const uploaderProps = useFileUploadHandler({
    uploadedFiles,
    type,
    uploadOperationAsync,
    completeOperationAsync,
    deleteOperationAsync,
    onClose: setDrawerClose,
    status,
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <FileUploader
          status={status}
          type={type}
          title={title ?? ''}
          label={label}
          placeholder={placeholder ?? ''}
          onClickIfFileExists={setDrawerOpen}
          onClickIfFileDoesntExist={setDrawerOpen}
          buttonRendererIfFileExists={buttonRendererIfFileExists}
          buttonRendererIfFileDoesntExist={buttonRendererIfFileDoesntExist}
          uploadedFiles={uploadedFiles}
          disabled={isDisabled}
          uploadedFileDescription={description}
        />
      </Grid>
      {notification?.severity && (
        <Grid item xs={12}>
          <Notification {...notification} withIcon />
        </Grid>
      )}
      <UploadDrawer
        {...uploaderProps}
        isOpen={isDrawerOpen}
        uploaderConfig={uploaderConfig}
        drawerConfig={drawerConfig}
        comments={comments}
        templates={templates}
        notification={notification}
        type={type}
        templatesAreBeingFetched={templatesAreBeingFetched}
      />
    </Grid>
  );
};
