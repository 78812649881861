import get from 'lodash/get';
import { LolaBffApiContractsModelsLoanActiveLoan } from '@api/output/api';

export const getLoanIdentifier = (
  loan: LolaBffApiContractsModelsLoanActiveLoan
) => {
  const identifierFields = [
    'name',
    'loanIdentifier',
    'productType',
    'borrower.firstName',
    'borrower.lastName',
  ];

  return identifierFields.map((field) => get(loan, field, '')).join('');
};
