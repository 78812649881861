import React from 'react';
// router
import { useParams } from 'react-router-dom';
// MUI
import { Paper, Typography, Skeleton, Link, Grid, Button } from '@mui/material';
// hooks
import { AlertIcon, ArchiveIcon } from '@lola/ui-react-components';
import { useArchiveLoan } from '@pages/personalLoans/hooks/useArchiveLoan';
// icons
// API
import {
  useGetApiV1ApprovemyloanGetReviewStatusQuery,
  useGetApiV1LoansByLoanIdQuery,
} from '@api/output/api';
// components
import {
  LoanStatusSection,
  STATUS_LIST,
  ArchiveLoanModal,
} from '@pages/personalLoans/components/index';
import { NoContent } from '@components/NoContent';
import { Step } from '@components/verticalStepDetail/VerticalStepDetail.component';
// copy
import copyDetails, { CopyText } from '../../constants/copyDetails';
import {
  MessageArea,
  MessageAreaProps,
} from './components/MessageArea.component';
// style
import style from './ReviewStatus.module.scss';

export const ReviewStatusPage = () => {
  // route
  const { loanId = '' } = useParams();

  // API
  const { isLoading, data, isError } =
    useGetApiV1ApprovemyloanGetReviewStatusQuery({
      dealId: loanId,
    });

  const {
    data: { loanIdentifier, loanName } = {
      loanIdentifier: '',
      loanName: '',
    },
    refetch,
  } = useGetApiV1LoansByLoanIdQuery({
    loanId: loanId,
  });

  // hooks
  const { switchArchiveLoanModalOpen, handleArchive, archiveLoanModalOpen } =
    useArchiveLoan({
      loanIdentifier: loanIdentifier ?? '',
      loanName: loanName ?? '',
      callback: () => refetch(),
    });

  if (isLoading) {
    return (
      <Paper data-testid="skeleton" elevation={2}>
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <Skeleton
          style={{ marginBottom: '1rem' }}
          variant="text"
          sx={{ fontSize: '1.2rem' }}
        />
        <Skeleton variant="rounded" height={210} />
      </Paper>
    );
  }

  if (isError || !data) {
    return (
      <Paper elevation={2}>
        <NoContent
          title="This page will show you the progress and the results of your initial quote review. "
          text="Please wait for updates."
        />
      </Paper>
    );
  }

  // text content
  const { loanStatus, loanDescription = '', messagingArea, progress } = data;

  // copie
  const { title, text }: CopyText = copyDetails;

  // const
  const isSuspended = (loanStatus as STATUS_LIST) === STATUS_LIST.SUSPENDED;
  const isDeniedOrSuspended =
    isSuspended ||
    (loanStatus === STATUS_LIST.ERROR &&
      loanDescription.toLowerCase().includes('denied'));

  return (
    <>
      <Paper elevation={2} className={style.reviewStatus}>
        <Typography variant="h1">{title}</Typography>
        <Typography variant="p">{text}</Typography>

        {messagingArea && (
          <MessageArea {...(messagingArea as MessageAreaProps)} />
        )}

        <Paper className={style.paperTop} elevation={2} variant="filled">
          <LoanStatusSection
            loanStatus={loanStatus as STATUS_LIST}
            loanDescription={loanDescription}
            progress={progress as Step[]}
          >
            {isDeniedOrSuspended && (
              <Grid item sm={12}>
                <Paper className={style.paperContact} elevation={2}>
                  <AlertIcon />
                  <Typography variant="p">
                    Have questions? Contact our support at{' '}
                    <Link href="tel:+18003904212" underline="none">
                      800-390-4212
                    </Link>
                  </Typography>
                </Paper>
              </Grid>
            )}
          </LoanStatusSection>
        </Paper>

        {isSuspended && (
          <Grid className={style.paperTop} item sm={12}>
            <Button
              size="large"
              variant="outlined"
              startIcon={<ArchiveIcon />}
              onClick={switchArchiveLoanModalOpen}
            >
              Archive loan
            </Button>
          </Grid>
        )}
      </Paper>

      <ArchiveLoanModal
        isModalOpen={archiveLoanModalOpen}
        onClose={switchArchiveLoanModalOpen}
        handleArchive={handleArchive}
      />
    </>
  );
};
