import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import { LOAN_PROGRAM } from '@typings/common';
import { LOAN_PROGRAM_KEY } from '@config/forms';

const CONSTRUCTION_LOAN_PROGRAMS = new Set([
  LOAN_PROGRAM.NEW_CONSTRUCTION,
  LOAN_PROGRAM.FIX_N_FlIP,
]);

export const useIsConstructionLoanProgram = () => {
  const { watch } = useFormContext();
  const loanProgram = watch(LOAN_PROGRAM_KEY);

  return useMemo(() => {
    return CONSTRUCTION_LOAN_PROGRAMS.has(loanProgram);
  }, [loanProgram]);
};
