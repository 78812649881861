import { useState, useCallback } from 'react';
// types
import { FileItem, RulesConfig } from '@components/Uploader/Uploader.types';

interface UseUploadProps {
  rules: RulesConfig;
}

type UpdateState = {
  files: FileItem[];
  errors: null | string;
};

export const useUpload = ({ rules }: UseUploadProps) => {
  const { maxFiles } = rules;
  // states
  const [updateState, setUpdateState] = useState<UpdateState>({
    files: [],
    errors: null,
  });

  // action states
  const setFiles = (newFiles: FileItem[]) => {
    setUpdateState((prevState) => ({
      ...prevState,
      files: newFiles,
      errors: null,
    }));
  };

  const setErrors = (message: string) => {
    setUpdateState((prevState) => ({
      ...prevState,
      errors: message,
    }));
  };

  // actions
  const onUploadHandler = useCallback(
    (files: FileItem[]) => {
      const isError = files.find((file) => file?.metadata?.error);
      if (isError?.metadata?.error) {
        return setErrors(isError.metadata.error);
      }

      if (!maxFiles) {
        return setFiles(files.slice(-1));
      }

      setFiles(files);
    },
    [setFiles]
  );

  const uploadFiles = async (newFiles: FileItem[]) => {
    if (!newFiles) return;

    onUploadHandler(newFiles);
  };

  const deleteFiles = (name: string) => {
    const filteredFiles = updateState.files?.filter(
      (fileItem) => fileItem.fileName !== name
    );
    if (filteredFiles) onUploadHandler(filteredFiles);
  };

  const deleteAllFiles = () => {
    onUploadHandler([]);
  };

  const actions = {
    uploadFiles,
    deleteFiles,
    deleteAllFiles,
    setErrors,
  };

  return {
    ...actions,
    ...updateState,
  };
};
