// types
import { LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse } from '@api/output/api';

//Select Scenario for Rental Scenario
export const selectScenario = (
  details: LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse
) => {
  const isIndexScenario = details?.in?.scenario?.findIndex(
    (e) => e.preferred === 'Yes'
  );

  const returnedData = {
    ...details,
    pricingTerms: {
      ...details?.out?.scenarios?.[isIndexScenario ?? 0],
      ...details?.in?.scenario?.[isIndexScenario ?? 0],
    },
  };

  return returnedData;
};
