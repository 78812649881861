import React, { useCallback, useContext, useMemo } from 'react';
import { useOpenState } from '@hooks/useOpenState';
import { FileUploader } from '@components/FileUploader';
import { useFilesUploader } from '@components/Uploader/hooks/useFilesUploader';
import { UploadDrawer } from '@components/UploadDrawer/UploadDrawer.component';
import { useLoanDetails } from '@pages/personalLoans/pages/buildMyLoan/hooks/useLoanDetails';
import {
  CONFIGURED_TASKS,
  LOAN_PDF_APPLICATION_TYPE,
  TASK_SECTIONS,
} from '@typings/common';
import { usePDFUploader } from '@hooks/usePDFUploader';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { useTasksByName } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTasksByName';
import { useTemplates } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTemplatesByTaskId';
import { useTaskConfigurationIdByTaskKey } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTaskConfigurationIdByTaskKey';
import {
  BORROWER_APPLICATION_DRAWER_CONFIG,
  BORROWER_APPLICATION_UPLOADER_CONFIG,
} from './uploadBorrowerApplication.constants';

export interface UploadBorrowerApplicationProps {
  title: string;
  applicationType:
    | LOAN_PDF_APPLICATION_TYPE.BORROWER_GUARANTOR_APP
    | LOAN_PDF_APPLICATION_TYPE.GUARANTOR_APP;
  disabled?: boolean;
  existedEntityId?: string | null;
}

export const UploadBorrowerApplication = ({
  applicationType,
  title,
  existedEntityId,
  disabled: propDisabled,
}: UploadBorrowerApplicationProps) => {
  const { disabled: contextDisabled } = useContext(LoanDisableContext);
  const disabled = propDisabled || contextDisabled;
  const { refetchLoanDetails } = useLoanDetails();
  const tasks = useTasksByName(
    TASK_SECTIONS.BORROWER_UPLOAD_DOCUMENTS,
    CONFIGURED_TASKS.SPONSOR_APPLICATION
  );
  const { uploadPdf: uploadOperationAsync } = usePDFUploader(applicationType);
  const [isDrawerOpen, setDrawerOpen, setDrawerClose] = useOpenState(false);
  const taskConfigurationId = useTaskConfigurationIdByTaskKey(
    CONFIGURED_TASKS.SPONSOR_APPLICATION
  );
  const { templates, templatesAreBeingFetched } =
    useTemplates(taskConfigurationId);

  const uploadedFiles = useMemo(() => {
    const primaryTask = tasks?.find(
      ({ entityId }) => entityId === existedEntityId
    );

    return primaryTask?.task?.documents?.length
      ? [primaryTask?.task?.documents[0]]
      : [];
  }, [existedEntityId, tasks]);

  const completeOperationAsync = useCallback(async () => {
    setDrawerClose();
    refetchLoanDetails();
  }, [refetchLoanDetails]);

  const uploaderProps = useFilesUploader({
    uploadedFiles,
    uploadOperationAsync,
    completeOperationAsync,
    onClose: setDrawerClose,
  });

  return (
    <>
      <FileUploader
        title={title}
        placeholder="PDF, less than 20mb"
        buttonRendererIfFileExists={() => null}
        onClickIfFileDoesntExist={setDrawerOpen}
        uploadedFiles={uploadedFiles}
        disabled={disabled}
      />
      <UploadDrawer
        {...uploaderProps}
        isOpen={isDrawerOpen}
        uploaderConfig={BORROWER_APPLICATION_UPLOADER_CONFIG}
        drawerConfig={BORROWER_APPLICATION_DRAWER_CONFIG}
        templates={templates}
        templatesAreBeingFetched={templatesAreBeingFetched}
      />
    </>
  );
};
