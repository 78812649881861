import React from 'react';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import { StagesProgress } from '@components/StagesProgress';
import { ProgressIndicatorProps } from '../../types';
import { progressIndicator } from '../constants/progressIndicator.constants';
import styles from '../../styles/progressIndicator.module.scss';

export const ProgressIndicatorStages = ({
  totalSteps,
  completedSteps,
  locked,
}: ProgressIndicatorProps) => {
  return (
    <>
      <Typography
        className={classNames(styles.text, {
          [styles.locked]: locked,
        })}
      >
        {locked
          ? progressIndicator.notAvailableText
          : `${completedSteps}/${totalSteps}`}
      </Typography>
      <StagesProgress
        className={styles.progressBar}
        sectionsCount={totalSteps}
        completedCount={completedSteps}
      />
    </>
  );
};
