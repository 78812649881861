export const STEPS = [
  {
    id: '/profile/personal-info',
    title: 'Profile information',
    to: '/profile/personal-info',
  },
  {
    id: '/profile/licensing-info',
    title: 'Licensing Info',
    to: `/profile/licensing-info`,
  },
];

export const PROFILE_PERSONAL_INFO_FOOTER_ID = 'personal-info-footer-id';
