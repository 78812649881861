import React, { useMemo } from 'react';
import {
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Grid,
  Divider,
} from '@mui/material';
import { InformationIcon } from '@lola/ui-react-components';
import styles from 'src/components/QuoteCalculation/components/LeverageCalculations/components/LeverageCalculationsDrawer/components/LeverageFormBlock/leverageFormBlock.module.scss';
import { AdjustLeverageInput } from '@components/QuoteCalculation/components/LeverageCalculations/components/LeverageCalculationsDrawer/components/AdjustLeverageInput/AdjustLeverageInput.component';
import { LeverageFormBlockItem } from '@components/QuoteCalculation/components/LeverageCalculations/components/LeverageCalculationsDrawer/components/LeverageFormBlockItem/LeverageFormBlockItem.component';
import {
  LeverageDetails,
  LeverageFormItem,
} from '../../../../LeverageCalculations.type';

export interface LeverageCalculationsProps {
  title: string;
  tooltip?: string;
  adjustLeverageLabel: string;
  adjustLeverageDescriptionRenderer: (
    quoteDetails: LeverageDetails,
    leverageDetails: LeverageDetails
  ) => string;
  valueProperty: string;
  maxValueProperty: string;
  items: LeverageFormItem[];
  quoteDetails?: LeverageDetails;
  leverageDetails?: LeverageDetails;
}

export const LeverageFormBlock = ({
  title,
  tooltip,
  adjustLeverageLabel,
  adjustLeverageDescriptionRenderer,
  valueProperty,
  maxValueProperty,
  items,
  quoteDetails,
  leverageDetails,
}: LeverageCalculationsProps) => {
  const adjustLeverageDescription = useMemo(() => {
    return quoteDetails && leverageDetails
      ? adjustLeverageDescriptionRenderer(quoteDetails, leverageDetails)
      : '';
  }, [quoteDetails, leverageDetails, adjustLeverageDescriptionRenderer]);

  return (
    <Paper variant="filled" className={styles.wrapper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={styles.title}>
            <Typography variant="pBold">{title}</Typography>
            {tooltip && (
              <Tooltip title={tooltip}>
                <IconButton className={styles.iconWrapper}>
                  <InformationIcon className={styles.infoIcon} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <AdjustLeverageInput
            adjustLeverageLabel={adjustLeverageLabel}
            adjustLeverageDescription={adjustLeverageDescription}
            valueProperty={valueProperty}
            maxValueProperty={maxValueProperty}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {items.map((item) => (
              <Grid item xs={12} key={item.key}>
                <LeverageFormBlockItem details={leverageDetails} item={item} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
