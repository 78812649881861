import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
// types
import { LolaBffApiContractsModelsApproveMyLoanResponseShowCondition } from '@api/output/api';
import { ConditionItem } from '../ConditionItem/ConditionItem.component';
import { PanelDataType } from '../ManageConditionPanel/types/ManageConditionUploadPanel.type';
// style
import styles from './conditionsDisplay.module.scss';

export interface ConditionsDisplayInterface {
  conditions: LolaBffApiContractsModelsApproveMyLoanResponseShowCondition[];
  selectedItem: string | undefined;
  onClick?: (arg: PanelDataType) => void;
}

export const ConditionsDisplay = ({
  conditions,
  selectedItem,
  onClick,
}: ConditionsDisplayInterface) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  useEffect(() => {
    if (expanded.length < 1) {
      expandAll();
    }
  }, []);

  useEffect(() => {
    expandAll();
  }, [conditions]);

  const expandAll = useCallback(() => {
    const collapsibles = conditions.map((item) => {
      return item.maindependency || '';
    });
    setExpanded(collapsibles);
  }, [conditions]);

  const openPanel = (item: PanelDataType) => {
    onClick && onClick(item);
  };

  const handleChange = (panel: string | null | undefined) => {
    return (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (!panel) {
        return;
      }

      if (isExpanded) {
        const newExpanded = [...expanded];
        newExpanded.push(panel);
        setExpanded(newExpanded);
      } else {
        setExpanded(expanded.filter((el) => el !== panel));
      }
    };
  };
  return (
    <Grid item className={styles.conditionsDisplay}>
      {conditions &&
        conditions.length > 0 &&
        conditions.map((info) => {
          return (
            <Accordion
              key={info.mainDependencyId}
              className={styles.conditionsAccordeon}
              expanded={!!expanded.find((el) => el === info.maindependency)}
              onChange={handleChange(info.maindependency)}
              data-testid="main-dependency"
            >
              <AccordionSummary
                aria-controls={`${info.maindependency}-content`}
                className={styles.accordionSummary}
              >
                <Typography
                  sx={{ alignSelf: 'center' }}
                  variant="h3"
                  className={styles.accordionSummaryText}
                >
                  {info.maindependency}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={styles.accordionDetails}>
                {info.conditions &&
                  info.conditions.map((subItem) => {
                    const subItemId = subItem.conditionId || '';

                    return (
                      <ConditionItem
                        key={`${info.mainDependencyId}-${subItem.name}-mapItem`}
                        condition={subItem}
                        onClick={(item) => openPanel(item)}
                        selected={selectedItem === subItemId}
                      />
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Grid>
  );
};
