import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { USER_EMAIL_ERROR_TEXT_PLACEHOLDER } from '@constants/text.constants';

export interface Error {
  title: string;
  message: string;
}

const ERRORS: Record<number | string, Error> = {
  422: {
    title: 'Email exists',
    message: 'Such email already exists',
  },
  404: {
    title: 'Email not found',
    message: `User ${USER_EMAIL_ERROR_TEXT_PLACEHOLDER} not found`,
  },
  default: {
    title: 'Error happened',
    message: 'Unexpected error happened',
  },
};

export const getCorrectedError = (
  error: FetchBaseQueryError | SerializedError | undefined
): Error => {
  const status = error && 'status' in error ? error.status : 'default';

  return ERRORS[status] ?? ERRORS.default;
};
