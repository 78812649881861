import React, { PropsWithChildren, useMemo } from 'react';
import { Button, Typography } from '@mui/material';
import { ListIcon } from '@lola/ui-react-components';
import { EntityType } from '../../NewExistingSelector.types';
import { DESCRIPTION } from './SelectedEntityType.constants';
import styles from './selectedEntityType.module.scss';

export interface SelectedBorrowerProps extends PropsWithChildren {
  entityType: EntityType;
  onBorrowerChange: () => void;
  title?: string;
  selected?: boolean;
  disabled?: boolean;
}

export const SelectedEntityType = ({
  entityType,
  title,
  selected,
  onBorrowerChange,
  children,
  disabled,
}: SelectedBorrowerProps) => {
  const dynamicText = useMemo(
    () => (selected ? 'Change' : 'Select'),
    [selected]
  );

  if (!title) {
    return null;
  }

  return (
    <>
      <section className={styles.wrapper}>
        <header className={styles.header}>
          <div className={styles.title}>
            <Typography variant="pBold">{title}</Typography>
            <Typography variant="pDescription">
              {DESCRIPTION[entityType].replace(
                '%action%',
                dynamicText.toLowerCase()
              )}
            </Typography>
          </div>
          <Button
            variant="outlined"
            onClick={onBorrowerChange}
            startIcon={<ListIcon />}
            disabled={disabled}
          >
            {dynamicText}
          </Button>
        </header>
      </section>
      {children}
    </>
  );
};
