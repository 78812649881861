import React from 'react';
import { Chip } from '@mui/material';
import { SCENARIO_ELIGIBILITY } from '@components/QuoteCalculation/components/ScenarioRentalCalculation/ScenarioRentalCalculation.constants';
import { STATUS_CONFIG } from '@config/status';
import { ScenarioProps } from '../../ScenarioRentalCalculation.types';

export const ScenarioEligibility = ({ scenario }: ScenarioProps) => {
  const { eligibility } = scenario;

  const config =
    eligibility === SCENARIO_ELIGIBILITY.ELIGIBLE
      ? STATUS_CONFIG.SUCCESS_FILLED
      : STATUS_CONFIG.ERROR_FILLED;

  return <Chip label={eligibility} {...config} />;
};
