import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const getPasswordValidationError = (sybmol: string) =>
  `Must contain at least one ${sybmol}`;

const yupNativeSchema = yup
  .object({
    newPassword: yup
      .string()
      .required('New password is required')
      .min(8, 'Must have at least 8 characters')
      .matches(/\d/, getPasswordValidationError('number'))
      .matches(/[A-Za-z]/, getPasswordValidationError('letter'))
      .matches(
        /[$&+,:;=?@#|'<>.^*()%!-]/,
        getPasswordValidationError("special symbol ($&+,:;=?@#|'<>.^*()%!-)")
      ),
  })
  .required();

export const passwordFormSchema = yupResolver(yupNativeSchema);
