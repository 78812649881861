import React, { useState, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { Typography, Button, Grid } from '@mui/material';
import { Modal } from '@components/Modal';
import styles from './formExitConfirmationPrompt.module.scss';

interface FormExitConfirmationPromptProps {
  when: boolean;
  title?: string;
  message?: string;
}

enum BlockerState {
  BLOCKED = 'blocked',
}

export const FormExitConfirmationPrompt = ({
  when,
  title = 'Warning',
  message = 'You are leaving the page. Data will be lost - are you sure?',
}: FormExitConfirmationPromptProps) => {
  const [open, setOpen] = useState(false);
  const blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === BlockerState.BLOCKED && !when) {
      blocker.reset();
    }
  }, [blocker, when]);

  useEffect(() => {
    if (blocker.state === BlockerState.BLOCKED) {
      setOpen(true);
    }
  }, [blocker]);

  const handleClose = () => {
    setOpen(false);
    if (blocker.reset !== undefined) {
      blocker.reset();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    if (blocker.proceed !== undefined) {
      blocker.proceed();
    }
  };

  return (
    <Modal title={title} isOpen={open} onClose={handleClose}>
      <>
        <Typography>{message}</Typography>
        <Grid item xs={12} classes={{ root: styles.footer }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            classes={{ root: styles.button }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            classes={{ root: styles.button }}
          >
            Leave the page
          </Button>
        </Grid>
      </>
    </Modal>
  );
};
