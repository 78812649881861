import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, LinearProgress } from '@mui/material';
import { useRole } from '@hooks/useRole';
import { useUserId } from '@hooks/useUserId';
import { usePostApiV1TeamsMutation } from '@api/output/api';
import { ROLES, SetModalContent } from '@typings/common';
import { usePostUpdate } from '@hooks/usePostUpdate';
import { CreateTeamForm } from '../../../../../../components/CreateTeamForm/CreateTeamForm.component';
import { FormFooter } from '../FormFooter/FormFooter.component';
import {
  MODAL_STATE,
  ModalContentType,
} from '../../../../TeamMembers.constants';

interface CreateTeamProps {
  setModalContent: SetModalContent<ModalContentType>;
}

export const CreateTeam = ({ setModalContent }: CreateTeamProps) => {
  const currentRole = useRole();
  const currentUserId = useUserId();

  const methods = useForm();
  const managerUserId = methods.watch('managerUserId');

  const handleClose = useCallback(
    () => setModalContent({ state: MODAL_STATE.CLOSE }),
    []
  );

  const [createTeam, { isUpdating }] = usePostUpdate(
    usePostApiV1TeamsMutation,
    handleClose
  );

  const onFormSubmit = useMemo(() => {
    return methods.handleSubmit((values) => {
      const { teamId, managerUserId } = values;
      createTeam({
        lolaBffApiContractsModelsRequestsManageTeamRequest: {
          sourceTeamId: null,
          userId:
            currentRole === ROLES.BROKER_MANAGER
              ? currentUserId
              : managerUserId,
          destinationTeamId: teamId,
          previousUserId: null,
          action: 'Create',
        },
      });
    });
  }, [methods.handleSubmit]);

  if (isUpdating) {
    return <LinearProgress />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit}>
        <CreateTeamForm role={currentRole} selectVariant="filled" />
        <FormFooter>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={
              currentRole === ROLES.BROKER_MANAGER ? false : !managerUserId
            }
          >
            Create team
          </Button>
        </FormFooter>
      </form>
    </FormProvider>
  );
};
