import React from 'react';
import { Typography } from '@mui/material';

interface WidgetContentBody {
  label?: string | number;
  labelClass?: string;
  labelPostfix?: string;
}

export const WidgetContentBody = ({
  label,
  labelClass,
  labelPostfix,
}: WidgetContentBody) => {
  return (
    <section>
      <Typography variant="h2Bold" className={labelClass || ''}>
        {labelPostfix ? `${label} / ` : label}
      </Typography>
      {labelPostfix && (
        <Typography variant="h2Description">{labelPostfix}</Typography>
      )}
    </section>
  );
};
