import { useMemo } from 'react';
import { ControlType, ValidationRule } from '@form-configs/types';
import { convertYupValidationToControllerRules } from '@components/DynamicForm/utils/yupToControllerRules';
import {
  VALIDATION_DEFAULT_MESSAGES,
  VALIDATION_RULES,
} from '@components/DynamicForm/utils/validation.types';

type UseFieldValidationProps = {
  validation?: ValidationRule[];
  locked?: boolean;
  required?: boolean | string;
  controlType?: ControlType;
};

export const useFieldValidation = ({
  validation,
  locked,
  required,
  controlType,
}: UseFieldValidationProps) => {
  const isEnabled = useMemo(
    () => !locked && !!validation?.length,
    [locked, validation]
  );

  const convertedRules = useMemo(() => {
    return isEnabled
      ? convertYupValidationToControllerRules(validation || [], controlType)
      : { validate: undefined };
  }, [isEnabled, validation]);

  const requiredProp = useMemo(() => {
    if (locked || !required) {
      return {
        value: false,
        message: '',
      };
    }

    const requiredRule = validation?.find(
      ({ rule }) => rule === VALIDATION_RULES.REQUIRED
    );
    const isRequiredFromRule = !!requiredRule;
    const messageFromRule =
      typeof requiredRule?.args?.[0] === 'string' ? requiredRule.args[0] : '';

    return {
      value: isRequiredFromRule || !!required,
      message:
        messageFromRule ||
        (typeof required === 'string'
          ? required
          : VALIDATION_DEFAULT_MESSAGES.REQUIRED),
    };
  }, [validation, required, locked]);

  return {
    convertedRules,
    requiredProp,
  };
};

export default useFieldValidation;
