import { useDeferredValue, useMemo } from 'react';
import { LolaBffApiContractsModelsUser } from '@api/output/api';
import { checkInvitedUser } from '../InviteForm.utils';

export const useInviteUserCheck = (
  email: string,
  users: LolaBffApiContractsModelsUser[]
) => {
  const deferredEmail = useDeferredValue(email);
  return useMemo(
    () => checkInvitedUser(deferredEmail, users),
    [deferredEmail, users]
  );
};
