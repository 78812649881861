import { LolaBffApiContractsModelsLoanTaskInfo } from '@api/output/api';

export const isCreditReportExpired = (
  task?: LolaBffApiContractsModelsLoanTaskInfo
): boolean => {
  const document = task?.documents?.[task?.documents.length - 1];

  if (!document?.createdAt) return false;

  const expiresInDays = 180;
  const expiryDate = new Date(
    new Date(document.createdAt).getTime() + expiresInDays * 24 * 60 * 60 * 1000
  );
  const currentDate = new Date();

  return currentDate > expiryDate;
};
