import { Typography } from '@mui/material';
import React from 'react';
import { PdfIcon } from '@lola/ui-react-components';
import { useOpenState } from '@hooks/useOpenState';
import { Modal } from '@components/Modal';
import { DocumentPDFViewer } from '@components/DocumentPDFViewer/DocumentPDFViewer.component';

export interface DownloadPDFItemProps {
  url: string;
  fileName?: string | null;
  fileSize?: string;
  className?: string;
}
export const DownloadPDFItem = ({
  url,
  fileName,
  fileSize,
  className,
}: DownloadPDFItemProps) => {
  const [isModalOpen, openModal, closeModal] = useOpenState();

  return (
    <>
      <div className={className} onClick={openModal}>
        <PdfIcon />
        <div>
          <Typography variant="h4">{fileName}</Typography>
          <Typography variant="pSmall">{fileSize}</Typography>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          noSpace
          title={fileName ?? 'PDF File'}
          isOpen={true}
          onClose={closeModal}
          width="auto"
        >
          <DocumentPDFViewer url={url} />
        </Modal>
      )}
    </>
  );
};
