import React from 'react';
// MUI
import { Paper } from '@mui/material';

// style
import style from '../../ListOfActions.module.scss';

// wrapper
export const ListOfActionWrapper = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => (
  <Paper elevation={2} variant="filled" className={style.actionWrapper}>
    {children}
  </Paper>
);
