import { useCallback, useMemo } from 'react';
//Libraries
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { useParams } from 'react-router-dom';
//API
import {
  GetApiV1MeridianCraApiResponse,
  LolaBffMeridianApiClientModelsRequestMeridianCraRequest,
  LolaBffMeridianApiClientModelsRequestReissueCreditRequest,
} from '@api/output/api';
//Constants
import { ERROR_PULL } from '../reIssueCreditForm.constants';

export const useReIssueCreditForm = () => {
  const { loanId = '' } = useParams();

  const useHandleCraOptions = (craOptions: GetApiV1MeridianCraApiResponse) => {
    return useMemo(() => {
      if (!craOptions.length) return;
      return craOptions.map((info) => {
        return {
          label: info.name,
          value: info.code,
        };
      });
    }, [craOptions]);
  };

  const handleFieldError = useCallback(
    (errorString: string, setError: UseFormSetError<FieldValues>) => {
      ERROR_PULL.forEach((error) => {
        if (errorString.includes(error.stringKey)) {
          setError(error.fieldName, {
            type: 'custom',
            message: error.message,
          });
        }
      });
    },
    []
  );
  const handlePayloadBuilding = useCallback(
    (
      values: FieldValues,
      craOptions: GetApiV1MeridianCraApiResponse,
      partyId?: string | null
    ): LolaBffMeridianApiClientModelsRequestReissueCreditRequest => {
      const cra = craOptions.find((item) => item.code === values?.cra);
      return {
        loanId,
        partyId: partyId ?? '',
        firstName: values?.firstName ?? '',
        lastName: values?.lastName ?? '',
        ssn: values?.reIssueSsn.replaceAll('-', '') ?? '',
        vendorOrderIdentifier: values?.reIssueNumber ?? '',
        meridianCra:
          cra as LolaBffMeridianApiClientModelsRequestMeridianCraRequest,
      };
    },
    []
  );
  const handleScrollToScore = useCallback((partyId?: string | null) => {
    const element = document.getElementById(partyId ?? '');
    if (element) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + 300,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  return {
    useHandleCraOptions,
    handleFieldError,
    handlePayloadBuilding,
    handleScrollToScore,
  };
};
