import React, { useMemo } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import { Review, Submit } from './components';
import { useTransformReviewAndSubmitSteps } from './hooks/useTransormReviewAndSubmitSteps';
import { useFinalDocumentTasks } from './hooks/useFinalDocumentTasks';

export const ReviewAndSubmitPage = () => {
  const { isLoading } = useFinalDocumentTasks();
  const buildMyLoanSteps: ProgressStepperItem[] = useOutletContext();
  const stepsData = useTransformReviewAndSubmitSteps(buildMyLoanSteps);

  const isAllStepsCompleted = useMemo(
    () =>
      !!stepsData.length &&
      stepsData.every(({ status }) => status === ProgressStepItemStatus.DONE),
    [stepsData]
  );

  if (isLoading) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Review stepsData={stepsData} />
      </Grid>
      <Grid item xs={12}>
        <Submit isAllStepsCompleted={isAllStepsCompleted} />
      </Grid>
    </Grid>
  );
};
