import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { LolaBffApiContractsModelsLoanTaskInfo } from '@api/output/api';
import { UploadDocumentsList } from '@pages/personalLoans/pages/buildMyLoan/components/UploadDocumentsList/UploadDocumentsList.component';
import styles from './uploadDocumentsCategory.module.scss';

export interface UploadDocumentsCategoryProps {
  title: string;
  tasks: LolaBffApiContractsModelsLoanTaskInfo[];
  uploadDrawerHandler: (
    task: LolaBffApiContractsModelsLoanTaskInfo
  ) => () => void;
}

export const UploadDocumentsCategory = ({
  uploadDrawerHandler,
  title,
  tasks,
}: UploadDocumentsCategoryProps) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleAccordionStateChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded);
  };

  return (
    <div className={styles.documentsListWrapper}>
      <Accordion expanded={expanded} onChange={handleAccordionStateChange}>
        <AccordionSummary className={styles.accordionSummary}>
          <Typography variant="pBold" className={styles.accordionSummaryText}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <UploadDocumentsList
            tasks={tasks}
            uploadDrawerHandler={uploadDrawerHandler}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
