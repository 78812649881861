import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetApiV1LoansByLoanIdPartiesQuery,
  useGetApiV1LoansByLoanIdQuery,
  useGetApiV1LoansByLoanIdPropertiesQuery,
  useGetApiV1LoansByLoanIdDocumentTasksQuery,
} from '@api/output/api';
import { createLoanData } from '@pages/personalLoans/pages/buildMyLoan/utils/loan.utils';
import { BuildMyLoanFormData } from '../buildMyLoan.type';

interface UseLoanDetailsReturnType {
  data: BuildMyLoanFormData;
  refetchLoan: () => Promise<unknown>;
  refetchLoanDetails: () => Promise<unknown>;
  refetchParties: () => Promise<unknown>;
  isError: boolean;
  isLoading: boolean;
  isLoanFetching: boolean;
  arePartiesFetching: boolean;
}

export const useLoanDetails = (): UseLoanDetailsReturnType => {
  const { loanId = '' } = useParams();
  const {
    data: loan,
    isLoading: isLoanLoading,
    isFetching: isLoanFetching,
    refetch: refetchLoan,
    isError,
  } = useGetApiV1LoansByLoanIdQuery({ loanId });

  const {
    data: parties,
    isLoading: isPartyLoading,
    refetch: refetchParties,
    isFetching: arePartiesFetching,
  } = useGetApiV1LoansByLoanIdPartiesQuery({ loanId });

  const {
    data: properties,
    isLoading: isPropertiesLoading,
    refetch: refetchProperties,
  } = useGetApiV1LoansByLoanIdPropertiesQuery({ loanId });

  const {
    data: tasks,
    isLoading: isTasksLoading,
    refetch: refetchTasks,
  } = useGetApiV1LoansByLoanIdDocumentTasksQuery({ loanId });

  const refetchData = useCallback(async () => {
    await Promise.all([
      refetchLoan(),
      refetchParties(),
      refetchProperties(),
      refetchTasks(),
    ]);
  }, [refetchLoan, refetchParties, refetchProperties, refetchTasks]);

  return useMemo(() => {
    const isLoading = [
      isLoanLoading,
      isPartyLoading,
      isPropertiesLoading,
      isTasksLoading,
    ].includes(true);

    return {
      data: createLoanData(loan, parties, properties, tasks),
      refetchLoanDetails: refetchData,
      refetchLoan,
      refetchParties,
      isError,
      isLoading: isLoading || !loan,
      isLoanFetching,
      arePartiesFetching,
    };
  }, [
    loan,
    parties,
    properties,
    tasks,
    isLoanLoading,
    isPartyLoading,
    isPropertiesLoading,
    isTasksLoading,
    isLoanFetching,
    arePartiesFetching,
    refetchData,
    isError,
  ]);
};
