import React, { useState, useEffect, useCallback } from 'react';
// MUI
import { Grid, Typography } from '@mui/material';
// Libraries
import { TableColumnType } from 'antd';
// Hooks
import { createStatusRenderer } from '@utils/renderers/statusRenderer/createStatusRenderer';
import { usePaymentParties } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/hooks/usePaymentParties';
// Types
import { PAYMENT_STATUS_TYPE } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { LoanPartyInfo } from '@typings/common';
// components
import { userInfoRenderer } from '@pages/personalLoans/renders';
import { PAYMENT_STATUS_CONFIG } from '@pages/personalLoans/pages/buildMyLoan/components/Payment/Payment.constants';
import { paymentRateRenderer } from './renderers/paymentRateRenderer/paymentRateRenderer';
import { CreditScoreCollectionOptions, CreditScore } from './components';
import { Payment } from './../../components';
import { PAYMENT_RATE_VALUE } from './CreditReport.constants';

const paymentColumns: TableColumnType<LoanPartyInfo>[] = [
  {
    dataIndex: 'firstName',
    key: 'firstName',
    width: '30%',
    render: userInfoRenderer,
  },
  {
    dataIndex: 'contactPointEmailValue',
    key: 'contactPointEmailValue',
    width: '30%',
  },
  {
    dataIndex: 'fundedTotalAmount',
    key: 'fundedTotalAmount',
    width: '20%',
    render: paymentRateRenderer,
  },
  {
    dataIndex: 'paymentStatus',
    render: createStatusRenderer<PAYMENT_STATUS_TYPE>(PAYMENT_STATUS_CONFIG),
    width: '20%',
  },
];

export const CreditReportPage = () => {
  const {
    parties,
    change: setPartiesMode,
    isStatusLoading,
    refetchStatuses,
  } = usePaymentParties();
  const [isExpandedPayment, setIsExpandedPayment] = useState(false);
  const [
    currentlyExpandedCreditScoreDetailsId,
    setCurrentlyExpandedCreditScoreDetailsId,
  ] = useState<string | null>(null);
  const [creditReportAccordionExpanded, setCreditReportAccordionExpanded] =
    useState(true);

  useEffect(() => {
    setIsExpandedPayment(parties.length !== 0);
  }, [parties]);

  const handleExpandPaymentChange = useCallback(
    () => setIsExpandedPayment((prev) => !prev),
    []
  );

  return (
    <Grid container spacing={2}>
      <CreditScoreCollectionOptions
        onChange={setPartiesMode}
        setCreditReportAccordionExpanded={setCreditReportAccordionExpanded}
        setCurrentlyExpandedCreditScoreDetailsId={
          setCurrentlyExpandedCreditScoreDetailsId
        }
      />
      <Grid item xs={12}>
        <Payment
          productCode="credit_report"
          parties={parties}
          columns={paymentColumns}
          paymentRateValue={PAYMENT_RATE_VALUE}
          disabled={!parties?.length}
          refetchStatuses={refetchStatuses}
          setIsExpanded={handleExpandPaymentChange}
          isStatusLoading={isStatusLoading}
          isExpanded={isExpandedPayment}
          description={
            <Typography variant="p">
              Send payment links to borrowers, or pay yourself by credit card
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CreditScore
          currentlyExpandedCreditScoreDetailsId={
            currentlyExpandedCreditScoreDetailsId
          }
          setCreditReportAccordionExpanded={setCreditReportAccordionExpanded}
          setCurrentlyExpandedCreditScoreDetailsId={
            setCurrentlyExpandedCreditScoreDetailsId
          }
          creditReportAccordionExpanded={creditReportAccordionExpanded}
        />
      </Grid>
    </Grid>
  );
};
