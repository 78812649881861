export enum ApproveMyLoanStatus {
  Approved = 'Approved',
  Uploaded = 'Uploaded',
  InReview = 'In Review',
  Submitted = 'Submitted',
  NewCondition = 'New condition',
  Denied = 'Denied',
}

export enum ActiveLoanStatus {
  NotSet = 'Not Set',
  Unsubmitted = 'Unsubmitted',
  NotSubmitted = 'NotSubmitted',
  Submitted = 'Submitted',
  ClearToClose = 'Clear to close',
  Closed = 'Closed',
  Archived = 'Archived',
  ExceptionRequest = 'Exception request',
  Pending = 'Pending',
  ChangeRequest = 'Change request',
  InDevelopment = 'In Development',
  Delivered = 'Delivered',
  Closing = 'Closing',
}
