import get from 'lodash/get';
import { Quote, QUOTE_TYPE } from '@typings/common';
import { LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse } from '@api/output/api';

export interface PrefilledFieldConfig {
  label: string;
  quotePath: string | ((quote: Quote) => string | number | boolean | undefined);
  loanPath: string;
}

export interface PrefilledField extends PrefilledFieldConfig {
  quoteValue?: string;
  loanValue?: string;
}

export interface QuoteConfig {
  propertiesNumberField: string;
  fields: PrefilledFieldConfig[];
}

export const STF_PREFILL_FIELDS: PrefilledFieldConfig[] = [
  {
    label: 'Loan Purpose',
    quotePath: 'in.loanSummary.loanPurpose',
    loanPath: 'properties[0].propertyLoanPurpose',
  },
  {
    label: 'Current/original purchase price',
    quotePath: 'in.dealTerms.lesserOfLotOrPurchase',
    loanPath: 'properties[0].originalPurchasePriceAmount',
  },
  {
    label: 'Address Line 1',
    quotePath: 'in.loanSummary.propertyAddress',
    loanPath: 'properties[0].address.addressLineText',
  },
  {
    label: 'City',
    quotePath: 'in.loanSummary.city',
    loanPath: 'properties[0].address.cityName',
  },
  {
    label: 'State',
    quotePath: 'in.loanSummary.state',
    loanPath: 'properties[0].address.stateCode',
  },
  {
    label: 'Zip Code',
    quotePath: 'in.loanSummary.zip',
    loanPath: 'properties[0].address.postalCode',
  },
  {
    label: 'Requested loan amount',
    quotePath: 'out.cashToClose.estimatedLoanAmt',
    loanPath: 'loan.aggregations.requestedLoanAmount.override',
  },
  {
    label: 'Original Purchase Date',
    quotePath: 'in.dealTerms.originalPurchaseDate',
    loanPath: 'properties[0].originalPurchaseDate',
  },
  {
    label: 'Estimated after repair value',
    quotePath: 'in.dealTerms.rehabConBudget',
    loanPath: 'properties[0].appraisal.subjectToPropertyValuationAmount',
  },
];

export const RENTAL_PREFILL_FIELDS: PrefilledFieldConfig[] = [
  {
    label: 'Current/original purchase price',
    quotePath: 'in.purchasePrice',
    loanPath: 'properties[0].originalPurchasePriceAmount',
  },
  {
    label: 'Address Line 1',
    quotePath: 'in.propertyAddress',
    loanPath: 'properties[0].address.addressLineText',
  },
  {
    label: 'City',
    quotePath: 'in.city',
    loanPath: 'properties[0].address.cityName',
  },
  {
    label: 'State',
    quotePath: 'in.state',
    loanPath: 'properties[0].address.stateCode',
  },
  {
    label: 'Zip Code',
    quotePath: 'in.zip',
    loanPath: 'properties[0].address.postalCode',
  },
  {
    label: 'Requested loan amount',
    quotePath: (quote: Quote) => {
      if (!quote) {
        return undefined;
      }

      const rentalQuote =
        quote as LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse;

      const preferredScenarioIndex = rentalQuote.in?.scenario?.findIndex(
        (scenario) => scenario.preferred
      );

      return get(
        rentalQuote,
        `out.scenarios[${preferredScenarioIndex}].loanAmt`
      );
    },
    loanPath: 'loan.aggregations.requestedLoanAmount.override',
  },
  {
    label: 'Annual taxes',
    quotePath: 'in.annualTaxes',
    loanPath: 'properties[0].cityAnnualTaxAmount',
  },
  {
    label: 'Estimated market rent',
    quotePath: 'in.monthlyRent',
    loanPath: 'properties[0].leasedFinancedUnits.unit0.monthlyMarketRentAmount',
  },
  {
    label: 'Annual property insurance',
    quotePath: 'in.annualInsurance',
    loanPath: 'properties[0].propertyInsurance.insurancePremiumAmount',
  },
  {
    label: 'Annual flood insurance',
    quotePath: 'in.annualFloodInsurance',
    loanPath: 'properties[0].floodInsurance.insurancePremiumAmount',
  },
  {
    label: 'Annual HOA',
    quotePath: 'in.annualHOA',
    loanPath: 'properties[0].annualHomeownersAssociationFeeAmount',
  },
];

export const QUOTE_CONFIG: Record<string, QuoteConfig> = {
  [QUOTE_TYPE.SHORT_TERM]: {
    propertiesNumberField: 'in.dealTerms.numOfProperties',
    fields: STF_PREFILL_FIELDS,
  },
  [QUOTE_TYPE.RENTAL]: {
    propertiesNumberField: 'in.numOfProperties',
    fields: RENTAL_PREFILL_FIELDS,
  },
};
