import { useCallback, useEffect, useState } from 'react';
import { useEntityTasksBySection } from '@pages/personalLoans/pages/buildMyLoan/hooks/useEntityTasksBySection';
import { LolaBffApiContractsModelsLoanTaskInfo } from '@api/output/api';
import { useOpenState } from '@hooks/useOpenState';
import { useTemplates } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTemplatesByTaskId';
import { TASK_SECTIONS } from '@typings/common';

export const useUploadActiveTask = (sectionName: TASK_SECTIONS) => {
  const [tasks, { raw }] = useEntityTasksBySection(sectionName);
  const [activeTask, setActiveTask] =
    useState<LolaBffApiContractsModelsLoanTaskInfo>();
  const { templates, templatesAreBeingFetched } = useTemplates(
    activeTask?.taskConfigurationId
  );

  const [uploadDrawerState, setUploadDrawerOpen, setUploadDrawerClose] =
    useOpenState(false);

  const uploadDrawerHandler = useCallback(
    (task?: LolaBffApiContractsModelsLoanTaskInfo) => {
      return () => {
        setActiveTask(task);
        setUploadDrawerOpen();
      };
    },
    []
  );

  useEffect(() => {
    if (uploadDrawerState && tasks && activeTask) {
      const updatedTask = raw.find(
        ({ taskId }) => activeTask.taskId === taskId
      );

      setActiveTask(updatedTask);
    }
  }, [tasks, activeTask, uploadDrawerState]);

  const uploaderCloseHandler = useCallback(() => {
    setUploadDrawerClose();
  }, [setUploadDrawerClose]);

  return {
    activeTask,
    tasks,
    uploadDrawerState,
    uploaderCloseHandler,
    uploadDrawerHandler,
    templates,
    templatesAreBeingFetched,
  };
};
