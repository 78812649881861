import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormData } from '@form-configs/types';
import { QUOTE_TYPE } from '@typings/common';
import { useQuotesCalculateAndSave } from '@hooks/useQuotesApi/useQuotesCalculateAndSave';
import { useOpenToast } from '@hooks/useOpenToast';

export const useQuotesBuilderApi = (type: QUOTE_TYPE, quoteId?: string) => {
  const navigate = useNavigate();
  const openToast = useOpenToast(5000);

  const { save, loadById, isSaving, isQuoteLoading, isError, data, summary } =
    useQuotesCalculateAndSave(type, quoteId);

  const createAndNavigate = useCallback(
    async (values: FormData) => {
      const id = await save(values);
      if (id) {
        navigate(`/scenario-builder/quote/${type.toLowerCase()}/${id}`);
      }
    },
    [type, navigate]
  );

  const updateOrCreate = useCallback(
    async (values: FormData) => {
      const saveMethod = quoteId ? save : createAndNavigate;
      await saveMethod(values);

      if (quoteId) {
        openToast({
          title: 'Quote has been calculated and saved.',
          description:
            'Quote has been calculated and saved! You will be able to review it in the quote list.',
        });
      }
    },
    [createAndNavigate, save, quoteId]
  );

  return {
    save: updateOrCreate,
    isError,
    loadById,
    isQuoteLoading: isQuoteLoading,
    isCalculating: isSaving,
    data,
    summary,
  };
};
