import { useCallback, useMemo, useReducer } from 'react';
import { useParties } from '@pages/personalLoans/pages/buildMyLoan/hooks/useParties';
import { useLolaTasks } from '@hooks/useLolaTasks';
import { CREDIT_REPORT_TASK } from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useBuildMyLoan.constants';
import { PAYMENT_STATUS_TYPE } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { useTasksByName } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTasksByName';
import { CONFIGURED_TASKS, TASK_SECTIONS } from '@typings/common';

export enum CREDIT_REPORT_ACTION {
  NEW = 'new',
  RE_ISSUE = 're-issue',
}

export interface PaymentAction {
  type: CREDIT_REPORT_ACTION;
  payload: string;
}

export const paymentReducer = (state: string[] = [], action: PaymentAction) => {
  const existIndex = state.indexOf(action.payload);

  if (
    action.type === CREDIT_REPORT_ACTION.NEW &&
    existIndex === -1 &&
    action.payload
  ) {
    return [...state, action.payload];
  }

  if (
    action.type === CREDIT_REPORT_ACTION.RE_ISSUE &&
    existIndex !== -1 &&
    action.payload
  ) {
    const newState = [...state];
    newState.splice(existIndex, 1);
    return newState;
  }

  return state;
};

export const usePaymentParties = () => {
  const { parties } = useParties();
  const tasks = useTasksByName(
    TASK_SECTIONS.CREDIT_REPORT,
    CONFIGURED_TASKS.CREDIT_REPORT
  );
  const [state, dispatch] = useReducer(paymentReducer, []);

  const paymentParties = useMemo(() => {
    return parties.filter(({ id }) => id && state.includes(id));
  }, [parties, state]);

  const {
    tasks: lolaTasks,
    refetchTasks: refetchStatuses,
    areTasksFetching: isStatusLoading,
  } = useLolaTasks(!paymentParties.length);

  const change = useCallback(
    (type: CREDIT_REPORT_ACTION, payload: string) => {
      dispatch({
        type,
        payload,
      });
    },
    [dispatch]
  );

  const partiesFiltered = useMemo(() => {
    return paymentParties
      .filter((party) => {
        const task = tasks?.find(({ entityId }) => entityId === party.id);

        const authorizationLolaTask = lolaTasks.find((lolaTask) => {
          return (
            party?.id === lolaTask?.entity2Id &&
            lolaTask?.taskType === CREDIT_REPORT_TASK.AUTHORIZATION
          );
        });

        const documents = task?.task?.documents?.length;

        return !documents && authorizationLolaTask;
      })
      .map((party) => {
        const paymentTask = lolaTasks.find((lolaTask) => {
          return (
            party?.id === lolaTask?.entity2Id &&
            lolaTask?.taskType === CREDIT_REPORT_TASK.PAYMENT
          );
        });

        return {
          ...party,
          completion: paymentTask?.completionStatus,
          status: paymentTask
            ? paymentTask.displayStatus
            : PAYMENT_STATUS_TYPE.NOT_PAID,
        };
      });
  }, [paymentParties, lolaTasks, isStatusLoading]);

  return {
    parties: partiesFiltered,
    isStatusLoading,
    refetchStatuses,
    change,
  };
};
