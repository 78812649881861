import { Buffer } from 'buffer';
import { useCallback } from 'react';
// Utils
import { downloadFile } from '@utils/file.utils';
// Hooks
import { useDownloadUrl } from '@pages/personalLoans/hooks/useDownloadUrl';

export const useDownloadHandler = () => {
  const getDocumentUrl = useDownloadUrl();

  const downloadHandler = useCallback(
    async (objectString: string, fileName: string) => {
      const encoded = Buffer.from(objectString).toString('base64');
      const url = await getDocumentUrl(encoded);
      if (url) {
        downloadFile(fileName, url);
      }
    },
    []
  );

  return { downloadHandler };
};
