import React, { useCallback } from 'react';
import { UploadDrawer } from '@components/UploadDrawer/UploadDrawer.component';
import {
  UploadFileItem,
  useFilesUploader,
} from '@components/Uploader/hooks/useFilesUploader';
import { FileItem, UploaderConfig } from '@components/Uploader/Uploader.types';
import { UploaderDrawerConfig } from '@components/UploadDrawer/uploaderDrawer.types';
import { NotificationProps } from '@components/Notification/Notification.component';
import {
  LolaBffApiContractsModelsTaskDocumentTemplateTemplateInfo,
  useDeleteApiV1DocumentsDeleteMutation,
  usePostApiV1DocumentsUploadByTaskIdMutation,
} from '@api/output/api';
import { UPLOAD_STATUS } from '@constants/uploader.constants';
import { UploadHistory } from '@components/UploadDrawer/components/History/History.component';

export interface TaskFilesUploadDrawerProps {
  status?: UPLOAD_STATUS;
  uploadedFiles?: FileItem[];
  onClose: () => void;
  taskId?: string;
  completeOperationAsync?: () => Promise<void>;
  submitOperation?: () => Promise<void>;
  isOpen?: boolean;
  uploaderConfig?: UploaderConfig;
  drawerConfig?: UploaderDrawerConfig;
  notification?: NotificationProps;
  comment?: string;
  history?: UploadHistory;
  templates?: LolaBffApiContractsModelsTaskDocumentTemplateTemplateInfo[];
}

export const TaskFilesUploadDrawer = ({
  status,
  uploadedFiles,
  onClose,
  taskId,
  completeOperationAsync,
  submitOperation,
  ...props
}: TaskFilesUploadDrawerProps) => {
  const [uploadDocument] = usePostApiV1DocumentsUploadByTaskIdMutation();
  const [deleteDocument] = useDeleteApiV1DocumentsDeleteMutation();

  const uploadOperationAsync = useCallback(
    async ({ body }: UploadFileItem) => {
      if (taskId) {
        return uploadDocument({
          taskId,
          body: body as { file?: Blob },
        }).unwrap();
      }

      return Promise.resolve();
    },
    [taskId]
  );

  const deleteOperationAsync = useCallback(
    async ({ downloadId }: FileItem) => {
      if (taskId && downloadId) {
        return deleteDocument({
          taskId,
          downloadId,
        }).unwrap();
      }

      return Promise.resolve();
    },
    [taskId]
  );

  const uploaderProps = useFilesUploader({
    status,
    uploadedFiles,
    uploadOperationAsync,
    deleteOperationAsync,
    submitOperation,
    completeOperationAsync,
    onClose,
  });

  return <UploadDrawer {...props} {...uploaderProps} />;
};
