import React, { useCallback, useMemo } from 'react';
// MUI
import {
  Typography,
  Paper,
  IconButton,
  Grid,
  Divider,
  Link,
} from '@mui/material';
// icons
import { DeleteIcon } from '@lola/ui-react-components';
// assets
import fileImage from '@assets/images/filePreview.jpg';
// hooks
import { useDownloadHandler } from '@hooks/useDownloadHandler';
// types
import { Status } from './UploadedFileCard.type';
//Components
import { UploadedFileCardStatus } from './components';
// style
import style from './UploadedFileCard.module.scss';

interface UploadedFileCardProps {
  updateDate?: string | null;
  fileName?: string | null;
  onDelete?: () => void | null;
  disabled?: boolean;
  status?: Status | null;
  fileId?: string;
}

export const UploadedFileCard = ({
  fileName,
  updateDate,
  onDelete,
  disabled = false,
  status,
  fileId,
}: UploadedFileCardProps): JSX.Element => {
  // hooks
  const { downloadHandler } = useDownloadHandler();

  // actions
  const handleDelete = useCallback(() => {
    onDelete?.();
  }, [onDelete]);

  const handledDownloadFile = useCallback(() => {
    if (!fileId || !fileName) return;
    const toEncode = {
      fileId,
      fileName,
    };

    const objectString = JSON.stringify(toEncode);

    downloadHandler(objectString, fileName);
  }, [fileId, fileName]);

  // render
  const imgElement = useMemo(
    () => (
      <img
        src={fileImage}
        alt="File"
        width={38}
        height={42}
        className={style.img}
        role="img"
      />
    ),
    []
  );

  return (
    <section className={style.container}>
      <Paper elevation={1} className={style.paper}>
        <div className={style.paperInfo}>
          {imgElement}
          <div className={style.paperInfoHeaders}>
            <Link
              underline="none"
              key={fileName}
              onClick={handledDownloadFile}
              download={fileName}
            >
              <Typography role="p" variant="pBold">
                {fileName}
              </Typography>
            </Link>
            <Typography role="p" variant="pCaption">
              {updateDate}
            </Typography>
          </div>

          {onDelete && (
            <IconButton
              disabled={disabled}
              onClick={handleDelete}
              className={style.delete}
              role="button"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
        {status && (
          <>
            <Divider />
            <Grid>
              <UploadedFileCardStatus status={status} />
            </Grid>
          </>
        )}
      </Paper>
    </section>
  );
};
