import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { Notification } from '@components/Notification/Notification.component';
import { options } from '@form-configs/output';
import { LicensingFields } from './LicensingFields.component';

export interface LicensingFormInvalidProps {
  stateCode?: string;
}

export const LicensingFormInvalid = ({
  stateCode,
}: LicensingFormInvalidProps) => {
  const stateName = useMemo(() => {
    const state = options.states.find((state) => state.value === stateCode);
    return state ? state.label : 'selected state';
  }, [stateCode]);
  return (
    <>
      <Grid item xs={12}>
        <Notification
          severity="warning"
          title="No active license"
          withIcon
          text={`The subject property for this loan is in ${stateName} which requires a broker or lender license to close this loan. Please have your admin provide enter valid license data into the profile section of the portal or contact your CSM for assistance resolving this issue.`}
        />
      </Grid>
      <Grid item>
        <LicensingFields required />
      </Grid>
    </>
  );
};
