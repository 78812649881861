import React from 'react';
import { Typography, useTheme } from '@mui/material';

import classnames from 'classnames';
import styles from './authSidebar.module.scss';

export const AuthSidebar = () => {
  const { config } = useTheme();

  const isLight = !config.images.logoLogin;
  const logo = isLight ? config.images.logoInternal : config.images.logoLogin;

  return (
    <aside
      className={classnames(styles.sidebar, {
        [styles.lightSidebar]: isLight,
      })}
    >
      <div
        className={styles.logo}
        style={{
          backgroundImage: `url('${logo}')`,
        }}
      />

      <div className={styles.content}>
        <Typography variant="h1" className={styles.title}>
          LOLA Portal
        </Typography>
        <Typography variant="p">
          Welcome to Loan Origination with Less Aggravation (LOLA). This portal
          has been designed to help you serve your clients and close more deals
          with the ability to quickly and easily submit loans and efficiently
          manage your pipeline
        </Typography>
      </div>
    </aside>
  );
};
