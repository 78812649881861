import { useMemo } from 'react';
import { useGetApiV1SettingsProgramsAndProductsQuery } from '@api/output/api';
import { convertToOptions } from '@utils/options.utils';
import { LOAN_PROGRAM } from '@typings/common';

export const useLoanProgramList = () => {
  const { data } = useGetApiV1SettingsProgramsAndProductsQuery();

  const programs = useMemo(
    () =>
      convertToOptions({
        input: data?.programs ?? [],
        idKey: 'id',
        valueKeys: ['name'],
      }).filter(({ value }) => {
        return !`${value}`.toLowerCase().includes('rental');
      }),
    [data]
  );

  const products = useMemo(() => {
    const rental = data?.programs?.find(
      ({ id }) => id === LOAN_PROGRAM.RENTAL_30
    );

    return convertToOptions({
      input: rental?.products ?? [],
      idKey: 'id',
      valueKeys: ['name'],
    });
  }, [data]);

  return {
    programs,
    products,
  };
};
