import { useMemo } from 'react';
import { ProgressStepperItem } from '@components/ProgressStepper/types';

const transformConstructionBudgetStep = (
  item: ProgressStepperItem
): ProgressStepperItem => {
  return {
    ...item,
    items: [
      {
        id: item.id,
        to: item.to,
        title: 'Documents upload',
        status: item.status,
      },
    ],
  };
};

const transformOrderAppraisalStep = (
  item: ProgressStepperItem
): ProgressStepperItem => {
  return {
    ...item,
    items: [
      {
        id: item.id,
        to: item.to,
        title: 'Appraisal upload',
        status: item.status,
      },
    ],
  };
};

const transformationMap: {
  [key: string]: (item: ProgressStepperItem) => ProgressStepperItem;
} = {
  '/personal-loans/:id/build-my-loan/construction-budget':
    transformConstructionBudgetStep,
  '/personal-loans/:id/build-my-loan/order-appraisal':
    transformOrderAppraisalStep,
};

const removeReviewStep = (
  stepsData: ProgressStepperItem[]
): ProgressStepperItem[] => stepsData.slice(0, stepsData.length - 1);

const applyTransformations = (
  stepsData: ProgressStepperItem[]
): ProgressStepperItem[] =>
  stepsData.map((item) => {
    const transformFunc = transformationMap[item.id];
    return transformFunc ? transformFunc(item) : item;
  });

export const useTransformReviewAndSubmitSteps = (
  stepsData: ProgressStepperItem[]
): ProgressStepperItem[] => {
  return useMemo(() => {
    return applyTransformations(removeReviewStep(stepsData));
  }, [stepsData]);
};
