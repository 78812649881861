import React, { useState, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  ClickAwayListener,
} from '@mui/material';
import { FilterOption } from '@lola/ui-react-components';
import { ControlPanelLabel } from '../index';

import styles from './dataFilter.module.scss';

export interface DataFilterProps {
  handleFilterChange: (filterKey: string, value: string[]) => void;
  filterKey: string;
  filterTitle: string;
  filterOptions?: FilterOption[];
  appliedFilterOptions?: string[];
  optionLabelRenderer?: (value: string) => React.ReactNode;
}

export const DataFilter = ({
  handleFilterChange,
  filterOptions = [],
  appliedFilterOptions = [],
  filterKey,
  filterTitle,
  optionLabelRenderer,
}: DataFilterProps) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [isPanelVisible, setIsPanelVisible] = useState<boolean>(false);
  const isApplied = useMemo(() => {
    return isEqual(selected, appliedFilterOptions);
  }, [selected, appliedFilterOptions]);

  const handlePanelVisibility = () => {
    setSelected(appliedFilterOptions);
    setIsPanelVisible(!isPanelVisible);
  };

  const handleClickAway = () => {
    setIsPanelVisible(false);
    setSelected([]);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (selected.includes(value)) {
      setSelected(selected.filter((item) => item !== value));
    } else {
      setSelected([...selected, value]);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={styles.container}>
        <ControlPanelLabel
          label={filterTitle}
          isActive={isPanelVisible}
          onClick={handlePanelVisibility}
          labelPostfix={
            appliedFilterOptions.length > 0
              ? `${appliedFilterOptions.length} selected`
              : ''
          }
        />
        <div className={styles.panelWrapper}>
          {isPanelVisible && (
            <div className={styles.panel}>
              <div className={styles.panelHeader}>
                <Typography className={styles.panelSelected}>
                  {selected.length} selected
                </Typography>
                <Button
                  variant="text"
                  size="small"
                  disabled={!selected.length}
                  onClick={() => setSelected([])}
                >
                  Clear
                </Button>
              </div>
              <div className={styles.panelBody}>
                <ul>
                  {filterOptions.map(({ value, label }) => {
                    return (
                      <li key={value}>
                        <FormControlLabel
                          value={value}
                          control={
                            <Checkbox
                              checked={selected.includes(value)}
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={
                            optionLabelRenderer
                              ? optionLabelRenderer(label)
                              : label
                          }
                          labelPlacement="end"
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className={styles.panelFooter}>
                <Button
                  className={styles.applyButton}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleFilterChange(filterKey, selected);
                    setIsPanelVisible(false);
                  }}
                  disabled={isApplied}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};
