import { useCallback, useMemo, useEffect, useRef } from 'react';
import { UseMutationStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import merge from 'lodash/merge';
import { QUOTE_TYPE } from '@typings/common';
import {
  LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest,
  usePostApiV1PricingEngineQuoteRentalCalculationsMutation,
  usePostApiV1PricingEngineQuoteStfCalculationsMutation,
} from '@api/output/api';
import {
  CalculateHandler,
  CalculateResponse,
} from '@hooks/useQuotesApi/quotes.types';
import { IN_STF, IN_RENTAL } from '@hooks/useQuotesApi/quotes.constants';
import { ApiError, convertCatchError } from '@utils/errors.utils';
import { useOpenToast } from '@hooks/useOpenToast';
import { DEFAULT_API_ERROR_TITLE } from '@constants/text.constants';
import { CalculatePayloadValuesWithFees } from './utils/feeUtils.types';
import { replaceFeesWithObject } from './utils/replaceFeesWithObject.utils';

export const useQuotesCalculateApi = (
  quoteType: QUOTE_TYPE
): [CalculateHandler, UseMutationStateResult<any, any>] => {
  const openToast = useOpenToast();
  const updatedQuoteType = useRef(quoteType);
  const [calculateRental, calculateRentalValue] =
    usePostApiV1PricingEngineQuoteRentalCalculationsMutation();
  const [calculateShortTerm, calculateShortTermValue] =
    usePostApiV1PricingEngineQuoteStfCalculationsMutation();

  useEffect(() => {
    updatedQuoteType.current = quoteType;
  }, [quoteType]);

  const calculate = useCallback(
    async (
      values: CalculatePayloadValuesWithFees
    ): Promise<CalculateResponse> => {
      try {
        const transformedValues = replaceFeesWithObject(values);
        if (updatedQuoteType.current === QUOTE_TYPE.SHORT_TERM) {
          return await calculateShortTerm({
            lolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest:
              merge({}, IN_STF, transformedValues),
          }).unwrap();
        } else {
          return await calculateRental({
            lolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest:
              merge(
                {},
                IN_RENTAL,
                transformedValues
              ) as unknown as LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest,
          }).unwrap(); // change the type for premier to support null on the backend
        }
      } catch (err) {
        const description = convertCatchError(err as ApiError);

        openToast({
          isError: true,
          title: DEFAULT_API_ERROR_TITLE,
          description,
        });

        return Promise.reject(new Error('Error calculating quote'));
      }
    },
    [calculateRental, calculateShortTerm, openToast]
  );

  const calculateResult = useMemo(() => {
    return quoteType === QUOTE_TYPE.SHORT_TERM
      ? calculateShortTermValue
      : calculateRentalValue;
  }, [quoteType, calculateShortTermValue, calculateRentalValue]);

  return useMemo(
    () => [calculate, calculateResult],
    [calculate, calculateResult]
  );
};
