import { useCallback } from 'react';
import { useUpdateBuildMyLoanCache } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateBuildMyLoanCache';
import { ROLE_TYPE } from '@typings/common';
import { DEFAULT_GUARANTOR_VALUE } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';

export const useOnAddParty = () => {
  const { updatePartyCacheById } = useUpdateBuildMyLoanCache();

  return useCallback(async (name: string, index: number) => {
    const paths = name.split('.');
    const apiName = paths.shift();

    /* first name in the key can be used for identifying properly model for update: party / loan / property */
    if (apiName === 'party') {
      const party = {
        ...DEFAULT_GUARANTOR_VALUE,
        index,
      };
      updatePartyCacheById(party, ROLE_TYPE.OTHER_INDIVIDUAL);
    }
  }, []);
};
