import React, { useMemo } from 'react';
import { TableColumnType } from 'antd';
import {
  DataTable,
  createDefaultSorter,
  useTableDataSearch,
  currencyRenderer,
} from '@lola/ui-react-components';
import { ContentSection } from '@components/ContentSection';
import { DataSearch } from '@components/DataTableControlPanel/components/DataSearch/DataSearch.component';
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import { useUser } from '@hooks/useUser';
import { LolaBffApiContractsModelsUser } from '@api/output/api';
import { useLoansInPipeline } from '../../hooks/useLoansInPipeline';
import { displayNameRenderer } from './renderers/displayNameRenderer/displayNameRenderer';
import { StatisticRow } from './LoansInPipelineWidget.typings';
import styles from './loansInPipelineWidget.module.scss';

type SorterColumn = Omit<StatisticRow, 'children'>;

const createColumns = (
  currentUser: LolaBffApiContractsModelsUser | undefined
): TableColumnType<StatisticRow>[] => [
  {
    dataIndex: 'displayName',
    title: 'Team',
    width: '30%',
    sorter: createDefaultSorter<SorterColumn>('displayName'),
    render: displayNameRenderer(currentUser),
  },
  {
    dataIndex: 'dealsNumber',
    title: 'Deals',
    width: '10%',
    sorter: createDefaultSorter<SorterColumn>('dealsNumber'),
  },
  {
    dataIndex: 'applicationsNumber',
    title: 'Apps',
    width: '10%',
    sorter: createDefaultSorter<SorterColumn>('applicationsNumber'),
  },
  {
    dataIndex: 'submissionsNumber',
    title: 'Subs',
    width: '10%',
    sorter: createDefaultSorter<SorterColumn>('submissionsNumber'),
  },
  {
    dataIndex: 'closedNumber',
    title: 'Closed',
    width: '10%',
    sorter: createDefaultSorter<SorterColumn>('closedNumber'),
  },
  {
    dataIndex: 'pipelineTotalAmount',
    title: 'Pipeline',
    width: '15%',
    sorter: createDefaultSorter<SorterColumn>('pipelineTotalAmount'),
    render: currencyRenderer,
  },
  {
    dataIndex: 'fundedTotalAmount',
    title: 'Funded',
    width: '15%',
    sorter: createDefaultSorter<SorterColumn>('fundedTotalAmount'),
    render: currencyRenderer,
  },
];

export const LoansInPipelineWidget = () => {
  const currentUser = useUser();
  const { data, isLoading, isError } = useLoansInPipeline();

  const columns = useMemo(() => createColumns(currentUser), [currentUser]);

  const { newData, handleSearchChange } = useTableDataSearch<StatisticRow>(
    data?.children ?? [],
    'displayName'
  );

  if (isError) {
    return (
      <ContentSection title="LOANS IN PIPELINE" isLoading={isLoading}>
        <ErrorNoticeFetchRequest />
      </ContentSection>
    );
  }

  return (
    <ContentSection title="LOANS IN PIPELINE" isLoading={isLoading}>
      <header className={styles.header}>
        <DataSearch handleSearchChange={handleSearchChange} />
      </header>
      <DataTable
        rowKey="id"
        scroll={{
          x: 900,
          y: 600,
        }}
        tree={true}
        columns={columns}
        dataSource={newData}
        mode="slim"
      />
    </ContentSection>
  );
};
