import { DEFAULT_UPLOADER_CONFIG } from '@components/Uploader/Uploader.constants';

export const REI_LENDER_UPLOADER_CONFIG = {
  ...DEFAULT_UPLOADER_CONFIG,
  fileDelete: false,
  description: 'Accepted file types: PDF',
  rules: {
    ...DEFAULT_UPLOADER_CONFIG.rules,
    allowedExtensions: {
      rule: ['pdf'],
      message: 'Wrong file extension',
    },
  },
};
