import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { forms } from '@form-configs/output';
import { useEntityTasksBySection } from '@pages/personalLoans/pages/buildMyLoan/hooks/useEntityTasksBySection';
import {
  convertFormConfigToYupSchema,
  getValidationErrors,
} from '@components/DynamicForm/utils/validation';
import { FormDataSchema } from '@form-configs/types';
import { useHasConstructionBudgetInspection } from '@pages/personalLoans/pages/buildMyLoan/hooks/constructionBudget/useHasInspection';

export const useConstructionCompleted = () => {
  const hasInspection = useHasConstructionBudgetInspection();
  const [, { raw }] = useEntityTasksBySection('construction_budget');
  const [constructionContact] = useWatch({
    name: ['loan.lola.constructionContact'],
  });
  const { constructionInspection } = forms;
  const constructionInspectionSchema = convertFormConfigToYupSchema(
    constructionInspection
  ) as FormDataSchema;
  const simpleConstructionInspectionSchemaLoan = constructionInspectionSchema
    .fields.loan as FormDataSchema;
  const simpleConstructionInspectionSchemaLola =
    simpleConstructionInspectionSchemaLoan.fields.lola as FormDataSchema;
  const simpleConstructionInspectionSchema =
    simpleConstructionInspectionSchemaLola.fields
      .constructionContact as FormDataSchema;
  const isAllDocumentsLoaded = useMemo(
    () => !!raw.length && raw?.every((task) => task?.documents?.length),
    [raw]
  );

  const isValid = useMemo(() => {
    const errors = getValidationErrors(
      constructionContact,
      simpleConstructionInspectionSchema
    );
    const validationErrors = errors.filter((err) => !!err);

    return validationErrors.length === 0;
  }, [constructionContact, constructionInspectionSchema]);

  return useMemo(
    () =>
      hasInspection ? isAllDocumentsLoaded && isValid : isAllDocumentsLoaded,
    [hasInspection, isAllDocumentsLoaded, isValid]
  );
};
