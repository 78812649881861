import { useEffect, useState } from 'react';
// Router
import { useNavigate, useParams, useLocation } from 'react-router';
//Redux
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { ToastType, addToastWithAutodelete } from '@store/slices/toast.slice';
//API
import {
  LolaBffApiContractsModelsLoanRequestArchiveLoanRequest,
  usePostApiV1LoansArchiveLoanByDealIdMutation,
} from '@api/output/api';

interface ArchiveLoanProps {
  loanIdentifier?: string;
  loanName?: string;
  callback?: () => void;
}

export const useArchiveLoan = ({
  loanIdentifier,
  loanName,
  callback,
}: ArchiveLoanProps) => {
  // Router
  const navigate = useNavigate();
  const { loanId = '' } = useParams();
  const { pathname } = useLocation();

  // states
  const [archiveLoanModalOpen, setArchiveLoanModalOpen] = useState(false);

  // State actions
  const switchArchiveLoanModalOpen = () =>
    setArchiveLoanModalOpen((prev) => !prev);

  // API
  const [ArchiveLoan, { isSuccess, isError }] =
    usePostApiV1LoansArchiveLoanByDealIdMutation();
  const dispatch = useDispatch();

  // actions
  const handleArchive = async (
    loanId: string,
    lolaReason: LolaBffApiContractsModelsLoanRequestArchiveLoanRequest
  ) => {
    await ArchiveLoan({
      dealId: loanId,
      lolaBffApiContractsModelsLoanRequestArchiveLoanRequest: lolaReason,
    });
  };

  const handledNavigate = () => {
    const newRoute = `/personal-loans/${loanId}`;

    if (newRoute === pathname) return navigate(0);

    callback?.();
    return navigate(newRoute);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        addToastWithAutodelete({
          type: ToastType.Success,
          title: 'Loan Archived',
          description: `Loan #${loanIdentifier} ${loanName} was archived`,
        }) as unknown as AnyAction
      );

      setArchiveLoanModalOpen(false);
      handledNavigate();
    }

    if (isError) {
      dispatch(
        addToastWithAutodelete({
          type: ToastType.Error,
          title: 'Failed to Loan Archived',
          description: 'The process to archive a loan failed, try again.',
        }) as unknown as AnyAction
      );
      setArchiveLoanModalOpen(false);
    }
  }, [isSuccess, isError]);

  const actions = {
    switchArchiveLoanModalOpen,
    handleArchive,
  };

  return {
    ...actions,
    archiveLoanModalOpen,
  };
};
