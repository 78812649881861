import { createContext, FC } from 'react';
import { ControlType, FieldConfig, FormData } from '@form-configs/types';
import { DynamicCollapsed } from '@components/DynamicForm/components/DynamicCollapsed/DynamicCollapsed.component';
import { DynamicBlock } from '@components/DynamicForm/components/DynamicBlock/DynamicBlock.component';
import { DynamicDropdown } from '@components/DynamicForm/components/DynamicDropdown/DynamicDropdown.component';
import { DynamicTextField } from '@components/DynamicForm/components/DynamicTextField/DynamicTextField.component';
import { DynamicCard } from '@components/DynamicForm/components/DynamicCard/DynamicCard.component';
import { DynamicDatePicker } from '@components/DynamicForm/components/DynamicDatePicker/DynamicDatePicker.component';
import { DynamicCheckboxGroup } from '@components/DynamicForm/components/DynamicCheckboxGroup/DynamicCheckboxGroup.component';
import { DynamicRadioGroup } from '@components/DynamicForm/components/DynamicRadioGroup/DynamicRadioGroup.component';
import { DynamicList } from '@components/DynamicForm/components/DynamicList/DynamicList.component';
import { DynamicCheckbox } from '@components/DynamicForm/components/DynamicCheckbox/DynamicCheckbox.component';
import { DynamicDivider } from '@components/DynamicForm/components/DynamicDivider/DynamicDivider.component';
import { DynamicTypography } from '@components/DynamicForm/components/DynamicTypography/DynamicTypography.component';
import { DynamicUploader } from '@components/DynamicForm/components/DynamicUploader/DynamicUploader.component';

export type CustomComponentProps = {
  items?: FieldConfig[];
};

export const DEFAULT_COMPONENTS_MAP = {
  collapse: DynamicCollapsed,
  block: DynamicBlock,
  dropdown: DynamicDropdown,
  text: DynamicTextField,
  number: DynamicTextField,
  card: DynamicCard,
  datePicker: DynamicDatePicker,
  checkboxGroup: DynamicCheckboxGroup,
  radioGroup: DynamicRadioGroup,
  divider: DynamicDivider,
  list: DynamicList,
  checkbox: DynamicCheckbox,
  typography: DynamicTypography,
  uploader: DynamicUploader,
};

export type ComponentsMap = Record<
  ControlType | string,
  | FC<FormData>
  | typeof DEFAULT_COMPONENTS_MAP[keyof typeof DEFAULT_COMPONENTS_MAP]
>;

export const ComponentsContext = createContext<ComponentsMap>(
  DEFAULT_COMPONENTS_MAP
);
