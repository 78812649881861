import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  '& p': {
    marginBottom: 0,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.darkBlue,
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.description,
  '& span': {
    paddingLeft: 12,
    position: 'relative',
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:not(:first-child)::before': {
      background: theme.palette.custom.description,
      borderRadius: '50%',
      content: '""',
      display: 'block',
      height: 4,
      left: 4,
      position: 'absolute',
      top: 9,
      width: 4,
    },
  },
}));
