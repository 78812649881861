import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { Paper, Typography } from '@mui/material';
import { StatusChip } from '@components/StatusChip/StatusChip.component';
import { FileItem } from '@components/Uploader/Uploader.types';
import {
  UPLOAD_STATUS,
  UPLOAD_STATUS_CONFIG,
  UPLOAD_TYPE,
} from '@constants/uploader.constants';
import {
  detailsButtonRendererUploader,
  FileUploaderButtonProps,
  uploadButtonRendererUploader,
} from './renderers';

import styles from './fileUploader.module.scss';

export interface FileUploaderProps {
  title: string;
  placeholder: string;
  uploadedFileDescription?: string;
  status?: UPLOAD_STATUS;
  type?: UPLOAD_TYPE;
  onClickIfFileExists?: () => void;
  onClickIfFileDoesntExist?: () => void;
  elevation?: number;
  label?: string;
  uploadedFiles?: FileItem[];
  buttonRendererIfFileExists?: (
    props: FileUploaderButtonProps
  ) => React.ReactNode;
  buttonRendererIfFileDoesntExist?: (
    props: FileUploaderButtonProps
  ) => React.ReactNode;
  disabled?: boolean;
}

export const FileUploader = ({
  label,
  title,
  placeholder,
  uploadedFiles,
  uploadedFileDescription,
  status = UPLOAD_STATUS.NOT_UPLOADED,
  type = UPLOAD_TYPE.STANDARD,
  onClickIfFileExists,
  onClickIfFileDoesntExist,
  buttonRendererIfFileExists,
  buttonRendererIfFileDoesntExist,
  elevation = 3,
  disabled,
}: FileUploaderProps) => {
  const memoizedButtonRendererIfFileExists = useMemo(() => {
    return buttonRendererIfFileExists
      ? buttonRendererIfFileExists({
          onClick: onClickIfFileExists,
          disabled,
        })
      : detailsButtonRendererUploader({
          onClick: onClickIfFileExists,
          disabled,
        });
  }, [buttonRendererIfFileExists, onClickIfFileExists, disabled]);

  const memoizedButtonRendererIfFileDoesntExists = useMemo(() => {
    return buttonRendererIfFileDoesntExist
      ? buttonRendererIfFileDoesntExist({
          onClick: onClickIfFileDoesntExist,
          disabled,
        })
      : uploadButtonRendererUploader({
          onClick: onClickIfFileDoesntExist,
          disabled,
        });
  }, [buttonRendererIfFileDoesntExist, onClickIfFileDoesntExist, disabled]);

  const uploadStatus = useMemo(() => {
    if (type === UPLOAD_TYPE.CONDITIONAL) {
      return status;
    }
    return uploadedFiles?.length
      ? UPLOAD_STATUS.UPLOADED
      : UPLOAD_STATUS.NOT_UPLOADED;
  }, [type, status, uploadedFiles]);

  const uploadDate = useMemo(
    () =>
      uploadedFiles?.[0]?.createdAt
        ? dayjs(uploadedFiles[0].createdAt).format('D MMM YY')
        : '',
    [uploadedFiles]
  );

  const uploadedDescription = useMemo(() => {
    const text = uploadedFileDescription ?? uploadedFiles?.[0]?.fileName;
    return text ? `${text} • ` : '';
  }, [uploadedFileDescription, uploadedFiles]);

  return (
    <div>
      {label && <Typography paragraph>{label}</Typography>}
      <Paper elevation={elevation} className={styles.container}>
        <div className={styles.info}>
          {uploadedFiles?.[0] ? (
            <div className={styles.fileInfoBlock}>
              <div className={styles.fileDescription}>
                <Typography variant="pBold" paragraph className={styles.title}>
                  {title}
                </Typography>
                {uploadDate && (
                  <Typography variant="pDescription">
                    {uploadedDescription}
                    Uploaded on {uploadDate}
                  </Typography>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.fileInfo}>
              <div className={styles.fileDescription}>
                <Typography variant="pBold" paragraph className={styles.title}>
                  {title}
                </Typography>
                <Typography variant="pDescription">{placeholder}</Typography>
              </div>
            </div>
          )}
        </div>
        <StatusChip status={uploadStatus} config={UPLOAD_STATUS_CONFIG[type]} />
        <div className={styles.button}>
          {uploadedFiles?.length
            ? memoizedButtonRendererIfFileExists
            : memoizedButtonRendererIfFileDoesntExists}
        </div>
      </Paper>
    </div>
  );
};
