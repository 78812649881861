import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { PORTAL_ACTIONS_ID } from './LoanFooter.constants';

export const LoanFooterActions = ({ children }: PropsWithChildren) => {
  const domElement = document.getElementById(PORTAL_ACTIONS_ID);

  if (!domElement) {
    return null;
  }

  return createPortal(children, domElement);
};
