import React from 'react';
import { Grid, Paper } from '@mui/material';
import { InfoForm } from './components/InfoForm/InfoForm.component';
import { ChangePassword } from './components/ChangePassword/ChangePassword.component';

export const PersonalInfoPage = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={2}>
          <InfoForm />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={2}>
          <ChangePassword />
        </Paper>
      </Grid>
    </Grid>
  );
};
