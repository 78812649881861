import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Typography, Paper, Collapse } from '@mui/material';

import styles from './formSection.module.scss';

interface StepTwoFormProps {
  title: string;
  isDisabled?: boolean;
}

export const FormSection = ({
  title,
  isDisabled = false,
  children,
}: PropsWithChildren<StepTwoFormProps>) => {
  const titleClasses = classnames({
    [styles.title]: true,
    [styles.disabled]: isDisabled,
  });

  return (
    <Paper elevation={2}>
      <Typography variant="h3" className={titleClasses}>
        {title}
      </Typography>
      <Collapse in={!isDisabled}>
        <div className={styles.content}>{children}</div>
      </Collapse>
    </Paper>
  );
};
