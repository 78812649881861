import React, { useCallback, useContext, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { Collapsed } from '@components/Collapsed';
import { LoanPartyInfo, TASK_SECTIONS } from '@typings/common';
import { RadioGroup } from '@components/RadioGroup';
import { UploadFileItem } from '@components/Uploader/hooks/useFilesUploader';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { AuthorizationUpload } from './components/AuthorizationUpload/AuthorizationUpload.component';
import { AuthorizationLinks } from './components/AuthorizationLinks/AuthorizationLinks.component';
import {
  AUTHORIZATION_RADIO_GROUP_NAME,
  AUTHORIZATION_TYPE,
} from './authorization.types';
import { AUTHORIZATION_TYPE_OPTIONS } from './Authorization.constants';

export interface AuthorizationProps {
  parties: LoanPartyInfo[];
  taskSectionName?: TASK_SECTIONS;
  taskName?: string;
  onUpload?: (file: UploadFileItem, party: LoanPartyInfo) => Promise<unknown>;
  title?: string;
  radioLabel?: string;
  withRowSelection?: boolean;
}
export const Authorization = ({
  parties,
  title = 'Authorization',
  radioLabel = 'We need to get permission from the parties to the loan to act on their behalf to pull their credit',
}: AuthorizationProps) => {
  const { disabled } = useContext(LoanDisableContext);
  const [authorizationType, setAuthorizationType] = useState(
    AUTHORIZATION_TYPE.LINKS
  );

  const setAuthorizationTypeHandler = useCallback(
    (_: unknown, value: string) => {
      setAuthorizationType(value as AUTHORIZATION_TYPE);
    },
    [setAuthorizationType]
  );

  return (
    <Paper>
      <Collapsed label={title} disabled={!parties.length}>
        {!!parties.length && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RadioGroup
                name={AUTHORIZATION_RADIO_GROUP_NAME.AUTHORIZATION_TYPE}
                label={radioLabel}
                options={AUTHORIZATION_TYPE_OPTIONS}
                value={authorizationType}
                onChange={setAuthorizationTypeHandler}
                row
                major
                disabled={disabled}
              />
            </Grid>
            {authorizationType === AUTHORIZATION_TYPE.UPLOAD && (
              <Grid item xs={12}>
                <AuthorizationUpload parties={parties} />
              </Grid>
            )}
            {authorizationType === AUTHORIZATION_TYPE.LINKS && (
              <AuthorizationLinks parties={parties} />
            )}
          </Grid>
        )}
      </Collapsed>
    </Paper>
  );
};
