export enum KPI {
  QUOTES = 'quotes',
  PIPELINE = 'pipeline',
  FUNDED = 'funded',
}

export const LEFT_LABEL_POSTFIX_MAP: Record<string, string> = {
  [KPI.QUOTES]: 'quotes',
  [KPI.PIPELINE]: 'loans',
  [KPI.FUNDED]: 'loans',
};

export const KPI_TOOLTIP_MAP: Record<string, string> = {
  [KPI.QUOTES]:
    'This number reflects the total number of quotes that have been created during the Stats period.',
  [KPI.PIPELINE]:
    'The pipeline reflects all active deals (that have not been either closed or archived) for the Stats period selected.',
  [KPI.FUNDED]:
    'This represents the total of all funded deals for the Stats period selected.',
};
