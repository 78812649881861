import { useMemo } from 'react';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import { useStepAppAndEntity } from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useStepAppAndEntity';
import { useStepCreditReport } from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useStepCreditReport';
import { useStepUploadDocuments } from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useStepUploadDocuments';
import { TASK_SECTIONS } from '@typings/common';

const BORROWER: ProgressStepperItem = {
  id: 'Borrower',
  title: 'Borrower',
  items: [],
};

const UPLOAD_DOCUMENTS_BORROWER: ProgressStepperItem = {
  id: '/personal-loans/:id/build-my-loan/upload-documents-borrower',
  title: 'Upload documents',
  to: 'upload-documents-borrower',
};

export const useStepBorrower = (isDynamic: boolean) => {
  const appStep = useStepAppAndEntity(isDynamic);
  const creditStep = useStepCreditReport(
    appStep.status as ProgressStepItemStatus,
    isDynamic
  );
  const uploadDocumentsStep = useStepUploadDocuments(
    [creditStep.status, appStep.status] as ProgressStepItemStatus[],
    UPLOAD_DOCUMENTS_BORROWER,
    TASK_SECTIONS.BORROWER_UPLOAD_DOCUMENTS
  );

  return useMemo(() => {
    const status =
      appStep.status === ProgressStepItemStatus.DONE &&
      creditStep.status === ProgressStepItemStatus.DONE
        ? ProgressStepItemStatus.DONE
        : ProgressStepItemStatus.IN_PROGRESS;

    return {
      ...BORROWER,
      status,
      items: [appStep, creditStep, uploadDocumentsStep],
    };
  }, [appStep, creditStep, uploadDocumentsStep]);
};
