export enum VALIDATION_RULES {
  MAX_LENGTH = 'maxLength',
  REQUIRED = 'required',
  MIN = 'min',
  MAX = 'max',
  PATTERN = 'pattern',
  MIN_DATE = 'minDate',
}

export enum VALIDATION_DEFAULT_MESSAGES {
  REQUIRED = 'Required field',
  DATE = 'Invalid Date',
  VALUE = 'Invalid Value',
  LENGTH = 'Invalid Input Length',
}
