import React from 'react';
import { ListItem, Typography } from '@mui/material';
import { Collapsed } from '@components/Collapsed';

import styles from './commentItem.module.scss';

interface CommentItemProps {
  comments?: string | null;
}

export const CommentItem = ({ comments }: CommentItemProps) => {
  return (
    <ListItem classes={{ root: styles.comment }}>
      <Collapsed label="Underwriter comment">
        <Typography>{comments ?? ''}</Typography>
      </Collapsed>
    </ListItem>
  );
};
