import React from 'react';
import {
  DoneFillIcon,
  StatusAlertIcon,
  StatusInProgressIcon,
} from '@lola/ui-react-components';
import { VERTICAL_STATUS_STATE } from '@components/verticalStepDetail/VerticalStepDetail.component';

export const STATUS_ICON: Partial<Record<VERTICAL_STATUS_STATE, JSX.Element>> =
  {
    [VERTICAL_STATUS_STATE.IN_PROGRESS]: <StatusInProgressIcon />,
    [VERTICAL_STATUS_STATE.ERROR]: <StatusAlertIcon />,
    [VERTICAL_STATUS_STATE.DONE]: <DoneFillIcon />,
  };
