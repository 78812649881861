import { Paper, Typography } from '@mui/material';
import React from 'react';
import { Collapsed } from '@components/Collapsed';

import { FullApplicationForm } from '@pages/personalLoans/pages/buildMyLoan/pages/applicationAndEntityInformation/components/FullApplicationForm/FullApplicationForm.component';

export const FullApplication = () => {
  return (
    <Paper elevation={2}>
      <Collapsed label="Full Application" defaultExpanded>
        <Typography variant="p" paragraph>
          Add information about guarantor, experience, assets and etc. You can
          fill it later if you want to proceed to initial feasibility faster.
        </Typography>
        <FullApplicationForm />
      </Collapsed>
    </Paper>
  );
};
