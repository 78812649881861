import { STATUS_CONFIG } from '@config/status';

export enum UPLOAD_TYPE {
  STANDARD = 'standard',
  CONDITIONAL = 'conditional',
}

export enum UPLOAD_STATUS {
  NOT_UPLOADED = 'Created',
  UPLOADED = 'Ready for Review',
  IN_REVIEW = 'Under Review',
  APPROVED = 'Completed',
  REOPENED = 'Reopened',
  DENIED = 'Denied',
}

export const UPLOAD_STATUS_CONFIG = {
  [UPLOAD_TYPE.STANDARD]: {
    [UPLOAD_STATUS.NOT_UPLOADED]: {
      ...STATUS_CONFIG.INFO,
      label: 'Not uploaded',
    },
    [UPLOAD_STATUS.UPLOADED]: {
      ...STATUS_CONFIG.SUCCESS_FILLED,
      label: 'Uploaded',
    },
  },
  [UPLOAD_TYPE.CONDITIONAL]: {
    [UPLOAD_STATUS.NOT_UPLOADED]: {
      ...STATUS_CONFIG.INFO,
      label: 'Not uploaded',
    },
    [UPLOAD_STATUS.UPLOADED]: {
      ...STATUS_CONFIG.INFO,
      label: 'Uploaded',
    },
    [UPLOAD_STATUS.IN_REVIEW]: {
      ...STATUS_CONFIG.WARNING_FILLED,
      label: 'In review',
    },
    [UPLOAD_STATUS.REOPENED]: {
      ...STATUS_CONFIG.WARNING_FILLED,
      label: 'In review',
    },
    [UPLOAD_STATUS.APPROVED]: {
      ...STATUS_CONFIG.SUCCESS_FILLED,
      label: 'Approved',
    },
    [UPLOAD_STATUS.DENIED]: {
      ...STATUS_CONFIG.ERROR_FILLED,
      label: 'Denied',
    },
  },
};
