import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';

export const updateStepperConfig = <T>(
  stepperConfig: ProgressStepperItem[],
  activeStep: T,
  fields: {
    [key: string]: string;
  }
): ProgressStepperItem[] => {
  return stepperConfig.map((step) => {
    if (fields[step.id] || fields[step.id] === null) {
      return {
        ...step,
        status: ProgressStepItemStatus.DONE,
      };
    }

    if (step.id === activeStep) {
      return {
        ...step,
        status: ProgressStepItemStatus.IN_PROGRESS,
      };
    }

    return { ...step };
  });
};
