import { ChipProps } from '@mui/material';

export const STATUS_CONFIG: Record<string, ChipProps> = {
  SUCCESS: {
    color: 'success',
    size: 'small',
    variant: 'outlined',
  },
  INFO: {
    color: 'info',
    size: 'small',
    variant: 'outlined',
  },
  ERROR: {
    color: 'error',
    size: 'small',
    variant: 'outlined',
  },
  DISABLED: {
    color: 'default',
    size: 'small',
    variant: 'filled',
  },
  INFO_FILLED: {
    color: 'info',
    size: 'small',
    variant: 'filled',
  },
  SUCCESS_FILLED: {
    color: 'success',
    size: 'small',
    variant: 'filled',
  },
  WARNING_FILLED: {
    color: 'warning',
    size: 'small',
    variant: 'filled',
  },
  ERROR_FILLED: {
    color: 'error',
    size: 'small',
    variant: 'filled',
  },
};
