import React from 'react';
import { Chip, Stack } from '@mui/material';
import { LolaBffApiContractsModelsUser } from '@api/output/api';
import {
  RemainingElements,
  RemainingElementsList,
} from './teamsListRenderer.styles';

export const teamsListRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsUser
) => {
  const { managesTeams, teamId } = row;
  const brokerTeamId = teamId ? [teamId] : [];

  let displayTeams = !managesTeams?.length ? brokerTeamId : managesTeams;
  displayTeams = displayTeams.map((id) => `Team ${id}`);

  return (
    <Stack
      direction={{ xs: 'row' }}
      sx={{ alignItems: 'baseline' }}
      spacing={{ xs: 1 }}
    >
      {displayTeams?.length === 0 ? (
        <Chip color="info" size="small" label="N/A" variant="standard" />
      ) : (
        <>
          <Chip
            color="info"
            size="small"
            label={displayTeams[0]}
            variant="standard"
          />
          {displayTeams?.length > 1 && (
            <RemainingElements>
              <Chip
                color="info"
                size="small"
                label={`+${displayTeams?.length - 1}`}
                variant="standard"
              />
              <RemainingElementsList>
                <ul>
                  {displayTeams.slice(1).map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </RemainingElementsList>
            </RemainingElements>
          )}
        </>
      )}
    </Stack>
  );
};
