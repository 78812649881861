import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Slide } from '@mui/material';
import { getToasts } from '@store/selectors/toast.selectors';

import { removeToastById } from '@store/slices/toast.slice';
import { ToastItem } from '@containers/Toast/components/ToastItem/ToastItem.component';
import styles from './toast.module.scss';

export const Toast = () => {
  const dispatch = useDispatch();
  const toasts = useSelector(getToasts);

  const closeToast = useCallback(
    (id: string) => {
      dispatch(removeToastById(id));
    },
    [dispatch]
  );

  return (
    <div className={styles.toastBar}>
      <div className={styles.wrapper}>
        {toasts.map((toast) => (
          <Slide key={toast.id} direction="left" in timeout={600}>
            <ToastItem key={toast.id} {...toast} onClose={closeToast} />
          </Slide>
        ))}
      </div>
    </div>
  );
};
