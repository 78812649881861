import { configureStore } from '@reduxjs/toolkit';
import { ConfigureStoreOptions } from '@reduxjs/toolkit/src/configureStore';
import { api } from '@api/output/api';
import { reducer as authReducer } from '@store/slices/auth.slice';
import { reducer as toastReducer } from '@store/slices/toast.slice';
import { initApi } from '@store/api';

initApi();

export const storeConfig: ConfigureStoreOptions = {
  reducer: {
    auth: authReducer,
    toast: toastReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([api.middleware]),
};

export const store = configureStore(storeConfig);

export type RootState = ReturnType<typeof store.getState>;
