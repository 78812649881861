import React, { useEffect, useRef } from 'react';
import { ButtonProps } from '@mui/material';
import { AddIcon } from '@lola/ui-react-components';
import { UPLOADER_STATE } from '../Uploader.constants';
import { ButtonLabelConfigType } from '../Uploader.types';

export const useButtonConfig = (
  state: UPLOADER_STATE,
  labelConfig: ButtonLabelConfigType,
  initIcon?: JSX.Element
) => {
  const labelRef = useRef<string>(labelConfig[UPLOADER_STATE.INIT]);
  const variantRef = useRef<ButtonProps['variant']>('contained');
  const startIconRef = useRef<JSX.Element | null>(initIcon || null);

  useEffect(() => {
    labelRef.current = labelConfig[state] || labelRef.current;
  }, [state]);

  if (state === UPLOADER_STATE.SUCCESS) {
    variantRef.current = 'outlined';
    startIconRef.current = <AddIcon />;
  }

  return {
    label: labelConfig[state] || labelRef.current,
    startIcon: startIconRef.current,
    variant: variantRef.current,
  };
};
