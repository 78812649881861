import { useMemo } from 'react';
import { useGetApiV1LicensesQuery } from '@api/output/api';
import { LICENSING_STATES } from '@pages/profile/pages/licensingInfo/components/LicenseUpdateDrawer/LicenseUpdateDrawer.constants';
import { LICENSE_STATUS_TYPE } from '@typings/common';

export interface UseLicenseCheckResult {
  isLicenseRequired?: boolean;
  isBrokerLicenseValid?: boolean;
}

export const useLicensingCheck = (state?: string): UseLicenseCheckResult => {
  const { data: storedLicenses } = useGetApiV1LicensesQuery();

  return useMemo(() => {
    if (!state) {
      return {
        isLicenseRequired: undefined,
        isBrokerLicenseValid: undefined,
      };
    }

    const isLicenseRequired = LICENSING_STATES.includes(state);
    const brokerLicense = storedLicenses?.find(
      (license) => license.stateCode === state
    );

    let isBrokerLicenseValid = false;

    if (brokerLicense) {
      const isBrokerLicenseActive =
        brokerLicense.status !== LICENSE_STATUS_TYPE.EXPIRED;
      const isBrokerLicenseDateValid =
        new Date(brokerLicense.expirationDate) > new Date();

      isBrokerLicenseValid = isBrokerLicenseActive && isBrokerLicenseDateValid;
    }

    return {
      isLicenseRequired,
      isBrokerLicenseValid,
    };
  }, [state, storedLicenses]);
};
