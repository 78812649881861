import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { UseFormTrigger, FieldValues } from 'react-hook-form';
import { convertStepsToArr } from '@pages/personalLoans/hooks/useActiveStepRoute';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import { useActiveRoute } from '@hooks/useActiveRoute';
import { NavigationConfig } from '@typings/common';

export enum DIRECTIONS {
  UP = 1,
  DOWN = -1,
}

const useRelativeStep = (
  steps: NavigationConfig[],
  index: number,
  direction: DIRECTIONS
) => {
  return useMemo(() => {
    let possibleIndex = index + direction;
    while (steps[possibleIndex]) {
      const possibleStep = steps[possibleIndex];
      if (possibleStep?.metadata?.status !== ProgressStepItemStatus.LOCKED) {
        return possibleStep;
      }
      possibleIndex += direction;
    }
  }, [index, steps, direction]);
};

const useNavigationHandler = <T extends FieldValues>(
  navigationConfig?: NavigationConfig,
  triggerValidation?: UseFormTrigger<T>,
  isDirty?: boolean
) => {
  const navigate = useNavigate();

  return useMemo(() => {
    if (navigationConfig === undefined) {
      return undefined;
    }

    return async () => {
      const isValid =
        triggerValidation && isDirty
          ? await triggerValidation(undefined, { shouldFocus: true })
          : true;

      if (isValid) {
        navigate(navigationConfig.to);
        window.scrollTo({ top: 0 });
      }
    };
  }, [navigationConfig, triggerValidation, isDirty]);
};

export const useStepsNavigation = <T extends FieldValues>(
  steps: ProgressStepperItem[] = [],
  triggerValidation?: UseFormTrigger<T>,
  isDirty?: boolean
) => {
  const flatSteps = useMemo(() => convertStepsToArr(steps), [steps]);
  const [, index] = useActiveRoute(flatSteps);

  const previousStep = useRelativeStep(flatSteps, index, DIRECTIONS.DOWN);
  const nextStep = useRelativeStep(flatSteps, index, DIRECTIONS.UP);

  const goToPreviousStep = useNavigationHandler<T>(
    previousStep,
    triggerValidation,
    isDirty
  );
  const goToNextStep = useNavigationHandler<T>(
    nextStep,
    triggerValidation,
    isDirty
  );

  return {
    currentStep: flatSteps[index],
    goToPreviousStep,
    goToNextStep,
  };
};
