import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useLoanDetails } from '@pages/personalLoans/pages/buildMyLoan/hooks/useLoanDetails';
import {
  PROGRAMS_WITHOUT_INSPECTIONS,
  STRATEGY_WITHOUT_INSPECTION,
} from './constructionBudget.constants';

export const useHasConstructionBudgetInspection = () => {
  const { data } = useLoanDetails();
  const [developmentStrategy] = useWatch({
    name: ['loan.developmentStrategy'],
  });

  return useMemo(
    () =>
      !(
        PROGRAMS_WITHOUT_INSPECTIONS.includes(
          data?.loan?.loanProgramType?.toLowerCase() ?? ''
        ) && STRATEGY_WITHOUT_INSPECTION.includes(developmentStrategy)
      ),
    [data?.loan?.loanProgramType, developmentStrategy]
  );
};
