import { useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import {
  PROGRAM_KEY,
  SAVED_QUOTE_ID_TO_LOAN,
} from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { checkRental } from '@pages/personalLoans/pages/buildMyLoan/pages/product/Product.utils';
import { QUOTE_TYPE } from '@typings/common';

/* TODO: Can be removed at the start of 2024 */
const LEGACY_SAVED_QUOTE_ID = 'loan.lola.linkedQuoteId';

export const useQuoteTypeAndId = () => {
  const [loanProgramType, savedQuoteId, legacyQuoteId] = useWatch({
    name: [PROGRAM_KEY, SAVED_QUOTE_ID_TO_LOAN, LEGACY_SAVED_QUOTE_ID],
  });

  const quoteId = useMemo(() => {
    return savedQuoteId ?? legacyQuoteId;
  }, [savedQuoteId, legacyQuoteId]);

  const quoteType = useMemo(() => {
    return checkRental(loanProgramType)
      ? QUOTE_TYPE.RENTAL
      : QUOTE_TYPE.SHORT_TERM;
  }, [loanProgramType]);

  return {
    quoteId,
    quoteType,
  };
};
