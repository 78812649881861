import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { BlockProps, FieldConfig, FormData } from '@form-configs/types';
import { DynamicItem } from '@components/DynamicForm/components/DynamicItem/DynamicItem.component';
import {
  getCurrentFields,
  resetWithOverlap,
  ResetWithOverlapDataConfig,
} from '@components/DynamicForm/utils';
import { GridHelperText } from '@components/GridHelperText/GridHelperText.component';

import styles from './dynamicBlock.module.scss';

export interface DynamicBlockProps extends BlockProps {
  items?: FieldConfig[];
}

export interface NewClearResult {
  newItems: FieldConfig[];
  clearItems: FieldConfig[];
}

export const DynamicBlock = ({
  col = [12],
  helperText,
  rowSpacing = 3,
  columnSpacing = 3,
  items = [],
  hidden = false,
}: DynamicBlockProps) => {
  const [resetConfig, setResetConfig] = useState<ResetWithOverlapDataConfig>();
  const [fields, setFields] = useState<FieldConfig[]>([]);
  const { watch, setValue, resetField, getValues } = useFormContext();

  useEffect(() => {
    const { newItems } = getCurrentFields(fields, items, {});
    setFields(newItems);
  }, []);

  const triggerChange = useCallback(
    (values: FormData) => {
      const { newItems, clearItems, isChanged } = getCurrentFields(
        fields,
        items,
        values
      );

      if (!isChanged) {
        return;
      }

      if (newItems.length || clearItems.length) {
        setFields(newItems);
        setResetConfig({
          fieldsToReset: clearItems,
          fieldsToRender: newItems,
          fields,
        });
      }
    },
    [items, fields]
  );

  useEffect(() => {
    const { unsubscribe } = watch(triggerChange);
    return unsubscribe;
  }, [triggerChange]);

  useEffect(() => {
    triggerChange(getValues());
  }, [triggerChange]);

  useEffect(() => {
    if (resetConfig?.fieldsToReset) {
      resetWithOverlap({
        updateFn: setValue,
        resetFn: resetField,
        ...resetConfig,
      });
    }
  }, [resetConfig]);

  const blockClasses = classnames(styles.container, {
    [styles.hidden]: hidden,
  });

  return (
    <Grid
      container
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
      className={blockClasses}
    >
      {fields.map((item, index) => {
        const gridIndex = index % col.length;
        return (
          <Grid key={item.id} item xs={12} sm={col[gridIndex]}>
            <DynamicItem {...item} />
          </Grid>
        );
      })}
      <GridHelperText helperText={helperText} />
    </Grid>
  );
};
