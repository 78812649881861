import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import { LolaBffApiContractsModelsLoanLoanProperty } from '@api/output/api';
import { useEntityTasksBySection } from '@pages/personalLoans/pages/buildMyLoan/hooks/useEntityTasksBySection';
import { getFilesByEntity } from '@pages/personalLoans/pages/buildMyLoan/utils/tasks.utils';
import { TASK_SECTIONS } from '@typings/common';
import { useStepStatus } from '../useStepStatus';

const ORDER_APPRAISAL: ProgressStepperItem = {
  id: '/personal-loans/:id/build-my-loan/order-appraisal',
  to: 'order-appraisal',
  title: 'Order appraisal',
};

export const useStepOrderAppraisal = (
  requiredSteps: (ProgressStepItemStatus | undefined)[]
) => {
  const [tasks] = useEntityTasksBySection(TASK_SECTIONS.APPRAISAL);
  const properties: LolaBffApiContractsModelsLoanLoanProperty[] =
    useWatch({
      name: 'properties',
    }) ?? [];
  const documents = properties.map((property) => {
    return getFilesByEntity(tasks, 'Appraisal', property);
  });

  const isCompleted = useMemo(() => {
    return Object.keys(tasks).every((taskId) => {
      return tasks[taskId][0]?.documents?.[0];
    });
  }, [documents]);

  const status = useStepStatus({
    requiredStepsStatus: requiredSteps,
    isCompleted,
  });

  return useMemo(
    () => ({
      ...ORDER_APPRAISAL,
      status,
    }),
    [status]
  );
};
