import React, { useCallback } from 'react';
import { LinearProgress, Paper } from '@mui/material';
import { TableColumnType } from 'antd';
import {
  createDateRenderer,
  createDateSorter,
  createDefaultSorter,
  DataTable,
  useTableDataSearchAndFilter,
  VTScrollHandler,
} from '@lola/ui-react-components';
import { DataControlPanel } from '@components/DataTableControlPanel/DataTableControlPanel.component';
import { LolaBffApiContractsModelsActivitiesActivityLogData } from '@api/output/api';
import { DATE_TIME_FORMAT } from '@constants/date.constants';
import { useActivityLogApi } from './hooks/useActivityLogApi';
import { userInfoRenderer } from './renderers/userInfoRenderer';
import { activityNameRenderer } from './renderers/activityNameRenderer';

import styles from './changeLog.module.scss';

export const COLUMNS_CONFIG: TableColumnType<LolaBffApiContractsModelsActivitiesActivityLogData>[] =
  [
    {
      dataIndex: 'activity',
      title: 'Action',
      width: '50%',
      render: activityNameRenderer,
    },
    {
      dataIndex: 'userInfo',
      title: 'Performed By',
      sorter: createDefaultSorter('userInfo.name'),
      render: userInfoRenderer,
      width: '25%',
    },
    {
      dataIndex: 'activityDate',
      title: 'Date',
      sorter: createDateSorter('activityDate'),
      render: createDateRenderer(DATE_TIME_FORMAT),
      width: '25%',
    },
  ];

export const ChangeLog = () => {
  /* Pagination is not enabled */
  const { items, isLoading, loadMore } = useActivityLogApi();

  const {
    filteredData,
    handleSearchChange,
    handleFilterChange,
    appliedFilters,
    filters,
  } =
    useTableDataSearchAndFilter<LolaBffApiContractsModelsActivitiesActivityLogData>(
      items,
      {
        'userInfo.name': {
          values: [],
          title: 'Performed By',
        },
      },
      'activity',
      ''
    );

  const onScroll: VTScrollHandler = useCallback(
    ({ isEnd }) => {
      if (loadMore && isEnd && !isLoading) {
        loadMore();
      }
    },
    [loadMore]
  );

  if (isLoading) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  return (
    <>
      <DataControlPanel
        appliedFilters={appliedFilters}
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleSearchChange={handleSearchChange}
        searchPlaceholder="Search by document"
        title="Filters"
      />
      <div className={styles.wrapper}>
        <Paper elevation={0}>
          <DataTable
            rowKey="activityId"
            scroll={{
              x: 900,
              y: 450,
            }}
            columns={COLUMNS_CONFIG}
            dataSource={filteredData}
            onScroll={onScroll}
          />
        </Paper>
      </div>
    </>
  );
};
