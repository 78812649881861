import React from 'react';
import { Paper, Typography } from '@mui/material';
import { InformationIcon } from '@lola/ui-react-components';
import styles from './licensingNote.module.scss';

export const LicensingNote = () => (
  <Paper elevation={3} className={styles.container}>
    <InformationIcon width={24} height={24} />
    <div>
      <Typography variant="pBold">Licensing information</Typography>
      <Typography>You can add your brokerage licenses to this list.</Typography>
    </div>
  </Paper>
);
