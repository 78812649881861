import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import {
  LolaBffApiContractsModelsActivitiesActivityLogData,
  useLazyGetApiV1ActivityLogQuery,
} from '@api/output/api';

export type LogItem = LolaBffApiContractsModelsActivitiesActivityLogData;

export const useActivityLogApi = () => {
  const { loanId = '' } = useParams();
  const [items, setItems] = useState<LogItem[]>([]);

  const [loadActivityLog, { data, isFetching }] =
    useLazyGetApiV1ActivityLogQuery();

  const defaultParams = useMemo(
    () => ({
      pageSize: 50,
      entityType: 'loan',
      entityId: loanId,
    }),
    [loanId]
  );

  useEffect(() => {
    loadActivityLog(defaultParams);
  }, []);

  useEffect(() => {
    if (data?.data) {
      const newItems = items.concat(data?.data);
      setItems(newItems);
    }
  }, [data]);

  const loadMore = useMemo(() => {
    if (data?.meta?.hasMore && data?.meta?.cursor) {
      return () =>
        loadActivityLog({
          ...defaultParams,
          cursor: data.meta?.cursor as string,
        });
    }
  }, [data?.meta?.hasMore, data?.meta?.cursor]);

  const isLoading = useMemo(() => {
    return items.length === 0 && isFetching;
  }, [items, isFetching]);

  return {
    items,
    isLoading,
    loadMore,
  };
};
