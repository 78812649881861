import { FieldProp } from '@components/DynamicForm/contexts/FieldsProps.context';

export const shortTermFormFieldsProps: Record<string, FieldProp[]> = {
  'borrowerInfo.primaryGuarantor': [
    {
      propName: 'locked',
      value: true,
    },
  ],
  'borrowerInfo.borrowingEntity': [
    {
      propName: 'locked',
      value: true,
    },
  ],
};

export const rentalFormFieldsProps: Record<string, FieldProp[]> = {
  borrowingEntity: [
    {
      propName: 'locked',
      value: true,
    },
  ],
};

export const QUOTE_PRODUCT_KEY = 'loanSummary.loanProduct';
