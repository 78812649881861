import React from 'react';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

import styles from './quickLoanDates.module.scss';

interface QuickLoanDatesProps {
  createdAt?: string | null;
  modifiedAt?: string | null;
}

export const QuickLoanDates = ({
  createdAt = '',
  modifiedAt = '',
}: QuickLoanDatesProps) => {
  return (
    <div className={styles.dates}>
      {createdAt && (
        <Typography variant="pDescription">
          Created: {dayjs(createdAt).format('D MMM YYYY')}
        </Typography>
      )}
      {modifiedAt && (
        <Typography variant="pDescription">
          Last update: {dayjs(modifiedAt).format('D MMM YYYY')}
        </Typography>
      )}
    </div>
  );
};
