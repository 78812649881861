import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { LinearProgress, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Drawer } from '@lola/ui-react-components';
import { ContentSectionFooter } from '@components/ContentSection';
import { TabPanel } from '@components/TabPanel/TabPanel.component';
import { Modal } from '@components/Modal';
import { useOpenState } from '@hooks/useOpenState';
import { ManageAccessConfirm } from '@containers/ManageAccessDrawer/components';
import { useRole } from '@hooks/useRole';
import { useLazyGetApiV1LoansQuery } from '@api/output/api';
import {
  DEFAULT_TABS,
  DrawerState,
  MANAGE_ACCESS_FORM,
  MANAGE_ACCESS_FORM_NAME,
  TAB_VALUE,
  TABS,
} from './ManageAccessDrawer.constants';
import { Processors, Brokers } from './components';

import styles from './manageAccessDrawer.module.scss';

interface ManageAccessDrawerProps {
  drawerState: DrawerState;
  onClose: () => void;
}

export const ManageAccessDrawer = ({
  drawerState,
  onClose,
}: ManageAccessDrawerProps) => {
  const [activeTab, setActiveTab] = useState(TAB_VALUE.PROCESSORS);

  const [isModalOpen, openModal, closeModal] = useOpenState();
  const { isOpen, context = {} } = drawerState;

  const methods = useForm();
  const handleDrawerClose = useCallback(() => {
    methods.resetField(MANAGE_ACCESS_FORM_NAME);
    onClose();
  }, [methods.resetField]);

  const { loanId = '' } = useParams();
  const [loadLoans, { data, isLoading }] = useLazyGetApiV1LoansQuery();
  useEffect(() => {
    if (!context.id && isOpen) {
      loadLoans();
      methods.setValue(MANAGE_ACCESS_FORM.LOAN_ID, loanId);
      return;
    }
    if (isOpen) {
      methods.setValue(MANAGE_ACCESS_FORM.LOAN_ID, context.id);
    }
  }, [methods.setValue, context.id, isOpen]);

  const role = useRole();
  const localContext = useMemo(() => {
    if (!context.id && isOpen) {
      const { id, processors, brokerName, brokerId, brokerEmail } =
        data?.find(({ id }) => id === loanId) ?? {};

      return {
        id,
        processors,
        brokerName,
        brokerId,
        brokerEmail,
      };
    }
    if (isOpen) {
      return context;
    }

    return {};
  }, [data, context.id, isOpen]);

  const actions = useMemo(
    () => ({
      cancel: {
        onClick: handleDrawerClose,
      },
      button: {
        onClick: openModal,
        disabled: !methods.formState.dirtyFields[MANAGE_ACCESS_FORM_NAME],
        label: 'Confirm',
      },
    }),
    [
      handleDrawerClose,
      openModal,
      methods.formState.dirtyFields[MANAGE_ACCESS_FORM_NAME],
    ]
  );

  return (
    <FormProvider {...methods}>
      <Modal isOpen={isModalOpen} title="Manage access" onClose={closeModal}>
        <ManageAccessConfirm
          handleClose={closeModal}
          handleDrawerClose={handleDrawerClose}
        />
      </Modal>
      <Drawer
        isOpen={isOpen}
        onClose={handleDrawerClose}
        className={styles.drawer}
        title="Manage access"
      >
        <Tabs
          value={activeTab}
          className={styles.tabs}
          onChange={(_: SyntheticEvent, value: string) =>
            setActiveTab(value as TAB_VALUE)
          }
        >
          {(TABS[role] ?? DEFAULT_TABS).map(({ value, label }) => (
            <Tab key={label} value={value} label={label} />
          ))}
        </Tabs>
        <div className={styles.section}>
          {isLoading && <LinearProgress />}
          {!isLoading && (
            <div className={styles.content}>
              <TabPanel active={activeTab === TAB_VALUE.PROCESSORS}>
                <Processors list={localContext.processors} />
              </TabPanel>
              <TabPanel active={activeTab === TAB_VALUE.BROKERS}>
                <Brokers
                  currentBrokerId={localContext.brokerId ?? ''}
                  name={
                    localContext.brokerName ?? localContext.brokerEmail ?? ''
                  }
                  email={localContext.brokerEmail ?? ''}
                />
              </TabPanel>
            </div>
          )}
        </div>
        <ContentSectionFooter actions={actions} />
      </Drawer>
    </FormProvider>
  );
};
