import React from 'react';
//MUI
import { Typography } from '@mui/material';
//Components
import { UserProfileBall } from '@components/UserProfileBall/UserProfileBall.component';
//Style
import style from './manageConditionUserHeader.module.scss';

export interface ManageConditionUserHeaderProps {
  name?: string | null;
  lastName?: string | null;
  title?: string | null;
  date?: string | null;
}

export const ManageConditionUserHeader = ({
  name,
  lastName,
  title,
  date,
}: ManageConditionUserHeaderProps) => {
  return (
    <div className={style.uploadDescriptionText}>
      <UserProfileBall firstName={name} lastName={lastName} size="small" />
      {title && <Typography variant="pBold">{title}</Typography>}
      {date && <Typography variant="pDescription">{date}</Typography>}
    </div>
  );
};
