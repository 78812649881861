import { FieldConfig } from '@form-configs/types';

export const updateLabel = (
  formItems: FieldConfig[],
  oldLabel: string,
  newLabel: string
) => {
  return formItems.map((formItem) => {
    if (formItem.items) {
      updateLabel(formItem.items, oldLabel, newLabel);
    }

    if (formItem?.props?.name) {
      formItem.props.name = formItem.props.name.includes(oldLabel)
        ? formItem.props.name.replace(oldLabel, newLabel)
        : `${newLabel}.${formItem.props.name}`;
    }

    return formItem;
  });
};
