import React, { useMemo } from 'react';
import { LinearProgress, Grid, SelectProps } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { useGetApiV1TeamsQuery } from '@api/output/api';
import { GridHelperText } from '@components/GridHelperText/GridHelperText.component';
import styles from '../ChangeTeam/components/BrokerManagerForm/brokerManagerForm.module.scss';

interface SelectDestinationTeamProps {
  label: string;
  showHelperText: boolean;
  variant?: SelectProps['variant'];
}

export const SelectDestinationTeam = ({
  label,
  showHelperText,
  variant = 'standard',
}: SelectDestinationTeamProps) => {
  const { data: teams = [], isLoading } = useGetApiV1TeamsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const destinationTeamId = useWatch({ name: 'destinationTeamId' });

  const allTeams = useMemo(
    () =>
      teams.map((team) => {
        const { id, manager } = team ?? {};
        return {
          value: id ?? -1,
          label: `Team ${id}`,
          managerName: manager?.fullName ?? '',
        };
      }),
    [teams]
  );

  const helperText = useMemo(() => {
    const { managerName } =
      allTeams?.find((team) => team.value === destinationTeamId) ?? {};

    return destinationTeamId ? (
      <div className={styles.helperText}>
        <GridHelperText helperText="Two managers can not lead one Team at the same time." />
        <GridHelperText
          helperText={`A manager of Team ${destinationTeamId} (${managerName}) will become unassigned.`}
        />
      </div>
    ) : null;
  }, [destinationTeamId]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectControl
          name="destinationTeamId"
          label={label}
          required="Team is required"
          options={allTeams}
          variant={variant}
        />
      </Grid>
      {showHelperText && (
        <Grid item xs={12}>
          {helperText}
        </Grid>
      )}
    </Grid>
  );
};
