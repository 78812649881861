import { useEffect } from 'react';
import {
  MutationTrigger,
  UseMutation,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationDefinition } from '@reduxjs/toolkit/query';
import { DynamicBaseQueryHandler } from '@api/DynamicBaseQuery';
import { useLazyGetApiV1UsersQuery } from '@api/output/api';

type PostHookType<T> = UseMutation<
  MutationDefinition<T, DynamicBaseQueryHandler, never, unknown, 'api'>
>;
type OnSuccessType = () => void;
type UsePostUpdateReturnType<T> = [
  MutationTrigger<
    MutationDefinition<T, DynamicBaseQueryHandler, never, unknown, 'api'>
  >,
  { isUpdating: boolean }
];

export const usePostUpdate = <T>(
  postHook: PostHookType<T>,
  onSuccess: OnSuccessType
): UsePostUpdateReturnType<T> => {
  const [update, { isLoading: isUpdating, isSuccess }] = postHook();
  const [load] = useLazyGetApiV1UsersQuery();

  useEffect(() => {
    if (isSuccess) {
      load({});
      onSuccess();
    }
  }, [isSuccess]);

  return [
    update,
    {
      isUpdating,
    },
  ];
};
