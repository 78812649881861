import { useMemo } from 'react';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import { useQuoteState } from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useQuoteState';
import {
  useLicenseThirdPartyCheck,
  useLicensingCheck,
} from '../useLicenseCheck';

const PRODUCT: ProgressStepperItem = {
  id: '/personal-loans/:id/build-my-loan/product',
  title: 'Product',
  to: 'product',
};

export const useStepProduct = () => {
  const state = useQuoteState();
  const { isLicenseRequired, isBrokerLicenseValid } = useLicensingCheck(state);
  const isThirdPartyLicenseProvided = useLicenseThirdPartyCheck({
    prefix: 'loan.',
  });

  const isLicenseValid = useMemo(() => {
    const isLicenseProvided =
      isBrokerLicenseValid || isThirdPartyLicenseProvided;

    return isLicenseRequired ? isLicenseProvided : true;
  }, [isLicenseRequired, isBrokerLicenseValid, isThirdPartyLicenseProvided]);

  const [status, metadata] = useMemo(() => {
    if (!state) {
      return [ProgressStepItemStatus.IN_PROGRESS];
    }

    if (!isLicenseValid) {
      return [
        ProgressStepItemStatus.ALERT,
        {
          reason:
            'No active license is filled out. Please come back to this section to finish the form.',
        },
      ];
    }

    return [ProgressStepItemStatus.DONE];
  }, [state, isLicenseValid]);

  return useMemo(
    () => ({
      ...PRODUCT,
      status,
      metadata,
    }),
    [status, metadata]
  );
};
