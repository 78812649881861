import { StatusConfig } from '@typings/common';
import { STATUS_CONFIG } from '@config/status';

export enum AUTH_STATUS_TYPE {
  SIGNED = 'Signed',
  NOT_SEND = 'Not Sent',
  SENT = 'Sent',
  UPLOADED = 'Uploaded',
}

export const AUTH_STATUS_CONFIG: StatusConfig<AUTH_STATUS_TYPE> = {
  [AUTH_STATUS_TYPE.SIGNED]: STATUS_CONFIG.SUCCESS_FILLED,
  [AUTH_STATUS_TYPE.UPLOADED]: STATUS_CONFIG.SUCCESS_FILLED,
  [AUTH_STATUS_TYPE.NOT_SEND]: STATUS_CONFIG.INFO,
  [AUTH_STATUS_TYPE.SENT]: STATUS_CONFIG.WARNING_FILLED,
};

export const EMAIL_KEY = 'contactPointEmailValue';
