import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import styles from './userProfileBall.module.scss';

export interface UserProfileBallProps {
  firstName?: string | null;
  lastName?: string | null;
  picture?: string | null;
  size?: 'small' | 'large';
  hash?: string | number;
}

export const UserProfileBall = ({
  firstName,
  lastName,
  picture,
  size,
  hash,
}: UserProfileBallProps) => {
  const userInitials = useMemo(() => {
    let initials = '';

    if (firstName) {
      initials += firstName.charAt(0).toUpperCase();
    }

    if (lastName) {
      initials += lastName.charAt(0).toUpperCase();
    }

    return initials;
  }, [firstName, lastName]);

  const profileClasses = classnames({
    [styles.profile]: true,
    [styles[size as string]]: !!size,
  });

  const [showPicture, setShowPicture] = useState(true);

  return (
    <div data-testid="user-profile-ball" className={profileClasses}>
      {picture && showPicture ? (
        <img
          src={`${picture}?ts=${hash}`}
          alt={userInitials}
          onError={() => setShowPicture(false)}
        />
      ) : (
        userInitials
      )}
    </div>
  );
};
