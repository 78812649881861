import React, {
  ChangeEvent,
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { RadioGroup } from '@components/RadioGroup';
import { LoanPartyInfo } from '@typings/common';
import { CREDIT_REPORT_ACTION } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/hooks/usePaymentParties';
import { LolaBffApiContractsModelsLoanTaskInfo } from '@api/output/api';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { CREDIT_SCORE_ACTION_OPTIONS } from '../../creditScoreCollectionOptions.constants';
import { ReIssueCreditForm } from '../ReIssueCreditForm/ReIssueCreditForm.component';
import { isCreditReportExpired } from './utils/isCreditReportExpired.utils';
import styles from './creditScoreCollectionSelector.module.scss';

export interface CreditScoreCollectionSelectorProps {
  task?: LolaBffApiContractsModelsLoanTaskInfo;
  taskReissue?: LolaBffApiContractsModelsLoanTaskInfo;
  status?: string | null;
  party: LoanPartyInfo;
  onChange: (type: CREDIT_REPORT_ACTION, partyId: string) => void;
  setCreditReportAccordionExpanded: (state: boolean) => void;
  setCurrentlyExpandedCreditScoreDetailsId: (state: string | null) => void;
}

export const CreditScoreCollectionSelector = React.memo(
  ({
    party = {},
    task,
    onChange,
    setCreditReportAccordionExpanded,
    setCurrentlyExpandedCreditScoreDetailsId,
  }: CreditScoreCollectionSelectorProps) => {
    const { disabled } = useContext(LoanDisableContext);
    const [creditScoreAction, setCreditScoreAction] = useState(
      CREDIT_REPORT_ACTION.RE_ISSUE
    );

    const creditReportExpired = useMemo(
      () => isCreditReportExpired(task),
      [task]
    );

    const changeHandler = useCallback(
      (_: ChangeEvent, value: string) => {
        const correctedValue = value as unknown as CREDIT_REPORT_ACTION;
        onChange(correctedValue, party.id as string);
        setCreditScoreAction(correctedValue);
      },
      [party, setCreditScoreAction]
    );

    return (
      <div>
        {creditReportExpired && (
          <Alert severity="error" className={styles.alert}>
            <AlertTitle>Credit score is expired</AlertTitle>
            Please generate new credit score or re-issue
          </Alert>
        )}

        {creditScoreAction === CREDIT_REPORT_ACTION.RE_ISSUE && (
          <Alert severity="error" className={styles.alert}>
            <AlertTitle>Credit Re-issue Maintenance</AlertTitle>
            Xactus 360 are currently not available. We expect to have this issue
            fixed very soon but all other CRA’s can be used for re-issue credit.
          </Alert>
        )}
        <RadioGroup
          label="Generate a new credit score or re-issue"
          options={CREDIT_SCORE_ACTION_OPTIONS}
          row
          major
          value={creditScoreAction}
          onChange={changeHandler}
          disabled={disabled}
        />
        {creditScoreAction === CREDIT_REPORT_ACTION.RE_ISSUE && (
          <div className={styles.creditScore}>
            <ReIssueCreditForm
              party={party}
              setCreditReportAccordionExpanded={
                setCreditReportAccordionExpanded
              }
              setCurrentlyExpandedCreditScoreDetailsId={
                setCurrentlyExpandedCreditScoreDetailsId
              }
            />
          </div>
        )}
      </div>
    );
  }
);
