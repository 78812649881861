import React from 'react';
// MUI
import { Skeleton } from '@mui/material';
// style
import style from './ConditionPanelLoading.module.scss';

export const ConditionPanelLoading = () => {
  return (
    <>
      <Skeleton variant="text" />
      <Skeleton
        variant="circular"
        height={30}
        width={30}
        className={style.skeleton}
      />
      <Skeleton variant="rounded" height={140} className={style.skeleton} />
      <Skeleton variant="rounded" height={140} className={style.skeleton} />
    </>
  );
};
