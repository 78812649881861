import { useCallback, useState } from 'react';
import { QUOTE_ID_AGGREGATION_PATH } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { useOpenToast } from '@hooks/useOpenToast';
import { useUpdateLoan } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateLoan';
import { useUpdateBuildMyLoanCache } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateBuildMyLoanCache';

export type UseAttachQuoteResult = [boolean, (id: string) => void];
export type IdChangedHandler = (value: string) => void;

export const useAttachQuote = (
  loanId: string,
  onChange: IdChangedHandler
): UseAttachQuoteResult => {
  const { updateLoanCache } = useUpdateBuildMyLoanCache();
  const updateLoan = useUpdateLoan();
  const openToast = useOpenToast(5000);
  const [isQuoteAttaching, setIsQuoteAttaching] = useState(false);

  const quoteSelectHandler = useCallback(
    async (id: string) => {
      setIsQuoteAttaching(true);
      try {
        const loanBody = {
          [QUOTE_ID_AGGREGATION_PATH]: id,
        };

        await updateLoan(loanId, loanBody);
        updateLoanCache(loanBody);
        onChange(id);
      } catch (e) {
        openToast({
          title: 'Unable to attach quote',
          description:
            'We cannot attach this quote to the current loan, please try to attach another one or try later.',
          isError: true,
        });
      }
      setIsQuoteAttaching(false);
    },
    [loanId]
  );

  return [isQuoteAttaching, quoteSelectHandler];
};
