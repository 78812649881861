import React from 'react';
import { Grid, Typography } from '@mui/material';
import { DynamicFields } from '@components/DynamicForm';
import {
  FORM_FIELDS_CONFIG,
  FORM_FIELDS_CONFIG_OPTIONAL,
} from '../licensingForm.constants';

export interface LicensingFieldsProps {
  required?: boolean;
}

export const LicensingFields = ({ required }: LicensingFieldsProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h3">
          Third Party Broker {required ? '' : '(Optional)'}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="p">
          If you have a third-party broker working on this deal, you must
          provide the broker name and NMLS number to submit this loan to The REI
          Lender.
        </Typography>
      </Grid>
      <Grid item>
        <DynamicFields
          fields={required ? FORM_FIELDS_CONFIG : FORM_FIELDS_CONFIG_OPTIONAL}
        />
      </Grid>
    </Grid>
  );
};
