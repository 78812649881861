import { useMemo } from 'react';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import { useConstructionCompleted } from '@pages/personalLoans/pages/buildMyLoan/hooks/constructionBudget/useConstructionCompleted';
import { useStepStatus } from '../useStepStatus';
import { useTaskPolling } from '../useTaskPolling';
import { useIsConstructionLoanProgram } from './useIsConstructionLoanProgram';
import { isAllRequiredStepsDone } from './useBuildMyLoan.utils';
import {
  CONSTRUCTION_BUDGET_TASK,
  DEFAULT_POLLING_INTERVAL,
  RARE_POLLING_INTERVAL,
} from './useBuildMyLoan.constants';

const CONSTRUCTION_BUDGET: ProgressStepperItem = {
  id: '/personal-loans/:id/build-my-loan/construction-budget',
  to: 'construction-budget',
  title: 'Construction budget',
};

export const useStepConstructionBudget = (
  requiredSteps: (ProgressStepItemStatus | undefined)[],
  isDynamic = false
) => {
  const isConstructionLoanProgram = useIsConstructionLoanProgram();

  useTaskPolling({
    shouldRefetchInit:
      isAllRequiredStepsDone(requiredSteps) &&
      isDynamic &&
      window.location.pathname.includes('construction-budget'),
    config: [
      {
        taskNames: [CONSTRUCTION_BUDGET_TASK.PAYMENT],
        interval: DEFAULT_POLLING_INTERVAL,
      },
      {
        taskNames: [CONSTRUCTION_BUDGET_TASK.INSPECTION],
        interval: RARE_POLLING_INTERVAL,
      },
    ],
  });

  const isCompleted = useConstructionCompleted();

  const status = useStepStatus({
    requiredStepsStatus: requiredSteps,
    isVisible: isConstructionLoanProgram,
    isCompleted,
  });

  return useMemo(() => {
    if (status) {
      return {
        ...CONSTRUCTION_BUDGET,
        status,
      };
    }
  }, [status]);
};
