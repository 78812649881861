import { STATUS_CONFIG } from '@config/status';
import { LOAN_STATUS, StatusConfig } from '@typings/common';

export const LOAN_STATUS_CONFIG: StatusConfig<LOAN_STATUS> = {
  [LOAN_STATUS.IN_REVIEW]: {
    ...STATUS_CONFIG.INFO,
    label: 'In Review',
  },
  [LOAN_STATUS.IN_DEVELOPMENT]: {
    ...STATUS_CONFIG.INFO,
    label: 'In Development',
  },
  [LOAN_STATUS.CLOSING]: STATUS_CONFIG.SUCCESS_FILLED,
  [LOAN_STATUS.CLOSED]: STATUS_CONFIG.SUCCESS_FILLED,
  [LOAN_STATUS.DELIVERED]: STATUS_CONFIG.INFO,
  [LOAN_STATUS.SUBMITTED]: STATUS_CONFIG.INFO,
  [LOAN_STATUS.ARCHIVED]: STATUS_CONFIG.DISABLED,
  [LOAN_STATUS.NA]: {
    ...STATUS_CONFIG.DISABLED,
    label: 'N/A',
  },
};
