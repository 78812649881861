import { Option } from '@typings/common';

export enum FILL_OPTION_VALUE {
  MANUALLY = 'manually',
  UPLOAD = 'upload',
}

export const FILL_OPTIONS: Option[] = [
  {
    label: 'Fill application manually',
    value: FILL_OPTION_VALUE.MANUALLY,
  },
  {
    label: 'Upload application',
    value: FILL_OPTION_VALUE.UPLOAD,
  },
];
