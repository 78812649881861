import React from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { LolaBffApiContractsModelsDashboardKeyStatisticsLoanPortfolioItem } from '@api/output/api';
import { getNumberPostfix } from '@utils/text.utils';

import { formatNumber } from '@utils/numberFormatting.utils';
import styles from './widgetContentList.module.scss';

export interface ListItem
  extends LolaBffApiContractsModelsDashboardKeyStatisticsLoanPortfolioItem {
  name: string;
}

interface WidgetContentListProps {
  list: ListItem[];
}

export const WidgetContentList = ({ list }: WidgetContentListProps) => {
  return (
    <List>
      {list.map((item: ListItem, index: number) => {
        return (
          <ListItem key={item.name} className={styles.listItem}>
            <Typography
              variant="p"
              className={styles.listItemTitle}
              data-mark-color-index={index}
            >
              {item.name}
            </Typography>
            <span>
              <Typography variant="pBold">
                {`$${formatNumber(item.amount, 0)}`} /
              </Typography>
              <Typography variant="pDescription">
                {item.numberOfLoans
                  ? `${item.numberOfLoans} ${getNumberPostfix(
                      item.numberOfLoans,
                      'loan',
                      'loans'
                    )}`
                  : '0 loans'}
              </Typography>
            </span>
          </ListItem>
        );
      })}
    </List>
  );
};
