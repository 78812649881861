import { useFormContext } from 'react-hook-form';
import React, { useCallback } from 'react';
import { Radio } from '@mui/material';
import { ScenarioProps } from '../../ScenarioRentalCalculation.types';

export const PreferredScenario = ({ scenario, disabled }: ScenarioProps) => {
  const { index, total } = scenario;

  const name = `scenario[${index}].preferred`;
  const { watch, setValue } = useFormContext();
  const value = watch(name);

  const handleChange = useCallback(() => {
    for (let i = 0; i < total; i++) {
      const fieldName = `scenario[${i}].preferred`;
      setValue(fieldName, i === index ? 'Yes' : 'No', { shouldDirty: true });
    }
  }, [index, total]);

  return (
    <Radio
      value={value}
      checked={value === 'Yes'}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};
