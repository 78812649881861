import React from 'react';
import { LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse } from '@api/output/api';
import {
  ActionsMenu,
  ActionsMenuProps,
} from '../../../components/ActionsMenu/ActionsMenu.component';

export interface ActionsRendererArg {
  quote: LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse;
  onOpenModal: ActionsMenuProps['onOpenModal'];
  onCopyQuote: ActionsMenuProps['onCopyQuote'];
  onStageQuote: ActionsMenuProps['onStageQuote'];
}

export const actionsRenderer = ({
  quote,
  onOpenModal,
  onCopyQuote,
  onStageQuote,
}: ActionsRendererArg) => {
  return (
    <ActionsMenu
      onOpenModal={onOpenModal}
      quote={quote}
      onCopyQuote={onCopyQuote}
      onStageQuote={onStageQuote}
    />
  );
};
