import React from 'react';
import { Paper, Typography } from '@mui/material';
import { InformationIcon } from '@lola/ui-react-components';

import styles from './removeNotification.module.scss';

export const RemoveNotification = () => {
  return (
    <Paper classes={{ root: styles.wrapper }}>
      <InformationIcon className={styles.icon} />
      <Typography variant="pBold">
        Processor will be removed after you press confirm
      </Typography>
    </Paper>
  );
};
