import { useCallback } from 'react';
import { set, useFormContext } from 'react-hook-form';
import { usePutApiV1PartiesPartyRelationsMutation } from '@api/output/api';
import { getPartyFieldPath } from '@pages/personalLoans/pages/buildMyLoan/utils/fields.utils';
import { useStatusToastsHandlers } from '@hooks/useStatusToastsHandlers';
import { useUpdateBuildMyLoanCache } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateBuildMyLoanCache';
import { CustomValueType } from '@typings/common';

/* Elphi uses the same key for different releationship, but in our get model we cannot duplicate this logic. So we need some extra mapping GET-to-SAVE */
export const CUSTOM_MAPPING: Record<string, string | undefined> = {
  'partyRelationRoles[0]': 'relationRoleType[0]',
};

export const usePartiesRelationship = () => {
  const [updateRelationship] = usePutApiV1PartiesPartyRelationsMutation();
  const { updatePartyPropertyCache } = useUpdateBuildMyLoanCache();
  const { successHandler, errorHandler } = useStatusToastsHandlers();
  const { setError } = useFormContext();

  return useCallback(
    (entityId?: string | null, individualId?: string | null) => {
      return async (name: string, value: CustomValueType) => {
        if (!entityId || !individualId) {
          setError(name, {});
          errorHandler({
            name: 'Error',
            message: 'Entity or Guarantor ID is invalid',
          });
          return;
        }

        const shortName = getPartyFieldPath(name);
        const apiName = CUSTOM_MAPPING[shortName] ?? shortName;
        const body = {
          relations: [
            {
              id: `${entityId}_${individualId}`,
            },
          ],
        };

        set(body.relations[0], apiName, value);

        try {
          await updateRelationship({
            body,
          }).unwrap();
          updatePartyPropertyCache(individualId, shortName, value);
          successHandler();
        } catch (e) {
          setError(name, {});
          errorHandler(e as Error);
        }
      };
    },
    []
  );
};
