// MUI
import { ChipProps } from '@mui/material';
import {
  ApproveMyLoanStatus,
  ActiveLoanStatus,
} from '@pages/personalLoans/types/LoanTypes.type';

export const COLOR_LIST: Record<string, ChipProps['color']> = {
  success: 'success',
  default: 'default',
  warning: 'warning',
  error: 'error',
  primary: 'primary',
};

type STATUS_LIST_TYPE = {
  label: string;
  color: ChipProps['color'];
};

const STATUS_CONDITION_LIST: Record<ApproveMyLoanStatus, STATUS_LIST_TYPE> = {
  [ApproveMyLoanStatus.NewCondition]: {
    label: ApproveMyLoanStatus.NewCondition,
    color: COLOR_LIST.primary,
  },
  [ApproveMyLoanStatus.Approved]: {
    label: ApproveMyLoanStatus.Approved,
    color: COLOR_LIST.success,
  },

  [ApproveMyLoanStatus.Uploaded]: {
    label: ApproveMyLoanStatus.Uploaded,
    color: COLOR_LIST.primary,
  },
  [ApproveMyLoanStatus.InReview]: {
    label: 'In Review',
    color: COLOR_LIST.warning,
  },
  [ApproveMyLoanStatus.Submitted]: {
    label: ApproveMyLoanStatus.Submitted,
    color: COLOR_LIST.warning,
  },
  [ApproveMyLoanStatus.Denied]: {
    label: ApproveMyLoanStatus.Denied,
    color: COLOR_LIST.error,
  },
};

const STATUS_ACTIVE_LOAN_LIST = {
  [ActiveLoanStatus.Archived]: {
    label: ActiveLoanStatus.Archived,
    color: COLOR_LIST.default,
  },
  [ActiveLoanStatus.ClearToClose]: {
    label: 'Clear To Close',
    color: COLOR_LIST.success,
  },
  [ActiveLoanStatus.Closed]: {
    label: ActiveLoanStatus.Closed,
    color: COLOR_LIST.success,
  },
  [ActiveLoanStatus.NotSubmitted]: {
    label: 'Not Submitted',
    color: COLOR_LIST.primary,
  },
  [ActiveLoanStatus.Submitted]: {
    label: ActiveLoanStatus.Submitted,
    color: COLOR_LIST.primary,
  },
  [ActiveLoanStatus.Unsubmitted]: {
    label: ActiveLoanStatus.Unsubmitted,
    color: COLOR_LIST.primary,
  },
  [ActiveLoanStatus.ExceptionRequest]: {
    label: ActiveLoanStatus.ExceptionRequest,
    color: COLOR_LIST.error,
  },
  [ActiveLoanStatus.Pending]: {
    label: ActiveLoanStatus.Pending,
    color: COLOR_LIST.warning,
  },
  [ActiveLoanStatus.ChangeRequest]: {
    label: ActiveLoanStatus.ChangeRequest,
    color: COLOR_LIST.warning,
  },
  [ActiveLoanStatus.NotSet]: {
    label: ActiveLoanStatus.NotSet,
    color: COLOR_LIST.primary,
  },
  [ActiveLoanStatus.InDevelopment]: {
    label: ActiveLoanStatus.InDevelopment,
    color: COLOR_LIST.primary,
  },
  [ActiveLoanStatus.Delivered]: {
    label: ActiveLoanStatus.Delivered,
    color: COLOR_LIST.primary,
  },
  [ActiveLoanStatus.Closing]: {
    label: ActiveLoanStatus.Closing,
    color: COLOR_LIST.primary,
  },
};

export const STATUS_LIST = {
  ...STATUS_CONDITION_LIST,
  ...STATUS_ACTIVE_LOAN_LIST,
};
