import { useCallback, useMemo } from 'react';
import { FormData } from '@form-configs/types';
import { ACTIONS } from '@pages/teamManagement/components/InviteDrawer/components/InviteForm/components/InviteTeam/inviteTeam.constants';
import {
  LolaBffApiContractsModelsUsersCreateAuth0UserRequest,
  usePostApiV1UsersByUserIdReSendInvitationMutation,
  usePostApiV1UsersMutation,
} from '@api/output/api';

export const useInviteFormSubmit = (invitedUserId: string) => {
  const [
    createUser,
    {
      isLoading: isSending,
      isSuccess: isSendSuccess,
      isError: isSendError,
      error: errorSend,
    },
  ] = usePostApiV1UsersMutation();
  const [
    resendInvitation,
    {
      isLoading: isResending,
      isSuccess: isResendSuccess,
      isError: isResendError,
    },
  ] = usePostApiV1UsersByUserIdReSendInvitationMutation();

  const sendInvite = useCallback((values: FormData) => {
    const { teamType, ...request } = values;
    createUser({
      lolaBffApiContractsModelsUsersCreateAuth0UserRequest: {
        ...request,
        teamId: request.teamId || null,
        managerUserId: request.managerUserId || null,
        isNewTeam: teamType === ACTIONS.CREATE_NEW,
      } as LolaBffApiContractsModelsUsersCreateAuth0UserRequest,
    });
  }, []);

  const resendInvite = useCallback(() => {
    resendInvitation({
      userId: invitedUserId,
    });
  }, [invitedUserId]);

  return useMemo(
    () => ({
      handleSubmit: invitedUserId ? resendInvite : sendInvite,
      isSending: isSending || isResending,
      isSuccess: isSendSuccess || isResendSuccess,
      isError: isSendError || isResendError,
      error: errorSend,
    }),
    [
      resendInvite,
      resendInvitation,
      isSending,
      isResending,
      isSendSuccess,
      isResendSuccess,
      isSendError,
      isResendError,
      errorSend,
    ]
  );
};
