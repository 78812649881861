import React from 'react';
import { TableColumnType } from 'antd';
import { DataTable, Modal } from '@lola/ui-react-components';
import { Button } from '@mui/material';
import { PrefilledField } from './hooks/usePrefill.constants';

export interface PrefillModalProps {
  fields: PrefilledField[];
  cancel: () => void;
  confirm: () => void;
  isOpen?: boolean;
}

export const columns: TableColumnType<PrefilledField>[] = [
  {
    dataIndex: 'label',
    title: 'Field',
    width: '40%',
  },
  {
    dataIndex: 'loanValue',
    title: 'Current Value',
    width: '30%',
  },
  {
    dataIndex: 'quoteValue',
    title: 'New Value',
    width: '30%',
  },
];

export const PrefillModal = ({
  isOpen,
  cancel,
  confirm,
  fields,
}: PrefillModalProps) => {
  return (
    <Modal
      title="Override fields"
      isOpen={isOpen}
      onClose={cancel}
      actions={
        <>
          <Button onClick={cancel}>Cancel</Button>
          <Button variant="contained" onClick={confirm}>
            Override
          </Button>
        </>
      }
    >
      <p>Do you want to override the following fields?</p>
      <DataTable dataSource={fields} columns={columns} />
    </Modal>
  );
};
