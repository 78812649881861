export enum ACTION_TYPE {
  REMOVE = 'processor/remove',
  ADD = 'processor/add',
}

export const OPTIONS = [
  {
    label: 'Remove processor',
    value: ACTION_TYPE.REMOVE,
  },
  {
    label: 'Change processor',
    value: ACTION_TYPE.ADD,
  },
];
