import React from 'react';
//MUI
import { Grid, List, ListItem, Paper, Typography } from '@mui/material';
//Types
import { LolaBffApiContractsModelsLoanLoanModel } from '@api/output/api';
//Styles
import styles from './milestoneDatesList.module.scss';

export interface MilestoneListProps {
  data: LolaBffApiContractsModelsLoanLoanModel;
  listTitle: string;
}

export const MilestoneDatesList = ({ data, listTitle }: MilestoneListProps) => {
  return (
    <Paper elevation={0} variant="outlined" className={styles.milestoneList}>
      <List>
        <ListItem className={styles.detailListItem}>
          <Typography variant="h4" className={styles.listTitle}>
            {listTitle}
          </Typography>
        </ListItem>
        <Grid container>
          <Grid item xs={6}>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">Application Disclosure</Typography>
              <Typography variant="pBold">
                {data?.applicationDisclosureDate ?? ''}
              </Typography>
            </ListItem>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">LE sent</Typography>
              <Typography variant="pBold"></Typography>
            </ListItem>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">CD sent</Typography>
              <Typography variant="pBold"></Typography>
            </ListItem>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">Funds released</Typography>
              <Typography variant="pBold">
                {data?.wireReleaseDate ?? ''}
              </Typography>
            </ListItem>
          </Grid>
          <Grid item xs={6}>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">Submit to UW</Typography>
              <Typography variant="pBold">
                {data?.submittedUnderwriterDate ?? ''}
              </Typography>
            </ListItem>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">Estimated closing</Typography>
              <Typography variant="pBold">
                {data?.estimatedClosingDate ?? ''}
              </Typography>
            </ListItem>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">UW Approval</Typography>
              <Typography variant="pBold">
                {data?.underwriterApprovalDate ?? ''}
              </Typography>
            </ListItem>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">AW Clear to Close</Typography>
              <Typography variant="pBold">
                {data?.clearToCloseDate ?? ''}
              </Typography>
            </ListItem>
          </Grid>
        </Grid>
      </List>
    </Paper>
  );
};
