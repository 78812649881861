import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  FormHelperText,
} from '@mui/material';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { ROLES } from '@typings/common';
import { CreateTeam } from '../CreateTeam/CreateTeam.component';
import { SelectTeam } from '../SelectTeam/SelectTeam.component';
import { ActionRoleMap, ACTIONS } from './inviteTeam.constants';

export interface InviteTeamProps {
  role: ROLES;
}

export const InviteTeam = ({ role }: InviteTeamProps) => {
  const {
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [selectedRole, teamType]: [ROLES, ACTIONS] = watch([
    'role',
    'teamType',
  ]);

  useEffect(() => {
    setValue('teamType', '');
  }, [selectedRole]);

  const additionalSectionContent = useMemo(() => {
    if (teamType === ACTIONS.CREATE_NEW) {
      return <CreateTeam role={role} />;
    }

    if (teamType === ACTIONS.SELECT_EXISTING) {
      return <SelectTeam />;
    }

    return null;
  }, [teamType, role]);

  if (!selectedRole || selectedRole === ROLES.PROCESSOR) {
    return null;
  }

  const options = ActionRoleMap[selectedRole] || [];

  return (
    <LabeledCard label="Team">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="teamType"
            control={control}
            render={({ field: { value = '', ...props } }) => (
              <RadioGroup value={value} row {...props}>
                {options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.name}
                  />
                ))}
              </RadioGroup>
            )}
          />
          <FormHelperText error={!!errors?.teamType?.message}>
            {errors?.teamType?.message as string}
          </FormHelperText>
        </Grid>
        {additionalSectionContent && (
          <Grid item xs={12}>
            {additionalSectionContent}
          </Grid>
        )}
      </Grid>
    </LabeledCard>
  );
};
