import React, { useCallback, useState } from 'react';
//MUI
import { Button, ClickAwayListener, MenuItem } from '@mui/material';
//Libraries
import classnames from 'classnames';
//Components
import { Menu } from '@components/Menu';
//Types
import { MenuButtonOptions } from './MenuButton.types';
//Syles
import styles from './menuButton.module.scss';

interface MenuButtonProps {
  label: string;
  options: MenuButtonOptions[];
  startIcon?: React.ReactNode;
}

export const MenuButton = ({ label, options, startIcon }: MenuButtonProps) => {
  const [isPanelVisible, setIsPanelVisible] = useState(false);

  const openPanel = useCallback(() => setIsPanelVisible(true), []);

  const handleClickAway = useCallback(() => setIsPanelVisible(false), []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Menu
          trigger={
            <Button
              variant="outlined"
              size="large"
              startIcon={startIcon}
              onClick={openPanel}
              className={classnames({
                [styles.buttonActive]: isPanelVisible,
              })}
            >
              {label}
            </Button>
          }
        >
          {options.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => option.action()}
              data-testid="menu-button-item"
            >
              {option?.startIcon ?? ''}
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </ClickAwayListener>
  );
};
