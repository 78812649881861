import React, { SyntheticEvent, useCallback, useState, useMemo } from 'react';
import { Tabs, Tab } from '@mui/material';
import { ContentSection } from '@components/ContentSection';
import {
  LolaBffApiContractsModelsLearningCenterLearningCenterSection,
  useGetApiV1LearningCenterQuery,
} from '@api/output/api';
import { DocumentDownloadList } from '@pages/dashboard/components/LearningCenterWidget/components/DocumentDownloadList/DocumentDownloadList.component';
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import { NoContent } from '@components/NoContent';
import styles from './learningCenterWidget.module.scss';

const SECTION_TITLE = 'LEARNING CENTER';

const SectionRender = ({
  activeSection,
}: {
  activeSection?: LolaBffApiContractsModelsLearningCenterLearningCenterSection | null;
}) => {
  if (activeSection) {
    return (
      <DocumentDownloadList
        documents={activeSection.documents ?? []}
        isProtected={activeSection.isViewOnly}
      />
    );
  } else {
    return null;
  }
};

export const LearningCenterWidget = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabChangeHandler = useCallback((e: SyntheticEvent, value: number) => {
    setActiveTab(value);
  }, []);

  const { data, isLoading, isError } = useGetApiV1LearningCenterQuery();

  const activeSection = useMemo(() => {
    if (!data?.sections) return null;
    return data.sections[activeTab] ?? null;
  }, [data, activeTab]);

  if (isError) {
    return (
      <ContentSection title={SECTION_TITLE} isLoading={isLoading}>
        <ErrorNoticeFetchRequest />
      </ContentSection>
    );
  }

  return (
    <ContentSection title={SECTION_TITLE} isLoading={isLoading}>
      {data?.sections ? (
        <>
          <Tabs
            className={styles.tabs}
            value={activeTab}
            onChange={tabChangeHandler}
          >
            {data.sections.map((section, index) => (
              <Tab
                label={section.name}
                value={index}
                key={`${section.name}-${index}`}
              />
            ))}
          </Tabs>
          <SectionRender activeSection={activeSection} />
        </>
      ) : (
        <NoContent
          variant="grey"
          title="Something went wrong"
          text="Sections have not been added"
        />
      )}
    </ContentSection>
  );
};
