export type InactiveLoansSortingTypes = {
  name?: string | null;
  status?: string | null;
  createdAt?: string | null;
  lastUpdateAt?: string | null;
  fullName?: string | null;
  processors?: unknown[] | null;
};

export enum LOAN_STATUS {
  CLOSED = 'Closed',
  ARCHIVED = 'Archived',
}
