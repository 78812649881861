import React from 'react';
import { Typography } from '@mui/material';
import {
  ExtendedScenario,
  Scenario,
  ScenarioComponent,
} from '../../ScenarioRentalCalculation.types';

interface RowCellProps {
  isCalculating?: boolean;
  scenario: ExtendedScenario;
  dataIndex: string;
  className: string;
  disabled?: boolean;
  Component?: ScenarioComponent;
}

export const RowCell = ({
  scenario,
  dataIndex,
  Component,
  className,
  disabled,
}: RowCellProps) => {
  return (
    <>
      {Component && (
        <div key={dataIndex} className={className}>
          <Component scenario={scenario} disabled={disabled} />
        </div>
      )}
      {!Component && (
        <div key={dataIndex} className={className}>
          <Typography>{scenario[dataIndex as keyof Scenario]}</Typography>
        </div>
      )}
    </>
  );
};
