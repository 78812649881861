import { StatusConfig } from '@typings/common';
import { STATUS_CONFIG } from '@config/status';

export enum SEND_LOAN_TYPE {
  YES = 'yes',
  NO = 'no',
}

export enum RADIO_GROUP_NAME {
  SEND_LOAN = 'sendLoan',
}

export const SEND_LOAN_OPTIONS = [
  {
    label: 'Yes',
    value: SEND_LOAN_TYPE.YES,
  },
  {
    label: 'No',
    value: SEND_LOAN_TYPE.NO,
  },
];

export enum SEND_QUOTE_TASKS {
  INITIAL = 'initial_loan_quote.approval',
  FINAL = 'final_loan_quote.approval',
}

export enum SEND_QUOTE_STATUS_TYPE {
  APPROVED = 'Approved',
  NOT_SEND = 'Not sent',
  SENT = 'Sent',
}

export const SEND_QUOTE_STATUS_CONFIG: StatusConfig<SEND_QUOTE_STATUS_TYPE> = {
  [SEND_QUOTE_STATUS_TYPE.NOT_SEND]: STATUS_CONFIG.INFO,
  [SEND_QUOTE_STATUS_TYPE.SENT]: {
    ...STATUS_CONFIG.WARNING_FILLED,
    label: 'Quote sent',
  },
  [SEND_QUOTE_STATUS_TYPE.APPROVED]: {
    ...STATUS_CONFIG.SUCCESS_FILLED,
    label: 'Quote approved',
  },
};
