import { useForm, useFormContext } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import set from 'lodash/set';
import { FormData } from '@form-configs/types';
import { useStatusToastsHandlers } from '@hooks/useStatusToastsHandlers';
import { AnyError } from '@utils/errors.utils';
import { FIELD_NAMES } from '@pages/personalLoans/pages/buildMyLoan/pages/product/components/LicensingForm/licensingForm.constants';
import { useUpdateBuildMyLoanCache } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateBuildMyLoanCache';
import { useUpdateLoan } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateLoan';

export const getDefaultFormValues = (values: FormData) =>
  FIELD_NAMES.reduce((result: Record<string, string>, field: string) => {
    const value = get(values, `loan.${field}`);
    set(result, field, value || '');
    return result;
  }, {});

/* ATTENTION: This hook should be used only in components which have direct access to loan for context (not quote) */
export const useLicenseForm = () => {
  const { loanId = '' } = useParams();
  const { updateLoanCache } = useUpdateBuildMyLoanCache();
  const loanMethods = useFormContext();
  const licenseMethods = useForm<FormData>({
    defaultValues: getDefaultFormValues(loanMethods.getValues()),
  });
  const updateLoan = useUpdateLoan();
  const { errorHandler } = useStatusToastsHandlers();

  const saveLicense = useCallback(async () => {
    const licenseValues = licenseMethods.getValues();

    try {
      await updateLoan(loanId, licenseValues);
      updateLoanCache(licenseValues);
    } catch (err) {
      errorHandler(err as AnyError, 'License is not updated.');
    }

    Object.entries(licenseValues).forEach(([key, value]) => {
      loanMethods.setValue(`loan.${key}`, value);
    });

    licenseMethods.reset(licenseValues);
  }, [loanMethods, licenseMethods]);

  return useMemo(
    () => ({
      licenseMethods,
      saveLicense,
    }),
    [licenseMethods, saveLicense]
  );
};
