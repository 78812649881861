import { useEffect, useMemo, useState } from 'react';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { Quote, QUOTE_TYPE } from '@typings/common';
import { useQuotesSaveApi } from '@hooks/useQuotesApi/useQuotesSaveApi';
import { useQuoteGetApi } from '@hooks/useQuotesApi/useQuoteGetApi';
import {
  getQuoteSummary,
  QuoteSummary,
} from '@hooks/useQuotesApi/utils/getQuoteSummary.utils';
import { SUMMARY_ITEM_KEYS } from '@pages/scenarioBuilder/pages/quoteForm/QuoteForm.types';

export const useQuotesCalculateAndSave = (
  type: QUOTE_TYPE,
  quoteId?: string
) => {
  const [save, { data: savedData, isLoading: isCalculating }] =
    useQuotesSaveApi(type, quoteId);
  const [loadById, { data, isLoading, isFetching, isError }] =
    useQuoteGetApi(type);
  const [quoteSummary, setQuoteSummary] = useState<QuoteSummary>();
  const [memoizedData, setMemoizedData] = useState<Quote>();

  useEffect(() => {
    if (data) {
      setMemoizedData(data);
    }
  }, [data]);

  useEffect(() => {
    if (savedData) {
      setMemoizedData(savedData);
    }
  }, [savedData]);

  useEffect(() => {
    if (data) {
      const summaryData = getQuoteSummary(data as Quote, type);
      setQuoteSummary(summaryData);
    }
  }, [data, type]);

  useEffect(() => {
    if (savedData) {
      const summaryData = getQuoteSummary(savedData as Quote, type);
      setQuoteSummary(summaryData);
    }
  }, [savedData, type]);

  useEffect(() => {
    if (quoteId) {
      loadById({ quoteId });
    }
  }, [savedData, quoteId, loadById]);

  const summary = useMemo(() => {
    const warningData = quoteSummary
      ? omit(quoteSummary, SUMMARY_ITEM_KEYS.CALCULATION_ERROR)
      : {};

    const validationWarningData = quoteSummary
      ? pick(quoteSummary, SUMMARY_ITEM_KEYS.VALIDATION_WARNING)
      : {};

    const LLPAData = quoteSummary
      ? pick(
          quoteSummary,
          SUMMARY_ITEM_KEYS.APPLIED_RATE_LLPAS_FACTORED,
          SUMMARY_ITEM_KEYS.FEE_LLPAS_FACTORED
        )
      : {};

    return {
      details: quoteSummary,
      warnings: Object.values(warningData),
      validationWarnings: Object.values(validationWarningData),
      LLPAs: Object.values(LLPAData),
      errors: quoteSummary?.[SUMMARY_ITEM_KEYS.CALCULATION_ERROR]?.items ?? [],
    };
  }, [quoteSummary]);

  const isCalculateInProgress = useMemo(() => {
    return isCalculating || isFetching || isLoading;
  }, [isCalculating, isFetching, isLoading]);

  return {
    data: memoizedData,
    save,
    isSaving: isCalculateInProgress,
    isQuoteLoading: isLoading || isFetching,
    isError,
    loadById,
    summary,
  };
};
