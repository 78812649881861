import { LolaBffApiContractsModelsTenantTenant } from '@api/output/api';
import { extendPaletteOptions } from './palette';

export const convertTheme = (
  themeConfig: LolaBffApiContractsModelsTenantTenant
) => {
  if (themeConfig) {
    return {
      palette: extendPaletteOptions({
        custom: themeConfig.themeData.palette,
      }),
      config: {
        companyShortName: themeConfig.companyShortName,
        companyLongName: themeConfig.companyLongName,
        images: {
          logoLogin: themeConfig.themeData.images.logoLogin,
          logoInternal: themeConfig.themeData.images.logoInternal,
        },
      },
    };
  }
};
