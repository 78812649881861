import React from 'react';
import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import { ModalFooter } from '@components/Menu';

interface ConfirmModalProps {
  isUpdating: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
}

export const ConfirmModal = ({
  isUpdating,
  handleSubmit,
  handleCancel,
}: ConfirmModalProps) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      {isUpdating ? (
        <LinearProgress data-cy="linear-progress" />
      ) : (
        <Typography>
          Are you sure you want to apply the changes you made?
        </Typography>
      )}
    </Grid>
    <Grid item xs={12}>
      <ModalFooter>
        <Button variant="text" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isUpdating}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Grid>
  </Grid>
);
