import React, { ReactNode } from 'react';
// MUI
import { Grid, Paper } from '@mui/material';

interface CloseMyLoanContainerProps {
  children: ReactNode;
  className?: string;
}

export const CloseMyLoanContainer = ({
  children,
  className,
}: CloseMyLoanContainerProps) => {
  return (
    <Grid container spacing={2} className={className}>
      <Grid item xs={12}>
        <Paper elevation={2}>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
