import React, { useCallback, useMemo } from 'react';
// MUI
import { Link } from '@mui/material';
import { TableColumnType } from 'antd';
// API
import { DownloadIcon } from '@lola/ui-react-components';
import { LolaBffApiContractsModelsDocumentLockerDocumentInfo } from '@api/output/api';
// Icons
// Utils
import { downloadFile } from '@utils/file.utils';
// Hooks
import { useDownloadUrl } from '@pages/personalLoans/hooks/useDownloadUrl';
// Styles
import styles from './useDownloadAction.module.scss';

export const useDownloadAction =
  (): TableColumnType<LolaBffApiContractsModelsDocumentLockerDocumentInfo> => {
    const getDocumentUrl = useDownloadUrl();

    const downloadHandler = useCallback(
      async (downloadId: string, fileName: string) => {
        const url = await getDocumentUrl(downloadId);
        if (url) {
          downloadFile(fileName, url);
        }
      },
      []
    );

    const actionsRenderer = useCallback(
      (
        _: unknown,
        row: LolaBffApiContractsModelsDocumentLockerDocumentInfo
      ) => {
        const { fileName, downloadId } = row;

        if (!downloadId || !fileName) {
          return null;
        }

        return (
          <Link
            underline="none"
            key={fileName}
            className={styles.documentLink}
            onClick={() => downloadHandler(downloadId, fileName)}
            download={fileName}
          >
            <DownloadIcon width={24} height={24} />
          </Link>
        );
      },
      []
    );

    return useMemo(
      () => ({
        key: 'actions',
        render: actionsRenderer,
        width: '4%',
        title: '',
      }),
      [actionsRenderer]
    );
  };
