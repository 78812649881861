import React, { useMemo } from 'react';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { GridHelperText } from '@components/GridHelperText/GridHelperText.component';
import { SelectManager } from '@pages/teamManagement/components/SelectManager/SelectManager.component';
import styles from './formWithTeamSelection.module.scss';

interface FormWithTeamSelectionProps {
  fullName: string;
  teams: string[];
}

export const FormWithTeamSelection = ({
  fullName,
  teams,
}: FormWithTeamSelectionProps) => {
  const options = useMemo(() => {
    return teams.map((teamId) => ({
      label: `Team ${teamId}`,
      value: teamId || -1,
    }));
  }, [teams]);

  return (
    <LabeledCard label={`Remove ${fullName} from the Team`}>
      <SelectControl
        name={'removeFromTeamId'}
        label={`Select the team from which you want to remove ${fullName}`}
        options={options}
        required="Team is required"
      />
      <div className={styles.managerSelector}>
        <SelectManager
          label={`Select manager who will lead the team instead of ${fullName}`}
          required="Manager is required"
        />
      </div>
      <GridHelperText helperText="You can’t keep a team without a manager." />
    </LabeledCard>
  );
};
