import React from 'react';
import { Typography } from '@mui/material';
import styles from './quickLoanInfo.module.scss';

interface QuickLoanInfoProps {
  name?: string | null;
  addressLine?: string | null;
  loanProgramType?: string | null;
  loanNumber?: string | null;
}

export const QuickLoanInfo = ({
  name,
  addressLine,
  loanProgramType,
  loanNumber,
}: QuickLoanInfoProps) => (
  <>
    <Typography variant="h3" classes={{ root: styles.title }}>
      {addressLine}
    </Typography>
    <Typography
      variant="pDescription"
      component="p"
      classes={{ root: styles.description }}
    >
      {name} • {loanProgramType} # {loanNumber}
    </Typography>
  </>
);
