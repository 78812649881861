import { SelectProps } from '@mui/material';
import { ValidationRule as ValidationRuleHookForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { FileUploaderControlProps } from '@components/FileUploader';
import { CustomRegisterOptions } from '@components/DynamicForm/utils/yupToControllerRules';
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type FormData = any;

export type PrimitiveValue = string | number | boolean | undefined;
export type PossibleFieldValue = Record<string, PrimitiveValue> | string;

export type FormDataSchema = ObjectSchema<FormData>;

export type ControlType =
  | 'empty'
  | 'list'
  | 'divider'
  | 'text'
  | 'number'
  | 'collapse'
  | 'block'
  | 'dropdown'
  | 'card'
  | 'checkboxGroup'
  | 'radioGroup'
  | 'checkbox'
  | 'custom'
  | 'datePicker'
  | 'typography'
  | 'uploader';

export enum Condition {
  AND = 'AND',
  OR = 'OR',
}

export enum ConditionOperation {
  EQUAL = '==',
  NOT_EQUAL = '!=',
  EXIST = '~',
}

export interface ValidationRule {
  rule: string;
  args?: PrimitiveValue[];
}

export type ConditionConfig = [ConditionOperation, string, PrimitiveValue];

export type ConditionMap = Record<Condition, ConditionConfig[]>;

export interface FieldConfig<T = Record<string, FormData>> {
  id: string;
  controlType: ControlType;
  props?: T;
  items?: FieldConfig[];
}

export interface FieldsConfigWithPropsAndItems extends FieldConfig {
  props: Record<string, FormData>;
  items: FieldConfig[];
}

export interface CSVJsonField {
  key?: string;
  label?: string;
  apiName?: string;
  controlType?: string;
  specialOptions?: string;
  controlUiParams?: Record<string, PrimitiveValue>;
  validation?: Record<string, PrimitiveValue>;
}

export type CSVKey = keyof CSVJsonField;

/* Component props */

export interface CollapseProps {
  label: string;
}

export interface CardProps {
  label: string;
  tooltip?: string;
}

export interface BlockProps {
  col?: number[];
  helperText?: string;
  condition?: ConditionMap;
  rowSpacing?: number;
  columnSpacing?: number;
  hidden?: boolean;
}

export interface DefaultInputProps {
  locked?: boolean;
  label?: string;
  description?: string;
  name: string;
  validation?: ValidationRule[];
  variant?: SelectProps['variant'];
  required?: string | boolean;
  optionalHelperText?: string;
  helperText?: string;
  defaultValue?: string | number | boolean;
  sync?: string;
  controlType?: ControlType;
}

export interface DynamicCheckboxProps extends DefaultInputProps {
  useYesNo?: boolean;
}

export interface TextFieldProps extends DefaultInputProps {
  placeholder?: string;
  mask?: string;
  formula?: string;
  tooltip?: string;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  locked?: boolean;
  max?: number;
}

export interface DropdownOption {
  label: string;
  value: string | number;
}

export interface DropdownProps extends DefaultInputProps {
  optionsKey?: string;
  options?: DropdownOption[];
  optionsParams?: string[];
  multiple?: string;
  placeholder?: string;
}

export interface DatePickerProps {
  label: string;
  name: string;
  required?: string | ValidationRuleHookForm<boolean>;
  optionalHelperText?: string;
  locked?: boolean;
  sync?: string;
  validation?: ValidationRule[];
  rules?: CustomRegisterOptions;
  helperText?: string;
  error?: boolean;
}

export interface GroupProps {
  label: string;
  options?: DropdownOption[];
  required?: boolean;
  isLabelTruncated?: boolean;
}

export interface RadioGroupProps extends GroupProps {
  name: string;
}

export interface ListProps {
  addFirstButtonText?: string;
  firstTitle?: string;
  autoTitlePattern: string;
  firstIsRequired: boolean;
  addButtonText: string;
  label: string;
  name: string;
  startFrom?: number;
  withSpacing?: boolean;
  withDivider?: boolean;
}

export interface TypographyProps {
  label: string;
  controlType?: ControlType;
}

export type DynamicUploaderProps = Omit<
  FileUploaderControlProps,
  | 'onUploadedFilesChange'
  | 'onClickIfFileExists'
  | 'onClickIfFileDoesntExist'
  | 'buttonRendererIfFileExists'
  | 'buttonRendererIfFileDoesntExist'
>;
