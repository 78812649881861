import React, { DragEvent, PropsWithChildren, SyntheticEvent } from 'react';
import { OnUploadType } from '../../Uploader.types';

import styles from './dropZone.module.scss';

interface DropZoneProps {
  onUpload: OnUploadType;
  disabled?: boolean;
  maxFiles?: number;
}

export const DropZone = ({
  onUpload,
  children,
  disabled,
  maxFiles,
}: PropsWithChildren<DropZoneProps>) => {
  const handleDrop = (e: DragEvent) => {
    const files = e.dataTransfer.files;

    if (disabled || !files) return;

    if (maxFiles && files.length > maxFiles) {
      return;
    }

    onUpload(
      Array.from(e.dataTransfer.files).map((file) => ({
        file,
        fileName: file.name,
      }))
    );

    e.preventDefault();
  };

  const handleDragOver = (e: SyntheticEvent) => {
    e.preventDefault();
  };

  return (
    <div
      className={styles.dropZone}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {children}
    </div>
  );
};
