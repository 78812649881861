import React from 'react';

//MUI
import { Grid, List, ListItem, Paper, Typography } from '@mui/material';
// Utils
import { formatAmountToCurrency } from '@utils/currency.utils';
//Types
import { LolaBffApiContractsModelsLoanLoanModel } from '@api/output/api';
//Styles
import styles from './detailsListArchive.module.scss';

export interface DetailsListProps {
  data: LolaBffApiContractsModelsLoanLoanModel;
  listTitle: string;
}

export const DetailsListArchive = ({ data, listTitle }: DetailsListProps) => {
  return (
    <Paper elevation={0} variant="outlined" className={styles.detailList}>
      <List>
        <ListItem className={styles.detailListItem}>
          <Typography variant="h4" className={styles.listTitle}>
            {listTitle}
          </Typography>
        </ListItem>
        <Grid container>
          <Grid item xs={6}>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">{'Loan amount'}</Typography>
              <Typography variant="pBold">
                ${formatAmountToCurrency(data?.totalLoanAmount) ?? ''}
              </Typography>
            </ListItem>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">{'Interest rate'}</Typography>
              <Typography variant="pBold">
                {data?.totalRatePercent ?? ''}%
              </Typography>
            </ListItem>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">{'LTV'}</Typography>
              <Typography variant="pBold"></Typography>
            </ListItem>

            <ListItem className={styles.detailListItem}>
              <Typography variant="p">{'Price'}</Typography>
              <Typography variant="pBold"></Typography>
            </ListItem>
          </Grid>
          <Grid item xs={6}>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">{'Loan type'}</Typography>
              <Typography variant="pBold">
                {data?.loanProgramType ?? ''}
              </Typography>
            </ListItem>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">{'Purpose'}</Typography>
              <Typography variant="pBold">
                {data?.loanPurposeType ?? ''}
              </Typography>
            </ListItem>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">{'Loan term'}</Typography>
              <Typography variant="pBold">
                {data?.loanTermPeriodMonthCount ?? ''}
              </Typography>
            </ListItem>
            <ListItem className={styles.detailListItem}>
              <Typography variant="p">{'Loan ID #'}</Typography>
              <Typography variant="pBold">
                {data?.loanIdentifier ?? '-'}
              </Typography>
            </ListItem>
          </Grid>
        </Grid>
      </List>
    </Paper>
  );
};
