import { useMemo, useState, useCallback } from 'react';
// Router
import { useParams } from 'react-router-dom';
// types
import { QUOTE_TYPE, LOAN_PROGRAM } from '@typings/common';
import { CalculatePayloadValuesWithFees } from '@hooks/useQuotesApi/utils/feeUtils.types';
import { FormData } from '@form-configs/types';
// API
import { useGetApiV1LoansByLoanIdQuery } from '@api/output/api';
// Hooks
import { useQuotesCalculateAndSave } from '@hooks/useQuotesApi/useQuotesCalculateAndSave';
// utils
import { handlerQuoteId } from '../utils/handlerQuoteId';

interface FinalPricingAndTermsSheetProps {
  scrollView?: () => void;
}

export function useFinalPricingAndTermsSheet({
  scrollView,
}: FinalPricingAndTermsSheetProps) {
  const { loanId = '' } = useParams();

  // states
  const [isCollapse, setIsCollapse] = useState({
    term: true,
    pricing: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  // actions states
  const openPricing = () => {
    scrollView?.();
    setIsCollapse({
      term: false,
      pricing: true,
    });
  };

  const closePricing = () => {
    setIsCollapse({
      term: true,
      pricing: false,
    });
  };

  const handledCollapse = ({ key }: { key: 'term' | 'pricing' }) =>
    setIsCollapse((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

  const handledOpenModal = () => setIsModalOpen((prev) => !prev);

  // API
  const { data: dataLoans, isLoading: isLoadingLoans } =
    useGetApiV1LoansByLoanIdQuery({
      loanId: loanId,
    });

  const quoteType: QUOTE_TYPE = useMemo(
    () =>
      dataLoans?.loanProgramType === LOAN_PROGRAM.RENTAL_30
        ? QUOTE_TYPE.RENTAL
        : QUOTE_TYPE.SHORT_TERM,
    [dataLoans]
  );

  // data
  const isQuoteId = handlerQuoteId({
    dataId: dataLoans?.quote?.aggregations?.quoteId,
  });

  // hooks
  const {
    save: saveBuilder,
    data: termSheet,
    loadById,
    isQuoteLoading,
    isSaving: isCalculating,
  } = useQuotesCalculateAndSave(quoteType, isQuoteId ?? '');

  // data
  const isLoading = isLoadingLoans || isQuoteLoading;

  // actions
  const save = useCallback(
    (data: FormData) => {
      saveBuilder(data as CalculatePayloadValuesWithFees).then(() => {
        loadById({ quoteId: isQuoteId ?? '' });
        scrollView?.();
        closePricing();
      });
    },
    [saveBuilder]
  );

  const states = {
    isLoading,
    termSheet,
    isCollapse,
    isQuoteId,
    quoteType,
    isCalculating,
    isModalOpen,
  };

  const actions = {
    openPricing,
    handledCollapse,
    save,
    handledOpenModal,
  };

  return {
    ...states,
    ...actions,
  };
}
