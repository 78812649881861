import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { StatusConfig } from '@typings/common';
import { STATUS_CONFIG } from '@config/status';

export interface StatusChipProps {
  defaultChip?: ChipProps;
  config: StatusConfig<string>;
  status?: string | null;
}

export const StatusChip = ({
  status,
  config,
  defaultChip = STATUS_CONFIG.INFO,
}: StatusChipProps) => {
  const chipConfig = config[status as string] ?? defaultChip;

  return <Chip label={status} {...chipConfig} />;
};
