import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import {
  Typography,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
} from '@mui/material';
import { Collapsed } from '@components/Collapsed';
import { formatAmountToCurrency } from '@utils/currency.utils';
import { useParties } from '@pages/personalLoans/pages/buildMyLoan/hooks/useParties';
import { MemoizedOwnershipRow } from './components/OwnershipRow/OwnershipRow.component';

import styles from './entityOwnership.module.scss';

export const EntityOwnership = () => {
  const [entity = {}] = useWatch({
    name: ['party.entity'],
  });

  const { borrower, guarantors, parties } = useParties();

  const totalAmount = useMemo(
    () =>
      parties.reduce((acc, current) => {
        const apiValue = parseFloat(current?.liquidAssetTotalAmount ?? '0');
        const value = isNaN(apiValue) ? 0 : apiValue;
        return acc + value;
      }, 0),
    [parties]
  );

  const labelPostfix = useMemo(() => {
    const { displayName } = entity;
    return displayName ? `(${displayName})` : '';
  }, [entity.displayName]);

  const isValid = useMemo(() => {
    return entity?.id && borrower?.id;
  }, [entity, borrower]);

  return (
    <Collapsed label={`Entity ownership ${labelPostfix}`} defaultExpanded>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>
            This plate represents the structure of your entity.
            <br /> Enter new guarantors in the field above to be displayed in
            this table.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isValid ? (
            <TableContainer
              component={Paper}
              elevation={0}
              classes={{ root: styles.table }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell classes={{ root: styles.nameColumn }}>
                      <Typography variant="pDescription">Name</Typography>
                    </TableCell>
                    <TableCell classes={{ root: styles.titleColumn }}>
                      <Typography variant="pDescription">Title</Typography>
                    </TableCell>
                    <TableCell classes={{ root: styles.ownershipColumn }}>
                      <Typography variant="pDescription">
                        Ownership %
                      </Typography>
                    </TableCell>
                    <TableCell classes={{ root: styles.assetsColumn }}>
                      <Typography variant="pDescription">
                        Liquid assets*
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <MemoizedOwnershipRow
                    party={borrower}
                    path="party.borrower"
                    entityId={entity.id}
                  />
                  {guarantors?.map((guarantor, index) => {
                    return (
                      <MemoizedOwnershipRow
                        key={guarantor.id}
                        entityId={entity.id}
                        party={guarantor}
                        path={`party.guarantors[${index}]`}
                      />
                    );
                  })}
                  <TableRow>
                    <TableCell
                      classes={{ root: styles.totalCount }}
                      colSpan={3}
                    >
                      <Typography
                        classes={{ root: styles.totalCountInfo }}
                        variant="pDescription"
                      >
                        *cash, stocks, bonds, 401k, etc.
                      </Typography>
                    </TableCell>
                    <TableCell classes={{ root: styles.totalCountSum }}>
                      <Typography variant="p">Total: </Typography>
                      <Typography variant="pBold">
                        ${formatAmountToCurrency(totalAmount)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert severity="warning">
              You should add primary guarantor and entity before updating the
              structure of entity. This is required section.
            </Alert>
          )}
        </Grid>
      </Grid>
    </Collapsed>
  );
};
