import React from 'react';
//MUI
import { Grid, Paper, Typography, Link } from '@mui/material';
//Component
import {
  StatusInProgressIcon,
  InProcessIcon,
  BuildingIcon,
  LocationIcon,
  PhoneIcon,
} from '@lola/ui-react-components';
import { NoContent } from '@components/NoContent';
//Icons
//Types
import { LolaBffApiContractsModelsCloseMyLoanScheduleInformation } from '../../../../../../../../../packages/api/output/api';
//Styles
import styles from './invitationToClosing.module.scss';

export interface InvitationToClosingProps {
  meetingData?: LolaBffApiContractsModelsCloseMyLoanScheduleInformation | null;
  title?: string | null;
}

export const InvitationToClosing = ({
  meetingData,
  title,
}: InvitationToClosingProps) => {
  return (
    <Grid className={styles.invitationToClosing}>
      <Typography variant="h3" className={styles.title}>
        {title ?? 'Invitation to the closing meeting'}
      </Typography>
      <Paper elevation={0}>
        {meetingData ? (
          <Grid className={styles.info}>
            <div className={styles.infoItem}>
              <InProcessIcon />
              <Typography variant="pBold">{meetingData.date ?? ''}</Typography>
            </div>
            <div className={styles.infoItem}>
              <BuildingIcon />
              <Typography variant="pBold">
                {meetingData.organizationName ?? ''}
              </Typography>
            </div>
            <Link
              href={`http://maps.google.com/maps?q=${meetingData.address?.replace(
                /\s+/g,
                '+'
              )}`}
              underline="none"
              target="_blank"
              className={styles.infoItem}
            >
              <LocationIcon />
              <Typography variant="pBold">
                {meetingData.address ?? ''}
              </Typography>
            </Link>
            <Link
              href={`tel:${meetingData.phone}`}
              underline="none"
              className={styles.infoItem}
            >
              <PhoneIcon />
              <Typography variant="pBold">{meetingData.phone ?? ''}</Typography>
            </Link>
          </Grid>
        ) : (
          <NoContent
            variant="grey"
            icon={<StatusInProgressIcon />}
            title="No data to show here yet"
            text="We are working on setting up time with your title company"
          />
        )}
      </Paper>
    </Grid>
  );
};
