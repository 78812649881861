import { Button, Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { AddIcon } from '@lola/ui-react-components';
import { useRole } from '@hooks/useRole';
import { addToastWithAutodelete, ToastType } from '@store/slices/toast.slice';
import { getCorrectedError } from '@utils/error.utils';
import { useLoadUsers } from '@hooks/useLoadUsers';
import { useInviteUserCheck } from '@pages/teamManagement/components/InviteDrawer/components/InviteForm/hooks/useInviteUserCheck';
import { useInviteFormSubmit } from '@pages/teamManagement/components/InviteDrawer/components/InviteForm/hooks/useInviteFormSubmit';
import { InviteTeam } from './components/InviteTeam/InviteTeam.component';
import { InviteDefaultPart } from './components/InviteDefaultPart/InviteDefaultPart.component';
import { InviteRole } from './components/InviteRole/InviteRole.component';
import { createInviteFormSchema } from './inviteForm.schema';

import styles from './inviteForm.module.scss';

export interface InviteFormProps {
  onClose: () => void;
}

export const InviteForm = ({ onClose }: InviteFormProps) => {
  const currentRole = useRole();
  const { users = [] } = useLoadUsers({});

  const methods = useForm({
    resolver: createInviteFormSchema(currentRole, users),
    mode: 'onBlur',
  });

  const [firstName, lastName, email] = methods.watch([
    'firstName',
    'lastName',
    'email',
  ]);

  const { isUserInvited, userId, invitedUserStatus } = useInviteUserCheck(
    email,
    users
  );
  const { handleSubmit, isSuccess, isSending, isError, error } =
    useInviteFormSubmit(userId);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess) {
      const name = `${firstName} ${lastName}`;
      dispatch(
        addToastWithAutodelete({
          type: ToastType.Success,
          title: 'Invitation sent',
          description: `Invitation has been sent to ${name}.`,
        }) as unknown as AnyAction
      );

      onClose();
    }

    if (isError) {
      const { title, message } = getCorrectedError(error);
      dispatch(
        addToastWithAutodelete({
          type: ToastType.Error,
          title: title,
          description: message,
        }) as unknown as AnyAction
      );
    }
  }, [isSuccess, isError]);

  const onFormSubmit = useMemo(() => {
    return methods.handleSubmit(handleSubmit);
  }, [methods.handleSubmit, handleSubmit]);

  if (isSending) {
    return <LinearProgress />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit}>
        <Grid container spacing={2} className={styles.content}>
          <Grid item xs={12}>
            <InviteDefaultPart
              isUserInvited={isUserInvited}
              invitedUserStatus={invitedUserStatus}
            />
          </Grid>
          <Grid item xs={12}>
            <InviteRole role={currentRole} isLocked={isUserInvited} />
          </Grid>
          <Grid item xs={12}>
            <InviteTeam role={currentRole} />
          </Grid>
        </Grid>
        <footer className={styles.footer}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            type="submit"
            size="large"
            disabled={
              !methods.formState.isDirty || invitedUserStatus === 'Active'
            }
          >
            {isUserInvited ? 'Resend invitation' : 'Invite member'}
          </Button>
        </footer>
      </form>
    </FormProvider>
  );
};
