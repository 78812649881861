import React from 'react';
import { useWatch } from 'react-hook-form';
import { ProgressStepper } from '@components/ProgressStepper/ProgressStepper.component';
import { ProgressIndicatorType } from '@components/ProgressStepper/types';
import { NotificationWithConfirmation } from '@components/NotificationWithConfirmation/NotificationWithConfirmation.component';
import { isLoanDisabled } from '@pages/personalLoans/pages/buildMyLoan/utils/loan.utils';
import styles from './buildMyLoanColumnStepper.module.scss';
import { useBuildMyLoansControlPanelSteps } from './hooks/useBuildMyLoansControlPanelSteps';

export const BuildMyLoanColumnStepper = () => {
  const milestone = useWatch({ name: 'loan.dealMetadata.milestone' });
  const loanDisabledState = isLoanDisabled(milestone);
  const steps = useBuildMyLoansControlPanelSteps();

  return (
    <ProgressStepper
      progressIndicatorType={ProgressIndicatorType.LINEAR}
      items={steps}
      headerTitle="Build my Loan"
      tooltip="This section includes loans under consideration but have not yet been submitted."
      iconPosition="afterTitle"
      notification={
        loanDisabledState && (
          <NotificationWithConfirmation
            keyName="BuildMyLoanControlPanelNotification"
            expiresIn={10}
            severity="warning"
            title="Build my loan is completed"
            text="You cannot make changes to the &#34;Build my loan&#34; section."
            className={styles.notification}
          />
        )
      }
    />
  );
};
