import React from 'react';
import { Grid } from '@mui/material';
import { WidgetConfig } from '../../dashboard.constants';

export interface DynamicWidgetLayoutProps {
  items?: WidgetConfig[];
}

export const DynamicWidgetLayout = ({
  items = [],
}: DynamicWidgetLayoutProps) => {
  return (
    <>
      {items.map((config) => {
        const { Component, children, id, ...gridProps } = config;

        if (children) {
          return (
            <Grid item key={id} {...gridProps}>
              <Grid container spacing={4}>
                <DynamicWidgetLayout items={children} />
              </Grid>
            </Grid>
          );
        }

        if (Component) {
          return (
            <Grid key={id} item {...gridProps}>
              <Component />
            </Grid>
          );
        }

        return null;
      })}
    </>
  );
};
