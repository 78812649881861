import React from 'react';
import { Chip, Stack } from '@mui/material';
import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';
import styles from './processorListRenderer.module.scss';

export const processorListRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsLoanLoanView
) => {
  const { processors } = row;

  if (!processors) return null;

  return (
    <Stack
      direction={{ xs: 'row' }}
      sx={{ alignItems: 'baseline' }}
      spacing={{ xs: 1 }}
    >
      {processors?.length === 0 ? (
        <Chip color="info" size="small" label="N/A" variant="standard" />
      ) : (
        <>
          <Chip
            color="info"
            size="small"
            label={processors[0].processorName}
            variant="standard"
          />
          {processors?.length > 1 && (
            <div className={styles.remainingElements}>
              <Chip
                color="info"
                size="small"
                label={`+${processors?.length - 1}`}
                variant="standard"
                className={styles.remainingElementsCount}
              />
              <div className={styles.remainingElementsList}>
                <ul>
                  {processors.slice(1).map(({ processorName }) => (
                    <li key={1}>{processorName}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
    </Stack>
  );
};
