import { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  id: string;
}

export const Portal = ({ id, children }: PropsWithChildren<PortalProps>) => {
  const [domElement, setDomElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const el = document.getElementById(id) as HTMLDivElement;
    if (el) {
      setDomElement(el);
    }
  }, []);

  if (!domElement) {
    return null;
  }

  return createPortal(children, domElement);
};
