import { useMemo } from 'react';
import { UPLOAD_STATUS } from '@constants/uploader.constants';
import { PORTFOLIO_EXCEL_UPLOADER_ALLOWED_EXTENSIONS } from '@pages/personalLoans/pages/buildMyLoan/pages/application/components/PortfolioExcelUpload/PortfolioExcelUpload.constants';
import { checkFileExtension } from '@utils/file.utils';
import {
  LolaBffApiContractsModelsLoanLoanProperty,
  LolaBffApiContractsModelsLoanTaskInfo,
} from '@api/output/api';

export const useIsPdfUploadAllowed = (
  task?: LolaBffApiContractsModelsLoanTaskInfo,
  properties?: LolaBffApiContractsModelsLoanLoanProperty[]
): boolean => {
  return <boolean>useMemo(() => {
    const statusIsApproved = task?.taskStatus === UPLOAD_STATUS.APPROVED;
    const excelFileUploaded = task?.documents?.some((doc) =>
      checkFileExtension(
        doc.fileName,
        PORTFOLIO_EXCEL_UPLOADER_ALLOWED_EXTENSIONS
      )
    );

    return (
      statusIsApproved &&
      excelFileUploaded &&
      properties &&
      properties.length > 1
    );
  }, [task]);
};
