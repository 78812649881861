import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

export const calculate = (fields: string[], operations: string[]) => {
  if (!fields.length || !operations.length) {
    return undefined;
  }

  const values = [...fields];
  const ops = [...operations];
  let res = parseInt(values.shift() || '0');

  while (values.length) {
    const newValue = values.shift() || 0;
    const currentOps = ops.shift() === '+' ? 1 : -1;
    const signValue = newValue ? +newValue * currentOps : 0;
    res += signValue;
  }

  return res;
};

export const useFormulaValue = (formula?: string) => {
  const { watch } = useFormContext();

  const [keys, ops] = useMemo(() => {
    const variables = formula?.split(/[+-]/) || [];
    const words = new RegExp(variables.join('|'), 'g');
    const operations = formula?.replace(words, '').split('') || [];
    return [variables, operations];
  }, [formula]);

  const fields: string[] = watch(keys);

  return useMemo(() => calculate(fields, ops), [fields, ops]);
};
