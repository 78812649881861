import React, { useContext, useMemo } from 'react';
import { FieldConfig } from '@form-configs/types';
import { FieldProp, FieldsPropsContext } from './contexts/FieldsProps.context';
import { FormConfigContext } from './contexts/FormConfig.context';
import {
  ComponentsContext,
  ComponentsMap,
  DEFAULT_COMPONENTS_MAP,
} from './contexts/Components.context';
import { DynamicItems } from './components/DynamicItems/DynamicItems.component';

export interface DynamicFieldsProps {
  fields: FieldConfig[];
  fieldsProps?: Record<string, FieldProp[]>;
  components?: ComponentsMap;
  disabled?: boolean;
}

export const DynamicFields = ({
  fields,
  fieldsProps = {},
  disabled = false,
  components = {},
}: DynamicFieldsProps) => {
  const nearestComponentsMap = useContext(ComponentsContext);
  const defaultConfig = useContext(FormConfigContext);
  const defaultFieldPropsConfig = useContext(FieldsPropsContext);

  const fullComponents = useMemo(
    () => ({
      ...DEFAULT_COMPONENTS_MAP,
      ...nearestComponentsMap,
      ...components,
    }),
    [components]
  );

  const formConfig = useMemo(() => {
    const isDisabled = defaultConfig.disabled || disabled;

    return {
      ...defaultConfig,
      disabled: isDisabled,
    };
  }, [defaultConfig, disabled]);

  const fieldPropsValue = useMemo(() => {
    return {
      ...defaultFieldPropsConfig,
      ...fieldsProps,
    };
  }, [defaultFieldPropsConfig, fieldsProps]);

  return (
    <FormConfigContext.Provider value={formConfig}>
      <ComponentsContext.Provider value={fullComponents}>
        <FieldsPropsContext.Provider value={fieldPropsValue}>
          <DynamicItems fields={fields} />
        </FieldsPropsContext.Provider>
      </ComponentsContext.Provider>
    </FormConfigContext.Provider>
  );
};
