import React, {
  memo,
  useCallback,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';
import classnames from 'classnames';
import { Button, Typography } from '@mui/material';
import { NotificationProps } from '@components/Notification/Notification.component';
import { UPLOAD_STATUS, UPLOAD_TYPE } from '@constants/uploader.constants';
import { getFilesToUpload } from '@components/Uploader/utils/files.utils';
import { UploaderHeader } from './components/UploaderHeader/UploaderHeader.component';
import { UploaderButton } from './components/UploaderButton/UploaderButton.component';
import { FileList } from './components/FileList/FileList.component';
import {
  FileDeleteHandler,
  FileItem,
  OnUploadType,
  UploaderConfig,
} from './Uploader.types';
import { DEFAULT_UPLOADER_CONFIG, UPLOADER_STATE } from './Uploader.constants';
import { DropZone } from './components/DropZone/DropZone.component';
import { checkFile, formAcceptedFiles, showDropZone } from './Uploader.utils';

import styles from './uploader.module.scss';

export interface UploaderProps {
  state: UPLOADER_STATE;
  files?: FileItem[];
  onUpload?: OnUploadType;
  onDelete?: FileDeleteHandler;
  uploaderConfig?: UploaderConfig;
  className?: string;
  notification?: NotificationProps;
  status?: UPLOAD_STATUS;
  comments?: string | null;
  setUploaderSate?: (arg: UPLOADER_STATE) => void;
  type?: UPLOAD_TYPE;
  submitHandler?: () => Promise<void>;
  submitDisabled?: boolean;
  submitButtonText?: string;
}

export const Uploader = memo(
  ({
    state,
    files = [],
    onUpload,
    onDelete,
    uploaderConfig = DEFAULT_UPLOADER_CONFIG,
    className = '',
    notification,
    status = UPLOAD_STATUS.NOT_UPLOADED,
    comments,
    setUploaderSate,
    type,
    submitHandler,
    submitDisabled,
    submitButtonText,
    children,
  }: PropsWithChildren<UploaderProps>) => {
    const [processingFiles, setProcessingFiles] = useState<FileItem[]>([]);
    const { iconConfig, textConfig, buttonLabelConfig, description, rules } =
      uploaderConfig;
    const isLimitReached = useMemo(
      () =>
        uploaderConfig.rules?.maxFiles
          ? files?.length >= uploaderConfig.rules?.maxFiles
          : false,
      [uploaderConfig, files]
    );
    const isSubmitShown = useMemo(
      () =>
        type === UPLOAD_TYPE.CONDITIONAL &&
        [UPLOAD_STATUS.NOT_UPLOADED, UPLOAD_STATUS.UPLOADED].includes(status),
      [type, status]
    );

    const isDropZoneShown = useMemo(() => showDropZone(status), [status]);

    const isUploadDisabled = useMemo(() => {
      if (status === UPLOAD_STATUS.DENIED) {
        return false;
      }
      return state === UPLOADER_STATE.LOADING ? true : isLimitReached;
    }, [state, status, isLimitReached]);

    const acceptedFileTypes = useMemo(
      () => formAcceptedFiles(rules?.allowedExtensions?.rule),
      []
    );

    const uploadFiles = useCallback(
      async (newFiles: FileItem[]) => {
        if (newFiles && onUpload) {
          const filesToUpload = getFilesToUpload(newFiles, files);
          setProcessingFiles(filesToUpload);
          await onUpload([
            ...filesToUpload.map((fileItem) => {
              return {
                ...fileItem,
                metadata: {
                  error: checkFile(fileItem.file, rules) ?? '',
                },
              };
            }),
          ]);
          setProcessingFiles([]);
        }
      },
      [onUpload, files, setProcessingFiles]
    );

    const deleteFile = useCallback(
      async (file?: FileItem) => {
        if (state === UPLOADER_STATE.LOADING || !file || !onDelete) return;
        setProcessingFiles([file]);
        await onDelete(file);
        setProcessingFiles([]);
      },
      [state, onDelete, setProcessingFiles]
    );

    const onSubmitHandler = useCallback(async () => {
      if (submitHandler && files) {
        setProcessingFiles(files);
        await submitHandler();
        setProcessingFiles([]);
      }
    }, [files, submitHandler, setProcessingFiles]);

    return (
      <>
        <div
          className={classnames({
            [styles.uploader]: true,
            [className]: className,
          })}
        >
          {isDropZoneShown && (
            <DropZone
              onUpload={uploadFiles}
              disabled={isUploadDisabled}
              maxFiles={uploaderConfig.rules?.maxFiles}
            >
              <UploaderHeader
                state={state}
                iconConfig={iconConfig}
                textConfig={textConfig}
              />
              {buttonLabelConfig && !isLimitReached && (
                <UploaderButton
                  state={state}
                  labelConfig={buttonLabelConfig}
                  onClick={uploadFiles}
                  maxFiles={uploaderConfig.rules?.maxFiles}
                  accept={acceptedFileTypes}
                  disabled={isUploadDisabled}
                />
              )}
              {description && !isLimitReached && (
                <Typography variant="pDescription">{description}</Typography>
              )}
            </DropZone>
          )}
          <FileList
            files={files}
            state={state}
            onDelete={deleteFile}
            processingFiles={processingFiles}
            fileDelete={uploaderConfig.fileDelete}
            notification={notification}
            status={status}
            comments={comments}
            setUploaderSate={setUploaderSate}
          />
          {children}
        </div>
        {isSubmitShown && (
          <Button
            className={styles.submitBtn}
            variant="contained"
            onClick={onSubmitHandler}
            disabled={submitDisabled}
          >
            {submitButtonText}
          </Button>
        )}
      </>
    );
  }
);
