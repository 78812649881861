import React from 'react';
import { Grid, Paper } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { ProgressStepper } from '@components/ProgressStepper/ProgressStepper.component';
import { PROFILE_PERSONAL_INFO_FOOTER_ID, STEPS } from './Profile.constants';

import styles from './profile.module.scss';

export const ProfilePage = () => {
  const { pathname } = useLocation();

  return (
    <section className={styles.section}>
      <Grid container spacing={4} padding={4}>
        <Grid item xs={3}>
          <Paper elevation={0} className={styles.stepper}>
            <ProgressStepper
              items={STEPS}
              headerTitle="Profile page"
              activeItem={pathname}
            />
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Outlet />
        </Grid>
      </Grid>
      <footer id={PROFILE_PERSONAL_INFO_FOOTER_ID} />
    </section>
  );
};
