import React from 'react';
//MUI
import { List, ListItem, Paper, Typography } from '@mui/material';
//Types
import { LolaBffApiContractsModelsLoanLoanModel } from '@api/output/api';
// Utils
import { formatAmountToCurrency } from '@utils/currency.utils';
//Styles
import styles from './detailsList.module.scss';

export interface DetailsListProps {
  data: LolaBffApiContractsModelsLoanLoanModel;
  listTitle: string;
}

export const DetailsList = ({ data, listTitle }: DetailsListProps) => {
  return (
    <Paper elevation={0} variant="outlined" className={styles.detailList}>
      <List>
        <ListItem className={styles.detailListItem}>
          <Typography variant="h4" className={styles.listTitle}>
            {listTitle}
          </Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'Loan type'}</Typography>
          <Typography variant="pBold">{data?.loanProgramType ?? ''}</Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'Loan purpose'}</Typography>
          <Typography variant="pBold">{data?.loanPurposeType ?? ''}</Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'Loan amount'}</Typography>
          <Typography variant="pBold">
            ${formatAmountToCurrency(data?.totalLoanAmount) ?? ''}
          </Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'Rate'}</Typography>
          <Typography variant="pBold">
            {data?.totalRatePercent ?? '-'}%
          </Typography>
        </ListItem>
      </List>
    </Paper>
  );
};
