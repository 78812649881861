import React from 'react';
import {
  DoneIcon,
  StatusAlertIcon,
  UploadIcon,
} from '@lola/ui-react-components';
import { UploaderConfig } from './Uploader.types';

export enum UPLOADER_STATE {
  INIT = 'init',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  DISABLED = 'disabled',
}

export const DEFAULT_UPLOADER_CONFIG: UploaderConfig = {
  fileDelete: true,
  iconConfig: {
    [UPLOADER_STATE.INIT]: <UploadIcon />,
    [UPLOADER_STATE.LOADING]: <UploadIcon />,
    [UPLOADER_STATE.SUCCESS]: <DoneIcon />,
    [UPLOADER_STATE.ERROR]: <StatusAlertIcon />,
  },
  textConfig: {
    [UPLOADER_STATE.INIT]: 'Drop your documents here or',
    [UPLOADER_STATE.DISABLED]: 'Drop your documents here or',
    [UPLOADER_STATE.LOADING]: 'Processing...',
    [UPLOADER_STATE.SUCCESS]: 'File operation completed',
    [UPLOADER_STATE.ERROR]:
      'Error occurred while processing files. Please try again.',
  },
  buttonLabelConfig: {
    [UPLOADER_STATE.INIT]: 'Choose files to upload',
    [UPLOADER_STATE.DISABLED]: 'Choose files to upload',
    [UPLOADER_STATE.SUCCESS]: 'Add more files to upload',
  },
  description: 'Accepted file types: PDF, DOCX',
  rules: {
    allowedExtensions: {
      rule: ['docx', 'pdf'],
      message: 'Wrong file extension',
    },
    maxSize: {
      rule: 20000000,
      message: 'The file size is too large. Please try again.',
    },
  },
};
