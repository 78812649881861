import React, { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Grid } from '@mui/material';
import { Option } from '@typings/common';
import { RadioGroupControl } from '@components/RadioGroup';
import { LabeledCardCollapsed } from '@components/LabeledCardCollapsed/LabeledCardCollapsed.component';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { useAvailableProcessors } from '@containers/ManageAccessDrawer/components/Processors/hooks';
import { MANAGE_ACCESS_FORM } from '../../../../ManageAccessDrawer.constants';
import { RemoveNotification } from '../index';
import { ACTION_TYPE, OPTIONS } from './ActionCard.constants';

import styles from './actionCard.module.scss';

interface ActionCardProps {
  label: string;
  description: string;
  index: number;
  processors: Option[];
}

export const ActionCard = ({
  label,
  description,
  index,
  processors,
}: ActionCardProps) => {
  const { resetField } = useFormContext();
  const [currentProcessor = {}, newProcessorId = '', selectedProcessors = []] =
    useWatch({
      name: [
        `${MANAGE_ACCESS_FORM.PROCESSORS}[${index}]`,
        MANAGE_ACCESS_FORM.NEW_ASSIGNED_PROCESSOR_ID,
        MANAGE_ACCESS_FORM.PROCESSORS,
      ],
    });

  const availableOptions = useAvailableProcessors({
    processors,
    selectedProcessors,
    currentProcessor: currentProcessor[MANAGE_ACCESS_FORM.NEW_PROCESSOR_ID],
  });

  const options = useMemo(() => {
    return availableOptions.filter(({ value }) => value !== newProcessorId);
  }, [availableOptions, newProcessorId]);

  const action = useMemo(
    () => currentProcessor[MANAGE_ACCESS_FORM.ACTION],
    [currentProcessor]
  );
  const selectName = useMemo(
    () =>
      `${MANAGE_ACCESS_FORM.PROCESSORS}[${index}].${MANAGE_ACCESS_FORM.NEW_PROCESSOR_ID}`,
    [index]
  );

  useEffect(() => {
    if (action === ACTION_TYPE.REMOVE) {
      resetField(selectName);
    }
  }, [action]);

  return (
    <LabeledCardCollapsed label={label} description={description}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RadioGroupControl
            name={`${MANAGE_ACCESS_FORM.PROCESSORS}[${index}].${MANAGE_ACCESS_FORM.ACTION}`}
            options={OPTIONS}
            className={styles.radioGroup}
          />
        </Grid>
        {action === ACTION_TYPE.ADD && (
          <Grid item xs={12}>
            <SelectControl
              name={`${MANAGE_ACCESS_FORM.PROCESSORS}[${index}].${MANAGE_ACCESS_FORM.NEW_PROCESSOR_ID}`}
              options={options}
              label="Select a processor whom you would like to assign this loan to"
            />
          </Grid>
        )}
        {action === ACTION_TYPE.REMOVE && (
          <Grid item xs={12}>
            <RemoveNotification />
          </Grid>
        )}
      </Grid>
    </LabeledCardCollapsed>
  );
};
