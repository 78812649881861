import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { ROLES } from '@typings/common';
import { CreateTeamForm } from '../../../../../CreateTeamForm/CreateTeamForm.component';

export interface CreateTeamProps {
  role: ROLES;
}

export const CreateTeam = ({ role }: CreateTeamProps) => {
  const { watch } = useFormContext();

  const [selectedRole, selectedFirstName = '', selectedLastName = ''] = watch([
    'role',
    'firstName',
    'lastName',
  ]);

  const textAppendix = useMemo(() => {
    const isManager =
      selectedRole === ROLES.KEY_BROKER_MANAGER ||
      selectedRole === ROLES.BROKER_MANAGER;

    const hasName = selectedFirstName || selectedLastName;

    if (isManager && hasName) {
      return (
        <Typography variant="p">
          <Typography variant="pBold">
            {selectedFirstName} {selectedLastName}
          </Typography>{' '}
          will be manager of the new team.
        </Typography>
      );
    }
  }, [selectedRole, selectedFirstName, selectedLastName]);

  return (
    <CreateTeamForm
      role={role}
      textAppendix={textAppendix}
      selectVariant="standard"
      showManagerSelect={
        role === ROLES.KEY_BROKER_MANAGER && selectedRole === ROLES.BROKER
      }
    />
  );
};
