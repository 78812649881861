import { get } from 'react-hook-form';
import { FieldConfig, FormData } from '@form-configs/types';

export const replaceFieldByKey = (
  fields: FieldConfig[],
  key: string,
  value: string,
  newField: FieldConfig
) => {
  fields.forEach((field, index) => {
    const fieldValue = get(field, key);
    if (fieldValue === value) {
      fields[index] = newField;
    } else if (field.items) {
      replaceFieldByKey(field.items, key, value, newField);
    }
  });
};

export const deleteFieldByKey = (
  fields: FieldConfig[],
  key: string,
  value: string
) => {
  for (let i = 0; i < fields.length; i++) {
    const fieldValue = get(fields[i], key);
    if (fieldValue === value) {
      fields.splice(i, 1);
    }

    if (fields[i].items) {
      deleteFieldByKey(fields[i].items ?? [], key, value);
    }
  }
};

export const getNearestId = (obj: FormData, path: string): string[] => {
  const paths = path.split('.');
  paths.pop();

  while (paths.length > 0) {
    const newPath = `${paths.join('.')}.id`;
    const id = get(obj, newPath);
    if (id) {
      return [id, newPath];
    }

    paths.pop();
  }

  return [];
};

export const getPartyFieldPath = (path: string) => {
  return path
    .replace('party.borrower.', '')
    .replace('party.entity.', '')
    .replace(/party\.guarantors\[\d*]\./, '');
};
