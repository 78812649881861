import React, { useCallback, useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { CardSection } from '@lola/ui-react-components';
import { RadioGroup } from '@components/RadioGroup';
import { BorrowerInformation } from '@pages/personalLoans/pages/buildMyLoan/pages/applicationAndEntityInformation/components/BorrowerInformation/BorrowerInformation.component';
import { LOAN_PDF_APPLICATION_TYPE } from '@typings/common';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { Notification } from '@components/Notification/Notification.component';
import { useParties } from '../../hooks/useParties';
import { Authorization } from '../../components/Authorization/Authorization.component';
import {
  FILL_OPTION_VALUE,
  FILL_OPTIONS,
} from './applicationAndEntityInformation.constants';
import { FullApplication, UploadBorrowerApplication } from './components';
import { useBorrowerDocumentsExist } from './hooks/useBorrowerDocumentsExist';

export const ApplicationAndEntityInformationPage = () => {
  const { disabled } = useContext(LoanDisableContext);
  const { parties, borrower, guarantors } = useParties();

  const [flow, setFlow] = useState(FILL_OPTION_VALUE.MANUALLY);
  const borrowerDocumentsExist = useBorrowerDocumentsExist(borrower?.id);

  const changeHandler = useCallback(
    (_: unknown, value: string) => {
      setFlow(value as FILL_OPTION_VALUE);
    },
    [setFlow]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardSection
          title="Application & Entity information"
          description="Fill out the application manually or upload a filled PDF. When filling out the application manually, you will need to fill in information about the borrower and guarantors, as well as fill out the full application."
          data-cy="application-and-entity-information"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RadioGroup
                label="Do you want to fill it manually or upload?"
                options={FILL_OPTIONS}
                row
                major
                value={flow}
                onChange={changeHandler}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              {flow === FILL_OPTION_VALUE.MANUALLY && <BorrowerInformation />}
              {flow === FILL_OPTION_VALUE.UPLOAD && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Notification
                      severity="info"
                      withIcon
                      title="Documents upload"
                      text="It takes up to 48h to review your file. When uploading your portfolios, please keep in mind that you need to upload the Borrower's application first and after that the Guarantor's application."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <UploadBorrowerApplication
                      applicationType={
                        LOAN_PDF_APPLICATION_TYPE.BORROWER_GUARANTOR_APP
                      }
                      existedEntityId={borrower?.id}
                      title="Borrower Application"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <UploadBorrowerApplication
                      applicationType={LOAN_PDF_APPLICATION_TYPE.GUARANTOR_APP}
                      existedEntityId={guarantors?.[0]?.id}
                      title="Guarantor Application"
                      disabled={!borrowerDocumentsExist}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardSection>
      </Grid>
      {flow === FILL_OPTION_VALUE.MANUALLY && (
        <Grid item xs={12}>
          <FullApplication />
        </Grid>
      )}
      <Grid item xs={12}>
        <Authorization parties={parties} />
      </Grid>
    </Grid>
  );
};
