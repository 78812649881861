import { TableColumnType } from 'antd';
import {
  createDateSorter,
  createDefaultSorter,
  dateRenderer,
  createTruncatedTextRenderer,
} from '@lola/ui-react-components';
import { LolaBffApiContractsModelsDashboardActionsLoanActionDto } from '@api/output/api';
import { ROLES } from '@typings/common';
import {
  DEFAULT_UPLOADER_CONFIG,
  UPLOADER_STATE,
} from '@components/Uploader/Uploader.constants';
import { DEFAULT_UPLOADER_DRAWER_CONFIG } from '@components/UploadDrawer/uploaderDrawer.constants';
import { teamRenderer } from '@pages/dashboard/components/ActionsForTodayWidget/renderers/team';
import { loanTitleRenderer } from '@utils/renderers/loanTitleRenderer/loanTitleRenderer';
import { stageRenderer } from '@utils/renderers/stageRenderer/stageRenderer';

export type TodoColumn =
  TableColumnType<LolaBffApiContractsModelsDashboardActionsLoanActionDto>;

export const loanNameColumn: TodoColumn = {
  key: 'addressLine',
  dataIndex: 'addressLine',
  title: 'Loan Name',
  width: '35%',
  render: loanTitleRenderer,
  sorter:
    createDefaultSorter<LolaBffApiContractsModelsDashboardActionsLoanActionDto>(
      'addressLine'
    ),
};

export const loanStageColumn: TodoColumn = {
  key: 'step',
  dataIndex: 'step',
  title: 'Step',
  width: '23%',
  render: stageRenderer,
  sorter:
    createDefaultSorter<LolaBffApiContractsModelsDashboardActionsLoanActionDto>(
      'step'
    ),
};

export const loanDateColumn: TodoColumn = {
  key: 'createdAt',
  dataIndex: 'createdAt',
  title: 'Created',
  width: '13%',
  render: dateRenderer,
  sorter: createDateSorter('createdAt'),
};

export const loanTeamColumn: TodoColumn = {
  key: 'brokerTeam',
  dataIndex: 'brokerTeam',
  sorter:
    createDefaultSorter<LolaBffApiContractsModelsDashboardActionsLoanActionDto>(
      'brokerTeam'
    ),
  render: teamRenderer,
  title: 'Team',
  width: '18%',
};

export const loanActionsColumn: TodoColumn = {
  key: 'actions',
  title: 'Actions',
  width: '35%',
  sorter:
    createDefaultSorter<LolaBffApiContractsModelsDashboardActionsLoanActionDto>(
      'displayedTaskName'
    ),
};

export const loanBrokerColumn: TodoColumn = {
  key: 'brokerName',
  dataIndex: 'brokerName',
  title: 'Broker',
  width: '15%',
  sorter:
    createDefaultSorter<LolaBffApiContractsModelsDashboardActionsLoanActionDto>(
      'brokerName'
    ),
  render: createTruncatedTextRenderer('brokerName'),
};

export const brokerColumns = [
  loanNameColumn,
  loanStageColumn,
  loanDateColumn,
  loanActionsColumn,
];

export const processorColumns = [
  loanNameColumn,
  loanStageColumn,
  loanBrokerColumn,
  loanTeamColumn,
  loanDateColumn,
  loanActionsColumn,
];

export const ACTION_CONFIG_INDEX = {
  [ROLES.BROKER]: brokerColumns.indexOf(loanActionsColumn),
  [ROLES.PROCESSOR]: processorColumns.indexOf(loanActionsColumn),
  [ROLES.NOT_SET]: -1,
  [ROLES.BROKER_MANAGER]: brokerColumns.indexOf(loanActionsColumn),
  [ROLES.KEY_BROKER_MANAGER]: brokerColumns.indexOf(loanActionsColumn),
};

export const COLUMN_CONFIG: Record<ROLES, TodoColumn[]> = {
  [ROLES.BROKER]: brokerColumns,
  [ROLES.PROCESSOR]: processorColumns,
  [ROLES.NOT_SET]: [],
  [ROLES.BROKER_MANAGER]: brokerColumns,
  [ROLES.KEY_BROKER_MANAGER]: brokerColumns,
};

export const UPLOADER_CONFIG = {
  ...DEFAULT_UPLOADER_CONFIG,
  textConfig: {
    ...DEFAULT_UPLOADER_CONFIG.textConfig,
    [UPLOADER_STATE.INIT]: 'Drop your document here or',
  },
  buttonLabelConfig: {
    ...DEFAULT_UPLOADER_CONFIG.buttonLabelConfig,
    [UPLOADER_STATE.INIT]: 'Choose file to upload',
    [UPLOADER_STATE.SUCCESS]: '',
  },
  description: 'PDF less than 20mb',
  rules: {
    ...DEFAULT_UPLOADER_CONFIG.rules,
    allowedExtensions: {
      rule: ['pdf'],
      message: 'Wrong file extension',
    },
    maxFiles: 1,
  },
};

export const DRAWER_CONFIG = {
  ...DEFAULT_UPLOADER_DRAWER_CONFIG,
  headerTitle: 'Upload document',
  description: null,
};
