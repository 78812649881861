import React, { PropsWithChildren, ReactNode } from 'react';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import classnames from 'classnames';
import { InformationIcon } from '@lola/ui-react-components';
import styles from './labeledCard.module.scss';

export interface LabeledCardProps {
  label?: string | ReactNode;
  className?: string;
  disabled?: boolean;
  tooltip?: string;
}

export const LabeledCard = ({
  label,
  children,
  disabled,
  className,
  tooltip,
}: PropsWithChildren<LabeledCardProps>) => {
  return (
    <Paper variant="filled" className={className}>
      {label && (
        <Typography
          variant="h3"
          className={classnames({
            [styles.label]: !!children,
            [styles.disabled]: disabled,
          })}
        >
          {label}
          {tooltip && (
            <Tooltip title={tooltip}>
              <IconButton className={styles.iconWrapper}>
                <InformationIcon className={styles.infoIcon} />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      {children}
    </Paper>
  );
};
