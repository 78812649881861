import { useFormContext } from 'react-hook-form';
import React, { useCallback, useMemo } from 'react';
import { NumberStepInput } from '@components/NumberStepInput/NumberStepInput.component';
import { checkApproveMyLoanStep } from '@utils/loanMilestone.util';
import { ScenarioProps } from '../../ScenarioRentalCalculation.types';

export const LoanToValue = ({ scenario, disabled }: ScenarioProps) => {
  const { index, loanMilestone = '' } = scenario;
  const { setValue, watch } = useFormContext();

  const name = `scenario[${index}].ltvPercentage`;
  const value = watch(name);

  const onChange = useCallback((value: number) => {
    setValue(name, value, { shouldDirty: true });
  }, []);

  const isDisabled = useMemo(
    () => disabled || (checkApproveMyLoanStep(loanMilestone) && index === 0),
    [disabled, loanMilestone]
  );

  return (
    <NumberStepInput
      value={value}
      minimum={0}
      maximum={100}
      maskSymbol={'%'}
      onChange={onChange}
      disabled={isDisabled}
    />
  );
};
