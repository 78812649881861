export type DataLayerRecord = Record<string, string | number | boolean>;

export interface WindowLayered extends Window {
  dataLayer?: DataLayerRecord[];
}

/* Temporary solution with storing tag IDs */
export const getGTMId = () => {
  const isProduction = window.location.host.split('.').length === 3;
  return isProduction ? 'GTM-WT86N62Z' : 'GTM-P7ZDV5FV';
};

export const initGTM = (id: string) => {
  const customWindow = window as unknown as WindowLayered;

  if (customWindow.dataLayer) {
    return;
  }

  customWindow.dataLayer = [
    {
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    },
  ];

  const firstScriptTag = document.getElementsByTagName('script')[0];

  if (!firstScriptTag.parentElement) {
    return;
  }
  const newElement = document.createElement('script');

  newElement.async = true;
  newElement.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;

  firstScriptTag.parentElement.insertBefore(newElement, firstScriptTag);

  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
  iframe.style.display = 'none';

  noscript.appendChild(iframe);

  document.body.insertBefore(iframe, document.body.children[0]);
};
