import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Paper, Typography } from '@mui/material';
import { RowRecord } from '@lola/ui-react-components';
import { RadioGroup } from '@components/RadioGroup';
import { ListOfBorrowers } from '@containers/ListOfBorrowers/ListOfBorrowers.component';
import { FIELDS } from '../../CreateLoan.constants';
import styles from './borrowerPicker.module.scss';

const options = [
  {
    value: 'Build new borrower',
    label: 'Build new borrower',
    description: 'Make underwriting easier',
  },
  {
    value: 'Choose from existing borrower',
    label: 'Choose from existing borrower',
    description: 'Choose the product and start the process',
  },
];

export const BorrowerPicker = () => {
  const [borrowerPickerVariant, setBorrowerPickerVariant] =
    useState<string>('');
  const [selectedExistingBorrower, setSelectedExistingBorrower] =
    useState<RowRecord | null>(null);
  const { setValue } = useFormContext();

  useEffect(() => {
    if (borrowerPickerVariant === options[0].value) {
      setValue(FIELDS.BORROWER, null);
    } else if (selectedExistingBorrower) {
      setValue(FIELDS.BORROWER, selectedExistingBorrower.id);
    } else {
      setValue(FIELDS.BORROWER, '');
      setValue(FIELDS.LOAN_TYPE, '');
      setValue(FIELDS.USER_ID, '');
    }
  }, [borrowerPickerVariant, selectedExistingBorrower, setValue]);

  const handleExistingBorrowerChange = (selectedBorrower: RowRecord) => {
    setSelectedExistingBorrower(selectedBorrower);
  };

  const handlePickerVariantChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value === options[0].value ? options[0].value : null;
    setBorrowerPickerVariant(e.target.value);
    setValue(FIELDS.BORROWER, value);
  };

  return (
    <div>
      <RadioGroup
        name="borrowerPickerVariant"
        ariaLabel="Build new borrower"
        options={options}
        value={borrowerPickerVariant}
        onChange={handlePickerVariantChange}
      />
      {borrowerPickerVariant === options[1].value && (
        <>
          {selectedExistingBorrower ? (
            <div className={styles.selectedBorrowerBar}>
              <Typography
                variant="pBold"
                className={styles.selectedBorrowerTitle}
              >
                {selectedExistingBorrower?.displayName as string}
              </Typography>
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setSelectedExistingBorrower(null);
                  setValue(FIELDS.BORROWER, null);
                }}
              >
                Change
              </Button>
            </div>
          ) : (
            <Paper elevation={0} className={styles.tableWrapper}>
              <header className={styles.titleHeader}>
                <Typography variant="h4">
                  LIST OF BORROWERS / GUARANTORS
                </Typography>
              </header>
              <ListOfBorrowers onChange={handleExistingBorrowerChange} />
            </Paper>
          )}
        </>
      )}
    </div>
  );
};
