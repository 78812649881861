import { FieldConfig } from '@form-configs/types';
import { THIRD_PARTY_BROKER_FIELDS } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';

export const FORM_FIELDS: FieldConfig[] = [
  {
    id: 'third-party-broker-first-name',
    controlType: 'text',
    props: {
      label: 'First Name',
      name: THIRD_PARTY_BROKER_FIELDS.FIRST_NAME,
      validation: [
        {
          rule: 'required',
        },
      ],
      required: true,
    },
  },
  {
    id: 'third-party-broker-last-name',
    controlType: 'text',
    props: {
      label: 'Last Name',
      name: THIRD_PARTY_BROKER_FIELDS.LAST_NAME,
      validation: [
        {
          rule: 'required',
        },
      ],
      required: true,
    },
  },
  {
    id: 'third-party-broker-nmlsid',
    controlType: 'text',
    props: {
      label: 'NMLS ID',
      mask: [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
      ],
      name: THIRD_PARTY_BROKER_FIELDS.NMLS,
      validation: [
        {
          rule: 'required',
        },
      ],
      required: true,
    },
  },
  {
    id: 'third-party-broker-datepicker',
    controlType: 'datePicker',
    props: {
      label: 'Expiration Date',
      minDate: new Date(),
      name: THIRD_PARTY_BROKER_FIELDS.EXPIRATION,
      required: false,
    },
  },
];

export const FIELD_NAMES = FORM_FIELDS.map((field) => field.props?.name).filter(
  Boolean
);

export const LOAN_FIELDS_NAMES = FIELD_NAMES.map((field) => `loan.${field}`);

export const FORM_FIELDS_CONFIG: FieldConfig[] = [
  {
    id: 'third-party-broker',
    controlType: 'block',
    props: {
      col: [6, 6],
    },
    items: FORM_FIELDS,
  },
];

export const FORM_FIELDS_CONFIG_OPTIONAL = [
  {
    ...FORM_FIELDS_CONFIG[0],
    items: FORM_FIELDS.map((field) => ({
      ...field,
      props: {
        ...field.props,
        required: false,
        validation: [],
      },
    })),
  },
];
