import React from 'react';
// MUI
import { Grid, Button, LinearProgress } from '@mui/material';
// global components
import { Modal } from '@components/Modal';
import styles from './ModalFinalUnderwriting.module.scss';

interface ModalFinalUnderwritingProps {
  startFinalUnderwriting: boolean;
  closeModal: () => void;
  handledInitFinal: () => void;
  isLoadingFinal: boolean;
}

export const ModalFinalUnderwriting = ({
  startFinalUnderwriting,
  closeModal,
  handledInitFinal,
  isLoadingFinal,
}: ModalFinalUnderwritingProps) => {
  return (
    <Modal
      isOpen={startFinalUnderwriting}
      onClose={closeModal}
      width={640}
      title="Start final underwriting"
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <p>
            Once you proceed to final underwriting, you will not be able to push
            changes to tasks. Please make sure all tasks are properly solved.
          </p>
          {isLoadingFinal && <LinearProgress />}
        </Grid>
        <Grid item xs={12} spacing={6}>
          <Grid container justifyContent="flex-end">
            <Button
              variant="text"
              className={styles.cancelFinalUnderGrating}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handledInitFinal}
              disabled={isLoadingFinal}
            >
              Start final underwriting
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
