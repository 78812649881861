import { LOAN_PROGRAM } from '@typings/common';

export enum RADIO_GROUP_NAME {
  APPLICATION_TYPE = 'applicationType',
  PROPERTY_DETAILS_FLOW = 'propertyDetailsFlow',
}

export enum APPLICATION_TYPE {
  SINGLE = 'single',
  PORTFOLIO = 'portfolio',
}

export const APPLICATION_TYPE_OPTIONS = [
  {
    label: 'Single property',
    value: APPLICATION_TYPE.SINGLE,
  },
  {
    label: 'Portfolio',
    value: APPLICATION_TYPE.PORTFOLIO,
  },
];

export const FORM_NAME_MAP: Record<APPLICATION_TYPE, Record<string, string>> = {
  [APPLICATION_TYPE.SINGLE]: {
    [LOAN_PROGRAM.FIX_N_FlIP]: 'singleFixNFlipAndBp',
    [LOAN_PROGRAM.BRIDGE_PLUS]: 'singleFixNFlipAndBp',
    [LOAN_PROGRAM.NEW_CONSTRUCTION]: 'singleNewConstruction',
    [LOAN_PROGRAM.RENTAL_30]: 'singleRental30',
    [LOAN_PROGRAM.RENTAL_30_PREMIERE]: 'singleRental30',
  },
  [APPLICATION_TYPE.PORTFOLIO]: {
    [LOAN_PROGRAM.FIX_N_FlIP]: 'portfolioRental30',
    [LOAN_PROGRAM.BRIDGE_PLUS]: 'portfolioRental30',
    [LOAN_PROGRAM.NEW_CONSTRUCTION]: 'portfolioRental30',
    [LOAN_PROGRAM.RENTAL_30]: 'portfolioRental30',
    [LOAN_PROGRAM.RENTAL_30_PREMIERE]: 'portfolioRental30',
  },
};

export const QUESTIONNAIRE_FORM_NAME_MAP: Record<
  APPLICATION_TYPE,
  Record<string, string>
> = {
  [APPLICATION_TYPE.SINGLE]: {
    [LOAN_PROGRAM.FIX_N_FlIP]: 'questionFixNFlipAndBp',
    [LOAN_PROGRAM.BRIDGE_PLUS]: 'questionFixNFlipAndBp',
    [LOAN_PROGRAM.NEW_CONSTRUCTION]: 'questionNewConstruction',
    [LOAN_PROGRAM.RENTAL_30]: 'questionSingleRental30',
    [LOAN_PROGRAM.RENTAL_30_PREMIERE]: 'questionSingleRental30',
  },
  [APPLICATION_TYPE.PORTFOLIO]: {
    [LOAN_PROGRAM.FIX_N_FlIP]: 'questionFixNFlipAndBp',
    [LOAN_PROGRAM.BRIDGE_PLUS]: 'questionFixNFlipAndBp',
    [LOAN_PROGRAM.NEW_CONSTRUCTION]: 'questionNewConstruction',
    [LOAN_PROGRAM.RENTAL_30]: 'questionPortfolioRental30',
    [LOAN_PROGRAM.RENTAL_30_PREMIERE]: 'questionPortfolioRental3',
  },
};
