import React from 'react';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import { ChevronDownIcon } from '@lola/ui-react-components';

import styles from './controlPanelLabel.module.scss';

interface ControlPanelLabelProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  labelPostfix?: string;
}

export const ControlPanelLabel = ({
  label,
  isActive,
  onClick,
  labelPostfix = '',
}: ControlPanelLabelProps) => {
  return (
    <div
      className={classNames(styles.button, {
        [styles.selected]: isActive || !!labelPostfix,
      })}
      onClick={onClick}
    >
      <Typography>
        {label}
        {!!labelPostfix && (
          <Typography variant="pBold" component="span">
            &nbsp;{labelPostfix}
          </Typography>
        )}
      </Typography>
      <ChevronDownIcon className={styles.buttonChevron} />
    </div>
  );
};
