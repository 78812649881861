export enum LICENSING_DRAWER_TYPE {
  UPDATE = 'Update',
  ADD = 'Add',
  CLOSE = 'Close',
}

export const LICENSING_DRAWER_TITLE: Record<LICENSING_DRAWER_TYPE, string> = {
  [LICENSING_DRAWER_TYPE.UPDATE]: 'Update license',
  [LICENSING_DRAWER_TYPE.ADD]: 'Add license',
  [LICENSING_DRAWER_TYPE.CLOSE]: '',
};
