import React, { useMemo, useRef } from 'react';
import { Button } from '@mui/material';
import { useButtonConfig } from '../../hooks/useButtonConfig';
import { UPLOADER_STATE } from '../../Uploader.constants';
import { ButtonLabelConfigType, OnUploadType } from '../../Uploader.types';
import { useUploadButtonLogic } from '../../hooks/useUploaderButtonLogic';
import styles from './uploaderButton.module.scss';

interface UploaderButtonProps {
  state: UPLOADER_STATE;
  labelConfig: ButtonLabelConfigType;
  onClick: OnUploadType;
  maxFiles?: number;
  initIcon?: JSX.Element;
  size?: number;
  accept?: string;
  disabled?: boolean;
}

export const UploaderButton = ({
  state,
  labelConfig,
  onClick,
  maxFiles,
  initIcon,
  size,
  accept = '',
  disabled,
}: UploaderButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { label, startIcon, variant } = useButtonConfig(
    state,
    labelConfig,
    initIcon
  );
  const { handleUploadChange, handleUploadClick } = useUploadButtonLogic();

  const isMultiple = useMemo(() => {
    return maxFiles === undefined || maxFiles > 1;
  }, [maxFiles]);

  return (
    <>
      <Button
        variant={variant}
        startIcon={startIcon}
        disabled={disabled}
        onClick={() => handleUploadClick(inputRef)}
        className={styles.button}
      >
        {label}
      </Button>
      <input
        type="file"
        ref={inputRef}
        multiple={isMultiple}
        onChange={(e) => handleUploadChange(e, onClick, maxFiles)}
        style={{ display: 'none' }}
        size={size}
        accept={accept}
      />
    </>
  );
};
