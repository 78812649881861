import { useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import { LolaBffApiContractsModelsLoanPartyInfo } from '@api/output/api';
import { LoanPartyInfo } from '@typings/common';

export interface UsePartiesReturnValue {
  parties: LoanPartyInfo[];
  borrower?: LoanPartyInfo;
  guarantors?: LoanPartyInfo[];
}

export const useParties = (): UsePartiesReturnValue => {
  const [borrower, guarantors]: [
    LolaBffApiContractsModelsLoanPartyInfo,
    LolaBffApiContractsModelsLoanPartyInfo[]
  ] = useWatch({
    name: ['party.borrower', 'party.guarantors'],
  });

  return useMemo(() => {
    let parties: LoanPartyInfo[] = [];
    let extendedBorrower: LoanPartyInfo | undefined;
    let extendedGuarantors: LoanPartyInfo[] | undefined;

    if (borrower?.id) {
      extendedBorrower = {
        ...borrower,
        relationRole: 'Primary guarantor',
      };
      parties.push(extendedBorrower);
    }

    if (guarantors?.length) {
      extendedGuarantors = guarantors.map((party, index) => ({
        ...party,
        relationRole: `Guarantor #${index + 1}`,
      }));

      parties = parties.concat(extendedGuarantors);
    }

    return {
      parties,
      borrower: extendedBorrower,
      guarantors: extendedGuarantors,
    };
  }, [borrower, guarantors]);
};
