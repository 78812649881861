import React, { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FileUploader } from '@components/FileUploader/FileUploader.component';
import { useOpenState } from '@hooks/useOpenState';
import { useFilesUploader } from '@components/Uploader/hooks/useFilesUploader';
import { UploadDrawer } from '@components/UploadDrawer/UploadDrawer.component';
import { useLoanDetails } from '@pages/personalLoans/pages/buildMyLoan/hooks/useLoanDetails';
import { usePDFUploader } from '@hooks/usePDFUploader';
import { useTasksByName } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTasksByName';
import { useTemplates } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTemplatesByTaskId';
import { CONFIGURED_TASKS, TASK_SECTIONS } from '@typings/common';
import { useTaskConfigurationIdByTaskKey } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTaskConfigurationIdByTaskKey';
import {
  DRAWER_CONFIG,
  UPLOADER_CONFIG,
} from './ApplicationUploader.constants';
import { getLoanPDFApplicationType } from './utils/getLoanPDFApplicationType.utils';

export const ApplicationUploader = () => {
  const taskConfigurationId = useTaskConfigurationIdByTaskKey(
    CONFIGURED_TASKS.DEAL_APPLICATION
  );
  const { templates, templatesAreBeingFetched } =
    useTemplates(taskConfigurationId);
  const loanType = useWatch({
    name: 'loan.aggregations.loanProgramType.thirdParty',
  });
  const loanPDFApplicationType = getLoanPDFApplicationType(loanType);
  const { uploadPdf: uploadOperationAsync } = usePDFUploader(
    loanPDFApplicationType
  );
  const { refetchLoanDetails } = useLoanDetails();
  const tasks = useTasksByName(
    TASK_SECTIONS.LOAN_APPLICATION,
    CONFIGURED_TASKS.DEAL_APPLICATION
  );
  const [isDrawerOpen, setDrawerOpen, setDrawerClose] = useOpenState(false);

  const applicationTask = useMemo(() => {
    return tasks?.[0];
  }, [tasks]);

  const uploadedFiles = useMemo(() => {
    return applicationTask?.task?.documents ?? [];
  }, [applicationTask]);

  const completeOperationAsync = useCallback(async () => {
    setDrawerClose();
    refetchLoanDetails();
  }, [refetchLoanDetails]);

  const uploaderProps = useFilesUploader({
    uploadedFiles,
    uploadOperationAsync,
    completeOperationAsync,
    onClose: setDrawerClose,
  });

  return (
    <>
      <FileUploader
        title="Application"
        placeholder="Optional. Upload PDF if you have it. Fields will be populated according to it"
        buttonRendererIfFileExists={() => null}
        onClickIfFileDoesntExist={setDrawerOpen}
        uploadedFiles={uploaderProps.files}
      />
      <UploadDrawer
        {...uploaderProps}
        isOpen={isDrawerOpen}
        drawerConfig={DRAWER_CONFIG}
        uploaderConfig={UPLOADER_CONFIG}
        templates={templates}
        templatesAreBeingFetched={templatesAreBeingFetched}
      />
    </>
  );
};
