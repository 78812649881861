import React from 'react';
import classnames from 'classnames';
import { Typography } from '@mui/material';
import { ItemConfig } from '@utils/quotes.utils';
import styles from './calculationBlock.module.scss';

interface CalculationBlockProps {
  title: string;
  description?: string;
  items: ItemConfig[];
}

export const CalculationBlock = ({
  title,
  description,
  items,
}: CalculationBlockProps) => {
  return (
    <div className={styles.block}>
      <Typography variant="h3">{title}</Typography>
      <div className={styles.valuesContainer}>
        {items.map((item) => (
          <div
            key={item.key}
            className={classnames(styles.valueLine, styles[item.decorations!])}
          >
            {item.customRenderer ? (
              item.customRenderer()
            ) : (
              <div
                key={item.key}
                className={classnames(
                  styles.valueLine,
                  styles[item.decorations!]
                )}
              >
                <Typography className={styles.valueDescription}>
                  {item.title}
                </Typography>
                <Typography className={styles.value}>{item.value}</Typography>
              </div>
            )}
          </div>
        ))}
      </div>
      {description && (
        <Typography
          variant="pCaption"
          component="p"
          className={styles.blockDescription}
        >
          {description}
        </Typography>
      )}
    </div>
  );
};
