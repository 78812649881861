import { useContext, useEffect } from 'react';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/query';
import { useRole } from '@hooks/useRole';
import {
  GetApiV1DashboardStatsKeyManagerPipelineApiArg,
  LolaBffApiContractsModelsDashboardLoansStatisticsLoansKeyBrokerManagerStatisticData,
  useLazyGetApiV1DashboardStatsBrokerPipelineQuery,
  useLazyGetApiV1DashboardStatsKeyManagerPipelineQuery,
  useLazyGetApiV1DashboardStatsManagerPipelineQuery,
} from '@api/output/api';
import { ROLES, TEAM_ASSIGNMENT_STATUS } from '@typings/common';
import { DynamicBaseQueryHandler } from '@api/DynamicBaseQuery';
import { useUserAssignmentStatus } from '@hooks/useUserAssignmentStatus';
import { PeriodContext } from '../contexts/Period.context';
import {
  filterOutBMEmptyTeams,
  filterOutKBMEmptyTeams,
  filterOutBrokerEmptyTeams,
} from '../Dashboard.utils';

export type Trigger = LazyQueryTrigger<
  QueryDefinition<
    GetApiV1DashboardStatsKeyManagerPipelineApiArg,
    DynamicBaseQueryHandler,
    string,
    LolaBffApiContractsModelsDashboardLoansStatisticsLoansKeyBrokerManagerStatisticData
  >
>;

export const useLoansInPipeline = () => {
  const role = useRole();
  const teamAssignmentStatus = useUserAssignmentStatus();

  const { start, end } = useContext(PeriodContext);

  const [
    loadBMRows,
    { data: dataBM, isLoading: isLoadingBM, isError: isBMError },
  ] = useLazyGetApiV1DashboardStatsManagerPipelineQuery();

  const [
    loadKBMRows,
    { data: dataKBM, isLoading: isLoadingKBM, isError: isKBMError },
  ] = useLazyGetApiV1DashboardStatsKeyManagerPipelineQuery();

  const [
    loadBrokerRows,
    { data: dataBroker, isLoading: isLoadingBroker, isError: isBrokerError },
  ] = useLazyGetApiV1DashboardStatsBrokerPipelineQuery();

  let loadRows: Trigger | undefined = undefined;

  let data = undefined;
  let isLoading = false;

  if (role === ROLES.BROKER_MANAGER) {
    loadRows = loadBMRows as Trigger;
    data = filterOutBMEmptyTeams(dataBM);
    isLoading = isLoadingBM;
  }

  if (role === ROLES.KEY_BROKER_MANAGER) {
    loadRows = loadKBMRows;
    data = filterOutKBMEmptyTeams(dataKBM);
    isLoading = isLoadingKBM;
  }

  if (
    teamAssignmentStatus === TEAM_ASSIGNMENT_STATUS.UNASSIGNED &&
    role === ROLES.BROKER
  ) {
    loadRows = loadBrokerRows as Trigger;
    data = filterOutBrokerEmptyTeams(dataBroker);
    isLoading = isLoadingBroker;
  }

  useEffect(() => {
    loadRows?.({
      dateFrom: start,
      dateTo: end,
    });
  }, [loadRows, start, end]);

  return {
    data,
    isLoading,
    isError: isBMError || isKBMError || isBrokerError,
  };
};
