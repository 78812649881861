import React from 'react';
import { StageStep, STEP_VALUES } from '@components/StageStep';

interface StageRendererRowProps {
  step?: string | null;
}

export const stageRenderer = (_: unknown, row: StageRendererRowProps) => {
  const rowStep = row.step as STEP_VALUES;

  return <StageStep rowStep={rowStep} textPosition="top" />;
};
