import React, { useMemo } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import classnames from 'classnames';
import { StagesProgress } from '@components/StagesProgress';
import { calcCompletedCount } from '@components/StagesProgress/StagesProgress.util';
import { getCreditScoreResult } from '../../../../../../CreditScore.utils';
import { SECTION_SIZES, DIVISIONS } from './CreditScoreResult.constants';

import styles from './creditScoreResult.module.scss';

interface CreditScoreResultProps {
  hasGeneratedDocument?: boolean;
  score?: number;
  onOpenModal?: () => void;
}

export const CreditScoreResult = ({
  score = 0,
  hasGeneratedDocument,
  onOpenModal,
}: CreditScoreResultProps) => {
  const creditScoreResult = useMemo(() => getCreditScoreResult(score), [score]);

  const completedCount = useMemo(
    () =>
      calcCompletedCount({
        min: DIVISIONS.MIN,
        max: DIVISIONS.MAX,
        value: score,
        sectionSizes: SECTION_SIZES,
      }),
    [score]
  );

  const scaleClasses = classnames({
    [styles.scale]: true,
    [styles[creditScoreResult]]: true,
  });

  const scoreResultClasses = classnames({
    [styles.scoreResult]: true,
    [styles[creditScoreResult] || '']: true,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={styles.header}>
        <Typography variant="p" className={styles.score}>
          {score}
        </Typography>
        <Typography variant="pDescription">
          Credit score is{' '}
          <Typography variant="pBold" className={scoreResultClasses}>
            {creditScoreResult}!
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StagesProgress
          sectionsSizes={SECTION_SIZES}
          completedCount={completedCount}
          divisions={{
            min: DIVISIONS.MIN,
            max: DIVISIONS.MAX,
          }}
          className={scaleClasses}
        />
      </Grid>
      <Grid item xs={12} className={styles.small}>
        <Typography variant="pDescription">
          *We are showing your borrowers middle score to base our credit
          decision
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={onOpenModal}
          disabled={!hasGeneratedDocument}
        >
          View credit report
        </Button>
      </Grid>
    </Grid>
  );
};
