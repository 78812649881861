import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { FileItem } from '@components/Uploader/Uploader.types';
import { UPLOADER_STATE } from '@components/Uploader/Uploader.constants';
import { UPLOAD_STATUS } from '@constants/uploader.constants';

export type UploadFileItem = FileItem & { body?: FormData };

const verifyFile = (file: FileItem) => !file.metadata?.error;

export interface UseUploaderProps {
  status?: UPLOAD_STATUS;
  uploadedFiles?: FileItem[];
  onClose?: () => void;
  uploadOperationAsync: (file: UploadFileItem) => Promise<unknown>;
  deleteOperationAsync?: (file: FileItem) => Promise<unknown>;
  completeOperationAsync?: () => Promise<void>;
  submitOperation?: () => Promise<void>;
}

export const useFilesUploader = ({
  uploadedFiles,
  onClose,
  uploadOperationAsync,
  deleteOperationAsync,
  completeOperationAsync,
  submitOperation,
}: UseUploaderProps) => {
  const [files, setFiles] = useState<FileItem[]>([]);
  const [uploaderState, setUploaderSate] = useState(UPLOADER_STATE.INIT);

  const uploadStatus = useMemo(() => {
    return uploadedFiles?.length
      ? UPLOAD_STATUS.UPLOADED
      : UPLOAD_STATUS.NOT_UPLOADED;
  }, [uploadedFiles]);

  useLayoutEffect(() => {
    if (uploadedFiles?.length) {
      setFiles(uploadedFiles);
      setUploaderSate(UPLOADER_STATE.SUCCESS);
      return;
    }
    setFiles([]);
    setUploaderSate(UPLOADER_STATE.INIT);
  }, [uploadedFiles, setFiles, setUploaderSate]);

  const closeHandler = useCallback(() => {
    if (uploaderState === UPLOADER_STATE.LOADING) return;
    if (onClose) onClose();
  }, [setFiles, setUploaderSate, onClose, uploaderState]);

  const uploadHandler = useCallback(
    async (files: FileItem[]) => {
      const existedFiles = uploadedFiles ? uploadedFiles : [];

      setFiles([...existedFiles, ...files]);

      const verifiedFiles = files?.filter((file) => verifyFile(file)) || [];

      if (!verifiedFiles.length) return;

      setUploaderSate(UPLOADER_STATE.LOADING);

      try {
        await Promise.all(
          verifiedFiles.map((file) => {
            const body = new FormData();
            body.append('file', file.file ?? '');

            return uploadOperationAsync({
              ...file,
              body,
            });
          })
        );

        if (completeOperationAsync) {
          await completeOperationAsync();
        }
        setUploaderSate(UPLOADER_STATE.SUCCESS);
      } catch (err) {
        setUploaderSate(UPLOADER_STATE.ERROR);
        setFiles((prevFiles) => {
          const filesToExclude = files.map(({ fileName }) => fileName);
          return prevFiles.filter(
            ({ fileName }) => !filesToExclude.includes(fileName)
          );
        });
      }
    },
    [
      setUploaderSate,
      uploadedFiles,
      uploadOperationAsync,
      completeOperationAsync,
      setFiles,
    ]
  );

  const deleteHandler = useCallback(
    async (file: FileItem) => {
      const prevState = uploaderState;
      const prevFiles = files;

      if (!verifyFile(file)) {
        setFiles(
          prevFiles.filter(({ fileName }) => fileName !== file.fileName)
        );
        return;
      }

      setUploaderSate(UPLOADER_STATE.LOADING);

      try {
        if (deleteOperationAsync) {
          await deleteOperationAsync(file);
        }
        if (completeOperationAsync) {
          await completeOperationAsync();
        }

        setFiles(
          prevFiles.filter(({ fileName }) => fileName !== file.fileName)
        );
        setUploaderSate(prevFiles.length > 1 ? prevState : UPLOADER_STATE.INIT);
      } catch {
        setUploaderSate(UPLOADER_STATE.ERROR);
      }
    },
    [
      uploaderState,
      files,
      setUploaderSate,
      deleteOperationAsync,
      completeOperationAsync,
    ]
  );

  const submitHandler = useCallback(async () => {
    if (submitOperation) {
      await submitOperation();
    }
  }, [submitOperation]);

  return {
    files,
    status: uploadStatus,
    uploaderState,
    setUploaderSate,
    uploadHandler,
    deleteHandler,
    closeHandler,
    submitHandler,
  };
};
