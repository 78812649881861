import { useFormContext } from 'react-hook-form';
import React, { ChangeEvent, useCallback } from 'react';
import { Checkbox } from '@mui/material';
import { ScenarioProps } from '../../ScenarioRentalCalculation.types';

export const IncludedInQuote = ({ scenario, disabled }: ScenarioProps) => {
  const { index } = scenario;

  const name = `scenario[${index}].included`;
  const { watch, setValue } = useFormContext();
  const value = watch(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.checked;
      setValue(name, value ? 'Yes' : 'No', { shouldDirty: true });
    },
    [name]
  );

  return (
    <Checkbox
      checked={value === 'Yes'}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};
