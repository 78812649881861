import { Option } from '@typings/common';

export const TITLE_OPTIONS: Option[] = [
  {
    label: 'Authorized Signatory',
    value: 'Authorized Signatory',
  },
  {
    label: 'CEO',
    value: 'CEO',
  },
  {
    label: 'CFO',
    value: 'CFO',
  },
  {
    label: 'Director',
    value: 'Director',
  },
  {
    label: 'Executor',
    value: 'Executor',
  },
  {
    label: 'General Partner',
    value: 'General Partner',
  },
  {
    label: 'Limited Partner',
    value: 'Limited Partner',
  },
  {
    label: 'Manager',
    value: 'Manager',
  },
  {
    label: 'Managing Member',
    value: 'Managing Member',
  },
  {
    label: 'Member',
    value: 'Member',
  },
  {
    label: 'Non-Owner Managing Member',
    value: 'Non-Owner Managing Member',
  },
  {
    label: 'Owner',
    value: 'Owner',
  },
  {
    label: 'Partner',
    value: 'Partner',
  },
  {
    label: 'President',
    value: 'President',
  },
  {
    label: 'Principal',
    value: 'Principal',
  },
  {
    label: 'Secretary',
    value: 'Secretary',
  },
  {
    label: 'Sole Member',
    value: 'Sole Member',
  },
  {
    label: 'Special Limited Partner',
    value: 'Special Limited Partner',
  },
  {
    label: 'Treasurer',
    value: 'Treasurer',
  },
  {
    label: 'Trustee',
    value: 'Trustee',
  },
  {
    label: 'Vice President',
    value: 'Vice President',
  },
];
