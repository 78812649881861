import React, { useCallback, useContext } from 'react';
import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ListIcon } from '@lola/ui-react-components';
import { ActionBlock, CardSection } from '@lola/ui-react-components';
import { Collapsed } from '@components/Collapsed';
import { Quote, QUOTE_ACCORDION_VALUES, QUOTE_TYPE } from '@typings/common';
import { DropdownListsContext } from '@components/DynamicForm';
import { QuoteFields } from '@pages/scenarioBuilder';
import { MyQuotesListDrawer } from '@pages/personalLoans/pages/buildMyLoan/pages/product/components';
import { FormExitConfirmationPrompt } from '@components/FormExitConfirmationPrompt/FormExitConfirmationPrompt.component';
import { useOpenState } from '@hooks/useOpenState';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { useLimitedLoanProgramList } from '../../hooks';
import { ProductComponentProps } from '../../Product.type';
import styles from './productDetails.module.scss';

export interface ProductDetailsProps extends ProductComponentProps {
  quoteType: QUOTE_TYPE;
  quoteId?: string;
  calculationErrors?: string[];
  data?: Quote;
  onQuoteSelect?: (id: string) => void;
  isCalculating?: boolean;
  isLicensingFormDirty?: boolean;
}

export const ProductDetails = ({
  quoteType,
  accordionValue,
  onChange,
  data,
  quoteId,
  onQuoteSelect,
  calculationErrors,
  isCalculating,
  isLicensingFormDirty,
}: ProductDetailsProps) => {
  const { disabled } = useContext(LoanDisableContext);
  const [isListOpen, openList, closeList] = useOpenState();
  const {
    formState: { isDirty },
  } = useFormContext();

  const isCalculated = !!data?.out;

  const pullFromQuoteHandler = useCallback(
    (quoteId: string) => {
      if (onQuoteSelect) onQuoteSelect(quoteId);
      closeList();
    },
    [onQuoteSelect, closeList]
  );

  const listValues = useLimitedLoanProgramList();

  return (
    <CardSection
      title="Product"
      description="Choose the loan program that best suits your borrower's needs. The below information will be used to provide you with the preliminary terms of the loan."
    >
      <Collapsed
        label="Details"
        expanded={accordionValue === QUOTE_ACCORDION_VALUES.DETAILS}
        onChange={onChange(QUOTE_ACCORDION_VALUES.DETAILS)}
      >
        <div className={styles.actionBlockWrapper}>
          <ActionBlock
            title="Pull from a quote"
            description="You can pull up the data for the loan from the quote you made in the Scenario Builder"
          >
            <Button
              variant="outlined"
              onClick={openList}
              startIcon={<ListIcon />}
              disabled={disabled}
            >
              Pull from quote
            </Button>
          </ActionBlock>
        </div>
        <DropdownListsContext.Provider value={listValues}>
          <QuoteFields
            errors={calculationErrors}
            quoteType={quoteType}
            isDirty={isDirty || isLicensingFormDirty}
            isCalculating={isCalculating}
            isCalculated={!!quoteId || isCalculated}
            disabled={disabled}
          />
        </DropdownListsContext.Provider>

        <MyQuotesListDrawer
          onClose={closeList}
          onQuoteSelect={pullFromQuoteHandler}
          open={isListOpen}
          quoteType={quoteType}
        />

        <FormExitConfirmationPrompt when={!isCalculating && isDirty} />
      </Collapsed>
    </CardSection>
  );
};
