export enum ALL_TIME_KPI_TYPE {
  QUOTES_TO_SUBMISSION = 'quotesToSubmission',
  SUBMISSION_TO_CLOSE = 'submissionToClose',
  QUOTES_TO_CLOSE = 'quotesToClose',
}

export const ALL_TIME_KPI_TITLES = {
  [ALL_TIME_KPI_TYPE.QUOTES_TO_SUBMISSION]: 'Quotes to submission',
  [ALL_TIME_KPI_TYPE.SUBMISSION_TO_CLOSE]: 'Submission to close',
  [ALL_TIME_KPI_TYPE.QUOTES_TO_CLOSE]: 'Quotes to close',
};
