import React, { PropsWithChildren, useMemo } from 'react';
import classnames from 'classnames';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Modal as MuiModal,
  Typography,
} from '@mui/material';
import { CloseIcon } from '@lola/ui-react-components';

import styles from './modal.module.scss';

export interface ModalProps {
  isOpen?: boolean;
  isLoading?: boolean;
  title?: string;
  onClose?: () => void;
  successButtonText?: string;
  onSuccess?: () => void;
  width?: number | string;
  hasCancel?: boolean;
  cancelButtonText?: string;
  noSpace?: boolean;
}

export const Modal = ({
  isLoading = false,
  isOpen = false,
  title = '',
  successButtonText = 'OK',
  onSuccess,
  onClose,
  children,
  width = 620,
  hasCancel = false,
  cancelButtonText = 'Cancel',
  noSpace = false,
}: PropsWithChildren<ModalProps>) => {
  const widthWithUnit = useMemo(
    () => (typeof width === 'number' ? `${width}px` : width),
    [width]
  );

  return (
    <>
      <MuiModal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        classes={{ root: styles.wrapper }}
      >
        <Box className={styles.modal} style={{ width: widthWithUnit }}>
          <section className={styles.header}>
            <Typography id="modal-modal-title" variant="h4">
              {title}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon className={styles.icon} />
            </IconButton>
            {isLoading && (
              <LinearProgress
                className={styles.loadingIndicator}
                data-cy="linear-progress"
              />
            )}
          </section>
          <Divider />
          <section
            className={classnames(styles.content, {
              [styles.noSpace]: noSpace,
            })}
          >
            <Grid container spacing={6}>
              <Grid item xs={12}>
                {children}
              </Grid>
              {onSuccess && (
                <Grid item xs={12} classes={{ root: styles.footer }}>
                  {hasCancel && (
                    <Button variant="text" onClick={onClose}>
                      {cancelButtonText}
                    </Button>
                  )}
                  <Button variant="contained" onClick={onSuccess} size="large">
                    {successButtonText}
                  </Button>
                </Grid>
              )}
            </Grid>
          </section>
        </Box>
      </MuiModal>
    </>
  );
};
