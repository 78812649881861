import React from 'react';
import { Typography } from '@mui/material';
import { LolaBffApiContractsModelsActivitiesActivityLogData } from '@api/output/api';

export const userInfoRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsActivitiesActivityLogData
) => {
  const userInfo = row.userInfo;

  return (
    <>
      <Typography variant="p">{userInfo?.name}</Typography>
      <br />
      <Typography variant="pDescription">{userInfo?.email}</Typography>
    </>
  );
};
