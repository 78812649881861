import React, { useCallback } from 'react';
import { IconButton, MenuItem, Stack } from '@mui/material';
import {
  MoreVerticalIcon,
  ArchiveIcon,
  ManageAccessIcon,
} from '@lola/ui-react-components';
import { Menu } from '@components/Menu';
import { SetModalContent } from '@typings/common';
import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';
import { DrawerState } from '@containers/ManageAccessDrawer/ManageAccessDrawer.constants';
import { MODAL_STATE, ModalContentType } from '../../Loans.constants';
import styles from './actionsMenu.module.scss';

export interface ActionsMenuProps {
  context: LolaBffApiContractsModelsLoanLoanView;
  setModalContent?: SetModalContent<ModalContentType>;
  setDrawerState?: (arg: DrawerState) => void;
  disabled?: boolean;
}

export const ActionsMenu = ({
  context,
  setModalContent,
  setDrawerState,
  disabled,
}: ActionsMenuProps) => {
  const openModal = useCallback(
    (modalState: MODAL_STATE) => {
      if (setModalContent) {
        setModalContent({
          state: modalState,
          context,
        });
      }
    },
    [context, setModalContent]
  );

  const openDrawer = useCallback(() => {
    if (setDrawerState) {
      setDrawerState({
        isOpen: true,
        context,
      });
    }
  }, [setDrawerState, context]);

  return (
    <Stack direction="row" justifyContent="center">
      <Menu
        disabled={disabled}
        trigger={
          <IconButton disabled={disabled}>
            <MoreVerticalIcon />
          </IconButton>
        }
      >
        <div className={styles.menu}>
          <MenuItem onClick={() => openModal(MODAL_STATE.ARCHIVE_LOAN)}>
            <ArchiveIcon /> Archive loan
          </MenuItem>
          <MenuItem onClick={openDrawer}>
            <ManageAccessIcon /> Manage access
          </MenuItem>
        </div>
      </Menu>
    </Stack>
  );
};
