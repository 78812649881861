import React, { useEffect, useMemo } from 'react';
import {
  Alert,
  createTheme,
  LinearProgress,
  ThemeProvider,
} from '@mui/material';
import { themeOptions } from '@lola/ui-react-components';
import { useGetApiV1TenantConfigQuery } from '@api/output/api';
import { Router } from '@router/router';
import { auth } from '@config/auth';
import { useOpenToast } from '@hooks/useOpenToast';
import { useVersionCheck } from '@hooks/useVersionCheck';
import { HALF_A_DAY } from '@constants/time.constants';
import { getGTMId, initGTM } from '@utils/analytics';
import { updateCSSVariables } from './themes/utils/variables';
import { updateFavicon } from './themes/utils/favicon';
import { convertTheme } from './themes/utils/tenant';

export const App = () => {
  const newVersionAvailable = useVersionCheck(HALF_A_DAY);
  const openToast = useOpenToast(5000);

  useEffect(() => {
    const gtagManagerId = getGTMId();
    initGTM(gtagManagerId);
  }, []);

  const {
    data: themeConfig,
    isFetching,
    isSuccess,
    isError,
  } = useGetApiV1TenantConfigQuery({
    domain: auth.domain,
  });

  useEffect(() => {
    if (newVersionAvailable) {
      openToast({
        title: 'New version is available',
        description: 'Please refresh the page',
      });
    }
  }, [newVersionAvailable]);

  useEffect(() => {
    if (themeConfig) {
      document.title = themeConfig.themeData.longCompanyName || 'Broker Portal';
      updateCSSVariables(themeConfig.themeData.palette);
      updateFavicon(themeConfig.themeData.images.favIcon);
    }
  }, [themeConfig]);

  const theme = useMemo(() => {
    if (themeConfig) {
      return createTheme({
        ...themeOptions,
        ...convertTheme(themeConfig),
      });
    }
  }, [themeConfig]);

  if (isFetching) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  if (!theme && isSuccess) {
    return (
      <Alert severity="error">
        Unable to identify client, please check the URL and try again.
      </Alert>
    );
  }

  if (isError) {
    return (
      <Alert severity="error">
        Something went wrong, please try again later.
      </Alert>
    );
  }

  if (!theme) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
};
