import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import classnames from 'classnames';
// router
import { useParams } from 'react-router-dom';
// MUI
import { Typography, Skeleton, IconButton, Divider } from '@mui/material';
// icons
import { CloseIcon } from '@lola/ui-react-components';
// API
import { useGetApiV1ConditionGetConditionDetailsQuery } from '@api/output/api';
// global components
import { NoContent } from '@components/NoContent';
// local components
import {
  ConditionPanelLoading,
  ConditionPanel,
  FileHistory,
} from './components';
// style
import style from './ManageConditionPanel.module.scss';

export interface ManageConditionPanelProps {
  title?: string;
  conditionId: string;
  isExceptionRequest?: boolean;
  loanName?: string;
  loanIdentifier?: string | null;
  handledExceptionRequest?: (boolean: boolean) => void;
  onClose?: () => void;
  openModal?: () => void;
  setIsReFetchNeeded?: Dispatch<SetStateAction<boolean>>;
}

export const ManageConditionPanel = ({
  title,
  conditionId = '',
  isExceptionRequest = false,
  loanName = '',
  loanIdentifier = '',
  handledExceptionRequest,
  onClose,
  openModal,
  setIsReFetchNeeded,
}: ManageConditionPanelProps): JSX.Element => {
  // router
  const { loanId = '' } = useParams();

  // API
  const { isLoading, data, isError, refetch } =
    useGetApiV1ConditionGetConditionDetailsQuery({
      conditionId,
      loanId,
    });

  // data
  const conditionHistory = data?.history;
  const disabledButton = !data?.enableUploadFiles;

  // actions
  const handledClose = useCallback(() => onClose?.(), [onClose]);

  useEffect(() => {
    if (!conditionId) return;

    refetch();
  }, [conditionId]);

  return (
    <section className={style.ManageConditionPanel}>
      <header className={style.header}>
        {isLoading ? (
          <Skeleton variant="text" width={300} role="progressbar" />
        ) : (
          <Typography variant="h4">
            {isExceptionRequest ? 'Exception request' : title}
          </Typography>
        )}
        <IconButton role="button_close" onClick={handledClose}>
          <CloseIcon />
        </IconButton>
      </header>
      <Divider />

      <section
        className={classnames({
          [style.container]: true,
          [style.exception]: isExceptionRequest,
        })}
      >
        {isError && <NoContent title="Something was wrong, pleas try again." />}
        {isLoading ? (
          <ConditionPanelLoading />
        ) : (
          <ConditionPanel
            exceptionRequest={isExceptionRequest}
            handledClose={handledClose}
            openModal={openModal}
            data={data}
            setExceptionRequest={handledExceptionRequest}
            conditionId={conditionId}
            loanId={loanId}
            loanIdentifier={loanIdentifier}
            loanName={loanName}
            disabledButton={disabledButton}
            callBack={() => {
              setIsReFetchNeeded?.(true);
              refetch();
            }}
            conditionHistory={conditionHistory as FileHistory[]}
          />
        )}
      </section>
    </section>
  );
};
