import * as yup from 'yup';
import {
  processMaxLengthRule,
  processMinDateRule,
  processNoEmptyRule,
} from '@components/DynamicForm/utils/validators.utils';
import {
  FormData,
  ControlType,
  FieldConfig,
  ConditionMap,
  ValidationRule,
} from '@form-configs/types';
import { getConditionResult } from '@components/DynamicForm/utils/condition.utils';
import { isValidDateFormat } from '@utils/date.utils';
import { MINIMUM_DATE } from '@constants/time.constants';
import {
  VALIDATION_DEFAULT_MESSAGES,
  VALIDATION_RULES,
} from '@components/DynamicForm/utils/validation.types';
import { AnyObject } from '@typings/common';

export interface ValidationItem {
  name: string;
  controlType: ControlType;
  rules: ValidationRule[];
  condition?: ConditionMap;
}

export interface ValidationTreeItem {
  [key: string]: ValidationTreeItem | Omit<ValidationItem, 'name'>;
}

export const CONTROLS_DEFAULT_VALIDATION: Record<string, () => yup.AnySchema> =
  {
    array: () => yup.array(),
    string: () => yup.string(),
    number: () => yup.number(),
    datePicker: () =>
      yup
        .date()
        .min(MINIMUM_DATE, VALIDATION_DEFAULT_MESSAGES.DATE)
        .transform((_, originalValue) => {
          return isValidDateFormat(originalValue)
            ? new Date(originalValue)
            : new Date(VALIDATION_DEFAULT_MESSAGES.DATE);
        }),
  };

export const isValidationApplicableToTheField = ({ props }: FieldConfig) => {
  if (!props?.name || props.locked) {
    return false;
  }

  return props?.validation?.length > 0;
};

export const convertFieldsToSimpleStructure = (
  fields: FieldConfig[],
  condition?: ConditionMap
): ValidationItem[] => {
  return fields.reduce((result: ValidationItem[], field: FieldConfig) => {
    const localCondition = field.props?.condition ?? condition;

    if (field.items) {
      return result.concat(
        convertFieldsToSimpleStructure(field.items, localCondition)
      );
    }

    if (!isValidationApplicableToTheField(field)) {
      return result;
    }

    const name = field.props?.name || '';

    const element: ValidationItem = {
      name,
      controlType: field.controlType,
      rules: field.props?.validation,
    };

    if (condition) {
      element.condition = localCondition;
    }

    result.push(element);

    return result;
  }, []);
};

export const convertSimpleConfigToValidationObject = (
  result: ValidationItem[]
) => {
  return result.reduce((output: ValidationTreeItem, item: ValidationItem) => {
    const names = item.name.replace(/\[(\d|)]/, '.0').split('.');

    const parsedObject = names.reduce(
      (partObj: AnyObject, partName, index, arr) => {
        if (!partObj[partName]) {
          const nextItem = arr[index + 1];
          const isArray = nextItem === '0';
          partObj[partName] = isArray ? [{}] : {};
        }

        return partObj[partName];
      },
      output
    );

    Object.assign(parsedObject, {
      controlType: item.controlType,
      rules: item.rules,
    });

    if (item.condition) {
      parsedObject.condition = item.condition;
    }

    return output;
  }, {});
};

export const whenObjectBasedOnCondition = (condition: ConditionMap) => {
  const keys = Object.values(condition)
    .flat()
    .map((item) => item[1]);

  const check: (...args: string[]) => boolean = (...values) => {
    const tree = keys.reduce((result: Record<string, string>, key, index) => {
      result[key] = values[index];
      return result;
    }, {});

    return getConditionResult(tree, condition);
  };

  return {
    keys,
    check,
  };
};

const applyValidationRule = (
  output: yup.AnySchema,
  rule: ValidationRule,
  controlType: ControlType
): yup.AnySchema => {
  switch (rule.rule) {
    case VALIDATION_RULES.REQUIRED: {
      if (controlType === 'text') {
        const message =
          (rule?.args?.[0] as string) || VALIDATION_DEFAULT_MESSAGES.REQUIRED;

        return output.test(
          VALIDATION_RULES.REQUIRED,
          message,
          (value: string) => processNoEmptyRule(value)
        );
      } else {
        return output.required(VALIDATION_DEFAULT_MESSAGES.REQUIRED);
      }
    }
    case VALIDATION_RULES.MAX_LENGTH: {
      const maxLength = rule.args ? (rule.args[0] as number) : 0;
      const message =
        (rule?.args?.[1] as string) || VALIDATION_DEFAULT_MESSAGES.LENGTH;

      return output.test(
        VALIDATION_RULES.MAX_LENGTH,
        message,
        processMaxLengthRule(maxLength)
      );
    }
    case VALIDATION_RULES.MIN_DATE: {
      const minDate = rule.args ? (rule.args[0] as string) : MINIMUM_DATE;
      const message =
        (rule?.args?.[1] as string) || VALIDATION_DEFAULT_MESSAGES.DATE;

      return output.test(
        VALIDATION_RULES.MIN_DATE,
        message,
        processMinDateRule(minDate)
      );
    }
    default: {
      const schemaRule = rule.rule as keyof yup.AnySchema;
      const args = rule.args ?? [];

      if (typeof output[schemaRule] === 'function') {
        return output[schemaRule](...args);
      } else {
        console.warn(`Unsupported validation rule: ${schemaRule}`);
        return output;
      }
    }
  }
};

export const createYupSequence = (
  controlType: ControlType,
  rules: ValidationRule[],
  condition?: ConditionMap
): yup.AnySchema => {
  const localRules = [...rules];
  const initialCreator =
    CONTROLS_DEFAULT_VALIDATION[rules[0].rule] ||
    CONTROLS_DEFAULT_VALIDATION[controlType] ||
    CONTROLS_DEFAULT_VALIDATION.string;

  const initial = initialCreator();

  if (CONTROLS_DEFAULT_VALIDATION[rules[0].rule]) {
    localRules.shift();
  }

  const validation = localRules.reduce(
    (output: yup.AnySchema, rule) =>
      applyValidationRule(output, rule, controlType),
    initial
  );

  if (condition) {
    const { keys, check } = whenObjectBasedOnCondition(condition);
    return initialCreator().default(null).nullable().when(keys, {
      is: check,
      then: validation,
    });
  }

  return validation;
};

export const convertObjectConfigToYup = (
  config: ValidationTreeItem | ValidationTreeItem[]
): yup.AnySchema => {
  const yupObject: AnyObject = {};

  if (Array.isArray(config)) {
    return yup.array().of(convertObjectConfigToYup(config[0]));
  } else {
    for (const [name, value] of Object.entries(config)) {
      if ('rules' in value) {
        yupObject[name] = createYupSequence(
          value.controlType as ControlType,
          value.rules as ValidationRule[],
          value.condition as ConditionMap
        );
      } else {
        yupObject[name] = convertObjectConfigToYup(value);
      }
    }
  }

  return yup.object(yupObject);
};

export const convertFormConfigToYupSchema = (fields: FieldConfig[] = []) => {
  const simpleConfig = convertFieldsToSimpleStructure(fields);
  const objectConfig = convertSimpleConfigToValidationObject(simpleConfig);
  return convertObjectConfigToYup(objectConfig);
};

export const getValidationErrors = (form: FormData, schema: yup.AnySchema) => {
  try {
    schema.validateSync(form, {
      abortEarly: false,
    });
    return [];
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      return err.inner;
    }
    console.error(err);
    return [];
  }
};
