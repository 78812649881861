import React, { useContext } from 'react';
import { CardProps, FieldConfig } from '@form-configs/types';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { FormConfigContext } from '@components/DynamicForm/contexts/FormConfig.context';
import { DynamicItems } from '../DynamicItems/DynamicItems.component';

export interface DynamicCardProps extends CardProps {
  items?: FieldConfig[];
}

export const DynamicCard = ({
  label,
  tooltip,
  items = [],
}: DynamicCardProps) => {
  const { disabled } = useContext(FormConfigContext);

  return (
    <LabeledCard label={label} tooltip={tooltip} disabled={disabled}>
      <DynamicItems fields={items} />
    </LabeledCard>
  );
};
