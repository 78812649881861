import React from 'react';
//MUI
import { Chip } from '@mui/material';
//types
import { LolaBffApiContractsModelsLoanInactiveLoan } from '@api/output/api';
import { LOAN_STATUS } from '../../myLoansInactiveLoans.type';
//styles
import styles from './loanStatusRenderer.module.scss';

const STATUS_LIST = {
  [LOAN_STATUS.CLOSED]: (
    <Chip
      size="small"
      label={LOAN_STATUS.CLOSED}
      color="success"
      variant="filled"
    />
  ),
  [LOAN_STATUS.ARCHIVED]: (
    <Chip
      size="small"
      label={LOAN_STATUS.ARCHIVED}
      color="default"
      variant="filled"
      disabled={true}
      className={styles.archived}
    />
  ),
};
export const loanStatusRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsLoanInactiveLoan
) => {
  const { status } = row;
  return (
    STATUS_LIST[status as LOAN_STATUS] || STATUS_LIST[LOAN_STATUS.ARCHIVED]
  );
};
