import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import { StatusAlertIcon } from '@lola/ui-react-components';
import { LolaBffApiContractsModelsActivitiesActivityLogData } from '@api/output/api';
import styles from './activityNameRenderer.module.scss';

export const activityNameRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsActivitiesActivityLogData
) => {
  const isError = row.errorMessage;

  return (
    <div
      className={classnames({
        [styles.error]: isError,
      })}
    >
      <div className={styles.title}>
        {isError && <StatusAlertIcon />}
        <Typography variant="pBold">{row.activity}</Typography>
      </div>
      {isError && <Typography variant="p">{row.errorMessage}</Typography>}
    </div>
  );
};
