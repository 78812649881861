import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';

export enum MODAL_STATE {
  CLOSE = 'CLOSE',
  ARCHIVE_LOAN = 'ARCHIVE_LOAN',
  ASSIGN_PROCESSOR_BULK = 'ASSIGN_PROCESSOR_BULK',
  ASSIGN_PROCESSOR_CONFIRM = 'ASSIGN_PROCESSOR_CONFIRM',
}

export const MODAL_TITLES: Record<MODAL_STATE, string> = {
  CLOSE: '',
  ARCHIVE_LOAN: 'Archive loan',
  ASSIGN_PROCESSOR_BULK: 'Assign processor',
  ASSIGN_PROCESSOR_CONFIRM: 'Assign processor',
};

export interface ModalContentType {
  state: MODAL_STATE;
  context?: LolaBffApiContractsModelsLoanLoanView;
}

export const ASSIGN_PROCESSOR_BULK_FORM_NAME = 'assignProcessorBulk';
export const ASSIGN_PROCESSOR_BULK_FORM = {
  PROCESSOR_ID: `${ASSIGN_PROCESSOR_BULK_FORM_NAME}.processorId`,
};
