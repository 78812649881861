import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
//MUI
import { Button, Grid, Paper, Typography, Alert, Divider } from '@mui/material';
//hooks
import { useUpload } from '@components/Uploader/hooks/useUpload';
// API
import {
  LolaBffApiContractsModelsConditionResponseConditionDetailsResponse,
  usePostApiV1ConditionConditionUploadMutation,
} from '@api/output/api';
//Types
import { FileItem } from '@components/Uploader/Uploader.types';
import { ApproveMyLoanStatus } from '@pages/personalLoans/types/LoanTypes.type';
// constants
import {
  UPLOADER_STATE,
  DEFAULT_UPLOADER_CONFIG,
} from '@components/Uploader/Uploader.constants';
// Global Components
import { Uploader } from '@components/Uploader/Uploader.component';
import { LoanStatusChip } from '@pages/personalLoans/components';
// components
import { MessageArea } from '@pages/personalLoans/pages/approveMyLoan/pages/reviewStatus/components/MessageArea.component';
import { ExceptionRequest } from '@components/ExceptionRequest/ExceptionRequest.component';
import { UploadedFileCard } from '@components/UploadedFileCard/UploadedFileCard.component';
import { ManageConditionUserHeader } from '../ManageConditionUserHeader/ManageConditionUserHeader.component';
//styles
import style from './conditionPanel.module.scss';

export type FileHistory = {
  file: {
    conditionName: string;
    fileId: string;
    folderId: string;
    name: string;
    timeStamp: string;
  };
};

export interface ConditionPanelProps {
  exceptionRequest: boolean;
  handledClose: () => void;
  openModal?: () => void;
  data?: LolaBffApiContractsModelsConditionResponseConditionDetailsResponse;
  setExceptionRequest?: (boolean: boolean) => void;
  conditionId: string;
  loanId: string;
  loanIdentifier: string | null;
  loanName?: string;
  disabledButton?: boolean;
  callBack?: () => void;
  conditionHistory?: FileHistory[];
}

export const ConditionPanel = ({
  exceptionRequest,
  handledClose,
  openModal,
  data,
  setExceptionRequest,
  conditionId = '',
  loanId,
  loanIdentifier,
  loanName = '',
  disabledButton = false,
  callBack,
  conditionHistory,
}: ConditionPanelProps) => {
  // states
  const [statusValue, setStatusValue] = useState(data?.conditionStatus);

  // data
  const enableExceptionRequest = data?.enableExceptionRequest ?? false;
  const isAcceptFile: string[] = data?.upload?.acceptedFilesType ?? [];
  const maxSize: number = data?.upload?.maxFileWeight
    ? parseInt(data.upload.maxFileWeight)
    : 0;

  // const
  const UPLOAD_RULES_CONF = {
    allowedExtensions: {
      rule: isAcceptFile,
      message: 'The file is not accepted format.  ',
    },
    maxSize: {
      rule: maxSize,
      message: `The file size must be below to ${maxSize / 1000000}MB.`,
    },
    maxFiles: 20,
  };

  // API
  const [handledUpload, responseUpload] =
    usePostApiV1ConditionConditionUploadMutation();
  const { isError, data: dataResponse, isLoading } = responseUpload;

  const broker = useMemo(() => {
    if (dataResponse?.broker?.name) return dataResponse?.broker;
    return false;
  }, [data, dataResponse]);

  // hooks
  const { files, uploadFiles, setErrors } = useUpload({
    rules: UPLOAD_RULES_CONF,
  });

  const isErrorUpload: string | null = isError
    ? 'Something was wrong. Please try again later.'
    : null;

  const handledState = useMemo((): UPLOADER_STATE => {
    if (disabledButton) return UPLOADER_STATE.DISABLED;

    if (isLoading) return UPLOADER_STATE.LOADING;

    return files.length === 0 ? UPLOADER_STATE.INIT : UPLOADER_STATE.SUCCESS;
  }, [disabledButton, files, isLoading]);

  const handledSendFile = async (files: FileItem[]) => {
    const body = new FormData();
    files.forEach((file) => {
      body.append('files', file.file as unknown as Blob, file.fileName ?? '');
    });

    try {
      await handledUpload({
        conditionId,
        body: body as unknown as { files: Blob[] },
        loanId: loanId,
        loanIdentifier: loanIdentifier ?? '',
      });

      setStatusValue('Submitted');
      callBack?.();
    } catch (err) {
      setErrors(err as string);
    }
  };

  useEffect(() => {
    if (!files || files.length === 0) return;
    handledSendFile(files);
  }, [files]);

  if (exceptionRequest) {
    return (
      <ExceptionRequest
        handledClose={handledClose}
        openModal={openModal}
        loanName={loanName}
        loanIdentifier={loanIdentifier ?? ''}
      />
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <header className={style.headerPanel}>
          <div>
            {data?.conditionName && (
              <Typography variant="h3">{data.conditionName}</Typography>
            )}
            {data?.conditionDescription && (
              <Typography variant="p">{data.conditionDescription}</Typography>
            )}
          </div>
          {data?.conditionStatus && (
            <LoanStatusChip status={statusValue as ApproveMyLoanStatus} />
          )}
        </header>
      </Grid>
      {data?.upload && (
        <Grid item xs={12}>
          {enableExceptionRequest && (
            <Button
              variant="outlined"
              onClick={() => setExceptionRequest?.(true)}
              className={style.exceptionRequestButton}
            >
              Run exception request
            </Button>
          )}

          <MessageArea
            messageStatus="warning"
            title="Uploaded files cannot be deleted"
            message="You will not be able to delete documents once you have uploaded them. Please be careful when uploading them."
          />

          <Paper elevation={0} className={style.uploadDescription}>
            {broker && (
              <div className={style.uploadDescriptionBrokerHeader}>
                <ManageConditionUserHeader
                  name={broker?.name?.split(' ')[0]}
                  lastName={broker?.name?.split(' ')[1]}
                  title={broker?.name}
                  date={broker?.timeStamp}
                />
              </div>
            )}

            <Uploader
              state={handledState}
              className={style.uploadSection}
              onUpload={(file) => uploadFiles(file)}
              uploaderConfig={{
                ...DEFAULT_UPLOADER_CONFIG,
                description: `Accepted file types: ${UPLOAD_RULES_CONF.allowedExtensions.rule.reduce(
                  (acc, current) => `${acc}${acc && ','} ${current}`,
                  ''
                )}`,
                rules: UPLOAD_RULES_CONF,
              }}
            >
              {conditionHistory?.map(
                ({ file: { name, timeStamp, fileId } }) => (
                  <>
                    <Divider />
                    <UploadedFileCard
                      key={uuidv4()}
                      fileName={name}
                      updateDate={timeStamp}
                      fileId={fileId}
                    />
                  </>
                )
              )}
            </Uploader>

            {isError && (
              <Alert variant="outlined" severity="error">
                {isErrorUpload}
              </Alert>
            )}
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
