import { FC } from 'react';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import { TEAM_ASSIGNMENT_STATUS, ROLES } from '@typings/common';
import { EntityBasedSwitcherConfigs } from '@components/ModeSwitcher/ModeSwitcher.types';
import {
  ActionsForTodayWidget,
  AllTimeKPIWidget,
  LearningCenterWidget,
  QuickActionsWidget,
  StatisticsWidget,
  StatsProgressWidget,
  LoansInPipelineWidget,
} from './components';

export interface WidgetConfig extends RegularBreakpoints {
  Component?: FC;
  children?: WidgetConfig[];
  id: string;
}

export const brokerOverviewConfig: WidgetConfig[] = [
  {
    id: '3-column-statistic',
    xs: 12,
    Component: StatsProgressWidget,
  },
  {
    id: 'broker-2-col-left',
    xs: 12,
    lg: 8,
    children: [
      {
        id: 'broker-actions-for-today',
        xs: 12,
        Component: ActionsForTodayWidget,
      },
      {
        id: 'broker-learning',
        xs: 12,
        Component: LearningCenterWidget,
      },
    ],
  },
  {
    id: 'broker-2-col-global-right',
    xs: 12,
    lg: 4,
    children: [
      {
        id: 'broker-side-widget',
        xs: 12,
        Component: StatisticsWidget,
      },
      {
        id: 'all-time-kpi-widget',
        xs: 12,
        Component: AllTimeKPIWidget,
      },
      {
        id: 'broker-quick-action',
        xs: 12,
        Component: QuickActionsWidget,
      },
    ],
  },
];

export const unassignedBrokerTeamOverviewConfig: WidgetConfig[] = [
  {
    id: '3-column-statistic',
    xs: 12,
    Component: StatsProgressWidget,
  },
  {
    id: 'broker-2-col-left',
    xs: 12,
    lg: 8,
    children: [
      {
        id: 'team-performance-widget',
        xs: 12,
        Component: LoansInPipelineWidget,
      },
      {
        id: 'broker-learning',
        xs: 12,
        Component: LearningCenterWidget,
      },
    ],
  },
  {
    id: 'broker-2-col-global-right',
    xs: 12,
    lg: 4,
    children: [
      {
        id: 'broker-side-widget',
        xs: 12,
        Component: StatisticsWidget,
      },
      {
        id: 'all-time-kpi-widget',
        xs: 12,
        Component: AllTimeKPIWidget,
      },
    ],
  },
];

export const processorAnalyticsConfig = [
  {
    id: 'processor-stats',
    xs: 12,
    Component: StatsProgressWidget,
  },
  {
    id: 'processor-actions-for-today',
    xs: 12,
    Component: ActionsForTodayWidget,
  },
  {
    id: 'processor-main-combi-block',
    xs: 12,
    children: [
      {
        id: 'processor-learning',
        xs: 12,
        lg: 8,
        Component: LearningCenterWidget,
      },
      {
        id: 'processor-left',
        xs: 12,
        lg: 4,
        children: [
          {
            id: 'processor-all-time',
            xs: 12,
            Component: AllTimeKPIWidget,
          },
          {
            id: 'processor-quick-actions',
            xs: 12,
            Component: QuickActionsWidget,
          },
        ],
      },
    ],
  },
];

export const managerPerformanceConfig: WidgetConfig[] = [
  {
    id: '3-column-statistic',
    xs: 12,
    Component: StatsProgressWidget,
  },
  {
    id: 'broker-2-col-left',
    xs: 12,
    lg: 8,
    children: [
      {
        id: 'team-performance-widget',
        xs: 12,
        Component: LoansInPipelineWidget,
      },
      {
        id: 'broker-learning',
        xs: 12,
        Component: LearningCenterWidget,
      },
    ],
  },
  {
    id: 'broker-2-col-global-right',
    xs: 12,
    lg: 4,
    children: [
      {
        id: 'broker-side-widget',
        xs: 12,
        Component: StatisticsWidget,
      },
      {
        id: 'all-time-kpi-widget',
        xs: 12,
        Component: AllTimeKPIWidget,
      },
    ],
  },
];

export enum DASHBOARD_MODE {
  PERSONAL = 'personal',
  TEAM = 'team',
  NOT_SET = 'notSet',
}

const brokerWithTeamSwitcherConfig = {
  defaultValue: DASHBOARD_MODE.TEAM,
  options: [
    {
      label: 'Personal',
      value: DASHBOARD_MODE.PERSONAL,
    },
    {
      label: 'Team',
      value: DASHBOARD_MODE.TEAM,
    },
  ],
};

const brokerWithoutTeamSwitcherConfig = {
  defaultValue: DASHBOARD_MODE.PERSONAL,
  options: [
    {
      label: 'Personal',
      value: DASHBOARD_MODE.PERSONAL,
    },
    {
      label: 'All Unassigned',
      value: DASHBOARD_MODE.TEAM,
    },
  ],
};

export const ASSIGNED_ROLE_TO_ALLOWED_MODES: Record<
  TEAM_ASSIGNMENT_STATUS,
  EntityBasedSwitcherConfigs<DASHBOARD_MODE>
> = {
  [TEAM_ASSIGNMENT_STATUS.ASSIGNED]: {
    [ROLES.KEY_BROKER_MANAGER]: brokerWithTeamSwitcherConfig,
    [ROLES.BROKER_MANAGER]: brokerWithTeamSwitcherConfig,
  },
  [TEAM_ASSIGNMENT_STATUS.UNASSIGNED]: {
    [ROLES.KEY_BROKER_MANAGER]: brokerWithTeamSwitcherConfig,
    [ROLES.BROKER_MANAGER]: brokerWithTeamSwitcherConfig,
    [ROLES.BROKER]: brokerWithoutTeamSwitcherConfig,
  },
};

type DashboardConfigs = {
  [status in TEAM_ASSIGNMENT_STATUS]: {
    [mode in DASHBOARD_MODE]: {
      [role in ROLES]: WidgetConfig[];
    };
  };
};

export const PERSONAL_ASSIGNED_WIDGETS = {
  [ROLES.BROKER]: brokerOverviewConfig,
  [ROLES.PROCESSOR]: brokerOverviewConfig,
  [ROLES.BROKER_MANAGER]: brokerOverviewConfig,
  [ROLES.KEY_BROKER_MANAGER]: brokerOverviewConfig,
  [ROLES.NOT_SET]: [],
};

export const TEAM_ASSIGNED_WIDGETS = {
  [ROLES.BROKER]: brokerOverviewConfig,
  [ROLES.PROCESSOR]: processorAnalyticsConfig,
  [ROLES.BROKER_MANAGER]: managerPerformanceConfig,
  [ROLES.KEY_BROKER_MANAGER]: managerPerformanceConfig,
  [ROLES.NOT_SET]: [],
};

const PERSONAL_UNASSIGNED_WIDGETS = {
  ...PERSONAL_ASSIGNED_WIDGETS,
};

const TEAM_UNASSIGNED_WIDGETS = {
  ...TEAM_ASSIGNED_WIDGETS,
  [ROLES.BROKER]: unassignedBrokerTeamOverviewConfig,
};

export const DASHBOARD_CONFIGS: DashboardConfigs = {
  [TEAM_ASSIGNMENT_STATUS.ASSIGNED]: {
    [DASHBOARD_MODE.PERSONAL]: PERSONAL_ASSIGNED_WIDGETS,
    [DASHBOARD_MODE.TEAM]: TEAM_ASSIGNED_WIDGETS,
    [DASHBOARD_MODE.NOT_SET]: PERSONAL_ASSIGNED_WIDGETS,
  },
  [TEAM_ASSIGNMENT_STATUS.UNASSIGNED]: {
    [DASHBOARD_MODE.PERSONAL]: PERSONAL_UNASSIGNED_WIDGETS,
    [DASHBOARD_MODE.TEAM]: TEAM_UNASSIGNED_WIDGETS,
    [DASHBOARD_MODE.NOT_SET]: PERSONAL_ASSIGNED_WIDGETS,
  },
};

export const CAN_START_LOAN = [
  ROLES.KEY_BROKER_MANAGER,
  ROLES.BROKER_MANAGER,
  ROLES.BROKER,
  ROLES.PROCESSOR,
];
