import React, { useContext, useMemo, useState } from 'react';
import { Button, Grid, LinearProgress, Paper } from '@mui/material';
import { TableColumnType } from 'antd';
import { DataTable, RowRecord } from '@lola/ui-react-components';
import { Collapsed } from '@components/Collapsed';
import { LoanPartyInfo } from '@typings/common';
import { usePayment } from '@pages/personalLoans/pages/buildMyLoan/components/Payment/hooks/usePayment';
import { PAYMENT_STATUS_TYPE } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import styles from './payment.module.scss';

export interface PaymentPartiesProps {
  columns: TableColumnType<LoanPartyInfo>[];
  parties: LoanPartyInfo[];
  description: React.ReactNode;
  paymentRateValue: number;
  productCode: string;
  isStatusLoading: boolean;
  isLoanSubmitted?: boolean;
  disabled?: boolean;
  isExpanded: boolean;
  refetchStatuses: () => void;
  setIsExpanded: () => void;
}

export const Payment = ({
  columns,
  parties,
  description,
  paymentRateValue,
  productCode,
  disabled,
  isStatusLoading,
  isExpanded,
  refetchStatuses,
  setIsExpanded,
}: PaymentPartiesProps) => {
  const { disabled: disabledButtons } = useContext(LoanDisableContext);
  const [selectedParties, setSelectedParties] = useState<RowRecord[]>([]);
  const { payByLink, sendPaymentLinks, errorPartyIds, isLoading } = usePayment(
    selectedParties as LoanPartyInfo[],
    productCode,
    refetchStatuses
  );

  const paymentPartiesWithStatus = useMemo(
    () =>
      parties?.map((party) => ({
        ...party,
        status:
          party.id && errorPartyIds.includes(party.id)
            ? PAYMENT_STATUS_TYPE.ERROR
            : party.status,
      })),
    [parties, errorPartyIds]
  );

  const isPaidForAllParties = useMemo(
    () =>
      paymentPartiesWithStatus
        ? paymentPartiesWithStatus.every(
            ({ status }) => status === PAYMENT_STATUS_TYPE.PAID
          )
        : false,
    [paymentPartiesWithStatus]
  );

  return (
    <Paper>
      <Collapsed
        label="Payment"
        disabled={disabled}
        expanded={isExpanded}
        onChange={setIsExpanded}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {description}
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} classes={{ root: styles.tableWrapper }}>
              {!!paymentPartiesWithStatus?.length && (
                <DataTable
                  rowSelection={
                    disabledButtons
                      ? undefined
                      : {
                          type: 'checkbox',
                          selectedRowKeys: selectedParties.map(
                            (party) => party.id
                          ),
                        }
                  }
                  rowKey="id"
                  scroll={{
                    x: 700,
                  }}
                  columns={columns}
                  dataSource={paymentPartiesWithStatus}
                  showHeader={false}
                  onSelectedChange={setSelectedParties}
                />
              )}
              {(isLoading || isStatusLoading) && <LinearProgress />}
            </Paper>
            <div className={styles.buttons}>
              <Button
                variant="contained"
                size="large"
                disabled={
                  disabledButtons ||
                  !selectedParties.length ||
                  isPaidForAllParties
                }
                onClick={payByLink}
              >
                Pay by Stripe ($
                {isPaidForAllParties
                  ? 0
                  : selectedParties.length * paymentRateValue}
                )
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={sendPaymentLinks}
                disabled={
                  disabledButtons ||
                  !selectedParties.length ||
                  isPaidForAllParties
                }
              >
                Send payment link
              </Button>
            </div>
          </Grid>
        </Grid>
      </Collapsed>
    </Paper>
  );
};
