import { useForm } from 'react-hook-form';
import { QUOTE_TYPE, ROLES } from '@typings/common';
import {
  usePostApiV1LoansMutation,
  usePostApiV1LoansCreateFromQuoteMutation,
  LolaBffApiContractsModelsRequestsCreateNewLoanRequest,
  LolaBffApiContractsModelsRequestsCreateLoanFromQuoteRequest,
} from '@api/output/api';
import { CreateFormFields } from '@pages/createLoan/CreateLoan.constants';

interface SubmitLoanFormParams {
  methods: ReturnType<typeof useForm<CreateFormFields>>;
  role: ROLES;
  createLoan: ReturnType<typeof usePostApiV1LoansMutation>[0];
  createLoanFromQuote: ReturnType<
    typeof usePostApiV1LoansCreateFromQuoteMutation
  >[0];
  quoteType?: QUOTE_TYPE;
  quoteId: number;
}

export const submitLoanForm = ({
  methods,
  role,
  createLoan,
  createLoanFromQuote,
  quoteType,
  quoteId,
}: SubmitLoanFormParams) => {
  return methods.handleSubmit((values: CreateFormFields) => {
    const userKey:
      | keyof LolaBffApiContractsModelsRequestsCreateNewLoanRequest
      | keyof LolaBffApiContractsModelsRequestsCreateLoanFromQuoteRequest =
      role === ROLES.PROCESSOR ? 'brokerUserId' : 'processorUserId';

    const commonBody = {
      borrowerId: values.borrowerId,
      [userKey]: values.userId,
    };

    if (quoteType) {
      if (isNaN(quoteId)) return;
      createLoanFromQuote({
        lolaBffApiContractsModelsRequestsCreateLoanFromQuoteRequest: {
          ...commonBody,
          quoteId,
        },
      });
    } else {
      createLoan({
        lolaBffApiContractsModelsRequestsCreateNewLoanRequest: {
          ...commonBody,
          loanProgramType: values.loanProgramType ?? '',
        },
      });
    }
  });
};
