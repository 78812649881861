import { getNumberPostfix } from '@utils/text.utils';
import { QUOTE_TYPE } from '@typings/common';
import { global } from '@config/global';
import { TitleConfig } from './QuoteForm.types';

interface GetTitleArg {
  count: number;
  titleConfig: TitleConfig;
}

export const getTitle = ({ count, titleConfig }: GetTitleArg) => {
  return titleConfig.withoutCount
    ? getNumberPostfix(count, titleConfig.singular, titleConfig.plural)
    : `${count} ${getNumberPostfix(
        count,
        titleConfig.singular,
        titleConfig.plural
      )}`;
};

export const getPdfEndpoint = (
  quoteId?: string,
  formQuoteType?: QUOTE_TYPE
) => {
  const pdfEndpointsMap: { [key in QUOTE_TYPE]?: string } = {
    [QUOTE_TYPE.RENTAL]: `/api/v1/pricing-engine/quote/${quoteId}/rental/pdf`,
    [QUOTE_TYPE.SHORT_TERM]: `/api/v1/pricing-engine/quote/${quoteId}/stf/pdf`,
  };

  if (!formQuoteType) {
    throw new Error('Form quote type is not defined');
  }

  const endpoint = pdfEndpointsMap[formQuoteType];
  if (!endpoint) {
    throw new Error('Invalid form quote type');
  }

  return `${global.bffApiUrl}${endpoint}`;
};
