import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { AnyAction } from '@reduxjs/toolkit';
import {
  AddIcon,
  InboxIcon,
  DataTable,
  filterTeamsOptionLabelRenderer,
  useTableDataSearchAndFilter,
} from '@lola/ui-react-components';
import { ContentSection } from '@components/ContentSection';
import { useRole } from '@hooks/useRole';
import {
  api,
  GetApiV1DashboardActionsForTodayApiResponse,
  LolaBffApiContractsModelsDashboardActionsLoanActionDto,
  useLazyGetApiV1DashboardActionsForTodayQuery,
} from '@api/output/api';
import { DataControlPanel } from '@components/DataTableControlPanel/DataTableControlPanel.component';
import { ROLES } from '@typings/common';
import { NoContent } from '@components/NoContent';
import { useOpenState } from '@hooks/useOpenState';
import { getActionForTodayById } from '@store/selectors/actionsForToday.selectors';
import { FileItem } from '@components/Uploader/Uploader.types';
import { TaskFilesUploadDrawer } from '@containers/TaskFilesUploadDrawer/TaskFilesUploadDrawer.component';
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import {
  ACTION_CONFIG_INDEX,
  COLUMN_CONFIG,
  DRAWER_CONFIG,
  UPLOADER_CONFIG,
} from './actionsForTodayWidget.constants';
import { createActionRenderer } from './renderers/actions';
import styles from './actionsForTodayWidget.module.scss';

export const ActionsForTodayWidget = () => {
  const dispatch = useDispatch();
  const [uploadDrawerState, setUploadDrawerOpen, setUploadDrawerClose] =
    useOpenState(false);
  const [currentActiveUploaderTaskId, setCurrentActiveUploaderTaskId] =
    useState<string>('');
  const [currentUploaderFiles, setCurrentUploaderFiles] = useState<FileItem[]>(
    []
  );
  const currentActiveActionForToday = useSelector((state) =>
    getActionForTodayById(state, currentActiveUploaderTaskId)
  );
  const [loadRows, { data, isFetching, isError }] =
    useLazyGetApiV1DashboardActionsForTodayQuery();
  const role = useRole();

  // To prevent scroll issues when items with nullish loanNumber provided
  // Currently loan with nullish loanNumber break table scroll
  // TODO: Remove for production when created loan is guaranteed to have non-nullish unique value
  const correctedData = useMemo(
    () => (data ? data.filter(({ loanNumber }) => !!loanNumber) : []),
    [data]
  );

  const {
    filteredData,
    handleSearchChange,
    handleFilterChange,
    appliedFilters,
    filters,
    //TODO fix in https://dev.azure.com/limaonecapital/LOLA/_workitems/edit/4562
  } =
    useTableDataSearchAndFilter<LolaBffApiContractsModelsDashboardActionsLoanActionDto>(
      correctedData,
      {
        step: {
          values: [],
          title: 'Step',
        },
        brokerName: {
          values: [],
          title: 'Loan officer',
        },
        brokerTeam: {
          values: [],
          optionLabelRenderer: filterTeamsOptionLabelRenderer,
          title: 'Team',
        },
        displayedTaskName: {
          values: [],
          title: 'Actions',
        },
      },
      'addressLine',
      ''
    );

  useEffect(() => {
    loadRows();
  }, [loadRows]);

  const onActionClick = useCallback(
    (data: LolaBffApiContractsModelsDashboardActionsLoanActionDto) => {
      setCurrentActiveUploaderTaskId(data.displayedTaskId || '');
      setUploadDrawerOpen();
    },
    [setCurrentActiveUploaderTaskId]
  );

  const uploaderCloseHandler = useCallback(() => {
    if (currentUploaderFiles.length) {
      dispatch(
        api.util.updateQueryData(
          'getApiV1DashboardActionsForToday',
          undefined,
          (draft: GetApiV1DashboardActionsForTodayApiResponse) => {
            const indexToDelete = draft.findIndex(
              (actionItem) =>
                actionItem.displayedTaskId === currentActiveUploaderTaskId
            );
            draft.splice(indexToDelete, 1);
          }
        ) as unknown as AnyAction
      );
      setCurrentUploaderFiles([]);
    }
    setCurrentActiveUploaderTaskId('');
    setUploadDrawerClose();
  }, [
    loadRows,
    currentUploaderFiles,
    setCurrentActiveUploaderTaskId,
    setCurrentUploaderFiles,
    setUploadDrawerClose,
  ]);

  const columnsConfig = useMemo(() => {
    const currentConfig = [...COLUMN_CONFIG[role]];
    const actionIndex = ACTION_CONFIG_INDEX[role];
    currentConfig[actionIndex] = {
      ...currentConfig[actionIndex],
      render: createActionRenderer(onActionClick),
    };
    return currentConfig;
  }, [role, onActionClick]);

  if (isError) {
    return (
      <ContentSection title="ACTIONS FOR TODAY" isLoading={isFetching}>
        <ErrorNoticeFetchRequest />
      </ContentSection>
    );
  }

  return (
    <>
      <ContentSection title="ACTIONS FOR TODAY" isLoading={isFetching}>
        {data?.length ? (
          <>
            {role === ROLES.PROCESSOR && (
              <DataControlPanel
                appliedFilters={appliedFilters}
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleSearchChange={handleSearchChange}
                className={styles.dataControlPanel}
                title="Filters"
              />
            )}
            <DataTable
              rowKey="loanNumber"
              scroll={{
                x: 900,
                y: 600,
              }}
              columns={columnsConfig}
              mode={role === ROLES.PROCESSOR ? 'wide' : undefined}
              //TODO fix in https://dev.azure.com/limaonecapital/LOLA/_workitems/edit/4562
              dataSource={filteredData}
              className={styles.table}
            />
          </>
        ) : (
          <NoContent
            title="No data to show here yet"
            text="This block shows actions that you need to perform to proceed with the loan flow.
          To start your first loan, press the button below."
            icon={<InboxIcon />}
            variant="grey"
          >
            <Link component={NavLink} to="/new-loan" underline="none">
              <Button variant="contained" startIcon={<AddIcon />} size="large">
                Start a loan
              </Button>
            </Link>
          </NoContent>
        )}
      </ContentSection>
      <TaskFilesUploadDrawer
        isOpen={uploadDrawerState}
        onClose={uploaderCloseHandler}
        uploadedFiles={currentUploaderFiles}
        uploaderConfig={UPLOADER_CONFIG}
        taskId={currentActiveUploaderTaskId}
        drawerConfig={{
          ...DRAWER_CONFIG,
          title: `${
            currentActiveActionForToday?.displayedTaskName || ''
          } document`,
        }}
      />
    </>
  );
};
