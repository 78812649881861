import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import DOMPurify from 'dompurify';
import omit from 'lodash/omit';
import { TypographyProps } from '@form-configs/types';
import { FormConfigContext } from '@components/DynamicForm/contexts/FormConfig.context';
import styles from './dynamicTypography.module.scss';

const createMarkup = (htmlString: string) => {
  return { __html: DOMPurify.sanitize(htmlString) };
};

const renderContent = (label: string) => {
  if (label.includes('<')) {
    return <span dangerouslySetInnerHTML={createMarkup(label)} />;
  }
  return label;
};

export const DynamicTypography = ({ label, ...props }: TypographyProps) => {
  const typographyProps = omit(props, 'controlType');
  const { disabled } = useContext(FormConfigContext);

  return (
    <Typography
      {...typographyProps}
      className={classnames({
        [styles.disabled]: disabled,
      })}
    >
      {renderContent(label)}
    </Typography>
  );
};
