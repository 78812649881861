export type ListDetailsType = {
  step: string;
  lastChange?: string;
  lastChangeLabel?: string | null;
};

export type CopyText = {
  title: string;
  text: string;
  listDetails: ListDetailsType[];
  exceptionButton: string;
  archiveButton: string;
};

const copyDetails: CopyText = {
  title: 'Review status',
  text: 'This page will show you the progress and the results of your initial quote review.',
  listDetails: [
    {
      step: 'Submitted',
      lastChange: 'Done on Nov 29',
      lastChangeLabel: null,
    },
    {
      step: 'In review',
      lastChange: 'Started on Nov 29',
      lastChangeLabel: '(more conditions required)',
    },
    {
      step: 'New conditions',
      lastChange: 'Started on Nov 29',
      lastChangeLabel: null,
    },
    {
      step: 'New conditions 2',
      lastChange: 'Started on Nov 29',
      lastChangeLabel: null,
    },
    {
      step: 'New conditions 3',
      lastChange: 'Started on Nov 29',
      lastChangeLabel: null,
    },
  ],
  exceptionButton: 'Exception request',
  archiveButton: 'Archive a loan',
};

export default copyDetails;
