import React from 'react';
import { LolaBffApiContractsModelsLicensesLicense } from '@api/output/api';
import { LicensingActions } from '../../LicensingActions/LicensingActions.component';

type OpenModalArg = (
  arg: LolaBffApiContractsModelsLicensesLicense
) => () => void;
type OpenDrawerArg = (
  arg: LolaBffApiContractsModelsLicensesLicense
) => () => void;

export const createActionsRenderer =
  (openModal: OpenModalArg, openDrawer: OpenDrawerArg) =>
  (_: unknown, row: LolaBffApiContractsModelsLicensesLicense) => {
    return (
      <LicensingActions
        openModal={openModal(row)}
        openDrawer={openDrawer(row)}
      />
    );
  };
