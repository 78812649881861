import React from 'react';
import { Paper } from '@mui/material';
import { TableColumnType } from 'antd';
import { DataTable } from '@lola/ui-react-components';
import { LolaBffApiContractsModelsDocumentLockerDocumentInfo } from '@api/output/api';
import styles from './documentsList.module.scss';

export interface DocumentsListProps {
  columns: TableColumnType<LolaBffApiContractsModelsDocumentLockerDocumentInfo>[];
  data?: LolaBffApiContractsModelsDocumentLockerDocumentInfo[];
}

export const DocumentsList = ({ columns, data }: DocumentsListProps) => {
  return (
    <Paper elevation={0} classes={{ root: styles.paper }}>
      <DataTable
        rowKey="fileId"
        scroll={{
          x: 900,
          y: 450,
        }}
        columns={columns}
        dataSource={data}
      />
    </Paper>
  );
};
