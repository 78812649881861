import { Format, SectionsConfig } from '@utils/quotes.utils';
import { LeverageFormSectionConfig } from './LeverageCalculations.type';

export const LEVERAGE_FORM_SECTIONS_CONFIGS: LeverageFormSectionConfig[] = [
  {
    title: 'Blended LTC calculation',
    tooltip:
      "Based on Property Acquisition and Construction figures, a flat leverage ratio is applied to the blended 'Total Cost'",
    adjustLeverageLabel: 'Adjust Blended LTC',
    adjustLeverageDescriptionRenderer: (quoteDetails) =>
      `Current Blended LTC: ${
        quoteDetails?.in?.leverage?.adjustedBlendedLTC ||
        quoteDetails?.out?.blendedLTCCalc?.maxBlendedLTCRatio
      }%`,
    valueProperty: 'in.leverage.adjustedBlendedLTC',
    maxValueProperty: 'out.blendedLTCCalc.maxBlendedLTCRatio',
    items: [
      {
        key: 'blended-total-cost',
        title: 'Total Cost',
        value: 'out.blendedLTCCalc.totalCost',
        format: Format.Currency,
      },
      {
        key: 'blended-max-ltc-leverage',
        title: 'Max Blended LTC Leverage',
        value: 'out.blendedLTCCalc.maxBlendedLTCRatio',
        format: Format.Percent,
      },
      {
        key: 'blended-max-loan-amount',
        title: 'Max. Loan Amount',
        value: 'out.blendedLTCCalc.blendedLTCMaxLoan',
        format: Format.Currency,
      },
    ],
  },
  {
    title: 'LTC Calculation on Purchase + Rehab',
    adjustLeverageLabel: 'Adjust Purchase LTC',
    adjustLeverageDescriptionRenderer: (quoteDetails) =>
      `Current LTC on Purchase: ${
        quoteDetails?.in?.leverage?.adjustedPurchaseLTC ||
        quoteDetails?.out?.ltcCalcOnPurchaseRehab?.maxLTCOnPurchase
      }%`,
    valueProperty: 'in.leverage.adjustedPurchaseLTC',
    maxValueProperty: 'out.ltcCalcOnPurchaseRehab.maxLTCOnPurchase',
    items: [
      {
        key: 'purchase-max-ltc-leverage',
        title: 'Max. Purchase Cost Leverage',
        value: 'out.ltcCalcOnPurchaseRehab.maxLTCOnPurchase',
        format: Format.Percent,
      },
      {
        key: 'construction-budget-leverage',
        title: 'Max. Construction Budget Leverage',
        value: 'out.ltcCalcOnPurchaseRehab.ltcConBudget',
        format: Format.Percent,
      },
      {
        key: 'purchase-rehab-max-loan',
        title: 'Purchase+Rehab Max Loan',
        value: 'out.ltcCalcOnPurchaseRehab.ltcCalcRehabMaxLoan',
        format: Format.Currency,
      },
    ],
  },
  {
    title: 'LTV/ARV Calculation',
    adjustLeverageLabel: 'Adjust ARV',
    adjustLeverageDescriptionRenderer: (quoteDetails) =>
      `Current ARV: ${
        quoteDetails?.in?.leverage?.adjustedARV ||
        quoteDetails?.out?.ltvarvCalc?.maxLTVRatio
      }%`,
    valueProperty: 'in.leverage.adjustedARV',
    maxValueProperty: 'out.ltvarvCalc.maxLTVRatio',
    items: [
      {
        key: 'after-completed-value',
        title: 'After completed value',
        value: 'out.ltvarvCalc.afterCompletedVal',
        format: Format.Currency,
      },
      {
        key: 'max-ltv-ltarv-leverage',
        title: 'Max. LTV/LTARV Leverage',
        value: 'out.ltvarvCalc.maxLTVRatio',
        format: Format.Percent,
      },
      {
        key: 'ltv-ltarv-max-loan',
        title: 'LTV /LTARV Max. Loan',
        value: 'out.ltvarvCalc.ltvMaxLoan',
        format: Format.Currency,
      },
    ],
  },
];

export const FINAL_LOAN_SECTIONS_CONFIGS: SectionsConfig = [
  {
    title: 'Final loan amount',
    items: [
      {
        key: 'total-loan-amount',
        title: 'Total loan amount',
        value: 'out.ltcCalcOnPurchaseRehab.loanAmt',
        format: Format.Currency,
      },
      {
        key: 'initially-funded-loan-amount',
        title: 'Initially funded loan amount',
        value: 'out.ltcCalcOnPurchaseRehab.initFundedLoanAmt',
        format: Format.Currency,
      },
    ],
  },
];
