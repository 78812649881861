import React, { ComponentType, useCallback, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { AddIcon, TeamIcon } from '@lola/ui-react-components';
import { Modal } from '@components/Modal';
import { Portal } from '@components/Portal';
import { ROLES } from '@typings/common';
import { useRole } from '@hooks/useRole';
import { InviteDrawer } from '@pages/teamManagement/components';
import { ModalContent } from '../ModalContent/ModalContent.component';
import {
  MODAL_STATE,
  MODAL_TITLES,
  ModalContentType,
} from '../../TeamMembers.constants';
import { PORTAL_TEAM_MEMBERS_ACTIONS_ID } from '../../../../TeamManagement.constants';

export const withTableActions = <P,>(
  WrappedComponent: ComponentType<P>
): ComponentType<P> => {
  return (props: P) => {
    const role = useRole();
    const [inviteDrawerOpened, setDrawerOpened] = useState(false);
    const [modalContent, setModalContentState] = useState<ModalContentType>({
      state: MODAL_STATE.CLOSE,
    });

    const handleModalClose = useCallback(
      () => setModalContentState({ state: MODAL_STATE.CLOSE }),
      []
    );

    const handleDrawerOpen = useCallback(() => setDrawerOpened(true), []);

    const managerButtons = useMemo(() => {
      if (role === ROLES.KEY_BROKER_MANAGER || role === ROLES.BROKER_MANAGER) {
        return (
          <>
            <Button
              variant="outlined"
              startIcon={<TeamIcon />}
              size="large"
              onClick={() =>
                setModalContentState({ state: MODAL_STATE.CREATE })
              }
            >
              Create new team
            </Button>
            <Button
              variant="contained"
              size="large"
              startIcon={<AddIcon />}
              onClick={handleDrawerOpen}
            >
              Invite Member
            </Button>
          </>
        );
      }
    }, [role, setModalContentState]);

    return (
      <>
        <Portal id={PORTAL_TEAM_MEMBERS_ACTIONS_ID}>{managerButtons}</Portal>
        <WrappedComponent
          {...props}
          setModalContent={setModalContentState}
          onInviteMemberClick={handleDrawerOpen}
        />
        <Modal
          isOpen={modalContent.state !== MODAL_STATE.CLOSE}
          title={MODAL_TITLES[modalContent.state]}
          onClose={handleModalClose}
        >
          <ModalContent
            content={modalContent}
            setModalContent={setModalContentState}
          />
        </Modal>
        <InviteDrawer
          open={inviteDrawerOpened}
          onClose={() => setDrawerOpened(false)}
        />
      </>
    );
  };
};
