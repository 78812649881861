import React, { useMemo } from 'react';
import { Button, Grid } from '@mui/material';
import { InputControl } from '@lola/ui-react-components';
import { GridHelperText } from '@components/GridHelperText/GridHelperText.component';
import { DynamicFields } from '@components/DynamicForm';
import { QUOTE_TYPE } from '@typings/common';
import { forms } from '@form-configs/output';
import { PartnerEconomics } from '@components/PartnerEconomics/PartnerEconomics.component';
import { CalculationErrors } from '@pages/scenarioBuilder/pages/quoteForm/components/CalculationErrors/CalculationErrors.component';
import { FieldProp } from '@components/DynamicForm/contexts/FieldsProps.context';
import { convertConfigToFieldProps } from '@components/DynamicForm/utils';
import styles from './quoteFields.module.scss';

const QUOTE_NAME = 'quoteName';

export interface QuoteFieldsProps {
  quoteType: QUOTE_TYPE;
  isCalculating?: boolean;
  isCalculated?: boolean;
  isDirty?: boolean;
  errors?: string[];
  disabled?: boolean;
  disabledByCheck?: boolean;
  milestoneFieldConfig?: Record<string, FieldProp[]>;
}

export const QuoteFields = ({
  errors,
  quoteType,
  isDirty,
  isCalculated,
  isCalculating,
  disabled = false,
  disabledByCheck,
  milestoneFieldConfig,
}: QuoteFieldsProps) => {
  const fieldSpecificProps = useMemo(
    () => convertConfigToFieldProps(milestoneFieldConfig?.[QUOTE_NAME] ?? []),
    [milestoneFieldConfig]
  );

  return (
    <Grid container spacing={2}>
      <CalculationErrors errors={errors} />
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} data-cy="quote-name">
          <InputControl
            name={QUOTE_NAME}
            type="text"
            variant="filled"
            placeholder="Enter data"
            label="Enter quote name"
            required="Quote Name is required"
            disabled={disabled || disabledByCheck}
            className={styles.quoteName}
            {...fieldSpecificProps}
          />
        </Grid>
        <GridHelperText helperText="Best to use a unique name for each quote" />
      </Grid>
      {quoteType === QUOTE_TYPE.SHORT_TERM && (
        <Grid item xs={12}>
          <DynamicFields
            fields={forms.shortTerm}
            fieldsProps={milestoneFieldConfig}
          />
        </Grid>
      )}
      {quoteType === QUOTE_TYPE.RENTAL && (
        <Grid item xs={12}>
          <DynamicFields
            fields={forms.rental}
            fieldsProps={milestoneFieldConfig}
          />
        </Grid>
      )}
      {quoteType && (
        <Grid item xs={12}>
          <PartnerEconomics quoteType={quoteType} disabled={disabled} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          disabled={isCalculating || !isDirty}
          data-cy="calculate-button"
        >
          {isCalculated ? 'Recalculate' : 'Calculate'}
        </Button>
      </Grid>
    </Grid>
  );
};
