import React from 'react';
import { Grid, LinearProgress, Paper } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CardSection } from '@lola/ui-react-components';
import { LolaBffApiContractsModelsLoanLoanProperty } from '@api/output/api';
import { BuildMyLoanUploaderDrawer } from '@pages/personalLoans/pages/buildMyLoan/components/BuildMyLoanUploaderDrawer/BuildMyLoanUploaderDrawer.component';
import { getTemplateString } from '@utils/text.utils';
import { useUploadActiveTask } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUploadActiveTask';
import { TASK_SECTIONS } from '@typings/common';
import { AppraisalItem } from './components/AppraisalItem/AppraisalItem.component';
import {
  ORDER_APPRAISAL_DRAWER_CONFIG,
  ORDER_APPRAISAL_UPLOADER_CONFIG,
} from './OrderAppraisal.constants';

export const OrderAppraisalPage = () => {
  const {
    activeTask,
    tasks,
    uploadDrawerHandler,
    uploadDrawerState,
    uploaderCloseHandler,
    templates,
    templatesAreBeingFetched,
  } = useUploadActiveTask(TASK_SECTIONS.APPRAISAL);

  const { watch } = useFormContext();
  const properties: LolaBffApiContractsModelsLoanLoanProperty[] =
    watch('properties') ?? [];

  const [firstProperty, ...restProperties] = properties;

  if (!properties.length) {
    return <LinearProgress />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardSection
            title="Order appraisal"
            description="Please upload the appraisal you have on your property. We will review it after you submit a loan to underwriting"
          >
            <AppraisalItem
              isSingle={!restProperties.length}
              label={getTemplateString([
                'Property #1',
                firstProperty?.address?.addressLineText as string,
              ])}
              tasks={tasks[firstProperty?.id as string] ?? []}
              uploadDrawerHandler={uploadDrawerHandler}
            />
          </CardSection>
        </Grid>
        {!!restProperties?.length &&
          restProperties.map(({ id, address }, index) => (
            <Grid item xs={12} key={id}>
              <Paper elevation={2}>
                <AppraisalItem
                  label={getTemplateString([
                    `Property #${index + 2}`,
                    address?.addressLineText as string,
                  ])}
                  tasks={tasks[id as string] ?? []}
                  uploadDrawerHandler={uploadDrawerHandler}
                />
              </Paper>
            </Grid>
          ))}
      </Grid>
      <BuildMyLoanUploaderDrawer
        isOpen={uploadDrawerState}
        onClose={uploaderCloseHandler}
        task={activeTask}
        uploaderConfig={ORDER_APPRAISAL_UPLOADER_CONFIG}
        drawerConfig={ORDER_APPRAISAL_DRAWER_CONFIG}
        templates={templates}
        templatesAreBeingFetched={templatesAreBeingFetched}
      />
    </>
  );
};
