import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import {
  LolaBffApiContractsModelsLoanDocumentTask,
  useLazyGetApiV1LoansByLoanIdDocumentTasksQuery,
} from '@api/output/api';

export type UseTasksBySectionReturnValue = [
  LolaBffApiContractsModelsLoanDocumentTask[] | undefined,
  () => Promise<void>
];

export const useTasksBySection = (
  sectionName: string
): UseTasksBySectionReturnValue => {
  const { loanId = '' } = useParams();
  const [fetch, { data = [] }] =
    useLazyGetApiV1LoansByLoanIdDocumentTasksQuery();

  const reload = useCallback(async () => {
    await fetch({ loanId }).unwrap();
  }, [fetch, loanId]);

  useEffect(() => {
    fetch({ loanId });
  }, [loanId]);

  return useMemo(() => {
    const items =
      data.find(({ section }) => section === sectionName)?.documentTasks ??
      undefined;

    return [items, reload];
  }, [sectionName, data, reload]);
};
