import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { GridHelperText } from '@components/GridHelperText/GridHelperText.component';
import { LolaBffApiContractsModelsUser } from '@api/output/api';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { SelectManager } from '@pages/teamManagement/components/SelectManager/SelectManager.component';
import { SelectDestinationTeam } from '../../../SelectDestinationTeam/SelectDestinationTeam.component';
import styles from './brokerManagerForm.module.scss';

interface BrokerManagerFormProps {
  fullName: LolaBffApiContractsModelsUser['fullName'];
  userTeams: LolaBffApiContractsModelsUser['managesTeams'];
}

export const BrokerManagerForm = ({
  fullName,
  userTeams,
}: BrokerManagerFormProps) => {
  const teams = useMemo(
    () =>
      userTeams?.map((teamId) => ({
        label: `Team ${teamId}`,
        value: teamId,
      })),
    [userTeams]
  );

  return (
    <Grid container spacing={'20px'}>
      <Grid item xs={12}>
        <LabeledCard label={`Remove ${fullName} from the Team`}>
          {teams && teams.length > 1 && (
            <SelectControl
              name="removeFromTeam"
              label={`Select the team from which you want to remove ${fullName}`}
              required="Team is required"
              options={teams}
            />
          )}
          <div className={styles.managerSelector}>
            <SelectManager
              label={`Select manager who will lead the team instead of ${fullName}`}
              required="Manager is required"
            />
            <GridHelperText helperText="You can’t keep a team without a manager." />
          </div>
        </LabeledCard>
      </Grid>
      <Grid item xs={12}>
        <LabeledCard label="Add a manager to new team">
          <SelectDestinationTeam
            label={`Select the team to which you want to add ${fullName}`}
            showHelperText
          />
        </LabeledCard>
      </Grid>
    </Grid>
  );
};
