import {
  COMPLETION_STATUS,
  IN_PROGRESS_STATUS,
} from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useBuildMyLoan.constants';

export const CONSTRUCTION_INSPECTION_FIELDS = [
  'properties[0].appraisalManagementCompany.representativeFullName',
  'properties[0].appraisalManagementCompany.contactPointTelephoneValue',
  'properties[0].appraisalManagementCompany.contactPointEmailValue',
];

export enum CONSTRUCTION_INSPECTION_TASK {
  ASSIGNED = 'construction_inspection.assigned',
  INSPECTION = 'construction_inspection.inspection',
}

export const ESTIMATED_DAYS_TO_COMPLETE = 7;

export enum CONSTRUCTION_STEP {
  ASSIGNED = 'Inspection assigned',
  IN_PROGRESS = 'Inspection in progress',
  COMPLETED = 'Completed',
}

export interface ProgressStepConfig {
  step: CONSTRUCTION_STEP;
  taskName: CONSTRUCTION_INSPECTION_TASK;
  inProgressTaskStatus: string | null;
  completeStatus: string;
}

export const PROGRESS_STEPS_CONFIG: ProgressStepConfig[] = [
  {
    step: CONSTRUCTION_STEP.ASSIGNED,
    taskName: CONSTRUCTION_INSPECTION_TASK.ASSIGNED,
    inProgressTaskStatus: IN_PROGRESS_STATUS,
    completeStatus: COMPLETION_STATUS,
  },
  {
    step: CONSTRUCTION_STEP.IN_PROGRESS,
    taskName: CONSTRUCTION_INSPECTION_TASK.INSPECTION,
    inProgressTaskStatus: IN_PROGRESS_STATUS,
    completeStatus: COMPLETION_STATUS,
  },
  {
    step: CONSTRUCTION_STEP.COMPLETED,
    taskName: CONSTRUCTION_INSPECTION_TASK.INSPECTION,
    inProgressTaskStatus: null,
    completeStatus: COMPLETION_STATUS,
  },
];
