import { useMemo } from 'react';
import { useTasksBySection } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTasksBySection';
import { useTasksConfiguration } from '@pages/personalLoans/hooks/useTasksConfiguration';
import { LolaBffApiContractsModelsLoanDocumentTask } from '@api/output/api';
import { CONFIGURED_TASKS, TASK_SECTIONS } from '@typings/common';

export const useTasksByName = (
  section: TASK_SECTIONS,
  taskKey: CONFIGURED_TASKS
): LolaBffApiContractsModelsLoanDocumentTask[] | undefined => {
  const { data: configuration } = useTasksConfiguration();
  const [items] = useTasksBySection(section);

  return useMemo(() => {
    if (!configuration || !items) {
      return;
    }

    const configurationId =
      configuration.taskMapping?.[taskKey]?.taskConfigurationId;

    return items.filter(
      (item) => item.task?.taskId?.indexOf(`${configurationId}_`) === 0
    );
  }, [configuration, items]);
};
