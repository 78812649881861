import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { CommonError, SetModalContent } from '@typings/common';
import { useOpenToast } from '@hooks/useOpenToast';
import {
  LolaBffApiContractsModelsLoanLoanView,
  useLazyGetApiV1LoansQuery,
  usePostApiV1LoansAssignProcessorMutation,
} from '@api/output/api';
import { ConfirmModal } from '@components/Modal';
import {
  ASSIGN_PROCESSOR_BULK_FORM_NAME,
  MODAL_STATE,
  ModalContentType,
} from '../../../../Loans.constants';

interface AssignProcessorConfirmProps {
  setModalContent: SetModalContent<ModalContentType>;
}

export const AssignProcessorConfirm = ({
  setModalContent,
}: AssignProcessorConfirmProps) => {
  const [load, { isFetching }] = useLazyGetApiV1LoansQuery();
  const [assignProcessor, { isLoading }] =
    usePostApiV1LoansAssignProcessorMutation();

  const { handleSubmit, resetField } = useFormContext();

  const handleCancel = useCallback(() => {
    resetField(ASSIGN_PROCESSOR_BULK_FORM_NAME);
    setModalContent({ state: MODAL_STATE.CLOSE });
  }, [resetField, setModalContent]);

  const openToast = useOpenToast();

  const handleSubmitClick = useCallback(
    handleSubmit((values) => {
      const { assignProcessorBulk, selectedLoans } = values;
      assignProcessor({
        lolaBffApiContractsModelsLoanAssignLoansToProcessorRequest: {
          loanIds: selectedLoans.map(
            ({ id }: LolaBffApiContractsModelsLoanLoanView) => id
          ),
          processorUserId: assignProcessorBulk.processorId,
        },
      })
        .unwrap()
        .then(() => load())
        .then(() => {
          openToast({
            title: 'Changes applied!',
            description: 'Your changes have been applied.',
          });
          handleCancel();
        })
        .catch((err: CommonError) => {
          openToast({
            isError: true,
            title: 'Error occurred',
            description: err.message,
          });
          handleCancel();
        });
    }),
    [handleSubmit, openToast]
  );

  return (
    <ConfirmModal
      isUpdating={isLoading || isFetching}
      handleSubmit={handleSubmitClick}
      handleCancel={handleCancel}
    />
  );
};
