import React, { SyntheticEvent, useCallback } from 'react';
import { Button, Grid, Paper } from '@mui/material';
import { ActionBlock, InformationIcon } from '@lola/ui-react-components';
import { Collapsed } from '@components/Collapsed';
import {
  QUOTE_TYPE,
  QUOTE_ACCORDION_VALUES,
  QuoteSummaryItem,
} from '@typings/common';
import {
  LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse,
  LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteByIdResponse,
} from '@api/output/api';
import { QuoteSummary } from '@hooks/useQuotesApi/utils/getQuoteSummary.utils';
import { SHORT_TERM_CALCULATION_SECTIONS_CONFIGS } from '@constants/shortTermCalculationsConfigs.constants';
import { DefaultScenarioBuilderCalculation } from './components/DefaultScenarioBuilderCalculation/DefaultScenarioBuilderCalculation.component';
import { ScenarioRentalCalculation } from './components/ScenarioRentalCalculation/ScenarioRentalCalculation.component';
import styles from './quoteCalculation.module.scss';

export interface QuoteCalculationProps {
  quoteType: QUOTE_TYPE;
  accordionValue: QUOTE_ACCORDION_VALUES | null;
  onChange: (
    panel: QUOTE_ACCORDION_VALUES
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
  calculationErrors: string[];
  warnings: QuoteSummaryItem[];
  setAccordionValue?: (value: QUOTE_ACCORDION_VALUES | null) => void;
  details?:
    | LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteByIdResponse
    | LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse;
  quoteSummary?: QuoteSummary;
  isCalculating?: boolean;
  disabled?: boolean;
  isStaged?: boolean;
  showStageInfoBlock?: boolean;
}

export const QuoteCalculation = ({
  details,
  quoteType,
  accordionValue,
  onChange,
  setAccordionValue,
  isCalculating,
  disabled,
  isStaged,
  showStageInfoBlock = true,
}: QuoteCalculationProps) => {
  const recalculateActionHandler = useCallback(() => {
    if (setAccordionValue) {
      setAccordionValue(QUOTE_ACCORDION_VALUES.DETAILS);
    }
  }, [setAccordionValue]);

  return (
    <Paper elevation={2}>
      <Collapsed
        label="Calculation"
        disabled={!details}
        expanded={
          !!details && accordionValue === QUOTE_ACCORDION_VALUES.CALCULATION
        }
        onChange={onChange(QUOTE_ACCORDION_VALUES.CALCULATION)}
      >
        <div className={styles.actionBlockWrapper}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} data-cy="stage-quote-message">
              {!isStaged && showStageInfoBlock && (
                <ActionBlock
                  title="You may now Stage this Quote for a loan"
                  description="The Stage Quote button should be enabled for any quote that is likely to become a loan. Users may use their discretion in deciding to 'stage' a quote. The Stage Quote button must be activated
prior to starting a loan."
                  icon={<InformationIcon width="24px" height="24px" />}
                />
              )}
              <ActionBlock
                title="Recalculate results?"
                description="You can adjust the calculation results by changing the input data in
          the Details section. To do this, click Recalculate"
              >
                <Button
                  variant="outlined"
                  onClick={recalculateActionHandler}
                  data-cy="go-to-details-button"
                >
                  Go to details
                </Button>
              </ActionBlock>
            </Grid>
          </Grid>
        </div>
        {quoteType === QUOTE_TYPE.RENTAL && (
          <ScenarioRentalCalculation
            isCalculating={isCalculating}
            disabled={disabled}
            details={
              details as LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse
            }
          />
        )}
        {quoteType === QUOTE_TYPE.SHORT_TERM && (
          <DefaultScenarioBuilderCalculation
            disabled={disabled}
            details={
              details as LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteByIdResponse
            }
            configs={SHORT_TERM_CALCULATION_SECTIONS_CONFIGS}
          />
        )}
      </Collapsed>
    </Paper>
  );
};
