import { createContext } from 'react';
import { AddHandler, ChangeHandler, RemoveHandler } from '@typings/common';

export interface FormConfigContextValue {
  disabled?: boolean;
  onBlur?: ChangeHandler;
  onRemove?: RemoveHandler;
  onAdd?: AddHandler;
  clearErrorState?: () => void;
}

export const DEFAULT_CONTEXT = {
  disabled: false,
};

export const FormConfigContext =
  createContext<FormConfigContextValue>(DEFAULT_CONTEXT);
