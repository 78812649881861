import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const TOAST_DISAPPEAR_TIMEOUT = 2 * 1000;
export const MAX_TOASTS_COUNT = 3;

export enum ToastType {
  Error = 'error',
  Success = 'success',
}

export interface ToastItem {
  type: ToastType;
  title: string;
  description: string;
  id: string;
}

export type ToastItemPayload = Omit<ToastItem, 'id'> & {
  id?: string;
  disappearTimeout?: number;
};

export interface ToastState {
  toasts: ToastItem[];
}

export const initialState: ToastState = {
  toasts: [],
};

const toastSlice = createSlice({
  initialState,
  name: 'toast',
  reducers: {
    addToast: (state: ToastState, action: PayloadAction<ToastItem>) => {
      if (state.toasts.length >= MAX_TOASTS_COUNT) {
        state.toasts.shift();
      }
      state.toasts.push(action.payload);
    },
    removeToastById: (state: ToastState, action: PayloadAction<string>) => {
      const index = state.toasts.findIndex(
        (toast) => toast.id === action.payload
      );
      if (index > -1) {
        state.toasts.splice(index, 1);
      }
    },
  },
});

export const {
  reducer,
  actions: { addToast, removeToastById },
} = toastSlice;

export const addToastWithAutodelete = createAsyncThunk(
  'toast/addWithAutodelete',
  async (toast: ToastItemPayload, thunkAPI): Promise<void> => {
    const id = toast.id || Date.now().toString();
    toast.id = id;

    thunkAPI.dispatch(addToast(toast as ToastItem));

    setTimeout(() => {
      thunkAPI.dispatch(removeToastById(id));
    }, toast.disappearTimeout ?? TOAST_DISAPPEAR_TIMEOUT);
  }
);
