import React from 'react';
import { Grid } from '@mui/material';
import { Collapsed } from '@components/Collapsed';
import { ApplicationUploader } from './components/ApplicationUploader/ApplicationUploader.component';

interface PropertyDetailsProps {
  form: JSX.Element;
}

export const PropertyDetails = ({ form }: PropertyDetailsProps) => {
  return (
    <Collapsed label="Property details" defaultExpanded>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ApplicationUploader />
        </Grid>
        <Grid item xs={12}>
          {form}
        </Grid>
      </Grid>
    </Collapsed>
  );
};
