import React, { PropsWithChildren, useMemo, useState } from 'react';
import classNames from 'classnames';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { IconButton, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  InformationIcon,
} from '@lola/ui-react-components';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from 'src/components/ProgressStepper/types';
import { ProgressStatusIcon } from '../ProgressStatusIcon/ProgressStatusIcon.component';
import styles from './progressStep.module.scss';

export interface ProgressStepProps extends ProgressStepperItem {
  activeItem?: string;
  indent?: number;
  bold?: boolean;
  startFolded?: boolean;
}

export const ProgressStep = ({
  id,
  items,
  activeItem,
  onClick,
  status,
  title,
  tooltip,
  indent,
  bold,
  to,
  startFolded,
}: ProgressStepProps) => {
  const [open, setOpen] = useState(!startFolded);

  const handleClick = () => {
    if (items?.length) {
      setOpen(!open);
    } else {
      if (onClick) {
        onClick();
      }
    }
  };

  const Wrapper = useMemo(() => {
    if (to !== undefined && status !== ProgressStepItemStatus.LOCKED) {
      return ({ children }: PropsWithChildren) => (
        <NavLink to={to} className={styles.link}>
          {children}
        </NavLink>
      );
    }

    return React.Fragment;
  }, [to, status]);

  return (
    <Wrapper>
      <ListItemButton
        onClick={handleClick}
        disabled={status === ProgressStepItemStatus.LOCKED}
        className={classNames(styles.button, {
          [styles.alert]: status === ProgressStepItemStatus.ALERT,
          [styles.active]: activeItem === id,
        })}
        classes={{ disabled: styles.disabled }}
        sx={{ pl: indent }}
      >
        {status && <ProgressStatusIcon status={status} />}
        <ListItemText
          primary={title}
          className={classNames(styles.title, {
            [styles.active]: activeItem === id,
            [styles.bold]: bold,
            [styles.locked]: status === ProgressStepItemStatus.LOCKED,
          })}
        />
        {!!items?.length && status !== ProgressStepItemStatus.LOCKED ? (
          <>
            {open ? (
              <IconButton className={styles.iconWrapper}>
                <ChevronDownIcon className={styles.chevronIcon} />
              </IconButton>
            ) : (
              <IconButton className={styles.iconWrapper}>
                <ChevronRightIcon className={styles.chevronIcon} />
              </IconButton>
            )}
          </>
        ) : null}
        {tooltip && status !== ProgressStepItemStatus.LOCKED ? (
          <Tooltip title={tooltip}>
            <IconButton className={styles.iconWrapper}>
              <InformationIcon className={styles.infoIcon} />
            </IconButton>
          </Tooltip>
        ) : null}
      </ListItemButton>
      {!!items?.length && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items.map((item) => (
              <ProgressStep
                key={item.id}
                activeItem={activeItem}
                indent={(indent ?? 0) + 4}
                {...item}
              />
            ))}
          </List>
        </Collapse>
      )}
    </Wrapper>
  );
};
