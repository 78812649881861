import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ROLES } from '@typings/common';
import { LolaBffApiContractsModelsUser } from '@api/output/api';
import { checkInvitedUser } from './InviteForm.utils';
import { ACTIONS } from './components/InviteTeam/inviteTeam.constants';

export const createInviteFormSchema = (
  role: ROLES,
  users: LolaBffApiContractsModelsUser[]
) => {
  const yupNativeSchema = yup
    .object({
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      email: yup.string().email().required('Email is required'),
      role: yup.lazy((_, { parent }) => {
        const { isUserInvited } = checkInvitedUser(parent.email, users);
        return isUserInvited
          ? yup.string().notRequired()
          : yup.string().required('Role is required');
      }),
      teamType: yup.lazy((_, { parent }) => {
        const { isUserInvited } = checkInvitedUser(parent.email, users);
        if (parent.role === ROLES.PROCESSOR || isUserInvited) {
          return yup.string();
        }
        return yup.string().required('Team action is required');
      }),
      managerUserId: yup.lazy((_, { parent }) => {
        const isRequired =
          role === ROLES.KEY_BROKER_MANAGER &&
          parent.role === ROLES.BROKER &&
          parent.teamType === ACTIONS.CREATE_NEW;

        if (isRequired) {
          return yup.string().required('Team manager is a required field.');
        }

        return yup.mixed().notRequired();
      }),
      teamId: yup.lazy((_, { parent }) => {
        const isRequired =
          role === ROLES.KEY_BROKER_MANAGER &&
          parent.role === ROLES.BROKER &&
          parent.teamType === ACTIONS.SELECT_EXISTING;

        if (isRequired) {
          return yup.number().required('Team is a required field.');
        }

        return yup.mixed().notRequired();
      }),
    })
    .required();

  return yupResolver(yupNativeSchema);
};
