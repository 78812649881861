import { useMemo } from 'react';
import { ROLES, TEAM_ASSIGNMENT_STATUS, USER_STATUS } from '@typings/common';
import { useRole } from '@hooks/useRole';
import { useUserAssignmentStatus } from '@hooks/useUserAssignmentStatus';

const ROLES_ADDABLE_BY_UNASSIGNED: Partial<Record<ROLES, ROLES[]>> = {
  [ROLES.KEY_BROKER_MANAGER]: [
    ROLES.KEY_BROKER_MANAGER,
    ROLES.BROKER_MANAGER,
    ROLES.BROKER,
  ],
  [ROLES.BROKER_MANAGER]: [ROLES.BROKER],
};

const ROLES_ADDABLE_BY: Partial<Record<ROLES, ROLES[]>> = {
  [ROLES.KEY_BROKER_MANAGER]: [
    ROLES.KEY_BROKER_MANAGER,
    ROLES.BROKER_MANAGER,
    ROLES.BROKER,
  ],
};

export const useAddPermission = (
  role?: string,
  status?: string,
  hasTeam?: boolean
): boolean => {
  const currentUserRole = useRole();
  const userAssignmentStatus = useUserAssignmentStatus();

  const canAddUnassigned = () =>
    (userAssignmentStatus === TEAM_ASSIGNMENT_STATUS.UNASSIGNED &&
      !hasTeam &&
      ROLES_ADDABLE_BY_UNASSIGNED[currentUserRole]?.includes(role as ROLES)) ??
    false;

  const canAdd = () =>
    ROLES_ADDABLE_BY[currentUserRole]?.includes(role as ROLES) ?? false;

  return useMemo(() => {
    if (status === USER_STATUS.ARCHIVED) {
      return false;
    }

    return canAddUnassigned() || canAdd();
  }, [currentUserRole, role, status, userAssignmentStatus, hasTeam]);
};
