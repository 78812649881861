import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { DEFAULT_PROPS } from './ChipList.constants';

import styles from './chipList.module.scss';

interface ChipListProps {
  chips: ChipProps[];
}

export const ChipList = ({ chips }: ChipListProps) => {
  return (
    <div className={styles.chips}>
      {chips.map((chip) => {
        const { label, ...chipProps } = chip;
        return (
          <Chip
            label={label}
            key={label as string}
            {...DEFAULT_PROPS}
            {...chipProps}
          />
        );
      })}
    </div>
  );
};
