import { useCallback, useMemo } from 'react';
import omit from 'lodash/omit';
import { QUOTE_TYPE } from '@typings/common';
import { useQuotesCalculateApi } from '@hooks/useQuotesApi/useQuotesCalculateApi';
import {
  usePostApiV1PricingEngineQuoteRentalMutation,
  usePostApiV1PricingEngineQuoteStfMutation,
} from '@api/output/api';
import { CalculatePayloadValuesWithFees } from '@hooks/useQuotesApi/utils/feeUtils.types';
import { replaceFeesWithObject } from '@hooks/useQuotesApi/utils/replaceFeesWithObject.utils';

export type CreateHandler = (
  values: CalculatePayloadValuesWithFees
) => Promise<string | undefined>;

export type UseCreateApiReturnValue = [
  CreateHandler,
  (
    | ReturnType<typeof usePostApiV1PricingEngineQuoteRentalMutation>[1]
    | ReturnType<typeof usePostApiV1PricingEngineQuoteStfMutation>[1]
  )
];

/* 
  Create quote, this method will execute 2 operation: calculate and create. 
  Create will be executed only if calculation do not have critical errors.
  It tries to mimic similar behavior to update method.
*/
export const useQuotesCreateApi = (
  quoteType: QUOTE_TYPE
): UseCreateApiReturnValue => {
  const [createRental, createRentalValue] =
    usePostApiV1PricingEngineQuoteRentalMutation();
  const [createShortTerm, createShortTermValue] =
    usePostApiV1PricingEngineQuoteStfMutation();

  const [calculate, calculateResult] = useQuotesCalculateApi(quoteType);

  const createResult = useMemo(
    () =>
      quoteType === QUOTE_TYPE.SHORT_TERM
        ? createShortTermValue
        : createRentalValue,
    [createRentalValue, createShortTermValue, quoteType]
  );

  const create = useCallback(
    async (values: CalculatePayloadValuesWithFees) => {
      const transformedValues = omit(
        replaceFeesWithObject(values),
        'conditions'
      );

      const { out } = await calculate(values);

      if (out?.validationErrors) {
        return;
      }

      const payload = {
        in: transformedValues,
        out,
      };

      if (quoteType === QUOTE_TYPE.SHORT_TERM) {
        return createShortTerm({
          lolaBffApiContractsModelsPricingEngineRequestSaveShortTermFinanceQuoteRequest:
            payload,
        }).unwrap();
      } else {
        return createRental({
          lolaBffApiContractsModelsPricingEngineRequestSaveRentalQuoteRequest:
            payload,
        }).unwrap();
      }
    },
    [createRental, createShortTerm, quoteType]
  );

  const result = useMemo(() => {
    const { data, isError, isLoading } = calculateResult;
    if (isLoading || isError || data?.out?.validationErrors) {
      return calculateResult;
    }

    return createResult;
  }, [createResult, calculateResult]);

  return useMemo(() => [create, result], [create, result]);
};
