import React, { useCallback, useMemo } from 'react';
import { LinearProgress, Paper, Typography } from '@mui/material';
import { TableColumnType } from 'antd';
import { DataTable } from '@lola/ui-react-components';
import { Modal } from '@components/Modal';
import { useOpenState } from '@hooks/useOpenState';
import { useRole } from '@hooks/useRole';
import { ROLES } from '@typings/common';
import {
  LolaBffApiContractsModelsLicensesLicense,
  useDeleteApiV1LicensesByLicenseIdMutation,
} from '@api/output/api';
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import { useOpenToast } from '@hooks/useOpenToast';
import { LicensingNote } from '../LicensingNote/LicensingNote.component';
import { LICENSING_DRAWER_TYPE } from '../../LicensingInfo.costants';
import { createActionsRenderer } from './renderers';
import { COLUMNS } from './LicensingList.constants';
import styles from './licensingList.module.scss';

interface LicensingListProps {
  setDrawerType: (arg: LICENSING_DRAWER_TYPE) => void;
  getLicenses: () => void;
  currentLicense: LolaBffApiContractsModelsLicensesLicense | null;
  setCurrentLicense: (
    license: LolaBffApiContractsModelsLicensesLicense
  ) => void;
  data?: LolaBffApiContractsModelsLicensesLicense[];
  isError?: boolean;
  isFetching?: boolean;
}

export const LicensingList = ({
  setDrawerType,
  getLicenses,
  data,
  isFetching,
  isError,
  setCurrentLicense,
  currentLicense,
}: LicensingListProps) => {
  const openToast = useOpenToast(3000);

  const [isModalOpen, openModal, closeModal] = useOpenState();
  const [deleteLicense, { isLoading }] =
    useDeleteApiV1LicensesByLicenseIdMutation();

  const openModalHandler = useCallback(
    (license: LolaBffApiContractsModelsLicensesLicense) => () => {
      if (license) {
        openModal();
        setCurrentLicense(license);
      }
    },
    [openModal, setCurrentLicense]
  );

  const openDrawer = useCallback(
    (license: LolaBffApiContractsModelsLicensesLicense) => () => {
      if (license) {
        setCurrentLicense(license);
        setDrawerType(LICENSING_DRAWER_TYPE.UPDATE);
      }
    },
    [setCurrentLicense, setDrawerType]
  );

  const role = useRole();
  const columnsWithActions: TableColumnType<LolaBffApiContractsModelsLicensesLicense>[] =
    useMemo(() => {
      if (role === ROLES.KEY_BROKER_MANAGER) {
        return [
          ...COLUMNS,
          {
            key: 'licenseActions',
            width: '1%',
            render: createActionsRenderer(openModalHandler, openDrawer),
            align: 'center',
          },
        ];
      }
      return COLUMNS;
    }, [openModal]);

  const deleteLicenseHandler = useCallback(() => {
    if (!currentLicense?.licenseId) return;

    return deleteLicense({
      licenseId: currentLicense.licenseId,
    })
      .unwrap()
      .then(() => {
        openToast({
          title: 'Success',
          description: 'License has been deleted',
        });
        getLicenses();
        closeModal();
      })
      .catch(() =>
        openToast({
          isError: true,
          title: 'Error',
          description: 'Something went wrong, try again later',
        })
      );
  }, [currentLicense, getLicenses, openToast, closeModal]);

  if (isFetching) {
    return <LinearProgress variant="indeterminate" value={30} />;
  }

  if (isError) {
    return <ErrorNoticeFetchRequest />;
  }

  if (!data?.length) {
    return <LicensingNote />;
  }

  return (
    <>
      <Paper elevation={0} classes={{ root: styles.wrapper }}>
        <DataTable
          columns={columnsWithActions}
          dataSource={data}
          rowKey="licenseId"
        />
      </Paper>
      <Modal
        isOpen={isModalOpen}
        isLoading={isLoading}
        title="Delete license"
        successButtonText="Confirm"
        onClose={closeModal}
        onSuccess={deleteLicenseHandler}
        hasCancel
      >
        <Typography>
          Are you sure you want to delete license #
          {currentLicense?.licenseNumber || ''}?
        </Typography>
      </Modal>
    </>
  );
};
