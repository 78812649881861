import React from 'react';
import { Drawer, IconButton } from '@mui/material';
import { CloseIcon } from '@lola/ui-react-components';
import { ContentSectionHeader } from '@components/ContentSection';

import { InviteForm } from '@pages/teamManagement/components/InviteDrawer/components/InviteForm/InviteForm.component';
import styles from './inviteDrawer.module.scss';

export interface InviteDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const InviteDrawer = ({ open, onClose }: InviteDrawerProps) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      hideBackdrop={true}
      anchor="right"
      classes={{
        paper: styles.drawer,
      }}
    >
      <ContentSectionHeader
        title="Invite member"
        actions={
          <IconButton onClick={onClose} title="Close invite member">
            <CloseIcon />
          </IconButton>
        }
      />
      <InviteForm onClose={onClose} />
    </Drawer>
  );
};
