import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Skeleton } from '@mui/material';
import { useLoanDetails } from '@pages/personalLoans/pages/buildMyLoan';
import { BuildMyLoanColumnStepper } from '@pages/personalLoans/pages/controlPanel/components/BuildMyLoanColumn/BuildMyLoanColumnStepper.component';

export const BuildMyLoanColumn = () => {
  const { data, isLoading } = useLoanDetails();
  const methods = useForm({
    values: data,
  });

  /* Currently steps hooks expect data receiving from form context */
  return (
    <FormProvider {...methods}>
      {isLoading ? (
        <Skeleton variant="rectangular" height="100%" />
      ) : (
        <BuildMyLoanColumnStepper />
      )}
    </FormProvider>
  );
};
