import React, { useCallback, useMemo } from 'react';
import {
  Button,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { CloseIcon } from '@lola/ui-react-components';
import { ActionBlock } from '@lola/ui-react-components';
import { ContentSectionHeader } from '@components/ContentSection';
import { convertQuoteObjectToCalculationBlock } from '@utils/quotes.utils';
import { CalculationBlock } from '@components/CalculationBlock/CalculationBlock.component';
import { LeverageFormBlock } from '@components/QuoteCalculation/components/LeverageCalculations/components/LeverageCalculationsDrawer/components/LeverageFormBlock/LeverageFormBlock.component';
import { CalculationErrors } from '@pages/scenarioBuilder/pages/quoteForm/components/CalculationErrors/CalculationErrors.component';
import { LeverageDetails } from '../../LeverageCalculations.type';
import {
  FINAL_LOAN_SECTIONS_CONFIGS,
  LEVERAGE_FORM_SECTIONS_CONFIGS,
} from '../../LeverageCalculations.constants';
import styles from './leverageCalculationsDrawer.module.scss';

export interface LeverageCalculationsDrawerProps {
  open: boolean;
  onClose: () => void;
  calculateHandler: () => void;
  updateHandler: () => void;
  calculationErrors: string[];
  quoteDetails?: LeverageDetails;
  leverageDetails?: LeverageDetails;
  isCalculating?: boolean;
  isSaving?: boolean;
  isDirty?: boolean;
  isCalculated?: boolean;
}

export const LeverageCalculationsDrawer = ({
  open,
  onClose,
  quoteDetails,
  leverageDetails,
  calculateHandler,
  updateHandler,
  isCalculating,
  isSaving,
  isDirty,
  isCalculated,
  calculationErrors,
}: LeverageCalculationsDrawerProps) => {
  const formBlocks = useMemo(() => [...LEVERAGE_FORM_SECTIONS_CONFIGS], []);

  const finalLoanSectionBlocks = useMemo(
    () =>
      convertQuoteObjectToCalculationBlock(
        leverageDetails,
        FINAL_LOAN_SECTIONS_CONFIGS
      ),
    [leverageDetails]
  );

  const onCloseHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Drawer
      open={open}
      onClose={onCloseHandler}
      hideBackdrop={true}
      anchor="right"
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      <ContentSectionHeader
        title="Leverage calculations"
        actions={
          <IconButton onClick={onCloseHandler} title="Close list of my quotes">
            <CloseIcon />
          </IconButton>
        }
      />
      <div className={styles.main}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionBlock
              title="Apply LTV"
              description="By default we will calculate the maximum leverage available. For a smaller loan amount, adjust Leverage and press Apply Leverage and Recalculate button."
              className={styles.actionBlock}
            >
              <>
                <Button
                  variant="outlined"
                  onClick={calculateHandler}
                  disabled={!isDirty}
                >
                  Apply Leverage and Recalculate
                </Button>
                {isCalculating && (
                  <LinearProgress
                    className={styles.loadingIndicator}
                    data-cy="linear-progress"
                  />
                )}
              </>
            </ActionBlock>
          </Grid>
          {!!calculationErrors.length && (
            <Grid item xs={12}>
              <CalculationErrors errors={calculationErrors} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {formBlocks.map((block) => (
                <Grid key={block.title} item xs={4}>
                  <LeverageFormBlock
                    {...block}
                    adjustLeverageDescriptionRenderer={
                      block.adjustLeverageDescriptionRenderer
                    }
                    leverageDetails={leverageDetails}
                    quoteDetails={quoteDetails}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {finalLoanSectionBlocks.map((block) => (
              <Grid key={block.title} item xs={12}>
                <CalculationBlock {...block} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
      {isSaving && <LinearProgress data-cy="linear-progress" />}
      <footer className={styles.footer}>
        <Button variant="text" size="large" onClick={onCloseHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={updateHandler}
          disabled={!isCalculated}
        >
          Save changes
        </Button>
      </footer>
    </Drawer>
  );
};
