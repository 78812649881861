import React from 'react';
import { Controller, useFormContext, get } from 'react-hook-form';
import { RadioGroup, RadioGroupProps } from './RadioGroup.component';

export interface RadioGroupControlProps extends Omit<RadioGroupProps, 'name'> {
  disabled?: boolean;
  onBlurCreator?: (
    defaultHandler: RadioGroupProps['onChange']
  ) => RadioGroupProps['onChange'];
  name: string;
  required?: boolean;
}

export const RadioGroupControl = ({
  name,
  ariaLabel,
  options,
  disabled,
  onBlurCreator,
  required,
  ...radioProps
}: RadioGroupControlProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { value = '', onChange, ...props } }) => (
        <RadioGroup
          disabled={disabled}
          options={options}
          value={value}
          ariaLabel={ariaLabel}
          onChange={onBlurCreator ? onBlurCreator(onChange) : onChange}
          required={required}
          error={!!get(errors, name)}
          {...radioProps}
          {...props}
        />
      )}
    />
  );
};
