export enum NotificationConditionIcon {
  Message = 'message',
  Upload = 'upload',
  Document = 'document',
}

export type SetIcon =
  | NotificationConditionIcon.Message
  | NotificationConditionIcon.Upload
  | NotificationConditionIcon.Document;
