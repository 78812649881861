import React from 'react';
import { FilterOption } from '@lola/ui-react-components';
import { DataFilter } from '@components/DataTableControlPanel/components';

export const filterByCreatedByRenderer = (
  appliedFilterOptions: string[],
  handleFilterChange: (filterKey: string, value: string[]) => void,
  filterOptions?: FilterOption[]
) => {
  return (
    <DataFilter
      handleFilterChange={handleFilterChange}
      filterOptions={filterOptions}
      appliedFilterOptions={appliedFilterOptions}
      filterKey=""
      filterTitle="Created by"
    />
  );
};
