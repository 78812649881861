import { useCallback } from 'react';
import { set } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  usePostApiV1LoansByLoanIdAddPropertiesMutation,
  usePutApiV1PropertiesByPropertyIdMutation,
} from '@api/output/api';
import { CustomValueType } from '@typings/common';

export const useUpdateProperties = () => {
  const { loanId = '' } = useParams();
  const [createAndAttachProperty] =
    usePostApiV1LoansByLoanIdAddPropertiesMutation();
  const [updateProperty] = usePutApiV1PropertiesByPropertyIdMutation();

  return useCallback(
    async (
      id: string | undefined,
      payload: Record<string, CustomValueType>
    ) => {
      let propertyId = id;

      const body = Object.entries(payload).reduce((acc, [path, value]) => {
        set(acc, path, value);
        return acc;
      }, {});

      if (!propertyId) {
        const response = await createAndAttachProperty({
          loanId,
          body: {
            properties: [body],
          },
        }).unwrap();
        propertyId = response.properties?.[0];
      } else {
        await updateProperty({
          loanId,
          propertyId,
          lolaBffApiContractsModelsPropertyUpdatePropertyRequest: {
            ...body,
          },
        });
      }

      return propertyId;
    },
    []
  );
};
