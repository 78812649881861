import React, { PropsWithChildren } from 'react';
import { Divider, Paper } from '@mui/material';
import { Collapsed } from '@components/Collapsed';

import styles from './creditScoreCollapsed.module.scss';

interface CreditScoreCollapsedProps {
  label: string;
  icon?: JSX.Element;
  description?: string;
  defaultExpanded?: boolean;
  expanded?: boolean;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
}

export const CreditScoreCollapsed = ({
  label,
  icon,
  description,
  children,
  defaultExpanded,
  expanded,
  onChange,
}: PropsWithChildren<CreditScoreCollapsedProps>) => {
  return (
    <Paper variant="filled" className={styles.subsection}>
      <Collapsed
        label={label}
        icon={icon}
        description={description}
        variant="filled"
        expanded={expanded}
        defaultExpanded={defaultExpanded}
        onChange={onChange}
      >
        <Divider className={styles.divider} />
        <div className={styles.container}>{children}</div>
      </Collapsed>
    </Paper>
  );
};
