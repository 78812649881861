import { useCallback, useEffect, useMemo, useState } from 'react';
import { LolaBffApiContractsModelsApproveMyLoanResponseActionResponse } from '@api/output/api';
import { useOpenState } from '@hooks/useOpenState';

export const useApproveMyLoanActionsUpload = (
  tasks: LolaBffApiContractsModelsApproveMyLoanResponseActionResponse[]
) => {
  const [isDrawerOpen, openDrawer, closeDrawer] = useOpenState();
  const [activeTask, setActiveTask] = useState<
    LolaBffApiContractsModelsApproveMyLoanResponseActionResponse | undefined
  >({});
  const uploadDrawerHandler = useCallback(
    (task?: LolaBffApiContractsModelsApproveMyLoanResponseActionResponse) => {
      return () => {
        setActiveTask(task);
        openDrawer();
      };
    },
    []
  );

  useEffect(() => {
    if (isDrawerOpen && tasks && activeTask) {
      const updatedAction = tasks.find(
        ({ taskId }) => activeTask.taskId === taskId
      );

      setActiveTask(updatedAction);
    }
  }, [isDrawerOpen, activeTask, tasks]);

  return useMemo(
    () => ({
      isDrawerOpen,
      closeDrawer,
      activeTask,
      uploadDrawerHandler,
    }),
    [isDrawerOpen, openDrawer, closeDrawer, activeTask, uploadDrawerHandler]
  );
};
