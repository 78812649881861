import { useCallback, useMemo } from 'react';
import { QUOTE_TYPE } from '@typings/common';
import { ApiError, convertCatchError } from '@utils/errors.utils';
import { useOpenToast } from '@hooks/useOpenToast';
import { useQuotesCreateApi } from '@hooks/useQuotesApi/useQuotesCreateApi';
import { useQuotesUpdateApi } from '@hooks/useQuotesApi/useQuotesUpdateApi';
import { CalculatePayloadValuesWithFees } from '@hooks/useQuotesApi/utils/feeUtils.types';
import { DEFAULT_API_ERROR_TITLE } from '@constants/text.constants';

export type SaveHandler = (
  values: CalculatePayloadValuesWithFees
) => Promise<string | undefined>;

export type UseSaveReturnValue = [
  SaveHandler,
  (
    | ReturnType<typeof useQuotesCreateApi>[1]
    | ReturnType<typeof useQuotesUpdateApi>[1]
  )
];

export const useQuotesSaveApi = (
  quoteType: QUOTE_TYPE,
  quoteId?: string
): UseSaveReturnValue => {
  const openToast = useOpenToast();
  const [create, createValue] = useQuotesCreateApi(quoteType);
  const [update, updateValue] = useQuotesUpdateApi(quoteType);

  const save = useCallback(
    async (values: CalculatePayloadValuesWithFees): Promise<string> => {
      try {
        let id = quoteId;
        if (quoteId) {
          await update(quoteId, values);
        } else {
          id = await create(values);
        }

        return id ?? '';
      } catch (err) {
        const description = convertCatchError(err as ApiError);
        openToast({
          isError: true,
          title: DEFAULT_API_ERROR_TITLE,
          description,
        });
        return Promise.reject(new Error('Error saving quote'));
      }
    },
    [update, create, quoteId, openToast]
  );

  const saveResult = useMemo(() => {
    return quoteId ? updateValue : createValue;
  }, [quoteId, createValue, updateValue]);

  return useMemo(() => [save, saveResult], [save, saveResult]);
};
