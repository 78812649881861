import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import { DEFAULT_TITLE } from '@components/NoContent/constants/constants';
import styles from './noContent.module.scss';

export interface NoContentProps {
  variant?: 'grey';
  title: string;
  text?: string | ReactNode;
  icon?: JSX.Element;
  children?: JSX.Element;
}

export const NoContent = ({
  title = DEFAULT_TITLE,
  text,
  icon,
  children,
  variant,
}: NoContentProps) => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      {icon && (
        <div
          className={classnames({
            [styles.icon]: true,
            [styles.iconGrey]: variant === 'grey',
          })}
        >
          {icon}
        </div>
      )}
      <Typography variant="h3" className={styles.title}>
        {title}
      </Typography>
      {text && (
        <Typography variant="p" paragraph className={styles.text}>
          {text}
        </Typography>
      )}
      {children}
    </div>
  </div>
);
