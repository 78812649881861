import React from 'react';
//Mui
import { Button, Grid, Link, Skeleton } from '@mui/material';
//Router
import { NavLink, useParams } from 'react-router-dom';
//Icons
import { TeamIcon, AddIcon } from '@lola/ui-react-components';
//Api
import { useGetApiV1LoansByLoanIdQuery } from '@api/output/api';
//Components
import { NoContent } from '@components/NoContent';
import { DetailsPopulated } from './components';
//Styles
import styles from './details.module.scss';

export const DetailsPage = () => {
  // router
  const { loanId = '' } = useParams();

  // API
  const { data, isLoading } = useGetApiV1LoansByLoanIdQuery({
    loanId: loanId,
  });

  if (isLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Skeleton variant="rounded" data-testid="skeleton" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rounded" />
        </Grid>
      </Grid>
    );
  }

  return (
    <section className={styles.details}>
      {(!data || data?.isLoanDetailsEmpty) && (
        <NoContent
          title="No data to show here yet"
          text="This tab shows loan details and key data."
          icon={<TeamIcon />}
        >
          <Link component={NavLink} to="../" underline="none">
            <Button variant="outlined" startIcon={<AddIcon />} size="large">
              Go to control panel
            </Button>
          </Link>
        </NoContent>
      )}

      {data && !data?.isLoanDetailsEmpty && (
        <DetailsPopulated loanDetails={data} />
      )}
    </section>
  );
};
