import {
  LolaBffApiContractsModelsLoanDocumentTaskSection,
  LolaBffApiContractsModelsLoanLoanMilestone,
  LolaBffApiContractsModelsLoanLoanModel,
  LolaBffApiContractsModelsLoanLoanProperty,
  LolaBffApiContractsModelsLoanPartyInfo,
} from '@api/output/api';
import { LOAN_MILESTONE, PARTY_TYPE, ROLE_TYPE } from '@typings/common';
import { BuildMyLoanParty } from '../buildMyLoan.type';

export const createParties = (
  parties: LolaBffApiContractsModelsLoanPartyInfo[] = []
) => {
  const result: BuildMyLoanParty = {
    guarantors: [],
  };

  for (const item of parties) {
    const relationship = item.relationRoleType ?? [];
    const isIndividual = item.type === PARTY_TYPE.INDIVIDUAL;

    if (isIndividual && relationship.includes(ROLE_TYPE.PRIMARY_INDIVIDUAL)) {
      result.borrower = item;
    } else if (!isIndividual) {
      result.entity = item;
    } else if (
      isIndividual &&
      relationship.includes(ROLE_TYPE.OTHER_INDIVIDUAL)
    ) {
      result.guarantors?.push(item);
    }
  }

  return result;
};

export const createLoanData = (
  loan: LolaBffApiContractsModelsLoanLoanModel = {},
  parties: LolaBffApiContractsModelsLoanPartyInfo[] = [],
  properties: LolaBffApiContractsModelsLoanLoanProperty[] = [],
  tasks: LolaBffApiContractsModelsLoanDocumentTaskSection[] = []
) => {
  return {
    loan,
    party: createParties(parties),
    properties,
    tasks,
  };
};

export const isLoanDisabled = (
  milestone?: LolaBffApiContractsModelsLoanLoanMilestone
): boolean => {
  return (
    milestone !== undefined &&
    milestone !== '' &&
    milestone !== LOAN_MILESTONE.PRE_SUBMISSION &&
    milestone !== LOAN_MILESTONE.SUSPENDED
  );
};
