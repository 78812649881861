import { useCallback, useMemo, useState } from 'react';

export type UseExpandReturnValue = [
  {
    expanded: boolean;
    onChange: (_: unknown, value: boolean) => void;
  },
  (value: boolean) => void
];

export const useExpand = (defaultValue = false): UseExpandReturnValue => {
  const [value, setValue] = useState(defaultValue);

  const changeHandler = useCallback((_: unknown, value: boolean) => {
    setValue(value);
  }, []);

  const props = useMemo(() => {
    return {
      expanded: value,
      onChange: changeHandler,
    };
  }, [changeHandler, value]);

  return [props, setValue];
};
