import { useMemo } from 'react';
import { Option } from '@typings/common';
import { useAvailableOptions } from '@hooks/useAvailableOptions';

export interface Processor {
  id: string;
  newProcessorId: string;
}

interface UseAvailableProcessorsArg {
  processors: Option[];
  selectedProcessors: Processor[];
  currentProcessor?: string;
}

export const useAvailableProcessors = ({
  processors,
  selectedProcessors,
  currentProcessor,
}: UseAvailableProcessorsArg) => {
  const processorIds = useMemo(
    () =>
      selectedProcessors.reduce((acc: string[], { id, newProcessorId }) => {
        const isCurrent = currentProcessor === newProcessorId;
        return [...acc, id, isCurrent ? '' : newProcessorId];
      }, []),
    [selectedProcessors, currentProcessor]
  );
  return useAvailableOptions(processors, processorIds);
};
