import { useMemo } from 'react';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import { useGetApiV1DocumentTemplatesQuery } from '@api/output/api';
import { checkFileExtension } from '@utils/file.utils';

const MAX_CACHE_LIVE_TIME = 24 * 60 * 60 * 1000; // 1 DAY

type OperatorValueType = string[] | undefined;
type OperatorFunction = (
  value: string | undefined,
  options: OperatorValueType
) => boolean;

const operatorCheck: { [key: string]: OperatorFunction } = {
  $in: (value, options) => (options ? options.includes(value || '') : false),
  $nin: (value, options) => (options ? !options.includes(value || '') : false),
};

const entityTypeMapping: Record<string, string> = {
  deal: 'loan',
  party: 'party',
  property: 'property',
};

export const useTemplates = (
  taskConfigurationId?: string | null,
  targetExtension?: string[]
) => {
  const { data, isFetching } = useGetApiV1DocumentTemplatesQuery(undefined, {
    refetchOnMountOrArgChange: MAX_CACHE_LIVE_TIME,
  });
  const { getValues } = useFormContext();

  const templates = useMemo(() => {
    if (!data?.tasks || !taskConfigurationId) {
      return [];
    }

    const formData = getValues();
    const task = data.tasks.find(
      (task) => task.taskConfigurationId === taskConfigurationId
    );

    return task?.templates?.filter((template) => {
      if (
        targetExtension &&
        !checkFileExtension(template.file, targetExtension)
      ) {
        return false;
      }

      const entityTypeFilter: string | undefined = task.entityType
        ? entityTypeMapping[task.entityType]
        : undefined;

      if (!entityTypeFilter) {
        return false;
      }

      if (!template.condition) {
        return true;
      }

      const conditionKeys = Object.keys(template.condition);

      return conditionKeys.every((key) => {
        const condition = template.condition[key];

        return Object.entries(condition).every(([operator, operatorValue]) => {
          const value = get(formData[entityTypeFilter], key);

          return operatorCheck[operator] === undefined
            ? true
            : operatorCheck[operator](
                value,
                operatorValue as OperatorValueType
              );
        });
      });
    });
  }, [data, taskConfigurationId]);

  return {
    templates,
    templatesAreBeingFetched: isFetching,
  };
};
