import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const StageName = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const StageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  '&.textTop': {
    flexDirection: 'column',
    '& .stageBar': {
      flexGrow: 0,
      flexShrink: 0,
    },
  },
  '&.textRight': {
    alignItems: 'center',
    flexDirection: 'row-reverse',
    '& span': {
      flexGrow: 2,
      marginLeft: theme.spacing(1.5),
    },
    '& div': {
      flexGrow: 1,
    },
  },
}));
