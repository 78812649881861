import React from 'react';
import { LolaBffApiContractsModelsLoanTaskInfo } from '@api/output/api';
import { UploadDocumentsItem } from './components/UploadDocumentsItem/UploadDocumentsItem.component';

export interface UploadDocumentsListProps {
  tasks: LolaBffApiContractsModelsLoanTaskInfo[];
  uploadDrawerHandler: (
    task: LolaBffApiContractsModelsLoanTaskInfo
  ) => () => void;
}

export const UploadDocumentsList = ({
  tasks,
  uploadDrawerHandler,
}: UploadDocumentsListProps) => {
  return (
    <>
      {tasks.map((task) => (
        <UploadDocumentsItem
          key={task.taskId}
          task={task}
          placeholder="PDF less than 20mb"
          uploadDrawerHandler={uploadDrawerHandler}
        />
      ))}
    </>
  );
};
