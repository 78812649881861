import React from 'react';
import {
  ChatNotificationIcon,
  UploadIcon,
  ListIcon,
} from '@lola/ui-react-components';
import { SetIcon, NotificationConditionIcon } from '../../types/Condition.type';

import styles from './notificationIcon.module.scss';

export interface NotificationIconProps {
  icon: SetIcon;
}

export const NotificationIcon = ({ icon }: NotificationIconProps) => {
  const ICONS_LIST = {
    [NotificationConditionIcon.Message]: (
      <ChatNotificationIcon className={styles.notificationIcon} />
    ),
    [NotificationConditionIcon.Upload]: (
      <UploadIcon className={styles.notificationIcon} />
    ),
    [NotificationConditionIcon.Document]: (
      <ListIcon className={styles.notificationIcon} />
    ),
  };

  return ICONS_LIST[icon] || ICONS_LIST['document'];
};
