import { UPLOAD_TYPE } from '@constants/uploader.constants';
import {
  useFilesUploaderWithSubmit,
  UseUploaderWithSubmitProps,
} from './useFilesUploaderWithSubmit';
import { useFilesUploader, UseUploaderProps } from './useFilesUploader';

export interface UseFileUploadHandlerProps
  extends UseUploaderProps,
    UseUploaderWithSubmitProps {
  type?: UPLOAD_TYPE;
}

export const useFileUploadHandler = (props: UseFileUploadHandlerProps) => {
  const uploaderPropsConditional = useFilesUploaderWithSubmit(props);
  const uploaderPropsRegular = useFilesUploader(props);

  return props.type === UPLOAD_TYPE.CONDITIONAL
    ? uploaderPropsConditional
    : uploaderPropsRegular;
};
