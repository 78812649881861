import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  LolaBffApiContractsModelsDashboardLoansStatisticsBrokerStatistic,
  LolaBffApiContractsModelsUser,
} from '@api/output/api';
import { ROLES } from '@typings/common';
import { isUserAssignedToTeam } from '@utils/isUserAssignedToTeam.utils';
import { StatisticRow } from '../../LoansInPipelineWidget.typings';
import styles from './displayNameRenderer.module.scss';

const UNASSIGNED_ROLES_PLAIN_TEXT = [ROLES.BROKER, ROLES.BROKER_MANAGER];

const shouldShowPlainText = (
  currentUser: LolaBffApiContractsModelsUser | undefined,
  isRowUserAssignedToTeam: boolean
) => {
  if (!currentUser || isRowUserAssignedToTeam) return false;

  const isCurrentUserAssigned = isUserAssignedToTeam(
    currentUser?.managesTeams,
    currentUser?.teamId
  );

  if (!isCurrentUserAssigned) {
    return UNASSIGNED_ROLES_PLAIN_TEXT.includes(currentUser?.role as ROLES);
  }

  return !!(
    currentUser?.role === ROLES.BROKER_MANAGER &&
    currentUser?.managesTeams &&
    currentUser?.managesTeams.length > 0
  );
};

const renderDisplayText = (displayName?: string | null) => (
  <Typography variant="pBold">{displayName ?? ''}</Typography>
);

const renderLink = (displayName?: string | null) => (
  <Link
    to="/team-management/loans"
    className={styles.link}
    state={{ loanOfficer: displayName ?? '' }}
  >
    {displayName ?? ''}
  </Link>
);

export const displayNameRenderer =
  (currentUser: LolaBffApiContractsModelsUser | undefined) =>
  (_: unknown, row: StatisticRow) => {
    const { firstName, lastName, displayName, managesTeams, teamId } =
      row as LolaBffApiContractsModelsDashboardLoansStatisticsBrokerStatistic;
    const isRowUserAssignedToTeam = isUserAssignedToTeam(managesTeams, teamId);
    const isEligibleForPlainText = shouldShowPlainText(
      currentUser,
      isRowUserAssignedToTeam
    );

    switch (true) {
      case isEligibleForPlainText:
        return renderDisplayText(displayName);
      case !!(firstName || lastName):
        return renderLink(displayName);
      default:
        return renderDisplayText(displayName);
    }
  };
