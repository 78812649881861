import React, { useState, useEffect } from 'react';
import { AlertProps, Button } from '@mui/material';
import { Notification } from '@components/Notification/Notification.component';
import styles from './notificationWithConfirmation.module.scss';

export interface NotificationWithConfirmationProps {
  keyName: string;
  expiresIn: number;
  severity: AlertProps['severity'];
  title?: string;
  text?: string;
  className?: string;
  icon?: JSX.Element;
  withIcon?: boolean;
}

export const NotificationWithConfirmation = ({
  keyName,
  expiresIn,
  severity,
  title,
  text,
  className,
  icon,
  withIcon,
}: NotificationWithConfirmationProps) => {
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const setLocalStorageWithExpiry = (
    key: string,
    value: boolean,
    ttl: number
  ) => {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const getLocalStorageWithExpiry = (key: string) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  useEffect(() => {
    const storedValue = getLocalStorageWithExpiry(keyName);
    if (!storedValue) {
      setShowNotification(true);
    }
  }, [keyName]);

  const clickHandler = () => {
    setLocalStorageWithExpiry(keyName, true, expiresIn * 86400000); // 86400000 milliseconds in a day
    setShowNotification(false);
  };

  if (!showNotification) return null;

  return (
    <Notification
      className={className}
      severity={severity}
      title={title}
      text={text}
      icon={icon}
      withIcon={withIcon}
      actionButton={
        <Button
          className={styles.button}
          color="inherit"
          variant="outlined"
          onClick={clickHandler}
        >
          Ok
        </Button>
      }
    />
  );
};
