import { Option } from '@typings/common';
import { UploaderConfig } from '@components/Uploader/Uploader.types';
import { DEFAULT_UPLOADER_CONFIG } from '@components/Uploader/Uploader.constants';
import { DEFAULT_UPLOADER_DRAWER_CONFIG } from '@components/UploadDrawer/uploaderDrawer.constants';
import { UploaderDrawerConfig } from '@components/UploadDrawer/uploaderDrawer.types';
import { CREDIT_REPORT_ACTION } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/hooks/usePaymentParties';

export enum CREDIT_SCORE_ACTION_OPTION_VALUE {
  NEW = 'new',
  UPLOAD = 'upload',
}

export const CREDIT_SCORE_ACTION_OPTIONS: Option[] = [
  {
    label: 'New credit order',
    value: CREDIT_REPORT_ACTION.NEW,
  },
  {
    label: 'Re-issue credit',
    value: CREDIT_REPORT_ACTION.RE_ISSUE,
  },
];

export const CREDIT_REPORT_UPLOADER_CONFIG: UploaderConfig = {
  ...DEFAULT_UPLOADER_CONFIG,
  rules: {
    ...DEFAULT_UPLOADER_CONFIG.rules,
    maxFiles: 1,
  },
};

export const CREDIT_REPORT_DRAWER_CONFIG: UploaderDrawerConfig = {
  ...DEFAULT_UPLOADER_DRAWER_CONFIG,
  title: 'Credit Report documents',
  description: 'Optional description of the document',
};
