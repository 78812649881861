import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { ValidationError } from 'yup';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import { usePartyTaskStatuses } from '@pages/personalLoans/pages/buildMyLoan/hooks/usePartyTaskStatuses';
import { useParties } from '@pages/personalLoans/pages/buildMyLoan/hooks/useParties';
import {
  convertFormConfigToYupSchema,
  getValidationErrors,
} from '@components/DynamicForm/utils/validation';
import { forms } from '@form-configs/output';
import { FormDataSchema } from '@form-configs/types';
import { LoanPartyInfo } from '@typings/common';
import {
  DEFAULT_POLLING_INTERVAL,
  OWNERSHIP_ROW_SCHEMA,
} from './useBuildMyLoan.constants';
const APP_AND_ENTITY: ProgressStepperItem = {
  id: '/personal-loans/:id/build-my-loan',
  to: '',
  title: 'Application & entity information',
};

const { primaryGuarantor, entityBlock } = forms;

const personSchema = convertFormConfigToYupSchema(
  primaryGuarantor
) as FormDataSchema;
const simplePersonSchemaParty = personSchema.fields.party as FormDataSchema;
const simplePersonSchema = simplePersonSchemaParty.fields
  .borrower as FormDataSchema;

const entitySchema = convertFormConfigToYupSchema(
  entityBlock
) as FormDataSchema;
const simpleEntitySchemaParty = entitySchema.fields.party as FormDataSchema;
const simpleEntitySchema = simpleEntitySchemaParty.fields
  .entity as FormDataSchema;
export const useStepAppAndEntity = (
  isDynamic: boolean
): ProgressStepperItem => {
  const [entity, borrower, guarantors] = useWatch({
    name: ['party.entity', 'party.borrower', 'party.guarantors'],
  });

  const { parties } = useParties();

  const { partyStatuses, refetchStatuses } = usePartyTaskStatuses({
    parties,
    taskType: 'credit_report.authorization',
  });

  const isDone = useMemo(
    () => partyStatuses.every(({ completion }) => completion === 'Success'),
    [partyStatuses]
  );

  useEffect(() => {
    let timeoutId: number;
    if (!isDone && isDynamic) {
      timeoutId = setTimeout(() => {
        refetchStatuses();
      }, DEFAULT_POLLING_INTERVAL) as unknown as number;
    }
    return () => clearTimeout(timeoutId);
  }, [isDynamic, isDone, partyStatuses]);

  const isValid = useMemo(() => {
    let errors: ValidationError[] = [];
    errors = errors.concat(
      getValidationErrors(entity, simpleEntitySchema),
      getValidationErrors(borrower, simplePersonSchema),
      getValidationErrors(borrower, OWNERSHIP_ROW_SCHEMA)
    );

    guarantors?.forEach((guarantor: LoanPartyInfo) => {
      errors = errors.concat(
        getValidationErrors(guarantor, simplePersonSchema),
        getValidationErrors(guarantor, OWNERSHIP_ROW_SCHEMA)
      );
    });

    const validationErrors = errors.filter((err) => !!err);

    /* temporary solution for checking fields during demo*/
    console.warn(validationErrors);

    return validationErrors.length === 0;
  }, [entity, borrower, guarantors]);

  const status = useMemo(() => {
    return isDone && isValid
      ? ProgressStepItemStatus.DONE
      : ProgressStepItemStatus.IN_PROGRESS;
  }, [partyStatuses, isValid, isDone]);

  return useMemo(
    () => ({
      ...APP_AND_ENTITY,
      status,
    }),
    [status]
  );
};
