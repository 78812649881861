import { QUOTE_TYPE } from '@typings/common';
import { ControlType } from '@form-configs/types';
import {
  feesTypeOptionsForRental,
  feesTypeOptionsForShortTerm,
} from './PartnerEconomics.constants';

export const getPartnerEconomicsCommon = () => {
  const prefix = 'lenderFinance.';

  return [
    {
      controlType: 'block' as ControlType,
      props: {
        col: [6, 6],
      },
      items: [
        {
          controlType: 'number' as ControlType,
          props: {
            mask: 'positive:3',
            placeholder: '0.00%',
            label: 'Interest rate add on, %',
            name: `${prefix}interestRateAddOn`,
            defaultValue: 0,
            validation: [
              {
                rule: 'max',
                args: ['3', 'Value cannot exceed 3%. Please adjust the value.'],
              },
            ],
          },
          id: 'id-1',
        },
        {
          controlType: 'text' as ControlType,
          props: {
            mask: 'positive',
            placeholder: '0.00%',
            label: 'Origination points add on, %',
            name: `${prefix}originationPointsAddOn`,
            defaultValue: 0,
            validation: [
              {
                rule: 'max',
                args: ['3', 'Value cannot exceed 3%. Please adjust the value.'],
              },
            ],
          },
          id: 'id-2',
        },
      ],
      id: 'id-0',
    },
  ];
};

export const getPartnerEconomicsFee = (quoteType: QUOTE_TYPE) => {
  const maxItems =
    quoteType === QUOTE_TYPE.RENTAL
      ? feesTypeOptionsForRental.length
      : feesTypeOptionsForShortTerm.length;

  const addButtonDescription = `**You can add no more than ${maxItems} fees`;

  return [
    {
      controlType: 'list' as ControlType,
      id: 'list1',
      props: {
        autoTitlePattern: 'Fee #[]',
        addButtonText: 'Add a fee',
        regularButton: true,
        startFrom: 1,
        maxItems,
        label: 'Fees',
        name: 'fees',
        firstIsRequired: true,
        withSpacing: false,
        withDivider: true,
        addButtonDescription: [
          '* Total partner fees may not exceed $2,500 (not including the $1,500 REI Lender fee).',
          addButtonDescription,
        ],
      },
      items: [
        {
          controlType: 'custom' as ControlType,
          id: 'custom1',
          props: {
            controlType: 'feesFormBlock',
          },
        },
      ],
    },
  ];
};

export const getBrokerEconomicsFee = (queueType: QUOTE_TYPE) => {
  const prefix = queueType === QUOTE_TYPE.SHORT_TERM ? 'loanTerm.' : '';

  return [
    {
      controlType: 'block' as ControlType,
      props: {
        col: [6, 6],
      },
      items: [
        {
          controlType: 'text' as ControlType,
          props: {
            mask: 'positive',
            placeholder: '0.00%',
            label: 'Origination points add on, %',
            name: `${prefix}brokerFee`,
            defaultValue: 0,
            validation: [
              {
                rule: 'max',
                args: ['100', 'Fee amount must be less than or equal to 100'],
              },
            ],
          },
          id: 'id-BrokerEconomicsFee-1',
        },
        {
          controlType: 'number' as ControlType,
          props: {
            mask: 'positive',
            placeholder: '$0.00',
            label: 'Fee amount',
            name: `${prefix}brokerProcessingFee`,
            defaultValue: 0,
            validation: [
              {
                rule: 'max',
                args: ['2500', 'Fee amount must be less than or equal to 2500'],
              },
            ],
          },
          id: 'id-BrokerEconomicsFee-2',
        },
      ],
      id: 'id-BrokerEconomicsFee-0',
    },
  ];
};
