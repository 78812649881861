import React, { FC } from 'react';
import { Link, Typography } from '@mui/material';
import {
  DocIcon,
  XlsIcon,
  PdfIcon,
  DownloadIcon,
  LearningCenterIcon,
  FileIcon,
  VideoIcon,
} from '@lola/ui-react-components';
import { LolaBffApiContractsModelsLearningCenterLearningDocument } from '@api/output/api';
import { NoContent } from '@components/NoContent';
import {
  FILE_TYPE,
  getFileSizeByBytes,
  getFileTypeByExtension,
} from '@utils/file.utils';
import { DownloadPDFItem } from '../DownloadPDFItem/DownloadPDFItem.component';
import styles from './documentDownloadList.module.scss';

export interface DocumentDownloadListProps {
  isProtected?: boolean;
  documents?: LolaBffApiContractsModelsLearningCenterLearningDocument[];
}

const ICONS_MAP: Record<FILE_TYPE, FC> = {
  [FILE_TYPE.DOC]: DocIcon,
  [FILE_TYPE.PDF]: PdfIcon,
  [FILE_TYPE.XLS]: XlsIcon,
  [FILE_TYPE.VIDEO]: VideoIcon,
  [FILE_TYPE.NOT_SET]: FileIcon,
};

export const DocumentDownloadList = ({
  isProtected,
  documents = [],
}: DocumentDownloadListProps) => {
  if (documents.length === 0) {
    return (
      <NoContent
        variant="grey"
        title="No data to show here yet"
        text="Learning materials will be available later"
        icon={<LearningCenterIcon />}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      {documents.map((file) => {
        const fileType = getFileTypeByExtension(file.fileType);
        const fileSize = getFileSizeByBytes(file.fileSize);
        const Icon = ICONS_MAP[fileType];

        if (isProtected && fileType === FILE_TYPE.PDF) {
          return (
            <DownloadPDFItem
              key={file.fileName}
              url={file.contentUrl ?? ''}
              fileName={file.fileName}
              fileSize={fileSize}
              className={styles.documentLink}
            />
          );
        }

        return (
          <Link
            underline="none"
            href={file.contentUrl || '#'}
            key={file.fileName}
            className={styles.documentLink}
            download={file.fileName}
          >
            <Icon />
            <div className={styles.content}>
              <div>
                <Typography variant="h4" className={styles.fileName}>
                  {file.fileName}
                </Typography>
                <Typography variant="pSmall">{fileSize}</Typography>
              </div>
              <DownloadIcon />
            </div>
          </Link>
        );
      })}
    </div>
  );
};
