import { useMemo } from 'react';
import { ProgressStepItemStatus } from '@components/ProgressStepper/types';
import { isAllRequiredStepsDone } from './useBuildMyLoanSteps/useBuildMyLoan.utils';

interface UseStepStatus {
  requiredStepsStatus: (ProgressStepItemStatus | undefined)[];
  isCompleted: boolean;
  isVisible?: boolean;
}

export const useStepStatus = ({
  requiredStepsStatus,
  isCompleted,
  isVisible = true,
}: UseStepStatus) => {
  return useMemo(() => {
    if (!isVisible) {
      return;
    }

    if (!isAllRequiredStepsDone(requiredStepsStatus)) {
      return ProgressStepItemStatus.LOCKED;
    }

    if (isCompleted) {
      return ProgressStepItemStatus.DONE;
    }

    return ProgressStepItemStatus.IN_PROGRESS;
  }, [requiredStepsStatus, isCompleted]);
};
