import React, { memo, useEffect } from 'react';
// MUI
import { Grid, Paper, LinearProgress } from '@mui/material';
// Nav
import { Outlet, useParams, useLocation } from 'react-router-dom';
// Libraries
import { FormProvider, useForm } from 'react-hook-form';
// API
import { useGetApiV1ApprovemyloanGetOverviewProgressQuery } from '@api/output/api';
// Types
import {
  ProgressStepperItem,
  ProgressIndicatorType,
} from '@components/ProgressStepper/types';
// Components
import { ProgressStepper } from '@components/ProgressStepper/ProgressStepper.component';
import { LoanFooter } from '@pages/personalLoans/components/LoanFooter/LoanFooter.component';
// Hooks
import { useStepsNavigation } from '@pages/personalLoans/hooks/useStepsNavigation';
import { useActiveStepRoute } from '../../hooks/useActiveStepRoute';
// Styles
import styles from './approveMyLoan.module.scss';

export const ApproveMyLoanLayout = memo(() => {
  // nav
  const location = useLocation();
  const { loanId = '' } = useParams();

  // API
  const { isLoading, data, refetch } =
    useGetApiV1ApprovemyloanGetOverviewProgressQuery({
      loanId: loanId,
    });

  // Data
  const newElemts: ProgressStepperItem[] = data as ProgressStepperItem[];

  // Hooks
  const { currentStep, goToNextStep, goToPreviousStep } =
    useStepsNavigation(newElemts);
  const activeStep = useActiveStepRoute(newElemts ? newElemts : []);

  // Form
  const methods = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    refetch();
  }, [location]);

  if (isLoading || !data) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  return (
    <FormProvider {...methods}>
      <section className={styles.section}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Paper elevation={0} className={styles.stepperWidget}>
              <ProgressStepper
                progressIndicatorType={ProgressIndicatorType.LINEAR}
                items={newElemts}
                headerTitle="Approve my Loan"
                activeItem={activeStep}
              />
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Outlet />
          </Grid>
        </Grid>
      </section>
      <LoanFooter
        currentStep={currentStep}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
      />
    </FormProvider>
  );
});
