import React from 'react';
import { ProgressIndicatorType, ProgressStepperItem } from '../../types/index';
import { ProgressIndicatorLinear, ProgressIndicatorStages } from '../index';

import { useProgressStepperStats } from '../../hooks/useProgressStepperStats';

interface MemoizedProgressIndicator {
  items: ProgressStepperItem[];
  progressIndicatorType?: ProgressIndicatorType;
  locked?: boolean;
}

const getProgressIndicator = (
  progressIndicatorType?: ProgressIndicatorType
) => {
  switch (progressIndicatorType) {
    case ProgressIndicatorType.LINEAR:
      return ProgressIndicatorLinear;
    case ProgressIndicatorType.STAGES:
      return ProgressIndicatorStages;
    default:
      return ProgressIndicatorLinear;
  }
};

export const MemoizedProgressIndicator = React.memo(
  ({ items, progressIndicatorType, locked }: MemoizedProgressIndicator) => {
    const ProgressIndicatorComponent = getProgressIndicator(
      progressIndicatorType
    );
    const { totalSteps, completedSteps } = useProgressStepperStats(items);
    return (
      <ProgressIndicatorComponent
        totalSteps={totalSteps}
        completedSteps={completedSteps}
        locked={locked}
      />
    );
  }
);
