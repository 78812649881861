import React from 'react';
import { ChipNavigation } from '@components/ChipNavigation/ChipNavigation.component';
import { Option } from '@typings/common';

interface ModeSwitcherProps {
  value: string | number;
  onSelect: (v: string | number) => void;
  options?: Option[];
}

export const ModeSwitcher = ({
  value,
  onSelect,
  options = [],
}: ModeSwitcherProps) => {
  return <ChipNavigation options={options} value={value} onSelect={onSelect} />;
};
