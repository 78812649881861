export const SSN_MASK = [
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  '-',
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  '-',
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
];

export const EIN_MASK = [
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export enum ENTITY_TYPE {
  INDIVIDUAL = 'individual',
  ENTITY = 'entity',
}

export const MASK_MAP = {
  [ENTITY_TYPE.INDIVIDUAL]: SSN_MASK,
  [ENTITY_TYPE.ENTITY]: EIN_MASK,
};
