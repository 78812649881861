import { useMemo } from 'react';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import { useUploadDocuments } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUploadDocuments';
import { LolaBffApiContractsModelsLoanTaskInfo } from '@api/output/api';
import { TASK_SECTIONS } from '@typings/common';
import { useStepStatus } from '../useStepStatus';

const UPLOAD_DOCUMENTS: ProgressStepperItem = {
  id: '/personal-loans/:id/build-my-loan/upload-documents',
  title: 'Upload documents',
  to: 'upload-documents',
  tooltip:
    'This step is not required to complete Order Appraisal, but you will still  be asked to submit these documents before Submission to feasibility.',
};

export const useStepUploadDocuments = (
  requiredSteps: ProgressStepItemStatus[],
  step = UPLOAD_DOCUMENTS,
  section = TASK_SECTIONS.UPLOAD_DOCUMENTS
) => {
  const documents = useUploadDocuments(section);
  const isCompleted = useMemo(() => {
    return documents.every((document) => {
      const tasks =
        (document.tasks as LolaBffApiContractsModelsLoanTaskInfo[]) ?? [];
      return tasks.every((task) => task.documents?.[0]);
    });
  }, [documents]);

  const status = useStepStatus({
    requiredStepsStatus: requiredSteps,
    isCompleted,
  });

  return useMemo(
    () => ({
      ...step,
      status,
    }),
    [step, status]
  );
};
