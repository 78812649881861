import { NavigationConfig, ROLES } from '@typings/common';

export const PORTAL_TEAM_MEMBERS_ACTIONS_ID = 'portal-team-members-actions-id';
export const PORTAL_TEAM_MANAGEMENT_FOOTER_ID =
  'portal-team-management-footer-id';

export const TEAM_MANAGEMENT_BASE_PATH = '/team-management';

const LOANS_TAB: NavigationConfig = {
  to: `${TEAM_MANAGEMENT_BASE_PATH}/loans`,
  value: `${TEAM_MANAGEMENT_BASE_PATH}/loans`,
  label: 'Loans',
};

export const MEMBERS_TAB: NavigationConfig = {
  to: `${TEAM_MANAGEMENT_BASE_PATH}/members`,
  value: `${TEAM_MANAGEMENT_BASE_PATH}/members`,
  label: 'Members',
};

const FULL_MENU = [LOANS_TAB, MEMBERS_TAB];

export const TEAM_MANAGEMENT_TABS: Partial<Record<ROLES, NavigationConfig[]>> =
  {
    [ROLES.KEY_BROKER_MANAGER]: FULL_MENU,
    [ROLES.BROKER_MANAGER]: FULL_MENU,
    [ROLES.PROCESSOR]: [LOANS_TAB],
  };

export const ROLE_DEFAULT_TAB: Partial<Record<ROLES, NavigationConfig>> = {
  [ROLES.PROCESSOR]: LOANS_TAB,
};
