import { useMemo } from 'react';
import { ProgressStepperItem } from '@components/ProgressStepper/types';
import { useActiveRoute } from '@hooks/useActiveRoute';
import { NavigationConfig } from '@typings/common';

export const convertStepsToArr = (
  steps: ProgressStepperItem[]
): NavigationConfig[] => {
  const result: NavigationConfig[] = [];

  for (const step of steps) {
    if (step.items) {
      result.push(...convertStepsToArr(step.items));
    } else {
      result.push({
        to: step.to || '',
        value: step.id,
        label: step.title,
        metadata: {
          status: step.status,
          hasButton: step.hasButton,
          percentComplete: step.percentComplete,
        },
      });
    }
  }

  return result;
};

export const useActiveStepRoute = (steps: ProgressStepperItem[]) => {
  const flatSteps = useMemo(() => convertStepsToArr(steps), [steps]);
  return useActiveRoute(flatSteps)[0];
};
