import React, { PropsWithChildren } from 'react';
import { Button, Grid } from '@mui/material';
import { Modal } from '@components/Modal';

import styles from './modalWarning.module.scss';

interface ModalWarningProps {
  open: boolean;
  title: string;
  handleClose: () => void;
}

export const ModalWarning = ({
  open,
  title,
  handleClose,
  children,
}: PropsWithChildren<ModalWarningProps>) => {
  return (
    <Modal title={title} isOpen={open} onClose={handleClose}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {children}
        </Grid>
        <Grid item xs={12} classes={{ root: styles.footer }}>
          <Button
            variant="contained"
            onClick={handleClose}
            classes={{ root: styles.button }}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
