import { useCallback } from 'react';
import { get, set, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FormData } from '@form-configs/types';
import {
  getNearestId,
  getPartyFieldPath,
} from '@pages/personalLoans/pages/buildMyLoan/utils/fields.utils';
import { useUpdateProperties } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateProperties';
import { useStatusToastsHandlers } from '@hooks/useStatusToastsHandlers';
import { CustomValueType } from '@typings/common';
import {
  UPDATE_MODE,
  useUpdateParty,
} from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateParty';
import { useUpdateLoan } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateLoan';
import { useUpdateBuildMyLoanCache } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateBuildMyLoanCache';
import { isLoanDisabled } from '@pages/personalLoans/pages/buildMyLoan/utils/loan.utils';

export const useOnChange = ({
  setValue,
  setError,
  getValues,
}: UseFormReturn<FormData>) => {
  const { loanId = '' } = useParams();
  const updateLoan = useUpdateLoan();
  const {
    updatePartyPropertyCache,
    updateLoanCache,
    updateLoanPropertiesCache,
  } = useUpdateBuildMyLoanCache();
  const updateProperty = useUpdateProperties();
  const updateParty = useUpdateParty();

  const { successHandler, errorHandler } = useStatusToastsHandlers();

  /* ALL POSSIBLE APIs CAN BE ADDED HERE */
  /* Also we can add cache updating logic from RTK on success save here */
  return useCallback(async (name: string, value: CustomValueType) => {
    const values = getValues();
    const milestone = values?.loan?.dealMetadata?.milestone;
    const loanDisabledState = isLoanDisabled(milestone);

    if (loanDisabledState) return;

    const paths = name.split('.');
    const apiName = paths.shift()?.replace(/\[\d]/, '');
    const path = paths.join('.');

    if (apiName === 'loan') {
      try {
        const payload = {
          [path]: value,
        };
        await updateLoan(loanId, payload);
        updateLoanCache(payload);
        successHandler();
      } catch (e) {
        setError(name, {});
        errorHandler(e as Error);
      }
    }

    if (apiName === 'party') {
      const mode = name.includes('.entity.')
        ? UPDATE_MODE.ENTITY
        : UPDATE_MODE.INDIVIDUAL;

      const [partyId] = getNearestId(values, name);
      const partyPath = getPartyFieldPath(name);

      try {
        await updateParty(mode, partyId, partyPath, value);
        updatePartyPropertyCache(partyId, partyPath, value);
        successHandler();
      } catch (e) {
        setError(name, {});
        errorHandler(e as Error);
      }
    }

    if (apiName === 'properties') {
      try {
        const propertyKey = /properties\[\d]/.exec(name) ?? '';
        const propertyIdPath = `${propertyKey}.id`;
        const propertyId = get(values, propertyIdPath);
        const propertyPath = name.replace(/properties\[\d]/, '');
        const payload = {
          [propertyPath]: value,
        };
        const id = await updateProperty(propertyId, payload);
        if (id) updateLoanPropertiesCache(id, payload);
        setValue(propertyIdPath, id);
        successHandler();
      } catch (e) {
        setError(name, {});
        errorHandler(e as Error);
      }
    }
  }, []);
};
