import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const yupNativeSchema = yup
  .object({
    email: yup.string().email('Incorrect email').required('Email is required'),
  })
  .required();

export const passwordFormSchema = yupResolver(yupNativeSchema);
