import React from 'react';
import { ChipProps } from '@mui/material';
import { StatusConfig, StatusKey } from '@typings/common';
import { StatusChip } from '@components/StatusChip/StatusChip.component';

interface StatusRendererRowProps {
  status?: string | null;
}

export const createStatusRenderer =
  <T extends StatusKey>(config: StatusConfig<T>, defaultChip?: ChipProps) =>
  (_: unknown, row: StatusRendererRowProps) => {
    const { status } = row;
    return (
      <StatusChip status={status} config={config} defaultChip={defaultChip} />
    );
  };
