import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { CommonEvent } from '@typings/common';
import { usePrevious } from '@hooks/usePrevious';

export const useEventHandlerWrapper = <T extends CommonEvent>(
  name: string,
  customHandler?: (name: string, value: string | boolean) => void,
  targetProperty: 'value' | 'checked' = 'value'
) => {
  const { setValue, trigger, getValues } = useFormContext();

  const currentValue = getValues(name);
  const previousValue = usePrevious(currentValue);

  return useCallback(() => {
    return async (e: T) => {
      const value = e.target[targetProperty] ?? '';

      if (value === previousValue) {
        return;
      }

      setValue(name, value);

      const isValid = await trigger(name);

      if (isValid && customHandler) {
        customHandler(name, value);
      }
    };
  }, [name, setValue, trigger, customHandler, targetProperty, previousValue]);
};
