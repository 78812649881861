import { LOAN_PRODUCTS } from '@typings/common';

export enum KEY_STATISTICS_TYPE {
  SUBMISSION = 'submission',
  LOAN_PORTFOLIO = 'loanPortfolio',
  SUBMISSION_TO_CLOSE = 'submissionToClose',
}

export const KEY_STATISTICS_TITLES = {
  [KEY_STATISTICS_TYPE.SUBMISSION]: 'Submissions',
  [KEY_STATISTICS_TYPE.LOAN_PORTFOLIO]: 'Loan portfolio',
  [KEY_STATISTICS_TYPE.SUBMISSION_TO_CLOSE]: 'Submission to close',
};

export const LOAN_PRODUCT_TITLES = {
  [LOAN_PRODUCTS.FIX_N_FLIP]: 'FixNFlip',
  [LOAN_PRODUCTS.NEW_CONSTRUCTION]: 'New Construction',
  [LOAN_PRODUCTS.RENTAL]: 'Rental',
  [LOAN_PRODUCTS.BRIDGE_PLUS]: 'Bridge Plus',
};
