import React from 'react';
import { InformationIcon } from '@lola/ui-react-components';
import { ActionBlock } from '@lola/ui-react-components';
import { MessageArea } from '@pages/personalLoans/pages/approveMyLoan/pages/reviewStatus/components/MessageArea.component';
import { RE_ISSUE_MESSAGE } from '../../reIssueCreditForm.type';

export interface ReIssueMessageProps {
  message: RE_ISSUE_MESSAGE;
}

export const ReIssueMessage = ({ message }: ReIssueMessageProps) => {
  const MESSAGE_LIST = {
    [RE_ISSUE_MESSAGE.TRIMERGE]: (
      <MessageArea
        messageStatus="error"
        title="Not a tri-merge report"
        message="The original credit report issues was not a tri-merge report, REI servicing requires a tri-merge report. Please pull a new report using New credit issue"
      />
    ),
    [RE_ISSUE_MESSAGE.FIELDS]: (
      <MessageArea
        messageStatus="error"
        title="Carefully fill in the fields"
        message="The information below does not match the information on the credit report you issued. Please check the information and continue"
      />
    ),
    [RE_ISSUE_MESSAGE.DEFAULT]: (
      <ActionBlock
        title="Carefully fill in the fields below"
        description="Make sure that the information below matches the information on the credit report you issued"
        icon={<InformationIcon />}
      ></ActionBlock>
    ),
  };

  return MESSAGE_LIST[message] || MESSAGE_LIST['default'];
};
