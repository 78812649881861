export interface ApiError {
  data?: {
    detail?: string;
    title?: string;
  };
  status?: number;
}

export type AnyError = ApiError | Error | string;

export const convertCatchError = (err: AnyError): string => {
  console.error(err);

  if (typeof err === 'string') {
    return err;
  }

  if ('data' in err && err.data) {
    return (
      err.data.title ?? err.data.detail ?? 'External error. Try again later.'
    );
  } else if ('status' in err) {
    return 'External error. Try again later.';
  }

  return 'Internal error. Try again later.';
};
