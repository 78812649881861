import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { QUOTE_TYPE } from '@typings/common';
import { FormData } from '@form-configs/types';
import { useQuotesCalculateAndSave } from '@hooks/useQuotesApi/useQuotesCalculateAndSave';
import { QUOTE_ID_AGGREGATION_PATH } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { useOpenToast } from '@hooks/useOpenToast';
import { useUpdateLoan } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateLoan';
import { useUpdateBuildMyLoanCache } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateBuildMyLoanCache';

interface UseQuotesProductApiProps {
  type: QUOTE_TYPE;
  quoteId?: string;
}

export const useQuotesProductApi = ({
  type,
  quoteId,
}: UseQuotesProductApiProps) => {
  const { save, loadById, isSaving, isQuoteLoading, data, summary } =
    useQuotesCalculateAndSave(type, quoteId);

  const { updateLoanCache } = useUpdateBuildMyLoanCache();

  const updateLoan = useUpdateLoan();
  const { loanId = '' } = useParams();
  const openToast = useOpenToast(5000);

  const saveMethod = useCallback(
    async (values: FormData) => {
      const id = await save(values);

      if (id) {
        openToast({
          title: 'Quote has been calculated and saved.',
          description:
            'Quote has been calculated and saved! You will be able to review it in the quote list.',
        });
        if (quoteId !== id) {
          try {
            const loanBody = {
              [QUOTE_ID_AGGREGATION_PATH]: id,
            };
            await updateLoan(loanId, loanBody);
            updateLoanCache(loanBody);
          } catch (error) {
            openToast({
              title: 'Quote is not attached to the loan',
            });
          }
        }
      }

      return id;
    },
    [save, quoteId, type, loanId]
  );

  return {
    save: saveMethod,
    isQuoteLoading,
    isCalculating: isSaving,
    data,
    summary,
    loadById,
  };
};
