import {
  LolaBffApiContractsModelsUser,
  LolaBffApiContractsModelsUserStatus,
} from '@api/output/api';

interface InvitedUser {
  userId: string;
  isUserInvited: boolean;
  invitedUserStatus: LolaBffApiContractsModelsUserStatus | '';
}

export const checkInvitedUser = (
  email: string,
  users: LolaBffApiContractsModelsUser[]
): InvitedUser => {
  const { userId, status } = users.find((user) => user.email === email) ?? {};
  return {
    userId: userId ?? '',
    isUserInvited: !!userId,
    invitedUserStatus: status ?? '',
  };
};
