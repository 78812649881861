import { UploaderConfig } from '@components/Uploader/Uploader.types';
import { DEFAULT_UPLOADER_CONFIG } from '@components/Uploader/Uploader.constants';
import { UploaderDrawerConfig } from '@components/UploadDrawer/uploaderDrawer.types';
import { DEFAULT_UPLOADER_DRAWER_CONFIG } from '@components/UploadDrawer/uploaderDrawer.constants';

export const ORDER_APPRAISAL_UPLOADER_CONFIG: UploaderConfig = {
  ...DEFAULT_UPLOADER_CONFIG,
  rules: {
    ...DEFAULT_UPLOADER_CONFIG.rules,
    maxFiles: 1,
  },
};

export const ORDER_APPRAISAL_DRAWER_CONFIG: UploaderDrawerConfig = {
  ...DEFAULT_UPLOADER_DRAWER_CONFIG,
  title: 'Order appraisal documents',
  description: 'Optional description of the document',
};
