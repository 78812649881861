import { useCallback, useEffect, useState } from 'react';
import { UseFormReset, UseFormClearErrors } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { BuildMyLoanFormData } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.type';
import { usePrevious } from '@hooks/usePrevious';

type UseFormDirtyStatePersistArgs = {
  data?: BuildMyLoanFormData;
  isDirty: boolean;
  reset: UseFormReset<BuildMyLoanFormData>;
  clearErrors: UseFormClearErrors<BuildMyLoanFormData>;
};

type UseFormDirtyStatePersistReturnType = {
  hasFormModified: boolean;
  clearErrorState: () => void;
};

export const useFormDirtyState = ({
  data,
  isDirty,
  reset,
  clearErrors,
}: UseFormDirtyStatePersistArgs): UseFormDirtyStatePersistReturnType => {
  const { pathname } = useLocation();
  const [hasFormModified, setHasFormModified] = useState(false);
  const prevData = usePrevious(data);

  const clearErrorState = useCallback(() => {
    setHasFormModified(false);
    clearErrors();
    reset(data, { keepValues: true });
  }, [setHasFormModified, data]);

  useEffect(() => {
    clearErrorState();
  }, [pathname, clearErrorState]);

  useEffect(() => {
    if (isDirty || (data && prevData && !isEqual(data, prevData))) {
      setHasFormModified(true);
    }
  }, [isDirty, data, prevData]);

  return {
    hasFormModified,
    clearErrorState,
  };
};
