import React from 'react';
import { DynamicItems } from '@components/DynamicForm/components/DynamicItems/DynamicItems.component';
import { Collapsed } from '@components/Collapsed';
import { CollapseProps, FieldConfig } from '@form-configs/types';

export interface DynamicCollapsedProps extends CollapseProps {
  items?: FieldConfig[];
}

export const DynamicCollapsed = ({
  items = [],
  ...props
}: DynamicCollapsedProps) => {
  return (
    <Collapsed {...props}>
      <DynamicItems fields={items} />
    </Collapsed>
  );
};
