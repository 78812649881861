import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto } from '@api/output/api';
import { LoanPartyInfo } from '@typings/common';
import { useLolaTasks } from '@hooks/useLolaTasks';

interface UsePartyTaskStatuses {
  parties: LoanPartyInfo[];
  taskType: string;
  defaultStatus?: string;
  searchProp?: keyof LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto;
  isSearchByDeal?: boolean;
}

export const usePartyTaskStatuses = ({
  parties,
  taskType,
  defaultStatus = 'Not Sent',
  searchProp = 'entity2Id',
  isSearchByDeal = false,
}: UsePartyTaskStatuses) => {
  const {
    tasks,
    refetchTasks: refetchStatuses,
    areTasksFetching: isStatusLoading,
  } = useLolaTasks(!parties.length);
  const { loanId = '' } = useParams();
  const partyStatuses = useMemo(() => {
    return parties.map((party) => {
      const task = tasks.find((task) => {
        const searchId = isSearchByDeal ? loanId : party?.id;
        return searchId === task?.[searchProp] && taskType === task?.taskType;
      });
      return {
        ...party,
        completion: task?.completionStatus,
        status: task ? task.displayStatus : defaultStatus,
      };
    });
  }, [parties, tasks, taskType, isStatusLoading]);

  return {
    partyStatuses,
    isStatusLoading,
    refetchStatuses,
  };
};
