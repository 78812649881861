import React, { useContext, useEffect } from 'react';
import { FormProvider, useFormContext, useWatch } from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  useLicensingCheck,
  useLicenseThirdPartyCheck,
} from '@pages/personalLoans/pages/buildMyLoan/hooks/useLicenseCheck';
import { QUOTE_TYPE } from '@typings/common';
import { LicensingFormValid } from './components/LicensingFormValid.component';
import { LicensingFormInvalid } from './components/LicensingFormInvalid.component';
import { LicensingFormContext } from './LicensingForm.context';

export const LicensingForm = () => {
  const quoteContext = useFormContext();
  const licensesForm = useContext(LicensingFormContext);
  const quoteType = useWatch({ name: 'quoteType' });
  const fieldName =
    quoteType === QUOTE_TYPE.SHORT_TERM ? 'loanSummary.state' : 'state';

  const currentStateCode = useWatch({ name: fieldName });

  const { isLicenseRequired, isBrokerLicenseValid } =
    useLicensingCheck(currentStateCode);

  const isThirdPartyLicenseProvided = useLicenseThirdPartyCheck({
    control: licensesForm.control,
  });

  useEffect(() => {
    if (isLicenseRequired === false) {
      licensesForm.reset({});
    }
  }, [isLicenseRequired]);

  useEffect(() => {
    if (
      !isLicenseRequired ||
      isBrokerLicenseValid ||
      isThirdPartyLicenseProvided
    ) {
      quoteContext.clearErrors(fieldName);
      return;
    }

    quoteContext.setError(fieldName, {
      type: 'custom',
    });
  }, [isLicenseRequired, isBrokerLicenseValid]);

  if (!isLicenseRequired) {
    return null;
  }
  return (
    <FormProvider {...licensesForm}>
      <Grid container spacing={2}>
        {!isBrokerLicenseValid && (
          <LicensingFormInvalid stateCode={currentStateCode} />
        )}
        {isBrokerLicenseValid && <LicensingFormValid />}
      </Grid>
    </FormProvider>
  );
};
