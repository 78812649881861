import { StartEndPeriod } from '@utils/date.utils';

export interface PeriodConfig extends StartEndPeriod {
  value: 'all' | 'year' | 'custom';
}

export const PERIOD_OPTIONS = [
  {
    label: 'All time',
    value: 'all',
  },
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];
