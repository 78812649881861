import React from 'react';
import { Divider, Grid } from '@mui/material';
import { DynamicFields } from '@components/DynamicForm';
import { Collapsed } from '@components/Collapsed';
import { SelectGuarantor } from '../SelectGuarantor/SelectGuarantor.component';
import { EntityBlock, EntityOwnership } from './components';
import { guarantorsForm } from './FullApplicationForm.constants';

import styles from './fullApplicationForm.module.scss';

export const FullApplicationForm = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <EntityBlock />
      </Grid>
      <Grid item xs={12}>
        <Divider classes={{ root: styles.divider }} />
      </Grid>
      <Grid item xs={12}>
        <Collapsed label="Guarantors" defaultExpanded>
          <DynamicFields
            fields={guarantorsForm}
            components={{
              guarantorNewExisting: SelectGuarantor,
            }}
          />
        </Collapsed>
      </Grid>
      <Grid item xs={12}>
        <Divider classes={{ root: styles.divider }} />
      </Grid>
      <Grid item xs={12}>
        <EntityOwnership />
      </Grid>
    </Grid>
  );
};
