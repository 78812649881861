import React, { ReactNode } from 'react';
// MUI
import { Paper, Typography } from '@mui/material';
// icons
import {
  StatusAlertIcon,
  StatusDoneIcon,
  StatusInProgressIcon,
} from '@lola/ui-react-components';
// style
import style from './CardStatus.module.scss';

export enum STATUS_LIST {
  IN_PROGRESS = 'InProgress',
  DONE = 'Done',
  INFO = 'Info',
  ERROR = 'Error',
  SUSPENDED = 'Suspended',
}

export interface CardStatusProps {
  title?: string;
  status: STATUS_LIST;
  children?: ReactNode;
  header?: ReactNode;
}

const ICON_LIST = {
  [STATUS_LIST.IN_PROGRESS]: (
    <StatusInProgressIcon
      data-testid="status-icon"
      className={style.colorProgress}
    />
  ),
  [STATUS_LIST.INFO]: (
    <StatusInProgressIcon
      data-testid="status-icon"
      className={style.colorProgress}
    />
  ),
  [STATUS_LIST.DONE]: (
    <StatusDoneIcon data-testid="status-icon" className={style.colorDone} />
  ),
  [STATUS_LIST.ERROR]: (
    <StatusAlertIcon data-testid="status-icon" className={style.colorError} />
  ),
  [STATUS_LIST.SUSPENDED]: (
    <StatusAlertIcon data-testid="status-icon" className={style.colorError} />
  ),
};

export const CardStatusComponent = ({
  title,
  status,
  children,
  header,
}: CardStatusProps): JSX.Element => {
  return (
    <section className={style.statusComponent}>
      <Paper elevation={0} className={style.container}>
        {header}
        {ICON_LIST[status]}
        {title && (
          <Typography variant="pBold" className={style.title}>
            {title}
          </Typography>
        )}
        {children}
      </Paper>
    </section>
  );
};
