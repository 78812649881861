import React, { ChangeEvent, useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import {
  getFeatures,
  resetFeatures,
  toggleFeatureInStorage,
} from '@utils/feature.util';

import styles from './setting.module.scss';

export const Settings = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const toggleFeature = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    toggleFeatureInStorage(name);
    setFeatures(getFeatures());
  }, []);

  const reset = useCallback(() => {
    resetFeatures();
    setFeatures(getFeatures);
  }, []);

  const [features, setFeatures] = useState(getFeatures());

  return (
    <div>
      <div tabIndex={-1} onClick={toggleDrawer} className={styles.control} />
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={toggleDrawer}
        className={styles.drawer}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" m={0}>
              You can turn on experimental feature here.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {features.map(({ label, name, value }) => (
              <Grid item key={name} xs={12} sm={6} lg={4}>
                <FormControlLabel
                  label={label}
                  control={
                    <Checkbox
                      name={name}
                      checked={value}
                      onChange={toggleFeature}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={reset}>
              Reset features
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};
