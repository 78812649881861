import { LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto } from '@api/output/api';
import { ProgressStepItemStatus } from '@components/ProgressStepper/types';
import { COMPLETION_STATUS } from './useBuildMyLoan.constants';

export const isAllTasksCompletedForId = (
  ids: string[],
  taskNames?: string[],
  tasks?: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto[]
) => {
  const idsSet = new Set(ids);

  tasks?.forEach((task) => {
    if (
      idsSet.has(task.entity2Id ?? '') &&
      taskNames?.includes(task.taskType ?? '') &&
      task.completionStatus === COMPLETION_STATUS
    ) {
      idsSet.delete(task.entity2Id ?? '');
    }
  });

  return idsSet.size === 0;
};

export const isAllTasksCompleted = (
  taskNames?: string[],
  tasks?: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto[]
) => {
  const requiredTasks = tasks?.filter(({ taskType }) =>
    taskNames?.includes(taskType ?? '')
  );

  return !!(
    requiredTasks?.length === taskNames?.length &&
    requiredTasks?.every((task) => task.completionStatus === COMPLETION_STATUS)
  );
};

export const checkTaskCompletion = (
  partyIds?: string[],
  taskNames?: string[],
  tasks?: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto[]
) => {
  return partyIds?.length
    ? isAllTasksCompletedForId(partyIds, taskNames, tasks)
    : isAllTasksCompleted(taskNames, tasks);
};

export const isAllRequiredStepsDone = (
  requiredStepsStatus: (ProgressStepItemStatus | undefined)[]
) =>
  requiredStepsStatus
    ?.filter((status) => status)
    ?.every(
      (stepStatus) =>
        stepStatus === ProgressStepItemStatus.DONE ||
        stepStatus === ProgressStepItemStatus.ALERT
    );

export const hasTaskWithStatus = (
  taskNames: string[],
  status: string,
  tasks?: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto[]
) => {
  const requiredTasks = tasks?.filter(({ taskType }) =>
    taskNames.includes(taskType ?? '')
  );

  return (
    !!requiredTasks?.length &&
    requiredTasks?.some(({ completionStatus }) => completionStatus === status)
  );
};
