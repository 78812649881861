import { LolaBffApiContractsModelsLoanAddress } from '@api/output/api';

export const convertAddressToTitle = (
  address?: LolaBffApiContractsModelsLoanAddress
) => {
  if (!address) {
    return '';
  }

  let result = '';

  if (address.postalCode) {
    result += `${address.postalCode} `;
  }

  if (address.addressLineText) {
    result += `${address.addressLineText}`;
  }

  if (address.stateCode) {
    result += `, ${address.stateCode}`;
  }

  return result;
};
