import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { VERTICAL_STATUS_STATE } from '@components/verticalStepDetail/VerticalStepDetail.component';
import { StatusIcon } from '../../../../../StatusIcon/StatusIcon.component';

import styles from './stepInfoItem.module.scss';

export interface StepInfoItemProps {
  step: string;
  currentState: VERTICAL_STATUS_STATE;
}

export const StepInfoItem = ({ step, currentState }: StepInfoItemProps) => {
  const icon = useMemo(
    () => <StatusIcon status={currentState} className={styles.icon} />,
    [currentState]
  );

  const text = useMemo(() => {
    if (!step) {
      return 'Waiting';
    }

    switch (currentState) {
      case VERTICAL_STATUS_STATE.ERROR:
        return `${step} failed`;
      case VERTICAL_STATUS_STATE.IN_PROGRESS:
        return `Waiting for ${step?.toLowerCase()}`;
      case VERTICAL_STATUS_STATE.DONE:
        return `Waiting for the next step`;
    }
  }, [step, currentState]);

  return (
    <>
      {icon}
      <Typography variant="pBold">{text}</Typography>
    </>
  );
};
