import { useMemo } from 'react';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';

const PROPERTIES: ProgressStepperItem = {
  id: 'Product & property',
  title: 'Product & property',
};

export const useStepProductAndProperties = (
  requiredSteps: ProgressStepperItem[]
) => {
  return useMemo(() => {
    const isAllCompleted = requiredSteps.every(
      ({ status }) => status === ProgressStepItemStatus.DONE
    );

    const isSomeErrors = requiredSteps.some(
      ({ status }) => status === ProgressStepItemStatus.ALERT
    );

    let status = ProgressStepItemStatus.IN_PROGRESS;
    if (isSomeErrors) {
      status = ProgressStepItemStatus.ALERT;
    }
    if (isAllCompleted) {
      status = ProgressStepItemStatus.DONE;
    }

    return {
      ...PROPERTIES,
      status,
      items: requiredSteps,
    };
  }, requiredSteps);
};
