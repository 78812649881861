export const getNumberPostfix = (
  value: number,
  singularLabel: string,
  pluralLabel: string
): string => (value === 1 ? singularLabel : pluralLabel);

export const getTemplateString = (
  values: string[],
  startEndSymbols = '()'
): string => {
  if (!values.length) return '';

  if (values.length === 1) return values[0];

  const [firstValue, ...restValues] = values;
  const [start, end] = startEndSymbols.split('');
  return `${firstValue} ${start}${restValues.join(', ')}${end}`;
};

export const normalizeCamelCase = (value = ''): string =>
  [...value]
    .map((letter, index) => {
      if (index === 0) {
        return letter.toUpperCase();
      }
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`;
      }
      return letter;
    })
    .join('');

type PlaceholderValues = Record<string, string>;

export const getCorrectedText = (
  text: string,
  placeholderValues: PlaceholderValues
) => {
  let result = '';
  Object.entries(placeholderValues).forEach(([key, value]) => {
    result = text.replace(key, value);
  });
  return result;
};
