import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TableColumnType } from 'antd';
import { Grid } from '@mui/material';
import { ListWithAction } from '@components/ListWitAction/ListWithAction.component';
import { LoanPartyInfo, QUOTE_TYPE } from '@typings/common';
import { userInfoRenderer } from '@pages/personalLoans/renders';
import { useParties } from '@pages/personalLoans/pages/buildMyLoan/hooks/useParties';
import { usePartyTaskStatuses } from '@pages/personalLoans/pages/buildMyLoan/hooks/usePartyTaskStatuses';
import { createStatusRenderer } from '@utils/renderers/statusRenderer/createStatusRenderer';
import { RadioGroup } from '@components/RadioGroup';
import {
  SEND_QUOTE_STATUS_CONFIG,
  SEND_QUOTE_STATUS_TYPE,
  SEND_QUOTE_TASKS,
  SEND_LOAN_TYPE,
  SEND_LOAN_OPTIONS,
  RADIO_GROUP_NAME,
} from './sendQuote.constants';
import { useSendQuote } from './hooks/useSendQuote';
const columns: TableColumnType<LoanPartyInfo>[] = [
  {
    dataIndex: 'firstName',
    width: '30%',
    render: userInfoRenderer,
  },
  {
    dataIndex: 'contactPointEmailValue',
    width: '50%',
  },
  {
    dataIndex: 'status',
    width: '20%',
    render: createStatusRenderer(SEND_QUOTE_STATUS_CONFIG),
  },
];

interface SendQuoteProps {
  taskName: SEND_QUOTE_TASKS;
  quoteId?: string;
  quoteType?: QUOTE_TYPE;
  parties?: LoanPartyInfo[];
  isDisabled?: boolean;
  onRadioChange?: (value: SEND_LOAN_TYPE) => void;
}

export const SendQuote = ({
  parties,
  isDisabled = false,
  taskName,
  quoteId,
  quoteType,
  onRadioChange,
}: SendQuoteProps) => {
  const [sendLoan, setSendLoan] = useState(SEND_LOAN_TYPE.NO);

  const sendLoanChangeHandler = useCallback(
    (_: unknown, value: string) => {
      const typedValue = value as SEND_LOAN_TYPE;
      setSendLoan(typedValue);
      onRadioChange?.(typedValue);
    },
    [setSendLoan]
  );

  const isFinal = taskName === SEND_QUOTE_TASKS.FINAL;

  const { disabled, sendQuote, isLoading } = useSendQuote({
    isFinal,
    quoteId,
    quoteType,
  });

  const borrower = useMemo(() => {
    return parties?.[0] ? [parties[0]] : [];
  }, [parties]);

  const { partyStatuses } = usePartyTaskStatuses({
    parties: borrower,
    taskType: taskName,
    defaultStatus: SEND_QUOTE_STATUS_TYPE.NOT_SEND,
  });

  const currentStatus = useMemo(() => {
    if (!partyStatuses.length) {
      return '';
    }
    return partyStatuses[0].status ?? '';
  }, [partyStatuses]);

  useEffect(() => {
    const statuses: string[] = [
      SEND_QUOTE_STATUS_TYPE.APPROVED,
      SEND_QUOTE_STATUS_TYPE.SENT,
    ];
    const isTriggered = statuses.includes(currentStatus);
    if (isTriggered) {
      setSendLoan(SEND_LOAN_TYPE.YES);
    }
  }, [currentStatus]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <RadioGroup
          name={RADIO_GROUP_NAME.SEND_LOAN}
          label="Do you want to send a loan quote to your borrower?"
          options={SEND_LOAN_OPTIONS}
          value={sendLoan}
          onChange={sendLoanChangeHandler}
          row
          major
        />
      </Grid>
      {sendLoan === SEND_LOAN_TYPE.YES && (
        <Grid item xs={12}>
          <ListWithAction
            columns={columns}
            dataSource={partyStatuses}
            isLoading={isLoading}
            submitHandler={sendQuote}
            submitLabel="Send quote"
            isSubmitDisabled={disabled || isDisabled}
            isSelectionEnabled={false}
          />
        </Grid>
      )}
    </Grid>
  );
};
