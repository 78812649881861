import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { FileUploaderControl } from '@components/FileUploader';
import { useOpenState } from '@hooks/useOpenState';
import { DynamicUploaderProps } from '@form-configs/types';
import { TaskFilesUploadDrawer } from '@containers/TaskFilesUploadDrawer/TaskFilesUploadDrawer.component';
import { FormConfigContext } from '@components/DynamicForm/contexts/FormConfig.context';

export const DynamicUploader = ({ name, ...props }: DynamicUploaderProps) => {
  const [isOpen, open, close] = useOpenState();
  const { disabled } = useContext(FormConfigContext);

  const { watch } = useFormContext();

  const uploadedFiles = watch(name);

  return (
    <>
      <FileUploaderControl
        name={name}
        uploadedFiles={uploadedFiles || []}
        onClickIfFileDoesntExist={open}
        onClickIfFileExists={open}
        {...props}
        disabled={props?.disabled || disabled}
      />
      <TaskFilesUploadDrawer
        isOpen={isOpen}
        onClose={close}
        uploadedFiles={uploadedFiles || []}
      />
    </>
  );
};
