import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { IconButton, TextField } from '@mui/material';
import { SearchIcon } from '@lola/ui-react-components';
import styles from './dataSearch.module.scss';

export interface DataSearchProps {
  handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  defaultSearchValue?: string;
  searchPlaceholder?: string;
}

export const DataSearch = ({
  handleSearchChange,
  defaultSearchValue,
  searchPlaceholder,
}: DataSearchProps) => {
  // state
  const [inputValue, setInputValue] = useState<string | undefined>(
    defaultSearchValue
  );

  // actions
  const handledValue = useCallback((value: ChangeEvent<HTMLInputElement>) => {
    setInputValue(value.target.value);
    handleSearchChange(value);
  }, []);

  useEffect(() => {
    if (defaultSearchValue === '') {
      setInputValue('');
    }
  }, [defaultSearchValue]);

  return (
    <TextField
      autoComplete="off"
      variant="standard"
      placeholder={searchPlaceholder || 'Search'}
      size="small"
      fullWidth
      onChange={handledValue}
      className={styles.search}
      defaultValue={defaultSearchValue}
      value={inputValue}
      InputProps={{
        startAdornment: (
          <IconButton className={styles.searchIcon}>
            <SearchIcon />
          </IconButton>
        ),
        inputProps: {
          className: styles.searchInput,
        },
      }}
    />
  );
};
