import { Quote, QUOTE_TYPE, QuoteSummaryItem } from '@typings/common';
import {
  KeyAssertion,
  SUMMARY_ITEM_KEYS,
} from '@pages/scenarioBuilder/pages/quoteForm/QuoteForm.types';
import {
  SUMMARY_ITEM_TITLES,
  SUMMARY_ITEMS,
} from '@pages/scenarioBuilder/pages/quoteForm/QuoteForm.constants';
import { getTitle } from '@pages/scenarioBuilder/pages/quoteForm/QuoteForm.utils';

export type QuoteSummary = Partial<Record<SUMMARY_ITEM_KEYS, QuoteSummaryItem>>;

export const getQuoteSummary = (
  data: Quote,
  type: QUOTE_TYPE
): QuoteSummary => {
  const summaryItems =
    type === QUOTE_TYPE.RENTAL
      ? [...SUMMARY_ITEMS, SUMMARY_ITEM_KEYS.VALIDATION_WARNING]
      : SUMMARY_ITEMS;

  return summaryItems.reduce((res, key) => {
    let items: string[] = [];
    if (data?.out?.[key as KeyAssertion]) {
      items =
        data?.out?.[key as KeyAssertion]
          ?.split(/[\u2022\u25CF]/)
          ?.map((item: string) => item?.trim())
          ?.filter((item: string) => !!item) ?? [];
    }

    if (data?.out && 'scenarios' in data.out && data.out.scenarios) {
      items = data.out.scenarios
        .flatMap((scenario) => {
          const scenarioKey = key as keyof typeof scenario;
          const values = scenario?.[scenarioKey] as string;
          return values?.split(/[\u2022\u25CF]/);
        })
        .map((item: string) => item?.trim())
        .filter((warning) => !!warning) as string[];
    }

    return !items.length
      ? res
      : {
          ...res,
          [key]: {
            title: getTitle({
              count: items.length,
              titleConfig: SUMMARY_ITEM_TITLES[key],
            }),
            items,
          },
        };
  }, {});
};
