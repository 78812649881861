import { useMemo } from 'react';
import { ROLES, USER_STATUS } from '@typings/common';
import { useRole } from '@hooks/useRole';

const ROLES_REMOVABLE_BY: Partial<Record<ROLES, ROLES[]>> = {
  [ROLES.KEY_BROKER_MANAGER]: [ROLES.KEY_BROKER_MANAGER, ROLES.BROKER_MANAGER],
};

export const useRemovePermission = (
  role?: string,
  status?: string
): boolean => {
  const currentUserRole = useRole();

  return useMemo(() => {
    if (status === USER_STATUS.ARCHIVED) {
      return false;
    }

    if (ROLES_REMOVABLE_BY[currentUserRole]?.includes(role as ROLES)) {
      return true;
    }

    return false;
  }, [currentUserRole, role, status]);
};
