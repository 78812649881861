import React from 'react';
import { ChipProps } from '@mui/material';
import {
  LolaBffApiContractsModelsLoanPartyInfo,
  LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse,
  LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteByIdResponse,
} from '@api/output/api';

export interface CommonError {
  message?: string;
  error?: string;
  data?: Record<string, unknown>;
}

export interface NavigationConfig {
  to: string;
  value: string;
  label: string;
  /* Service property with metadata */
  metadata?: Record<string, string | undefined | boolean | number>;
}

export interface Option<T = string | number> {
  label: string;
  value: T;
  disabled?: boolean;
  description?: string;
  icon?: React.ReactNode;
}

export enum ROLES {
  NOT_SET = 'NotSet',
  KEY_BROKER_MANAGER = 'KeyBrokerManager',
  BROKER_MANAGER = 'BrokerManager',
  PROCESSOR = 'Processor',
  BROKER = 'Broker',
}

export enum LEGAL_ROLES {
  KEY_BROKER_MANAGER = 'KeyBrokerManager',
  BROKER_MANAGER = 'BrokerManager',
  PROCESSOR = 'Processor',
  BROKER = 'Broker',
}

export enum USER_STATUS {
  NOT_SET = 'NotSet',
  ACTIVE = 'Active',
  INVITED = 'Invited',
  ARCHIVED = 'Archived',
}

export enum LOAN_PRODUCTS {
  FIX_N_FLIP = 'fixNFlip',
  NEW_CONSTRUCTION = 'newConstruction',
  RENTAL = 'rental30',
  BRIDGE_PLUS = 'bridgePlus',
}

export enum LOAN_PROGRAM {
  FIX_N_FlIP = 'FixNFlip',
  RENTAL_30 = 'Rental30',
  NEW_CONSTRUCTION = 'New Construction',
  RENTAL_30_PREMIERE = 'Rental30 Premier',
  BRIDGE_PLUS = 'BridgePlus',
}

export enum LOAN_PDF_APPLICATION_TYPE {
  BORROWER_GUARANTOR_APP = 'BorrowerGuarantorApp',
  GUARANTOR_APP = 'GuarantorApp',
  FIX_N_FLIP_BRIDGE_APP = 'FixNFlipBridgeApp',
  NEW_CONSTRUCTION_APP = 'NewConstructionApp',
  RENTAL_SINGLE_30APP = 'RentalSingle30App',
  PORTFOLIO_APP = 'PortfolioApp',
}

/* Should be used for preventing error when map react-hook-form handler to jsx attribute */
export type NonPromiseSubmitHandler = (e?: React.BaseSyntheticEvent) => void;

export enum LOAN_STATUS {
  IN_REVIEW = 'InReview',
  IN_DEVELOPMENT = 'InDevelopment',
  SUBMITTED = 'Submitted',
  CLOSED = 'Closed',
  CLOSING = 'Closing',
  DELIVERED = 'Delivered',
  ARCHIVED = 'Archived',
  NA = 'NA',
}

export enum LOAN_MILESTONE {
  NEW_DEAL = 'New Deal',
  INITIAL_DEAL_REVIEW = 'Initial Deal Review',
  PROCESSING = 'Processing',
  SUSPENDED = 'Suspended',
  FINAL_DEAL_REVIEW = 'Final Deal Review',
  FINAL_DEAL_REVIEW_APPROVED = 'Final Deal Review Approved',
  FINAL_QUOTE_ISSUED = 'Final Quote Issued',
  FINAL_QUOTE_ACCEPTED = 'Final Quote Accepted',
  FINAL_CLOSING_UPDATES_REQUESTED = 'Final Closing Updates Requested',
  CLEAR_TO_CLOSE = 'Clear to Close',
  LOAN_PACKAGE_SENT = 'Loan Package Sent',
  LOAN_PACKAGE_RECEIVED = 'Loan Package Received',
  FUNDED = 'Funded',
  CLOSE_OF_ESCROW = 'Close of Escrow',
  FILE_COMPLETE = 'File Complete',
  DEAD_DEAL = 'Dead Deal',
  EXIT_STATE = 'Exit State',
  PRE_SUBMISSION = 'Pre-Submission Deal',
}

export type StatusKey = string | number | symbol;

export type StatusConfig<T extends StatusKey> = Partial<Record<T, ChipProps>>;

export const LOAN_PRODUCT_TITLES = {
  [LOAN_PRODUCTS.FIX_N_FLIP]: 'FixNFlip',
  [LOAN_PRODUCTS.NEW_CONSTRUCTION]: 'New construction',
  [LOAN_PRODUCTS.RENTAL]: 'Rental',
  [LOAN_PRODUCTS.BRIDGE_PLUS]: 'Bridge Plus',
};

export enum TASK_COMPLETION_STATUS {
  UNKNOWN = 'Unknown',
  IN_PROGRESS = 'InProgress',
  SUCCESS = 'Success',
  FAIL = 'Fail',
}

export type CompareFn<T> = (itemA: T, itemB: T) => number;
export type SetModalContent<T> = (content: T) => void;

export interface LoanPartyInfo extends LolaBffApiContractsModelsLoanPartyInfo {
  completion?: string | null;
  status?: string | null;
  relationRole?: string;
}

export enum QUOTE_TYPE {
  SHORT_TERM = 'STF',
  RENTAL = 'Rental',
}

export enum QUOTE_ACCORDION_VALUES {
  DETAILS = 'details',
  CALCULATION = 'calculation',
}

export const QUOTE_TYPE_LABELS = {
  [QUOTE_TYPE.SHORT_TERM]: 'FixNFlip-Bridge or New Construction',
  [QUOTE_TYPE.RENTAL]: 'Rental',
};

export interface QuoteSummaryItem {
  title: string;
  items: string[];
}

export type Quote =
  | LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse
  | LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteByIdResponse;

export enum PARTY_TYPE {
  INDIVIDUAL = 'Individual',
  ENTITY = 'Entity',
}

export enum ROLE_TYPE {
  PRIMARY_ENTITY = 'Primary Borrower',
  PRIMARY_INDIVIDUAL = 'Primary Sponsor',
  OTHER_INDIVIDUAL = 'Sponsor',
}

export type CommonEvent = {
  target: {
    value?: string | boolean;
    checked?: boolean;
  };
};

export type CommonHandler<T> = ((e: T) => void) | undefined;
export type ChangeHandler = (name: string, value: CustomValueType) => void;
export type RemoveHandler = (
  entity: string,
  id: string,
  index?: number
) => void;
export type AddHandler = (entity: string, index: number) => void;

export type CustomValueType =
  | undefined
  | string
  | boolean
  | (string | number)[];

export enum CONFIGURED_TASKS {
  AUTHORIZATION = 'BorrowerAuthorizationTask',
  CREDIT_REISSUE = 'CreditReissueTask',
  CREDIT_REPORT = 'CreditReportTask',
  DEAL_APPLICATION = 'DealApplicationTask',
  PORTFOLIO = 'PortfolioRequestTask',
  SPONSOR_APPLICATION = 'SponsorApplicationTask',
}

export enum TASK_SECTIONS {
  CREDIT_REPORT = 'credit_report',
  BORROWER_UPLOAD_DOCUMENTS = 'borrower_upload_documents',
  LOAN_APPLICATION = 'loan_application',
  CONSTRUCTION_BUDGET = 'construction_budget',
  APPRAISAL = 'appraisal',
  UPLOAD_DOCUMENTS = 'upload_documents',
  PORTFOLIO = 'portfolio',
}

export enum LICENSE_STATUS_TYPE {
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  EXPIRED_IN_30_DAYS = 'ExpiredIn30Days',
}

export enum TEAM_ASSIGNMENT_STATUS {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
}

export type AnyObject = {
  [k: string]: any;
};
