import React, { useContext, useMemo } from 'react';
import { FieldConfig } from '@form-configs/types';

import { ComponentsContext } from '@components/DynamicForm/contexts/Components.context';

export type DynamicItemProps = FieldConfig;

export const Debug = (props: FieldConfig['props']) => {
  return <div>{JSON.stringify(props)}</div>;
};

export const DynamicItem = ({
  controlType,
  items = [],
  props,
}: DynamicItemProps) => {
  const componentsMap = useContext(ComponentsContext);

  const { Component, correctProps } = useMemo(() => {
    let correctControlType = controlType;
    if (controlType === 'custom') {
      correctControlType = props?.controlType;
    }

    const correctProps = { ...props };
    if (items) {
      correctProps.items = items;
    }

    if (controlType === 'text' || controlType === 'number') {
      correctProps.type = controlType;
    }

    return {
      Component: componentsMap[correctControlType] || Debug,
      correctProps: {
        ...correctProps,
        controlType: correctControlType,
      },
    };
  }, [controlType, items]);

  return <Component {...correctProps} />;
};
