import React, { useCallback } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import { FileDownloadIcon } from '@lola/ui-react-components';
import {
  LolaBffApiContractsModelsLoanTaskTemplateInfo,
  useLazyGetApiV1DocumentTemplatesGetDownloadUrlQuery,
} from '@api/output/api';
import { useOpenToast } from '@hooks/useOpenToast';
import { ApiError } from '@utils/errors.utils';
import { downloadFile } from '@utils/file.utils';

interface DownloadButtonProps {
  template: LolaBffApiContractsModelsLoanTaskTemplateInfo;
}

interface TemplateDownloadProps {
  templates?: LolaBffApiContractsModelsLoanTaskTemplateInfo[] | null;
}

const DownloadButton = ({ template }: DownloadButtonProps) => {
  const openToast = useOpenToast();
  const [getTemplateData, { isLoading }] =
    useLazyGetApiV1DocumentTemplatesGetDownloadUrlQuery();

  const handleButtonClick = useCallback(async () => {
    try {
      if (template.templateId) {
        const result = await getTemplateData({
          templateId: template.templateId,
        }).unwrap();

        if (result.contentUrl && result.fileName) {
          downloadFile(result.fileName, result.contentUrl);
        }
      }
    } catch (err) {
      openToast({
        isError: true,
        title: (err as ApiError)?.data?.title || '',
      });
    }
  }, [template, openToast, getTemplateData]);

  return (
    <Button
      variant="outlined"
      startIcon={
        isLoading ? <CircularProgress size={20} /> : <FileDownloadIcon />
      }
      onClick={handleButtonClick}
    >
      {template.description
        ? `Download ${template.description}`
        : 'Download a template'}
    </Button>
  );
};

export const TemplateDownload = ({ templates }: TemplateDownloadProps) => {
  return (
    <Grid container spacing={2}>
      {templates?.map((template) => (
        <Grid item xs={12} key={template.templateId}>
          <DownloadButton template={template} />
        </Grid>
      ))}
    </Grid>
  );
};
