import { useFormContext } from 'react-hook-form';
import React, { useCallback, useEffect } from 'react';
import { NumberStepInput } from '@components/NumberStepInput/NumberStepInput.component';

interface AdjustLeverageInputProps {
  adjustLeverageLabel: string;
  adjustLeverageDescription: string;
  valueProperty: string;
  maxValueProperty: string;
}

export const AdjustLeverageInput = ({
  adjustLeverageLabel,
  adjustLeverageDescription,
  valueProperty,
  maxValueProperty,
}: AdjustLeverageInputProps) => {
  const { setValue, watch } = useFormContext();
  const maxValue = watch(maxValueProperty);
  const value = watch(valueProperty);

  useEffect(() => {
    const defaultValue = value || maxValue || 0;
    setValue(valueProperty, defaultValue);
  }, [value, setValue, valueProperty, maxValue]);

  const onChange = useCallback((value: number) => {
    setValue(valueProperty, value, { shouldDirty: true });
  }, []);

  return (
    <NumberStepInput
      label={adjustLeverageLabel}
      helperText={adjustLeverageDescription}
      value={value}
      minimum={0}
      maximum={maxValue}
      maskSymbol={'%'}
      onChange={onChange}
      white
    />
  );
};
