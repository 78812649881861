import { UploaderConfig } from '@components/Uploader/Uploader.types';
import {
  DEFAULT_UPLOADER_CONFIG,
  UPLOADER_STATE,
} from '@components/Uploader/Uploader.constants';
import { DEFAULT_UPLOADER_DRAWER_CONFIG } from '@components/UploadDrawer/uploaderDrawer.constants';
import { UploaderDrawerConfig } from '@components/UploadDrawer/uploaderDrawer.types';
import { UploadDocumentsFileTypesListItem } from './uploadDocuments.types';

export const UPLOAD_DOCUMENTS_DEFAULT_UPLOADER_CONFIG: UploaderConfig = {
  ...DEFAULT_UPLOADER_CONFIG,
  textConfig: {
    ...DEFAULT_UPLOADER_CONFIG.textConfig,
    [UPLOADER_STATE.INIT]: 'Drop your document here or',
  },
  buttonLabelConfig: {
    ...DEFAULT_UPLOADER_CONFIG.buttonLabelConfig,
    [UPLOADER_STATE.INIT]: 'Choose files to upload',
    [UPLOADER_STATE.SUCCESS]: '',
  },
  description: 'PDF, JPEG, JPG, Excel, less than 20mb',
  rules: {
    ...DEFAULT_UPLOADER_CONFIG.rules,
    allowedExtensions: {
      rule: ['docx', 'pdf', 'jpeg', 'jpg', 'xls', 'xlsx'],
      message: 'Wrong file extension',
    },
  },
};

export const UPLOAD_DOCUMENTS_DEFAULT_DRAWER_CONFIG: UploaderDrawerConfig = {
  ...DEFAULT_UPLOADER_DRAWER_CONFIG,
  headerTitle: 'Upload document',
  title: null,
  description: null,
};

export interface UploadDocumentsMockItem {
  title: string;
  id: string;
  fileTypesList: UploadDocumentsFileTypesListItem[];
}

export const uploadDocumentsMock: UploadDocumentsMockItem[] = [
  {
    title: 'Lead borrower',
    id: 'leadBorrowerId',
    fileTypesList: [
      {
        id: 'BusinessorpersonalaccountsLeadBorrowerId',
        title: 'Business or personal accounts',
        placeholder: 'PDF, JPEG, JPG, Excel, less than 20mb',
        uploadedFileDescription: 'Business or personal accounts',
        uploaderConfig: UPLOAD_DOCUMENTS_DEFAULT_UPLOADER_CONFIG,
        drawerConfig: {
          ...UPLOAD_DOCUMENTS_DEFAULT_DRAWER_CONFIG,
          title: 'Business or personal accounts document',
        },
      },
      {
        id: 'EntitydocumentsLeadBorrowerId',
        title: 'Entity documents',
        placeholder: 'PDF, JPEG, JPG, Excel, less than 20mb',
        uploadedFileDescription: 'Entity documents',
        uploaderConfig: UPLOAD_DOCUMENTS_DEFAULT_UPLOADER_CONFIG,
        drawerConfig: {
          ...UPLOAD_DOCUMENTS_DEFAULT_DRAWER_CONFIG,
          title: 'Entity documents document',
        },
      },
      {
        id: 'PersonalfinancialstatementsLeadBorrowerId',
        title: 'Personal financial statements',
        placeholder: 'PDF, JPEG, JPG, Excel, less than 20mb',
        uploadedFileDescription: 'Personal financial statements',
        uploaderConfig: UPLOAD_DOCUMENTS_DEFAULT_UPLOADER_CONFIG,
        drawerConfig: {
          ...UPLOAD_DOCUMENTS_DEFAULT_DRAWER_CONFIG,
          title: 'Personal financial statements document',
        },
      },
      {
        id: 'ValidphotoIDLeadBorrowerId',
        title: 'Valid photo ID',
        placeholder: 'PDF, JPEG, JPG, Excel, less than 20mb',
        uploadedFileDescription: 'Valid photo ID',
        uploaderConfig: UPLOAD_DOCUMENTS_DEFAULT_UPLOADER_CONFIG,
        drawerConfig: {
          ...UPLOAD_DOCUMENTS_DEFAULT_DRAWER_CONFIG,
          title: 'Valid photo ID document',
        },
      },
    ],
  },
  {
    title: 'Lead guarantor',
    id: 'leadGuarantorId',
    fileTypesList: [
      {
        id: 'BusinessorpersonalaccountsleadGuarantorId',
        title: 'Business or personal accounts',
        placeholder: 'PDF, JPEG, JPG, Excel, less than 20mb',
        uploadedFileDescription: 'Business or personal accounts',
        uploaderConfig: UPLOAD_DOCUMENTS_DEFAULT_UPLOADER_CONFIG,
        drawerConfig: {
          ...UPLOAD_DOCUMENTS_DEFAULT_DRAWER_CONFIG,
          title: 'Business or personal accounts document',
        },
      },
      {
        id: 'ScheduleofrealestateleadGuarantorId',
        title: 'Schedule of real estate',
        placeholder: 'PDF, JPEG, JPG, Excel, less than 20mb',
        uploadedFileDescription: 'Schedule of real estate',
        uploaderConfig: UPLOAD_DOCUMENTS_DEFAULT_UPLOADER_CONFIG,
        drawerConfig: {
          ...UPLOAD_DOCUMENTS_DEFAULT_DRAWER_CONFIG,
          title: 'Schedule of real estate',
        },
      },
    ],
  },
  {
    title: 'Property # 1. 4433 Gregory Lane , NY',
    id: 'Property#1',
    fileTypesList: [
      {
        id: 'BusinessorpersonalaccountsProperty#1',
        title: 'Business or personal accounts',
        placeholder: 'PDF, JPEG, JPG, Excel, less than 20mb',
        uploadedFileDescription: 'Business or personal accounts',
        uploaderConfig: UPLOAD_DOCUMENTS_DEFAULT_UPLOADER_CONFIG,
        drawerConfig: {
          ...UPLOAD_DOCUMENTS_DEFAULT_DRAWER_CONFIG,
          title: 'Business or personal accounts document',
        },
      },
      {
        id: 'ScheduleofrealestateleadProperty#1',
        title: 'Schedule of real estate',
        placeholder: 'PDF, JPEG, JPG, Excel, less than 20mb',
        uploadedFileDescription: 'Schedule of real estate',
        uploaderConfig: UPLOAD_DOCUMENTS_DEFAULT_UPLOADER_CONFIG,
        drawerConfig: {
          ...UPLOAD_DOCUMENTS_DEFAULT_DRAWER_CONFIG,
          title: 'Schedule of real estate',
        },
      },
    ],
  },
];
