import {
  LolaBffApiContractsModelsPricingEngineResponseRentalQuoteResponse,
  LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteResponse,
} from '@api/output/api';
import { STAGE_MODAL_TYPE } from '@pages/scenarioBuilder/pages/quoteForm/QuoteForm.constants';

export enum QUOTE_TYPE_RADIO_GROUP_NAME {
  QUOTE_TYPE = 'quoteType',
}

export enum SUMMARY_ITEM_KEYS {
  CALCULATION_ERROR = 'validationErrors',
  APPLIED_OVERRIDE = 'overridePricing',
  VALIDATION_WARNING = 'warnings',
  APPLIED_RATE_LLPAS_FACTORED = 'rateLLPAsFactored',
  FEE_LLPAS_FACTORED = 'feeLLPAsFactored',
  APPLIED_RATE_LLPAS = 'rateLLPAs',
  FEE_LLPAS = 'feeLLPAs',
}

export interface TitleConfig {
  singular: string;
  plural: string;
  withoutCount?: boolean;
}

export type KeyAssertion = keyof (
  | Pick<
      LolaBffApiContractsModelsPricingEngineResponseRentalQuoteResponse,
      | `${SUMMARY_ITEM_KEYS.CALCULATION_ERROR}`
      | `${SUMMARY_ITEM_KEYS.APPLIED_OVERRIDE}`
      | `${SUMMARY_ITEM_KEYS.APPLIED_RATE_LLPAS}`
      | `${SUMMARY_ITEM_KEYS.FEE_LLPAS}`
    >
  | Pick<
      LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteResponse,
      | `${SUMMARY_ITEM_KEYS.CALCULATION_ERROR}`
      | `${SUMMARY_ITEM_KEYS.APPLIED_OVERRIDE}`
      | `${SUMMARY_ITEM_KEYS.VALIDATION_WARNING}`
      | `${SUMMARY_ITEM_KEYS.APPLIED_RATE_LLPAS_FACTORED}`
      | `${SUMMARY_ITEM_KEYS.FEE_LLPAS_FACTORED}`
    >
);

export type StageModalConfig<T> = Partial<Record<STAGE_MODAL_TYPE, T>>;
