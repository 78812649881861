import React from 'react';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';

interface UserCredentialsProps {
  firstName?: string | null;
  lastName?: string | null;
  role: string;
  date?: string | null;
}

export const UserCredentials = ({
  firstName,
  lastName,
  role,
  date,
}: UserCredentialsProps) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h3">
          {firstName} {lastName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{role}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Joined in{' '}
          <Typography variant="pBold">
            {dayjs(date).format('MMMM YYYY')}
          </Typography>
        </Typography>
      </Grid>
    </>
  );
};
