import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { DeleteIcon } from '@lola/ui-react-components';
import {
  FileUploader,
  FileUploaderButtonProps,
} from '@components/FileUploader';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { LolaBffApiContractsModelsLoanTaskInfo } from '@api/output/api';

const deleteIconButtonRendererUploader = ({
  onClick,
  disabled,
}: FileUploaderButtonProps) => {
  return (
    <Button
      variant="outlined"
      startIcon={<DeleteIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      Delete
    </Button>
  );
};

interface UploadAppraisalProps {
  uploadDrawerHandler: (
    task?: LolaBffApiContractsModelsLoanTaskInfo
  ) => () => void;
  tasks: LolaBffApiContractsModelsLoanTaskInfo[];
}

export const UploadAppraisal = ({
  uploadDrawerHandler,
  tasks,
}: UploadAppraisalProps) => {
  const { disabled } = useContext(LoanDisableContext);

  return (
    <>
      {tasks.map((task) => (
        <FileUploader
          key={task.taskId}
          title="Appraisal"
          placeholder="PDF, less than 20mb"
          onClickIfFileExists={uploadDrawerHandler(task)}
          onClickIfFileDoesntExist={uploadDrawerHandler(task)}
          uploadedFiles={task.documents ?? []}
          buttonRendererIfFileExists={deleteIconButtonRendererUploader}
          disabled={disabled}
        />
      ))}
    </>
  );
};
