import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const ArchiveLoan = () => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form>Archive Loan Form</form>
    </FormProvider>
  );
};
