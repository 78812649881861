import React, { useCallback, useMemo } from 'react';
import { TableColumnType } from 'antd';
import { Button, LinearProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  DownloadIcon,
  FolderIcon,
  useTableDataSearchAndFilter,
  createDateSorter,
  createDefaultSorter,
  dateRenderer,
  createStatusOptionRenderer,
} from '@lola/ui-react-components';
import { DataControlPanel } from '@components/DataTableControlPanel/DataTableControlPanel.component';
import { createStatusRenderer } from '@utils/renderers/statusRenderer/createStatusRenderer';
import { NoContent as NoContentComponent } from '@components/NoContent';
import {
  LolaBffApiContractsModelsDocumentLockerDocumentInfo,
  useGetApiV1DocumentLockerQuery,
  useGetApiV1UsersMeQuery,
  useLazyGetApiV1DocumentsGenerateFilesArchiveQuery,
} from '@api/output/api';
import { Modal } from '@components/Modal';
import { useOpenState } from '@hooks/useOpenState';
import { STATUS_PROPS_MAP } from '@pages/personalLoans/pages/documentLocker/documentLocker.constants';
import { DOCUMENT_STATUS_TYPE } from '@pages/personalLoans/pages/documentLocker/types';
import { useDownloadAction } from './hooks/useDownloadAction';
import { DocumentsList } from './components/DocumentsList/DocumentsList.component';
import { documentInfoRenderer } from './renderers/tableCell';
import styles from './documentLocker.module.scss';

const columns: TableColumnType<LolaBffApiContractsModelsDocumentLockerDocumentInfo>[] =
  [
    {
      dataIndex: 'fileName',
      render: documentInfoRenderer,
      sorter:
        createDefaultSorter<LolaBffApiContractsModelsDocumentLockerDocumentInfo>(
          'fileName'
        ),
      title: 'Document name',
      width: '33%',
    },
    {
      dataIndex: 'status',
      render: createStatusRenderer(
        STATUS_PROPS_MAP,
        STATUS_PROPS_MAP[DOCUMENT_STATUS_TYPE.PENDING]
      ),
      sorter:
        createDefaultSorter<LolaBffApiContractsModelsDocumentLockerDocumentInfo>(
          'status'
        ),
      title: 'Status',
      width: '21%',
    },
    {
      dataIndex: 'createdAt',
      render: dateRenderer,
      sorter: createDateSorter('createdAt'),
      title: 'Uploaded',
      width: '21%',
    },
    {
      dataIndex: 'uploadedByEmail',
      sorter:
        createDefaultSorter<LolaBffApiContractsModelsDocumentLockerDocumentInfo>(
          'uploadedByEmail'
        ),
      title: 'Modified by',
      width: '21%',
    },
  ];

export const DocumentLockerPage = () => {
  const { data: user } = useGetApiV1UsersMeQuery();
  const { loanId = '' } = useParams();
  const [isOpen, open, close] = useOpenState();

  const { data = [], isFetching } = useGetApiV1DocumentLockerQuery(
    { loanId },
    { refetchOnMountOrArgChange: true }
  );

  const [prepareArchive, { isFetching: isFilesPreparing }] =
    useLazyGetApiV1DocumentsGenerateFilesArchiveQuery();

  const actionColumn = useDownloadAction();
  const columnsConfigs = useMemo(
    () => [...columns, actionColumn],
    [actionColumn]
  );

  const prepareArchiveHandler = useCallback(() => {
    prepareArchive({
      loanId,
    })
      .unwrap()
      .then(open);
  }, [loanId]);

  const {
    filteredData,
    handleSearchChange,
    handleFilterChange,
    appliedFilters,
    filters,
  } =
    useTableDataSearchAndFilter<LolaBffApiContractsModelsDocumentLockerDocumentInfo>(
      data,
      {
        status: {
          values: [],
          title: 'Status',
          optionLabelRenderer: createStatusOptionRenderer(
            STATUS_PROPS_MAP,
            STATUS_PROPS_MAP[DOCUMENT_STATUS_TYPE.PENDING]
          ),
          processNull: true,
        },
        uploadedByEmail: {
          values: [],
          title: 'Modified By',
        },
      },
      'fileName',
      ''
    );

  if (isFetching) {
    return <LinearProgress variant="indeterminate" value={30} />;
  }

  if (!data?.length) {
    return (
      <div className={styles.noContentWrapper}>
        <NoContentComponent
          icon={<FolderIcon />}
          title="No data to show here yet"
          text="The documents uploaded during the transaction will be shown here"
        />
      </div>
    );
  }

  return (
    <>
      <DataControlPanel
        appliedFilters={appliedFilters}
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleSearchChange={handleSearchChange}
        searchPlaceholder="Search by document"
        title="Filters"
      >
        <Button
          variant="text"
          startIcon={<DownloadIcon />}
          disabled={isFilesPreparing}
          onClick={prepareArchiveHandler}
        >
          Download all files
        </Button>
      </DataControlPanel>
      <div className={styles.documentsListContainer}>
        <DocumentsList columns={columnsConfigs} data={filteredData} />
      </div>
      <Modal
        isOpen={isOpen}
        title="Download all files"
        successButtonText="OK"
        onClose={close}
        onSuccess={close}
      >
        <Typography>
          Archive with all files has been sent to your email address:
        </Typography>
        <Typography variant="pBold">{user?.email}</Typography>
      </Modal>
    </>
  );
};
