import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useRole } from '@hooks/useRole';
import { MENU_MAP } from '@containers/Header/header.constants';
import { NavigationConfig } from '@typings/common';
import { useActiveRoute } from '@hooks/useActiveRoute';

import styles from './navigation.module.scss';

export const Navigation = () => {
  const role = useRole();
  const menuItems = MENU_MAP[role];
  const [currentMenuItem] = useActiveRoute(menuItems);

  return (
    <Tabs
      aria-label="nav tabs"
      value={currentMenuItem ? currentMenuItem : '/'}
      className={currentMenuItem ? '' : styles.inactiveIndicator}
    >
      {menuItems.map((props: NavigationConfig) => (
        <Tab
          key={props.label}
          component={NavLink}
          {...props}
          data-cy={props.label.toLowerCase().split(' ').join('-') + '-tablink'}
        />
      ))}
    </Tabs>
  );
};
