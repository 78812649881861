import React from 'react';
import { useTheme } from '@mui/material';
import { Collapsed } from '@components/Collapsed';
import { forms } from '@form-configs/output';
import { NewExistingSelector } from '../../../../components/NewExistingSelector/NewExistingSelector.component';
import { PartyForm } from '../../components/PartForm/PartyForm.component';

export const BorrowerInformation = () => {
  const { config } = useTheme();

  return (
    <Collapsed label="Primary guarantor" defaultExpanded>
      <NewExistingSelector
        entityType="primaryGuarantor"
        entityKey="party.borrower"
        autoOpen={true}
        major={true}
        label={`Has this client worked with ${config.companyLongName} before?`}
        newComponent={
          <PartyForm
            fields={forms.primaryGuarantor}
            entityKey="party.borrower"
          />
        }
      />
    </Collapsed>
  );
};
