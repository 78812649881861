import { useEffect, useState, useMemo, useCallback } from 'react';
import { FilterItem } from '@lola/ui-react-components';
import { useLazyGetApiV1UsersQuery } from '@api/output/api';
import { naturalSort } from '@utils/sorting.utils';
import { ROLES } from '@typings/common';
import { filterByCreatedByRenderer as importedFilterRenderer } from 'src/pages/scenarioBuilder/pages/quotes/components/QuotesList/renderers/filters/filterByCreatedByRenderer';
import { CREATED_BY_QUOTES_ALLOWED_ROLES } from '@constants/quotes.constants';

export const useCreatedByFilter = (
  role: ROLES,
  filters: Record<string, FilterItem>
) => {
  const [getUsers, { data: users }] = useLazyGetApiV1UsersQuery();
  const [selectedQuotesCreators, setSelectedQuotesCreators] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (CREATED_BY_QUOTES_ALLOWED_ROLES.includes(role)) {
      getUsers({});
    }
  }, [role, getUsers]);

  const handleCreatedByFilterChange = useCallback(
    (filterKey: string, value: string[]) => setSelectedQuotesCreators(value),
    []
  );

  const createdByOptions = useMemo(() => {
    return users
      ? [...users]
          .sort((a, b) => naturalSort(a.fullName ?? '', b.fullName ?? ''))
          .map(({ fullName, userId }) => ({
            label: fullName ?? '',
            value: userId ?? '',
          }))
      : [];
  }, [users]);

  const filterByCreatedByRenderer = useCallback(
    () =>
      importedFilterRenderer(
        selectedQuotesCreators,
        handleCreatedByFilterChange,
        createdByOptions
      ),
    [selectedQuotesCreators, handleCreatedByFilterChange, createdByOptions]
  );

  const updatedFilters = useMemo(() => {
    let newFilters = { ...filters };

    if (CREATED_BY_QUOTES_ALLOWED_ROLES.includes(role)) {
      const filterByCreatedBy = {
        key: 'CreatedBy',
        renderer: filterByCreatedByRenderer,
      };
      newFilters = {
        filterByCreatedBy,
        ...newFilters,
      };
    }

    return newFilters;
  }, [filters, role, filterByCreatedByRenderer]);

  return {
    selectedQuotesCreators,
    setSelectedQuotesCreators,
    updatedFilters,
  };
};
