import { LolaBffApiContractsModelsUser } from '@api/output/api';

export enum MODAL_STATE {
  CLOSE = 'CLOSE',
  CREATE = 'CREATE',
  CHANGE = 'CHANGE',
  ASSIGN = 'ASSIGN',
  REMOVE = 'REMOVE',
}

export const MODAL_TITLES: Record<MODAL_STATE, string> = {
  CLOSE: '',
  CREATE: 'Create new team',
  CHANGE: 'Change team',
  ASSIGN: 'Assign to a team',
  REMOVE: 'Remove from team',
};

export interface ModalContentType {
  state: MODAL_STATE;
  context?: LolaBffApiContractsModelsUser;
}
