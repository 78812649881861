import cloneDeep from 'lodash/cloneDeep';
import {
  LolaBffApiContractsModelsDashboardLoansStatisticsBrokerManagerLoansStatistic,
  LolaBffApiContractsModelsDashboardLoansStatisticsBrokerManagerLoansStatisticData,
  LolaBffApiContractsModelsDashboardLoansStatisticsLoansKeyBrokerManagerStatisticData,
  LolaBffApiContractsModelsDashboardLoansStatisticsTeamStatistic,
  LolaBffApiContractsModelsDashboardLoansStatisticsBrokerLoanStatisticData,
} from '@api/output/api';

type CommonChildren =
  | LolaBffApiContractsModelsDashboardLoansStatisticsTeamStatistic
  | LolaBffApiContractsModelsDashboardLoansStatisticsBrokerManagerLoansStatistic;

export interface UnknownChild {
  children?: CommonChildren[] | null;
}

const filterOutChildren = <T extends UnknownChild>(
  data?: T[] | null
): T[] | null => {
  if (!data) {
    return null;
  }

  return data.filter((item) => {
    const keep = item.children?.length !== 0 || item.children === undefined;
    if (keep && item.children) {
      item.children = filterOutChildren(item.children);
    }
    return keep;
  });
};
const filterOutEmptyTeams = <T extends UnknownChild, U>(
  data?: T
): U | undefined => {
  if (!data) return;

  const newData = cloneDeep(data);
  newData.children = filterOutChildren<CommonChildren>(newData?.children);

  return newData as unknown as U;
};

export const filterOutBMEmptyTeams = (
  data?: LolaBffApiContractsModelsDashboardLoansStatisticsBrokerManagerLoansStatisticData
) =>
  filterOutEmptyTeams<
    LolaBffApiContractsModelsDashboardLoansStatisticsBrokerManagerLoansStatisticData,
    LolaBffApiContractsModelsDashboardLoansStatisticsBrokerManagerLoansStatisticData
  >(data);

export const filterOutKBMEmptyTeams = (
  data?: LolaBffApiContractsModelsDashboardLoansStatisticsLoansKeyBrokerManagerStatisticData
) =>
  filterOutEmptyTeams<
    LolaBffApiContractsModelsDashboardLoansStatisticsLoansKeyBrokerManagerStatisticData,
    LolaBffApiContractsModelsDashboardLoansStatisticsLoansKeyBrokerManagerStatisticData
  >(data);

export const filterOutBrokerEmptyTeams = (
  data?: LolaBffApiContractsModelsDashboardLoansStatisticsBrokerLoanStatisticData
) =>
  filterOutEmptyTeams<
    LolaBffApiContractsModelsDashboardLoansStatisticsBrokerLoanStatisticData,
    LolaBffApiContractsModelsDashboardLoansStatisticsBrokerLoanStatisticData
  >(data);
