import { RegisterOptions, FieldValues } from 'react-hook-form';
import {
  generateMaxLengthValidator,
  generateMinDateValidator,
  generateMinMaxValidator,
  generatePatternValidator,
  generateRequiredValidator,
} from '@components/DynamicForm/utils/validators.utils';
import { ControlType, ValidationRule } from '@form-configs/types';
import { VALIDATION_RULES } from '@components/DynamicForm/utils/validation.types';
import { MINIMUM_DATE } from '@constants/time.constants';

export type CustomRegisterOptions<
  TFieldValues extends FieldValues = FieldValues
> = RegisterOptions<TFieldValues> & {
  notEmpty?: ValidationRule;
};

const DEFAULT_RULES: Partial<Record<ControlType, () => CustomRegisterOptions>> =
  {
    datePicker: () => {
      const minDateRule = generateMinDateValidator([MINIMUM_DATE]);
      return {
        validate: {
          ...minDateRule,
        },
      };
    },
  };

const initializeDefaultRules = (
  controlType?: ControlType
): CustomRegisterOptions => {
  if (controlType) {
    const defaultRules = DEFAULT_RULES[controlType];
    return defaultRules ? defaultRules() : {};
  }
  return {};
};

export const convertYupValidationToControllerRules = (
  rules: ValidationRule[],
  controlType?: ControlType
): CustomRegisterOptions => {
  const controllerRules = initializeDefaultRules(controlType);

  return rules.reduce<CustomRegisterOptions>((controllerRules, rule) => {
    const ruleName = rule.rule;
    const args = rule.args ?? [];

    switch (ruleName) {
      case VALIDATION_RULES.REQUIRED:
        controllerRules[ruleName] = generateRequiredValidator(args);
        break;
      case VALIDATION_RULES.MIN:
      case VALIDATION_RULES.MAX:
        controllerRules[ruleName] = generateMinMaxValidator(args);
        break;
      case VALIDATION_RULES.PATTERN:
        if (rule.args) {
          controllerRules[ruleName] = generatePatternValidator(args);
        }
        break;
      case VALIDATION_RULES.MAX_LENGTH:
        if (rule.args) {
          controllerRules[ruleName] = generateMaxLengthValidator(args);
        }
        break;
      case VALIDATION_RULES.MIN_DATE: {
        if (rule.args) {
          const minDateRule = generateMinDateValidator(args);
          controllerRules = {
            ...controllerRules,
            validate: {
              ...controllerRules.validate,
              ...minDateRule,
            },
          };
        }
        break;
      }
      default:
        break;
    }
    return controllerRules;
  }, controllerRules);
};
