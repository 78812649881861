import { NavigationConfig } from '@typings/common';

export const TABS: NavigationConfig[] = [
  {
    to: '',
    value: '/personal-loans/:id',
    label: 'Control panel',
  },
  {
    to: 'document-locker',
    value: '/personal-loans/:id/document-locker',
    label: 'Document locker',
  },
  {
    to: 'changelog',
    value: '/personal-loans/:id/changelog',
    label: 'Activity Log',
  },
  {
    to: 'details',
    value: '/personal-loans/:id/details',
    label: 'Details',
  },
];
