import { useMemo } from 'react';
import { LolaBffApiContractsModelsApproveMyLoanResponseShowCondition } from '@api/output/api';
import { MODE_SWITCHER_VALUES } from '../ManageConditions.constants';

export const filterConditions = (
  conditions:
    | LolaBffApiContractsModelsApproveMyLoanResponseShowCondition[]
    | undefined,
  filter: string | null,
  mode: MODE_SWITCHER_VALUES | string | number
) => {
  if (!conditions) {
    return [];
  }
  if ((filter === 'All' || !filter) && mode === 'All') {
    return conditions;
  }
  const filteredCondition = conditions.map((info) => {
    let infoConditions = info.conditions || [];
    if (mode === 'KCT') {
      infoConditions = infoConditions?.filter((i) => i.isKeyClosingTask);
    }
    if (filter && filter !== 'All') {
      infoConditions = infoConditions?.filter((item) => item.status === filter);
    }
    return {
      maindependency: info.maindependency,
      mainDependencyId: info.mainDependencyId,
      conditions: infoConditions,
    };
  });
  return filteredCondition.filter((el) => el.conditions?.length > 0);
};

export const useFilterConditions = (
  conditions: LolaBffApiContractsModelsApproveMyLoanResponseShowCondition[],
  filter: string | null,
  mode: MODE_SWITCHER_VALUES | string | number
) => {
  return useMemo(
    () => filterConditions(conditions, filter, mode),
    [conditions, filter, mode]
  );
};
