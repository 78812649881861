import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { InputAdornment, TextField, Button } from '@mui/material';
import { Range } from 'react-date-range';
import { CalendarIcon, DatePicker } from '@lola/ui-react-components';
import { PeriodConfig } from '@components/PeriodSelector';
import { getLast7yearsObject, isDateBetween } from '@utils/date.utils';
import styles from './customRangeSelector.module.scss';

export interface RangeDatePickerProps {
  period?: PeriodConfig;
  minAllowedYear?: number;
  onPeriodChange?: (config: PeriodConfig) => void;
}

interface TempState {
  startDate: string;
  endDate: string;
}

const allowedPeriod = getLast7yearsObject(); // Get the allowed period

export const CustomRangeSelector = ({
  onPeriodChange,
  minAllowedYear,
  period,
}: RangeDatePickerProps) => {
  const [state, setState] = useState<Range[]>(
    period?.value === 'custom'
      ? [
          {
            startDate: dayjs(period.start).startOf('day').toDate(),
            endDate: dayjs(period.end).startOf('day').toDate(),
            key: 'selection',
          },
        ]
      : [
          {
            startDate: dayjs().startOf('day').toDate(),
            endDate: dayjs().startOf('day').toDate(),
            key: 'selection',
          },
        ]
  );
  const [tempState, setTempState] = useState<TempState>({
    startDate:
      period?.value === 'custom' ? period.start : dayjs().format('YYYY-MM-DD'),
    endDate:
      period?.value === 'custom' ? period.end : dayjs().format('YYYY-MM-DD'),
  });

  const handleTempStateChange = useCallback(
    (type: 'startDate' | 'endDate') =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempState({
          ...tempState,
          [type]: event.target.value,
        });
      },
    [tempState]
  );

  const handleBlur = useCallback(
    (type: 'startDate' | 'endDate') => () => {
      const dateStr = tempState[type];
      const dateValue = dayjs(dateStr);
      const isDateWithinAllowedPeriod = isDateBetween(
        dateValue.toDate(),
        dayjs(allowedPeriod.start).toDate(),
        dayjs(allowedPeriod.end).toDate()
      );

      if (dateValue.isValid() && isDateWithinAllowedPeriod) {
        const newState = [...state];
        (newState[0][type as keyof Range] as Date) = dateValue
          .startOf('day')
          .toDate();
        setState(newState);
        setTempState({
          ...tempState,
          [type]: dateValue.format('YYYY-MM-DD'),
        });
      } else {
        const defaultDate =
          type === 'startDate' ? allowedPeriod.start : allowedPeriod.end;
        setTempState({
          ...tempState,
          [type]: defaultDate,
        });
        const newState = [...state];
        (newState[0][type as keyof Range] as Date) = dayjs(defaultDate)
          .startOf('day')
          .toDate();
        setState(newState);
      }
    },
    [state, tempState]
  );

  const handleDateChange = useCallback((newState: Range[]) => {
    const startDate = dayjs(newState[0].startDate).format('YYYY-MM-DD');
    const endDate = dayjs(newState[0].endDate).format('YYYY-MM-DD');

    setState(newState);

    setTempState((prevState) => {
      if (prevState.startDate === startDate && prevState.endDate === endDate) {
        return prevState;
      }

      return {
        startDate,
        endDate,
      };
    });
  }, []);

  const applyPeriod = useCallback(() => {
    if (onPeriodChange) {
      onPeriodChange({
        value: 'custom',
        start: dayjs(state[0].startDate).format('YYYY-MM-DD'),
        end: dayjs(state[0].endDate).format('YYYY-MM-DD'),
      });
    }
  }, [state, onPeriodChange]);

  return (
    <div>
      <div className={styles.inputsWrapper}>
        <TextField
          label="Start Date"
          type="date"
          value={tempState.startDate}
          classes={{ root: styles.inputTextFieldRoot }}
          InputProps={{
            classes: {
              root: styles.inputBaseRoot,
            },
            endAdornment: (
              <InputAdornment
                position="end"
                classes={{ root: styles.inputIcon }}
                data-cy="start-date-date-picker"
              >
                <CalendarIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleTempStateChange('startDate')}
          onBlur={handleBlur('startDate')}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="End Date"
          type="date"
          value={tempState.endDate}
          classes={{ root: styles.inputTextFieldRoot }}
          InputProps={{
            classes: {
              root: styles.inputBaseRoot,
            },
            endAdornment: (
              <InputAdornment
                position="end"
                classes={{ root: styles.inputIcon }}
              >
                <CalendarIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleTempStateChange('endDate')}
          onBlur={handleBlur('endDate')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div className={styles.calendarSection}>
        <DatePicker
          onChange={handleDateChange}
          ranges={state}
          minDate={new Date(allowedPeriod.start)}
          maxDate={new Date(allowedPeriod.end)}
          minAllowedYear={minAllowedYear}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          variant="text"
          onClick={() =>
            setState([
              {
                startDate: dayjs().startOf('day').toDate(),
                endDate: dayjs().startOf('day').toDate(),
                key: 'selection',
              },
            ])
          }
        >
          Clear
        </Button>
        <Button color="primary" variant="contained" onClick={applyPeriod}>
          Select
        </Button>
      </div>
    </div>
  );
};
