type DynamicCheckboxValue = boolean | string | number;

const handleYesNoValue = (value?: DynamicCheckboxValue): string => {
  if (value === 'No' || value === false || value === 0 || value === undefined) {
    return 'No';
  }
  return 'Yes';
};

const handleBooleanValue = (value?: DynamicCheckboxValue): boolean => {
  return value === undefined ? false : !!value;
};

export const getCheckboxValue = (
  useYesNo?: boolean,
  value?: boolean | string | number
): boolean | string => {
  return useYesNo ? handleYesNoValue(value) : handleBooleanValue(value);
};
