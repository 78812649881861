import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import {
  DeleteIcon,
  EditIcon,
  UploadIcon,
  DocIcon,
} from '@lola/ui-react-components';
import { Menu } from '@components/Menu';
import { UserProfileBall } from '@components/UserProfileBall/UserProfileBall.component';

import styles from './userPhoto.module.scss';

export type PhotoSelectHandler = (body: { file: Blob }) => void;
export type PhotoDeleteHandler = () => void;

interface UserPhotoProps {
  firstName?: string | null;
  lastName?: string | null;
  picture?: string | null;
  onPhotoSelect: PhotoSelectHandler;
  onPhotoDelete: PhotoDeleteHandler;
}

export const UserPhoto = ({
  firstName,
  lastName,
  picture,
  onPhotoSelect,
  onPhotoDelete,
}: UserPhotoProps) => {
  const uploadHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    if (files?.[0]) {
      const body = new FormData();
      body.append('file', files[0]);
      onPhotoSelect(body as unknown as { file: Blob });
      e.currentTarget.value = '';
    }
  }, []);

  const hash = useMemo(() => Date.now(), []);

  return (
    <div className={styles.wrapper}>
      <UserProfileBall
        firstName={firstName}
        lastName={lastName}
        picture={picture}
        size="large"
        hash={hash}
      />
      <div className={styles.trigger}>
        <input
          id="uploadPhoto"
          type="file"
          onChange={uploadHandler}
          className={styles.input}
        />
        <Menu
          trigger={
            <IconButton title="Edit photo" className={styles.iconButton}>
              <EditIcon />
            </IconButton>
          }
          position={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        >
          <label htmlFor="uploadPhoto">
            <DocIcon />
            <MenuItem className={styles.menuItem}>
              <UploadIcon /> Upload new photo
            </MenuItem>
          </label>
          <MenuItem
            className={styles.menuItem}
            onClick={onPhotoDelete}
            disabled={!picture}
          >
            <DeleteIcon /> Delete photo
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};
