import { useCallback, useEffect } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { usePostApiV1LoansByLoanIdSendChangeRequestMutation } from '@api/output/api';
import { useOpenToast } from '@hooks/useOpenToast';
import { CHANGE_REQUEST_FIELD } from '../ChangeRequest.typings';

export const useSendChangeRequest = (
  handleSubmit: UseFormHandleSubmit<Record<CHANGE_REQUEST_FIELD, string>>
) => {
  const [send, { isLoading, isError }] =
    usePostApiV1LoansByLoanIdSendChangeRequestMutation();
  const onFormSubmit = useCallback(
    handleSubmit((values) => {
      const { loanId, ...requestData } = values ?? {};
      send({
        loanId,
        lolaBffApiContractsModelsLoanRequestLoanChangeRequest: requestData,
      });
    }),
    [handleSubmit]
  );

  const openToast = useOpenToast();
  useEffect(() => {
    if (isError) {
      openToast({
        title: 'Error occurred',
        description: 'Error happened, please try again later',
        isError: true,
      });
    }
  }, [isError, openToast]);

  return {
    onFormSubmit,
    isSending: isLoading,
  };
};
