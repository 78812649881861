import { dynamicBaseQuery } from '@api/emptyApi';
import { BaseQueryApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { global } from '@config/global';
import { AppState } from '@store/types';
import { clearTokens, setTokens } from '@store/slices/auth.slice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

const filesEndpoints = [
  'postApiV1DocumentsUploadByTaskId',
  'postApiV1ConditionConditionUpload',
  'postApiV1LoansByLoanIdFromBorrowerGuarantorApp',
];

const authError: FetchBaseQueryError = {
  status: 401,
  data: undefined,
};

const setNewTokenPair = async (
  webApi: BaseQueryApi,
  username?: string,
  refreshToken?: string
) => {
  try {
    const response = await fetch(
      `${global.bffApiUrl}api/v1/auth/token/refresh`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          refreshToken,
        }),
      }
    );
    const data = await response.json();

    webApi.dispatch(
      setTokens({
        refreshToken: data.refreshToken,
        accessToken: data.accessToken,
        expiresIn: data.expiresIn,
      })
    );
  } catch (e) {
    webApi.dispatch(clearTokens());
    throw new Error('Auth Error');
  }
};

const authErrorResponse = {
  error: authError,
};

const baseQueryWithAuth: typeof fetchBaseQuery = (config) => {
  const rawBaseQuery = fetchBaseQuery({
    ...config,
    paramsSerializer: (params) => {
      const newParams = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (value === undefined) return;
        if (Array.isArray(value)) {
          value.forEach((item) => newParams.append(key, item));
          return;
        }
        newParams.append(key, value);
      });
      return newParams.toString();
    },
  });

  return async (args, webApi, extraOptions) => {
    const authState = (webApi.getState() as AppState).auth;
    const isAuthenticated = authState.accessToken && authState.refreshToken;
    const { expiresAt = 0 } = authState;
    const isSessionExpired = Date.now() > expiresAt;

    /* Try to refresh token and repeat request */
    if (isAuthenticated && isSessionExpired) {
      try {
        await setNewTokenPair(
          webApi,
          authState.username,
          authState.refreshToken
        );
      } catch (e) {
        return authErrorResponse;
      }
    }

    let result = await rawBaseQuery(args, webApi, extraOptions);
    
    if (result?.error?.status === 401) {
      try {
        await setNewTokenPair(
          webApi,
          authState.username,
          authState.refreshToken
        );
      } catch (e) {
        return authErrorResponse;
      }
      
      // Try one more time
      result = await rawBaseQuery(args, webApi, extraOptions);
      
      if (result?.error?.status === 401) {
        return authErrorResponse;
      }
    }

    return result;
  };
};

export const initApi = () => {
  dynamicBaseQuery.setHandler(
    baseQueryWithAuth({
      baseUrl: global.bffApiUrl,
      prepareHeaders: (headers, { getState, endpoint }) => {
        const token = (getState() as AppState).auth.accessToken;
        const isDefaultHeaders = !filesEndpoints.includes(endpoint);

        if (token) {
          headers.set('authorization', `Bearer ${token}`);
        }

        if (isDefaultHeaders) {
          headers.set('accept', 'application/json');
        }

        return headers;
      },
    })
  );
};
