import React from 'react';
//MUI
import { List, ListItem, Paper, Typography } from '@mui/material';
//Types
import { LolaBffApiContractsModelsLoanLoanModel } from '@api/output/api';
//Styles
import styles from './milestoneList.module.scss';

export interface MilestoneListProps {
  data: LolaBffApiContractsModelsLoanLoanModel;
  listTitle: string;
}

export const MilestoneList = ({ data, listTitle }: MilestoneListProps) => {
  return (
    <Paper elevation={0} variant="outlined" className={styles.milestoneList}>
      <List>
        <ListItem className={styles.detailListItem}>
          <Typography variant="h4" className={styles.listTitle}>
            {listTitle}
          </Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'Application Disclosure'}</Typography>
          <Typography variant="pBold">
            {data?.applicationDisclosureDate ?? ''}
          </Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'Registered'}</Typography>
          <Typography variant="pBold">
            {data?.applicationReceivedDate ?? ''}
          </Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'Submit to UW'}</Typography>
          <Typography variant="pBold">
            {data?.submittedUnderwriterDate ?? ''}
          </Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'Estimated closing'}</Typography>
          <Typography variant="pBold">
            {data?.estimatedClosingDate ?? ''}
          </Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'UW Approval'}</Typography>
          <Typography variant="pBold">
            {data?.underwriterApprovalDate ?? ''}
          </Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'Clear to Close'}</Typography>
          <Typography variant="pBold">
            {data?.clearToCloseDate ?? ''}
          </Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'Funds released'}</Typography>
          <Typography variant="pBold">{data?.wireReleaseDate ?? ''}</Typography>
        </ListItem>
        <ListItem className={styles.detailListItem}>
          <Typography variant="p">{'Rate lock expiration'}</Typography>
          <Typography variant="pBold">
            {data?.rateLockExpiration ?? ''}
          </Typography>
        </ListItem>
      </List>
    </Paper>
  );
};
