import React, { useEffect, useContext } from 'react';
import { LinearProgress } from '@mui/material';
import { ContentSection, ContentSectionItem } from '@components/ContentSection';
import { LOAN_PRODUCTS } from '@typings/common';
import {
  LolaBffApiContractsModelsDashboardKeyStatisticsLoanPortfolio,
  LolaBffApiContractsModelsDashboardKeyStatisticsSubmissionKpi,
  LolaBffApiContractsModelsDashboardKeyStatisticsSubmissionToCloseKeyStats,
  useLazyGetApiV1DashboardStatsKeyStatisticsQuery,
} from '@api/output/api';
import { getNumberPostfix } from '@utils/text.utils';
import { formatNumber, formatThousands } from '@utils/numberFormatting.utils';
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import { ListItem } from '@pages/dashboard/components/WidgetContent/components/WidgetContentList/WidgetContentList.component';
import {
  WidgetContent,
  WidgetContentBody,
  WidgetContentFooter,
  WidgetContentList,
} from '../WidgetContent';
import { useTeamStats } from '../../hooks/useTeamStats';
import { PeriodContext } from '../../contexts/Period.context';
import {
  KEY_STATISTICS_TITLES,
  KEY_STATISTICS_TYPE,
  LOAN_PRODUCT_TITLES,
} from './StatisticsWidget.constants';

import styles from './statisticWidget.module.scss';

interface SubmissionsWidgetProps {
  data: LolaBffApiContractsModelsDashboardKeyStatisticsSubmissionKpi;
  isError: boolean;
}

interface SubmissionsToCloseWidgetProps {
  data: LolaBffApiContractsModelsDashboardKeyStatisticsSubmissionToCloseKeyStats;
  isError: boolean;
}

interface LoanPortfolioWidgetProps {
  data: LolaBffApiContractsModelsDashboardKeyStatisticsLoanPortfolio & {
    products: ListItem[];
  };
  isError: boolean;
}

const SubmissionsWidget = ({ data, isError }: SubmissionsWidgetProps) => {
  const submissions = data || {};

  return (
    <WidgetContent
      title={KEY_STATISTICS_TITLES[KEY_STATISTICS_TYPE.SUBMISSION]}
      tooltip="The data in this section reflects all loans submitted for the selected Stats period."
      titleClass={styles.title}
    >
      {isError ? (
        <ErrorNoticeFetchRequest />
      ) : (
        <>
          <WidgetContentBody
            label={`$${formatThousands(submissions.totalAmount)}`}
          />
          <WidgetContentFooter
            leftLabelPrefix={submissions.numberOfLoans || ''}
            leftLabelPostfix={
              submissions.numberOfLoans
                ? getNumberPostfix(submissions.numberOfLoans, ' loan', ' loans')
                : 'Not enough data'
            }
            rightLabelPrefix={
              formatNumber(submissions.averageTotalAmount) || ''
            }
            rightLabelPostfix={
              submissions.averageTotalAmount ? ' per unit on average' : ''
            }
          />
        </>
      )}
    </WidgetContent>
  );
};

const LoanPortfolioWidget = ({ data, isError }: LoanPortfolioWidgetProps) => {
  const { totalAmount, totalLoansCount, products } = data;

  return (
    <WidgetContent
      title={KEY_STATISTICS_TITLES[KEY_STATISTICS_TYPE.LOAN_PORTFOLIO]}
      tooltip="The data in this section reflects the loans submitted by product type for the selected Stats period."
      titleClass={styles.title}
    >
      {isError ? (
        <ErrorNoticeFetchRequest />
      ) : (
        <>
          <WidgetContentBody
            label={`$${formatNumber(totalAmount, 0)}`}
            labelPostfix={
              totalLoansCount
                ? `${totalLoansCount} ${getNumberPostfix(
                    totalLoansCount,
                    ' loan',
                    ' loans'
                  )}`
                : '0 loans'
            }
          />
          <WidgetContentList list={products} />
        </>
      )}
    </WidgetContent>
  );
};

const SubmissionsToCloseWidget = ({
  data,
  isError,
}: SubmissionsToCloseWidgetProps) => {
  const submissionsToClose = data || {};

  return (
    <WidgetContent
      title={KEY_STATISTICS_TITLES[KEY_STATISTICS_TYPE.SUBMISSION_TO_CLOSE]}
      tooltip="The data in this section reflects the percentage of loans closed from all submissions for the selected Stats period."
      titleClass={styles.title}
    >
      {isError ? (
        <ErrorNoticeFetchRequest />
      ) : (
        <>
          <WidgetContentBody
            label={`${submissionsToClose.percentages?.toFixed(0) || 0}%`}
          />
          <WidgetContentFooter
            leftLabelPrefix={
              submissionsToClose.days !== undefined
                ? submissionsToClose.days
                : ''
            }
            leftLabelPostfix={
              submissionsToClose.days !== undefined
                ? getNumberPostfix(submissionsToClose.days, ' day', ' days')
                : 'Not enough data'
            }
            rightLabelPrefix={submissionsToClose.closedLoanCount || ''}
            rightLabelPostfix={
              submissionsToClose.closedLoanCount
                ? getNumberPostfix(
                    submissionsToClose.closedLoanCount,
                    ' loan closed',
                    ' loans closed'
                  )
                : ''
            }
          />
        </>
      )}
    </WidgetContent>
  );
};

export const StatisticsWidget = () => {
  const { start, end } = useContext(PeriodContext);
  const isTeamStats = useTeamStats();

  const [load, { data, isFetching, isError }] =
    useLazyGetApiV1DashboardStatsKeyStatisticsQuery();

  useEffect(() => {
    load({
      dateFrom: start,
      dateTo: end,
      teamStats: isTeamStats,
    });
  }, [start, end, isTeamStats]);

  if (isFetching) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  const submissions = data?.[KEY_STATISTICS_TYPE.SUBMISSION] || {};
  const loanPortfolio = data?.[KEY_STATISTICS_TYPE.LOAN_PORTFOLIO] || {};
  const submissionsToClose =
    data?.[KEY_STATISTICS_TYPE.SUBMISSION_TO_CLOSE] || {};

  const products = [
    {
      name: LOAN_PRODUCT_TITLES[LOAN_PRODUCTS.FIX_N_FLIP],
      ...loanPortfolio[LOAN_PRODUCTS.FIX_N_FLIP],
    },
    {
      name: LOAN_PRODUCT_TITLES[LOAN_PRODUCTS.NEW_CONSTRUCTION],
      ...loanPortfolio[LOAN_PRODUCTS.NEW_CONSTRUCTION],
    },
    {
      name: LOAN_PRODUCT_TITLES[LOAN_PRODUCTS.RENTAL],
      ...loanPortfolio[LOAN_PRODUCTS.RENTAL],
    },
    {
      name: LOAN_PRODUCT_TITLES[LOAN_PRODUCTS.BRIDGE_PLUS],
      ...loanPortfolio[LOAN_PRODUCTS.BRIDGE_PLUS],
    },
  ];

  return (
    <ContentSection title="Key statistics">
      <ContentSectionItem>
        <SubmissionsWidget data={submissions} isError={isError} />
      </ContentSectionItem>
      <ContentSectionItem>
        <LoanPortfolioWidget
          data={{
            ...loanPortfolio,
            products,
          }}
          isError={isError}
        />
      </ContentSectionItem>
      <ContentSectionItem>
        <SubmissionsToCloseWidget data={submissionsToClose} isError={isError} />
      </ContentSectionItem>
    </ContentSection>
  );
};
