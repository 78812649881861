import React, { useCallback, useState } from 'react';
import { Button, Drawer, IconButton, LinearProgress } from '@mui/material';
import { CloseIcon, RowRecord } from '@lola/ui-react-components';
import { ContentSectionHeader } from '@components/ContentSection';
import { LolaBffApiContractsModelsLoanPartyInfo } from '@api/output/api';
import { EntityType } from '../../NewExistingSelector.types';
import {
  DRAWER_TITLE,
  ENTITY_TYPE_LIST,
} from './EntityTypeListDrawer.constants';

import styles from './entityTypeListDrawer.module.scss';

export interface BorrowerListDrawerProps {
  entityType: EntityType;
  open: boolean;
  selectedBorrowerId?: string;
  onBorrowerSelect: (
    data: Record<string, LolaBffApiContractsModelsLoanPartyInfo>
  ) => void;
  onClose: () => void;
  isExistingEntityBeingAdded?: boolean;
}

export const EntityTypeListDrawer = ({
  entityType,
  open,
  onClose,
  onBorrowerSelect,
  isExistingEntityBeingAdded,
}: BorrowerListDrawerProps) => {
  const [selectedEntityType, setSelectedEntityType] = useState<RowRecord>();

  const ListComponent = ENTITY_TYPE_LIST[entityType];

  const selectEntityTypeHandler = useCallback(() => {
    onBorrowerSelect?.(
      selectedEntityType as Record<
        string,
        LolaBffApiContractsModelsLoanPartyInfo
      >
    );
  }, [selectedEntityType, onBorrowerSelect]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      hideBackdrop={true}
      anchor="right"
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      <ContentSectionHeader
        title={DRAWER_TITLE[entityType]}
        actions={
          <IconButton onClick={onClose} title="Close list">
            <CloseIcon />
          </IconButton>
        }
      />
      <ListComponent
        fullHeight
        className={styles.main}
        onChange={(record) => setSelectedEntityType(record)}
        selectedId={selectedEntityType?.id as string}
      />
      {isExistingEntityBeingAdded && (
        <LinearProgress data-cy="linear-progress" />
      )}
      <footer className={styles.footer}>
        <Button variant="text" size="large" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          disabled={!selectedEntityType || isExistingEntityBeingAdded}
          onClick={selectEntityTypeHandler}
        >
          Select
        </Button>
      </footer>
    </Drawer>
  );
};
