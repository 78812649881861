import React, { ReactNode } from 'react';
// MUI
import { Grid, Typography, CircularProgress } from '@mui/material';
// components
import {
  CardStatusComponent,
  STATUS_LIST,
} from '@pages/personalLoans/components/CardStatus/CardStatus.component';
import {
  VerticalStepDetail,
  Step,
} from '@components/verticalStepDetail/VerticalStepDetail.component';
// styles
import style from './LoanStatusSection.module.scss';

export type CompletedTasks = {
  tasksCompletedDescription: string;
  tasksCompletedPercentage: string;
};

interface LoanStatusSectionProps {
  loanStatus?: STATUS_LIST;
  loanDescription?: string;
  progress?: Step[];
  completedTasks?: CompletedTasks;
  children?: ReactNode;
}

export const LoanStatusSection = ({
  loanStatus,
  loanDescription,
  progress,
  completedTasks,
  children,
}: LoanStatusSectionProps) => {
  const currentPercentage = completedTasks?.tasksCompletedPercentage
    ? parseInt(completedTasks?.tasksCompletedPercentage.split('%')[0])
    : 0;

  const cardHeader = completedTasks && (
    <section className={style.task}>
      <span className={style.percentage}>
        <CircularProgress
          variant="determinate"
          value={100}
          color="inherit"
          className={style.percentageMark}
          size={52}
        />
        <CircularProgress
          variant="determinate"
          value={currentPercentage}
          color="secondary"
          className={style.percentageMark}
          size={52}
        />

        <Typography variant="pSmallBold">
          {completedTasks?.tasksCompletedPercentage}
        </Typography>
      </span>

      <Typography variant="pBold">
        {completedTasks?.tasksCompletedDescription}
      </Typography>
      <Typography variant="p">
        You have to complete all tasks before you can start a Final underwriting
      </Typography>
    </section>
  );

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={6}>
        {loanStatus && (
          <CardStatusComponent
            title={!completedTasks ? loanDescription : ''}
            status={loanStatus}
            header={cardHeader}
          />
        )}
      </Grid>
      <Grid item sm={12} md={6}>
        {progress && !!progress.length && (
          <VerticalStepDetail data={progress} />
        )}
      </Grid>

      {children}
    </Grid>
  );
};
