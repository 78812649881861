import dayjs from 'dayjs';
import { LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto } from '@api/output/api';
import {
  hasTaskWithStatus,
  isAllTasksCompleted,
} from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useBuildMyLoan.utils';
import { VERTICAL_STATUS_STATE } from '@components/verticalStepDetail/VerticalStepDetail.component';
import {
  FAIL_STATUS,
  IN_PROGRESS_STATUS,
} from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useBuildMyLoan.constants';
import { STATUS_LIST } from '@pages/personalLoans/components';
import {
  CONSTRUCTION_STEP,
  ESTIMATED_DAYS_TO_COMPLETE,
  ProgressStepConfig,
} from './ConstructionBudget.constants';

export const getCurrentStepStatus = (
  config: ProgressStepConfig,
  task?: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto
) => {
  if (task?.completionStatus === config.completeStatus) {
    return VERTICAL_STATUS_STATE.DONE;
  }

  if (task?.completionStatus === config.inProgressTaskStatus) {
    return VERTICAL_STATUS_STATE.IN_PROGRESS;
  }

  if (task?.completionStatus === FAIL_STATUS) {
    return VERTICAL_STATUS_STATE.ERROR;
  }

  return VERTICAL_STATUS_STATE.LOCKED;
};

export const MESSAGE_MAP = {
  [VERTICAL_STATUS_STATE.IN_PROGRESS]: 'Started on',
  [VERTICAL_STATUS_STATE.DONE]: 'Completed on',
  [VERTICAL_STATUS_STATE.ERROR]: 'Updated on',
  [VERTICAL_STATUS_STATE.LOCKED]: 'Started on',
};

export const getCurrentStepMessage = (
  config: ProgressStepConfig,
  status: VERTICAL_STATUS_STATE,
  task?: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto
) => {
  if (!task) {
    return '';
  }

  let prefix = MESSAGE_MAP[status];
  let date = dayjs(task.updatedAt ?? task.createdAt);

  if (config.step === CONSTRUCTION_STEP.COMPLETED) {
    const isExpected = status !== VERTICAL_STATUS_STATE.DONE;

    const currentDate = dayjs();
    const expectedDate = dayjs(task.createdAt).add(
      ESTIMATED_DAYS_TO_COMPLETE,
      'days'
    );

    if (isExpected) {
      date = expectedDate;
      prefix = 'Expected on';
    }

    if (currentDate > expectedDate) {
      date = currentDate.add(1, 'day');
    }
  }

  return `${prefix} ${date.format('MMM DD')}`;
};

export const getCurrentStatus = (
  taskNames: string[],
  tasks: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto[]
) => {
  if (isAllTasksCompleted(taskNames, tasks)) {
    return STATUS_LIST.DONE;
  }

  if (hasTaskWithStatus(taskNames, FAIL_STATUS, tasks)) {
    return STATUS_LIST.ERROR;
  }

  if (hasTaskWithStatus(taskNames, IN_PROGRESS_STATUS, tasks)) {
    return STATUS_LIST.IN_PROGRESS;
  }

  return STATUS_LIST.INFO;
};
