import React from 'react';
import { LolaBffApiContractsModelsUser } from '@api/output/api';
import { SetModalContent } from '@typings/common';
import { ActionsMenu } from '@pages/teamManagement/pages/TeamMembers/components';
import { ModalContentType } from '../../../TeamMembers.constants';

export const actionsRenderer = (
  handler: SetModalContent<ModalContentType>,
  context: LolaBffApiContractsModelsUser
) => {
  return <ActionsMenu setModalContent={handler} context={context} />;
};
