import React from 'react';
import { AvailableForms, forms } from '@form-configs/output';
import { DynamicItems } from '@components/DynamicForm/components/DynamicItems/DynamicItems.component';
import { LOAN_PROGRAM } from '@typings/common';
import {
  APPLICATION_TYPE,
  QUESTIONNAIRE_FORM_NAME_MAP,
} from '../../Application.constants';

interface QuestionnaireProps {
  applicationType: APPLICATION_TYPE;
  loanProgram: LOAN_PROGRAM;
}

export const Questionnaire = ({
  applicationType,
  loanProgram,
}: QuestionnaireProps) => {
  return (
    <DynamicItems
      fields={
        forms[
          QUESTIONNAIRE_FORM_NAME_MAP?.[applicationType]?.[
            loanProgram
          ] as AvailableForms
        ] ?? []
      }
    />
  );
};
