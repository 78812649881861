import React, { useContext, useMemo, useState } from 'react';
//MUI
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Stack,
} from '@mui/material';
//Library
import { FormProvider, useForm } from 'react-hook-form';
// API
import {
  useGetApiV1MeridianCraQuery,
  usePostApiV1MeridianReissueCreditReportMutation,
} from '@api/output/api';
//Types
import { LoanPartyInfo, Option } from '@typings/common';
//Hooks
import { useOpenToast } from '@hooks/useOpenToast';
//Components
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { DynamicTextField } from '@components/DynamicForm/components/DynamicTextField/DynamicTextField.component';
import { DynamicDropdown } from '@components/DynamicForm/components/DynamicDropdown/DynamicDropdown.component';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { useTasksBySection } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTasksBySection';
import { ReIssueMessage } from './components';
import { useReIssueCreditForm } from './hooks/useReIssueCreditForm';
import { RE_ISSUE_MESSAGE } from './reIssueCreditForm.type';
//Constants
import { FIELDS } from './reIssueCreditForm.constants';
//Styles
import styles from './reIssueCreditForm.module.scss';

export interface ReIssueCreditFormProps {
  party: LoanPartyInfo;
  setCreditReportAccordionExpanded: (state: boolean) => void;
  setCurrentlyExpandedCreditScoreDetailsId: (state: string | null) => void;
}

export const ReIssueCreditForm = ({
  party,
  setCreditReportAccordionExpanded,
  setCurrentlyExpandedCreditScoreDetailsId,
}: ReIssueCreditFormProps) => {
  const { disabled } = useContext(LoanDisableContext);
  const {
    useHandleCraOptions,
    handlePayloadBuilding,
    handleFieldError,
    handleScrollToScore,
  } = useReIssueCreditForm();
  const { data: craData = [], isLoading } = useGetApiV1MeridianCraQuery();
  const [reIssue, { isLoading: isQuering }] =
    usePostApiV1MeridianReissueCreditReportMutation();
  const craOptions = useHandleCraOptions(Array.from(craData));
  const openToast = useOpenToast();
  const methods = useForm({ mode: 'onBlur' });
  const {
    formState: { errors },
    setError,
    reset,
  } = methods;
  const [, reloadTasks] = useTasksBySection('credit_report');

  const [isMessage, setIsMessage] = useState('default');

  const handleSubmit = useMemo(() => {
    return methods.handleSubmit(async (values) => {
      methods.clearErrors();
      setIsMessage('default');
      const payload = handlePayloadBuilding(values, craData, party.id);

      try {
        const { error, tryMergeFound } = await reIssue({
          lolaBffMeridianApiClientModelsRequestReissueCreditRequest: payload,
        }).unwrap();

        const isSuccess = !error && tryMergeFound;

        if (isSuccess) {
          await reloadTasks();
          reset();
          setCreditReportAccordionExpanded(true);
          if (party.id) {
            setCurrentlyExpandedCreditScoreDetailsId(party.id);
          }
          handleScrollToScore(party.id);
        }

        if (error) {
          setIsMessage('fields');
          handleFieldError(error ?? '', setError);
        } else if (!tryMergeFound) {
          setIsMessage('tri-merge');
        }
      } catch (e) {
        openToast({
          title: 'Somenthing went wrong',
          description:
            'Something went wrong while trying to re-issue, please try again',
          isError: true,
        });
      }
    });
  }, [methods.handleSubmit, craData, party]);

  if (!craData || isLoading) {
    return (
      <Paper elevation={2} variant="filled">
        <Skeleton variant="text" height={200} />
      </Paper>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} className={styles.reIssueCreditForm}>
        <ReIssueMessage message={isMessage as RE_ISSUE_MESSAGE} />
        <Stack direction={{ xs: 'column' }} spacing={{ xs: 2 }}>
          <LabeledCard>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <DynamicTextField
                  type="number"
                  label="Re-issue number"
                  name={FIELDS.RE_ISSUE_NUMBER}
                  variant="standard"
                  required="Re-issue number is required"
                  helperText={errors?.reIssueNumber?.message as string}
                  optionalHelperText=""
                  data-testid={FIELDS.RE_ISSUE_NUMBER}
                />
              </Grid>
              <Grid item xs={6} data-testid={FIELDS.CRA}>
                <DynamicDropdown
                  name={FIELDS.CRA}
                  label="CRA"
                  variant="standard"
                  options={craOptions as Option[]}
                  required="CRA is required"
                />
              </Grid>
              <Grid item xs={6}>
                <DynamicTextField
                  type="text"
                  label="First name"
                  name={FIELDS.FIRST_NAME}
                  variant="standard"
                  helperText={errors?.firstName?.message as string}
                  required="First name is required"
                  optionalHelperText=""
                  data-testid={FIELDS.FIRST_NAME}
                />
              </Grid>
              <Grid item xs={6}>
                <DynamicTextField
                  type="text"
                  label="Last name"
                  name={FIELDS.LAST_NAME}
                  variant="standard"
                  helperText={errors?.lastName?.message as string}
                  required="Last name is required"
                  optionalHelperText=""
                  data-testid={FIELDS.LAST_NAME}
                />
              </Grid>
              <Grid item xs={6}>
                <DynamicTextField
                  type="text"
                  label="SSN"
                  name={FIELDS.SSN}
                  mask="ssn"
                  helperText={errors?.lastName?.message as string}
                  required="SSN is required"
                  optionalHelperText=""
                  data-testid={FIELDS.SSN}
                />
              </Grid>
            </Grid>
          </LabeledCard>
          <Button
            variant="outlined"
            size="large"
            type="submit"
            sx={{ alignSelf: 'flex-start' }}
            disabled={isQuering || disabled}
            startIcon={isQuering && <CircularProgress size={20} />}
          >
            Re-issue credit
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
