import React, { FocusEvent, memo, useCallback, useContext } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { InputControl } from '@lola/ui-react-components';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { useEventHandlerWrapper } from '@components/DynamicForm/hooks/useEventHandlerWrapper';
import { usePartiesRelationship } from '@pages/personalLoans/pages/buildMyLoan/hooks/usePartiesRelationship';
import { LoanPartyInfo } from '@typings/common';
import {
  UPDATE_MODE,
  useUpdateParty,
} from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateParty';
import { getPartyFieldPath } from '@pages/personalLoans/pages/buildMyLoan/utils/fields.utils';
import { useStatusToastsHandlers } from '@hooks/useStatusToastsHandlers';
import { useUpdateBuildMyLoanCache } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateBuildMyLoanCache';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { TITLE_OPTIONS } from './OwnershipRow.constants';

interface OwnershipProps {
  party?: LoanPartyInfo;
  path: string;
  entityId?: string | null;
}

const OwnershipRow = ({ entityId, party, path }: OwnershipProps) => {
  const liquidAssetTotalAmountName = `${path}.liquidAssetTotalAmount`;
  const partyRelationRoleName = `${path}.partyRelationRoles[0]`;
  const ownershipPercentName = `${path}.ownershipPercentage`;

  const { setError } = useFormContext();
  const { disabled } = useContext(LoanDisableContext);
  const { successHandler, errorHandler } = useStatusToastsHandlers();

  const createHandler = usePartiesRelationship();
  const updateParty = useUpdateParty();

  const ownershipPercentOnBlurCreator = useEventHandlerWrapper<
    FocusEvent<HTMLInputElement>
  >(ownershipPercentName, createHandler(entityId, party?.id));

  const { updatePartyPropertyCache } = useUpdateBuildMyLoanCache();

  const updatePartyHandler = useCallback(
    async (name: string, value: string | boolean) => {
      if (party?.id) {
        const key = getPartyFieldPath(name);
        try {
          await updateParty(UPDATE_MODE.INDIVIDUAL, party.id, key, value);
          updatePartyPropertyCache(party.id, key, value);
          successHandler();
        } catch (e) {
          setError(name, {});
          errorHandler(e as Error);
        }
      }
    },
    [updateParty, liquidAssetTotalAmountName]
  );

  const liquidAssetTotalAmountOnBlurCreator = useEventHandlerWrapper<
    FocusEvent<HTMLInputElement>
  >(liquidAssetTotalAmountName, updatePartyHandler);

  return (
    <TableRow>
      <TableCell>
        {party?.firstName} {party?.lastName}
      </TableCell>
      <TableCell>
        <SelectControl
          name={partyRelationRoleName}
          variant="filled"
          options={TITLE_OPTIONS}
          customHandler={createHandler(entityId, party?.id)}
          required="required"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <InputControl
          type="text"
          mask="positive"
          name={ownershipPercentName}
          placeholder="0.00%"
          variant="filled"
          onBlurCreator={ownershipPercentOnBlurCreator}
          required
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <InputControl
          type="text"
          mask="currency"
          name={liquidAssetTotalAmountName}
          placeholder="$0.00"
          variant="filled"
          onBlurCreator={liquidAssetTotalAmountOnBlurCreator}
          required
          disabled={disabled}
        />
      </TableCell>
    </TableRow>
  );
};

export const MemoizedOwnershipRow = memo(OwnershipRow);
