import React, { useContext } from 'react';
import { DatePickerControl } from '@lola/ui-react-components';
import { FormConfigContext } from '@components/DynamicForm/contexts/FormConfig.context';
import { CustomRegisterOptions } from '@components/DynamicForm/utils/yupToControllerRules';
import useFieldValidation from '@components/DynamicForm/hooks/useFieldValidation';
import { ControlType, ValidationRule } from '@form-configs/types';
import { MINIMUM_DATE } from '@constants/time.constants';
import { useFieldSpecificProps } from '@components/DynamicForm/hooks/useFieldSpecificProps';

export interface DynamicDatePickerProps {
  label: string;
  name: string;
  required?: string | boolean;
  optionalHelperText?: string;
  locked?: boolean;
  sync?: string;
  validation?: ValidationRule[];
  rules?: CustomRegisterOptions;
  helperText?: string;
  error?: boolean;
  minDate?: string;
  controlType?: ControlType;
}

export const DynamicDatePicker = ({
  label,
  name,
  required,
  validation,
  locked,
  sync,
  optionalHelperText = 'Optional',
  helperText,
  minDate = MINIMUM_DATE,
  controlType,
}: DynamicDatePickerProps) => {
  const { disabled, onBlur } = useContext(FormConfigContext);
  const fieldSpecificProps = useFieldSpecificProps(name);

  const { convertedRules, requiredProp } = useFieldValidation({
    validation,
    locked,
    required,
    controlType,
  });

  return (
    <DatePickerControl
      name={name}
      rules={convertedRules}
      label={label}
      disabled={disabled}
      required={requiredProp}
      optionalHelperText={optionalHelperText}
      helperText={helperText}
      locked={locked}
      sync={sync}
      minDate={new Date(minDate)}
      customBlurHandler={onBlur}
      {...fieldSpecificProps}
    />
  );
};
