import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { Header } from '@containers/Header';
import { Toast } from '@containers/Toast/Toast.component';
import styles from './rootLayout.module.scss';

export const RootLayout = () => {
  const { config } = useTheme();

  return (
    <>
      <Header
        logoUrl={config.images.logoInternal}
        title={config.companyLongName}
      />

      <div className={styles.content}>
        <Toast />
        <Outlet />
      </div>
    </>
  );
};
