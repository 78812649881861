import set from 'lodash/set';
import merge from 'lodash/merge';
import { Fee } from '@components/PartnerEconomics/PartnerEconomics.types';
import { Option, QUOTE_TYPE } from '@typings/common';
import {
  feesTypeOptionsForRental,
  feesTypeOptionsForShortTerm,
} from '@components/PartnerEconomics/PartnerEconomics.constants';
import {
  CalculatePayloadValuesWithFees,
  StringOrNumberRecord,
} from './feeUtils.types';

const convertFeesConfigToEmptyArray = (items: Option[]): Fee[] =>
  items.map(({ value }) => ({
    feeType: value.toString(),
    feeValue: '0',
  }));

const rentalDefaultFees = convertFeesConfigToEmptyArray(
  feesTypeOptionsForRental
);
const stfDefaultFees = convertFeesConfigToEmptyArray(
  feesTypeOptionsForShortTerm
);

const mapFeesToObjects = (fees: Fee[]): StringOrNumberRecord => {
  return fees.reduce((acc: StringOrNumberRecord, fee) => {
    const { feeType, feeValue = '' } = fee;
    if (!feeType) {
      return acc;
    }

    let value = 0;

    const parsedValue = parseInt(feeValue);
    if (!isNaN(parsedValue)) {
      value = Math.floor(parsedValue);
    }

    return set(acc, feeType, value);
  }, {} as StringOrNumberRecord);
};

export const replaceFeesWithObject = (
  values: CalculatePayloadValuesWithFees
): CalculatePayloadValuesWithFees => {
  const { fees, ...remainingValues } = values;

  const defaultFees =
    values.quoteType === QUOTE_TYPE.SHORT_TERM
      ? stfDefaultFees
      : rentalDefaultFees;

  const filledFees = fees ?? [];
  const fullFees = [...defaultFees, ...filledFees];
  const feesObject = mapFeesToObjects(fullFees);

  return merge(remainingValues, feesObject);
};
