import type { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldConfig } from '@form-configs/types';
import { convertFormConfigToYupSchema } from '@components/DynamicForm/utils/validation';

export const INFO_FORM_TOAST_CONFIG = {
  SUCCESS: {
    title: 'Success',
    description: 'Your profile details are updated',
  },
  ERROR: {
    isError: true,
    title: 'Error',
    description: 'Something went wrong, try again later',
  },
};

export enum FIELDS {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'userMetadata.phoneNumber',
  EMAIL = 'email',
  IS_PHONE_CALLS_NEEDED = 'userMetadata.receiveCall',
  IS_EMAILS_NEEDED = 'userMetadata.receiveEmail',
}

export const INFO_FORM_CONFIG: FieldConfig[] = [
  {
    id: '1',
    controlType: 'card',
    props: { label: 'General information' },
    items: [
      {
        id: '2',
        controlType: 'block',
        props: { col: [6, 6] },
        items: [
          {
            id: '3',
            controlType: 'text',
            props: {
              label: 'First name',
              type: 'text',
              name: FIELDS.FIRST_NAME,
              required: true,
              validation: [
                {
                  rule: 'required',
                },
              ],
            },
          },
          {
            id: '4',
            controlType: 'text',
            props: {
              label: 'Last name',
              type: 'text',
              name: FIELDS.LAST_NAME,
              required: true,
              validation: [
                {
                  rule: 'required',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  {
    id: '6',
    controlType: 'card',
    props: { label: 'Contact information' },
    items: [
      {
        id: '7',
        controlType: 'block',
        props: { col: [6, 6] },
        items: [
          {
            id: '8',
            controlType: 'text',
            props: {
              label: 'Phone number',
              type: 'text',
              name: FIELDS.PHONE,
              mask: "[/\\d/,/\\d/, /\\d/,'-', /\\d/,/\\d/, /\\d/, '-', /\\d/, /\\d/, /\\d/, /\\d/]",
              required: true,
              validation: [
                {
                  rule: 'required',
                },
              ],
            },
          },
          {
            id: '9',
            controlType: 'text',
            props: {
              label: 'Email',
              type: 'text',
              name: FIELDS.EMAIL,
              disabled: true,
              mask: 'email',
              required: true,
              validation: [
                {
                  rule: 'required',
                },
              ],
            },
          },
          {
            id: '10',
            controlType: 'checkbox',
            props: {
              label: 'I want to receive calls at this phone number',
              name: FIELDS.IS_PHONE_CALLS_NEEDED,
            },
          },
          {
            id: '11',
            controlType: 'checkbox',
            props: {
              label: 'I want to receive messages on this email',
              name: FIELDS.IS_EMAILS_NEEDED,
            },
          },
        ],
      },
    ],
  },
];

export const INFO_FORM_RESOLVER = yupResolver(
  convertFormConfigToYupSchema(INFO_FORM_CONFIG) as AnyObjectSchema
);
