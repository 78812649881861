import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { TableColumnType } from 'antd';
import { useFormContext } from 'react-hook-form';
import { RowRecord } from '@lola/ui-react-components';
import { LoanPartyInfo } from '@typings/common';
import { usePartyTaskStatuses } from '@pages/personalLoans/pages/buildMyLoan/hooks/usePartyTaskStatuses';
import {
  LolaBffApiContractsModelsLoanPartyInfo,
  usePostApiV1SigningSendCreditAuthorizationMutation,
} from '@api/output/api';
import { userInfoRenderer } from '@pages/personalLoans/renders';
import { ListWithAction } from '@components/ListWitAction/ListWithAction.component';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { createStatusRenderer } from '@utils/renderers/statusRenderer/createStatusRenderer';
import {
  AUTH_STATUS_CONFIG,
  AUTH_STATUS_TYPE,
  EMAIL_KEY,
} from './AuthorizationLinks.constants';

export interface AuthorizationLinksProps {
  parties: LoanPartyInfo[];
}

const columns: TableColumnType<LoanPartyInfo>[] = [
  {
    dataIndex: 'firstName',
    width: '45%',
    render: userInfoRenderer,
  },
  {
    dataIndex: 'contactPointEmailValue',
    width: '45%',
  },
  {
    dataIndex: 'status',
    render: createStatusRenderer<AUTH_STATUS_TYPE>(AUTH_STATUS_CONFIG),
    width: '10%',
  },
];

export const AuthorizationLinks = ({ parties }: AuthorizationLinksProps) => {
  const { disabled } = useContext(LoanDisableContext);
  const { loanId = '' } = useParams();
  const { partyStatuses, refetchStatuses, isStatusLoading } =
    usePartyTaskStatuses({
      parties,
      taskType: 'credit_report.authorization',
      defaultStatus: AUTH_STATUS_TYPE.NOT_SEND,
    });
  const [selectedParties, setSelectedParties] = useState<RowRecord[]>([]);

  const [sendLink, { isLoading }] =
    usePostApiV1SigningSendCreditAuthorizationMutation();

  const authorizationParties = useMemo(
    () =>
      partyStatuses.filter(
        ({ firstName, lastName }) => !!firstName || !!lastName
      ),
    [partyStatuses]
  );

  const authorizationPartiesHash = useMemo(
    () => authorizationParties.map((party) => party.id).join(''),
    [authorizationParties]
  );

  const shouldShowCheckboxes = authorizationParties.length > 1 && !disabled;

  useEffect(() => {
    const isSingleParty = authorizationParties.length === 1;
    if (isSingleParty) {
      setSelectedParties(authorizationParties);
    }
  }, [authorizationPartiesHash]);

  const allLinksHaveBeenSent = useMemo(
    () =>
      authorizationParties.every(
        ({ status }) =>
          status === AUTH_STATUS_TYPE.SENT ||
          status === AUTH_STATUS_TYPE.SIGNED ||
          status === AUTH_STATUS_TYPE.UPLOADED
      ),
    [authorizationParties]
  );

  const disabledRows = useMemo(() => {
    return authorizationParties.reduce((acc, party) => {
      if (party.status === AUTH_STATUS_TYPE.SIGNED && party.id != null) {
        acc.push(party.id);
      }
      return acc;
    }, [] as string[]);
  }, [authorizationParties]);

  const { formState, getValues, reset } = useFormContext();
  const isEmailChanged = useMemo(() => {
    const party = formState?.dirtyFields?.party;
    const isBorrowerEmailChanged = party?.borrower?.[EMAIL_KEY];
    const isGuarantorEmailChanged = party?.guarantors?.some(
      (guarantor: LolaBffApiContractsModelsLoanPartyInfo) =>
        guarantor?.[EMAIL_KEY]
    );
    return isBorrowerEmailChanged || isGuarantorEmailChanged;
  }, [formState?.dirtyFields?.party]);

  const handleSendLink = useCallback(() => {
    const correctedParties = shouldShowCheckboxes ? selectedParties : parties;
    const partyIds = correctedParties.map(({ id }) => id);
    sendLink({
      dealId: loanId,
      partyIds: partyIds as string[],
    })
      .unwrap()
      .then(() => {
        refetchStatuses();
        reset(getValues());
      })
      .catch((err) => console.error(err));
  }, [selectedParties, loanId, refetchStatuses, shouldShowCheckboxes]);

  return (
    <Grid item xs={12}>
      <ListWithAction
        disabledRows={disabledRows}
        columns={columns}
        dataSource={authorizationParties}
        isLoading={isLoading || isStatusLoading}
        submitHandler={handleSendLink}
        submitLabel="Send authorization link"
        isSubmitDisabled={
          disabled ||
          (shouldShowCheckboxes ? !selectedParties.length : false) ||
          isLoading ||
          (allLinksHaveBeenSent && !isEmailChanged)
        }
        onSelectedChange={setSelectedParties}
        isSelectionEnabled={shouldShowCheckboxes}
      />
    </Grid>
  );
};
