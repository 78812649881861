import React, { useEffect, useMemo } from 'react';
import { Paper, Grid, Divider, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { DynamicFields } from '@components/DynamicForm';
import { QUOTE_TYPE } from '@typings/common';
import {
  getBrokerEconomicsFee,
  getPartnerEconomicsCommon,
  getPartnerEconomicsFee,
} from '@components/PartnerEconomics/partnerEconomics.utils';
import { FeesFormBlock } from './components/FeesFormBlock.component';
import styles from './partnerEconomics.module.scss';

export interface PartnerEconomicsProps {
  quoteType: QUOTE_TYPE;
  disabled?: boolean;
}

export const PartnerEconomics = ({
  quoteType,
  disabled,
}: PartnerEconomicsProps) => {
  const { setValue } = useFormContext();

  const components = useMemo(
    () => ({
      feesFormBlock: FeesFormBlock,
    }),
    [quoteType]
  );

  const commonPartnerEconomicsFields = useMemo(
    () => getPartnerEconomicsCommon(),
    []
  );

  const brokerEconomicsFields = useMemo(
    () => getBrokerEconomicsFee(quoteType),
    [quoteType]
  );

  const listFields = useMemo(
    () => getPartnerEconomicsFee(quoteType),
    [quoteType]
  );

  useEffect(() => {
    setValue('fees', []);
  }, [quoteType]);

  const descriptionClasses = classnames(styles.description, {
    [styles.disabled]: disabled,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper variant="filled" className={styles.partnerEconomics}>
          <Grid container>
            <Grid item xs={12}>
              <LabeledCard label="Partner Economics" disabled={disabled}>
                <Typography component="p" className={descriptionClasses}>
                  Note that the values below will not appear on the HUD.
                </Typography>
                <DynamicFields
                  fields={commonPartnerEconomicsFields}
                  disabled={disabled}
                />
              </LabeledCard>
            </Grid>
            <Grid item xs={12} className={styles.divider}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <DynamicFields
                fields={listFields}
                components={components}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="filled" className={styles.partnerEconomics}>
          <Grid container>
            <Grid item xs={12}>
              <LabeledCard label="Broker Economics" disabled={disabled}>
                <Typography component="p" className={descriptionClasses}>
                  Note that the values below will not appear on the HUD, if a
                  broker is involved.
                </Typography>
                <DynamicFields
                  fields={brokerEconomicsFields}
                  disabled={disabled}
                />
              </LabeledCard>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
