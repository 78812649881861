import get from 'lodash/get';
import {
  Condition,
  ConditionConfig,
  ConditionMap,
  ConditionOperation,
  FormData,
} from '@form-configs/types';

const isValidCondition = (condition: ConditionConfig, values: FormData) => {
  const [operation, key, value] = condition;
  const fieldValue = get(values, key);
  switch (operation) {
    case ConditionOperation.EQUAL: {
      return fieldValue === value;
    }
    case ConditionOperation.NOT_EQUAL: {
      return fieldValue !== value;
    }
    case ConditionOperation.EXIST: {
      return !!fieldValue;
    }
    default: {
      return true;
    }
  }
};

export const getConditionalFieldNames = (condition?: ConditionMap) => {
  if (!condition) {
    return [];
  }

  return [
    ...condition[Condition.AND].map(([, key]) => key),
    ...condition[Condition.OR].map(([, key]) => key),
  ];
};

export const getConditionResult = (
  values: FormData,
  condition?: ConditionMap
): boolean => {
  if (!condition) {
    return true;
  }

  const isAndEqual = condition[Condition.AND].every((condition) =>
    isValidCondition(condition, values)
  );

  const isOrEqual = condition[Condition.OR].length
    ? condition[Condition.OR].some((condition) =>
        isValidCondition(condition, values)
      )
    : true;

  return isAndEqual && isOrEqual;
};
