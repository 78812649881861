import React from 'react';
import { IconButton, Tooltip, Grid } from '@mui/material';
import { InformationIcon } from '@lola/ui-react-components';
import { CardSection } from '@lola/ui-react-components';
import { useUploadDocuments } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUploadDocuments';
import { BuildMyLoanUploaderDrawer } from '@pages/personalLoans/pages/buildMyLoan/components/BuildMyLoanUploaderDrawer/BuildMyLoanUploaderDrawer.component';
import { useUploadActiveTask } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUploadActiveTask';
import { NotificationWithConfirmation } from '@components/NotificationWithConfirmation/NotificationWithConfirmation.component';
import { TASK_SECTIONS } from '@typings/common';
import {
  UPLOAD_DOCUMENTS_DEFAULT_DRAWER_CONFIG,
  UPLOAD_DOCUMENTS_DEFAULT_UPLOADER_CONFIG,
} from './uploadDocuments.constants';
import { UploadDocumentsCategory } from './components';
import styles from './uploadDocuments.module.scss';

export interface UploadDocumentsProps {
  section: TASK_SECTIONS;
  title?: string;
  description?: string;
}

export const UploadDocuments = ({
  section,
  title,
  description,
}: UploadDocumentsProps) => {
  const documents = useUploadDocuments(section);
  const {
    activeTask,
    uploadDrawerState,
    uploaderCloseHandler,
    uploadDrawerHandler,
    templates,
    templatesAreBeingFetched,
  } = useUploadActiveTask(section);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardSection title={title ?? ''} description={description ?? ''}>
          <NotificationWithConfirmation
            className={styles.notification}
            keyName="UploadDocumentNotification"
            expiresIn={10}
            severity="warning"
            title="Note"
            text="You can proceed without uploading all of the documents, but it will be required to upload all of them before you submit to feasibility."
            withIcon
            icon={
              <Tooltip title="TBD">
                <IconButton className={styles.iconWrapper}>
                  <InformationIcon className={styles.infoIcon} />
                </IconButton>
              </Tooltip>
            }
          />
          <div className={styles.documentsListWrapper}>
            {documents.map(
              (section) =>
                section.tasks && (
                  <div className={styles.documentsListItem} key={section.id}>
                    <UploadDocumentsCategory
                      {...section}
                      tasks={section.tasks}
                      uploadDrawerHandler={uploadDrawerHandler}
                    />
                  </div>
                )
            )}
          </div>
        </CardSection>
      </Grid>
      <BuildMyLoanUploaderDrawer
        task={activeTask}
        isOpen={uploadDrawerState}
        onClose={uploaderCloseHandler}
        uploaderConfig={UPLOAD_DOCUMENTS_DEFAULT_UPLOADER_CONFIG}
        drawerConfig={UPLOAD_DOCUMENTS_DEFAULT_DRAWER_CONFIG}
        templates={templates}
        templatesAreBeingFetched={templatesAreBeingFetched}
      />
    </Grid>
  );
};
