import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { InformationIcon } from '@lola/ui-react-components';
import { ActionBlock } from '@lola/ui-react-components';
import {
  Step,
  VERTICAL_STATUS_STATE,
  VerticalStepDetail,
} from '@components/verticalStepDetail/VerticalStepDetail.component';
import { CREDIT_SCORE_STEP_TITLES } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/components/CreditScore/CreditScore.constants';
import { LOAN_MILESTONE } from '@typings/common';
import { CreditScoreCollapsed } from '../CreditScoreCollapsed/CreditScoreCollapsed.component';
import { StatusIcon } from '../StatusIcon/StatusIcon.component';
import { getCreditScoreResult } from '../../CreditScore.utils';
import {
  CREDIT_SCORE_RESULT,
  CREDIT_SCORE_TASK_STATUS,
} from '../../CreditScore.types';
import { DynamicStepInfo } from './components/DynamicStepInfo/DynamicStepInfo.component';

interface CreditScoreActorDetailsProps {
  id: string;
  label: string;
  steps: Step[];
  currentlyExpandedCreditScoreDetailsId: string | null;
  setCurrentlyExpandedCreditScoreDetailsId: (state: string | null) => void;
  description?: string;
  score?: number;
  hasGeneratedDocument?: boolean;
  onOpenModal?: () => void;
}

export const CreditScoreActorDetails = ({
  id,
  label,
  description,
  score,
  steps,
  onOpenModal,
  hasGeneratedDocument,
  currentlyExpandedCreditScoreDetailsId,
  setCurrentlyExpandedCreditScoreDetailsId,
}: CreditScoreActorDetailsProps) => {
  const [isExpanded, setIsExpanded] = useState(!!score);
  const milestone = useWatch({ name: 'loan.dealMetadata.milestone' });
  const { step, currentState } = useMemo(() => {
    const activeSteps = [
      VERTICAL_STATUS_STATE.DONE,
      VERTICAL_STATUS_STATE.ERROR,
      VERTICAL_STATUS_STATE.IN_PROGRESS,
    ];

    const reversedSteps = [...steps];
    reversedSteps.reverse();

    const activeStep = reversedSteps.find(({ currentState }) =>
      activeSteps.includes(currentState as VERTICAL_STATUS_STATE)
    );

    if (!activeStep) {
      return {
        step: CREDIT_SCORE_STEP_TITLES[CREDIT_SCORE_TASK_STATUS.AUTHORIZATION],
        currentState: VERTICAL_STATUS_STATE.IN_PROGRESS,
      };
    }

    return activeStep;
  }, [steps]);

  const changeHandler = useCallback(
    (event: React.SyntheticEvent, expanded: boolean) => {
      setIsExpanded(expanded);
    },
    []
  );

  useEffect(() => {
    if (currentlyExpandedCreditScoreDetailsId === id) {
      setIsExpanded(true);
      setCurrentlyExpandedCreditScoreDetailsId(null);
    }
  }, [currentlyExpandedCreditScoreDetailsId, id]);

  const icon = useMemo(() => {
    const status =
      score && getCreditScoreResult(score) === CREDIT_SCORE_RESULT.POOR
        ? VERTICAL_STATUS_STATE.IN_PROGRESS
        : currentState;

    return <StatusIcon status={status as VERTICAL_STATUS_STATE} />;
  }, [currentState, score]);

  return (
    <CreditScoreCollapsed
      label={label}
      description={description}
      icon={icon}
      expanded={isExpanded}
      onChange={changeHandler}
    >
      <Grid container spacing={1}>
        {!!(milestone === LOAN_MILESTONE.SUSPENDED && score && score < 620) && (
          <Grid item xs={12}>
            <ActionBlock
              title="Credit score"
              description="The median credit score pulled was below the minimum per our guidelines. Our underwriting team will confirm with a final decision, so you may proceed with the rest of the application if you desire to provide a more full picture of the loan opportunity and your borrower's credit worthiness"
              icon={<InformationIcon />}
            />
          </Grid>
        )}

        <Grid item xs={5}>
          <DynamicStepInfo
            step={step as string}
            score={score}
            onOpenModal={onOpenModal}
            hasGeneratedDocument={hasGeneratedDocument}
            currentState={currentState as VERTICAL_STATUS_STATE}
          />
        </Grid>
        <Grid item xs={7}>
          <VerticalStepDetail data={steps} />
        </Grid>
      </Grid>
    </CreditScoreCollapsed>
  );
};
