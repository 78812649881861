import { useMemo } from 'react';
import { ROLES, USER_STATUS } from '@typings/common';
import { useRole } from '@hooks/useRole';

const ROLES_CHANGEABLE_BY: Partial<Record<ROLES, ROLES[]>> = {
  [ROLES.KEY_BROKER_MANAGER]: [
    ROLES.KEY_BROKER_MANAGER,
    ROLES.BROKER_MANAGER,
    ROLES.BROKER,
  ],
  [ROLES.BROKER_MANAGER]: [ROLES.BROKER],
};

export const useChangePermission = (
  role?: string,
  status?: string
): boolean => {
  const currentUserRole = useRole();

  return useMemo(() => {
    if (status !== USER_STATUS.ACTIVE) {
      return false;
    }

    if (ROLES_CHANGEABLE_BY[currentUserRole]?.includes(role as ROLES)) {
      return true;
    }

    return false;
  }, [currentUserRole, role, status]);
};
