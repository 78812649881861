import { Alert, Button, Grid } from '@mui/material';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateLoan } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateLoan';
import { QUOTE_ID_AGGREGATION_PATH } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';

export const DebugMode = ({ methods }: any) => {
  const { loanId = '' } = useParams();
  const updateLoan = useUpdateLoan();
  const detach = useCallback(async () => {
    const loanBody = {
      [QUOTE_ID_AGGREGATION_PATH]: '',
    };

    await updateLoan(loanId, loanBody);
    window.location.reload();
  }, [loanId]);

  const fill = useCallback(() => {
    methods.reset({
      loanSummary: {
        loanPurpose: 'Purchase',
        loanProduct: 'FixNFlip',
        propertyAddress: '1414 W Elm',
        city: 'Atlanta',
        formattedAddress: null,
        state: 'GA',
        zip: '45458',
        propertyType: 'SFR',
      },
      loanTerm: {
        channel: 'Lender Finance',
        referralFee: null,
        brokerFee: 1,
        loanTerm: '13',
        guaranty: 'Full Recourse',
        appraisalFee: null,
        brokerProcessingFee: null,
        projectInspectionFee: null,
        internalRefinance: null,
        exitFeeCheck: null,
        deferredOrigPercent: null,
      },
      leverage: {
        adjustedBlendedLTC: 0.0,
        adjustedPurchaseLTC: 0.0,
        adjustedARV: 0.0,
      },
      dealTerms: {
        targetCloseDate: '2023-10-31',
        timeCloseToPayoff: null,
        lesserOfLotOrPurchase: '150000',
        numOfProperties: 1,
        rehabConBudget: '150000',
        afterCompletedVal: '150000',
        refinance: null,
        originalPurchaseDate: '2023-10-31',
        existingDebtStatus: 'Purchase',
        payoff: 0.0,
        cashOut: null,
        heavyRehab: null,
        buyUpPercentage: null,
        buyDownPercentage: null,
        isZeroOrigFee: null,
        totalPurchasePrice: '150000',
      },
      borrowerInfo: {
        primaryGuarantor: 'GURDEEP TESTCOSB',
        secondaryGuarantor: null,
        borrowingEntity: 'Mahama Amala Inc1.',
        fnfCompletedInThreeYears: null,
        buildsCompletedInThreeYears: null,
        completedTransactions: null,
        qualifyingFICO: 800,
        combinedLiquidity: '5000000',
        borrowerTier: '4',
        usCitizen: 'Yes',
        altTier: null,
        borrowerIsReferral: null,
      },
      quoteType: 'STF',
      quoteName: 'qwerqwerqwer',
      sfAccountId: '',
      sfDealId: '',
      overrideMaster: false,
      hasSubmittedQuote: false,
      milestone: '',
      underwriterFields: {
        legalFee: '',
        taxImpound: '',
        insuranceImpound: '',
      },
      lenderFinance: {
        interestRateAddOn: '',
        originationPointsAddOn: '',
        applicationFee: '',
        documentFee: '',
        processingFee: '',
        lenderFinanceAppraisalFee: '',
        rpolt6: '',
        rpogt6: '',
        purchase: '',
      },
      exceptionPricingOverride: {
        commissionReduction: '',
        autoRate: 0.0,
        autoFee: 0.0,
      },
    });
  }, []);

  return (
    <Grid item style={{ display: 'none' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Alert severity="warning">
            Debug mode is enabled. Please disable it before going live.
          </Alert>
        </Grid>
        <Grid item>
          <Button onClick={detach} variant="contained">
            Detach
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={fill} variant="outlined">
            Fill STF
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
