import { AppState } from '@store/types';

export const isMutationLoading = (endpointName: string) => {
  return (state: AppState) => {
    return Object.values(state.api.mutations).some((mutation) => {
      const entity = mutation as { status: string; endpointName: string };
      return (
        entity.endpointName === endpointName && entity.status === 'pending'
      );
    });
  };
};
