import React from 'react';
import { NavLink } from 'react-router-dom';
import { IconButton, Typography, Link } from '@mui/material';
import { OpenInNewIcon, UploadIcon } from '@lola/ui-react-components';
import { LolaBffApiContractsModelsDashboardActionsLoanActionDto } from '@api/output/api';
import styles from './renderers.module.scss';

export const createActionRenderer = (
  onActionClick: (
    data: LolaBffApiContractsModelsDashboardActionsLoanActionDto
  ) => void
) => {
  return (
    _: unknown,
    row: LolaBffApiContractsModelsDashboardActionsLoanActionDto
  ) => {
    const appendix = row.moreTasksCount ? ` + ${row.moreTasksCount}` : '';

    const { displayedTaskName, loanId } = row;
    return (
      <div className={styles.actions}>
        <Typography variant="p">
          {displayedTaskName}
          {appendix}
        </Typography>

        {appendix ? (
          <Link
            component={NavLink}
            to={`/personal-loans/${loanId}/build-my-loan/review-&-submit-to-feasibility`}
          >
            <IconButton className={styles.actionButton}>
              <OpenInNewIcon />
            </IconButton>
          </Link>
        ) : (
          <IconButton
            className={styles.actionButton}
            onClick={() => onActionClick(row)}
          >
            <UploadIcon />
          </IconButton>
        )}
      </div>
    );
  };
};
