import React, { useCallback, useMemo } from 'react';
import { TableColumnType } from 'antd';
import classnames from 'classnames';
import flow from 'lodash/flow';
import {
  createDefaultSorter,
  createDateSorter,
  DataTable,
  dateRenderer,
  textRenderer,
  useTableScroll,
  AppliedFilter,
  FilterItem,
  RowRecord,
} from '@lola/ui-react-components';
import { LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse } from '@api/output/api';
import { DataControlPanel } from '@components/DataTableControlPanel/DataTableControlPanel.component';
import { useRole } from '@hooks/useRole';
import { adjustCreatedByColumnVisibilityByRole } from '@utils/quotesList.utils';
import { DataSearch } from '@components/DataTableControlPanel/components';
import {
  createQuoteStatusRenderer,
  QUOTE_STATUS,
} from '@pages/scenarioBuilder/pages/quotes/components/QuotesList/renderers/tableCell';
import styles from './listOfMyQuotes.module.scss';

type Quote = LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse;

interface ListOfMyQuotesProps {
  onChange: (id: string) => void;
  appliedFilters: Record<string, AppliedFilter>;
  filters: Record<string, FilterItem>;
  handleFilterChange: (filterKey: string, value: string[]) => void;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  fullHeight?: boolean;
  selectedId?: string;
  quotes?: Quote[];
}

const columns: TableColumnType<Quote>[] = [
  {
    dataIndex: 'name',
    title: 'Quote name',
    width: '20%',
    render: textRenderer(true),
    sorter: createDefaultSorter<Quote>('name'),
  },
  {
    dataIndex: 'productType',
    title: 'Product',
    width: '15%',
    sorter: createDefaultSorter<Quote>('productType'),
  },
  {
    dataIndex: 'isQuoteStaged',
    title: 'Quote status',
    width: '15%',
    render: createQuoteStatusRenderer({
      [QUOTE_STATUS.DRAFT]: 'Draft',
      [QUOTE_STATUS.STAGED]: 'Staged',
      [QUOTE_STATUS.ATTACHED]: 'Attached',
    }),
    defaultSortOrder: 'descend',
    sorter: createDefaultSorter('isQuoteStaged'),
  },
  {
    key: 'createdBy',
    dataIndex: 'portalUser',
    title: 'Created by',
    render: ({ fullName }) => fullName,
    sorter: createDefaultSorter('portalUser.fullName'),
    width: '25%',
  },
  {
    dataIndex: 'modifiedOn',
    title: 'Last loan date',
    width: '25%',
    render: dateRenderer,
    sorter: createDateSorter('modifiedOn'),
  },
];

export const SEARCH_HEADER_HEIGHT = 65;

export const ListOfMyQuotes = ({
  className,
  selectedId,
  onChange,
  fullHeight = false,
  quotes = [],
  appliedFilters,
  filters,
  handleFilterChange,
  handleSearchChange,
}: ListOfMyQuotesProps) => {
  const role = useRole();
  const [ref, scroll] = useTableScroll(fullHeight, -SEARCH_HEADER_HEIGHT, {
    x: 600,
    y: 400,
  });

  const updatedColumns = useMemo(() => {
    const transformations = flow(adjustCreatedByColumnVisibilityByRole(role));

    return transformations(columns);
  }, [columns, role]);

  const onSelect = useCallback(
    (row: Quote & RowRecord) => {
      if (row.id) {
        onChange?.(row.id);
      }
    },
    [onChange]
  );

  return (
    <div className={classnames(className, styles.wrapper)} ref={ref}>
      {Object.keys(filters).length > 0 ? (
        <DataControlPanel
          appliedFilters={appliedFilters}
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleSearchChange={handleSearchChange}
          className={styles.quotesListFilter}
          title="Filters"
        />
      ) : (
        <header className={styles.searchHeader}>
          <DataSearch handleSearchChange={handleSearchChange} />
        </header>
      )}

      <DataTable
        rowKey="id"
        highlightRows={selectedId ? [selectedId] : undefined}
        columns={updatedColumns}
        dataSource={quotes}
        onRowClick={onSelect}
        scroll={scroll}
      />
    </div>
  );
};
