import React from 'react';
import { Typography, Grid } from '@mui/material';
import { StatusChip } from '@lola/ui-react-components';
import { Collapsed } from '@components/Collapsed';
import { SUMMARY_QUOTE_STATUS_CONFIG } from '@pages/scenarioBuilder/pages/quoteForm/QuoteForm.constants';
import styles from './quoteSummarySidebar.module.scss';

interface QuoteSummarySidebarProps {
  quoteName: string;
  status: string;
}

export const QuoteSummarySidebar = ({
  quoteName,
  status,
}: QuoteSummarySidebarProps) => {
  return (
    <Collapsed defaultExpanded label="Quote summary">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="pSmall"
            paragraph
            mb={0.5}
            className={styles.descriptionText}
          >
            Quote type
          </Typography>
          <Typography variant="pBold" paragraph>
            {quoteName}
          </Typography>
        </Grid>
        {!!status && (
          <Grid item xs={12}>
            <Typography
              variant="pSmall"
              paragraph
              mb={0.5}
              className={styles.descriptionText}
            >
              Status
            </Typography>
            <StatusChip config={SUMMARY_QUOTE_STATUS_CONFIG} status={status} />
          </Grid>
        )}
      </Grid>
    </Collapsed>
  );
};
