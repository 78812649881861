import React, { ChangeEvent, useCallback } from 'react';
import { OnUploadType } from '@components/Uploader/Uploader.types';

export const useUploadButtonLogic = () => {
  const handleUploadClick = useCallback(
    (inputRef: React.RefObject<HTMLInputElement>) => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    },
    []
  );

  const handleUploadChange = useCallback(
    async (
      e: ChangeEvent<HTMLInputElement>,
      onClick: OnUploadType,
      maxFiles?: number
    ) => {
      const files = e.target.files;

      if (!files || (maxFiles && files.length > maxFiles)) {
        e.target.value = '';
        return;
      }

      await onClick(
        Array.from(files).map((file) => ({
          file,
          fileName: file.name,
        }))
      );

      e.target.value = '';
    },
    []
  );

  return {
    handleUploadChange,
    handleUploadClick,
  };
};
