import React from 'react';
// router
import { useParams } from 'react-router-dom';
// MUI
import {
  Grid,
  Paper,
  Typography,
  Alert,
  AlertTitle,
  Skeleton,
} from '@mui/material';
// Icons
import { StatusInProgressIcon } from '@lola/ui-react-components';
// API
import {
  useGetApiV1ClosemyloanGetInClosingQuery,
  useGetApiV1LoansByLoanIdQuery,
} from '@api/output/api';
// global components
import { NoContent } from '@components/NoContent';
// components
import { CloseMyLoanContainer } from '../../components/index';
// styles
import styles from './InClosing.module.scss';
// text
import textData from './__text__/InClosingText.json';

const { title, startDescription, endDescription, loanTerms, noDataTitle } =
  textData;

export const InClosingPage = () => {
  // router
  const { loanId = '' } = useParams();

  // api
  const { data, isLoading } = useGetApiV1ClosemyloanGetInClosingQuery({
    loanId,
  });
  const { data: loanDetailsData, isFetching } = useGetApiV1LoansByLoanIdQuery({
    loanId,
  });

  if (isLoading || isFetching) {
    return (
      <CloseMyLoanContainer className={styles.container}>
        <>
          <Grid item xs={12}>
            <Skeleton variant="text" role="skeleton" />
            <Skeleton variant="text" />
            <Skeleton
              variant="rounded"
              height={100}
              className={styles.skeleton}
            />
            <Paper variant="filled" elevation={2} className={styles.skeleton}>
              <Skeleton variant="text" width={350} />
              <ul className={styles.list}>
                {Array(6)
                  .fill(null)
                  .map((_, i) => i)
                  .map((element) => (
                    <li key={element}>
                      <Skeleton variant="text" width={200} />
                      <Skeleton variant="text" width={150} />
                    </li>
                  ))}
              </ul>
            </Paper>
          </Grid>
        </>
      </CloseMyLoanContainer>
    );
  }

  return (
    <CloseMyLoanContainer className={styles.container}>
      <>
        <Grid item xs={12}>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="p">
            {`${startDescription} #${loanDetailsData?.loanIdentifier} ${endDescription}`}
          </Typography>
        </Grid>

        {data?.messagingArea?.message && (
          <Grid item xs={12}>
            <Alert severity={data?.messagingArea?.messageStatus}>
              <AlertTitle>{data?.messagingArea?.title}</AlertTitle>
              {data?.messagingArea?.message}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Paper variant="filled" elevation={2}>
            {!data?.terms && (
              <NoContent
                title={noDataTitle}
                icon={<StatusInProgressIcon />}
                variant="grey"
              />
            )}

            {data?.terms && (
              <>
                <Typography variant="h3">{loanTerms}</Typography>
                <ul className={styles.list}>
                  {data?.terms.map((term) => (
                    <li key={term.title}>
                      <Typography variant="pDescription">
                        {term.title}
                      </Typography>
                      <Typography variant="pBold">{term.label}</Typography>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Paper>
        </Grid>
      </>
    </CloseMyLoanContainer>
  );
};
