import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import { isLoanDisabled } from '@pages/personalLoans/pages/buildMyLoan/utils/loan.utils';
import { REVIEW_AND_SUBMIT_CONSENT } from '../../buildMyLoan.constants';
import { isAllRequiredStepsDone } from './useBuildMyLoan.utils';

const REVIEW_AND_SUBMIT: ProgressStepperItem = {
  id: '/personal-loans/:id/build-my-loan/review-&-submit-to-feasibility',
  to: 'review-&-submit-to-feasibility',
  title: 'Review & submit',
};

export const useReviewAndSubmit = (
  requiredSteps: (ProgressStepItemStatus | undefined)[]
) => {
  const milestone = useWatch({ name: 'loan.dealMetadata.milestone' });
  const consent = useWatch({ name: REVIEW_AND_SUBMIT_CONSENT });

  const status = useMemo(() => {
    const loanDisabledState = isLoanDisabled(milestone);
    const isSigned = loanDisabledState || consent;

    if (isSigned && isAllRequiredStepsDone(requiredSteps)) {
      return ProgressStepItemStatus.DONE;
    }

    return ProgressStepItemStatus.IN_PROGRESS;
  }, [requiredSteps, milestone, consent]);

  return useMemo(
    () => ({
      ...REVIEW_AND_SUBMIT,
      status,
    }),
    [status]
  );
};
