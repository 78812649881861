import { useMemo } from 'react';
import get from 'lodash/get';
import { useLoanQuote } from '@pages/personalLoans/pages/buildMyLoan/hooks/useLoanQuote';
import { QUOTE_TYPE } from '@typings/common';

export const usePropertiesNumber = () => {
  const { data, quoteType } = useLoanQuote();

  const propertiesKey =
    quoteType === QUOTE_TYPE.SHORT_TERM
      ? 'in.dealTerms.numOfProperties'
      : 'in.numOfProperties';

  return useMemo(() => {
    if (!data) {
      return undefined;
    }

    return get(data, propertiesKey);
  }, [data, propertiesKey]);
};
