import React from 'react';
// MUI
import { Alert, Grid } from '@mui/material';
// global Types
import { FileItem, OnUploadType } from '@components/Uploader/Uploader.types';
// global components
import { UploadedFileCard } from '@components/UploadedFileCard/UploadedFileCard.component';
import { ExceptionUploaderButton } from '../ExceptionUploaderButton/ExceptionUploaderButton.component';
//style
import style from './exceptionRequestFileUpload.module.scss';

export interface ExceptionRequestFileUploadProps {
  errors: string | null;
  maxSize: number;
  isAcceptFile: string[];
  uploadFiles: OnUploadType;
  files: FileItem[];
}

export const ExceptionRequestFileUpload = ({
  errors,
  maxSize,
  isAcceptFile,
  uploadFiles,
  files,
}: ExceptionRequestFileUploadProps) => {
  if (files?.length > 0) {
    return (
      <UploadedFileCard
        fileName={files[0].fileName}
        disabled={false}
        onDelete={() => alert('Clicked')}
      />
    );
  }
  return (
    <Grid>
      <>
        <ExceptionUploaderButton
          labelConfig="Attach files"
          onClick={uploadFiles}
          maxFiles={1}
          size={maxSize}
          accept={isAcceptFile.map((file) => `.${file}`).join(',')}
        />
        <br />
        {errors && (
          <Alert severity="error" className={style.error}>
            {errors}
          </Alert>
        )}
      </>
    </Grid>
  );
};
