import { useContext, useMemo } from 'react';
import { DropdownOption } from '@form-configs/types';
import { options as allOptions } from '@form-configs/output';
import { DropdownListsContext } from '@components/DynamicForm';
import { Option } from '@typings/common';

export const useOptions = (
  optionsKey = '',
  options?: DropdownOption[]
): Option[] => {
  const lists = useContext(DropdownListsContext);

  return useMemo(() => {
    return options ?? lists[optionsKey] ?? allOptions[optionsKey] ?? [];
  }, [options, lists, optionsKey]);
};
