import React from 'react';
import { Box, Typography } from '@mui/material';
import { QuoteSummaryItem } from '@typings/common';
import styles from './quoteSummaryInfo.module.scss';

interface QuoteSummaryInfoProps extends QuoteSummaryItem {
  marker?: string;
}

export const QuoteSummaryInfo = ({
  title,
  items,
  marker = '',
}: QuoteSummaryInfoProps) => {
  return items.length ? (
    <Box className={styles.box}>
      <Typography variant="pBold" paragraph mb={0.5}>
        {title}
      </Typography>
      {items.map((item) => (
        <Typography key={item} variant="pSmall" paragraph mb={0.5}>
          {marker ? `${marker} ` : ''}
          {item}
        </Typography>
      ))}
    </Box>
  ) : null;
};
