import type { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export enum FIELDS {
  OLD_PASSWORD = 'oldPassword',
  NEW_PASSWORD = 'newPassword',
  CONFIRM_PASSWORD = 'confirmPassword',
}

export const CHANGE_PASSWORD_FORM_TOAST_CONFIG = {
  SUCCESS: {
    title: 'Success',
    description: 'Password has been changed',
  },
};

const getPasswordValidationError = (symbol: string) =>
  `Must contain at least one ${symbol}`;

const changePasswordValidationSchema: AnyObjectSchema = yup.object().shape({
  [FIELDS.OLD_PASSWORD]: yup.string().required('Current password is required'),
  [FIELDS.NEW_PASSWORD]: yup
    .string()
    .required('New password is required')
    .min(8, 'Must have at least 8 characters')
    .matches(/\d/, getPasswordValidationError('number'))
    .matches(/[A-Za-z]/, getPasswordValidationError('letter'))
    .matches(
      /[$&+,:;=?@#|'<>.^*()%!-]/,
      getPasswordValidationError("special symbol ($&+,:;=?@#|'<>.^*()%!-)")
    ),
  [FIELDS.CONFIRM_PASSWORD]: yup
    .string()
    .oneOf([yup.ref(FIELDS.NEW_PASSWORD)], 'Passwords must match'),
});

export const CHANGE_PASSWORD_RESOLVER = yupResolver(
  changePasswordValidationSchema
);
