import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';
import { Option } from '@typings/common';

export const convertLoansResponseToOptions = (
  response: LolaBffApiContractsModelsLoanLoanView[] = []
): Option[] => {
  return response.map(({ id, borrowerName, loanType, loanNumber }) => ({
    value: id ?? -1,
    label: `${borrowerName ?? ''}
        ${loanType ?? ''}
        ${loanNumber ? '#' + loanNumber : ''}`,
  }));
};
