import {
  EntityBasedSwitcherConfigs,
  SwitcherItem,
} from '@components/ModeSwitcher/ModeSwitcher.types';
import { ROLES } from '@typings/common';

export enum MODE_SWITCHER_VALUES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

const activeInactiveSwitcherItem: SwitcherItem<MODE_SWITCHER_VALUES> = {
  defaultValue: MODE_SWITCHER_VALUES.ACTIVE,
  options: [
    {
      label: 'Active',
      value: MODE_SWITCHER_VALUES.ACTIVE,
    },
    {
      label: 'Inactive',
      value: MODE_SWITCHER_VALUES.INACTIVE,
    },
  ],
};

export const MY_LOANS_CONFIGS: EntityBasedSwitcherConfigs<MODE_SWITCHER_VALUES> =
  {
    [ROLES.KEY_BROKER_MANAGER]: activeInactiveSwitcherItem,
    [ROLES.BROKER_MANAGER]: activeInactiveSwitcherItem,
  };
