import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { SubpageHeaderComponent } from '@components/SubpageHeader/SubpageHeader.component';
import {
  PORTAL_TEAM_MEMBERS_ACTIONS_ID,
  PORTAL_TEAM_MANAGEMENT_FOOTER_ID,
  TEAM_MANAGEMENT_TABS,
  ROLE_DEFAULT_TAB,
  TEAM_MANAGEMENT_BASE_PATH,
  MEMBERS_TAB,
} from '@pages/teamManagement/TeamManagement.constants';
import { useRole } from '@hooks/useRole';
import { NavigationConfig } from '@typings/common';
import { useActiveRoute } from '@hooks/useActiveRoute';
import { useDefaultRouteNavigate } from '@hooks/useDefaultRouteNavigate';
import styles from './teamManagement.module.scss';

export const TeamManagement = () => {
  const role = useRole();
  const tabs = TEAM_MANAGEMENT_TABS[role] ?? [];
  const [activeTab] = useActiveRoute(tabs);
  const defaultTab = ROLE_DEFAULT_TAB[role] ?? MEMBERS_TAB;
  const defaultNavigation = useDefaultRouteNavigate(
    TEAM_MANAGEMENT_BASE_PATH,
    defaultTab.to
  );

  if (defaultNavigation) {
    return defaultNavigation;
  }

  return (
    <>
      <SubpageHeaderComponent title="Team management">
        <div
          id={PORTAL_TEAM_MEMBERS_ACTIONS_ID}
          className={styles.headerButtons}
        />
      </SubpageHeaderComponent>
      <Tabs className={styles.tabs} value={activeTab}>
        {tabs?.map((props: NavigationConfig) => (
          <Tab key={props.label} component={NavLink} {...props} />
        ))}
      </Tabs>
      <Outlet />;
      <footer id={PORTAL_TEAM_MANAGEMENT_FOOTER_ID} />;
    </>
  );
};
