import React, { useMemo } from 'react';
import classnames from 'classnames';
import {
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { ActionBlock } from '@lola/ui-react-components';
import { CalculationBlock } from '@components/CalculationBlock/CalculationBlock.component';
import {
  CalculatedSection,
  convertQuoteObjectToCalculationBlock,
} from '@utils/quotes.utils';
import { LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse } from '@api/output/api';
import { RENTAL_CALCULATION_SECTIONS_CONFIGS } from './ScenarioRentalCalculation.constants';
import {
  RowHead,
  RowCell,
  LoanToValue,
  PreferredScenario,
  IncludedInQuote,
  ScenarioEligibility,
  ScenarioInterestRate,
  ScenarioCashToTitle,
} from './components';
import {
  Scenario,
  ScenarioComponent,
  ScenarioTitleComponent,
} from './ScenarioRentalCalculation.types';
import { useGetScenarios } from './hooks/useGetScenarios';
import {
  createAmountComponent,
  createCashToComponent,
} from './ScenarioRentalCalculation.utils';
import styles from './scenarioRentalCalculation.module.scss';

interface ScenarioRow {
  title: string;
  TitleComponent?: ScenarioTitleComponent;
  dataIndex: string;
  className?: string;
  Component?: ScenarioComponent;
}

interface ScenarioRentalCalculationContainerProps {
  details: LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse;
  isCalculating?: boolean;
  disabled?: boolean;
}

interface ScenarioRendererProps {
  scenarios: Scenario[];
  calculationSectionBlocks: CalculatedSection[];
  isCalculating?: boolean;
  disabled?: boolean;
}

const rows: ScenarioRow[] = [
  {
    title: 'Loan to value',
    dataIndex: 'loanToValue',
    className: styles.inputRow,
    Component: LoanToValue,
  },
  {
    title: 'Eligibility',
    dataIndex: 'eligibility',
    Component: ScenarioEligibility,
  },
  {
    title: 'Loan amount',
    dataIndex: 'loanAmount',
    Component: createAmountComponent('loanAmount'),
  },
  {
    title: 'Interest rate',
    dataIndex: 'initFixedInterestRate',
    Component: ScenarioInterestRate,
  },
  {
    title: 'Cash to',
    TitleComponent: ScenarioCashToTitle,
    dataIndex: 'cashTo',
    Component: createCashToComponent('cashTo'),
  },
  {
    title: 'Origination fee',
    dataIndex: 'originationFee',
    Component: createAmountComponent('originationFee'),
  },
  {
    title: 'RDSR',
    dataIndex: 'rdsr',
  },
  {
    title: 'Preferred scenario',
    dataIndex: 'preferred',
    Component: PreferredScenario,
  },
  {
    title: 'Included in quote',
    dataIndex: 'included',
    Component: IncludedInQuote,
  },
];

const ScenarioRentalCalculationRenderer = ({
  scenarios,
  calculationSectionBlocks,
  disabled,
}: ScenarioRendererProps) => {
  const extendedScenarios = useMemo(() => {
    return scenarios.map((scenario, index) => ({
      ...scenario,
      index: index,
      total: scenarios.length,
    }));
  }, [scenarios]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={styles.wrapper}>
          <div className={styles.head}>
            {rows.map(({ title, TitleComponent, dataIndex, className }) => (
              <RowHead
                key={dataIndex}
                title={title}
                titleComponent={
                  TitleComponent && <TitleComponent scenarios={scenarios} />
                }
                dataIndex={dataIndex}
                className={classnames(styles.item, styles.head, className)}
              />
            ))}
          </div>
          {extendedScenarios?.map((scenario) => (
            <div
              key={scenario.id}
              className={classnames(styles.column, {
                [styles.selected]: false,
              })}
            >
              <div className={styles.title}>
                <Typography variant="pBold">Scenario {scenario.id}</Typography>
              </div>
              {rows.map(({ dataIndex, className, Component }) => (
                <RowCell
                  key={dataIndex}
                  scenario={scenario}
                  dataIndex={dataIndex}
                  Component={Component}
                  disabled={disabled}
                  className={classnames(styles.item, className)}
                />
              ))}
            </div>
          ))}
        </div>
      </Grid>
      <Grid item xs={12}>
        {calculationSectionBlocks.map((block) => (
          <Grid key={block.title} item xs={12}>
            <CalculationBlock {...block} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export const ScenarioRentalCalculation = ({
  isCalculating,
  details,
  disabled,
}: ScenarioRentalCalculationContainerProps) => {
  const scenarios = useGetScenarios(details);

  const calculationSectionBlocks = useMemo(
    () =>
      convertQuoteObjectToCalculationBlock(
        details,
        RENTAL_CALCULATION_SECTIONS_CONFIGS
      ),
    [details]
  );

  if (!scenarios.length) return null;

  return (
    <>
      <Divider className={styles.negativeDivider} />
      <Typography variant="h3" className={styles.paragraph}>
        Scenarios
      </Typography>
      <ActionBlock
        className={styles.actionBlock}
        title="Apply LTV"
        description="To see different scenarios, adjust the input data in Loan to value section. After finishing, press Apply LTV and Recalculate button."
      >
        <>
          <Button variant="contained" type="submit" disabled={disabled}>
            Apply LTV and Recalculate
          </Button>
          {isCalculating && (
            <LinearProgress
              className={styles.loadingIndicator}
              data-cy="linear-progress"
            />
          )}
        </>
      </ActionBlock>

      <ScenarioRentalCalculationRenderer
        isCalculating={isCalculating}
        scenarios={scenarios}
        calculationSectionBlocks={calculationSectionBlocks}
        disabled={disabled}
      />
    </>
  );
};
