import { LolaBffApiContractsModelsApproveMyLoanResponseShowCondition } from '@api/output/api';
import { FilterButtonsCopies } from '../../../constants/filterButtonsCopies';

export const useFilteredButtons = (
  conditions: LolaBffApiContractsModelsApproveMyLoanResponseShowCondition[]
) => {
  const defaultFilters = ['All'];

  if (!conditions || !conditions.length) {
    return defaultFilters;
  }

  const statusesSet = new Set(defaultFilters);

  conditions.forEach((el) => {
    el.conditions?.forEach((subEl) => {
      if (subEl.status) {
        statusesSet.add(subEl.status);
      }
    });
  });

  return FilterButtonsCopies.filter((item) => statusesSet.has(item));
};
