import { styled } from '@mui/material/styles';

export const RemainingElements = styled('div')({
  display: 'inline-flex',
  position: 'relative',
  '&:hover div': {
    display: 'block',
  },
  '& > div': {
    cursor: 'pointer',
  },
});

export const RemainingElementsList = styled('div')(({ theme }) => ({
  background: theme.palette.custom.white,
  borderRadius: 8,
  boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
  display: 'none',
  left: 0,
  minWidth: 100,
  position: 'absolute',
  top: 32,
  zIndex: 1,
  '& ul': {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      padding: 12,
    },
  },
}));
