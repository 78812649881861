import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  LolaBffApiContractsModelsLoanPartyInfo,
  useGetApiV1BorrowersSearchQuery,
} from '@api/output/api';

export const useEntitySearch = () => {
  const { watch } = useFormContext();
  const [primaryGuarantor = {}, guarantors = []] = watch([
    'party.borrower',
    'party.guarantors',
  ]);

  const childPartyId = useMemo(() => {
    const guarantorIds = guarantors.map(
      ({ id }: LolaBffApiContractsModelsLoanPartyInfo) => id
    );
    return [primaryGuarantor.id, guarantorIds];
  }, [primaryGuarantor, guarantors]);

  const { data: initialData, isFetching } = useGetApiV1BorrowersSearchQuery(
    {
      type: 'entity',
      childPartyId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const data = useMemo(() => {
    return initialData?.filter(({ hasChildParty }) => hasChildParty);
  }, [initialData]);

  return useMemo(
    () => ({
      data,
      isFetching,
    }),
    [data, isFetching]
  );
};
