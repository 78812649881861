import { useMemo } from 'react';
import { useLolaTasks } from '@hooks/useLolaTasks';
import { getLolaTaskByName } from '@pages/personalLoans/pages/buildMyLoan/utils/tasks.utils';
import {
  CONSTRUCTION_INSPECTION_TASK,
  PROGRESS_STEPS_CONFIG,
} from '../ConstructionBudget.constants';
import {
  getCurrentStatus,
  getCurrentStepMessage,
  getCurrentStepStatus,
} from '../ConstructionBudget.utils';

export const useInspectionProgress = () => {
  const { tasks } = useLolaTasks();

  const inspectionProgress = useMemo(() => {
    return PROGRESS_STEPS_CONFIG.map((config) => {
      const task = getLolaTaskByName(tasks, config.taskName);
      const currentState = getCurrentStepStatus(config, task);
      const message = getCurrentStepMessage(config, currentState, task);

      return {
        step: config.step,
        lastChange: message,
        currentState,
      };
    });
  }, [tasks]);

  const inspectionStatus = useMemo(
    () =>
      getCurrentStatus(
        [
          CONSTRUCTION_INSPECTION_TASK.ASSIGNED,
          CONSTRUCTION_INSPECTION_TASK.INSPECTION,
        ],
        tasks
      ),
    [tasks]
  );

  return {
    inspectionProgress,
    inspectionStatus,
  };
};
