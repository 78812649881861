import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ROLES } from '@typings/common';
import { useGetApiV1UsersMeQuery } from '@api/output/api';
import { selectAccessToken } from '@store/selectors/auth.selectors';

export const useRole: (isRealtime?: boolean) => ROLES = (isRealtime) => {
  const token = useSelector(selectAccessToken);
  const { data, isError, isFetching, refetch } = useGetApiV1UsersMeQuery();

  useEffect(() => {
    if (isRealtime && !isFetching) {
      refetch();
    }
  }, [token, isRealtime]);

  if (isError || !data || !data.role) {
    return ROLES.NOT_SET;
  }

  return data.role as ROLES;
};
