import { FC } from 'react';
import {
  ListOfBorrowers,
  ListOfBorrowersProps,
} from '@containers/ListOfBorrowers/ListOfBorrowers.component';
import {
  ListOfEntities,
  ListOfEntitiesProps,
} from '@containers/ListOfEntities/ListOfEntities.component';
import { EntityType } from '../../NewExistingSelector.types';

type CommonListProps = ListOfBorrowersProps | ListOfEntitiesProps;

export const ENTITY_TYPE_LIST: Record<EntityType, FC<CommonListProps>> = {
  primaryGuarantor: ListOfBorrowers,
  guarantor: ListOfBorrowers,
  entity: ListOfEntities,
};

export const DRAWER_TITLE: Record<EntityType, string> = {
  primaryGuarantor: 'List of guarantors',
  guarantor: 'List of guarantors',
  entity: 'List of entities',
};
