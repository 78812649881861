import { ChipProps } from '@mui/material';
import { DOCUMENT_STATUS_TYPE } from '@pages/personalLoans/pages/documentLocker/types';

export const STATUS_PROPS_MAP: Record<string, Partial<ChipProps>> = {
  [DOCUMENT_STATUS_TYPE.PENDING]: {
    color: 'info',
    variant: 'outlined',
    label: 'Pending',
    size: 'small',
  },
  [DOCUMENT_STATUS_TYPE.DENIED]: {
    color: 'error',
    variant: 'filled',
    size: 'small',
  },
  [DOCUMENT_STATUS_TYPE.ATTENTION]: {
    color: 'warning',
    variant: 'filled',
    size: 'small',
  },
  [DOCUMENT_STATUS_TYPE.APPROVED]: {
    color: 'success',
    variant: 'filled',
    size: 'small',
  },
};
