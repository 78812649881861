import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { set } from 'react-hook-form';
import {
  api,
  GetApiV1LoansByLoanIdApiResponse,
  GetApiV1LoansByLoanIdPartiesApiResponse,
  GetApiV1LoansByLoanIdPropertiesApiResponse,
} from '@api/output/api';
import { CustomValueType, LoanPartyInfo, ROLE_TYPE } from '@typings/common';
import { ExtendedLoanPartyInfo } from '../buildMyLoan.type';

export const useUpdateBuildMyLoanCache = () => {
  const { loanId = '' } = useParams();
  const dispatch = useDispatch();

  const deletePartyCacheById = useCallback((id: string) => {
    dispatch(
      api.util.updateQueryData(
        'getApiV1LoansByLoanIdParties',
        { loanId },
        (draft: GetApiV1LoansByLoanIdPartiesApiResponse) => {
          const indexToDelete = draft.findIndex((p) => p.id === id);

          if (indexToDelete !== -1) {
            draft.splice(indexToDelete, 1);
          }
        }
      ) as unknown as AnyAction
    );
  }, []);

  const deletePartyCacheByIndex = useCallback((index: number) => {
    dispatch(
      api.util.updateQueryData(
        'getApiV1LoansByLoanIdParties',
        { loanId },
        (draft: ExtendedLoanPartyInfo[]) => {
          const indexToDelete = draft.findIndex((p) => p.index === index);

          if (indexToDelete !== -1) {
            draft.splice(indexToDelete, 1);
          }
        }
      ) as unknown as AnyAction
    );
  }, []);

  const updatePartyInCache = useCallback(
    (
      party: LoanPartyInfo,
      role: ROLE_TYPE,
      matchFunction: (p: ExtendedLoanPartyInfo) => boolean
    ) => {
      const partyWithRelationRoleType = {
        ...party,
        relationRoleType: [role],
      };

      dispatch(
        api.util.updateQueryData(
          'getApiV1LoansByLoanIdParties',
          { loanId },
          (draft: GetApiV1LoansByLoanIdPartiesApiResponse) => {
            if (
              role === ROLE_TYPE.PRIMARY_INDIVIDUAL ||
              role === ROLE_TYPE.PRIMARY_ENTITY
            ) {
              const indexToUpdate = draft.findIndex((p) =>
                p.relationRoleType?.includes(role)
              );
              if (indexToUpdate !== -1) {
                Object.assign(draft[indexToUpdate], partyWithRelationRoleType);
              } else {
                draft.push(partyWithRelationRoleType);
              }
            } else if (role === ROLE_TYPE.OTHER_INDIVIDUAL) {
              const indexToUpdate = draft.findIndex(matchFunction);
              if (indexToUpdate !== -1) {
                Object.assign(draft[indexToUpdate], partyWithRelationRoleType);
              } else {
                draft.push(partyWithRelationRoleType);
              }
            }
          }
        ) as unknown as AnyAction
      );
    },
    [dispatch]
  );

  const updatePartyCacheById = useCallback(
    (party: LoanPartyInfo, role: ROLE_TYPE, entityId?: string) => {
      updatePartyInCache(
        party,
        role,
        ({ id }) => !!(entityId && id === entityId)
      );
    },
    [updatePartyInCache]
  );

  const updatePartyCacheByIndex = useCallback(
    (party: LoanPartyInfo, role: ROLE_TYPE, entityIndex: number) => {
      updatePartyInCache(party, role, ({ index }) => index === entityIndex);
    },
    [updatePartyInCache]
  );

  const updatePartyPropertyCache = useCallback(
    (partyId: string, path: string, value: CustomValueType) => {
      dispatch(
        api.util.updateQueryData(
          'getApiV1LoansByLoanIdParties',
          { loanId },
          (draft: GetApiV1LoansByLoanIdPartiesApiResponse) => {
            const indexToUpdate = draft.findIndex(({ id }) => id === partyId);
            if (indexToUpdate !== -1) {
              set(draft[indexToUpdate], path, value);
            }
          }
        ) as unknown as AnyAction
      );
    },
    []
  );

  const updateLoanCache = useCallback(
    (payload: Record<string, CustomValueType>) => {
      dispatch(
        api.util.updateQueryData(
          'getApiV1LoansByLoanId',
          { loanId },
          (draft: GetApiV1LoansByLoanIdApiResponse) => {
            Object.entries(payload).forEach(([path, value]) =>
              set(draft, path, value)
            );
          }
        ) as unknown as AnyAction
      );
    },
    []
  );

  const updateLoanPropertiesCache = useCallback(
    (propertyId: string, payload: Record<string, CustomValueType>) => {
      dispatch(
        api.util.updateQueryData(
          'getApiV1LoansByLoanIdProperties',
          { loanId },
          (draft: GetApiV1LoansByLoanIdPropertiesApiResponse) => {
            const indexToUpdate = draft.findIndex(
              ({ id }) => id === propertyId
            );
            const data = draft[indexToUpdate] ?? {};
            Object.entries(payload).forEach(([path, value]) => {
              set(data, path, value);
            });

            if (indexToUpdate === -1) {
              draft.push({
                ...data,
                id: propertyId,
              });
            }
          }
        ) as unknown as AnyAction
      );
    },
    []
  );

  return {
    updatePartyCacheById,
    updatePartyCacheByIndex,
    updatePartyPropertyCache,
    updateLoanCache,
    updateLoanPropertiesCache,
    deletePartyCacheByIndex,
    deletePartyCacheById,
  };
};
