// DataTableControlPanel.component.tsx
import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Typography, Stack, Button } from '@mui/material';
import {
  DownloadIcon,
  AppliedFilter,
  FilterItem,
  HandleSortChange,
  SorterItem,
} from '@lola/ui-react-components';
import { DataSearch, DataFilter, DataSorter } from './components';

import styles from './dataTableControlPanel.module.scss';

export interface DataControlPanelProps {
  appliedFilters: Record<string, AppliedFilter>;
  filters: Record<string, FilterItem>;
  handleFilterChange: (filterKey: string, value: string[]) => void;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sorters?: SorterItem[];
  currentSorting?: string;
  handleSortChange?: HandleSortChange;
  defaultSearchValue?: string;
  className?: string;
  searchPlaceholder?: string;
  title?: string | false;
  teamManagement?: boolean;
  children?: ReactNode;
}

export const DataControlPanel = ({
  appliedFilters,
  filters,
  sorters = [],
  currentSorting,
  handleSortChange,
  handleFilterChange,
  handleSearchChange,
  defaultSearchValue,
  className,
  searchPlaceholder,
  title,
  teamManagement,
  children,
}: DataControlPanelProps) => {
  const correctedHandleSortChange: HandleSortChange = (option) => {
    if (handleSortChange) {
      handleSortChange(option);
    }
  };

  return (
    <section className={classnames(styles.container, className)}>
      <section className={styles.search}>
        <DataSearch
          handleSearchChange={handleSearchChange}
          defaultSearchValue={defaultSearchValue}
          searchPlaceholder={searchPlaceholder}
        />
      </section>
      <section
        className={classnames({
          [styles.filtersWithLabel]: !!title,
        })}
      >
        {title && (
          <Typography mr={4} className={styles.filterLabel}>
            {title}
          </Typography>
        )}
        <Stack
          direction={{ xs: 'row' }}
          alignItems={'center'}
          spacing={{ xs: 4 }}
        >
          <>
            {Object.keys(filters).map((key) =>
              filters[key]?.renderer ? (
                <div key={key}>{filters[key].renderer?.() ?? null}</div>
              ) : (
                <DataFilter
                  key={key}
                  handleFilterChange={handleFilterChange}
                  filterOptions={filters[key].filterOptions}
                  appliedFilterOptions={appliedFilters[key].values}
                  filterKey={key}
                  filterTitle={filters[key].title ?? ''}
                  optionLabelRenderer={filters[key].optionLabelRenderer}
                />
              )
            )}
            {sorters.map(({ label, options }) => (
              <DataSorter
                key={label}
                label={label}
                options={options}
                currentSorting={currentSorting}
                handleSortChange={correctedHandleSortChange}
              />
            ))}
            {children}
          </>
        </Stack>
      </section>
      {teamManagement && (
        <section className={styles.button}>
          <Button variant="text" startIcon={<DownloadIcon />}>
            Export to XLS
          </Button>
        </section>
      )}
    </section>
  );
};
