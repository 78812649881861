import { useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import {
  buildContactInfoForm,
  updateFields,
} from '@pages/personalLoans/pages/buildMyLoan/pages/application/components/ContactInfo/contactInfo.utils';

import { APPLICATION_TYPE } from '@pages/personalLoans/pages/buildMyLoan/pages/application/Application.constants';
import { LOAN_PROGRAM } from '@typings/common';

const CONTACT_INFO_SECTIONS = [
  'properties[0].propertyType',
  'loan.titleCompany',
  'properties[0].propertyInsuranceAgency',
  'loan.hoa',
];

export const useContactForm = (
  applicationType: APPLICATION_TYPE | null,
  loanProgram: LOAN_PROGRAM
) => {
  const [propertyType, loanTitleCompany, propertyInsuranceAgency, hoa] =
    useWatch({
      name: CONTACT_INFO_SECTIONS,
    });

  const fields = useMemo(() => {
    if (!applicationType || !loanProgram) {
      return [];
    }

    return buildContactInfoForm({
      applicationType,
      loanProgram,
      isCondo: propertyType === 'Condominium',
    });
  }, [applicationType, loanProgram, propertyType]);

  return useMemo(
    () =>
      updateFields(fields, {
        'loan.titleCompany': loanTitleCompany,
        'properties[0].propertyInsuranceAgency': propertyInsuranceAgency,
        'loan.hoa': hoa,
      }),
    [fields, loanTitleCompany, propertyInsuranceAgency, hoa]
  );
};
