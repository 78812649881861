import React from 'react';
import { NonIndexRouteObject, Outlet } from 'react-router-dom';
import { Typography } from '@mui/material';
import { MyLoansPage } from '@pages/myLoans';
import { ROLES, TASK_SECTIONS } from '@typings/common';
import { Dashboard } from '@pages/dashboard/Dashboard.page';
import { TeamManagement, Loans, TeamMembers } from '@pages/teamManagement';

import {
  ApplicationAndEntityInformationPage,
  ApplicationPage,
  ApproveMyLoanLayout,
  BuildMyLoan,
  ChangeLog,
  CloseMyLoanLayout,
  ConstructionBudgetPage,
  ControlPanel,
  CreditReportPage,
  DocumentLockerPage,
  ManageConditions,
  OrderAppraisalPage,
  PersonalLoans,
  ProductPage,
  ReviewStatusPage,
  SchedulingPage,
  InClosingPage,
  FundingPage,
  UploadDocumentsPage,
  DetailsPage,
  FinalPricingAndTermsSheet,
  ReviewAndSubmitPage,
  ApproveAndGetClearToClosePage,
} from '@pages/personalLoans';

import {
  AuthLayout,
  ForgotPasswordConfirmPage,
  ForgotPasswordPage,
  LoginPage,
} from '@pages/auth';

import { RequireAuth } from '@containers/RequireAuth/RequireAuth.component';

import { BuildBorrower } from '@pages/createLoan/CreateLoan.page';
import { ProfilePage } from '@pages/profile/Profile.page';
import { QuoteFormPage, QuotesPage } from '@pages/scenarioBuilder/pages';
import { ResetPasswordPage } from '@pages/auth/pages/resetPassword/ResetPassword.page';
import { LicensingInfoPage } from '@pages/profile/pages/licensingInfo/LicensingInfo.page';
import { PersonalInfoPage } from '@pages/profile/pages/personalInfo/PersonalInfo.page';

export interface RouterWithRoles extends NonIndexRouteObject {
  roles?: ROLES[];
  children?: RouterWithRoles[];
}

const ALL_AUTH_ROLES = [
  ROLES.BROKER,
  ROLES.KEY_BROKER_MANAGER,
  ROLES.BROKER_MANAGER,
  ROLES.PROCESSOR,
];

export const routerConfig = [
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <LoginPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'forgot-email-confirmation',
        element: <ForgotPasswordConfirmPage />,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPage />,
      },
    ],
  },
  {
    element: <RequireAuth />,
    children: [
      {
        path: '/',
        roles: ALL_AUTH_ROLES,
        element: <Dashboard />,
      },
      {
        path: '/team-management',
        roles: [
          ROLES.KEY_BROKER_MANAGER,
          ROLES.BROKER_MANAGER,
          ROLES.PROCESSOR,
        ],
        element: <TeamManagement />,
        children: [
          {
            path: 'members',
            element: <TeamMembers />,
          },
          {
            path: 'loans',
            element: <Loans />,
          },
        ],
      },
      {
        path: '/personal-loans',
        roles: ALL_AUTH_ROLES,
        children: [
          {
            path: '',
            element: <MyLoansPage />,
          },
          {
            path: ':loanId',
            element: <PersonalLoans />,
            children: [
              {
                path: '',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <ControlPanel />,
                  },
                  {
                    path: 'build-my-loan',
                    element: <BuildMyLoan />,
                    children: [
                      {
                        path: '',
                        element: <ApplicationAndEntityInformationPage />,
                      },
                      {
                        path: 'upload-documents-borrower',
                        element: (
                          <UploadDocumentsPage
                            title="Upload documents"
                            section={TASK_SECTIONS.BORROWER_UPLOAD_DOCUMENTS}
                            description="Below is a list of the documents you need to attach to your application."
                          />
                        ),
                      },
                      {
                        path: 'credit-report',
                        element: <CreditReportPage />,
                      },
                      {
                        path: 'product',
                        element: <ProductPage />,
                      },
                      {
                        path: 'application',
                        element: <ApplicationPage />,
                      },
                      {
                        path: 'upload-documents',
                        element: (
                          <UploadDocumentsPage
                            title="Upload documents"
                            section={TASK_SECTIONS.UPLOAD_DOCUMENTS}
                            description="Below is a list of the documents you need to attach to your application. This list is based on the product you have chosen earlier"
                          />
                        ),
                      },
                      {
                        path: 'construction-budget',
                        element: <ConstructionBudgetPage />,
                      },
                      {
                        path: 'order-appraisal',
                        element: <OrderAppraisalPage />,
                      },
                      {
                        path: 'review-&-submit-to-feasibility',
                        element: <ReviewAndSubmitPage />,
                      },
                    ],
                  },
                  {
                    path: 'approve-my-loan',
                    element: <ApproveMyLoanLayout />,
                    children: [
                      {
                        path: 'review-status',
                        element: <ReviewStatusPage />,
                      },
                      {
                        path: 'manage-conditions',
                        element: <ManageConditions />,
                      },
                      {
                        path: 'final-pricing-and-terms-sheet',
                        element: <FinalPricingAndTermsSheet />,
                      },
                      {
                        path: 'final-deal-approval',
                        element: <FinalPricingAndTermsSheet />,
                      },
                      {
                        path: 'approve-and-get-clear-to-close',
                        element: <ApproveAndGetClearToClosePage />,
                      },
                    ],
                  },
                  {
                    path: 'close-my-loan',
                    element: <CloseMyLoanLayout />,
                    children: [
                      {
                        path: 'scheduling',
                        element: <SchedulingPage />,
                      },
                      {
                        path: 'in-closing',
                        element: <InClosingPage />,
                      },
                      {
                        path: 'funding',
                        element: <FundingPage />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'document-locker',
                element: <DocumentLockerPage />,
              },
              {
                path: 'changelog',
                element: <ChangeLog />,
              },
              {
                path: 'details',
                element: <DetailsPage />,
              },
            ],
          },
        ],
      },
      {
        path: '/scenario-builder',
        roles: ALL_AUTH_ROLES,
        children: [
          {
            path: '',
            element: <QuotesPage />,
          },
          {
            path: 'quote',
            children: [
              {
                path: '',
                element: <QuoteFormPage />,
              },
              {
                path: ':quoteType/:quoteId',
                element: <QuoteFormPage />,
              },
            ],
          },
        ],
      },
      {
        path: '/new-loan',
        roles: ALL_AUTH_ROLES,
        element: <BuildBorrower />,
      },
      {
        path: '/profile',
        roles: ALL_AUTH_ROLES,
        element: <ProfilePage />,
        children: [
          {
            path: 'personal-info',
            element: <PersonalInfoPage />,
          },
          {
            path: 'licensing-info',
            element: <LicensingInfoPage />,
          },
        ],
      },
      {
        path: '*',
        element: <Typography variant="h1">Not found</Typography>,
      },
    ],
  },
];
