import React, { useCallback, useRef, useEffect } from 'react';
//MUI
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Paper,
  Skeleton,
  Stack,
  CircularProgress,
  Typography,
} from '@mui/material';
//Router
import { useParams, useNavigate } from 'react-router-dom';
//API
import {
  usePostApiV1ApprovemyloanAcceptFinalPricingAndTermsSheetMutation,
  useGetApiV1ApprovemyloanIsPricingAndTermsAcceptedQuery,
  LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse,
  LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteByIdResponse,
} from '@api/output/api';
// Global components
import { Collapsed } from '@components/Collapsed';
import { Modal } from '@components/Modal';
import { NoContent } from '@components/NoContent';
// Global types
import { QUOTE_TYPE } from '@typings/common';
// Utils
import { selectScenario } from '@utils/defaultScenarioBuilderCalculation.utils';
//Constants
import { SHORT_TERM_CALCULATION_SECTIONS_CONFIGS } from '@constants/shortTermCalculationsConfigs.constants';
import { DefaultScenarioBuilderCalculation } from '@components/QuoteCalculation/components/DefaultScenarioBuilderCalculation/DefaultScenarioBuilderCalculation.component';
import { RENTAL_TERM_CALCULATION_SECTIONS_CONFIGS } from './FinalPricingAndTermsSheet.constants';
// hook
import { useFinalPricingAndTermsSheet } from './hook/useFinalPricingAndTermsSheet';
//Components
import { PricingCalculation } from './components';
//Styles
import styles from './finalPricingAndTermsSheet.module.scss';

export const FinalPricingAndTermsSheet = () => {
  // router
  const { loanId = '' } = useParams();
  const navigate = useNavigate();

  // Ref Elements
  const summaryBlockRef = useRef<HTMLDivElement>(null);

  // API
  const [
    handledAcceptFinalPricingAndTermsSheet,
    { isLoading: isLoadingAccept, isError: isErrorAccept, isSuccess },
  ] = usePostApiV1ApprovemyloanAcceptFinalPricingAndTermsSheetMutation();

  const { data: isPricingAccepted, isLoading: isButtonLoadAccepted } =
    useGetApiV1ApprovemyloanIsPricingAndTermsAcceptedQuery({ loanId });

  // actions
  const handleAccept = useCallback(() => {
    handledAcceptFinalPricingAndTermsSheet({ loanId });
  }, []);

  const handledScrollView = useCallback(() => {
    if (!summaryBlockRef) return;
    summaryBlockRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [summaryBlockRef]);

  // hook
  const {
    termSheet,
    isLoading,
    isCollapse,
    quoteType,
    isQuoteId,
    isCalculating,
    isModalOpen,
    openPricing,
    handledCollapse,
    save,
    handledOpenModal,
  } = useFinalPricingAndTermsSheet({
    scrollView: handledScrollView,
  });

  useEffect(() => {
    if (!isSuccess) return;

    handledOpenModal();
    navigate(
      `/personal-loans/${loanId}/approve-my-loan/approve-and-get-clear-to-close`
    );
  }, [isSuccess]);

  if (isLoading || isButtonLoadAccepted) {
    return (
      <Paper
        data-testid="skeleton"
        elevation={2}
        className={styles.manageCondition}
      >
        <Skeleton variant="text" className={styles.skeletonTitle} />
        <Skeleton className={styles.skeletonBody} variant="text" />
        <Skeleton variant="rounded" className={styles.skeletonFooter} />
      </Paper>
    );
  }

  return (
    <Grid container spacing={2} ref={summaryBlockRef}>
      <Grid item xs={12}>
        <Paper elevation={2}>
          <Collapsed
            label="Final Pricing & Term Sheet"
            typographyVariant="h2"
            expanded={isCollapse.term}
            onChange={() => handledCollapse({ key: 'term' })}
          >
            {!termSheet && (
              <NoContent
                title="Not Final Pricing & Term Sheet data."
                text="Please, try to get data again."
              />
            )}
            {termSheet &&
              (quoteType === QUOTE_TYPE.SHORT_TERM ? (
                <DefaultScenarioBuilderCalculation
                  details={
                    termSheet as LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteByIdResponse
                  }
                  configs={SHORT_TERM_CALCULATION_SECTIONS_CONFIGS}
                />
              ) : (
                <DefaultScenarioBuilderCalculation
                  details={selectScenario(
                    termSheet as LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse
                  )}
                  configs={RENTAL_TERM_CALCULATION_SECTIONS_CONFIGS}
                  disabled
                />
              ))}
            <Stack
              flexDirection="row"
              alignItems="center"
              marginTop="32px"
              gap={2}
            >
              <Button
                variant="contained"
                disabled={Boolean(isPricingAccepted ?? !termSheet)}
                onClick={handledOpenModal}
                size="large"
              >
                Accept
              </Button>

              <Button
                disabled={Boolean(isPricingAccepted ?? !termSheet)}
                variant="outlined"
                size="large"
                onClick={openPricing}
              >
                Recalculate
              </Button>
            </Stack>
            {isErrorAccept && (
              <Alert severity="error" className={styles.alert}>
                <AlertTitle>
                  Something went wrong during the acceptance of the terms sheet.
                </AlertTitle>
                Please check the loan details and try again.
              </Alert>
            )}
          </Collapsed>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={2}>
          <Collapsed
            label="Pricing calculation"
            expanded={isCollapse.pricing}
            disabled={!isCollapse.pricing}
          >
            <PricingCalculation
              quoteType={quoteType}
              quoteId={isQuoteId}
              save={save}
              data={termSheet}
              isCalculating={isCalculating}
            />
          </Collapsed>
        </Paper>
      </Grid>

      <Modal isOpen={isModalOpen} title="Attention!" onClose={handledOpenModal}>
        <Typography variant="p">
          You are going to approve Final Pricing & Term Sheet.{' '}
        </Typography>
        <section className={styles.modal}>
          <Button variant="text" onClick={handledOpenModal}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleAccept}>
            {isLoadingAccept ? (
              <span className={styles.loaderCircularWrapper}>
                <CircularProgress
                  variant="indeterminate"
                  color="inherit"
                  className={styles.loaderCircular}
                  size={52}
                />
              </span>
            ) : (
              'Approve'
            )}
          </Button>
        </section>
      </Modal>
    </Grid>
  );
};
