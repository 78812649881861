import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RouteObject } from 'react-router';
import { useRolesRoutes } from './hooks/useRolesRoutes';

export const Router = () => {
  const routes = useRolesRoutes();
  const router = createBrowserRouter(routes as unknown as RouteObject[]);

  return <RouterProvider router={router} />;
};
