import React, { useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Alert, AlertTitle, LinearProgress, TextField } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePostApiV1AuthResetPasswordConfirmMutation } from '@api/output/api';
import { auth } from '@config/auth';
import { useOpenToast } from '@hooks/useOpenToast';
import { ContentFormSection } from '../../components/ContentFormSection';
import { passwordFormSchema } from './passwordForm.schema';

export interface ForgotPasswordFormInputs {
  newPassword: string;
}

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const openToast = useOpenToast();
  const [params] = useSearchParams();

  const [confirmResetPassword, { isSuccess, isError, isLoading }] =
    usePostApiV1AuthResetPasswordConfirmMutation();

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<ForgotPasswordFormInputs>({
    defaultValues: {
      newPassword: '',
    },
    resolver: passwordFormSchema,
  });

  const onSubmit = useMemo(() => {
    return handleSubmit(({ newPassword }) => {
      confirmResetPassword({
        lolaBffApiContractsModelsAuthAuthResetPasswordConfirmRequest: {
          newPassword,
          token: params.get('token') ?? '',
          userId: params.get('userId') ?? '',
          domain: auth.domain,
        },
      });
    });
  }, [handleSubmit]);

  useEffect(() => {
    if (isSuccess) {
      openToast({
        title: 'Password has been changed',
        description: 'Now you can use your username and password for login.',
      });
      navigate('/auth/login');
    }
  }, [isSuccess]);

  if (isLoading) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  return (
    <ContentFormSection
      title="Reset password"
      subtitle=""
      primaryButtonText="Reset password"
      backLinkProps={{
        to: '/auth',
        label: 'Back to login',
      }}
      primaryButtonDisabled={!isDirty}
      onSubmit={onSubmit}
    >
      <Alert severity="warning">
        <AlertTitle>Password requirements:</AlertTitle>
        - Minimum of 8 characters <br />
        - At least one letter <br />
        - At least one number <br />
        - At least one special character <br />
      </Alert>
      <Controller
        name="newPassword"
        control={control}
        render={({ field }) => {
          const error = isError
            ? 'Unexpected error, please try again later'
            : errors.newPassword?.message;

          return (
            <TextField
              {...field}
              error={!!error}
              helperText={error}
              variant="filled"
              label="New password"
            />
          );
        }}
      />
    </ContentFormSection>
  );
};
