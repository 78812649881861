import React from 'react';
import { Paper } from '@mui/material';
import { TableColumnType } from 'antd';
import { DataTable } from '@lola/ui-react-components';
import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';
import styles from './loansList.module.scss';

export interface LoansListProps {
  columns: TableColumnType<LolaBffApiContractsModelsLoanLoanView>[];
  data?: LolaBffApiContractsModelsLoanLoanView[];
  onLoanSelect?: (row: any[]) => void;
}

export const LoansList = ({ columns, data, onLoanSelect }: LoansListProps) => {
  return (
    <Paper elevation={0} classes={{ root: styles.paper }}>
      <DataTable
        rowSelection={{
          type: 'checkbox',
        }}
        rowKey="id"
        scroll={{
          x: data?.length ? 1300 : undefined,
          y: 600,
        }}
        columns={columns}
        dataSource={data}
        mode="wide"
        onSelectedChange={onLoanSelect}
      />
    </Paper>
  );
};
