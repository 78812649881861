import React, { useMemo } from 'react';
import { IconButton, MenuItem, Stack } from '@mui/material';
import {
  AddToTeamIcon,
  ArchiveIcon,
  ChangeIcon,
  MoreVerticalIcon,
  RemoveFromTeamIcon,
} from '@lola/ui-react-components';
import { Menu } from '@components/Menu';
import { LolaBffApiContractsModelsUser } from '@api/output/api';
import { SetModalContent, USER_STATUS } from '@typings/common';
import { MODAL_STATE, ModalContentType } from '../../TeamMembers.constants';
import { useChangePermission } from './hooks/useChangePermission';
import { useAddPermission } from './hooks/useAddPermission';
import { useRemovePermission } from './hooks/useRemovePermission';
import styles from './actionsMenu.module.scss';

export interface ActionsMenuProps {
  setModalContent: SetModalContent<ModalContentType>;
  context: LolaBffApiContractsModelsUser;
}

export const ActionsMenu = ({ setModalContent, context }: ActionsMenuProps) => {
  const { role, status, managesTeams, teamId } = context;

  const statusButton = useMemo(() => {
    const isArchived = status === USER_STATUS.ARCHIVED;

    return isArchived ? (
      <MenuItem onClick={() => alert('Active')}>
        <ArchiveIcon /> Activate member
      </MenuItem>
    ) : (
      <MenuItem onClick={() => alert('Archive')}>
        <ArchiveIcon /> Archive member
      </MenuItem>
    );
  }, [status]);

  const hasTeam = (managesTeams && managesTeams.length > 0) || !!teamId;

  const hasChangePermission = useChangePermission(role, status);

  const hasAddPermission = useAddPermission(role, status, hasTeam);

  const hasRemovePermission = useRemovePermission(role, status);

  const openModal = (modalState: MODAL_STATE) => {
    setModalContent({
      state: modalState,
      context,
    });
  };

  return (
    <Stack direction="row" justifyContent="center">
      <Menu
        trigger={
          <IconButton>
            <MoreVerticalIcon />
          </IconButton>
        }
      >
        <div className={styles.menu}>
          {statusButton}
          {hasTeam && hasChangePermission && (
            <MenuItem onClick={() => openModal(MODAL_STATE.CHANGE)}>
              <ChangeIcon /> Change team
            </MenuItem>
          )}
          {hasAddPermission && (
            <MenuItem onClick={() => openModal(MODAL_STATE.ASSIGN)}>
              <AddToTeamIcon /> Add to a team
            </MenuItem>
          )}
          {hasTeam && hasRemovePermission && (
            <MenuItem onClick={() => openModal(MODAL_STATE.REMOVE)}>
              <RemoveFromTeamIcon /> Remove from a team
            </MenuItem>
          )}
        </div>
      </Menu>
    </Stack>
  );
};
