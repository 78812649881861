import cloneDeep from 'lodash/cloneDeep';
import cloneDeepWith from 'lodash/cloneDeepWith';
import { forms } from '@form-configs/output';
import { APPLICATION_TYPE } from '@pages/personalLoans/pages/buildMyLoan/pages/application/Application.constants';
import { replaceFieldByKey } from '@pages/personalLoans/pages/buildMyLoan/utils/fields.utils';
import { FieldConfig } from '@form-configs/types';
import { LOAN_PROGRAM } from '@typings/common';

const RENTAL_PROGRAMS = [
  LOAN_PROGRAM.RENTAL_30,
  LOAN_PROGRAM.RENTAL_30_PREMIERE,
];

interface BuildContactInfoFormArg {
  applicationType: APPLICATION_TYPE;
  loanProgram: LOAN_PROGRAM;
  isCondo: boolean;
}

export const buildContactInfoForm = ({
  applicationType,
  loanProgram,
  isCondo,
}: BuildContactInfoFormArg) => {
  const contactInfo = isCondo
    ? forms.contactInfo
    : forms?.contactInfo?.[0]?.items?.filter(
        ({ props }) => !props?.label?.includes('for condos')
      );

  if (
    applicationType === APPLICATION_TYPE.SINGLE &&
    RENTAL_PROGRAMS.includes(loanProgram)
  ) {
    const formString = JSON.stringify(contactInfo).replace(
      'Title company contact information',
      'Title insurance contact information'
    );
    return JSON.parse(formString);
  }

  if (applicationType === APPLICATION_TYPE.PORTFOLIO) {
    const formClone = cloneDeep(contactInfo) ?? [];
    const newField = forms.propertyAccess[0];

    replaceFieldByKey(
      formClone,
      'props.label',
      'Property access contact information',
      newField
    );

    return formClone;
  }

  return contactInfo;
};

const CONTACT_INFO_FIELDS = [
  'representativeFullName',
  'contactPointTelephoneValue',
  'contactPointEmailValue',
  'HOAContactName',
  'HOAContactPhone',
  'HOAContactEmail',
];

/* We can use only any here, because the final structure of the object is not known */
export const updateFields = (
  form: FieldConfig[],
  sections: Record<string, any>
) => {
  return cloneDeepWith(form, (item) => {
    Object.entries(sections).forEach(([name = '', section = {}]) => {
      if (item?.props?.name?.includes(name) && section) {
        const hasDependantFieldChanged =
          Object.entries(section).filter(
            ([field, value]) => !!value && CONTACT_INFO_FIELDS.includes(field)
          ).length !== 0;
        item.props.validation = hasDependantFieldChanged
          ? [
              {
                rule: 'required',
              },
            ]
          : [];
      }
    });
  });
};
