import { TEAM_ASSIGNMENT_STATUS } from '@typings/common';
import { useGetApiV1UsersMeQuery } from '@api/output/api';
import { isUserAssignedToTeam } from '@utils/isUserAssignedToTeam.utils';

export const useUserAssignmentStatus = () => {
  const { data, isError } = useGetApiV1UsersMeQuery();

  if (isError || !data) return;

  return isUserAssignedToTeam(data?.managesTeams, data?.teamId)
    ? TEAM_ASSIGNMENT_STATUS.ASSIGNED
    : TEAM_ASSIGNMENT_STATUS.UNASSIGNED;
};
