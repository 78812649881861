import React from 'react';
import { LolaBffApiContractsModelsUser } from '@api/output/api';
import { SetModalContent } from '@typings/common';
import { MODAL_STATE, ModalContentType } from '../../TeamMembers.constants';
import {
  CreateTeam,
  ChangeTeam,
  AssignToTeam,
  RemoveFromTeam,
} from './components';

interface ModalContentProps {
  content: {
    state: MODAL_STATE;
    context?: LolaBffApiContractsModelsUser;
  };
  setModalContent: SetModalContent<ModalContentType>;
}

export const ModalContent = ({
  content,
  setModalContent,
}: ModalContentProps) => {
  switch (content.state) {
    case MODAL_STATE.CREATE: {
      return <CreateTeam setModalContent={setModalContent} />;
    }

    case MODAL_STATE.CHANGE: {
      const { context = {} } = content;
      return <ChangeTeam setModalContent={setModalContent} context={context} />;
    }

    case MODAL_STATE.ASSIGN: {
      const { context = {} } = content;
      return (
        <AssignToTeam setModalContent={setModalContent} context={context} />
      );
    }

    case MODAL_STATE.REMOVE: {
      const { context = {} } = content;
      return (
        <RemoveFromTeam setModalContent={setModalContent} context={context} />
      );
    }

    default:
      return null;
  }
};
