import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import get from 'lodash/get';
import { useGetApiV1LoansByLoanIdQuery } from '@api/output/api';
import { LOAN_PROGRAM, QUOTE_TYPE } from '@typings/common';
import {
  PROGRAM_PATH,
  QUOTE_ID_AGGREGATION_PATH,
} from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { checkRental } from '@pages/personalLoans/pages/buildMyLoan/pages/product/Product.utils';

export const useQuoteIdAndTypeFromApi = () => {
  const { loanId = '' } = useParams();
  const { data } = useGetApiV1LoansByLoanIdQuery({ loanId });

  return useMemo(() => {
    if (!data) {
      return {
        quoteId: '',
        quoteType: QUOTE_TYPE.SHORT_TERM,
      };
    }

    const loanProgramType = get(data, PROGRAM_PATH) as LOAN_PROGRAM;

    const quoteType = checkRental(loanProgramType)
      ? QUOTE_TYPE.RENTAL
      : QUOTE_TYPE.SHORT_TERM;

    return {
      quoteId: get(data, QUOTE_ID_AGGREGATION_PATH) ?? '',
      quoteType,
    };
  }, [data]);
};
