import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { ListContext } from '@components/DynamicForm/contexts/List.context';
import { DynamicTextField } from '@components/DynamicForm/components/DynamicTextField/DynamicTextField.component';
import { DynamicDropdown } from '@components/DynamicForm/components/DynamicDropdown/DynamicDropdown.component';
import { QUOTE_TYPE } from '@typings/common';
import {
  FEE_VALUE_TYPE,
  feesTypeOptionsForRental,
  feesTypeOptionsForShortTerm,
} from '../PartnerEconomics.constants';
import { FeesFormItem } from '../PartnerEconomics.types';

export const FeesFormBlock = () => {
  const index = useContext(ListContext);
  const { setValue } = useFormContext();
  const [fees, quoteType]: [FeesFormItem[], QUOTE_TYPE | undefined] = useWatch({
    name: ['fees', 'quoteType'],
  });

  const isFirstRender = useRef(true);

  const selectedType = fees?.[index]?.feeType;

  const availableOptions = useMemo(() => {
    if (!Array.isArray(fees)) {
      return [];
    }

    const feesFullOptions =
      quoteType === QUOTE_TYPE.RENTAL
        ? feesTypeOptionsForRental
        : feesTypeOptionsForShortTerm;

    return feesFullOptions.filter(
      (option) =>
        !fees.find(
          (item, itemIndex) =>
            item && item.feeType === option.value && itemIndex !== index
        )
    );
  }, [quoteType, fees, index]);

  useEffect(() => {
    if (quoteType && isFirstRender.current) {
      const isValidOption = availableOptions.find(
        (option) => option.value === selectedType
      );

      if (isValidOption) {
        setValue(`fees[${index}].feeType`, selectedType);
      } else if (availableOptions.length > 0) {
        setValue(`fees[${index}].feeType`, availableOptions[0].value);
      } else {
        setValue(`fees[${index}].feeType`, '');
      }

      isFirstRender.current = false;
    }
  }, [availableOptions, setValue, index, selectedType, quoteType]);

  const selectedValueType = useMemo(() => {
    const selectedOption = availableOptions.find(
      ({ value }) => value === selectedType
    );
    return selectedOption?.valueType;
  }, [selectedType, availableOptions]);

  const renderFeeValue = useMemo(() => {
    switch (selectedValueType) {
      case FEE_VALUE_TYPE.PERCENT:
        return (
          <DynamicTextField
            type="text"
            mask="positive"
            label="Fee amount, %*"
            validation={[
              {
                rule: 'max',
                args: ['100', 'Fee amount must be less than or equal to 100'],
              },
            ]}
            name={`fees[${index}].feeValue`}
          />
        );
      default:
        return (
          <DynamicTextField
            type="text"
            mask="positive"
            label="Fee amount*"
            validation={[
              {
                rule: 'max',
                args: ['2500', 'Fee amount must be less than or equal to 2500'],
              },
            ]}
            name={`fees[${index}].feeValue`}
          />
        );
    }
  }, [index, selectedValueType]);

  if (!quoteType) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} data-cy="fee-type-input">
        <DynamicDropdown
          label="Fee Type**"
          name={`fees[${index}].feeType`}
          options={availableOptions}
        />
      </Grid>
      {selectedType && (
        <Grid item xs={6}>
          {renderFeeValue}
        </Grid>
      )}
    </Grid>
  );
};
