import { useMemo } from 'react';
import { ProgressStepItemStatus } from '@components/ProgressStepper/types';
import { QUOTE_TYPE } from '@typings/common';
import { useStepAppAndEntity } from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps';
import {
  rentalFormFieldsProps,
  shortTermFormFieldsProps,
} from '../productPage.constants';

export const useFieldsProps = (quoteType: QUOTE_TYPE) => {
  const appStep = useStepAppAndEntity(false);

  return useMemo(() => {
    if (appStep.status !== ProgressStepItemStatus.DONE) {
      return {};
    }

    return quoteType === QUOTE_TYPE.SHORT_TERM
      ? shortTermFormFieldsProps
      : rentalFormFieldsProps;
  }, [appStep, quoteType]);
};
