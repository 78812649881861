import React from 'react';
//Libraries
import { Paper } from '@mui/material';
import { TableColumnType, TablePaginationConfig } from 'antd';
//components
import {
  DataTable,
  RowRecord,
  createDateSorter,
  createDefaultSorter,
  dateRenderer,
} from '@lola/ui-react-components';
//Types
import { LolaBffApiContractsModelsLoanInactiveLoan } from '@api/output/api';
import { InactiveLoansSortingTypes } from '../../myLoansInactiveLoans.type';
//Renders
import {
  loanActionRenderer,
  loanDataRenderer,
  loanProcessorRenderer,
  loanStatusRenderer,
} from '../../renders';
//Styles
import styles from './inactiveLoans.module.scss';

const columns: TableColumnType<LolaBffApiContractsModelsLoanInactiveLoan>[] = [
  {
    dataIndex: 'name',
    key: 'name',
    render: loanDataRenderer,
    sorter: createDefaultSorter<InactiveLoansSortingTypes>('name'),
    title: 'Loan name',
    width: '25%',
  },
  {
    dataIndex: 'status',
    key: 'status',
    render: loanStatusRenderer,
    sorter: createDefaultSorter<InactiveLoansSortingTypes>('status'),
    title: 'Status',
    width: '15%',
  },
  {
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: dateRenderer,
    sorter: createDateSorter('createdAt'),
    title: 'Created',
    width: '15%',
  },
  {
    dataIndex: 'lastUpdateAt',
    key: 'lastUpdateAt',
    render: dateRenderer,
    sorter: createDateSorter('lastUpdateAt'),
    title: 'Updated',
    width: '15%',
  },
  {
    dataIndex: 'processor',
    key: 'processor',
    render: loanProcessorRenderer,
    sorter: createDefaultSorter<InactiveLoansSortingTypes>(
      'processors[0].fullName'
    ),
    title: 'Processor',
  },
  {
    key: 'actions',
    title: 'Actions',
    render: loanActionRenderer,
    align: 'center',
  },
];

export interface InactiveLoansProps {
  dataSource: LolaBffApiContractsModelsLoanInactiveLoan[];
  onRowClick: (record: RowRecord) => void;
  pagination: TablePaginationConfig;
}

export const InactiveLoans = ({
  dataSource,
  onRowClick,
  pagination,
}: InactiveLoansProps) => {
  return (
    <section className={styles.inactiveLoansContainer}>
      <Paper elevation={0} classes={{ root: styles.paper }}>
        <DataTable
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          onRowClick={onRowClick}
          pagination={pagination}
        />
      </Paper>
    </section>
  );
};
