import React, { useCallback, useEffect, useState } from 'react';
import { Quote, QUOTE_ACCORDION_VALUES } from '@typings/common';

export type UseProductAccordionResult = [
  QUOTE_ACCORDION_VALUES | null,
  React.Dispatch<React.SetStateAction<QUOTE_ACCORDION_VALUES | null>>,
  (
    panel: QUOTE_ACCORDION_VALUES
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void
];

export const useProductAccordion = (
  data?: Quote
): UseProductAccordionResult => {
  const [accordionValue, setAccordionValue] =
    useState<QUOTE_ACCORDION_VALUES | null>(QUOTE_ACCORDION_VALUES.DETAILS);

  const accordionHandler = useCallback(
    (panel: QUOTE_ACCORDION_VALUES) =>
      (event: React.SyntheticEvent, isExpanded: boolean) => {
        setAccordionValue(isExpanded ? panel : null);
      },
    [setAccordionValue]
  );

  useEffect(() => {
    if (data) {
      if (data.out?.validationErrors === '') {
        setAccordionValue(QUOTE_ACCORDION_VALUES.CALCULATION);
      } else {
        document.getElementById('calculation-errors')?.scrollIntoView();
      }
    }
  }, [data]);

  return [accordionValue, setAccordionValue, accordionHandler];
};
