import { PositionProps } from '@components/Menu/Menu.types';

export const DEFAULT_POSITION_PROPS: PositionProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};
