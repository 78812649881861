export const THEME_ID = 'provider-theme-favicon';

export const updateFavicon = (favicon: string) => {
  let faviconElement = document.getElementById(THEME_ID) as HTMLLinkElement;
  if (!faviconElement) {
    faviconElement = document.createElement('link');
    faviconElement.rel = 'icon';
    faviconElement.id = THEME_ID;
    document.head.appendChild(faviconElement);
  }

  faviconElement.href = favicon;
};
