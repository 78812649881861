import React from 'react';
//MUI
import { Typography } from '@mui/material';
//types
import { LolaBffApiContractsModelsLoanInactiveLoan } from '@api/output/api';

export const loanProcessorRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsLoanInactiveLoan
) => {
  const { processors } = row;

  return (
    <Typography paragraph={true} variant="p" mb={0.5}>
      {processors?.[0]?.fullName}
    </Typography>
  );
};
