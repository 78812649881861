import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';
import { LolaBffApiContractsModelsLoanRequestArchiveLoanRequest } from '@api/output/api';
import { Modal } from '@components/Modal';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { archiveReasons } from './ArchiveLoanModal.constants';
import styles from './archiveLoanModal.module.scss';

export interface ArchiveLoanModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  handleArchive: (
    loanId: string,
    lolaReason: LolaBffApiContractsModelsLoanRequestArchiveLoanRequest
  ) => void;
}

export const ArchiveLoanModal = ({
  isModalOpen,
  onClose,
  handleArchive,
}: ArchiveLoanModalProps) => {
  const methods = useForm({
    defaultValues: {
      archiveReason: '',
    },
  });

  const onFormSubmit = useMemo(() => {
    return methods.handleSubmit((values) => {
      handleArchive(loanId, { lolaReason: values.archiveReason });
    });
  }, [methods.handleSubmit]);

  const { loanId = '' } = useParams();

  return (
    <Modal
      title="Archive a loan"
      isOpen={isModalOpen}
      onClose={onClose}
      width={636}
    >
      <Stack spacing={2}>
        <Typography variant="p">
          {'Please indicate the reason for archiving the loan'}
        </Typography>
        <Typography variant="p">
          {
            'Once you archive this loan, you cannot make any changes. To unarchive this loan, you will need to request approval.'
          }
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={onFormSubmit}>
            <SelectControl
              name="archiveReason"
              label="Reason for archiving the loan"
              options={archiveReasons}
              variant="filled"
            />
            <div className={styles.modalFooter}>
              <Button variant="text" type="button" onClick={onClose}>
                Close
              </Button>
              <Button variant="contained" type="submit">
                Archive loan
              </Button>
            </div>
          </form>
        </FormProvider>
      </Stack>
    </Modal>
  );
};
