import React, { PropsWithChildren } from 'react';
import { Typography, ChipProps } from '@mui/material';
import { Breadcrumbs, BreadcrumbConfig } from '@lola/ui-react-components';
import { ChipList } from '@components/ChipList/ChipList.component';

import styles from './subpageHeader.module.scss';

export interface SubpageHeaderProps {
  title: string;
  titleChip?: JSX.Element | null;
  breadcrumbs?: BreadcrumbConfig[];
  chips?: ChipProps[];
}

export const SubpageHeaderComponent = ({
  title,
  titleChip,
  breadcrumbs,
  chips,
  children,
}: PropsWithChildren<SubpageHeaderProps>) => {
  return (
    <header className={styles.header}>
      {breadcrumbs && (
        <div className={styles.headerBreadcrumbs}>
          <Breadcrumbs crumbs={breadcrumbs} />{' '}
        </div>
      )}
      <Typography variant="h1" className={styles.title}>
        {title}
        {titleChip}
      </Typography>
      <section className={styles.actions}>{children}</section>
      {chips && (
        <div className={styles.headerChips}>
          <ChipList chips={chips} />
        </div>
      )}
    </header>
  );
};
