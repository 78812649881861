import dayjs from 'dayjs';

export interface StartEndPeriod {
  start: string;
  end: string;
}

export const getLast7yearsObject: () => StartEndPeriod = () => {
  return {
    start: dayjs().subtract(7, 'years').format('YYYY-MM-DD'),
    end: dayjs().format('YYYY-MM-DD'),
  };
};

export const isDateBetween = (
  date: Date,
  startDate: Date,
  endDate: Date
): boolean => {
  return (
    date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()
  );
};

export const isValidDateFormat = (date = '') => {
  const format = /^\d{4}-\d{2}-\d{2}$/;
  return format.test(date);
};
