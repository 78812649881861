import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { addToastWithAutodelete, ToastType } from '@store/slices/toast.slice';

interface ResponseHandler {
  title: string;
  description?: string;
  isError?: boolean;
}

export const useOpenToast = (disappearTimeout?: number) => {
  const dispatch = useDispatch();
  return useCallback(
    ({ title, description = '', isError = false }: ResponseHandler) => {
      dispatch(
        addToastWithAutodelete({
          type: isError ? ToastType.Error : ToastType.Success,
          title,
          description,
          disappearTimeout,
        }) as unknown as AnyAction
      );
    },
    [dispatch]
  );
};
