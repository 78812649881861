import React, { useContext, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { AddIcon, DeleteIcon } from '@lola/ui-react-components';
import { FieldConfig, ListProps } from '@form-configs/types';
import {
  convertFieldsToIndexedFields,
  getTitle,
} from '@components/DynamicForm/components/DynamicList/utils/dataUtils';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { LabelWithAction } from '@components/LabeledCard/components/LabelWithAction/LabelWithAction.component';
import { FormConfigContext } from '@components/DynamicForm/contexts/FormConfig.context';
import { ListContext } from '@components/DynamicForm';
import { DynamicItems } from '../DynamicItems/DynamicItems.component';
import { useDynamicListControls } from './hooks/useDynamicListControls';

import styles from './dynamicList.module.scss';

export interface DynamicListProps extends ListProps {
  items: FieldConfig[];
  regularButton?: boolean;
  addButtonDescription?: string[];
  maxItems?: number;
}

export const DynamicList = ({
  addFirstButtonText,
  addButtonText,
  firstTitle,
  firstIsRequired,
  autoTitlePattern,
  name,
  items,
  regularButton,
  startFrom = 0,
  maxItems = Infinity,
  addButtonDescription,
  withSpacing = true,
  withDivider = false,
}: DynamicListProps) => {
  const { watch } = useFormContext();
  const { disabled } = useContext(FormConfigContext);

  const arr = watch(name) || [];
  const { addItem, removeItem } = useDynamicListControls(name);

  useEffect(() => {
    if (arr.length === 0 && firstIsRequired) {
      addItem();
    }
  }, [arr, firstIsRequired]);

  const buttonText = useMemo(() => {
    if (arr.length === 0 && addFirstButtonText) {
      return addFirstButtonText;
    }

    return addButtonText;
  }, [arr.length, addFirstButtonText, addButtonText]);

  const buttonDescriptionClasses = classnames(styles.buttonDescription, {
    [styles.disabled]: disabled,
  });

  return (
    <>
      {arr.map((_: unknown, index: number) => {
        const isRemovable = index > 0 || !firstIsRequired;
        const indexedFields = convertFieldsToIndexedFields(items, index);
        const title = getTitle(index, autoTitlePattern, startFrom, firstTitle);
        const key = `${title} - ${arr.length}`;

        return (
          <ListContext.Provider value={index} key={key}>
            <LabeledCard
              key={key}
              disabled={disabled}
              label={
                <LabelWithAction
                  onClick={() => removeItem(index)}
                  icon={isRemovable && !disabled && <DeleteIcon />}
                >
                  {title}
                </LabelWithAction>
              }
              className={classnames({
                [styles.spacing]: withSpacing,
                [styles.divided]: withDivider && index !== arr.length - 1,
              })}
            >
              <DynamicItems fields={indexedFields} />
            </LabeledCard>
          </ListContext.Provider>
        );
      })}
      {addButtonDescription && (
        <div className={styles.buttonDescriptionWrapper}>
          {addButtonDescription?.map((text) => (
            <Typography key={uuidv4()} className={buttonDescriptionClasses}>
              {text}
            </Typography>
          ))}
        </div>
      )}

      {regularButton ? (
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          disabled={disabled || arr.length >= maxItems}
          onClick={!disabled ? addItem : undefined}
          className={styles.addRegularButton}
        >
          {buttonText}
        </Button>
      ) : (
        <button
          onClick={!disabled && arr.length < maxItems ? addItem : undefined}
          className={styles.addButton}
          disabled={disabled || arr.length >= maxItems}
        >
          <LabeledCard
            disabled={disabled}
            label={
              <span className={styles.addLabel}>
                <AddIcon />
                {buttonText}
              </span>
            }
          />
        </button>
      )}
    </>
  );
};
