import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Collapsed } from '@components/Collapsed';
import { LoanFooterActions } from '@pages/personalLoans/components';
import { usePostApiV1LoansByLoanIdSubmitMutation } from '@api/output/api';
import { useLoanDetails } from '@pages/personalLoans/pages/buildMyLoan';
import { useOpenToast } from '@hooks/useOpenToast';
import { ApiError, convertCatchError } from '@utils/errors.utils';
import { useExpand } from '@hooks/useExpand';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import {
  SEND_QUOTE_TASKS,
  SendQuote,
} from '@pages/personalLoans/components/SendQuote';
import { DEFAULT_API_ERROR_TITLE } from '@constants/text.constants';
import { useParties } from '@pages/personalLoans/pages/buildMyLoan/hooks/useParties';
import { useQuoteTypeAndId } from '@pages/personalLoans/pages/buildMyLoan/hooks/useQuoteTypeAndId';
import { REVIEW_AND_SUBMIT_CONSENT } from '../../../../buildMyLoan.constants';
import styles from './submit.module.scss';

const MILESTONE_FOR_SUBMIT = 'Pre-Submission Deal';

export interface SubmitProps {
  isAllStepsCompleted?: boolean;
}

export const Submit = ({ isAllStepsCompleted }: SubmitProps) => {
  const { disabled } = useContext(LoanDisableContext);
  const navigate = useNavigate();
  const openToast = useOpenToast(8000);
  const { loanId = '' } = useParams();
  const { data, refetchLoanDetails } = useLoanDetails();

  const { setValue } = useFormContext();
  const consent = useWatch({ name: REVIEW_AND_SUBMIT_CONSENT });
  const [submitLoan, { isLoading }] = usePostApiV1LoansByLoanIdSubmitMutation();

  const [expandConsentProps, setConsentExpand] = useExpand(true);
  const [expandSubmitProps, setSubmitExpand] = useExpand();

  const { parties } = useParties();
  const { quoteId, quoteType } = useQuoteTypeAndId();

  useEffect(() => {
    if (disabled) {
      setValue(REVIEW_AND_SUBMIT_CONSENT, true);
      setConsentExpand(true);
      setSubmitExpand(true);
    }

    return () => {
      if (!disabled) {
        setValue(REVIEW_AND_SUBMIT_CONSENT, false);
      }
    };
  }, [disabled]);

  useEffect(() => {
    if (isAllStepsCompleted) {
      setSubmitExpand(true);
    }
  });

  const submitHandler = useCallback(async () => {
    try {
      await submitLoan({
        loanId,
      }).unwrap();
      refetchLoanDetails();
      navigate(`/personal-loans/${loanId}`);
    } catch (err) {
      const description = convertCatchError(err as ApiError);
      openToast({
        isError: true,
        title: DEFAULT_API_ERROR_TITLE,
        description,
      });
    }
  }, [submitLoan, loanId]);

  const { control } = useFormContext();

  const isReady = useMemo(() => {
    return (
      consent &&
      isAllStepsCompleted &&
      data?.loan?.dealMetadata?.milestone === MILESTONE_FOR_SUBMIT
    );
  }, [consent, isAllStepsCompleted]);

  return (
    <Paper>
      <Collapsed label="Submit" {...expandSubmitProps}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SendQuote
              quoteId={quoteId}
              quoteType={quoteType}
              parties={parties}
              taskName={SEND_QUOTE_TASKS.INITIAL}
              isDisabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={styles.innerAccordionsContainer}>
          <Grid item xs={12} className={styles.consent}>
            <Collapsed label="Partner Attestation" {...expandConsentProps}>
              <Typography mb={3}>
                I understand that I am submitting a loan application on behalf
                of my borrower and I have been authorized by my borrower to
                submit this request.
              </Typography>
              <Paper variant="filled">
                <Controller
                  control={control}
                  name={REVIEW_AND_SUBMIT_CONSENT}
                  render={({ field: { value, ...props } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disabled}
                          checked={value}
                          {...props}
                        />
                      }
                      label={
                        <Typography variant="p">
                          I consent to the terms and conditions outlined&nbsp;
                          <Link
                            component={NavLink}
                            to="/"
                            underline="always"
                            target="_blank"
                            variant="pBold"
                          >
                            here
                          </Link>
                        </Typography>
                      }
                    />
                  )}
                />
              </Paper>
            </Collapsed>
          </Grid>
        </Grid>
      </Collapsed>
      <LoanFooterActions>
        <Button
          size="large"
          variant="contained"
          disabled={!isReady || isLoading}
          onClick={submitHandler}
        >
          Submit to underwriting
        </Button>
      </LoanFooterActions>
    </Paper>
  );
};
