import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { UploadFileItem } from '@components/Uploader/hooks/useFilesUploader';
import { usePostApiV1LoansByLoanIdFromPdfAppMutation } from '@api/output/api';
import { LOAN_PDF_APPLICATION_TYPE } from '@typings/common';

export const usePDFUploader = (appType: LOAN_PDF_APPLICATION_TYPE) => {
  const { loanId = '' } = useParams();
  const [uploadDocument] = usePostApiV1LoansByLoanIdFromPdfAppMutation();

  const uploadPdf = useCallback(
    ({ body }: UploadFileItem) => {
      const file = body?.get('file');
      body?.delete('file');
      body?.append('pdfFile', file as Blob);
      return uploadDocument({
        loanId,
        appType,
        body: body as { pdfFile?: Blob },
      }).unwrap();
    },
    [loanId, appType, uploadDocument]
  );

  return { uploadPdf };
};
