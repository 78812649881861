import { useMemo } from 'react';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import { useReviewAndSubmit } from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useReviewAndSubmit';
import { useStepProduct } from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useStepProduct';
import { useStepApplication } from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useStepApplication';
import { useStepUploadDocuments } from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps/useStepUploadDocuments';
import { useStepConstructionBudget } from './useStepConstructionBudget';
import { useStepBorrower } from './useStepBorrower';
import { useStepProductAndProperties } from './useStepProductAndProperties';
import { useStepOrderAppraisal } from './useStepOrderAppraisal';

/* Start: Per QA team request */
const TMP_STEPS_MAP: Record<ProgressStepItemStatus, string> = {
  [ProgressStepItemStatus.DONE]: '✔',
  [ProgressStepItemStatus.IN_PROGRESS]: '🕑',
  [ProgressStepItemStatus.LOCKED]: '🔒',
  [ProgressStepItemStatus.ALERT]: '!',
};

const enableAllSteps = (steps: ProgressStepperItem[]) => {
  return steps.map((step) => {
    const newStep = { ...step };
    newStep.status = ProgressStepItemStatus.IN_PROGRESS;
    newStep.title += ` ${TMP_STEPS_MAP[step.status as ProgressStepItemStatus]}`;

    if (newStep.items) {
      newStep.items = enableAllSteps(newStep.items);
    }
    return newStep;
  });
};

const isForceNavEnabled =
  window.location.hash.includes('bml-nav') || localStorage.getItem('bml-nav');

/* End: Per QA team request */

export const useBuildMyLoanSteps = (isDynamic = true) => {
  const borrowerStep = useStepBorrower(isDynamic);
  const productStep = useStepProduct();
  const applicationStep = useStepApplication([productStep.status]);
  const uploadDocumentStep = useStepUploadDocuments([
    applicationStep.status as ProgressStepItemStatus,
  ]);
  const productAndPropertiesStep = useStepProductAndProperties([
    productStep,
    applicationStep,
    uploadDocumentStep,
  ]);
  const constructionStep = useStepConstructionBudget(
    [borrowerStep.status, applicationStep.status],
    isDynamic
  );
  const orderAppraisalStep = useStepOrderAppraisal([
    borrowerStep.status,
    productAndPropertiesStep.status,
  ]);
  const reviewAndSubmitStep = useReviewAndSubmit([
    borrowerStep.status,
    productAndPropertiesStep.status,
    constructionStep?.status,
    orderAppraisalStep?.status,
  ]);

  return useMemo(() => {
    const items: ProgressStepperItem[] = [
      borrowerStep,
      productAndPropertiesStep,
    ];

    if (constructionStep) {
      items.push(constructionStep);
    }

    items.push(orderAppraisalStep, reviewAndSubmitStep);

    if (isForceNavEnabled) {
      return enableAllSteps(items);
    }

    return items;
  }, [
    borrowerStep,
    productAndPropertiesStep,
    constructionStep,
    orderAppraisalStep,
    reviewAndSubmitStep,
  ]);
};
