import React from 'react';
import { Chip } from '@mui/material';
import { LolaBffApiContractsModelsDashboardActionsLoanActionDto } from '@api/output/api';

export const teamRenderer = (
  _: unknown,
  { brokerTeam }: LolaBffApiContractsModelsDashboardActionsLoanActionDto
) => {
  return (
    <Chip
      color="info"
      size="small"
      variant="standard"
      label={!brokerTeam ? 'N/A' : `Team ${brokerTeam}`}
    />
  );
};
