import { ErrorPoolItem } from './reIssueCreditForm.type';

export enum FIELDS {
  RE_ISSUE_NUMBER = 'reIssueNumber',
  CRA = 'cra',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  SSN = 'reIssueSsn',
}

export const ERROR_PULL: ErrorPoolItem[] = [
  {
    stringKey: 'First Name',
    fieldName: FIELDS.FIRST_NAME,
    message: 'First name does not match',
  },
  {
    stringKey: 'Last Name',
    fieldName: FIELDS.LAST_NAME,
    message: 'Last name does not match',
  },
  {
    stringKey: 'SSN',
    fieldName: FIELDS.SSN,
    message: 'SSN does not match',
  },
  {
    stringKey: 'Document not found',
    fieldName: FIELDS.RE_ISSUE_NUMBER,
    message: 'Re-issue number does not match',
  },
  {
    stringKey: 'file ID',
    fieldName: FIELDS.CRA,
    message: 'CRA does not match',
  },
];
