import React, { useState, useMemo, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Grid, Link } from '@mui/material';
import { AddIcon } from '@lola/ui-react-components';
import { SubpageHeaderComponent } from '@components/SubpageHeader/SubpageHeader.component';
import { useRole } from '@hooks/useRole';
import { getLast7yearsObject } from '@utils/date.utils';
import { PeriodSelector, PeriodConfig } from '@components/PeriodSelector';
import { ModeSwitcher } from '@components/ModeSwitcher';
import { useUserAssignmentStatus } from '@hooks/useUserAssignmentStatus';
import { DynamicWidgetLayout } from './components';
import { PeriodContext } from './contexts/Period.context';
import { ModeContext } from './contexts/Mode.context';
import {
  ASSIGNED_ROLE_TO_ALLOWED_MODES,
  CAN_START_LOAN,
  DASHBOARD_MODE,
  DASHBOARD_CONFIGS,
} from './dashboard.constants';
import styles from './dashboard.module.scss';

export const Dashboard = () => {
  const role = useRole();
  const teamAssignmentStatus = useUserAssignmentStatus();
  const [period, setPeriod] = useState<PeriodConfig>({
    ...getLast7yearsObject(),
    value: 'all',
  });
  const [mode, setMode] = useState<DASHBOARD_MODE>();

  const switcherItem = useMemo(
    () =>
      teamAssignmentStatus &&
      ASSIGNED_ROLE_TO_ALLOWED_MODES?.[teamAssignmentStatus]?.[role],
    [teamAssignmentStatus, role]
  );

  useEffect(() => {
    if (switcherItem) {
      setMode(switcherItem.defaultValue);
    } else {
      setMode(DASHBOARD_MODE.NOT_SET);
    }
  }, [switcherItem]);

  const switcherComponent = useMemo(
    () =>
      switcherItem &&
      mode && (
        <ModeSwitcher
          value={mode}
          onSelect={(value) => setMode(value as DASHBOARD_MODE)}
          options={switcherItem.options}
        />
      ),
    [mode, setMode, switcherItem]
  );

  const widgetConfig = useMemo(() => {
    if (!mode || !teamAssignmentStatus || !role) return [];

    const roleConfigMapping = DASHBOARD_CONFIGS[teamAssignmentStatus][mode];

    return roleConfigMapping[role] || [];
  }, [role, teamAssignmentStatus, mode, teamAssignmentStatus]);

  return (
    <section className={styles.section}>
      <SubpageHeaderComponent title="Dashboard" titleChip={switcherComponent}>
        <PeriodSelector
          onPeriodChange={(newPeriod) => setPeriod(newPeriod)}
          period={period}
        />
        {CAN_START_LOAN.includes(role) && (
          <Link component={NavLink} to="/new-loan" underline="none">
            <Button variant="contained" startIcon={<AddIcon />} size="large">
              Start a loan
            </Button>
          </Link>
        )}
      </SubpageHeaderComponent>
      <Grid container spacing={4} padding={4}>
        <PeriodContext.Provider value={period}>
          <ModeContext.Provider value={mode}>
            <DynamicWidgetLayout items={widgetConfig} />
          </ModeContext.Provider>
        </PeriodContext.Provider>
      </Grid>
    </section>
  );
};
