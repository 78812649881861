import { useMemo } from 'react';
import get from 'lodash/get';
import { QUOTE_TYPE } from '@typings/common';
import { useLoanQuote } from '@pages/personalLoans/pages/buildMyLoan/hooks/useLoanQuote';

export const useQuoteState = () => {
  const { quoteType, data } = useLoanQuote();

  const stateField =
    quoteType === QUOTE_TYPE.RENTAL ? 'state' : 'loanSummary.state';

  return useMemo(() => {
    if (!data) {
      return undefined;
    }

    return get(data.in, stateField);
  }, [stateField, data]);
};
