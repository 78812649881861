import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import { CardSection } from '@lola/ui-react-components';
import { ProgressStepperItem } from '@components/ProgressStepper/types';
import { Substep } from '@pages/personalLoans/pages/buildMyLoan/pages/reviewAndSubmit/components/review/components/Substep/Substep.component';

interface ReviewProps {
  stepsData?: ProgressStepperItem[];
}

export const Review = ({ stepsData }: ReviewProps) => {
  return (
    <CardSection
      title="Review and submit"
      description="Below is a list of all the actions that appeared during the application process. Please note that in order to successfully proceed to the next stage, you must complete all of the tasks on this list"
    >
      <Grid container spacing={2}>
        {stepsData?.map((stepCategory) => (
          <Grid item xs={12} key={stepCategory.id}>
            <Paper variant="filled">
              <Typography variant="h3">{stepCategory.title}</Typography>
              {!!stepCategory?.items?.length && (
                <Grid container spacing={2} mt={1}>
                  {stepCategory?.items?.map((step) => (
                    <Substep key={step.id} {...step} />
                  ))}
                </Grid>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </CardSection>
  );
};
