import {
  LolaBffApiContractsModelsLoanDocumentTask,
  LolaBffApiContractsModelsLoanPartyInfo,
  LolaBffApiContractsModelsLoanTaskInfo,
  LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto,
} from '@api/output/api';
import { FileItem } from '@components/Uploader/Uploader.types';

export const getTaskInfoByEntityId = (
  tasks?: LolaBffApiContractsModelsLoanDocumentTask[],
  existedEntityId?: string | null
): LolaBffApiContractsModelsLoanTaskInfo | undefined => {
  if (!tasks || !existedEntityId) {
    return undefined;
  }

  const task = tasks.find(({ entityId }) => entityId === existedEntityId);

  return task?.task;
};

/* to be removed */
export const getTaskByEntity = (
  tasks: Record<string, LolaBffApiContractsModelsLoanTaskInfo[]>,
  taskName: LolaBffApiContractsModelsLoanTaskInfo['taskName'],
  entity: LolaBffApiContractsModelsLoanPartyInfo
): LolaBffApiContractsModelsLoanTaskInfo | undefined => {
  if (!entity?.id) {
    return undefined;
  }

  const entityTasks = tasks[entity.id] ?? [];
  return entityTasks.find((entityTask) => entityTask.taskName === taskName);
};

/* to be removed */
export const getFilesByEntity = (
  tasks: Record<string, LolaBffApiContractsModelsLoanTaskInfo[]>,
  taskName: LolaBffApiContractsModelsLoanTaskInfo['taskName'],
  entity: LolaBffApiContractsModelsLoanPartyInfo
): FileItem[] => {
  if (!entity?.id) {
    return [];
  }

  const entityTasks = tasks[entity.id] ?? [];
  const entityTask = entityTasks.find(
    (entityTask) => entityTask.taskName === taskName
  );
  return entityTask?.documents ?? [];
};

export const getLolaTaskByName = (
  tasks: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto[],
  taskName: string
) => {
  return tasks.find(({ taskType }) => taskType === taskName);
};
