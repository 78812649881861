import { NavigationConfig, ROLES } from '@typings/common';

export const DASHBOARD_NAVIGATION_CONFIG: NavigationConfig = {
  to: '/',
  value: '/',
  label: 'Dashboard',
};

export const PERSONAL_LOANS_NAVIGATION_CONFIG: NavigationConfig = {
  to: '/personal-loans',
  value: '/personal-loans',
  label: 'Loans',
};

export const TEAM_MANAGEMENT_NAVIGATION_CONFIG: NavigationConfig = {
  to: '/team-management',
  value: '/team-management',
  label: 'Team management',
};

export const SCENARIO_BUILDER_NAVIGATION_CONFIG: NavigationConfig = {
  to: '/scenario-builder',
  value: '/scenario-builder',
  label: 'Scenario builder',
};

export const DEFAULT_MENU = [
  DASHBOARD_NAVIGATION_CONFIG,
  PERSONAL_LOANS_NAVIGATION_CONFIG,
  SCENARIO_BUILDER_NAVIGATION_CONFIG,
];

export const FULL_MENU = [
  DASHBOARD_NAVIGATION_CONFIG,
  PERSONAL_LOANS_NAVIGATION_CONFIG,
  TEAM_MANAGEMENT_NAVIGATION_CONFIG,
  SCENARIO_BUILDER_NAVIGATION_CONFIG,
];

export const MENU_MAP: Record<ROLES, NavigationConfig[]> = {
  [ROLES.BROKER]: DEFAULT_MENU,
  [ROLES.PROCESSOR]: FULL_MENU,
  [ROLES.KEY_BROKER_MANAGER]: FULL_MENU,
  [ROLES.BROKER_MANAGER]: FULL_MENU,
  [ROLES.NOT_SET]: DEFAULT_MENU,
};
