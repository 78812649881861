import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse } from '@api/output/api';
import { StatusChip } from '@components/StatusChip/StatusChip.component';
import { STATUS_CONFIG } from '@config/status';

export enum QUOTE_STATUS {
  STAGED = 'staged',
  ATTACHED = 'attached',
  DRAFT = 'draft',
}

export const QUOTE_STATUS_DEFAULT_TEXTS = {
  [QUOTE_STATUS.DRAFT]: 'Draft',
  [QUOTE_STATUS.STAGED]: 'Staged Quote',
  [QUOTE_STATUS.ATTACHED]: 'Attached to a loan',
};

const QUOTE_STATUS_CONFIG: Record<QUOTE_STATUS, ChipProps> = {
  [QUOTE_STATUS.DRAFT]: STATUS_CONFIG.INFO,
  [QUOTE_STATUS.STAGED]: STATUS_CONFIG.INFO_FILLED,
  [QUOTE_STATUS.ATTACHED]: STATUS_CONFIG.SUCCESS_FILLED,
};

export const createQuoteStatusRenderer = (
  titles = QUOTE_STATUS_DEFAULT_TEXTS
) => {
  return (
    _: unknown,
    row: LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse
  ) => {
    const { isAttachedToLoan, isQuoteStaged } = row;
    let status = isQuoteStaged
      ? titles[QUOTE_STATUS.STAGED]
      : titles[QUOTE_STATUS.DRAFT];

    let chipConfig = isQuoteStaged
      ? QUOTE_STATUS_CONFIG[QUOTE_STATUS.STAGED]
      : QUOTE_STATUS_CONFIG[QUOTE_STATUS.DRAFT];

    if (isAttachedToLoan) {
      status = titles[QUOTE_STATUS.ATTACHED];
      chipConfig = QUOTE_STATUS_CONFIG[QUOTE_STATUS.ATTACHED];
    }

    return <Chip label={status} {...chipConfig} />;
  };
};
