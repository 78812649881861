import { useEffect, useState } from 'react';
import round from 'lodash/round';
import {
  LolaBffApiContractsModelsLoanLoanMilestone,
  LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse,
} from '@api/output/api';
import { Scenario } from '../ScenarioRentalCalculation.types';

export const useGetScenarios = (
  details: LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse
): Scenario[] => {
  const [scenarios, setScenarios] = useState<Scenario[]>([]);

  useEffect(() => {
    const scenarios = details?.out?.scenarios;
    const loanMilestone = details?.quoteInfo
      ?.loanMilestone as LolaBffApiContractsModelsLoanLoanMilestone;
    if (scenarios) {
      const transformedScenarios: Scenario[] = scenarios.map(
        (outScenario, index) => {
          const inScenario = details.in?.scenario?.[index];

          return {
            id: inScenario?.scenarioId
              ? `${inScenario.scenarioId}`
              : `${index + 1}`,
            eligibility: outScenario.ltvEligibility ?? null,
            loanAmount: outScenario.loanAmt ?? 0,
            initFixedInterestRate: outScenario.initFixedInterestRate ?? null,
            cashTo: outScenario.closingCashToBorrower ?? 0,
            originationFee: outScenario.originationFee ?? 0,
            rdsr: round(outScenario.rentDebtRatio ?? 0, 3),
            loanMilestone,
          };
        }
      );

      setScenarios(transformedScenarios);
    }
  }, [details]);

  return scenarios;
};
