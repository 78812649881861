import { useMemo } from 'react';
import {
  CommonFeeIn,
  groupFeesToObject,
  NestedFeesObject,
} from '@hooks/useQuotesApi/utils/replaceObjectWithFees.utils';
import {
  useLazyGetApiV1PricingEngineQuoteByQuoteIdRentalQuery,
  useLazyGetApiV1PricingEngineQuoteByQuoteIdStfQuery,
} from '@api/output/api';

export type GetRentalHook = ReturnType<
  typeof useLazyGetApiV1PricingEngineQuoteByQuoteIdRentalQuery
>;
export type GetSTFHook = ReturnType<
  typeof useLazyGetApiV1PricingEngineQuoteByQuoteIdStfQuery
>;

export type RawReturnValue = GetRentalHook[1] & GetSTFHook[1];
export type FeesReturnValue = GetRentalHook[1] & GetSTFHook[1];

export const useQuoteFeesData = (apiResult: RawReturnValue) => {
  const data: NestedFeesObject | undefined = useMemo(() => {
    if (!apiResult.data) {
      return undefined;
    }

    const inData = groupFeesToObject(apiResult.data.in);

    return {
      ...apiResult.data,
      in: {
        ...apiResult.data.in,
        ...inData,
      } as CommonFeeIn,
    };
  }, [apiResult.data]);

  return useMemo(() => {
    return {
      ...apiResult,
      data,
    };
  }, [apiResult, data]);
};
