import React, { useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { LinearProgress, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { usePostApiV1AuthResetPasswordSendMutation } from '@api/output/api';
import { auth } from '@config/auth';
import { getCorrectedError } from '@utils/error.utils';
import { getCorrectedText } from '@utils/text.utils';
import { USER_EMAIL_ERROR_TEXT_PLACEHOLDER } from '@constants/text.constants';
import { ContentFormSection } from '../../components/ContentFormSection';
import { passwordFormSchema } from './passwordForm.schema';

export interface ForgotPasswordFormInputs {
  email: string;
}

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [sendResetPassword, { error, isSuccess, isError, isLoading }] =
    usePostApiV1AuthResetPasswordSendMutation();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isDirty, errors },
  } = useForm<ForgotPasswordFormInputs>({
    defaultValues: {
      email: '',
    },
    resolver: passwordFormSchema,
  });

  const onSubmit = useMemo(() => {
    return handleSubmit(({ email }) => {
      sendResetPassword({
        lolaBffApiContractsModelsAuthAuthResetPasswordRequest: {
          username: email,
          domain: auth.domain,
        },
      });
    });
  }, [handleSubmit]);

  useEffect(() => {
    if (isSuccess) {
      const { email } = getValues();
      navigate('/auth/forgot-email-confirmation', {
        state: email,
      });
    }
  }, [isSuccess]);

  if (isLoading) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  return (
    <ContentFormSection
      title="Forgot password?"
      subtitle="We will send you an email to change your username or password"
      primaryButtonText="Reset password"
      backLinkProps={{
        to: '/auth',
        label: 'Back to login',
      }}
      primaryButtonDisabled={!isDirty}
      onSubmit={onSubmit}
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => {
          const correctedError = getCorrectedError(error);
          const message = getCorrectedText(correctedError.message, {
            [USER_EMAIL_ERROR_TEXT_PLACEHOLDER]: field.value,
          });
          const errorText = isError ? message : errors.email?.message;

          return (
            <TextField
              {...field}
              error={!!errorText}
              helperText={errorText}
              variant="filled"
              label="Email"
            />
          );
        }}
      />
    </ContentFormSection>
  );
};
