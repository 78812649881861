import { useCallback, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { QUOTE_TYPE_RADIO_GROUP_NAME } from '@pages/scenarioBuilder/pages/quoteForm/QuoteForm.types';
import { QUOTE_TYPE } from '@typings/common';
import { DEFAULT_SCENARIOS } from '@hooks/useQuotesApi/quotes.constants';
import { FormData } from '@form-configs/types';

export const useResetQuote = (
  methods: UseFormReturn<FormData>,
  quoteType: QUOTE_TYPE
) => {
  const clearHandler = useCallback(() => {
    const defaultData = {
      [QUOTE_TYPE_RADIO_GROUP_NAME.QUOTE_TYPE]: quoteType,
    };

    if (quoteType === QUOTE_TYPE.RENTAL) {
      Object.assign(defaultData, {
        scenario: DEFAULT_SCENARIOS,
      });
    }

    methods.reset(defaultData);
  }, [quoteType]);

  useEffect(() => {
    clearHandler();
  }, [clearHandler]);

  return clearHandler;
};
