import { useMemo } from 'react';
import { LolaBffApiContractsModelsLoanTaskInfo } from '@api/output/api';
import { useTasksBySection } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTasksBySection';

export type TasksList = Record<string, LolaBffApiContractsModelsLoanTaskInfo[]>;

export interface ReduceReturnValue {
  map: TasksList;
  flat: LolaBffApiContractsModelsLoanTaskInfo[];
}

export interface UseEntityTasksHelpers {
  raw: LolaBffApiContractsModelsLoanTaskInfo[];
  refetch: () => Promise<void>;
}

export type UseEntityTasksBySectionReturnValue = [
  TasksList,
  UseEntityTasksHelpers
];

export enum ENTITY_TYPE {
  PARTY = 'party',
  PROPERTY = 'property',
  LOAN = 'deal',
  LOAN_PARTY = 'deal-party',
}

export const useEntityTasksBySection = (
  sectionName: string
): UseEntityTasksBySectionReturnValue => {
  const [tasks, refetch] = useTasksBySection(sectionName);

  return useMemo(() => {
    const arr = tasks ?? [];

    const { map, flat } = arr.reduce(
      (result: ReduceReturnValue, task) => {
        if (!task.entityId) {
          return result;
        }

        let id = task.entityId;

        // need to link deal-party to party section
        if (task.entityType === ENTITY_TYPE.LOAN_PARTY) {
          id = task.entityId.split('_').pop() ?? task.entityId;
        }

        if (!result.map[id]) {
          result.map[id] = [];
        }

        if (task.task) {
          result.map[id].push(task.task);
          result.flat.push(task.task);
        }

        return result;
      },
      {
        map: {},
        flat: [],
      }
    );

    Object.values(map).forEach((tasks) => {
      tasks.sort((itemA, itemB) => {
        if (
          !itemA.taskName ||
          !itemB.taskName ||
          itemA.taskName === itemB.taskName
        ) {
          return 0;
        }

        return itemA.taskName.localeCompare(itemB.taskName);
      });
    });

    return [
      map,
      {
        refetch,
        raw: flat,
      },
    ];
  }, [tasks, refetch]);
};
