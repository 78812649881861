import React, { useMemo } from 'react';
import { ProgressStepItemStatus } from '@components/ProgressStepper/types';

export interface PercentCompletionProps {
  percentage?: number;
  status?: ProgressStepItemStatus;
}

export const PercentCompletion = ({
  percentage,
  status,
}: PercentCompletionProps) => {
  const parsedPercentage = useMemo(() => {
    if (percentage !== undefined) {
      return percentage;
    }

    if (status === ProgressStepItemStatus.DONE) {
      return 100;
    }

    return 0;
  }, [percentage, status]);

  return <>{parsedPercentage}% Completed</>;
};
