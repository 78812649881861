import get from 'lodash/get';
import {
  LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest,
  LolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest,
  LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse,
} from '@api/output/api';
import { Fee } from '@components/PartnerEconomics/PartnerEconomics.types';
import {
  feesTypeOptionsForRental,
  feesTypeOptionsForShortTerm,
} from '@components/PartnerEconomics/PartnerEconomics.constants';
import { QUOTE_TYPE } from '@typings/common';

interface RentalFeeIn
  extends LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest {
  fees: Fee[];
}

interface StfFeeIn
  extends LolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest {
  fees: Fee[];
}

export type CommonFeeIn = RentalFeeIn | StfFeeIn;

export type NestedFeesObject =
  LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse & {
    in?: CommonFeeIn;
  };

export const groupFeesToObject = (
  data?: LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest
): CommonFeeIn | undefined => {
  if (!data?.quoteType) {
    return undefined;
  }

  const fees: Fee[] = [];
  const allOptions =
    data.quoteType === QUOTE_TYPE.SHORT_TERM
      ? feesTypeOptionsForShortTerm
      : feesTypeOptionsForRental;

  allOptions.forEach(({ value }) => {
    const savedValue = get(data, value);
    if (savedValue) {
      fees.push({
        feeType: value,
        feeValue: savedValue,
      });
    }
  });

  return {
    ...data,
    fees,
  };
};
