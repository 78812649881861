import { useCallback } from 'react';
import { set } from 'react-hook-form';
import {
  usePutApiV1PartiesByPartyIdEntityMutation,
  usePutApiV1PartiesByPartyIdIndividualMutation,
} from '@api/output/api';
import { CustomValueType } from '@typings/common';

export enum UPDATE_MODE {
  ENTITY,
  INDIVIDUAL,
}

export const useUpdateParty = () => {
  const [updatePartyEntity] = usePutApiV1PartiesByPartyIdEntityMutation();
  const [updatePartyIndividual] =
    usePutApiV1PartiesByPartyIdIndividualMutation();

  return useCallback(
    (
      mode: UPDATE_MODE,
      id: string | undefined,
      name: string,
      value: CustomValueType
    ) => {
      const body = {};
      set(body, name, value);

      if (!id) {
        return Promise.reject();
      }

      if (mode === UPDATE_MODE.ENTITY) {
        return updatePartyEntity({
          partyId: id,
          lolaBffApiContractsModelsPartyUpdateEntityPartyRequest: body,
        }).unwrap();
      } else {
        return updatePartyIndividual({
          partyId: id,
          lolaBffApiContractsModelsPartyUpdateIndividualPartyRequest: body,
        }).unwrap();
      }
    },
    []
  );
};
