import React, { useCallback } from 'react';
// router
import { useParams } from 'react-router-dom';
// MUI
import { Typography, Grid, Skeleton, Stack } from '@mui/material';
// API
import {
  LolaBffApiContractsModelsApproveMyLoanResponseActionResponse,
  useGetApiV1ApprovemyloanGetActionsQuery,
} from '@api/output/api';
// components
import { NoContent } from '@components/NoContent';
import { TaskFilesUploadDrawer } from '@containers/TaskFilesUploadDrawer/TaskFilesUploadDrawer.component';
import { ActionItem } from '@pages/personalLoans/pages/approveMyLoan/components/ListOfActions/components/ActionItem/ActionItem.component';
import { useApproveMyLoanActionsUpload } from './hooks/useApproveMyLoanActionsUpload';
import { REI_LENDER_UPLOADER_CONFIG } from './ListOfActions.constants';
import { ListOfActionWrapper } from './components/ListOfActionsWrapper/ListOfActioWrapper.component';
// style
import style from './ListOfActions.module.scss';

export const ListOfActionsDescription =
  'You will be able to continue once all the tasks below are completed by REI servicing.';

export const ListOfActions = (): JSX.Element => {
  // get pram
  const { loanId = '' } = useParams();

  // API
  const {
    isLoading,
    data = [],
    isError,
    refetch,
  } = useGetApiV1ApprovemyloanGetActionsQuery({
    loanId: loanId,
  });

  // data
  const actions: LolaBffApiContractsModelsApproveMyLoanResponseActionResponse[] =
    data as LolaBffApiContractsModelsApproveMyLoanResponseActionResponse[];

  const { isDrawerOpen, closeDrawer, activeTask, uploadDrawerHandler } =
    useApproveMyLoanActionsUpload(actions);

  const completeOperationAsync = useCallback(async () => {
    await refetch().unwrap();
  }, []);

  return (
    <>
      <Typography variant="p" className={style.listOfActionsDescription}>
        {ListOfActionsDescription}
      </Typography>
      {isLoading && (
        <ListOfActionWrapper>
          <Grid container>
            <Grid item xs={1}>
              <Skeleton variant="circular" height={30} width={30} />
            </Grid>
            <Grid item xs={11}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
          </Grid>
        </ListOfActionWrapper>
      )}

      {isError && (
        <ListOfActionWrapper>
          <NoContent
            title="No data to show here yet"
            text="Try to check soon."
          />
        </ListOfActionWrapper>
      )}
      <Stack direction="column" className={style.actions}>
        {actions?.map((action) => (
          <ActionItem
            key={action.taskName}
            action={action}
            uploadDrawerHandler={uploadDrawerHandler}
          />
        ))}
      </Stack>
      <TaskFilesUploadDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        taskId={activeTask?.taskId ?? undefined}
        uploadedFiles={activeTask?.documents ?? []}
        uploaderConfig={REI_LENDER_UPLOADER_CONFIG}
        drawerConfig={{
          headerTitle: 'Upload',
          title: activeTask?.taskName ?? 'Document',
        }}
        completeOperationAsync={completeOperationAsync}
      />
    </>
  );
};
