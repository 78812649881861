import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ContentFormSection } from '../../components/ContentFormSection';

export const ForgotPasswordConfirmPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state || 'your email';

  const onSubmit = () => navigate('/auth');

  return (
    <ContentFormSection
      title="Check your email"
      subtitle={
        <span>
          We have sent you instructions to <b>{email}</b> to change your
          username or password
        </span>
      }
      primaryButtonText="Log in"
      onSubmit={onSubmit}
    />
  );
};
