import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  StatusDoneIcon,
  StatusInProgressIcon,
} from '@lola/ui-react-components';
import { ListOfActionWrapper } from '@pages/personalLoans/pages/approveMyLoan/components/ListOfActions/components/ListOfActionsWrapper/ListOfActioWrapper.component';
import { UploaderStatusChip } from '@components/UploaderStatusChip/UploaderStatusChip.component';
import { UploaderIconButton } from '@components/UploaderIconButton/UploaderIconButton.component';
import { LolaBffApiContractsModelsApproveMyLoanResponseActionResponse } from '@api/output/api';
import style from './actionItem.module.scss';

interface ActionItemProps {
  action: LolaBffApiContractsModelsApproveMyLoanResponseActionResponse;
  uploadDrawerHandler: (
    action?: LolaBffApiContractsModelsApproveMyLoanResponseActionResponse
  ) => () => void;
}

export const ActionItem = ({
  action,
  uploadDrawerHandler,
}: ActionItemProps) => {
  const { taskName, lender, comments, lastChange, currentState, documents } =
    action;
  const isUploaded = (documents ?? []).length > 0;
  return (
    <ListOfActionWrapper key={taskName}>
      <Grid container alignItems="center" xs={12}>
        <Grid item xs={0.4} className={style.icon}>
          {currentState === 'Approved' ? (
            <StatusDoneIcon />
          ) : (
            <StatusInProgressIcon />
          )}
        </Grid>
        <Grid item xs={6}>
          {taskName && (
            <Typography variant="h3" className={style.title}>
              {taskName}
            </Typography>
          )}

          {lender && <Typography variant="pBold">{lender}: </Typography>}

          {comments && (
            <Typography variant="p" className={style.fonts}>
              {comments}
            </Typography>
          )}

          {lastChange && (
            <>
              <br />
              <Typography variant="p" className={style.description}>
                {lastChange}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={5}>
          <UploaderStatusChip isUploaded={isUploaded} />
        </Grid>
        <Grid item xs={0.5}>
          <UploaderIconButton
            isUploaded={isUploaded}
            onClick={uploadDrawerHandler(action)}
          />
        </Grid>
      </Grid>
    </ListOfActionWrapper>
  );
};
