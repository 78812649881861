import { TableColumnType } from 'antd';
import { ROLES } from '@typings/common';
import { LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse } from '@api/output/api';
import { CREATED_BY_QUOTES_ALLOWED_ROLES } from '@constants/quotes.constants';

type Column =
  TableColumnType<LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse>;

export const adjustCreatedByColumnVisibilityByRole =
  (role: ROLES) =>
  (columns: Column[]): Column[] =>
    CREATED_BY_QUOTES_ALLOWED_ROLES.includes(role)
      ? columns
      : columns.filter((column) => column.key !== 'createdBy');
