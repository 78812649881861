import React, { Fragment, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Typography } from '@mui/material';
import { Option } from '@typings/common';

import styles from './chipNavigation.module.scss';

export interface ChipNavigationProps {
  options: Option[];
  value: string | number;
  onSelect: (value: string | number) => void;
}

export const ChipNavigation = ({
  value,
  options = [],
  onSelect,
}: ChipNavigationProps) => {
  const navRef = useRef<HTMLDivElement>(null);
  const [selectionStyles, setStyles] = useState<{
    left: number;
    width: number;
  }>({
    left: 0,
    width: 0,
  });

  useEffect(() => {
    if (!navRef.current) {
      return;
    }

    const element: HTMLDivElement | null = navRef.current.querySelector(
      `[data-value=${value}]`
    );

    if (!element) {
      return;
    }

    setStyles({
      width: element.offsetWidth || 0,
      left: element.offsetLeft || 0,
    });
  }, [value]);

  return (
    <div className={styles.wrapper} ref={navRef}>
      <div className={styles.selection} style={selectionStyles} />
      {options.map((option) => (
        <Fragment key={option.value}>
          {option.icon ? (
            <div
              data-value={option.value}
              className={classnames({
                [styles.icon]: true,
                [styles.active]: option.value === value,
              })}
              onClick={() => onSelect(option.value)}
            >
              {option.icon}
            </div>
          ) : (
            <Typography
              variant="pBold"
              data-value={option.value}
              className={classnames({
                [styles.chip]: true,
                [styles.active]: option.value === value,
              })}
              onClick={() => onSelect(option.value)}
            >
              {option.label}
            </Typography>
          )}
        </Fragment>
      ))}
    </div>
  );
};
