import React from 'react';
import { SetModalContent } from '@typings/common';
import { LolaBffApiContractsModelsLoanLoanView } from '@api/output/api';
import { DrawerState } from '@containers/ManageAccessDrawer/ManageAccessDrawer.constants';
import { ActionsMenu } from '@pages/teamManagement/pages/Loans/components';
import { ModalContentType } from '../../../Loans.constants';

export const actionsRenderer = (
  context: LolaBffApiContractsModelsLoanLoanView,
  setModalContent?: SetModalContent<ModalContentType>,
  setDrawerState?: (arg: DrawerState) => void
) => {
  return (
    <ActionsMenu
      setModalContent={setModalContent}
      setDrawerState={setDrawerState}
      context={context}
    />
  );
};
