import React from 'react';
import { Grid } from '@mui/material';
import { FieldConfig } from '@form-configs/types';
import { DynamicItem } from '@components/DynamicForm/components/DynamicItem/DynamicItem.component';
import styles from './dynamicItems.module.scss';

export interface DynamicItemsProps {
  fields: FieldConfig[];
}

export const DynamicItems = ({ fields }: DynamicItemsProps) => (
  <Grid container spacing={3} className={styles.container}>
    {fields.map((fieldConfig) => (
      <Grid
        item
        key={fieldConfig.id}
        xs={12}
        className={styles[fieldConfig.controlType]}
      >
        <DynamicItem {...fieldConfig} />
      </Grid>
    ))}
  </Grid>
);
