import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyGetApiV1LolaTaskStatusQuery } from '@api/output/api';
import { DEFAULT_POLLING_INTERVAL } from './useBuildMyLoanSteps/useBuildMyLoan.constants';
import { checkTaskCompletion } from './useBuildMyLoanSteps/useBuildMyLoan.utils';

interface TaskConfig {
  taskNames: string[];
  interval: number;
}

interface UseTaskPolling {
  shouldRefetchInit: boolean;
  config: TaskConfig[];
  partyIds?: string[];
}

export const useTaskPolling = ({
  shouldRefetchInit = false,
  config,
  partyIds = [],
}: UseTaskPolling) => {
  const [loadTasks, { data, isFetching }] =
    useLazyGetApiV1LolaTaskStatusQuery();

  const { loanId = '' } = useParams();
  const loadTasksStatuses = useCallback(
    () =>
      loadTasks({
        entity1Type: 'loan',
        entity1Id: loanId,
      }),
    [loanId]
  );

  const [shouldRefetch, setShouldRefetch] = useState(shouldRefetchInit);
  const [pollingInterval, setPollingInterval] = useState(
    DEFAULT_POLLING_INTERVAL
  );

  const isAllTasksDone = useMemo(() => {
    return config.every((task) =>
      checkTaskCompletion(partyIds, task.taskNames, data)
    );
  }, [data, config]);

  useEffect(() => {
    if (isAllTasksDone) {
      setShouldRefetch(false);
      return;
    }

    setPollingInterval((prevInterval) => {
      let interval = prevInterval;
      config.every((task, index) => {
        if (
          checkTaskCompletion(partyIds, task.taskNames, data) &&
          config[index + 1]
        ) {
          interval = config[index + 1].interval;
          return false;
        }
        return true;
      });
      return interval;
    });

    setShouldRefetch(shouldRefetchInit);
  }, [
    isAllTasksDone,
    setShouldRefetch,
    setPollingInterval,
    shouldRefetchInit,
    config,
  ]);

  useEffect(() => {
    loadTasksStatuses();
  }, []);

  useEffect(() => {
    let timeoutId: number;
    if (shouldRefetch && !isFetching) {
      timeoutId = setTimeout(() => {
        loadTasksStatuses();
      }, pollingInterval) as unknown as number;
    }
    return () => clearTimeout(timeoutId);
  }, [isFetching, shouldRefetch, pollingInterval]);

  return data;
};
