import React from 'react';

import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Toast } from '@containers/Toast/Toast.component';
import { Settings } from '@pages/auth/components/Settings/Settings';
import { AuthSidebar } from './components/AuthSidebar/AuthSidebar.component';

import styles from './authLayout.module.scss';

export const AuthLayout = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <AuthSidebar />
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        justifyContent="center"
        alignItems="center"
        className={styles.page}
      >
        <Toast />
        <Outlet />
      </Grid>
      <Settings />
    </Grid>
  );
};
