import React from 'react';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { LolaBffApiContractsModelsLicensesLicense } from '@api/output/api';

export const expirationDateRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsLicensesLicense
) => {
  const { expirationDate } = row;

  return <Typography>{dayjs(expirationDate).format('DD MMM YYYY')}</Typography>;
};
