import { useCallback, useState } from 'react';

export type UseOpenStateReturnValue = [boolean, () => void, () => void];

export const useOpenState = (defaultValue = false): UseOpenStateReturnValue => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return [isOpen, open, close];
};
