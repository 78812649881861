import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem } from '@mui/material';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { SelectWithPlaceholder } from '@components/SelectWithPlaceholder/SelectWithPlaceholder.component';
import { ROLES } from '@typings/common';
import { OPTIONS_MAP } from './inviteRole.constants';

export interface InviteRoleProps {
  role: ROLES;
  isLocked: boolean;
}

export const InviteRole = ({ role, isLocked }: InviteRoleProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const selectOptions = OPTIONS_MAP[role] || [];

  return (
    <LabeledCard label="Role">
      <Controller
        name="role"
        control={control}
        render={({ field: { value = '', ...props } }) => (
          <SelectWithPlaceholder
            error={!!errors.role}
            helperText={errors.role?.message as string}
            value={value}
            placeholder="Select"
            label="Member role"
            selectOptions={selectOptions}
            disabled={isLocked}
            {...props}
          >
            {selectOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectWithPlaceholder>
        )}
      />
    </LabeledCard>
  );
};
