import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, LinearProgress } from '@mui/material';
import styles from './pageFooter.module.scss';

export interface PageFooterProps {
  onSubmit?: () => void;
  loading?: boolean;
  isDirty?: boolean;
}

export const PageFooter = ({ loading, isDirty, onSubmit }: PageFooterProps) => {
  const navigate = useNavigate();

  const onCancel = useCallback(() => navigate(-1), [navigate]);

  return (
    <form className={styles.footer}>
      {loading && <LinearProgress className={styles.loading} />}
      <Button variant="text" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant="contained"
        type="submit"
        disabled={!isDirty || loading}
        onClick={onSubmit}
      >
        Save
      </Button>
    </form>
  );
};
