import { useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import { useLoanProgramList } from '@pages/personalLoans/hooks/useLoanProgramList';
import { QUOTE_PRODUCT_KEY } from '@pages/personalLoans/pages/buildMyLoan/pages/product/productPage.constants';

/* TODO: this map should be added to BE after MVP */
export const OPTIONS_LIMITED_MAP: Record<string, string[]> = {
  FixNFlip: ['FixNFlip', 'BridgePlus'],
  BridgePlus: ['FixNFlip', 'BridgePlus'],
  'New Construction': ['New Construction'],
};

export const useLimitedLoanProgramList = () => {
  const { programs: fullOptions, products } = useLoanProgramList();

  const [currentLoanProgram = ''] = useWatch({
    name: [QUOTE_PRODUCT_KEY],
  });

  const options = useMemo(() => {
    const configs = OPTIONS_LIMITED_MAP[currentLoanProgram];
    if (configs) {
      return fullOptions.filter(({ value }) => configs.includes(`${value}`));
    }
    return [];
  }, [fullOptions, currentLoanProgram]);

  return useMemo(() => {
    return {
      limitedLoanProgram: options,
      loanProgram: products,
    };
  }, [options]);
};
