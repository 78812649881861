import React, { useRef } from 'react';
//material
import { Button, Grid, Typography } from '@mui/material';
//hooks
import { UploadIcon } from '@lola/ui-react-components';
import { useUploadButtonLogic } from '@components/Uploader/hooks/useUploaderButtonLogic';
// icons
//types
import { OnUploadType } from '@components/Uploader/Uploader.types';

import styles from './exceptionUploaderButton.module.scss';

interface ExceptionUploaderButtonProps {
  labelConfig: string;
  onClick: OnUploadType;
  maxFiles?: number;
  size?: number;
  accept?: string;
}

export const ExceptionUploaderButton = ({
  labelConfig,
  onClick,
  maxFiles,
  size,
  accept = '',
}: ExceptionUploaderButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleUploadChange, handleUploadClick } = useUploadButtonLogic();

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => handleUploadClick(inputRef)}
        className={styles.button}
      >
        <Grid className={styles.message}>
          <Typography variant="pBold">{labelConfig}</Typography>
          <Typography variant="pCaption">
            Optional • Any format, less than 20mb
          </Typography>
        </Grid>
        <UploadIcon />
      </Button>
      <input
        type="file"
        ref={inputRef}
        multiple={!!(maxFiles && maxFiles > 1)}
        onChange={(e) => handleUploadChange(e, onClick, maxFiles)}
        style={{ display: 'none' }}
        size={size}
        accept={accept}
      />
    </>
  );
};
