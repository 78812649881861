import { StatusConfig } from '@typings/common';
import { STATUS_CONFIG } from '@config/status';
import { PAYMENT_STATUS_TYPE } from '../../buildMyLoan.constants';

export const PAYMENT_STATUS_CONFIG: StatusConfig<PAYMENT_STATUS_TYPE> = {
  [PAYMENT_STATUS_TYPE.NOT_PAID]: STATUS_CONFIG.INFO,
  [PAYMENT_STATUS_TYPE.PAID]: STATUS_CONFIG.SUCCESS_FILLED,
  [PAYMENT_STATUS_TYPE.LINK_SENT]: STATUS_CONFIG.WARNING_FILLED,
  [PAYMENT_STATUS_TYPE.DENIED]: STATUS_CONFIG.ERROR_FILLED,
  [PAYMENT_STATUS_TYPE.ERROR]: STATUS_CONFIG.ERROR_FILLED,
};
