import { Format, SectionsConfig } from '@utils/quotes.utils';
import { RENTAL_PARTNER_ECONOMICS_CALCULATION_CONFIGS } from '@constants/rentalPECalculatuionsConfig.constants';

export const RENTAL_TERM_CALCULATION_SECTIONS_CONFIGS: SectionsConfig = [
  {
    title: 'Loan details',
    items: [
      {
        key: 'loan-to-value',
        title: 'Loan to value',
        value: 'pricingTerms.ltvPercentage',
        format: Format.Percent,
      },
      {
        key: 'loan-type',
        title: 'Loan type',
        value: 'in.productType',
      },
      {
        key: 'term',
        title: 'Term',
        value: 'pricingTerms.amortizationPeriod',
      },
      {
        key: 'number-of-properties',
        title: 'Number of properties',
        value: 'in.numOfProperties',
      },
    ],
  },
  {
    title: 'Loan terms summary',
    items: [
      {
        key: 'estimated-loan-amount',
        title: 'Estimated loan amount',
        value: 'pricingTerms.loanAmt',
        format: Format.Currency,
      },
      {
        key: 'rate',
        title: 'Rate',
        value: 'pricingTerms.initFixedInterestRate',
        format: Format.Percent,
      },
      {
        key: 'origination-fee',
        title: 'Origination fee',
        value: 'pricingTerms.originationFee',
        format: Format.Currency,
      },
      {
        key: 'rental-income',
        title: 'Rental income',
        value: 'pricingTerms.monthlyEstimatedRentalIncome',
        format: Format.Currency,
      },
      {
        key: 'rdsr',
        title: 'RDSR',
        value: 'pricingTerms.rentDebtRatio',
      },
      {
        key: 'prepayment-penalty',
        title: 'Prepayment penalty',
        value: 'in.prepaymentPenalty',
      },
    ],
  },
  {
    title: 'Estimated cash to close',
    items: [
      {
        key: 'underwriting-and-processing-fee',
        title: 'Underwriting and processing fee',
        value: 'pricingTerms.processingFee',
        format: Format.Currency,
      },
      {
        key: 'purchase',
        title: 'Purchase',
        value: 'pricingTerms.purchasePriceProperties',
        format: Format.Currency,
      },
      {
        key: 'existing-payoff',
        title: 'Existing payoff',
        value: 'pricingTerms.payoffMortgageProperties',
        format: Format.Currency,
      },
      {
        key: 'interest-reserve-escrow',
        title: 'Interest Reserve Escrow',
        value: 'pricingTerms.interestReserveEscrow',
        format: Format.Currency,
      },
      {
        key: 'estimated-3rd-party-closing-costs',
        title: 'Estimated 3rd Party Closing Costs',
        value: 'pricingTerms.estimatedClosingCost',
        format: Format.Currency,
      },
      {
        key: 'legal-fee',
        title: 'Legal Fee',
        value: 'pricingTerms.legalFee',
        format: Format.Currency,
      },
      {
        key: 'estimated-6-month-tax-impound',
        title: 'Estimated 6 month tax impound',
        value: 'pricingTerms.taxImpound',
        format: Format.Currency,
      },
      {
        key: 'estimated-6-month-insurance-impound',
        title: 'Estimated 6 month insurance impound',
        value: 'pricingTerms.insuranceImpound',
        format: Format.Currency,
      },
      {
        key: 'cash-to-from-borrower',
        title: 'Cash To/From Borrower',
        value: 'pricingTerms.closingCashToBorrower',
        format: Format.Currency,
      },
      {
        key: 'estimated-loan-amount-ectc',
        title: 'Estimated Loan Amount',
        value: 'pricingTerms.loanAmt',
        format: Format.Currency,
      },
    ],
  },
  {
    title: 'Transaction summary',
    items: [
      {
        key: 'monthly-payment-of-principal-and-interest',
        title: 'Monthly payment of principal and interest',
        value: 'pricingTerms.monthlyPrincipalInterest',
        format: Format.Currency,
      },
      {
        key: 'monthly-payment-of-taxes-and-insurance',
        title: 'Monthly payment of taxes and insurance',
        value: 'pricingTerms.monthlyTaxesInsurance',
        format: Format.Currency,
      },
      {
        key: 'monthly-payment-of-hoa-dues',
        title: 'Monthly payment of HOA Dues, Paid to HOA',
        value: 'pricingTerms.monthlyHOADues',
        format: Format.Currency,
      },
      {
        key: 'monthly-total-payment',
        title: 'Monthly total payment',
        value: 'pricingTerms.monthlyTotalPayment',
        format: Format.Currency,
        decorations: 'bold',
      },
    ],
  },
  {
    ...RENTAL_PARTNER_ECONOMICS_CALCULATION_CONFIGS,
  },
];
