import { useCallback, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Quote, QUOTE_TYPE } from '@typings/common';
import { FormData } from '@form-configs/types';
import { useUpdateLoan } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateLoan';
import { useUpdateBuildMyLoanCache } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateBuildMyLoanCache';
import { useUpdateProperties } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateProperties';
import { useOpenState } from '@hooks/useOpenState';
import { PrefilledField, QUOTE_CONFIG } from './usePrefill.constants';

export interface PrefilledResult {
  isOverride: boolean;
  fields: PrefilledField[];
}

export type ModalProps = {
  isOpen: boolean;
  confirm: () => Promise<void>;
  cancel: () => void;
};

export interface UsePrefillResult {
  prepareToPrefill: (quote: Quote) => void;
  modal: ModalProps;
  fields: PrefilledField[];
}

export const usePrefill = (
  loanMethods: ReturnType<typeof useFormContext>
): UsePrefillResult => {
  const [isOpen, open, close] = useOpenState();

  const { loanId = '' } = useParams();
  const updateLoan = useUpdateLoan();
  const updateProperty = useUpdateProperties();
  const { updateLoanCache, updateLoanPropertiesCache } =
    useUpdateBuildMyLoanCache();
  const [isOverride, setIsOverride] = useState(false);
  const [fields, setFields] = useState<PrefilledField[]>([]);

  const prepareToPrefill = useCallback((values: Quote) => {
    const quoteConfig =
      QUOTE_CONFIG[values.in?.quoteType || QUOTE_TYPE.SHORT_TERM];

    const isSingleProperty =
      get(values, quoteConfig.propertiesNumberField) === 1;
    const loanForm = loanMethods.getValues() as FormData;

    const { isOverride, fields } = quoteConfig.fields.reduce(
      (result: PrefilledResult, field) => {
        const isPropertyField = field.loanPath.includes('properties[0]');
        if (!isSingleProperty && isPropertyField) {
          return result;
        }

        const quoteValue =
          typeof field.quotePath === 'function'
            ? field.quotePath(values)
            : get(values, field.quotePath);

        const loanValue = get(loanForm, field.loanPath);
        const isValueChanged = loanValue && loanValue !== quoteValue;

        result.isOverride = result.isOverride || isValueChanged;

        if (loanValue !== quoteValue) {
          result.fields.push({
            ...field,
            quoteValue,
            loanValue,
          });
        }

        return result;
      },
      {
        isOverride: false,
        fields: [],
      }
    );

    setFields(fields);
    setIsOverride(isOverride);
  }, []);

  const prefill = useCallback(async () => {
    const { loanBody, propertyBody } = fields.reduce(
      (
        acc: {
          loanBody: Record<string, string | undefined>;
          propertyBody: Record<string, string | undefined>;
        },
        field
      ) => {
        if (field.loanPath.startsWith('loan.')) {
          const key = field.loanPath.replace('loan.', '');
          acc.loanBody[key] = field.quoteValue;
        }

        if (field.loanPath.startsWith('properties[0].')) {
          const key = field.loanPath.replace('properties[0].', '');
          acc.propertyBody[key] = field.quoteValue;
        }

        return acc;
      },
      {
        loanBody: {},
        propertyBody: {},
      }
    );

    await updateLoan(loanId, loanBody);
    updateLoanCache(loanBody);

    const loanForm = loanMethods.getValues() as FormData;
    const currentPropertyId = get(loanForm, 'properties[0].id');
    const propertyId = await updateProperty(currentPropertyId, propertyBody);
    if (propertyId) updateLoanPropertiesCache(propertyId, propertyBody);
  }, [fields]);

  const clear = useCallback(() => {
    setFields([]);
    setIsOverride(false);
  }, []);

  useEffect(() => {
    if (fields.length === 0) {
      return;
    }

    if (isOverride) {
      open();
    } else {
      prefill();
    }
  }, [fields, isOverride]);

  const cancel = useCallback(() => {
    clear();
    close();
  }, [clear, close]);

  const confirm = useCallback(async () => {
    await prefill();
    close();
  }, [prefill, close]);

  const modalProps: ModalProps = useMemo(
    () => ({
      isOpen,
      confirm,
      cancel,
    }),
    [isOpen, confirm, cancel]
  );

  return useMemo(
    () => ({
      prepareToPrefill,
      fields: fields,
      modal: modalProps,
    }),
    [fields, modalProps, prepareToPrefill]
  );
};
