import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  useDeleteApiV1LoansByLoanIdPartiesAndPartyIdMutation,
  usePostApiV1LoansByLoanIdPartiesAndPartyIdMutation,
} from '@api/output/api';
import { ROLE_TYPE } from '@typings/common';

export const getRoleByKey = (entityKey: string) => {
  if (entityKey.includes('entity')) {
    return ROLE_TYPE.PRIMARY_ENTITY;
  }

  if (entityKey.includes('borrower')) {
    return ROLE_TYPE.PRIMARY_INDIVIDUAL;
  }

  return ROLE_TYPE.OTHER_INDIVIDUAL;
};

export const usePartyAttach = () => {
  const { loanId = '' } = useParams();

  const [attach, { isLoading: isPartyAttaching }] =
    usePostApiV1LoansByLoanIdPartiesAndPartyIdMutation();

  const [detach, { isLoading: isPartyDetaching }] =
    useDeleteApiV1LoansByLoanIdPartiesAndPartyIdMutation();

  const detachParty = useCallback(async (id: string) => {
    if (id) {
      await detach({
        loanId,
        partyId: id,
      });
    }
  }, []);

  const attachParty = useCallback(
    async (
      newId?: string | null,
      oldId?: string,
      role = ROLE_TYPE.PRIMARY_ENTITY
    ) => {
      if (oldId) {
        await detach({
          loanId,
          partyId: oldId,
        });
      }

      if (newId) {
        await attach({
          loanId,
          partyId: newId,
          lolaBffApiContractsModelsPartyPartyRoleRequest: {
            role,
          },
        });
      }
    },
    [attach, detach]
  );

  return {
    attachParty,
    detachParty,
    isDetaching: isPartyDetaching,
    isSaving: isPartyAttaching || isPartyDetaching,
  };
};
