import React from 'react';
import { Grid, Typography } from '@mui/material';
import styles from './gridHelperText.module.scss';

export interface GridHelperTextProps {
  helperText?: string;
}

export const GridHelperText = ({ helperText }: GridHelperTextProps) => {
  return helperText ? (
    <Grid item sm={12} className={styles.helperText}>
      <Typography variant="pSmall">{helperText}</Typography>
    </Grid>
  ) : null;
};
