import { Control, useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { FormData } from '@form-configs/types';
import { convertArraysToObject } from '@utils/form/form.utils';
import { THIRD_PARTY_BROKER_FIELDS } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { createThirdPartyBrokerSchema } from './thirdPartyBroker.schema';

export interface UseLicenseThirdPartyCheckProps {
  control?: Control<FormData>;
  prefix?: string;
}

export const useLicenseThirdPartyCheck = ({
  control,
  prefix = '',
}: UseLicenseThirdPartyCheckProps) => {
  const fieldNames = useMemo(() => {
    return Object.values(THIRD_PARTY_BROKER_FIELDS).map(
      (field) => `${prefix}${field}`
    );
  }, [prefix]);

  const fields = useWatch({
    control: control,
    name: fieldNames,
  });

  return useMemo(() => {
    const values = convertArraysToObject(fieldNames, fields);
    const schema = createThirdPartyBrokerSchema(prefix);
    return schema.isValidSync(values);
  }, [fields]);
};
