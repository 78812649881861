import React from 'react';
// MUI
import { Grid, Typography } from '@mui/material';
//hooks
import classNames from 'classnames';
//icons
import {
  StatusAlertIcon,
  StatusDoneIcon,
  StatusInProgressIcon,
} from '@lola/ui-react-components';
// types
import { Status } from '../../UploadedFileCard.type';
// styles
import styles from './UploadedFileCardStatus.module.scss';

const STATUS_LIST = {
  [Status.APPROVED]: (
    <Grid
      className={classNames(styles.UploadedFileCardStatus, styles.approved)}
      data-testid="UploadedFileCardStatus"
    >
      <StatusDoneIcon />
      <Typography variant="pBold">
        Underwriting has accepted this file
      </Typography>
    </Grid>
  ),
  [Status.IN_REVIEW]: (
    <Grid
      className={classNames(styles.UploadedFileCardStatus, styles.review)}
      data-testid="UploadedFileCardStatus"
    >
      <StatusInProgressIcon />
      <Typography variant="pBold">Review</Typography>
    </Grid>
  ),
  [Status.DENIED]: (
    <Grid
      className={classNames(styles.UploadedFileCardStatus, styles.denied)}
      data-testid="UploadedFileCardStatus"
    >
      <StatusAlertIcon />
      <Typography variant="pBold">Underwriting has denied this file</Typography>
    </Grid>
  ),
};
export const UploadedFileCardStatus = ({
  status,
}: {
  status: Status;
}): JSX.Element => {
  return STATUS_LIST[status] || null;
};
