import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { Input } from '@lola/ui-react-components';

export interface SSNControlProps {
  label?: string;
  name: string;
  mask: (string | RegExp)[];
  disabled: boolean;
  onSSNChange: (ssn: string) => Promise<void>;
}

export interface SSNRef {
  reset: () => void;
}

export const SSNControl = forwardRef<SSNRef, SSNControlProps>(
  ({ label, name, mask, disabled, onSSNChange }: SSNControlProps, ref) => {
    const { control, setError, clearErrors } = useFormContext();
    const formSSN = useWatch({
      name,
    });

    const [localValue, setLocalValue] = useState(formSSN);

    useEffect(() => {
      setLocalValue(formSSN);
    }, [formSSN]);

    const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setLocalValue(e.target.value);
    }, []);

    const onBlur = useCallback(
      async (e: FocusEvent<HTMLInputElement>) => {
        const { value = '' } = e.target;
        if (value.length < mask.length) {
          setError(name, {}, { shouldFocus: true });
          return;
        }
        clearErrors(name);

        if (value === formSSN) {
          return;
        }

        await onSSNChange(value);
      },
      [formSSN, onSSNChange]
    );

    const reset = useCallback(() => {
      setLocalValue(formSSN);
    }, [formSSN]);

    useImperativeHandle(
      ref,
      () => {
        return { reset };
      },
      [reset]
    );

    return (
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field: { ref }, fieldState: { invalid } }) => (
          <MaskedInput
            disabled={disabled}
            mask={mask}
            onChange={onChange}
            onBlur={onBlur}
            guide={false}
            render={(maskRef, maskedProps) => (
              <Input
                inputRef={(element) => {
                  ref(element);
                  maskRef(element);
                }}
                error={invalid}
                variant="standard"
                label={label}
                value={localValue}
                placeholder="Enter Data"
                {...maskedProps}
              />
            )}
          />
        )}
      />
    );
  }
);
