import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import { ArrowLeftIcon } from '@lola/ui-react-components';

import styles from './widgetContentFooter.module.scss';

interface WidgetContentFooterProps {
  leftLabelPrefix?: number | string | null;
  leftLabelPostfix: string;
  rightLabelPrefix?: number | string | null;
  rightLabelPostfix?: string;
  withArrow?: boolean;
}

export const WidgetContentFooter = ({
  leftLabelPrefix,
  leftLabelPostfix,
  rightLabelPrefix,
  rightLabelPostfix,
  withArrow,
}: WidgetContentFooterProps) => {
  return (
    <footer
      className={classnames({
        [styles.footer]: true,
        [styles.withArrow]: withArrow,
      })}
    >
      <div>
        {leftLabelPrefix && (
          <Typography variant="pBold">{leftLabelPrefix}</Typography>
        )}
        <Typography variant="pDescription">{leftLabelPostfix}</Typography>
      </div>
      {withArrow && <ArrowLeftIcon className={styles.arrowIcon} />}
      {rightLabelPrefix && (
        <div>
          <Typography variant="pBold">{rightLabelPrefix}</Typography>
          <Typography variant="pDescription">{rightLabelPostfix}</Typography>
        </div>
      )}
    </footer>
  );
};
