interface CalcCompletedCountArg {
  value: number;
  min: number;
  max: number;
  sectionSizes: number[];
}

export const calcCompletedCount = ({
  value,
  min,
  max,
  sectionSizes,
}: CalcCompletedCountArg): number => {
  const totalSize = sectionSizes.reduce((total, current) => total + current, 0);

  const accumulatedSectionValues = sectionSizes.reduce(
    (acc, size) => {
      const diff = max - min;
      const percent = size / totalSize;
      return [...acc, Math.round(diff * percent) + acc[acc.length - 1]];
    },
    [min]
  );

  const fullyFilledSection =
    accumulatedSectionValues.findIndex(
      (sectionValue) => value <= sectionValue
    ) - 1;

  const valueDiff = value - accumulatedSectionValues[fullyFilledSection];
  const sectionDiff =
    accumulatedSectionValues[fullyFilledSection + 1] -
    accumulatedSectionValues[fullyFilledSection];

  const partiallyFilled = valueDiff / sectionDiff;

  return fullyFilledSection + partiallyFilled;
};
