import {
  LolaBffApiContractsModelsRequestsManageTeamRequest,
  LolaBffApiContractsModelsRequestsTeamAction,
  LolaBffApiContractsModelsUser,
} from '@api/output/api';
import { isManager } from '@utils/role.utils';
import { ROLES } from '@typings/common';

interface FormValuesType {
  removeFromTeam: string;
  managerUserId: string;
  destinationTeamId: string;
}

export const getChangeTeamRequest = (
  formValues: FormValuesType,
  userData: LolaBffApiContractsModelsUser
): LolaBffApiContractsModelsRequestsManageTeamRequest => {
  const { managerUserId, destinationTeamId, removeFromTeam } = formValues;
  const { userId: selectedUserId, managesTeams, role } = userData;

  const isUserManager = isManager(role as ROLES);

  const userId = isUserManager ? managerUserId : selectedUserId;
  const managerSourceTeamId =
    managesTeams && managesTeams.length > 1
      ? removeFromTeam
      : managesTeams?.[0];
  const sourceTeamId = isUserManager ? +(managerSourceTeamId as string) : null;
  const previousUserId = isUserManager ? selectedUserId : null;

  return {
    userId,
    sourceTeamId: sourceTeamId,
    destinationTeamId: +destinationTeamId,
    previousUserId,
    action: 'Change' as LolaBffApiContractsModelsRequestsTeamAction,
  };
};
