import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, AlertTitle, Grid, LinearProgress, Paper } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import merge from 'lodash/merge';
import { StatusAlertIcon, BreadcrumbConfig } from '@lola/ui-react-components';
import { Collapsed } from '@components/Collapsed';
import { SubpageHeaderComponent } from '@components/SubpageHeader/SubpageHeader.component';
import { FormData } from '@form-configs/types';
import { QUOTE_ACCORDION_VALUES, QUOTE_TYPE } from '@typings/common';
import { getLoanPurposeRentalDefaultValues } from '@utils/quotes.utils';
import { useGetScenarios } from '@components/QuoteCalculation/components/ScenarioRentalCalculation/hooks/useGetScenarios';
import { QuoteCalculation } from '@components/QuoteCalculation/QuoteCalculation.component';
import { useFileBlobDownloader } from '@hooks/useBlobFileDownloader';
import { Notification } from '@components/Notification/Notification.component';

import { ComponentsContext } from '@components/DynamicForm/contexts/Components.context';
import { usePutApiV1PricingEngineQuoteByQuoteIdChangeStatusMutation } from '@api/output/api';
import { useStageQuote } from '@pages/scenarioBuilder/pages/quoteForm/hooks/useStageQuote';
import { QuoteSummarySidebar } from './components/QuoteSummarySidebar/QuoteSummarySidebar.component';
import {
  useQuotesBuilderApi,
  useQuoteMilestoneConfig,
  useResetQuote,
} from './hooks';
import { QuoteDetails } from './components/QuoteDetails/QuoteDetails.component';
import { NewQuoteFooter } from './components/NewQuoteFooter/NewQuoteFooter.component';
import { QUOTE_TYPE_RADIO_GROUP_NAME } from './QuoteForm.types';
import {
  ELIGIBILITY,
  LOAN_PRODUCT_KEY,
  QUOTE_NAME,
  QUOTE_PATH_MAP,
  SUMMARY_QUOTE_STATUS,
} from './QuoteForm.constants';
import { QuoteSummaryInfo } from './components/QuoteSummaryInfo/QuoteSummaryInfo.component';
import styles from './quoteForm.module.scss';
import { getPdfEndpoint } from './QuoteForm.utils';

const BREADCRUMBS_NEW_QUOTE: BreadcrumbConfig[] = [
  {
    title: 'Scenario builder',
    link: '/scenario-builder',
  },
];

export const QuoteFormPage = () => {
  const { quoteType, quoteId } = useParams();
  const correctQuoteType = QUOTE_PATH_MAP[quoteType ?? 'stf'];
  const [accordionValue, setAccordionValue] =
    useState<QUOTE_ACCORDION_VALUES | null>(QUOTE_ACCORDION_VALUES.DETAILS);

  const accordionHandler =
    (panel: QUOTE_ACCORDION_VALUES) =>
    (_: React.SyntheticEvent, isExpanded: boolean) => {
      setAccordionValue(isExpanded ? panel : null);
    };

  const methods = useForm<FormData>({
    defaultValues: {
      [QUOTE_TYPE_RADIO_GROUP_NAME.QUOTE_TYPE]: correctQuoteType,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    formState: { isDirty },
  } = methods;

  const [formQuoteType, productType] = methods.watch([
    QUOTE_TYPE_RADIO_GROUP_NAME.QUOTE_TYPE,
    LOAN_PRODUCT_KEY,
  ]);
  const { save, data, isQuoteLoading, isCalculating, summary, isError } =
    useQuotesBuilderApi(formQuoteType, quoteId);

  const { isDisabledByMilestone, milestoneFieldConfig } =
    useQuoteMilestoneConfig(formQuoteType, data);

  const reset = useResetQuote(methods, formQuoteType);

  useEffect(() => {
    if (data) {
      const formCurrentValues = methods.getValues();
      const dataValues = data.in;
      const loanPurposeDefaultValues = dataValues
        ? getLoanPurposeRentalDefaultValues(dataValues)
        : {};
      const values = merge(
        {},
        formCurrentValues,
        dataValues,
        loanPurposeDefaultValues
      );
      methods.reset(values);
      setAccordionValue(
        data.out
          ? QUOTE_ACCORDION_VALUES.CALCULATION
          : QUOTE_ACCORDION_VALUES.DETAILS
      );
    }
  }, [data]);

  const breadcrumbs = useMemo(
    () => [
      ...BREADCRUMBS_NEW_QUOTE,
      {
        title: data?.in?.quoteName ?? 'New Quote',
      },
    ],
    [data]
  );

  const isSaved = !!data?.out && !!data?.in;

  const scenarios = useGetScenarios(data ?? {});
  const hasEligibleScenarios = useMemo(() => {
    return scenarios.some(
      ({ eligibility }) => eligibility === ELIGIBILITY.ELIGIBLE
    );
  }, [scenarios]);

  const isSubmitDisabled = useMemo(() => {
    const isRental = correctQuoteType === QUOTE_TYPE.RENTAL;
    const hasError = !!summary?.errors?.length;

    return (
      !isSaved || (isRental && !hasEligibleScenarios) || isDirty || hasError
    );
  }, [data, correctQuoteType, isSaved, hasEligibleScenarios, isDirty]);

  const isCalculated = !!data?.out;

  const { downloadBlobFile, isLoading: isPdfLoading } = useFileBlobDownloader();

  const submitHandler = useMemo(() => {
    return methods.handleSubmit(save);
  }, [methods.handleSubmit, quoteId, formQuoteType]);

  const generatePdfHandler = useCallback(async () => {
    const url = getPdfEndpoint(quoteId, formQuoteType);
    await downloadBlobFile(url);
  }, [quoteId, formQuoteType, downloadBlobFile]);

  const componentsContext = useMemo(
    () => ({
      licensing: () => null,
    }),
    []
  );

  const stageQuoteHandler = useStageQuote(quoteId, formQuoteType);

  const isStaged = useMemo(() => {
    return data?.quoteInfo?.isQuoteStaged;
  }, [data]);

  const summaryQuoteStatus = useMemo(() => {
    if (isStaged) {
      return SUMMARY_QUOTE_STATUS.STAGED;
    }

    if (isCalculated) {
      return SUMMARY_QUOTE_STATUS.DRAFT;
    }

    return '';
  }, [isCalculated, isStaged]);

  if (isQuoteLoading) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  if (isError) {
    return (
      <Alert severity="error" className={styles.alert}>
        <AlertTitle>Something went wrong</AlertTitle>
        Unable to load quote, please check that you are trying to open existed
        quote or try again later.
      </Alert>
    );
  }

  return (
    <>
      <SubpageHeaderComponent
        title={data?.in?.quoteName ?? 'New Quote'}
        breadcrumbs={breadcrumbs}
      />
      <div className={styles.container}>
        <section className={styles.content}>
          <FormProvider {...methods}>
            <ComponentsContext.Provider value={componentsContext}>
              <form onSubmit={submitHandler} noValidate>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4} data-cy="quote-summary-sidebar">
                    <Paper elevation={0} className={styles.sidebarPanel}>
                      <QuoteSummarySidebar
                        quoteName={QUOTE_NAME[formQuoteType as QUOTE_TYPE]}
                        status={summaryQuoteStatus}
                      />
                    </Paper>
                    {!!Object.values(summary.details ?? {}).length && (
                      <Paper
                        elevation={0}
                        className={classnames(
                          styles.sidebarPanel,
                          styles.stickyPanel
                        )}
                      >
                        <Collapsed defaultExpanded label="Calculation summary">
                          <Grid container spacing={2.5}>
                            {!!summary.errors?.length && (
                              <Grid item xs={12}>
                                <Notification
                                  severity="error"
                                  title="Calculation errors"
                                  data-cy="calculation-errors"
                                  withIcon
                                  icon={<StatusAlertIcon />}
                                  className={styles.notification}
                                >
                                  <>
                                    {summary.errors?.map((text) => (
                                      <p key={uuidv4()}>• {text}</p>
                                    ))}
                                  </>
                                </Notification>
                              </Grid>
                            )}
                            {!!summary.validationWarnings?.length && (
                              <Grid item xs={12}>
                                <Notification
                                  severity="warning"
                                  withIcon
                                  className={styles.notification}
                                >
                                  <>
                                    {Object.values(
                                      summary.validationWarnings
                                    ).map(({ title, items }) => (
                                      <QuoteSummaryInfo
                                        data-cy="validation-warnings"
                                        key={title}
                                        title={title}
                                        items={items}
                                        marker="•"
                                      />
                                    ))}
                                  </>
                                </Notification>
                              </Grid>
                            )}
                            {!!summary.LLPAs?.length && (
                              <Grid item xs={12}>
                                <Notification
                                  severity="info"
                                  withIcon
                                  className={styles.notification}
                                >
                                  <>
                                    {Object.values(summary.LLPAs).map(
                                      ({ title, items }) => (
                                        <QuoteSummaryInfo
                                          key={title}
                                          title={title}
                                          items={items}
                                          marker="•"
                                        />
                                      )
                                    )}
                                  </>
                                </Notification>
                              </Grid>
                            )}
                          </Grid>
                        </Collapsed>
                      </Paper>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={8}
                    spacing={2}
                    alignSelf="start"
                  >
                    <Grid item xs={12}>
                      <QuoteDetails
                        isDirty={isDirty}
                        quoteType={formQuoteType}
                        isCalculated={isCalculated}
                        isSaved={isSaved}
                        isCalculating={isCalculating}
                        calculationErrors={summary.errors}
                        accordionOnChange={accordionHandler}
                        accordionValue={accordionValue}
                        disabledByCheck={isDisabledByMilestone}
                        milestoneFieldConfig={milestoneFieldConfig}
                      />
                      {isCalculating ? <LinearProgress /> : null}
                    </Grid>
                    <Grid item xs={12}>
                      <QuoteCalculation
                        isCalculating={isCalculating}
                        isStaged={isStaged}
                        quoteType={formQuoteType}
                        accordionValue={accordionValue}
                        onChange={accordionHandler}
                        details={data}
                        setAccordionValue={setAccordionValue}
                        warnings={summary.warnings}
                        calculationErrors={summary.errors}
                        disabled={isDisabledByMilestone}
                        data-cy="quote-calculation"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </ComponentsContext.Provider>
          </FormProvider>
        </section>
        <NewQuoteFooter
          isCalculated={isCalculated}
          isStaged={isStaged}
          isCreateQuote={!quoteId}
          onFormClear={reset}
          isSubmitButtonDisabled={isSubmitDisabled}
          quoteId={quoteId}
          quoteType={formQuoteType}
          productType={productType}
          onGeneratePdf={generatePdfHandler}
          isPdfLoading={isPdfLoading}
          onStageQuote={stageQuoteHandler}
          data-cy="new-quote-footer"
        />
      </div>
    </>
  );
};
