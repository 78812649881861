export const formatAmountToCurrency = (
  amount: number | string | undefined
): string => {
  if (!amount) {
    return '0';
  }
  const correctedAmount = typeof amount === 'string' ? +amount : amount;
  return new Intl.NumberFormat('en-US').format(correctedAmount);
};

export const formatCurrency = (amount: number | string) => {
  const formatted = formatAmountToCurrency(amount);
  return amount >= 0 ? `$${formatted}` : `($${formatted.replace('-', '')})`;
};
