import React, { useMemo, useEffect } from 'react';
//MUI
import {
  Button,
  Grid,
  CircularProgress,
  Alert,
  AlertTitle,
} from '@mui/material';
//Libraries
import { FormProvider, useForm } from 'react-hook-form';
// types
import { InformationIcon } from '@lola/ui-react-components';
import { ActionBlock } from '@lola/ui-react-components';
import { QUOTE_TYPE, Quote } from '@typings/common';
import { FormData } from '@form-configs/types';
// Hooks
import { useLoanProgramList } from '@pages/personalLoans/hooks/useLoanProgramList';
//Form config
import { forms } from '@form-configs/output';
//Icons
//Components
import { DynamicFields, DropdownListsContext } from '@components/DynamicForm';
import { PartnerEconomics } from '@components/PartnerEconomics/PartnerEconomics.component';
// styles
import styles from './PricingCalculation.module.scss';

interface PricingCalculationProps {
  quoteId?: string | null;
  data?: Quote;
  quoteType: QUOTE_TYPE;
  isCalculating: boolean;
  save: (values: FormData) => void;
}

export const PricingCalculation = ({
  quoteId,
  quoteType,
  data,
  isCalculating,
  save,
}: PricingCalculationProps) => {
  // Hooks
  const { programs, products } = useLoanProgramList();

  // data
  const isFields = useMemo(
    () =>
      quoteType === QUOTE_TYPE.SHORT_TERM
        ? forms.shortTermPricing
        : forms.rentalPricing,
    [quoteType]
  );

  const values = useMemo(() => {
    return {
      limitedLoanProgram: programs,
      loanProgram: products,
    };
  }, [programs, products]);

  // form
  const methods = useForm<FormData>({
    defaultValues: data?.in,
  });
  const {
    formState: { errors },
    reset,
  } = methods;

  const submitHandler = useMemo(() => {
    return methods.handleSubmit((values) => {
      return save(values);
    });
  }, [methods.handleSubmit, quoteId]);

  useEffect(() => {
    reset(data?.in);
  }, [data?.in]);

  return (
    <DropdownListsContext.Provider value={values}>
      <FormProvider {...methods}>
        <form onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {Object.keys(errors).length ? (
                <Alert severity="error">
                  <AlertTitle>Incorrect input data</AlertTitle>
                  Please resolve the errors on the page below and press
                  calculate
                </Alert>
              ) : (
                <ActionBlock
                  title="Final pricing"
                  description="This pricing has been reviewed and approved by the underwriting team. Only the fields editable are able to be changed at this point."
                  icon={<InformationIcon />}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <DynamicFields fields={isFields} />
            </Grid>

            {quoteType && (
              <Grid item xs={12}>
                <PartnerEconomics quoteType={quoteType} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                disabled={isCalculating}
              >
                {isCalculating && (
                  <CircularProgress
                    variant="indeterminate"
                    color="inherit"
                    className={styles.progress}
                    size={52}
                  />
                )}
                Calculate
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </DropdownListsContext.Provider>
  );
};
