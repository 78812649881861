import { LolaBffApiContractsModelsUserStatus } from '@api/output/api';

type InvitedUserStatusMap = Partial<
  Record<LolaBffApiContractsModelsUserStatus | '', string>
>;

const INVITED_USER_NOTIFICATION_TITLE: InvitedUserStatusMap = {
  Active: 'The member has successfully logged in and is currently active',
};

const INVITED_USER_NOTIFICATION_TEXT: InvitedUserStatusMap = {
  Active: `We suggest utilizing the "forgot password" option to regain access.
  Re-send is not available for active user`,
};

export const getInvitedUserNotification = (
  status: LolaBffApiContractsModelsUserStatus | ''
) => {
  return {
    title:
      INVITED_USER_NOTIFICATION_TITLE[status] ??
      'This member was already invited',
    text:
      INVITED_USER_NOTIFICATION_TEXT[status] ??
      `We already sent the invitation letter to this email.
      Check the email or resend the invitation again.`,
  };
};
