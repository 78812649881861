export enum DRAWER_STATE {
  CLOSE = 'close',
  EXCEPTION = 'exception',
  CHANGE = 'change',
}

export const DRAWER_TITLE: Record<DRAWER_STATE, string> = {
  [DRAWER_STATE.EXCEPTION]: 'Exception request',
  [DRAWER_STATE.CHANGE]: 'Request a change',
  [DRAWER_STATE.CLOSE]: '',
};
