import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { GroupProps } from '@form-configs/types';
import styles from './dynamicCheckboxGroup.module.scss';

export const DynamicCheckboxGroup = ({ label, options = [] }: GroupProps) => {
  const { control } = useFormContext();

  return (
    <div>
      <Typography variant="p" paragraph className={styles.checkboxLabel}>
        {label}
      </Typography>
      <div className={styles.checkboxList}>
        {options.map(({ label, value }) => (
          <Controller
            key={value}
            control={control}
            name={value.toString()}
            render={({ field: { value = '', onChange, ...props } }) => (
              <FormControlLabel
                value={value}
                control={
                  <Checkbox value={value} onChange={onChange} {...props} />
                }
                label={label}
                labelPlacement="end"
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};
