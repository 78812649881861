import React from 'react';
import { Format, SectionConfig } from '@utils/quotes.utils';
import { CalculationBlockDivider } from '@components/CaclulationBlockDivider/CalculationBlockDivider.component';

export const RENTAL_PARTNER_ECONOMICS_CALCULATION_CONFIGS: SectionConfig = {
  title: 'Partner economics',
  items: [
    {
      key: 'origination-points',
      title: 'Origination points',
      decorations: 'boldGray',
    },
    {
      key: 'partner',
      title: 'Partner',
      value: 'out.scenarios[0].lenderFinanceOriginationPoints',
      format: Format.Currency,
    },
    {
      key: 'broker-fee',
      title: 'Broker',
      value: 'out.scenarios[0].brokerFee',
      format: Format.Currency,
    },
    {
      key: 'divider-key',
      itemRenderer: () => <CalculationBlockDivider />,
    },
    {
      key: 'interest-rate-add-on',
      title: 'Interest Rate Add-On',
      value: 'out.scenarios[0].lenderFinanceFrontEndYieldSpreadPremium',
      format: Format.Percent,
    },
    {
      key: 'application-fee',
      title: 'Application Fee',
      value: 'out.scenarios[0].lenderFinanceApplicationFee',
      format: Format.Currency,
    },
    {
      key: 'processing-fee',
      title: 'Processing Fee',
      value: 'out.scenarios[0].lenderFinanceProcessingFee',
      format: Format.Currency,
    },
    {
      key: 'document-fee',
      title: 'Document Fee',
      value: 'out.scenarios[0].lenderFinanceDocumentFee',
      format: Format.Currency,
    },
    {
      key: 'appraisal-fee',
      title: 'Appraisal Fee',
      value: 'out.scenarios[0].lenderFinanceAppraisalFee',
      format: Format.Currency,
    },
    {
      key: 'divider-key1',
      itemRenderer: () => <CalculationBlockDivider my={1} />,
    },
    {
      key: 'total-key',
      title: 'Total',
      format: Format.Currency,
      value: 'out.scenarios[0].totalLenderFinanceFees',
      decorations: 'bold',
    },
  ],
};
