import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { loadSpace } from '@usersnap/browser';
import { store } from './store/store';
import reportWebVitals from './reportWebVitals';

import './index.scss';
import { App } from './app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// In case of approve from Wade we should move this key to the process env and redefine for every instance.
const spaceApiKey = 'e00d7a3a-add7-4852-902b-b163c4edf335';

loadSpace(spaceApiKey).then((api) => {
  api.init();
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
