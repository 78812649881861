import React from 'react';
import { Chip } from '@mui/material';
import { LolaBffApiContractsModelsUser } from '@api/output/api';
import { USER_STATUS } from '@typings/common';

export const userStatusRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsUser
) => {
  const { status } = row;

  if (status === USER_STATUS.ARCHIVED || status === USER_STATUS.NOT_SET) {
    return (
      <Chip
        color="info"
        size="small"
        variant="outlined"
        disabled
        label={status}
      />
    );
  }

  if (status === USER_STATUS.ACTIVE) {
    return (
      <Chip color="success" size="small" variant="filled" label={status} />
    );
  }

  if (status === USER_STATUS.INVITED) {
    return (
      <Chip color="warning" size="small" variant="filled" label={status} />
    );
  }

  return null;
};
