import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { CommonError } from '@typings/common';
import { useOpenToast } from '@hooks/useOpenToast';
import {
  useLazyGetApiV1LoansQuery,
  usePostApiV1LoansByLoanIdManageUsersMutation,
} from '@api/output/api';
import { ConfirmModal } from '@components/Modal';
import { prepareOperations } from './manageAccessConfirm.utils';

interface ManageAccessConfirmProps {
  handleClose: () => void;
  handleDrawerClose: () => void;
}

export const ManageAccessConfirm = ({
  handleClose,
  handleDrawerClose,
}: ManageAccessConfirmProps) => {
  const [load, { isFetching }] = useLazyGetApiV1LoansQuery();
  const [manageAccessUpdater, { isLoading }] =
    usePostApiV1LoansByLoanIdManageUsersMutation();

  const { handleSubmit } = useFormContext();

  const handleActionAfterResponse = useCallback(() => {
    handleClose();
    handleDrawerClose();
  }, [handleClose, handleDrawerClose]);

  const openToast = useOpenToast();

  const handleSubmitClick = useCallback(
    handleSubmit((values) => {
      const { manageAccess } = values;
      manageAccessUpdater({
        loanId: manageAccess.loanId,
        lolaBffApiContractsModelsLoanManageLoanUsersRequest: {
          operations: prepareOperations(manageAccess),
        },
      })
        .unwrap()
        .then(() => load())
        .then(() => {
          openToast({
            title: 'Changes applied!',
            description: 'Your changes have been applied.',
          });
          handleActionAfterResponse();
        })
        .catch((err: CommonError) => {
          const errorMessage = JSON.stringify(err.data?.detail);

          openToast({
            isError: true,
            title: 'Error occurred',
            description: errorMessage,
          });
          handleActionAfterResponse();
        });
    }),
    [handleSubmit]
  );

  return (
    <ConfirmModal
      isUpdating={isLoading || isFetching}
      handleSubmit={handleSubmitClick}
      handleCancel={handleClose}
    />
  );
};
