import React from 'react';
import { Paper } from '@mui/material';
import { Collapsed } from '@components/Collapsed';
import { forms } from '@form-configs/output';
import { PartyForm } from '@pages/personalLoans/pages/buildMyLoan/pages/applicationAndEntityInformation/components/PartForm/PartyForm.component';
import { NewExistingSelector } from '../../../../../../components/NewExistingSelector/NewExistingSelector.component';
import styles from './entityBlock.module.scss';

export const EntityBlock = () => {
  return (
    <Collapsed label="Entity" defaultExpanded>
      <NewExistingSelector
        entityType="entity"
        entityKey="party.entity"
        autoOpen={true}
        major={true}
        newComponent={
          <Paper variant="filled" className={styles.entity}>
            <PartyForm fields={forms.entityBlock} entityKey="party.entity" />
          </Paper>
        }
      />
    </Collapsed>
  );
};
