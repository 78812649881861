import { Grid, Paper } from '@mui/material';
import { NavLink, Outlet } from 'react-router-dom';
import React from 'react';
import { useWatch, UseFormTrigger } from 'react-hook-form';
import { KanbanIcon } from '@lola/ui-react-components';
import styles from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.module.scss';
import { ProgressStepper } from '@components/ProgressStepper/ProgressStepper.component';
import { ProgressIndicatorType } from '@components/ProgressStepper/types';
import { LoanFooter } from '@pages/personalLoans/components';
import { useBuildMyLoanSteps } from '@pages/personalLoans/pages/buildMyLoan/hooks/useBuildMyLoanSteps';
import { useActiveStepRoute } from '@pages/personalLoans/hooks/useActiveStepRoute';
import { useStepsNavigation } from '@pages/personalLoans/hooks/useStepsNavigation';
import { useStepChangeInterceptor } from '@pages/personalLoans/hooks/useStepChangeInterceptor';
import { Notification } from '@components/Notification/Notification.component';
import { isLoanDisabled } from '@pages/personalLoans/pages/buildMyLoan/utils/loan.utils';
import { BuildMyLoanFormData } from '../../buildMyLoan.type';

export interface BuildMyLoanContainerProps {
  triggerValidation: UseFormTrigger<BuildMyLoanFormData>;
  isDirty?: boolean;
}

export const BuildMyLoanContainer = ({
  triggerValidation,
  isDirty,
}: BuildMyLoanContainerProps) => {
  const milestone = useWatch({ name: 'loan.dealMetadata.milestone' });
  const loanDisabledState = isLoanDisabled(milestone);
  const buildMyLoanSteps = useBuildMyLoanSteps();
  const activeStep = useActiveStepRoute(buildMyLoanSteps);
  const { currentStep, goToNextStep, goToPreviousStep } =
    useStepsNavigation<BuildMyLoanFormData>(
      buildMyLoanSteps,
      triggerValidation,
      isDirty
    );

  const {
    goToNextStepHandler,
    goToPreviousStepHandler,
    stepChangeInterceptorRenderer,
  } = useStepChangeInterceptor({
    currentStep,
    goToNextStep,
    goToPreviousStep,
  });

  return (
    <>
      <section className={styles.section}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            {loanDisabledState && (
              <Notification
                severity="warning"
                title="Build my loan is completed"
                text="You cannot make changes to the &#34;Build my loan&#34; section.
              You can only view completed forms."
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={0} className={styles.stepperWidget}>
              <ProgressStepper
                progressIndicatorType={ProgressIndicatorType.LINEAR}
                items={buildMyLoanSteps}
                headerTitle="Build my Loan"
                activeItem={activeStep}
                headerIcon={
                  <NavLink to="../">
                    <KanbanIcon />
                  </NavLink>
                }
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Outlet context={buildMyLoanSteps} />
          </Grid>
        </Grid>
      </section>
      <LoanFooter
        currentStep={currentStep}
        goToNextStep={goToNextStepHandler}
        goToPreviousStep={goToPreviousStepHandler}
      />
      {stepChangeInterceptorRenderer}
    </>
  );
};
