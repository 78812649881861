import React from 'react';
import { Divider } from '@mui/material';

import { Navigation } from '@containers/Header/components/Navigation/Navigation.component';
import { UserProfileLine } from './components/UserProfileLine/UserProfileLine.component';
import styles from './header.component.module.scss';

export interface HeaderProps {
  title: string;
  logoUrl: string;
}

export const Header = ({ title, logoUrl }: HeaderProps) => {
  return (
    <>
      <section className={styles.header}>
        <section>
          <img src={logoUrl} alt={title} className={styles.logo} />
          <Navigation />
        </section>

        <section className={styles.rightBlock}>
          <UserProfileLine />
        </section>
      </section>
      <Divider />
    </>
  );
};
