import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { GridHelperText } from '@components/GridHelperText/GridHelperText.component';
import { Notification } from '@components/Notification/Notification.component';
import { LolaBffApiContractsModelsUserStatus } from '@api/output/api';
import { getInvitedUserNotification } from './InviteDefaultPart.utils';
import styles from './inviteDefaultPart.module.scss';

interface InviteDefaultPartProps {
  isUserInvited: boolean;
  invitedUserStatus: LolaBffApiContractsModelsUserStatus | '';
}

export const InviteDefaultPart = ({
  isUserInvited,
  invitedUserStatus,
}: InviteDefaultPartProps) => {
  const {
    control,
    formState: { errors },
    resetField,
    clearErrors,
  } = useFormContext();

  useEffect(() => {
    if (isUserInvited) {
      resetField('role');
      clearErrors(['role', 'firstName', 'lastName']);
    }
  }, [isUserInvited, resetField, clearErrors]);

  const { title, text, isInvitedUserActive } = useMemo(
    () => ({
      isInvitedUserActive: invitedUserStatus === 'Active',
      ...getInvitedUserNotification(invitedUserStatus),
    }),
    [invitedUserStatus]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LabeledCard label="Name">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="firstName"
                control={control}
                render={({ field: { value = '', ...props } }) => (
                  <TextField
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message as string}
                    placeholder="Enter data"
                    label="Name"
                    value={value}
                    disabled={isInvitedUserActive}
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="lastName"
                control={control}
                render={({ field: { value = '', ...props } }) => (
                  <TextField
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message as string}
                    placeholder="Enter data"
                    label="Last name"
                    value={value}
                    disabled={isInvitedUserActive}
                    {...props}
                  />
                )}
              />
            </Grid>
            <GridHelperText helperText="Fill in the first and last names exactly as they appear in your passport. This information will be used when you create your user account." />
          </Grid>
        </LabeledCard>
      </Grid>
      <Grid item xs={12}>
        <LabeledCard label="Contact email">
          <Grid>
            <Grid item>
              <Controller
                name="email"
                control={control}
                render={({ field: { value = '', ...props } }) => (
                  <TextField
                    placeholder="name@email.com"
                    error={!!errors.email}
                    helperText={errors.email?.message as string}
                    label="Email"
                    value={value}
                    {...props}
                  />
                )}
              />
            </Grid>
            <GridHelperText helperText="A user will receive an invitation to this email." />
            {isUserInvited && (
              <Notification
                title={title}
                text={text}
                severity="warning"
                withIcon
                withBorder
                className={styles.notification}
              />
            )}
          </Grid>
        </LabeledCard>
      </Grid>
    </Grid>
  );
};
