import React, { forwardRef, useMemo } from 'react';
import classnames from 'classnames';
import {
  FormControl,
  FormHelperText,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import { LockedIcon, Label } from '@lola/ui-react-components';
import { Option } from '@typings/common';
import styles from './selectWithPlaceholder.module.scss';

export interface SelectWithPlaceholderProps extends SelectProps<string> {
  placeholder: string;
  selectOptions?: Option[];
  helperText?: string;
  optionalHelperText?: string;
  locked?: boolean;
}

const renderValue = (
  value: string | string[] | number,
  placeholder: string,
  selectOptions?: Option[]
) => {
  if (typeof value !== 'number' && !value?.length) {
    return (
      <Typography className={styles.placeholder}>{placeholder}</Typography>
    );
  }

  if (Array.isArray(value)) {
    return <Typography>{`${value.length} selected`}</Typography>;
  }

  const selectedOption = selectOptions?.find(
    (option) => option.value === value
  );

  return selectedOption?.label ?? '';
};

export const SelectWithPlaceholder = forwardRef(
  (
    {
      placeholder,
      label,
      value,
      children,
      helperText,
      selectOptions,
      disabled,
      required,
      error,
      optionalHelperText,
      locked,
      ...selectProps
    }: SelectWithPlaceholderProps,
    ref
  ) => {
    const selectClasses = classnames({
      [styles.error]: error,
      [styles.locked]: locked,
    });

    const customProps = useMemo(() => {
      if (locked) {
        return {
          disabled: true,
          IconComponent: LockedIcon,
        };
      }

      return {};
    }, [locked]);
    return (
      <FormControl fullWidth={true} disabled={disabled}>
        <Label
          text={label ?? ''}
          error={error}
          required={required}
          optionalHelperText={optionalHelperText}
          className={styles.label}
          disabled={disabled}
        />
        <Select
          inputRef={ref}
          fullWidth={true}
          displayEmpty
          value={value}
          className={selectClasses}
          renderValue={(value) =>
            renderValue(value, placeholder, selectOptions)
          }
          disabled={disabled}
          {...selectProps}
          {...customProps}
        >
          {children}
        </Select>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);
