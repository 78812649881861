import React, { ReactElement, useMemo } from 'react';
import { Button, Tooltip, ButtonProps } from '@mui/material';

export interface ButtonWithTooltipProps extends ButtonProps {
  tooltip?: string;
}

export const ButtonWithTooltip = ({
  tooltip,
  children,
  ...props
}: ButtonWithTooltipProps) => {
  const Wrapper = useMemo(() => {
    if (tooltip) {
      return ({ children }: { children: ReactElement }) => (
        <Tooltip title={tooltip}>{children}</Tooltip>
      );
    }

    return React.Fragment;
  }, [tooltip]);

  return (
    <Wrapper>
      <Button {...props}>{children}</Button>
    </Wrapper>
  );
};
