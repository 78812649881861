import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { getApiValue } from '@utils/quotes.utils';
import { LeverageFormItem } from '@components/QuoteCalculation/components/LeverageCalculations/LeverageCalculations.type';
import { Quote } from '@typings/common';
import styles from './leverageFormBlockItem.module.scss';

interface LeverageFormBlockItemProps {
  item: LeverageFormItem;
  details?: Quote;
}

export const LeverageFormBlockItem = ({
  item,
  details,
}: LeverageFormBlockItemProps) => {
  const value = useMemo(
    () => (details ? getApiValue(item, details) : ''),
    [item, details]
  );

  return (
    <div className={styles.wrapper}>
      <Typography className={styles.valueDescription} variant="p">
        {item.title}
      </Typography>
      <Typography className={styles.value} variant="pBold">
        {value}
      </Typography>
    </div>
  );
};
