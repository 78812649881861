import { useMemo } from 'react';
import { useRole } from '@hooks/useRole';
import { useGetApiV1UsersMeQuery } from '@api/output/api';
import { useLoadUsers } from '@hooks/useLoadUsers';
import { ROLES, TEAM_ASSIGNMENT_STATUS } from '@typings/common';
import { useUserAssignmentStatus } from '@hooks/useUserAssignmentStatus';
import { isUserAssignedToTeam } from '@utils/isUserAssignedToTeam.utils';

export const useLoadMembers = () => {
  const role = useRole();
  const userAssignmentStatus = useUserAssignmentStatus();
  const {
    data: manager,
    isLoading: isUserLoading,
    isError: managerError,
  } = useGetApiV1UsersMeQuery();
  const { users, isLoading, isError: usersError } = useLoadUsers({});

  const members = useMemo(() => {
    return role === ROLES.BROKER_MANAGER
      ? users?.filter(({ teamId, userId, managesTeams, role }) => {
          const hasTeam = isUserAssignedToTeam(managesTeams, teamId);

          return (
            manager?.managesTeams?.includes(teamId?.toString() as string) ||
            userId === manager?.userId ||
            (userAssignmentStatus === TEAM_ASSIGNMENT_STATUS.UNASSIGNED &&
              role !== ROLES.PROCESSOR &&
              !hasTeam)
          );
        })
      : users;
  }, [manager, users]);

  return {
    members,
    isLoading: isLoading || isUserLoading,
    isError: managerError || usersError || !users || !manager,
  };
};
