import keyBy from 'lodash/keyBy';
import dayjs from 'dayjs';
import {
  LolaBffApiContractsModelsLoanDocumentTask,
  LolaBffApiContractsModelsLoanTaskInfo,
  LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto,
} from '@api/output/api';
import {
  Step,
  VERTICAL_STATUS_STATE,
} from '@components/verticalStepDetail/VerticalStepDetail.component';
import { LoanPartyInfo, TASK_COMPLETION_STATUS } from '@typings/common';
import {
  CREDIT_SCORE_TASK_STATUS,
  CreditScoreIndividual,
} from '../CreditScore.types';
import {
  CREDIT_SCORE_STEP_TITLES,
  CREDIT_SCORE_TASK_COMPLETION_STATUS_MAP,
} from '../CreditScore.constants';

export const getCreditScoreStepStatus = (
  completionStatus?: TASK_COMPLETION_STATUS
): VERTICAL_STATUS_STATE => {
  if (
    completionStatus &&
    CREDIT_SCORE_TASK_COMPLETION_STATUS_MAP[completionStatus]
  ) {
    return CREDIT_SCORE_TASK_COMPLETION_STATUS_MAP[completionStatus];
  }

  return VERTICAL_STATUS_STATE.LOCKED;
};

export const calculateCreditScoreValue = (item: CreditScoreIndividual) => {
  const { equifaxScore, experianScore, transUnionScore } = item || {};
  const scores: number[] = [
    equifaxScore,
    experianScore,
    transUnionScore,
  ].reduce((result: number[], item: string | null | undefined) => {
    if (item !== null && item !== undefined) {
      const score = +item;
      if (!isNaN(score)) {
        result.push(score);
      }
    }
    return result;
  }, [] as number[]);

  if (scores.length === 1) {
    return scores[0];
  }

  if (scores.length === 3) {
    scores.sort((a, b) => a - b);
    return scores[1];
  }

  return 0;
};

const COMPLETION_MAP: Record<string, string> = {
  Success: 'Done on',
  DEFAULT: 'Updated on',
};

export const calculateStep = (
  step: string,
  status?: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto
): Step => {
  if (status) {
    const date = status.updatedAt ?? status.createdAt;
    const verb =
      COMPLETION_MAP[status.completionStatus ?? ''] || COMPLETION_MAP.DEFAULT;

    const changeStatus = date
      ? `${verb} ${dayjs(date).format('D MMM YY')}`
      : '';

    return {
      step,
      lastChange: changeStatus,
      currentState: getCreditScoreStepStatus(
        status?.completionStatus as TASK_COMPLETION_STATUS
      ),
    };
  }

  return {
    step,
    currentState: VERTICAL_STATUS_STATE.LOCKED,
  };
};
export const getStateAndLastChange = (
  task: LolaBffApiContractsModelsLoanTaskInfo | undefined
) => {
  const documentCreatedAt = task?.documents?.[0]?.createdAt;
  const formattedDate = documentCreatedAt
    ? `Done on ${dayjs(documentCreatedAt).format('D MMM YY')}`
    : '';

  return {
    lastChange: formattedDate,
    currentState: VERTICAL_STATUS_STATE.DONE,
  };
};

export const isReissueEnabled = (
  taskReissue?: LolaBffApiContractsModelsLoanTaskInfo,
  taskNewOrder?: LolaBffApiContractsModelsLoanTaskInfo
): boolean => {
  const documentReissue =
    taskReissue?.documents?.[taskReissue?.documents.length - 1];
  const documentNewOrder =
    taskNewOrder?.documents?.[taskNewOrder?.documents.length - 1];

  if (!documentReissue?.createdAt) return false;
  if (!documentNewOrder?.createdAt) return true;

  const documentReissueDate = new Date(
    new Date(documentReissue.createdAt).getTime()
  );
  const documentNewOrderDate = new Date(
    new Date(documentNewOrder.createdAt).getTime()
  );

  return documentReissueDate > documentNewOrderDate;
};
const getIndividualSteps = (
  taskStatusData: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto[],
  item: LoanPartyInfo
) => {
  const filteredStatuses = taskStatusData?.filter(
    (status) => status.entity2Id === item.id
  );
  const statuses: Record<
    string,
    LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto
  > = keyBy(filteredStatuses, 'taskType');

  const authorizationStep = calculateStep(
    CREDIT_SCORE_STEP_TITLES[CREDIT_SCORE_TASK_STATUS.AUTHORIZATION],
    statuses[CREDIT_SCORE_TASK_STATUS.AUTHORIZATION]
  );
  const generateReissueStep = calculateStep(
    CREDIT_SCORE_STEP_TITLES[CREDIT_SCORE_TASK_STATUS.REISSUE_GENERATION],
    statuses[CREDIT_SCORE_TASK_STATUS.REISSUE_GENERATION]
  );
  const paymentStep = calculateStep(
    CREDIT_SCORE_STEP_TITLES[CREDIT_SCORE_TASK_STATUS.PAYMENT],
    statuses[CREDIT_SCORE_TASK_STATUS.PAYMENT]
  );
  const generationStep = calculateStep(
    CREDIT_SCORE_STEP_TITLES[CREDIT_SCORE_TASK_STATUS.GENERATION],
    statuses[CREDIT_SCORE_TASK_STATUS.GENERATION]
  );
  const resultStep = calculateStep(
    CREDIT_SCORE_STEP_TITLES[CREDIT_SCORE_TASK_STATUS.RESULT],
    statuses[CREDIT_SCORE_TASK_STATUS.RESULT]
  );

  return {
    authorizationStep,
    generateReissueStep,
    paymentStep,
    generationStep,
    resultStep,
  };
};

export const getIndividualConfig = (
  reissueTasks?: LolaBffApiContractsModelsLoanDocumentTask[],
  reportTasks?: LolaBffApiContractsModelsLoanDocumentTask[],
  existedEntityId?: string | null
) => {
  const taskNewOrder = reportTasks?.find(
    ({ entityId }) => entityId === existedEntityId
  );
  const taskReissue = reissueTasks?.find(
    ({ entityId }) => entityId === existedEntityId
  );

  const isReissue = isReissueEnabled(taskReissue?.task, taskNewOrder?.task);

  return {
    isReissue,
    task: isReissue ? taskReissue : taskNewOrder,
  };
};

export const getCreditScoreActors = (
  individuals: LoanPartyInfo[],
  taskStatusData: LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto[],
  reissueTasks?: LolaBffApiContractsModelsLoanDocumentTask[],
  reportTasks?: LolaBffApiContractsModelsLoanDocumentTask[]
) => {
  if (!individuals.length) return null;

  return individuals.map((item) => {
    const {
      authorizationStep,
      generateReissueStep,
      paymentStep,
      generationStep,
      resultStep,
    } = getIndividualSteps(taskStatusData, item);

    const { isReissue, task } = getIndividualConfig(
      reissueTasks,
      reportTasks,
      item.id ?? ''
    );

    const documents = task?.task?.documents;

    const isCreditReportGenerated =
      paymentStep?.currentState === VERTICAL_STATUS_STATE.LOCKED &&
      !!documents?.length;

    const steps: Step[] = [isReissue ? generateReissueStep : authorizationStep];

    if (
      (isCreditReportGenerated &&
        authorizationStep.currentState === VERTICAL_STATUS_STATE.DONE) ||
      isReissue
    ) {
      steps.push({
        ...resultStep,
        ...getStateAndLastChange(task?.task),
      });
    } else {
      steps.push(paymentStep, generationStep, resultStep);
    }

    const displayName = item?.displayName?.trim();
    const fullName = item?.fullName?.trim();

    return {
      id: item?.id ?? '',
      name: fullName ?? displayName ?? '',
      description: item.relationRole,
      score: item.lola?.creditReportLatestMedianScore ?? 0,
      hasGeneratedDocument: !!documents?.length,
      steps: steps.filter((step): step is Step => step !== null),
    };
  });
};
