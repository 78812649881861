import cloneDeep from 'lodash/cloneDeep';
import { forms } from '@form-configs/output';
import { ControlType } from '@form-configs/types';
import { updateLabel } from './FullApplicationForm.utils';

const [{ items = [] }] = cloneDeep(forms.primaryGuarantor);

const guarantorsItems = updateLabel(
  items,
  'party.borrower',
  'party.guarantors[]'
);

export const guarantorsForm = [
  {
    controlType: 'list' as ControlType,
    id: 'list1',
    props: {
      autoTitlePattern: 'Guarantor #[]',
      addButtonText: 'Add a co-guarantor',
      startFrom: 1,
      label: 'Guarantors',
      name: 'party.guarantors',
    },
    items: [
      {
        controlType: 'custom' as ControlType,
        id: 'custom1',
        props: {
          controlType: 'guarantorNewExisting',
        },
        items: guarantorsItems,
      },
    ],
  },
];
