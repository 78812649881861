import { FooterAction, FooterActionConfig } from './ContentSectionFooter.types';

export const DEFAULT_ACTION_PROPS: Record<
  FooterAction,
  Omit<FooterActionConfig, 'buttonAction'>
> = {
  cancel: {
    variant: 'text',
    size: 'large',
    label: 'Cancel',
  },
  submit: {
    variant: 'contained',
    type: 'submit',
    size: 'large',
    label: 'Submit',
  },
  button: {
    variant: 'contained',
    size: 'large',
  },
};
