import React from 'react';
import { Typography } from '@mui/material';
import { ScenarioProps } from '../../ScenarioRentalCalculation.types';

export const ScenarioInterestRate = ({ scenario }: ScenarioProps) => {
  const { initFixedInterestRate } = scenario;

  return (
    <>
      <Typography>{initFixedInterestRate}%</Typography>
    </>
  );
};
