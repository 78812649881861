import { AUTHORIZATION_TYPE } from './authorization.types';

export const AUTHORIZATION_TYPE_OPTIONS = [
  {
    label: 'Send link to guarantors',
    value: AUTHORIZATION_TYPE.LINKS,
  },
  {
    label: 'Upload authorization',
    value: AUTHORIZATION_TYPE.UPLOAD,
  },
];
