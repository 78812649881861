import { LolaBffApiContractsModelsUser } from '@api/output/api';

export const prepareForm = (data?: LolaBffApiContractsModelsUser) => {
  return {
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    email: data?.email,
    userMetadata: {
      phoneNumber: data?.userMetadata?.phoneNumber ?? '',
      receiveCall: data?.userMetadata?.receiveCall,
      receiveEmail: data?.userMetadata?.receiveEmail,
    },
  };
};
