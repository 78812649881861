import { DEFAULT_UPLOADER_CONFIG } from '@components/Uploader/Uploader.constants';
import { DEFAULT_UPLOADER_DRAWER_CONFIG } from '@components/UploadDrawer/uploaderDrawer.constants';

export const UPLOADER_CONFIG = {
  ...DEFAULT_UPLOADER_CONFIG,
  description: 'PDF, JPEG, JPG, Excel, less than 20mb',
  rules: {
    ...DEFAULT_UPLOADER_CONFIG.rules,
    allowedExtensions: {
      rule: ['pdf', 'jpeg', 'jpg', 'xlsx'],
      message: 'Wrong file extension',
    },
  },
};

export const DRAWER_CONFIG = {
  ...DEFAULT_UPLOADER_DRAWER_CONFIG,
  title: 'Application documents',
  description: 'Upload your application documents',
};
