import { FieldConfig } from '@form-configs/types';

export const convertFieldsToIndexedFields = (
  fields: FieldConfig[],
  index: number
) => {
  return fields.map((field) => {
    const newField = {
      ...field,
      props: { ...field.props },
    };

    if (field.items) {
      newField.items = convertFieldsToIndexedFields(field.items, index);
    }

    if (field?.props?.name) {
      newField.props.name = field.props.name.replace(
        '[]',
        `[${index.toString()}]`
      );
    }

    return newField;
  });
};

export const getTitle = (
  index: number,
  patternTitle: string,
  startFrom: number,
  firstTitle?: string
) => {
  return index === 0 && firstTitle
    ? firstTitle
    : patternTitle.replace('[]', (startFrom + index).toString());
};
