import React from 'react';
import { Typography } from '@mui/material';
import { LolaBffApiContractsModelsDocumentLockerDocumentInfo } from '@api/output/api';
import styles from './documentInfoRenderer.module.scss';

export const documentInfoRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsDocumentLockerDocumentInfo
) => {
  const { fileName, size } = row;

  const fileParts = fileName?.split('.') || [];
  const extension = fileParts.pop()?.toUpperCase();
  const name = fileParts.join('.');

  return (
    <div className={styles.wrapper}>
      <Typography paragraph={true} variant="pBold">
        {name}
      </Typography>
      <Typography
        className={styles.description}
        paragraph={true}
        variant="pSmall"
      >
        {extension} • {size}
      </Typography>
    </div>
  );
};
