import { Option } from '@typings/common';
import { EntityType } from './NewExistingSelector.types';

export enum OPTIONS_VALUE {
  NEW = 'new',
  EXISTED = 'existed',
}

const DEFAULT_OPTIONS: Option[] = [
  {
    label: 'Yes',
    value: OPTIONS_VALUE.EXISTED,
  },
  {
    label: 'No',
    value: OPTIONS_VALUE.NEW,
  },
];

const ENTITY_OPTIONS: Option[] = [
  {
    label: 'Select existing',
    value: OPTIONS_VALUE.EXISTED,
  },
  {
    label: 'Create new',
    value: OPTIONS_VALUE.NEW,
  },
];

export const OPTIONS: Record<EntityType, Option[]> = {
  primaryGuarantor: DEFAULT_OPTIONS,
  guarantor: DEFAULT_OPTIONS,
  entity: ENTITY_OPTIONS,
};

export const MODAL_TEXT: Partial<Record<EntityType, string>> = {
  primaryGuarantor:
    "You cannot select %name% as a primary guarantor. You've already selected him/her as a guarantor. Please select another person",
  guarantor:
    "You cannot select %name% as a guarantor. You've already selected him/her as a primary guarantor. Please select another person",
};
