import { useFormContext } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { PROGRAM_KEY } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { QUOTE_TYPE } from '@typings/common';
import { CalculatePayloadValues } from '@hooks/useQuotesApi/quotes.types';
import {
  LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest,
  LolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest,
} from '@api/output/api';
import { useOnChange } from '@pages/personalLoans/pages/buildMyLoan/hooks/useOnChange';
import { DEFAULT_SCENARIOS } from '@hooks/useQuotesApi/quotes.constants';
import { getLoanPurposeRentalDefaultValues } from '@utils/quotes.utils';
import { QUOTE_PRODUCT_KEY } from '../productPage.constants';

export const useLoanToQuoteMapper = (
  loanMethods: ReturnType<typeof useFormContext>,
  quoteMethods: ReturnType<typeof useFormContext>,
  quoteType: QUOTE_TYPE,
  inData?: CalculatePayloadValues
) => {
  const onChange = useOnChange(loanMethods);

  const [
    loanProgramType,
    borrowingEntityName = '',
    primaryGuarantorFirstName = '',
    primaryGuarantorLastName = '',
  ] = loanMethods.watch([
    PROGRAM_KEY,
    'party.entity.fullName',
    'party.borrower.firstName',
    'party.borrower.lastName',
  ]);

  const loanProduct = quoteMethods.watch(QUOTE_PRODUCT_KEY);

  const primaryGuarantorFullName = useMemo(() => {
    return primaryGuarantorFirstName || primaryGuarantorLastName
      ? `${primaryGuarantorFirstName} ${primaryGuarantorLastName}`
      : '';
  }, [primaryGuarantorFirstName, primaryGuarantorLastName]);

  /* map data from quote to loan */
  const quoteInData = useMemo(() => {
    if (quoteType === QUOTE_TYPE.RENTAL) {
      const rentalData = (inData ?? {
        quoteType: QUOTE_TYPE.RENTAL,
        scenario: DEFAULT_SCENARIOS,
      }) as LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest;
      const loanPurposeDefaultValues =
        getLoanPurposeRentalDefaultValues(rentalData);

      return {
        ...rentalData,
        ...loanPurposeDefaultValues,
        borrowingEntity: borrowingEntityName
          ? borrowingEntityName
          : rentalData.borrowingEntity,
      };
    } else {
      const stfData = (inData ?? {
        quoteType: QUOTE_TYPE.SHORT_TERM,
      }) as LolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest;

      return {
        ...stfData,
        borrowerInfo: {
          ...stfData.borrowerInfo,
          borrowingEntity: borrowingEntityName
            ? borrowingEntityName
            : stfData.borrowerInfo?.borrowingEntity,
          primaryGuarantor: primaryGuarantorFullName
            ? primaryGuarantorFullName
            : stfData.borrowerInfo?.primaryGuarantor,
        },
        loanSummary: {
          ...stfData.loanSummary,
          loanProduct: loanProgramType,
        },
      };
    }
  }, [inData, quoteType, borrowingEntityName, primaryGuarantorFullName]);

  useEffect(() => {
    if (loanProgramType) {
      quoteMethods.setValue(QUOTE_PRODUCT_KEY, loanProgramType);
    }
  }, [loanProgramType]);

  /* map data from quote to loan */
  useEffect(() => {
    if (loanProduct) {
      loanMethods.setValue(PROGRAM_KEY, loanProduct);
      onChange?.(PROGRAM_KEY, loanProduct);
    }
  }, [loanProduct]);

  return {
    quoteType,
    quoteInData,
  };
};
