import dayjs from 'dayjs';
import { ValidationValueMessage } from 'react-hook-form';
import { VALIDATION_DEFAULT_MESSAGES } from '@components/DynamicForm/utils/validation.types';

export const processNoEmptyRule = (value?: string | number) => {
  if (typeof value === 'number' && !isNaN(value)) {
    return true;
  }
  return value ? value.toString().trim().length > 0 : false;
};

export const processMaxLengthRule = (maxLength: number) => (value?: string) =>
  value ? value.length <= maxLength : false;

export const processMinDateRule =
  (minDate: Date | string) => (value?: string) => {
    const date = typeof minDate === 'string' ? new Date(minDate) : minDate;
    if (isNaN(date.getTime())) {
      return true;
    }
    return value ? dayjs(value).diff(dayjs(date), 'day') >= 0 : false;
  };

export function generateRequiredValidator(
  args: unknown[]
): ValidationValueMessage<boolean> {
  return {
    value: true,
    message: (args[0] as string) || VALIDATION_DEFAULT_MESSAGES.REQUIRED,
  };
}

export function generateMinMaxValidator(
  args: unknown[]
): ValidationValueMessage<number> {
  return {
    value: args[0] as number,
    message: (args[1] as string) || VALIDATION_DEFAULT_MESSAGES.VALUE,
  };
}

export function generatePatternValidator(
  args: unknown[]
): ValidationValueMessage<RegExp> {
  return {
    value: new RegExp(args[0] as string),
    message: (args[1] as string) || VALIDATION_DEFAULT_MESSAGES.VALUE,
  };
}

export function generateMaxLengthValidator(
  args: unknown[]
): ValidationValueMessage<number> {
  return {
    value: args[0] as number,
    message: (args[1] as string) || VALIDATION_DEFAULT_MESSAGES.LENGTH,
  };
}

export function generateMinDateValidator(args: unknown[]): {
  minDate: (value: string) => boolean | string;
} {
  const minDateRule = processMinDateRule(args[0] as string);
  const message =
    args.length > 1 ? (args[1] as string) : VALIDATION_DEFAULT_MESSAGES.DATE;
  return {
    minDate: (value: string) => (minDateRule(value) ? true : message),
  };
}
