import { useMemo } from 'react';
import { QUOTE_TYPE } from '@typings/common';
import {
  useLazyGetApiV1PricingEngineQuoteByQuoteIdRentalQuery,
  useLazyGetApiV1PricingEngineQuoteByQuoteIdStfQuery,
} from '@api/output/api';
import {
  FeesReturnValue,
  useQuoteFeesData,
} from '@hooks/useQuotesApi/useQuoteFeesData';

export type GetRentalHook = ReturnType<
  typeof useLazyGetApiV1PricingEngineQuoteByQuoteIdRentalQuery
>;
export type GetSTFHook = ReturnType<
  typeof useLazyGetApiV1PricingEngineQuoteByQuoteIdStfQuery
>;

export type GetHandler = GetRentalHook[0] | GetSTFHook[0];
export type UseGetApiReturnValue = [GetHandler, FeesReturnValue];

export const useQuoteGetApi = (quoteType: QUOTE_TYPE): UseGetApiReturnValue => {
  const [getRental, getRentalResult] =
    useLazyGetApiV1PricingEngineQuoteByQuoteIdRentalQuery();

  const [getStf, getStfResult] =
    useLazyGetApiV1PricingEngineQuoteByQuoteIdStfQuery();

  const apiResult = useMemo(
    () =>
      quoteType === QUOTE_TYPE.SHORT_TERM ? getStfResult : getRentalResult,
    [getRentalResult, getStfResult]
  );

  const result = useQuoteFeesData(apiResult);

  return useMemo(() => {
    return quoteType === QUOTE_TYPE.SHORT_TERM
      ? [getStf, result]
      : [getRental, result];
  }, [quoteType, getStf, getRental, result]);
};
