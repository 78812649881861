import React from 'react';
import classnames from 'classnames';
// libraries
import { v4 as uuidv4 } from 'uuid';
// styles
import styles from './VerticalStepDetail.module.scss';

export enum VERTICAL_STATUS_STATE {
  LOCKED = 'Locked',
  DONE = 'Done',
  IN_PROGRESS = 'InProgress',
  ERROR = 'Error',
}

export interface Step {
  step: string | null;
  lastChange?: string | null;
  lastChangeLabel?: string | null;
  currentState?: VERTICAL_STATUS_STATE | null;
}

export interface VerticalStepDetailProps {
  data: Step[];
}

const LIST_MAP = {
  [VERTICAL_STATUS_STATE.IN_PROGRESS]: styles.elementInProgress,
  [VERTICAL_STATUS_STATE.ERROR]: styles.elementError,
  [VERTICAL_STATUS_STATE.DONE]: styles.elementDone,
  [VERTICAL_STATUS_STATE.LOCKED]: styles.elementLocked,
};

export const VerticalStepDetail = ({
  data,
}: VerticalStepDetailProps): JSX.Element => {
  // data
  const lasElement = data.length - 1;

  return (
    <section className={styles.container}>
      {data?.map((element, index) => {
        const {
          step,
          lastChange,
          lastChangeLabel,
          currentState = VERTICAL_STATUS_STATE.LOCKED,
        } = element;

        const statusClassName = currentState
          ? LIST_MAP[currentState]
          : LIST_MAP.Locked;

        return (
          <div
            key={uuidv4()}
            className={classnames(styles.element, statusClassName)}
          >
            <div className={styles.dotContainer}>
              <span className={styles.dot} />
              {index !== lasElement && <span className={styles.line} />}
            </div>
            <p>
              {step}
              <br />
              <small>{lastChange}</small>
              {lastChangeLabel && (
                <label className={styles.newConditionsLabel}>
                  {' '}
                  {lastChangeLabel}
                </label>
              )}
            </p>
          </div>
        );
      })}
    </section>
  );
};
