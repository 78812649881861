import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDeleteApiV1LoansByLoanIdPartiesAndPartyIdMutation } from '@api/output/api';
import { useOpenToast } from '@hooks/useOpenToast';
import { useUpdateBuildMyLoanCache } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateBuildMyLoanCache';

export const useOnRemoveParty = () => {
  const [removeParty] = useDeleteApiV1LoansByLoanIdPartiesAndPartyIdMutation();
  const { deletePartyCacheByIndex, deletePartyCacheById } =
    useUpdateBuildMyLoanCache();
  const { loanId = '' } = useParams();

  const openToast = useOpenToast();
  const successHandler = useCallback(() => {
    openToast({
      title: 'Relationship is removed.',
    });
  }, []);

  const errorHandler = useCallback((err: Error & { error?: string }) => {
    openToast({
      isError: true,
      title: 'Cannot remove relationship',
      description: `${err.message ?? err.error}`,
    });
  }, []);

  return useCallback(async (name: string, id?: string, index?: number) => {
    const paths = name.split('.');
    const apiName = paths.shift();

    /* first name in the key can be used for identifying properly model for update: party / loan / property */
    if (apiName === 'party') {
      if (id) {
        try {
          await removeParty({
            partyId: id,
            loanId,
          }).unwrap();
          deletePartyCacheById(id);
          successHandler();
        } catch (e) {
          errorHandler(e as Error);
        }
      } else if (index != null) {
        deletePartyCacheByIndex(index);
      }
    }
  }, []);
};
