import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { AddIcon, StatusAlertIcon, Drawer } from '@lola/ui-react-components';
import { useRole } from '@hooks/useRole';
import { LICENSE_STATUS_TYPE, ROLES } from '@typings/common';
import { Notification } from '@components/Notification/Notification.component';
import {
  LolaBffApiContractsModelsLicensesLicense,
  useLazyGetApiV1LicensesQuery,
} from '@api/output/api';
import { LicenseUpdateDrawer, LicensingList } from './components';
import {
  LICENSING_DRAWER_TITLE,
  LICENSING_DRAWER_TYPE,
} from './LicensingInfo.costants';

export const LicensingInfoPage = () => {
  const [currentLicense, setCurrentLicense] =
    useState<LolaBffApiContractsModelsLicensesLicense | null>(null);
  const [getLicenses, { data, isFetching, isError }] =
    useLazyGetApiV1LicensesQuery();
  const role = useRole();
  const [drawerType, setDrawerType] = useState<LICENSING_DRAWER_TYPE>(
    LICENSING_DRAWER_TYPE.CLOSE
  );

  useEffect(() => {
    getLicenses();
  }, []);

  const selectedStates = useMemo(
    () => (data ? data.map(({ stateCode }) => stateCode) : []),
    [data]
  );

  const { showExpired, showExpiring } = useMemo(() => {
    if (!data) {
      return {
        showExpired: false,
        showExpiring: false,
      };
    }

    return data.reduce(
      (acc, license) => {
        if (license.status === LICENSE_STATUS_TYPE.EXPIRED) {
          acc.showExpired = true;
        } else if (license.status === LICENSE_STATUS_TYPE.EXPIRED_IN_30_DAYS) {
          acc.showExpiring = true;
        }
        return acc;
      },
      {
        showExpired: false,
        showExpiring: false,
      }
    );
  }, [data]);

  const closeDrawer = useCallback(
    () => setDrawerType(LICENSING_DRAWER_TYPE.CLOSE),
    []
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2">Licensing info</Typography>
        </Grid>
        {role !== ROLES.KEY_BROKER_MANAGER && (
          <Grid item xs={12}>
            <Notification
              severity="warning"
              text="Contact your admin broker if any of the below information is incorrect."
              withIcon
            />
          </Grid>
        )}
        {showExpired && (
          <Grid item xs={12}>
            <Notification
              severity="error"
              text="You have a license expired"
              withIcon
              icon={<StatusAlertIcon />}
            />
          </Grid>
        )}
        {showExpiring && (
          <Grid item xs={12}>
            <Notification
              severity="warning"
              text="You have a license expiring within 30 days"
              withIcon
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <LicensingList
            data={data}
            setDrawerType={setDrawerType}
            isFetching={isFetching}
            isError={isError}
            getLicenses={getLicenses}
            currentLicense={currentLicense}
            setCurrentLicense={setCurrentLicense}
          />
        </Grid>
        {role === ROLES.KEY_BROKER_MANAGER && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setDrawerType(LICENSING_DRAWER_TYPE.ADD)}
              disabled={!data || data.length >= 12}
            >
              Add license
            </Button>
          </Grid>
        )}
      </Grid>
      <Drawer
        isOpen={drawerType !== LICENSING_DRAWER_TYPE.CLOSE}
        title={LICENSING_DRAWER_TITLE[drawerType]}
        onClose={closeDrawer}
      >
        <LicenseUpdateDrawer
          selectedStates={selectedStates}
          closeDrawer={closeDrawer}
          getLicenses={getLicenses}
          drawerType={drawerType}
          currentLicense={currentLicense}
        />
      </Drawer>
    </>
  );
};
