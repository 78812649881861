import { LolaBffApiContractsModelsLoanOperation } from '@api/output/api';
import { ACTION_TYPE } from '../Processors/components/ActionCard/ActionCard.constants';

interface Processor {
  id: string;
  action: ACTION_TYPE;
  newProcessorId: string;
}

interface Broker {
  currentBrokerId: string;
  newBrokerId: string;
}

interface PrepareOperationsArg {
  broker: Broker;
  newAssignedProcessorId: string;
  processors: Processor[];
}

export const prepareOperations = ({
  broker,
  newAssignedProcessorId,
  processors,
}: PrepareOperationsArg): LolaBffApiContractsModelsLoanOperation[] => {
  const operations = [];

  if (broker.newBrokerId) {
    operations.push(
      {
        name: 'broker/remove',
        userId: broker.currentBrokerId,
      },
      {
        name: 'broker/set',
        userId: broker.newBrokerId,
      }
    );
  }

  if (newAssignedProcessorId) {
    operations.push({
      name: 'processor/add',
      userId: newAssignedProcessorId,
    });
  }

  processors.forEach(({ id, action, newProcessorId }) => {
    if (action === ACTION_TYPE.REMOVE) {
      operations.push({
        name: action,
        userId: id,
      });
    }
    if (action === ACTION_TYPE.ADD && newProcessorId) {
      operations.push(
        {
          name: ACTION_TYPE.ADD,
          userId: newProcessorId,
        },
        {
          name: ACTION_TYPE.REMOVE,
          userId: id,
        }
      );
    }
  });

  return operations;
};
