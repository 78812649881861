import { createContext, FocusEvent } from 'react';

export type FieldPropFunction = (e: FocusEvent<HTMLElement>) => void;

export type FieldsPropsContextValue = Record<string, FieldProp[]>;

export interface FieldProp {
  propName: string;
  value: number | string | boolean | FieldPropFunction;
}

export const DEFAULT_CONTEXT: FieldsPropsContextValue = {};

export const FieldsPropsContext =
  createContext<FieldsPropsContextValue>(DEFAULT_CONTEXT);
