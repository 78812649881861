import { useState, useEffect } from 'react';

export const useVersionCheck = (intervalCheck: number): boolean => {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const checkIfNewVersion = async () => {
      const mainJsElement = document.querySelector(
        'script[src*="/static/js/main."]'
      );

      if (mainJsElement) {
        const jsFilePath = mainJsElement.getAttribute('src') ?? '';
        try {
          await fetch(jsFilePath, { cache: 'no-cache' });
          timeoutId = setTimeout(checkIfNewVersion, intervalCheck);
        } catch {
          setNewVersionAvailable(true);
          clearInterval(timeoutId);
        }
      }
    };

    checkIfNewVersion();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [intervalCheck]);

  return newVersionAvailable;
};
