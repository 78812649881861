import React, { PropsWithChildren, useMemo } from 'react';
import { useFieldsProps } from '@pages/personalLoans/pages/buildMyLoan/pages/product/hooks';
import { QUOTE_TYPE } from '@typings/common';
import { FieldsPropsContext } from '@components/DynamicForm/contexts/FieldsProps.context';

export interface LimitedFieldPropsContextProps extends PropsWithChildren {
  quoteType: QUOTE_TYPE;
}

export const LimitedFieldPropsContext = ({
  quoteType,
  children,
}: LimitedFieldPropsContextProps) => {
  const fieldsProps = useFieldsProps(quoteType);

  const contextValue = useMemo(() => {
    return {
      ...fieldsProps,
    };
  }, [fieldsProps]);

  return (
    <FieldsPropsContext.Provider value={contextValue}>
      {children}
    </FieldsPropsContext.Provider>
  );
};
