import React, { useMemo } from 'react';
import {
  TaskFilesUploadDrawer,
  TaskFilesUploadDrawerProps,
} from '@containers/TaskFilesUploadDrawer/TaskFilesUploadDrawer.component';
import { useUploadComplete } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUploadComplete';
import {
  LolaBffApiContractsModelsLoanTaskInfo,
  LolaBffApiContractsModelsTaskDocumentTemplateTemplateInfo,
} from '@api/output/api';
import { UploaderDrawerConfig } from '@components/UploadDrawer/uploaderDrawer.types';

export type LimitedTaskFilesUploadDrawerProps = Omit<
  TaskFilesUploadDrawerProps,
  'taskId' | 'template' | 'uploadedFiles'
>;

export interface BuildMyLoanUploaderDrawerProps
  extends LimitedTaskFilesUploadDrawerProps {
  task?: LolaBffApiContractsModelsLoanTaskInfo;
  templates?: LolaBffApiContractsModelsTaskDocumentTemplateTemplateInfo[];
  templatesAreBeingFetched?: boolean;
}

export const BuildMyLoanUploaderDrawer = ({
  task,
  drawerConfig,
  ...props
}: BuildMyLoanUploaderDrawerProps) => {
  const completeAsync = useUploadComplete();

  const adoptedDrawerConfig: UploaderDrawerConfig = useMemo(
    () => ({
      headerTitle: drawerConfig?.headerTitle ?? 'Upload',
      title: drawerConfig?.title ?? task?.taskName ?? 'Document',
      description: drawerConfig?.description,
    }),
    [task, drawerConfig]
  );

  return (
    <TaskFilesUploadDrawer
      {...props}
      taskId={task?.taskId ?? undefined}
      uploadedFiles={task?.documents ?? []}
      drawerConfig={adoptedDrawerConfig}
      completeOperationAsync={completeAsync}
    />
  );
};
