import React, { useMemo } from 'react';
import { Paper } from '@mui/material';
import { VERTICAL_STATUS_STATE } from '@components/verticalStepDetail/VerticalStepDetail.component';
import { CREDIT_SCORE_STEP_TITLES } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/components/CreditScore/CreditScore.constants';
import { CREDIT_SCORE_TASK_STATUS } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/components/CreditScore/CreditScore.types';
import { StepInfoItem } from './components/StepInfoItem/StepInfoItem.component';
import { CreditScoreResult } from './components/CreditScoreResult/CreditScoreResult.component';

import styles from './dynamicStepInfo.module.scss';

interface DynamicStepInfoProps {
  step: string;
  currentState: VERTICAL_STATUS_STATE;
  hasGeneratedDocument?: boolean;
  score?: number;
  onOpenModal?: () => void;
}

export const DynamicStepInfo = ({
  step,
  score,
  currentState,
  onOpenModal,
  hasGeneratedDocument,
}: DynamicStepInfoProps) => {
  const component = useMemo(() => {
    if (step !== CREDIT_SCORE_STEP_TITLES[CREDIT_SCORE_TASK_STATUS.RESULT]) {
      return <StepInfoItem step={step} currentState={currentState} />;
    }

    return (
      <CreditScoreResult
        onOpenModal={onOpenModal}
        score={score}
        hasGeneratedDocument={hasGeneratedDocument}
      />
    );
  }, [step, currentState, score, onOpenModal, hasGeneratedDocument]);

  return (
    <Paper elevation={3} className={styles.container}>
      {component}
    </Paper>
  );
};
