import { TableColumnType } from 'antd';
import { get } from 'react-hook-form';
import { SorterItemData, getSortingResult } from '@lola/ui-react-components';
import { LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse } from '@api/output/api';
import { ActionsMenuProps } from '@pages/scenarioBuilder/pages/quotes/components/QuotesList/components';
import { actionsRenderer } from './renderers/tableCell';

type Column =
  TableColumnType<LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse>;

export const updateActionsColumnRenderer =
  (
    onOpenModal: ActionsMenuProps['onOpenModal'],
    onCopyQuote: ActionsMenuProps['onCopyQuote'],
    onStageQuote: ActionsMenuProps['onStageQuote']
  ) =>
  (columns: Column[]): Column[] =>
    columns.map((column) =>
      column.key === 'actions'
        ? {
            ...column,
            render: (_, quote) =>
              actionsRenderer({
                onOpenModal,
                onCopyQuote,
                onStageQuote,
                quote,
              }),
          }
        : column
    );

export const quoteStatusSorter =
  <T extends SorterItemData>(key: string) =>
  (itemA: T, itemB: T) => {
    const a = get(itemA, key) || '';
    const b = get(itemB, key) || '';

    return getSortingResult(b, a);
  };
