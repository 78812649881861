import { LOAN_PDF_APPLICATION_TYPE, LOAN_PROGRAM } from '@typings/common';

export const getLoanPDFApplicationType = (
  loanType: LOAN_PROGRAM
): LOAN_PDF_APPLICATION_TYPE => {
  switch (loanType) {
    case LOAN_PROGRAM.FIX_N_FlIP:
    case LOAN_PROGRAM.BRIDGE_PLUS:
      return LOAN_PDF_APPLICATION_TYPE.FIX_N_FLIP_BRIDGE_APP;
    case LOAN_PROGRAM.NEW_CONSTRUCTION:
      return LOAN_PDF_APPLICATION_TYPE.NEW_CONSTRUCTION_APP;
    case LOAN_PROGRAM.RENTAL_30:
      return LOAN_PDF_APPLICATION_TYPE.RENTAL_SINGLE_30APP;
    default:
      return LOAN_PDF_APPLICATION_TYPE.FIX_N_FLIP_BRIDGE_APP;
  }
};
