import React from 'react';
import {
  Drawer,
  IconButton,
  LinearProgress,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import { CloseIcon } from '@lola/ui-react-components';
import { ContentSectionHeader } from '@components/ContentSection';
import { Uploader } from '@components/Uploader/Uploader.component';
import {
  DEFAULT_UPLOADER_CONFIG,
  UPLOADER_STATE,
} from '@components/Uploader/Uploader.constants';
import {
  UploaderConfig,
  FileItem,
  OnUploadType,
  FileDeleteHandler,
} from '@components/Uploader/Uploader.types';
import { UploaderDrawerConfig } from '@components/UploadDrawer/uploaderDrawer.types';
import { DEFAULT_UPLOADER_DRAWER_CONFIG } from '@components/UploadDrawer/uploaderDrawer.constants';
import {
  UPLOAD_STATUS,
  UPLOAD_STATUS_CONFIG,
  UPLOAD_TYPE,
} from '@constants/uploader.constants';
import { StatusChip } from '@components/StatusChip/StatusChip.component';
import { NotificationProps } from '@components/Notification/Notification.component';
import { LolaBffApiContractsModelsTaskDocumentTemplateTemplateInfo } from '@api/output/api';
import { TemplateDownload } from './components/TemplateDownload/TemplateDownload.component';

import styles from './uploadDrawer.module.scss';

export interface UploadDrawerProps {
  type?: UPLOAD_TYPE;
  status?: UPLOAD_STATUS;
  files?: FileItem[];
  uploaderState: UPLOADER_STATE;
  setUploaderSate?: (arg: UPLOADER_STATE) => void;
  isOpen?: boolean;
  closeHandler?: () => void;
  uploadHandler?: OnUploadType;
  deleteHandler?: FileDeleteHandler;
  submitHandler?: () => Promise<void>;
  uploaderConfig?: UploaderConfig;
  drawerConfig?: UploaderDrawerConfig;
  notification?: NotificationProps;
  comments?: string | null;
  submitDisabled?: boolean;
  templates?: LolaBffApiContractsModelsTaskDocumentTemplateTemplateInfo[];
  templatesAreBeingFetched?: boolean;
}

export const UploadDrawer = ({
  type = UPLOAD_TYPE.STANDARD,
  status,
  files,
  uploaderState,
  setUploaderSate,
  isOpen,
  closeHandler,
  uploadHandler,
  deleteHandler,
  submitHandler,
  uploaderConfig = DEFAULT_UPLOADER_CONFIG,
  drawerConfig = DEFAULT_UPLOADER_DRAWER_CONFIG,
  notification,
  comments,
  submitDisabled,
  templates,
  templatesAreBeingFetched,
}: UploadDrawerProps) => {
  return (
    <Drawer
      open={isOpen}
      hideBackdrop={true}
      anchor="right"
      data-testid="upload-drawer"
      classes={{
        paper: styles.drawer,
      }}
    >
      <ContentSectionHeader
        title={drawerConfig.headerTitle ?? ''}
        actions={
          <IconButton onClick={closeHandler} title="Close document uploader">
            <CloseIcon />
          </IconButton>
        }
      />
      <Box padding={4}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {drawerConfig.title && (
              <Typography variant="h3" className={styles.title}>
                {drawerConfig.title}
                <StatusChip
                  status={status}
                  config={UPLOAD_STATUS_CONFIG[type]}
                />
              </Typography>
            )}
            {drawerConfig.description && (
              <Typography paragraph className={styles.text}>
                {drawerConfig.description}
              </Typography>
            )}
          </Grid>
          {templatesAreBeingFetched && (
            <Grid item xs={12}>
              <LinearProgress data-cy="linear-progress" />
            </Grid>
          )}
          <Grid item xs={12}>
            {!!templates?.length && <TemplateDownload templates={templates} />}
          </Grid>
          <Grid item xs={12}>
            <Uploader
              state={uploaderState}
              files={files}
              onUpload={uploadHandler}
              onDelete={deleteHandler}
              uploaderConfig={uploaderConfig}
              notification={notification}
              status={status}
              comments={comments}
              setUploaderSate={setUploaderSate}
              type={type}
              submitHandler={submitHandler}
              submitDisabled={submitDisabled}
              submitButtonText={uploaderConfig.submitButtonText}
            />
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};
