import React from 'react';
import { ENTITY_TYPE } from '../SSNField.constants';
import {
  ReplaceModalContent,
  ReplaceModalContentProps,
} from './ReplaceModalContent.component';
import {
  SaveModalContent,
  SaveModalContentProps,
} from './SaveModalContent.component';

export enum MODAL_MODE {
  CREATE,
  REPLACE,
}

export type SaveModalProps = ReplaceModalContentProps &
  SaveModalContentProps & { mode: MODAL_MODE } & { entityType: ENTITY_TYPE };

export const ModalContent = ({
  mode,
  ssn,
  existedUserName,
  entityType,
}: SaveModalProps) => {
  if (mode === MODAL_MODE.CREATE) {
    return <SaveModalContent ssn={ssn} entityType={entityType} />;
  }

  if (mode === MODAL_MODE.REPLACE) {
    return (
      <ReplaceModalContent
        ssn={ssn}
        existedUserName={existedUserName}
        entityType={entityType}
      />
    );
  }

  return null;
};
