import { get } from 'react-hook-form';
import { SorterItemData } from '@lola/ui-react-components';
import { CompareFn } from '@typings/common';

export const createAscendingSorter =
  <F extends CompareFn<number | null>, T extends SorterItemData>(
    key: string,
    sorterFn: F
  ) =>
  (itemA: T, itemB: T) => {
    const fieldA = get(itemA, key);
    const fieldB = get(itemB, key);

    const dateA = fieldA ? new Date(fieldA).getTime() : null;
    const dateB = fieldB ? new Date(fieldB).getTime() : null;
    return sorterFn(dateA, dateB);
  };

export const createDescendingSorter =
  <F extends CompareFn<number | null>, T extends SorterItemData>(
    key: string,
    sorterFn: F
  ) =>
  (itemA: T, itemB: T) => {
    const fieldA = get(itemA, key);
    const fieldB = get(itemB, key);

    const dateA = fieldA ? new Date(fieldA).getTime() : null;
    const dateB = fieldB ? new Date(fieldB).getTime() : null;
    return sorterFn(dateB, dateA);
  };
