import { Option } from '@typings/common';
import { getTemplateString } from '@utils/text.utils';

interface ConvertToOptionsArg {
  input: unknown[];
  idKey: string;
  valueKeys: string[];
  startEndSymbols?: string;
  descriptionMap?: Record<string, string>;
}

export const convertToOptions = ({
  input,
  idKey,
  valueKeys,
  startEndSymbols,
  descriptionMap,
}: ConvertToOptionsArg): Option[] => {
  const inputData = input as Record<string, string | number | boolean>[];

  return inputData.reduce((result: Option[], item) => {
    const labels = valueKeys
      .map((valueKey) => `${item[valueKey] || ''}`)
      .filter((label) => label);
    const label = getTemplateString(labels, startEndSymbols);
    const value =
      typeof item[idKey] === 'boolean'
        ? undefined
        : (item[idKey] as string | number);
    const disabled = !!item.disabled;

    if (label && value) {
      const resultItem: Option = {
        label,
        value,
        disabled,
      };

      if (descriptionMap && descriptionMap[String(value)]) {
        resultItem.description = descriptionMap[String(value)];
      }

      result.push(resultItem);
    }

    return result;
  }, []);
};
