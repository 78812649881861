import { useMemo } from 'react';
import { Quote, QUOTE_TYPE } from '@typings/common';
import { checkApproveMyLoanStep } from '@utils/loanMilestone.util';
import { LolaBffApiContractsModelsLoanLoanMilestone } from '@api/output/api';
import { APPROVE_MY_LOAN_LOCKED_FIELDS } from '../QuoteForm.constants';

export const useQuoteMilestoneConfig = (type: QUOTE_TYPE, data?: Quote) => {
  const { isSubmittedInLoan, loanMilestone = '' } = data?.quoteInfo ?? {};

  return useMemo(() => {
    const isApproveMyLoan = checkApproveMyLoanStep(
      loanMilestone as LolaBffApiContractsModelsLoanLoanMilestone
    );
    const isDisabledByMilestone = isSubmittedInLoan && !isApproveMyLoan;

    let milestoneFieldConfig = {};

    if (isApproveMyLoan) {
      milestoneFieldConfig = APPROVE_MY_LOAN_LOCKED_FIELDS[type];
    }

    return {
      isDisabledByMilestone,
      milestoneFieldConfig,
    };
  }, [isSubmittedInLoan, loanMilestone, type]);
};
