import { useMemo } from 'react';
import { useTasksByName } from '@pages/personalLoans/pages/buildMyLoan/hooks/useTasksByName';
import { CONFIGURED_TASKS, TASK_SECTIONS } from '@typings/common';

export const useCreditTasks = () => {
  const reissueTasks = useTasksByName(
    TASK_SECTIONS.CREDIT_REPORT,
    CONFIGURED_TASKS.CREDIT_REISSUE
  );
  const reportTasks = useTasksByName(
    TASK_SECTIONS.CREDIT_REPORT,
    CONFIGURED_TASKS.CREDIT_REPORT
  );

  return useMemo(
    () => [reissueTasks, reportTasks],
    [reissueTasks, reportTasks]
  );
};
