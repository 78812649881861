import React, { FocusEvent, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputControl } from '@lola/ui-react-components';
import { TextFieldProps } from '@form-configs/types';
import { useEventHandlerWrapper } from '@components/DynamicForm/hooks/useEventHandlerWrapper';
import { useFieldSpecificProps } from '@components/DynamicForm/hooks/useFieldSpecificProps';
import useFieldValidation from '@components/DynamicForm/hooks/useFieldValidation';
import { useFormulaValue } from '../../hooks/useFormulaValue';

import { FormConfigContext } from '../../contexts/FormConfig.context';

export interface DynamicTextFieldProps extends TextFieldProps {
  type: 'text' | 'number';
}

export const DynamicTextField = ({
  name,
  formula,
  type,
  label,
  placeholder,
  mask,
  disabled,
  multiline = false,
  rows = undefined,
  variant = 'standard',
  locked,
  optionalHelperText = 'Optional',
  helperText,
  validation,
  required,
  max,
  defaultValue,
  sync,
  controlType,
  ...inputProps
}: DynamicTextFieldProps) => {
  const { setValue, watch } = useFormContext();
  const syncValue = sync ? watch(sync) : undefined;
  const fieldSpecificProps = useFieldSpecificProps(name);
  const { disabled: globalDisabled, onBlur } = useContext(FormConfigContext);
  const createChangeWrapper = useEventHandlerWrapper<
    FocusEvent<HTMLInputElement>
  >(name, onBlur);
  const calculatedValue = useFormulaValue(formula);
  const isDisabled = globalDisabled ?? disabled;

  useEffect(() => {
    const currentInputValue = watch(name);
    if (defaultValue !== undefined && !currentInputValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue, watch]);

  useEffect(() => {
    if (calculatedValue !== undefined) {
      setValue(name, calculatedValue);
    }
  }, [calculatedValue, name, setValue]);

  useEffect(() => {
    if (sync && syncValue !== undefined) {
      setValue(name, syncValue);
    }
  }, [sync, syncValue, name, setValue]);

  const { convertedRules, requiredProp } = useFieldValidation({
    validation,
    locked,
    required,
    controlType,
  });

  return (
    <InputControl
      rules={convertedRules}
      name={name}
      type={type}
      label={label}
      value={calculatedValue}
      disabled={isDisabled}
      placeholder={placeholder}
      mask={mask}
      locked={locked}
      multiline={multiline}
      rows={rows}
      variant={variant}
      defaultValue={defaultValue as string | number}
      inputProps={{
        ...inputProps,
        max,
        'data-cy': name.split('.')[1] + '-input',
      }}
      onBlurCreator={createChangeWrapper}
      required={requiredProp}
      optionalHelperText={optionalHelperText}
      helperText={helperText}
      {...fieldSpecificProps}
    />
  );
};
