import React from 'react';
import {
  Alert as MuiAlert,
  AlertColor,
  AlertProps,
  AlertTitle,
  Typography,
} from '@mui/material';
import classnames from 'classnames';
import { ICON } from './Notification.constants';

import styles from './notification.module.scss';

export interface NotificationProps {
  severity?: AlertProps['severity'];
  title?: string;
  text?: string;
  className?: string;
  withIcon?: boolean;
  withBorder?: boolean;
  actionButton?: JSX.Element | null;
  icon?: JSX.Element;
  children?: JSX.Element;
}

export const Notification = ({
  severity,
  title,
  text,
  className,
  withIcon = false,
  withBorder = false,
  icon,
  actionButton,
  children,
}: NotificationProps) => {
  return (
    <MuiAlert
      severity={severity}
      icon={withIcon && (icon ?? ICON[severity as AlertColor])}
      action={actionButton}
      className={classnames(styles.notification, {
        [className as string]: !!className,
        [styles.successBorder]: withBorder && severity === 'success',
        [styles.warningBorder]: withBorder && severity === 'warning',
        [styles.errorBorder]: withBorder && severity === 'error',
      })}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {text && <Typography>{text}</Typography>}
      {children}
    </MuiAlert>
  );
};
