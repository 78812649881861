import { LEGAL_ROLES, ROLES, Option } from '@typings/common';
import { ROLES_MAP } from '@constants/users.constants';

export const Roles: Record<LEGAL_ROLES, Option> = {
  [ROLES.KEY_BROKER_MANAGER]: {
    value: ROLES.KEY_BROKER_MANAGER,
    label: ROLES_MAP[ROLES.KEY_BROKER_MANAGER],
  },
  [ROLES.BROKER_MANAGER]: {
    value: ROLES.BROKER_MANAGER,
    label: ROLES_MAP[ROLES.BROKER_MANAGER],
  },
  [ROLES.BROKER]: {
    value: ROLES.BROKER,
    label: ROLES_MAP[ROLES.BROKER],
  },
  [ROLES.PROCESSOR]: {
    value: ROLES.PROCESSOR,
    label: ROLES_MAP[ROLES.PROCESSOR],
  },
};

export const KEY_BROKER_MANAGER_OPTIONS_LIST = [
  Roles[ROLES.KEY_BROKER_MANAGER],
  Roles[ROLES.BROKER_MANAGER],
  Roles[ROLES.BROKER],
  Roles[ROLES.PROCESSOR],
];

export const BROKER_MANAGER_OPTIONS_LIST = [
  Roles[ROLES.BROKER],
  Roles[ROLES.PROCESSOR],
];

export const OPTIONS_MAP: Record<string, Option[]> = {
  [ROLES.KEY_BROKER_MANAGER]: KEY_BROKER_MANAGER_OPTIONS_LIST,
  [ROLES.BROKER_MANAGER]: BROKER_MANAGER_OPTIONS_LIST,
};
