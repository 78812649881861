import { useCallback, useState, useEffect } from 'react';
//Constants
import { MODE_SWITCHER_VALUES } from '../ManageConditions.constants';

type DrawerState = {
  open: boolean;
  conditionId: string;
  isOpenModal: boolean;
  isExceptionRequest: boolean;
};

interface ManageConditionProps {
  refetch?: () => void;
}

export const useManageCondition = ({ refetch }: ManageConditionProps) => {
  // states
  const [activeButton, setActiveButton] = useState<string | null>();
  const [drawerState, setDrawerState] = useState<DrawerState>({
    open: false,
    conditionId: '',
    isOpenModal: false,
    isExceptionRequest: false,
  });
  const [mode, setMode] = useState<MODE_SWITCHER_VALUES | string | number>(
    MODE_SWITCHER_VALUES.ALL
  );
  const [startFinalUnderwriting, setStartFinalUnderwriting] =
    useState<boolean>(false);

  const [statusSectionOpen, setStatusSectionOpen] = useState<boolean>(false);

  // actions states
  const openPanel = useCallback((conditionId: string) => {
    setDrawerState((prevState) => ({
      ...prevState,
      open: true,
      conditionId,
    }));
  }, []);

  const openModal = useCallback(() => {
    setDrawerState((prevState) => ({
      ...prevState,
      isOpenModal: true,
    }));
  }, []);

  const closePanel = useCallback(() => {
    setDrawerState((prevState) => ({
      ...prevState,
      open: false,
      conditionId: '',
      isExceptionRequest: false,
    }));
  }, []);

  const closeModal = useCallback(() => {
    setDrawerState((prevState) => ({
      ...prevState,
      isOpenModal: false,
    }));
  }, []);

  const handledExceptionRequest = useCallback((boolean: boolean) => {
    setDrawerState((prevState) => ({
      ...prevState,
      isExceptionRequest: boolean,
    }));
  }, []);

  const handledActiveButton = useCallback(
    (button: string) => setActiveButton(button),
    []
  );

  const handledMode = useCallback(
    (mode: MODE_SWITCHER_VALUES | string | number) => setMode(mode),
    []
  );

  const handledFinalUnderwriting = useCallback(
    (final: boolean) => setStartFinalUnderwriting(final),
    []
  );

  const handleStatusSectionOpen = () =>
    setStatusSectionOpen((prevState) => !prevState);

  useEffect(() => {
    if (!drawerState.conditionId && refetch) {
      refetch();
    }
  }, [drawerState.conditionId]);

  const actions = {
    handledActiveButton,
    openPanel,
    openModal,
    closePanel,
    closeModal,
    handledMode,
    handledFinalUnderwriting,
    handledExceptionRequest,
    handleStatusSectionOpen,
  };

  const valuesStates = {
    activeButton,
    drawerState,
    mode,
    startFinalUnderwriting,
    statusSectionOpen,
  };

  return {
    ...valuesStates,
    ...actions,
  };
};
