import React, { useMemo } from 'react';
import { TableColumnType } from 'antd';
import { LinearProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  useTableDataSearchAndFilter,
  createDateSorter,
  createDefaultSorter,
  dateRenderer,
  createArrayOfObjectsSorterByProperty,
} from '@lola/ui-react-components';
import { DataControlPanel } from '@components/DataTableControlPanel/DataTableControlPanel.component';
import { NoContent } from '@components/NoContent';
import { LOAN_STATUS_CONFIG } from '@constants/loan.constants';
import { LOAN_STATUS, SetModalContent } from '@typings/common';
import { createStatusRenderer } from '@utils/renderers/statusRenderer/createStatusRenderer';
import {
  LolaBffApiContractsModelsLoanLoanView,
  useGetApiV1LoansQuery,
} from '@api/output/api';
import { DrawerState } from '@containers/ManageAccessDrawer/ManageAccessDrawer.constants';
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import { loanTitleRenderer } from '@utils/renderers/loanTitleRenderer/loanTitleRenderer';
import { stageRenderer } from '@utils/renderers/stageRenderer/stageRenderer';
import { ModalContentType } from './Loans.constants';
import { LoansList, withTableActions } from './components';
import { actionsRenderer, processorListRenderer } from './renderers/tableCell';
import styles from './loans.module.scss';

const columns: TableColumnType<LolaBffApiContractsModelsLoanLoanView>[] = [
  {
    dataIndex: 'addressLine',
    key: 'addressLine',
    render: loanTitleRenderer,
    sorter: createDefaultSorter('addressLine'),
    title: 'Loan Name',
    width: '20%',
  },
  {
    dataIndex: 'step',
    key: 'step',
    render: stageRenderer,
    sorter: createDefaultSorter('step'),
    title: 'Step',
    width: '15%',
  },
  {
    dataIndex: 'status',
    key: 'status',
    render: createStatusRenderer<LOAN_STATUS>(LOAN_STATUS_CONFIG),
    sorter: createDefaultSorter('status'),
    title: 'Status',
    width: '12%',
  },
  {
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: dateRenderer,
    sorter: createDateSorter('createdAt'),
    title: 'Created',
    width: '10%',
  },
  {
    dataIndex: 'lastUpdateAt',
    key: 'lastUpdateAt',
    render: dateRenderer,
    sorter: createDateSorter('lastUpdateAt'),
    title: 'Updated',
    width: '10%',
  },
  {
    dataIndex: 'brokerName',
    key: 'brokerName',
    sorter: createDefaultSorter('brokerName'),
    title: 'Loan officer',
    width: '13%',
  },
  {
    dataIndex: 'processors',
    key: 'processors',
    render: processorListRenderer,
    sorter: createArrayOfObjectsSorterByProperty('processors', 'processorName'),
    title: 'Processor',
    width: '13%',
  },
  {
    key: 'actions',
    title: 'Actions',
    align: 'center',
    width: '6%',
  },
];

export interface LoansProps {
  setModalContent?: SetModalContent<ModalContentType>;
  setDrawerState?: (arg: DrawerState) => void;
  onLoanSelect?: (arg: LolaBffApiContractsModelsLoanLoanView[]) => void;
  selectedLoansIdSet?: Set<string>;
}

const Loans = ({
  setModalContent,
  setDrawerState,
  onLoanSelect,
  selectedLoansIdSet,
}: LoansProps) => {
  const { data, isFetching, isError } = useGetApiV1LoansQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { state } = useLocation();
  const {
    filteredData,
    handleSearchChange,
    handleFilterChange,
    appliedFilters,
    filters,
  } = useTableDataSearchAndFilter<LolaBffApiContractsModelsLoanLoanView>(
    data ?? [],
    {
      status: {
        values: [],
        title: 'Status',
      },
      step: {
        values: [],
        title: 'Step',
      },
      brokerName: {
        values: state?.loanOfficer ? [state.loanOfficer] : [],
        title: 'Loan officer',
      },
      processors: {
        values: [],
        filterByObjKey: 'processorName',
        title: 'Processor',
      },
    },
    ['addressLine', 'loanNumber'],
    ''
  );

  const updatedColumns = useMemo(() => {
    const newColumns = [...columns];
    const actionsColumnIndex = newColumns.findIndex(
      (column) => column.key === 'actions'
    );

    if (actionsColumnIndex !== -1) {
      newColumns[actionsColumnIndex] = {
        ...newColumns[actionsColumnIndex],
        render: (_, context) =>
          actionsRenderer(context, setModalContent, setDrawerState),
      };
    }

    return newColumns;
  }, [columns, setModalContent, setDrawerState, selectedLoansIdSet]);

  if (isFetching) {
    return <LinearProgress variant="indeterminate" value={30} />;
  }

  if (isError) {
    return <ErrorNoticeFetchRequest />;
  }

  if (!data?.length) {
    return (
      <div className={styles.noContentWrapper}>
        <NoContent title="No data to show here yet" />
      </div>
    );
  }

  return (
    <>
      <DataControlPanel
        appliedFilters={appliedFilters}
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleSearchChange={handleSearchChange}
        teamManagement
      />
      <div className={styles.loansListContainer}>
        <LoansList
          columns={updatedColumns}
          data={filteredData}
          onLoanSelect={onLoanSelect}
        />
      </div>
    </>
  );
};

export default withTableActions(Loans);
