import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';
import styles from './contentSectionHeader.module.scss';

export interface ContentSectionHeaderProps {
  title?: string;
  description?: string;
  actions?: ReactNode;
}

export const ContentSectionHeader = ({
  title,
  description,
  actions,
}: ContentSectionHeaderProps) => {
  return (
    <header className={styles.header}>
      <div className={styles.headerLine}>
        <Typography variant="h4">{title}</Typography>
        <div>{actions}</div>
      </div>
      {description && (
        <div className={styles.headerLine}>
          <Typography className={styles.description} variant="pSmall" mt={0.5}>
            {description}
          </Typography>
        </div>
      )}
    </header>
  );
};
