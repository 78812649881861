import { Paper } from '@mui/material';
import React from 'react';
import { Collapsed } from '@components/Collapsed';
import { DynamicFields } from '@components/DynamicForm';
import { forms } from '@form-configs/output';

export const ConstructionInspection = () => {
  return (
    <Paper>
      <Collapsed label="Construction Contact" defaultExpanded>
        <DynamicFields fields={forms.constructionInspection} />
      </Collapsed>
    </Paper>
  );
};
