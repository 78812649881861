import { PaletteOptions } from '@mui/material/styles/createPalette';

export const extendPaletteOptions = (
  palette: PaletteOptions
): PaletteOptions => {
  return {
    ...palette,
    primary: {
      ...palette.primary,
      main: palette.custom.darkBlue,
    },
  };
};
