export const formatThousands = (num: number | null | undefined = 0): string => {
  if (num === null || num === undefined) {
    return '0';
  }

  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const formatNumber = (
  num?: number | null,
  decimalPlaces = 1
): string => {
  if (num === null || num === undefined) {
    return '0';
  }

  const absNum = Math.abs(num);
  let suffix = '';
  let shortNum: number;

  if (absNum >= 1000000) {
    suffix = 'MM';
    shortNum = num / 1000000;
  } else if (absNum >= 1000) {
    suffix = 'K';
    shortNum = num / 1000;
  } else {
    shortNum = num;
  }

  if (decimalPlaces === 0) {
    return Math.round(shortNum) + suffix;
  } else {
    return shortNum.toFixed(decimalPlaces) + suffix;
  }
};
