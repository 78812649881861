import React, { PropsWithChildren } from 'react';
import { LabeledCard } from '@components/LabeledCard/LabeledCard.component';
import { Collapsed } from '@components/Collapsed';

import styles from './labeledCardCollapsed.module.scss';

interface LabeledCardCollapsedProps {
  label: string;
  description?: string;
}

export const LabeledCardCollapsed = ({
  label,
  description,
  children,
}: PropsWithChildren<LabeledCardCollapsedProps>) => {
  return (
    <LabeledCard>
      <Collapsed
        label={label}
        description={description}
        classes={{ root: styles.collapsed }}
      >
        <div className={styles.content}>{children}</div>
      </Collapsed>
    </LabeledCard>
  );
};
