import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { OpenInNewIcon } from '@lola/ui-react-components';
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { ProgressStatusIcon } from '@components/ProgressStepper/components';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import styles from './substep.module.scss';

export const Substep = ({
  id,
  title,
  to,
  status,
  metadata,
}: ProgressStepperItem) => {
  const showNavigationButton = useMemo(() => {
    return status !== ProgressStepItemStatus.LOCKED;
  }, [status]);

  return (
    <Grid item xs={12} key={id}>
      <Paper className={styles.step}>
        {status && <ProgressStatusIcon status={status} />}
        <div
          className={classnames(styles.information, {
            [styles.alert]: status === ProgressStepItemStatus.ALERT,
          })}
        >
          <Typography variant="pBold" className={styles.stepTitle}>
            {title}
          </Typography>
          {metadata?.reason && (
            <Typography variant="pSmall" className={styles.stepDescription}>
              {metadata.reason}
            </Typography>
          )}
        </div>
        {showNavigationButton && (
          <NavLink to={`../${to}`} className={styles.navLink}>
            <IconButton>
              <OpenInNewIcon />
            </IconButton>
          </NavLink>
        )}
      </Paper>
    </Grid>
  );
};
