import { VERTICAL_STATUS_STATE } from '@components/verticalStepDetail/VerticalStepDetail.component';
import { TASK_COMPLETION_STATUS } from '@typings/common';
import { CREDIT_SCORE_TASK_STATUS } from './CreditScore.types';

export const CREDIT_SCORE_STEP_TITLES = {
  [CREDIT_SCORE_TASK_STATUS.AUTHORIZATION]: 'Authorization',
  [CREDIT_SCORE_TASK_STATUS.REISSUE_GENERATION]:
    'Credit score re-issue generation',
  [CREDIT_SCORE_TASK_STATUS.PAYMENT]: 'Payment',
  [CREDIT_SCORE_TASK_STATUS.GENERATION]: 'Credit score generation',
  [CREDIT_SCORE_TASK_STATUS.RESULT]: 'Credit score result',
};

export const CREDIT_SCORE_TASK_COMPLETION_STATUS_MAP = {
  [TASK_COMPLETION_STATUS.UNKNOWN]: VERTICAL_STATUS_STATE.LOCKED,
  [TASK_COMPLETION_STATUS.IN_PROGRESS]: VERTICAL_STATUS_STATE.IN_PROGRESS,
  [TASK_COMPLETION_STATUS.SUCCESS]: VERTICAL_STATUS_STATE.DONE,
  [TASK_COMPLETION_STATUS.FAIL]: VERTICAL_STATUS_STATE.ERROR,
};
