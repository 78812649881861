import React from 'react';
import { IconButton } from '@mui/material';
import { StatusDoneIcon } from '@lola/ui-react-components';
import { useProgressStepperStats } from '../../hooks/useProgressStepperStats';
import { ProgressStepperItem } from '../../types/index';

import styles from './progressStatusHeaderIcon.module.scss';

interface ProgressStatusIconProps {
  items: ProgressStepperItem[];
  headerIcon?: React.ReactNode;
  locked?: boolean;
}

const ProgressStatusIcon = ({
  items,
  headerIcon,
  locked,
}: ProgressStatusIconProps) => {
  const { totalSteps, completedSteps } = useProgressStepperStats(items);

  return (
    <div className={styles.headerIcons}>
      {completedSteps === totalSteps && totalSteps !== 0 ? (
        <StatusDoneIcon className={styles.statusIcon} />
      ) : null}
      {headerIcon && !locked ? (
        <IconButton className={styles.headerIcon}>{headerIcon}</IconButton>
      ) : null}
    </div>
  );
};

export const ProgressStatusHeaderIcon = React.memo(ProgressStatusIcon);
