import React from 'react';
import { Button, Box } from '@mui/material';
import { Quote } from '@typings/common';
import { useOpenState } from '@hooks/useOpenState';
import { LeverageCalculationControl } from '@components/QuoteCalculation/components/LeverageCalculations/components/LeverageCalculationControl/LeverageCalculationControl';

export interface LeverageCalculationsProps {
  details?: Quote;
  disabled?: boolean;
}

export const LeverageCalculations = ({
  details,
  disabled,
}: LeverageCalculationsProps) => {
  const [isOpen, openModal, closeModal] = useOpenState();
  return (
    <>
      <Box mt={1.5}>
        <Button variant="outlined" onClick={openModal} disabled={disabled}>
          Adjust Leverage values
        </Button>
      </Box>
      {isOpen && (
        <LeverageCalculationControl details={details} closeModal={closeModal} />
      )}
    </>
  );
};
