import React, { useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { AddIcon, InputControl } from '@lola/ui-react-components';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { ContentSectionFooter } from '@components/ContentSection';
import { useGetApiV1LoansDisabledQuery } from '@api/output/api';
import { convertToOptions } from '@utils/options.utils';
import { ChangeRequestItem } from '../ChangeRequestItem/ChangeRequestItem.component';
import { useSendChangeRequest } from './hooks/useSendChangeRequest';
import { CHANGE_REQUEST_FIELD } from './ChangeRequest.typings';
import styles from './changeRequest.module.scss';

const LABEL = {
  [CHANGE_REQUEST_FIELD.LOAN_ID]: 'Loan',
  [CHANGE_REQUEST_FIELD.REASON]: 'Reason',
  [CHANGE_REQUEST_FIELD.DESCRIPTION]: 'New term description',
};

interface ChangeRequestProps {
  handleClose: () => void;
}

export const ChangeRequest = ({ handleClose }: ChangeRequestProps) => {
  const methods = useForm({
    defaultValues: {
      [CHANGE_REQUEST_FIELD.LOAN_ID]: '',
      [CHANGE_REQUEST_FIELD.REASON]: '',
      [CHANGE_REQUEST_FIELD.DESCRIPTION]: '',
    },
  });

  const { data, isFetching } = useGetApiV1LoansDisabledQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const options = useMemo(
    () =>
      convertToOptions({
        input: data ?? [],
        idKey: 'id',
        valueKeys: ['loanName', 'loanIdentifier'],
      }),
    [data]
  );

  const { onFormSubmit, isSending } = useSendChangeRequest(
    methods.handleSubmit
  );

  const isSubmitDisabled = useMemo(() => {
    const { dirtyFields } = methods.formState;

    return (
      !dirtyFields[CHANGE_REQUEST_FIELD.LOAN_ID] ||
      !dirtyFields[CHANGE_REQUEST_FIELD.REASON] ||
      !dirtyFields[CHANGE_REQUEST_FIELD.DESCRIPTION] ||
      isSending
    );
  }, [methods.formState, isSending]);

  const actions = useMemo(
    () => ({
      cancel: { onClick: handleClose },
      submit: {
        startIcon: <AddIcon />,
        disabled: isSubmitDisabled,
      },
    }),
    [handleClose, isSubmitDisabled]
  );

  if (isFetching) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit} className={styles.form}>
        <Grid container spacing={2} classes={{ root: styles.section }}>
          <ChangeRequestItem
            label="Reason"
            elements={[
              <SelectControl
                key={CHANGE_REQUEST_FIELD.LOAN_ID}
                label={LABEL[CHANGE_REQUEST_FIELD.LOAN_ID]}
                name={CHANGE_REQUEST_FIELD.LOAN_ID}
                required="Loan is required"
                options={options}
              />,
              <InputControl
                key={CHANGE_REQUEST_FIELD.REASON}
                label={LABEL[CHANGE_REQUEST_FIELD.REASON]}
                name={CHANGE_REQUEST_FIELD.REASON}
                required
              />,
            ]}
          />
          <ChangeRequestItem
            label="Term change request"
            elements={[
              <Typography key="note">
                Enter the revised term being requested with specific detail. Any
                changes to or after final approval may result in closing delays
                or Lender credits back to the borrower:
              </Typography>,
              <InputControl
                key={CHANGE_REQUEST_FIELD.DESCRIPTION}
                label={LABEL[CHANGE_REQUEST_FIELD.DESCRIPTION]}
                name={CHANGE_REQUEST_FIELD.DESCRIPTION}
                placeholder="Your text here"
                multiline
                rows={3}
                required
              />,
            ]}
          />
        </Grid>
        {isSending && <LinearProgress />}
        <ContentSectionFooter actions={actions} />
      </form>
    </FormProvider>
  );
};
