import { Typography } from '@mui/material';
import React from 'react';
import { ENTITY_TYPE } from '../SSNField.constants';

export interface SaveModalContentProps {
  ssn?: string;
  existedUserName?: string;
  entityType: ENTITY_TYPE;
}

export const PREFIX_MAP = {
  [ENTITY_TYPE.INDIVIDUAL]: 'New user with SSN ',
  [ENTITY_TYPE.ENTITY]: 'New borrowing entity with EIN ',
};

export const SaveModalContent = ({
  ssn,
  entityType,
}: SaveModalContentProps) => {
  return (
    <Typography variant="p">
      {PREFIX_MAP[entityType]}
      <strong>{ssn}</strong> will be created.
    </Typography>
  );
};
