import { useGetApiV1UsersMeQuery } from '@api/output/api';

export const useUserId = () => {
  const { data, isError } = useGetApiV1UsersMeQuery();

  if (isError || !data || !data.userId) {
    return '';
  }

  return data.userId;
};
