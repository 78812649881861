import { useMemo } from 'react';
import { useLoadUsers } from '@hooks/useLoadUsers';
import { ROLES } from '@typings/common';
import { convertToOptions } from '@utils/options.utils';

export const useProcessorOptions = () => {
  const { users, isLoading } = useLoadUsers({
    roles: [ROLES.PROCESSOR],
    skipFetch: false,
  });
  const processors = useMemo(() => {
    return users
      ? convertToOptions({
          input: users,
          idKey: 'userId',
          valueKeys: ['fullName', 'email'],
        })
      : [];
  }, [users]);

  return {
    processors,
    isLoading,
  };
};
