import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { ROLES, SetModalContent } from '@typings/common';
import {
  LolaBffApiContractsModelsUser,
  usePostApiV1TeamsMutation,
} from '@api/output/api';
import { FormFooter } from '@pages/teamManagement/pages/TeamMembers/components/ModalContent/components';
import { isManager } from '@utils/role.utils';
import { usePostUpdate } from '@hooks/usePostUpdate';
import { getChangeTeamRequest } from '@pages/teamManagement/pages/TeamMembers/components/ModalContent/components/ChangeTeam/ChangeTeam.utils';
import { BrokerManagerForm } from '@pages/teamManagement/pages/TeamMembers/components/ModalContent/components/ChangeTeam/components/BrokerManagerForm/BrokerManagerForm.component';
import { SelectDestinationTeam } from '../SelectDestinationTeam/SelectDestinationTeam.component';
import {
  MODAL_STATE,
  ModalContentType,
} from '../../../../TeamMembers.constants';

interface ChangeTeamProps {
  context: LolaBffApiContractsModelsUser;
  setModalContent: SetModalContent<ModalContentType>;
}

export const ChangeTeam = ({ context, setModalContent }: ChangeTeamProps) => {
  const methods = useForm({
    defaultValues: {
      removeFromTeam: '',
      managerUserId: '',
      destinationTeamId: '',
    },
  });

  const handleClose = useCallback(
    () => setModalContent({ state: MODAL_STATE.CLOSE }),
    []
  );

  const [change, { isUpdating }] = usePostUpdate(
    usePostApiV1TeamsMutation,
    handleClose
  );

  const { fullName = '', managesTeams, role } = context;

  const onFormSubmit = useMemo(() => {
    return methods.handleSubmit((values) => {
      change({
        lolaBffApiContractsModelsRequestsManageTeamRequest:
          getChangeTeamRequest(values, context),
      });
    });
  }, [methods.handleSubmit]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit}>
        {isManager(role as ROLES) ? (
          <BrokerManagerForm fullName={fullName} userTeams={managesTeams} />
        ) : (
          <SelectDestinationTeam
            label={`Select the team to which you want to move ${fullName}`}
            variant="filled"
            showHelperText={false}
          />
        )}
        <FormFooter>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!methods.formState.isDirty || isUpdating}
          >
            Confirm
          </Button>
        </FormFooter>
      </form>
    </FormProvider>
  );
};
