import React from 'react';
import { Typography, Stack } from '@mui/material';
import { UserProfileBall } from '@components/UserProfileBall/UserProfileBall.component';
import { LolaBffApiContractsModelsUser } from '@api/output/api';
import styles from './userInfoRenderer.module.scss';

export const userInfoRenderer = (
  _: unknown,
  row: LolaBffApiContractsModelsUser
) => {
  const { fullName, firstName, lastName, email, picture } = row;

  return (
    <Stack direction={{ xs: 'row' }} spacing={{ xs: 1.5 }}>
      <div className={styles.avatar}>
        <UserProfileBall
          firstName={firstName}
          lastName={lastName}
          picture={picture}
        />
      </div>
      <div className={styles.userInfo}>
        <Typography paragraph={true} variant="pBold" mb={0.5}>
          {fullName}
        </Typography>
        <Typography
          className={styles.email}
          paragraph={true}
          variant="pSmall"
          mb={0.5}
        >
          {email}
        </Typography>
      </div>
    </Stack>
  );
};
