import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { LolaBffApiContractsModelsLoanProcessorDto } from '@api/output/api';
import { MANAGE_ACCESS_FORM } from '../../ManageAccessDrawer.constants';
import { useProcessorOptions } from './hooks/useProcessorOptions';
import { AssignForm, ActionCard } from './components';

interface ProcessorsProps {
  list?: LolaBffApiContractsModelsLoanProcessorDto[] | null;
}

export const Processors = ({ list = [] }: ProcessorsProps) => {
  const { processors, isLoading } = useProcessorOptions();

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(MANAGE_ACCESS_FORM.PROCESSORS, list);
  }, [list]);

  if (isLoading) {
    return <LinearProgress data-cy="linear-progress" />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>
          Here’s a list of processors assigned to this loan.
        </Typography>
        <Typography>
          You can change, remove and attach new processors to a loan.
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <AssignForm processors={processors} />
        {list?.map(({ processorName, email, id }, index) => (
          <Grid item xs={12} key={id}>
            <ActionCard
              label={processorName ?? ''}
              description={email ?? ''}
              index={index}
              processors={processors}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
