import { LolaBffApiContractsModelsPricingEngineRequestScenario } from '@api/output/api';

export const IN_STF = {
  sfAccountId: '',
  sfDealId: '',
  loanTerm: {
    guaranty: 'Full Recourse',
  },
};

export const IN_RENTAL = {
  appraisedVal1: 0,
  totalCosts1: 0,
  appraisedVal3: 0,
  purchasePrice: 0,
  propertiesNY: 'No',
  borrowerIsReferral: 'No',
  yieldSpread: 0,
  refiL1cFixNFlip: 'No',
  buyUpDownOption: 'No Buy Up/Buy Down',
  buyUpDownPercentage: 0,
  isTier5Borrower: 'No',
  brokerFee: 0,
  brokerProcessingFee: 0,
  lenderFinanceAppraisalFee: 0,
  isZeroOrigFee: 'No',
  premier: null, // change the type on the backend
  sfAccountId: '',
  sfDealId: '',
  overrideMaster: false,
  hasSubmittedQuote: null,
  milestone: null,
  seasonalRental: 'No',
  fiveNineUnit: 'No',
  underwriterFields: {
    legalFee: '',
    taxImpound: null,
    insuranceImpound: null,
  },
  exceptionPricingOverride: {
    commissionReduction: 0,
    autoRate: 0,
    autoFee: 0,
    rateReductionOverride: null,
    finalOrigFeeOverride: null,
    finalRateOverride: null,
    blendedLTCOverride: null,
    purchaseLTCOverride: null,
    arvOverride: null,
    exceptionStatus: null,
    exceptionType: null,
    autoRateException: null,
    autoFeeException: null,
    rentalRateOverride: null,
  },
  base: {
    fixToRent: 'No',
  },
};

export const DEFAULT_SCENARIOS: LolaBffApiContractsModelsPricingEngineRequestScenario[] =
  [
    {
      scenarioId: 1,
      included: 'Yes',
      ltvPercentage: 75,
      preferred: 'Yes',
    },
    {
      scenarioId: 2,
      included: 'Yes',
      ltvPercentage: 70,
      preferred: 'No',
    },
    {
      scenarioId: 3,
      included: 'Yes',
      ltvPercentage: 65,
      preferred: 'No',
    },
  ];
