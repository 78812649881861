import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
  StatusAlertIcon,
  StatusDoneIcon,
  StatusInProgressIcon,
  StatusLockedIcon,
} from '@lola/ui-react-components';
import { ProgressStepItemStatus } from '../../types/index';

import styles from './progressStatusIcon.module.scss';

interface StatusIconProps {
  status: ProgressStepItemStatus;
}

const getStatusIcon = (status: ProgressStepItemStatus) => {
  switch (status) {
    case ProgressStepItemStatus.IN_PROGRESS:
      return <StatusInProgressIcon className={styles.statusIcon} />;
    case ProgressStepItemStatus.DONE:
      return <StatusDoneIcon className={styles.statusIcon} />;
    case ProgressStepItemStatus.LOCKED:
      return <StatusLockedIcon className={styles.statusLockedIcon} />;
    case ProgressStepItemStatus.ALERT:
      return <StatusAlertIcon className={styles.statusRedIcon} />;
    default:
      return null;
  }
};

export const ProgressStatusIcon = React.memo(({ status }: StatusIconProps) => (
  <ListItemIcon className={styles.listItemIcon}>
    {getStatusIcon(status)}
  </ListItemIcon>
));
