import { ROLES } from '@typings/common';
import { ProgressStepItemStatus } from '@components/ProgressStepper/types';
import { LolaBffApiContractsModelsUserRole } from '@api/output/api';

export enum FIELDS {
  BORROWER = 'borrowerId',
  LOAN_TYPE = 'loanProgramType',
  USER_ID = 'userId',
}

export interface CreateFormFields {
  [FIELDS.BORROWER]: string;
  [FIELDS.LOAN_TYPE]?: string;
  [FIELDS.USER_ID]: string;
}

type TitleConfig = { [key: string]: string };

const BROKER_TITLES: TitleConfig = {
  [FIELDS.BORROWER]: 'Step 1. Build new borrower',
  [FIELDS.LOAN_TYPE]: 'Step 2. Choose a Loan program',
  [FIELDS.USER_ID]: 'Step 3. Assign processor (optional)',
};

const PROCESSOR_TITLES: TitleConfig = {
  [FIELDS.BORROWER]: 'Step 1. Build new borrower',
  [FIELDS.LOAN_TYPE]: 'Step 2. Choose a Loan program',
  [FIELDS.USER_ID]: 'Step 3. Assign loan officer',
};

export const TITLE_MAP: { [key: string]: TitleConfig } = {
  [ROLES.KEY_BROKER_MANAGER]: BROKER_TITLES,
  [ROLES.BROKER_MANAGER]: BROKER_TITLES,
  [ROLES.BROKER]: BROKER_TITLES,
  [ROLES.PROCESSOR]: PROCESSOR_TITLES,
};

export const CRUMBS = [
  {
    title: 'Dashboard',
    link: '/',
  },
  {
    title: 'New loan',
  },
];

const BROKER_STEPPER_CONFIG = Object.keys(BROKER_TITLES).map((key) => ({
  id: key,
  title: BROKER_TITLES[key],
  status:
    key === FIELDS.BORROWER
      ? ProgressStepItemStatus.IN_PROGRESS
      : ProgressStepItemStatus.LOCKED,
}));

const PROCESSOR_STEPPER_CONFIG = Object.keys(PROCESSOR_TITLES).map((key) => ({
  id: key,
  title: PROCESSOR_TITLES[key],
  status:
    key === FIELDS.BORROWER
      ? ProgressStepItemStatus.IN_PROGRESS
      : ProgressStepItemStatus.LOCKED,
}));

export const INITIAL_STEPPER_CONFIG = {
  [ROLES.KEY_BROKER_MANAGER]: BROKER_STEPPER_CONFIG,
  [ROLES.BROKER_MANAGER]: BROKER_STEPPER_CONFIG,
  [ROLES.BROKER]: BROKER_STEPPER_CONFIG,
  [ROLES.PROCESSOR]: PROCESSOR_STEPPER_CONFIG,
  [ROLES.NOT_SET]: [],
};

export enum USER_ID_KEY {
  PROCESSOR = 'processorUserId',
  BROKER = 'brokerUserId',
}

const PROCESSOR_LABEL = 'Assign a processor to handle this loan';
const BROKER_LABEL = 'Assign a loan officer to handle this loan';

export const LABEL_MAP: Record<string, string> = {
  [ROLES.KEY_BROKER_MANAGER]: PROCESSOR_LABEL,
  [ROLES.BROKER_MANAGER]: PROCESSOR_LABEL,
  [ROLES.BROKER]: PROCESSOR_LABEL,
  [ROLES.PROCESSOR]: BROKER_LABEL,
};

export const REQUIRED_ROLE_MAP: Record<
  string,
  LolaBffApiContractsModelsUserRole
> = {
  [ROLES.KEY_BROKER_MANAGER]: ROLES.PROCESSOR,
  [ROLES.BROKER_MANAGER]: ROLES.PROCESSOR,
  [ROLES.BROKER]: ROLES.PROCESSOR,
  [ROLES.PROCESSOR]: ROLES.BROKER,
};
