import React, { useMemo } from 'react';
// MUI
import { Grid } from '@mui/material';
// Components
import { CalculationBlock } from '@components/CalculationBlock/CalculationBlock.component';
// Utils
import {
  SectionsConfig,
  convertQuoteObjectToCalculationBlock,
} from '@utils/quotes.utils';

export interface DefaultScenarioBuilderCalculationProps {
  details: object;
  configs: SectionsConfig;
  disabled?: boolean;
}

export const DefaultScenarioBuilderCalculation = ({
  details,
  configs,
  disabled,
}: DefaultScenarioBuilderCalculationProps) => {
  const blocks = useMemo(
    () => convertQuoteObjectToCalculationBlock(details, configs, disabled),
    [details]
  );

  return (
    <Grid container spacing={2} justifyContent={'end'}>
      {blocks.map((block) => (
        <Grid key={block.title} item xs={12} sm={6}>
          <CalculationBlock {...block} />
        </Grid>
      ))}
    </Grid>
  );
};
