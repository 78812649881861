import React, { useMemo } from 'react';
import { TableColumnType } from 'antd';
import { Button, LinearProgress } from '@mui/material';
import {
  AddIcon,
  TeamIcon,
  createArrayOfNumbersSorter,
  createDateSorter,
  createDefaultSorter,
  dateRenderer,
  useTableDataSearchAndFilter,
  filterTeamsOptionLabelRenderer,
} from '@lola/ui-react-components';
import { LolaBffApiContractsModelsUser } from '@api/output/api';
import { DataControlPanel } from '@components/DataTableControlPanel/DataTableControlPanel.component';
import { NoContent } from '@components/NoContent';
import { SetModalContent } from '@typings/common';
import { roleRenderer } from '@pages/teamManagement/pages/TeamMembers/renderers/tableCell/roleRenderer/roleRenderer';
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import { useLoadMembers } from '../../hooks/useLoadMembers';
import { ModalContentType } from './TeamMembers.constants';
import { TeamMembersList, withTableActions } from './components';
import {
  actionsRenderer,
  userInfoRenderer,
  userStatusRenderer,
  teamsListRenderer,
} from './renderers/tableCell';
import styles from './teamMembers.module.scss';

const getManagesTeams = (item: LolaBffApiContractsModelsUser) => {
  let managesTeam = null;

  if (item.managesTeams && item.managesTeams.length > 0) {
    managesTeam = item.managesTeams;
  }

  if (item.teamId) {
    managesTeam = [item.teamId.toString()];
  }

  return managesTeam;
};

/* Default sorter can sort only primitives, so we remove definition of nested object */
type DefaultUserInfo = Omit<LolaBffApiContractsModelsUser, 'userMetadata'>;

const columns: TableColumnType<LolaBffApiContractsModelsUser>[] = [
  {
    dataIndex: 'fullName',
    key: 'fullName',
    render: userInfoRenderer,
    sorter: createDefaultSorter<DefaultUserInfo>('fullName'),
    title: 'Name',
    width: '30%',
  },
  {
    dataIndex: 'role',
    key: 'role',
    sorter: createDefaultSorter<DefaultUserInfo>('role'),
    render: roleRenderer,
    title: 'Role',
    width: '20%',
  },
  {
    dataIndex: 'teams',
    key: 'teams',
    render: teamsListRenderer,
    sorter: createArrayOfNumbersSorter<DefaultUserInfo>(getManagesTeams),
    title: 'Team',
    width: '15%',
  },
  {
    dataIndex: 'status',
    key: 'status',
    render: userStatusRenderer,
    sorter: createDefaultSorter<DefaultUserInfo>('status'),
    title: 'Status',
    width: '10%',
  },
  {
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: dateRenderer,
    sorter: createDateSorter('createdAt'),
    title: 'Date added',
  },
  {
    key: 'actions',
    title: 'Actions',
    align: 'center',
  },
];

export interface TeamMembersProps {
  onInviteMemberClick?: () => void;
  setModalContent?: SetModalContent<ModalContentType>;
}

const TeamMembers = ({
  setModalContent,
  onInviteMemberClick,
}: TeamMembersProps) => {
  const { members, isLoading, isError } = useLoadMembers();
  const {
    filteredData,
    handleSearchChange,
    handleFilterChange,
    appliedFilters,
    filters,
  } = useTableDataSearchAndFilter<LolaBffApiContractsModelsUser>(
    members ?? [],
    {
      role: {
        values: [],
        optionLabelRenderer: roleRenderer,
        title: 'Role',
      },
      managesTeams: {
        values: [],
        optionLabelRenderer: filterTeamsOptionLabelRenderer,
        title: 'Team',
        keys: ['managesTeams', 'teamId'],
        processNull: true,
      },
      status: {
        values: [],
        title: 'Status',
      },
    },
    'fullName',
    ''
  );

  const actionsColumn = useMemo(
    () => columns.find((column) => column.key === 'actions') ?? {},
    []
  );

  actionsColumn.render = (_, context) => {
    return setModalContent ? actionsRenderer(setModalContent, context) : null;
  };

  if (isLoading) {
    return <LinearProgress variant="indeterminate" value={30} />;
  }

  if (isError) {
    return <ErrorNoticeFetchRequest />;
  }

  if (members !== undefined && !members.length) {
    return (
      <div className={styles.noContentWrapper}>
        <NoContent
          icon={<TeamIcon />}
          title="No data to show here yet"
          text="Add your first team member  and create a team"
        >
          <Button
            onClick={onInviteMemberClick}
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
          >
            Invite Member
          </Button>
        </NoContent>
      </div>
    );
  }

  return (
    <>
      <DataControlPanel
        appliedFilters={appliedFilters}
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleSearchChange={handleSearchChange}
        title="Filters"
        teamManagement
      />
      <div className={styles.teamMembersListContainer}>
        <TeamMembersList columns={columns} data={filteredData} />
      </div>
    </>
  );
};

export default withTableActions(TeamMembers);
