import React from 'react';
// MUI
import { Grid, Skeleton, Paper } from '@mui/material';
//Router
import { useParams } from 'react-router-dom';
// icons
import { InformationIcon } from '@lola/ui-react-components';
//API
import { ActionBlock, CardSection } from '@lola/ui-react-components';
import { useGetApiV1ClosemyloanGetSchedulingQuery } from '@api/output/api';
//Components
import { InvitationToClosing } from './components';
//Styles
import styles from './scheduling.module.scss';

export const SchedulingPage = () => {
  const { loanId = '' } = useParams();
  const { isLoading, data } = useGetApiV1ClosemyloanGetSchedulingQuery({
    dealId: loanId,
  });

  if (isLoading || !data) {
    return (
      <Paper elevation={2}>
        <Skeleton variant="text" className={styles.skeletonTitle} />
        <Skeleton className={styles.skeletonBody} variant="text" />
        <Skeleton variant="rounded" className={styles.skeletonFooter} />
      </Paper>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardSection
          title="Schedule closing"
          description="On this page you can coordinate a convenient time for the borrower to sign the documents."
        >
          <ActionBlock
            title={data.messageTitle ?? ''}
            description={data.messageDescription ?? ''}
            icon={<InformationIcon />}
          />
          <InvitationToClosing
            meetingData={data.scheduleInformation}
            title={data.responseTitle}
          />
        </CardSection>
      </Grid>
    </Grid>
  );
};
