import React, { useContext, useMemo } from 'react';
import { DropdownProps } from '@form-configs/types';
import { useOptions } from '@components/DynamicForm/components/DynamicDropdown/hooks/useOptions';
import { FormConfigContext } from '@components/DynamicForm/contexts/FormConfig.context';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { useFieldSpecificProps } from '@components/DynamicForm/hooks/useFieldSpecificProps';
import useFieldValidation from '@components/DynamicForm/hooks/useFieldValidation';

export const DynamicDropdown = ({
  label,
  name,
  multiple,
  options,
  placeholder,
  optionsKey = '',
  locked,
  validation,
  variant,
  required,
  optionalHelperText = 'Optional',
  defaultValue,
  controlType,
}: DropdownProps) => {
  const { disabled, onBlur } = useContext(FormConfigContext);
  const fieldSpecificProps = useFieldSpecificProps(name);
  const selectOptions = useOptions(optionsKey, options);

  const { convertedRules, requiredProp } = useFieldValidation({
    validation,
    locked,
    required,
    controlType,
  });

  const parsedDefaultValue = useMemo(() => {
    if (multiple) {
      return defaultValue?.toString().split(',');
    }
    return defaultValue?.toString();
  }, [multiple, defaultValue]);

  if (!selectOptions.length) {
    return null;
  }

  return (
    <SelectControl
      name={name}
      rules={convertedRules}
      label={label}
      multiple={Boolean(multiple)}
      placeholder={placeholder ?? 'Select'}
      disabled={disabled}
      variant={variant}
      options={selectOptions}
      customHandler={onBlur}
      optionalHelperText={optionalHelperText}
      required={requiredProp}
      defaultValue={parsedDefaultValue}
      data-cy={`${label?.split('.')[1]}-dropdown`}
      {...fieldSpecificProps}
    />
  );
};
