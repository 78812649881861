import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Grid, Skeleton } from '@mui/material';
import { CREDIT_SCORE_TASK_STATUS } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/components/CreditScore/CreditScore.types';
import {
  useLazyGetApiV1LoansByLoanIdDocumentTasksQuery,
  useLazyGetApiV1LolaTaskStatusQuery,
} from '@api/output/api';
import { Collapsed } from '@components/Collapsed';
import { useOpenState } from '@hooks/useOpenState';
import { FileViewer } from '@components/FIleViewer/FileViewer.component';
import { useParties } from '@pages/personalLoans/pages/buildMyLoan/hooks/useParties';
import { useLoanDetails } from '@pages/personalLoans/pages/buildMyLoan';
import { CREDIT_SCORE_STEP_TITLES } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/components/CreditScore/CreditScore.constants';
import { VERTICAL_STATUS_STATE } from '@components/verticalStepDetail/VerticalStepDetail.component';
import { useCreditTasks } from '@pages/personalLoans/pages/buildMyLoan/pages/creditReport/hooks/useCreditTasks';
import { useDownloadUrl } from '@pages/personalLoans/hooks/useDownloadUrl';
import { CreditScoreActorDetails } from './components/CreditScoreActorDetails/CreditScoreActorDetails.component';
import { CreditScoreItem } from './CreditScore.types';
import styles from './creditScore.module.scss';
import {
  getCreditScoreActors,
  getIndividualConfig,
} from './utils/creditScore.utils';

export interface CreditScoreProps {
  setCreditReportAccordionExpanded: (state: boolean) => void;
  creditReportAccordionExpanded: boolean;
  currentlyExpandedCreditScoreDetailsId: string | null;
  setCurrentlyExpandedCreditScoreDetailsId: (state: string | null) => void;
}

export const CreditScore = ({
  creditReportAccordionExpanded,
  setCreditReportAccordionExpanded,
  currentlyExpandedCreditScoreDetailsId,
  setCurrentlyExpandedCreditScoreDetailsId,
}: CreditScoreProps) => {
  const { loanId = '' } = useParams();
  const { parties } = useParties();
  const [reissueTasks, reportTasks] = useCreditTasks();

  const getDocumentUrl = useDownloadUrl(true);

  const [refetchDocumentsTasks] =
    useLazyGetApiV1LoansByLoanIdDocumentTasksQuery();
  const [getTaskStatus, { data: taskStatusData, isLoading }] =
    useLazyGetApiV1LolaTaskStatusQuery();
  const [isModalOpen, openModal, closeModal] = useOpenState();
  const [creditReportDocumentUrl, setCreditReportDocumentUrl] = useState<
    string | undefined
  >();

  const { refetchParties } = useLoanDetails();

  useEffect(() => {
    getTaskStatus({
      entity1Type: 'loan',
      entity1Id: loanId,
    });
  }, []);

  const creditScoreActors: CreditScoreItem[] | null = useMemo(() => {
    if (!taskStatusData || !parties.length) return null;

    return getCreditScoreActors(
      parties,
      taskStatusData,
      reissueTasks,
      reportTasks
    );
  }, [taskStatusData, parties, reissueTasks, reportTasks]);

  useEffect(() => {
    const shouldRefetch = creditScoreActors?.some((actor) =>
      actor.steps.some(
        (step) =>
          step.step ===
            CREDIT_SCORE_STEP_TITLES[CREDIT_SCORE_TASK_STATUS.RESULT] &&
          step.currentState === VERTICAL_STATUS_STATE.DONE
      )
    );
    if (shouldRefetch && refetchParties && refetchDocumentsTasks) {
      refetchParties();
      refetchDocumentsTasks({ loanId });
    }
  }, [refetchParties, refetchDocumentsTasks, creditScoreActors]);

  const openModalHandler = useCallback(
    async (id: string) => {
      openModal();
      const { task } = getIndividualConfig(reissueTasks, reportTasks, id);

      const url = await getDocumentUrl(task?.task?.documents?.[0].downloadId);
      setCreditReportDocumentUrl(url);
    },
    [
      reissueTasks,
      reportTasks,
      openModal,
      getDocumentUrl,
      setCreditReportDocumentUrl,
    ]
  );

  const closeModalHandler = useCallback(() => {
    closeModal();
    setCreditReportDocumentUrl(undefined);
  }, [closeModal, setCreditReportDocumentUrl]);

  const accordionChangeHandler = useCallback(() => {
    setCreditReportAccordionExpanded(!creditReportAccordionExpanded);
  }, [setCreditReportAccordionExpanded, creditReportAccordionExpanded]);

  return (
    <>
      <Paper>
        <Collapsed
          label="Credit score"
          expanded={creditReportAccordionExpanded}
          onChange={accordionChangeHandler}
          disabled={!parties.length}
        >
          {isLoading ? (
            <div>
              {parties.map(({ id }) => (
                <Skeleton
                  key={id}
                  variant="rounded"
                  height={100}
                  className={styles.skeleton}
                />
              ))}
            </div>
          ) : (
            <Grid container spacing={2}>
              {creditScoreActors?.map(
                ({
                  id,
                  name,
                  description,
                  score,
                  steps,
                  hasGeneratedDocument,
                }) => (
                  <Grid item xs={12} key={id} id={id}>
                    <CreditScoreActorDetails
                      id={id}
                      label={name}
                      hasGeneratedDocument={hasGeneratedDocument}
                      description={description}
                      score={score}
                      steps={steps}
                      currentlyExpandedCreditScoreDetailsId={
                        currentlyExpandedCreditScoreDetailsId
                      }
                      setCurrentlyExpandedCreditScoreDetailsId={
                        setCurrentlyExpandedCreditScoreDetailsId
                      }
                      onOpenModal={() => openModalHandler(id)}
                    />
                  </Grid>
                )
              )}
            </Grid>
          )}
        </Collapsed>
      </Paper>
      <FileViewer
        open={isModalOpen}
        title="Credit report"
        fileUrl={creditReportDocumentUrl}
        handleClose={closeModalHandler}
      />
    </>
  );
};
