import { LolaBffApiContractsModelsLoanAggregationInfo } from '@api/output/api';

type handlerQuoteIdType = {
  dataId?: LolaBffApiContractsModelsLoanAggregationInfo;
};

type getKey = 'override' | 'thirdParty';

export const handlerQuoteId = ({ dataId }: handlerQuoteIdType) => {
  if (!dataId?.focused) return '';

  const getKey = dataId.focused;

  return getKey ? dataId[getKey as getKey] : '';
};
