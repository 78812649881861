import React from 'react';
import dayjs from 'dayjs';
import { ContentSection, ContentSectionItem } from '@components/ContentSection';
import { useGetApiV1DashboardStatsAllTimeKpiQuery } from '@api/output/api';
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import { useTeamStats } from '../../hooks/useTeamStats';
import {
  WidgetContent,
  WidgetContentBody,
  WidgetContentFooter,
} from '../WidgetContent';
import {
  ALL_TIME_KPI_TITLES,
  ALL_TIME_KPI_TYPE,
} from './AllTimeKPIWidget.constants';

import styles from './allTimeKPIWidget.module.scss';

export const AllTimeKPIWidget = () => {
  const isTeamStats = useTeamStats();
  const { data, isLoading, isError } = useGetApiV1DashboardStatsAllTimeKpiQuery(
    {
      dateFrom: `${dayjs().year()}-01-01`,
      dateTo: dayjs().format('YYYY-MM-DD'),
      teamStats: isTeamStats,
    }
  );
  const { quotesToSubmission, submissionToClose, quotesToClose } = data ?? {};

  return (
    <ContentSection
      title="All time KPI's"
      description="Does not change depending on time filter"
      isLoading={isLoading}
    >
      <ContentSectionItem>
        <WidgetContent
          title={ALL_TIME_KPI_TITLES[ALL_TIME_KPI_TYPE.QUOTES_TO_SUBMISSION]}
          chipPrefix={
            quotesToSubmission?.averageDaysToClose
              ? `${quotesToSubmission?.averageDaysToClose} days`
              : ''
          }
          chipPostfix={
            quotesToSubmission?.averageDaysToClose ? 'avg.' : 'No data'
          }
          titleClass={styles.title}
        >
          {isError ? (
            <ErrorNoticeFetchRequest />
          ) : (
            <>
              <WidgetContentBody
                label={`${
                  quotesToSubmission?.percentageClosed?.toFixed(0) || '0'
                }%`}
              />
              <WidgetContentFooter
                withArrow={!!quotesToSubmission?.numberOfLoansSubmitted}
                leftLabelPrefix={
                  quotesToSubmission?.numberOfLoansSubmitted || ''
                }
                leftLabelPostfix={
                  quotesToSubmission?.numberOfLoansSubmitted
                    ? ' quotes'
                    : 'Not enough data'
                }
                rightLabelPrefix={quotesToSubmission?.numberOfLoansClosed || ''}
                rightLabelPostfix={
                  quotesToSubmission?.numberOfLoansClosed ? ' submissions' : ''
                }
              />
            </>
          )}
        </WidgetContent>
      </ContentSectionItem>
      <ContentSectionItem>
        <WidgetContent
          title={ALL_TIME_KPI_TITLES[ALL_TIME_KPI_TYPE.SUBMISSION_TO_CLOSE]}
          chipPrefix={
            submissionToClose?.averageDaysToClose
              ? `${submissionToClose?.averageDaysToClose} days`
              : ''
          }
          chipPostfix={
            submissionToClose?.averageDaysToClose ? 'avg.' : 'No data'
          }
          titleClass={styles.title}
        >
          {isError ? (
            <ErrorNoticeFetchRequest />
          ) : (
            <>
              <WidgetContentBody
                label={`${
                  submissionToClose?.percentageClosed?.toFixed(0) || '0'
                }%`}
              />
              <WidgetContentFooter
                withArrow={!!submissionToClose?.numberOfLoansClosed}
                leftLabelPrefix={
                  submissionToClose?.numberOfLoansSubmitted || ''
                }
                leftLabelPostfix={
                  submissionToClose?.numberOfLoansSubmitted
                    ? ' submissions'
                    : 'Not enough data'
                }
                rightLabelPrefix={submissionToClose?.numberOfLoansClosed || ''}
                rightLabelPostfix={
                  submissionToClose?.numberOfLoansClosed ? ' closed' : ''
                }
              />
            </>
          )}
        </WidgetContent>
      </ContentSectionItem>
      <ContentSectionItem>
        <WidgetContent
          title={ALL_TIME_KPI_TITLES[ALL_TIME_KPI_TYPE.QUOTES_TO_CLOSE]}
          chipPrefix={
            quotesToClose?.averageDaysToClose
              ? `${quotesToClose?.averageDaysToClose} days`
              : ''
          }
          chipPostfix={quotesToClose?.averageDaysToClose ? 'avg.' : 'No data'}
          titleClass={styles.title}
        >
          {isError ? (
            <ErrorNoticeFetchRequest />
          ) : (
            <>
              <WidgetContentBody
                label={`${quotesToClose?.percentageClosed?.toFixed(0) || '0'}%`}
              />
              <WidgetContentFooter
                withArrow={!!quotesToClose?.numberOfLoansSubmitted}
                leftLabelPrefix={quotesToClose?.numberOfLoansSubmitted || ''}
                leftLabelPostfix={
                  quotesToClose?.numberOfLoansSubmitted
                    ? ' quotes'
                    : 'Not enough data'
                }
                rightLabelPrefix={quotesToClose?.numberOfLoansClosed || ''}
                rightLabelPostfix={
                  quotesToClose?.numberOfLoansClosed ? ' closed' : ''
                }
              />
            </>
          )}
        </WidgetContent>
      </ContentSectionItem>
    </ContentSection>
  );
};
