import React, { useMemo } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { InformationIcon } from '@lola/ui-react-components';
import { Collapsed } from '@components/Collapsed';
import {
  CardStatusComponent,
  STATUS_LIST,
} from '@pages/personalLoans/components/CardStatus/CardStatus.component';
import {
  Step,
  VerticalStepDetail,
} from '@components/verticalStepDetail/VerticalStepDetail.component';
import { ESTIMATED_DAYS_TO_COMPLETE } from '../../ConstructionBudget.constants';
import styles from './constructionInspectionStatus.module.scss';

export interface ConstructionInspectionStatusProps {
  status?: STATUS_LIST;
  progress?: Step[];
}

export const ConstructionInspectionStatus = ({
  status,
  progress,
}: ConstructionInspectionStatusProps) => {
  const cardStatusInfo = useMemo(() => {
    switch (status) {
      case STATUS_LIST.IN_PROGRESS:
        return (
          <CardStatusComponent title="Inspection assigned" status={status}>
            <Typography>
              Est. time to complete: {ESTIMATED_DAYS_TO_COMPLETE} days
            </Typography>
          </CardStatusComponent>
        );
      case STATUS_LIST.DONE:
        return (
          <CardStatusComponent title="Inspection completed" status={status} />
        );
      case STATUS_LIST.ERROR:
        return (
          <CardStatusComponent title="Inspection failed" status={status} />
        );
      case STATUS_LIST.INFO:
        return (
          <CardStatusComponent title="Waiting to be assigned" status={status} />
        );
      default:
        return null;
    }
  }, [status]);

  return (
    <Paper>
      <Collapsed label="Construction Inspection Status" defaultExpanded>
        <Paper variant="filled" className={styles.statusWrapper}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              {cardStatusInfo}
            </Grid>
            <Grid item sm={12} md={6}>
              {progress && !!progress.length && (
                <VerticalStepDetail data={progress} />
              )}
            </Grid>
          </Grid>
          <Paper elevation={3} className={styles.contactBlock}>
            <InformationIcon className={styles.infoIcon} />
            <Typography>
              Have questions? Contact our support at
              <Typography
                variant="pBold"
                component="span"
                className={styles.phoneNumber}
              >
                &nbsp;800-931-4834
              </Typography>
            </Typography>
          </Paper>
        </Paper>
      </Collapsed>
    </Paper>
  );
};
