import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  CreateFormFields,
  FIELDS,
} from '@pages/createLoan/CreateLoan.constants';
import { LOAN_PRODUCTS, QUOTE_TYPE } from '@typings/common';

const RENTAL_PRODUCT_TYPE = 'Rental30';

export const useCreateLoanForm = () => {
  const [searchParams] = useSearchParams();
  const quoteId = parseInt(searchParams.get('quoteId') ?? '', 10);
  const quoteType = searchParams.get('quoteType') as QUOTE_TYPE;
  const productType = searchParams.get('productType') as LOAN_PRODUCTS;

  const methods = useForm<CreateFormFields>();

  const [borrower, loanType, requiredUserId] = methods.watch([
    FIELDS.BORROWER,
    FIELDS.LOAN_TYPE,
    FIELDS.USER_ID,
  ]);

  useEffect(() => {
    if (quoteId) {
      const loanProductType =
        quoteType === QUOTE_TYPE.RENTAL
          ? RENTAL_PRODUCT_TYPE
          : productType?.trim();

      methods.setValue(FIELDS.LOAN_TYPE, loanProductType);
    }
  }, [quoteId, productType, quoteType, borrower]);

  return {
    methods,
    borrower,
    loanType,
    requiredUserId,
    quoteId,
    quoteType,
  };
};
