import { useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { SAVED_QUOTE_ID_TO_LOAN } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { LOAN_PROGRAM_KEY } from '@config/forms';
import { LOAN_PROGRAM, QUOTE_TYPE } from '@typings/common';
import { useQuoteGetApi } from '@hooks/useQuotesApi/useQuoteGetApi';

export const useLoanQuote = () => {
  const [savedQuoteIdToLoan, loanProgram] = useWatch({
    name: [SAVED_QUOTE_ID_TO_LOAN, LOAN_PROGRAM_KEY],
  });

  const quoteType =
    loanProgram === LOAN_PROGRAM.RENTAL_30
      ? QUOTE_TYPE.RENTAL
      : QUOTE_TYPE.SHORT_TERM;

  const [loadQuote, { data }] = useQuoteGetApi(quoteType);

  useEffect(() => {
    if (savedQuoteIdToLoan) {
      loadQuote({ quoteId: savedQuoteIdToLoan });
    }
  }, [savedQuoteIdToLoan]);

  return useMemo(
    () => ({
      quoteType,
      data,
    }),
    [quoteType, data]
  );
};
